import React from 'react';

import * as dS from '../styles';
import * as gS from '../grayStyles';

type InformationSheetHighlightSectionProperties = {
	type?: 'highlight';
	label: string;
	value: string;
	suffix?: React.ReactNode;
	labelWidth?: string;
	valueWidth?: string;
	labelStyle?: React.CSSProperties;
	valueStyle?: React.CSSProperties;
};

interface Props {
	item: InformationSheetHighlightSectionProperties;
	type?: 'default' | 'gray';
}

function HighlightSectionInformation({ item, type = 'default' }: Props): React.ReactElement {
	const S = type === 'default' ? dS : gS;

	return (
		<S.Information>
			<S.HighlightItemLabel
				style={{
					maxWidth: item.labelWidth,
					minWidth: item.labelWidth,
					...item.labelStyle,
				}}>
				{item.label}
			</S.HighlightItemLabel>
			<S.HighlightItemValue
				style={{
					maxWidth: item.valueWidth,
					minWidth: item.valueWidth,
					...item.valueStyle,
				}}>
				{item.value} {item.suffix && <S.SuffixValue>{item.suffix}</S.SuffixValue>}
			</S.HighlightItemValue>
		</S.Information>
	);
}

export default React.memo(HighlightSectionInformation);
