import React from 'react';

import * as S from './styles';

function IntegratedAssessmentDesireBasicStatusHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn style={{ width: '20rem' }} colSpan={2}>
				분류
			</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '60rem' }}>확인</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesireBasicStatusHeader);
