import { styled } from 'styled-components';

export const Container = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const Title = styled.div`
	${(props) => props.theme.typography.labelB}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2.4rem;
`;

export const Information = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const ItemLabel = styled.span`
	color: ${(props) => props.theme.colors.gray60};
`;

export const ItemValue = styled.span``;
