import styled from 'styled-components';

export const TableLabelColumn = styled.div<{ $height?: string }>`
	/* display: grid;
	grid-template-columns: 25% 25% 25% 25%; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: auto;
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	height: ${(p) => (p.$height ? p.$height : '3.3rem')};
	word-break: keep-all;
	:last-child {
		border-right: 0;
	}
`;

export const Block = styled.div`
	height: 100%;
	width: 100%;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Chip = styled.div<{ $isActive?: boolean }>`
	height: 2.5rem;
	background-color: ${(p) => (p.$isActive ? p.theme.colors.greenLighten : p.theme.colors.gray90)};
	color: ${(p) => (p.$isActive ? p.theme.colors.greenDarken : p.theme.colors.gray60)};
	padding: 0 0.8rem;
	border-radius: 2.4rem;
	cursor: default;
`;
