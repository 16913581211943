import React, { useCallback, useEffect, useMemo } from 'react';
import 'dayjs/locale/ko';

import CRInput from 'components/base/CRInput';
import useDialog from 'lib/hook/util/useDialog';
import * as Accordion from '@radix-ui/react-accordion';

import CRButton from 'components/base/CRButton';

import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeDetailBasicInformationTable from 'components/domain/table/EmployeeDetailBasicInformationTable';
import {
	useCommonCodes,
	useEmployeePageBaseInfo,
	useEmployeeResignInfo,
	useMyAccountInfo,
	useResignEmployee,
} from 'lib/hook/react-query';
import CRTable from 'components/base/CRTable';
import dayjs from 'dayjs';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { Controller, useForm } from 'react-hook-form';
import { EmployeeResignForm } from 'types/view/employee';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeResign } from 'lib';

import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import { Toast } from 'components/base/CRToast';
import { EmployeeDTO, ResignEmployeeRequest, ResponseCode } from 'types/api';
import { commonCodeAdapter } from 'lib/adapter/common';
import { endpoint } from 'lib/service/Api/endpoint';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import RouterPath from 'common/router';
import * as S from './styles';
import { EMPLOYEE_CONTRACT_LEAVE_SERVICE_TABLE_HEADER_CONFIG } from './constans';

function EmployeeResignPage(): React.ReactElement {
	const { setCurrentEmployee, currentEmployee } = useEmployeePage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { showDialog, hideDialog } = useDialog();
	const {
		control,
		handleSubmit,
		reset,
		formState: { isValid },
	} = useForm<EmployeeResignForm>({
		resolver: yupResolver(EmployeeResign),
	});

	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN109'],
		},
		commonCodeAdapter,
	);

	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: myAccountInfo?.centerId,
		employeeId: Number(params.id),
	});

	const { data: employeeResignInfo, mutate: getResignInfo } = useEmployeeResignInfo();

	const { mutate: resignEmployee } = useResignEmployee((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			if (returnData.data && currentEmployee) {
				client.invalidateQueries([
					endpoint.getEmployeeDetailInfo.key,
					{
						centerId: returnData.data.centerId,
						employeeId: returnData.data.employeeId,
					},
				]);

				client.setQueryData(
					[
						endpoint.getEmployees.key,
						{
							centerIds: [myAccountInfo?.centerId],
						},
					],
					(data: EmployeeDTO[] = []) => {
						const employeesCache = data;
						const newCache = employeesCache?.map((employee) => {
							if (employee.employeeId === returnData?.data?.employeeId) {
								return {
									...employee,
									workStateNm: '퇴사',
								};
							}
							return employee;
						});

						return newCache;
					},
				);

				setCurrentEmployee({
					...currentEmployee,
					workStateNm: '퇴사',
				});
			}
			Toast.success('직원 상태를 퇴사로 변경했습니다.');
			navigate(`/${RouterPath.employee().tab.uniqueKey}/${params.id}/basic`);
			// navigate(-1);
		} else {
			Toast.error('실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});

	const resignData = employeeResignInfo?.data;

	const renderServiceStartDate = useCallback(
		(serviceStartDate: string) => (
			<S.TextContainer>
				{serviceStartDate ? dayjs(serviceStartDate).format('YYYY.MM.DD') : '-'}
			</S.TextContainer>
		),
		[],
	);

	const renderManagerName = useCallback(() => {
		const managerNameArr = currentEmployeeBase?.managerNm?.split(' ');
		const managerName =
			managerNameArr && managerNameArr?.length > 0
				? managerNameArr[managerNameArr.length - 1]
				: '-';
		return <S.TextContainer>{managerName}</S.TextContainer>;
	}, [currentEmployeeBase]);

	const totalWorkDate = useMemo(() => {
		if (resignData?.serviceStartDate && resignData.serviceStateChangeDate) {
			const diffTotalMonth = dayjs(resignData.serviceStateChangeDate).diff(
				dayjs(resignData.serviceStartDate),
				'month',
			);

			if (diffTotalMonth > 11) {
				return `총 ${Math.floor(diffTotalMonth / 12)}년 ${Math.floor(
					diffTotalMonth % 12,
				)}개월 근무`;
			}
			return `${dayjs(resignData.serviceStartDate).format('YYYY.MM.DD')} ~ ${dayjs(
				resignData.serviceStateChangeDate,
			).format('YYYY.MM.DD')}(총 ${Math.floor(diffTotalMonth % 12)}개월 근무)`;
		}
		return '';
	}, [resignData]);

	const onSubmit = (data: EmployeeResignForm) => {
		if (!resignData) return;

		const params: ResignEmployeeRequest = {
			centerId: resignData.centerId,
			employeeId: resignData.employeeId,
			serviceStartDate: resignData.serviceStartDate,
			serviceStateChangeDate: resignData.serviceStateChangeDate,
			serviceChangeDescCd: resignData.serviceChangeDescCd,
			serviceChangeEtcDesc: resignData.serviceChangeDesc,
			serviceChangeDesc: resignData.serviceChangeReason || '',
			insuranceLossRequestYn: data.insuranceLossRequestYn,
			wardResignDischargeRequestYn: data.wardResignDischargeRequestYn,
			incompletePaperConfirmYn: data.incompletePaperConfirmYn,
			calculateConfirmYn: data.calculateConfirmYn,
		};

		resignEmployee(params);
	};

	const handleClickResign = () => {
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='퇴사 신청'
				content={`${currentEmployeeBase?.korMemberNm}(${currentEmployeeBase?.employeeId}) 직원 상태를 퇴사로 변경합니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '퇴사',
					successCallback: handleSubmit(onSubmit),
				}}
			/>
		));
	};

	const handleToggleAllCheck = () => {
		reset({
			insuranceLossRequestYn: true,
			wardResignDischargeRequestYn: true,
			incompletePaperConfirmYn: true,
			calculateConfirmYn: true,
		});
	};

	const contractLeaveServiceData = employeeResignInfo?.data?.contractLeaveServiceResponses || [];

	const resignType = useMemo(
		() =>
			(commonCodes?.CMN109 || [])?.find(
				(item) => item.data?.etcDesc2 === 'Y' && item.value === resignData?.serviceChangeDescCd,
			) ?? {
				label: '',
				value: '',
			},
		[commonCodes?.CMN109, resignData],
	);

	useEffect(() => {
		if (myAccountInfo?.centerId && params?.id) {
			getResignInfo({
				centerId: myAccountInfo?.centerId,
				employeeId: params.id,
			});
		}
	}, [myAccountInfo?.centerId, params.id]);

	useEffect(() => {
		if (employeeResignInfo && employeeResignInfo?.code !== ResponseCode.SUCCESS) {
			navigate(-1);
		}
	}, [employeeResignInfo]);

	return (
		<S.Container>
			<S.Title>{`${currentEmployeeBase?.korMemberNm}(${currentEmployeeBase?.employeeId}) 직원 퇴사`}</S.Title>
			<S.ContentsContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
					}}
					defaultValue={['기본 정보', '계약 종료 급여', '퇴사 신청', '점검']}>
					<Accordion.Item value='기본 정보' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>기본 정보</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<EmployeeDetailBasicInformationTable item={currentEmployeeBase} />
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='계약 종료 급여' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>계약 종료 급여</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<CRTable.Root
										style={{
											width: '60rem',
											borderLeft: '0.1rem solid #E1E3E3',
											borderRight: '0.1rem solid #E1E3E3',
										}}>
										<CRTable.Head heads={EMPLOYEE_CONTRACT_LEAVE_SERVICE_TABLE_HEADER_CONFIG} />
										<CRTable.Body>
											{contractLeaveServiceData.map((item) => (
												<CRTable.Row
													style={{
														width: '60rem',
													}}
													key={item.serviceContractDetailId}
													item={item}
													customRender={{
														serviceStartDate: renderServiceStartDate,
														managerId: renderManagerName,
													}}
													renderKeys={[
														'centerNm',
														'managerId',
														'serviceTypeCdNm',
														'employeeNm',
														'serviceStartDate',
													]}
												/>
											))}
										</CRTable.Body>
									</CRTable.Root>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='퇴사 신청' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>퇴사 신청</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<div
										style={{
											width: '57.2rem',
										}}>
										<CRInputLabel label='퇴사일' type='left-sub' isRequired>
											<CRInput.DatePicker
												disabled
												value={dayjs(resignData?.serviceStateChangeDate).toDate()}
												placeholder='퇴사일'
											/>
										</CRInputLabel>
										<CRInputLabel label='이용기간' type='left-sub'>
											<CRInput.Default disabled value={totalWorkDate} placeholder='퇴사일' />
										</CRInputLabel>
										<CRInputLabel label='종료 유형' type='left-sub' isRequired>
											<CRInput.Selector
												disabled
												currentValue={resignType}
												items={[resignType]}
												placeholder='퇴사일'
											/>
										</CRInputLabel>
										{resignData?.serviceChangeDescCd === 'CMN109.99' && (
											<CRInputLabel label='' type='left-sub'>
												<CRInput.Default
													disabled
													value={resignData?.serviceChangeDesc}
													placeholder='퇴사일'
												/>
											</CRInputLabel>
										)}
										<CRInputLabel label='종료 사유' type='left-sub' isRequired>
											<CRInput.TextArea
												disabled
												numberOfLines={10}
												value={resignData?.edocParams?.[0]?.leaveReason || ''}
											/>
										</CRInputLabel>
									</div>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='점검' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>점검</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<S.CheckBoxContainer>
										<S.CheckBoxRow
											style={{
												paddingTop: '0.4rem',
											}}>
											<CRCheckBox checked={isValid} onChange={handleToggleAllCheck}>
												전체 점검 완료
											</CRCheckBox>
										</S.CheckBoxRow>
										<S.Divider />
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														4대 보험 상실 신고 <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='insuranceLossRequestYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														시군구 보고(직원 퇴사 신고) <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='wardResignDischargeRequestYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<>
														<CRCheckBox checked={value} onChange={onChange}>
															미비 서류 확인 <S.RequiredMark>*</S.RequiredMark>
														</CRCheckBox>
														<S.SubCheckContainer>
															<S.SubCheckDescription>
																자격증 (요양보호사, 간호사, 간호조무사)
															</S.SubCheckDescription>
															<S.SubCheckDescription>근로자 계약서</S.SubCheckDescription>
															<S.SubCheckDescription>건강검진 결과지</S.SubCheckDescription>
															<S.SubCheckDescription>법정의무교육 수료증</S.SubCheckDescription>
															<S.SubCheckDescription>
																(해당 시) 임금이체위임 동의서
															</S.SubCheckDescription>
															<S.SubCheckDescription>
																(해당 시) 급여제공 서약서
															</S.SubCheckDescription>
															<S.SubCheckDescription>
																(해당 시) 급여제공기록지
															</S.SubCheckDescription>
														</S.SubCheckContainer>
													</>
												)}
												name='incompletePaperConfirmYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														정산 여부 확인(미납급, 환급금) <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='calculateConfirmYn'
												control={control}
											/>
										</S.CheckBoxRow>
									</S.CheckBoxContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
				</Accordion.Root>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer>
					<S.BottomRightMenuContainer>
						<CRButton.Default type='text' palette='gray' onClick={() => navigate(-1)}>
							취소
						</CRButton.Default>
						<CRButton.Default disabled={!isValid} type='outlined' onClick={handleClickResign}>
							퇴사
						</CRButton.Default>
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}

export default EmployeeResignPage;
