import CRStatus from 'components/base/CRStatus';
import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const Title = styled.p`
	${(prosp) => prosp.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const ApprovalLineList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const OrderCount = styled.h4<{ $hasItem?: boolean }>`
	${(props) => props.theme.typography.h4};
	color: ${(props) => (props.$hasItem ? props.theme.colors.gray00 : props.theme.colors.gray60)};
`;

export const EmployeeInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const EmployeeName = styled.span`
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const CenterName = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
`;

export const EmptyMessage = styled.p`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
`;

export const ItemLeft = styled.div`
	display: flex;
	align-items: center;
	gap: 1.6rem;
`;

export const Info = styled.div`
	display: flex;
	width: 2.4rem;
	align-items: center;
`;
export const DraggableIcon = styled.img`
	display: none;
	width: 100%;
	height: 2.4rem;
`;

export const Status = styled(CRStatus)``;

export const ItemRight = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const DeleteIcon = styled.img`
	display: none;
	width: 2.4rem;
	height: 2.4rem;
	user-select: none;
`;

export const ApprovalLineItem = styled.div<{ $hasItem?: boolean; $readOnly?: boolean }>`
	display: flex;
	justify-content: space-between;
	flex-direction: ${(props) => (props.$hasItem ? 'column' : 'row')};
	padding: 1.6rem 2.4rem;
	min-height: 8.8rem;
	box-sizing: border-box;
	background: ${(props) =>
		props.$hasItem ? props.theme.colors.gray100 : props.theme.colors.gray95};
	border-radius: 1.6rem;
	cursor: ${(props) => (props.$readOnly ? 'auto' : 'pointer')};

	${(props) =>
		props.$hasItem &&
		!props.$readOnly &&
		css`
			&:hover {
				background: ${props.theme.colors.gray95};
			}
			&:hover ${DraggableIcon} {
				display: flex;
			}

			&:hover ${OrderCount} {
				display: none;
			}

			&:hover ${DeleteIcon} {
				display: flex;
			}
		`};

	/* & + & {
		margin-top: 1.6rem;
	} */

	&:hover ${Status} {
		display: none!;
	}
`;

export const ApprovalLineItemTop = styled.div`
	display: flex;
	/* gap: 1.6rem; */
	justify-content: space-between;
	align-items: center;
`;
export const ApprovalStatusInfo = styled.div`
	/* width: 300px; */
	margin-top: 1.6rem;
`;

export const DragItemContainer = styled.div`
	& + & {
		margin-top: 1.6rem;
	}
`;
