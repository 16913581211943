import React from 'react';
import { Controller } from 'react-hook-form';

import { ContractRenewalInspectionCheckDTO } from 'types/dto';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import CRButton from 'components/base/CRButton';

import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import * as S from './styles';

function RenewalInspectionBeforeApprovalForm(): React.ReactElement {
	const { form, disabled } = useContractRenewalTask();

	const handleClickIaDirectly = () => {
		window.open(
			`recipient/${form.getValues('recipient.base.recipientId')}/integrated-assessment`,
			'_blank',
		);
	};
	return (
		<S.Container>
			<Controller
				name='inspection.check'
				control={form?.control}
				render={({ field: { onChange, value }, formState: { errors } }) => {
					const isAllChecked = (
						[
							'recipientIaWriteYn',
							'salaryOfferPlanWriteYn',
							'admsnUsereqUsedescLocalgovSendYn',
							'longTermUsePlanPcorpBrowseRequestYn',
						] as (keyof ContractRenewalInspectionCheckDTO)[]
					).every((item) => value?.[item]);

					return (
						<>
							<CRCheckBox
								checked={isAllChecked}
								onChange={() => {
									onChange({
										...value,
										recipientIaWriteYn: !isAllChecked,
										salaryOfferPlanWriteYn: !isAllChecked,
										admsnUsereqUsedescLocalgovSendYn: !isAllChecked,
										liabilityInsuranceRegistNeedYn: !isAllChecked,
										longTermUsePlanPcorpBrowseRequestYn: !isAllChecked,
									});
								}}
								disabled={disabled}>
								전체 점검 완료
							</CRCheckBox>
							<S.Divider />
							<CRCheckBox
								checked={value?.recipientIaWriteYn}
								onChange={() =>
									onChange({ ...value, recipientIaWriteYn: !value?.recipientIaWriteYn })
								}
								disabled={disabled}>
								통합사정 작성 (일정)
								<CRRequiredMark />
								<S.IntegratedAssessmentDirectContainer>
									<CRButton.Default
										palette='gray'
										type='outlined'
										size='xSmall'
										onClick={handleClickIaDirectly}>
										바로가기
									</CRButton.Default>
								</S.IntegratedAssessmentDirectContainer>
							</CRCheckBox>
							<CRCheckBox
								checked={value?.salaryOfferPlanWriteYn}
								onChange={() =>
									onChange({ ...value, salaryOfferPlanWriteYn: !value?.salaryOfferPlanWriteYn })
								}
								disabled={disabled}>
								급여제공계획서 작성
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.admsnUsereqUsedescLocalgovSendYn}
								onChange={() =>
									onChange({
										...value,
										admsnUsereqUsedescLocalgovSendYn: !value?.admsnUsereqUsedescLocalgovSendYn,
									})
								}
								disabled={disabled}>
								입소이용신청서/내역서 지자체 발송 <CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.longTermUsePlanPcorpBrowseRequestYn}
								onChange={() =>
									onChange({
										...value,
										longTermUsePlanPcorpBrowseRequestYn:
											!value?.longTermUsePlanPcorpBrowseRequestYn,
									})
								}
								disabled={disabled}>
								개인별 장기요양이용계획서 공단 열람 신청
								<CRRequiredMark />
							</CRCheckBox>
							{form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10' && (
								<CRCheckBox
									checked={value?.autotransUploadYn}
									onChange={() => {
										onChange({
											...value,
											autotransUploadYn: !value?.autotransUploadYn,
										});
									}}>
									자동이체동의서 업로드
									<CRRequiredMark />
								</CRCheckBox>
							)}
						</>
					);
				}}
			/>
		</S.Container>
	);
}

export default RenewalInspectionBeforeApprovalForm;
