import React, { CSSProperties } from 'react';
import * as S from './styles';

interface Props {
	containerStyle?: CSSProperties;
	floatMode?: boolean;
	size?: string;
}

export default function CRSpinner({ containerStyle = {}, floatMode = true, size = '2rem' }: Props) {
	return (
		<S.Container $floatMode={floatMode} style={containerStyle}>
			<S.Spinner $size={size} />
		</S.Container>
	);
}
