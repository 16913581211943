import { GetEmployeeAdvicesData, GetRecipientAdvicesData } from 'types/api/advice';
import { EmployeeConsulting } from 'types/view/employee';
import { RecipientConsulting } from 'types/view/recipient';

export const recipientAdvicesAdapter = (
	data: GetRecipientAdvicesData | null,
): RecipientConsulting[] =>
	(data ?? []).map((item) => ({
		id: item.consultId,
		date: item.consultDate,
		consultant: item.clientNm,
		relation: item.clientDivNm,
		type: item.tagGroupNm,
		tag: item.tagNm,
		center: item.centerNm,
		employee: item.writerNm,
	}));

export const EmployeeAdvicesAdapter = (data: GetEmployeeAdvicesData | null): EmployeeConsulting[] =>
	(data ?? []).map((item) => ({
		id: item.consultId,
		date: item.consultDate,
		consultant: item.clientNm,
		relation: item.clientDivNm,
		type: item.tagGroupNm,
		tag: item.tagNm,
		center: item.centerNm,
		employee: item.writerNm,
	}));
