import React from 'react';

import * as S from './styles';

function EmployeeInformationTable(): React.ReactElement {
	return (
		<S.Table>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '12rem' }}>이름</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '16rem' }}>김요보</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>성별</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '8rem' }}>남성</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>생년월일</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '12rem' }}>1934.04.25</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '12rem' }}>보호자 연락처</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '16rem' }}>010-1111-2222</S.TableValueColumn>
			</S.TableRow>
		</S.Table>
	);
}

export default EmployeeInformationTable;
