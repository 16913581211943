import React from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import { useEmployeePageLicenseInfo } from 'lib/hook/react-query';

import * as S from './styles';
import AddLicenseDialog from '../../dialog/AddLicenseDialog';
import ContractEmployeeLicenseTable from '../../table/ContractEmployeeLicenseTable';

interface Props {
	index: number;
}

function TrainingForm({ index }: Props): React.ReactElement {
	const { form, commonCodes, disabled } = useContractTask();
	const { showDialog } = useGlobalLayout();

	const { data: employeeLicenseInfo, refetch } = useEmployeePageLicenseInfo({
		employeeId: form.getValues(`employees.${index}.base.employeeId`),
	});

	const handleDeleteLicense = () => {
		refetch();
	};

	const handleClickAddCareer = () => {
		showDialog(({ hideDialog }) => (
			<AddLicenseDialog
				onDelete={handleDeleteLicense}
				currentEmployee={{
					korMemberNm: form.getValues(`employees.${index}.base.employeeNm`),
					employeeId: form.getValues(`employees.${index}.base.employeeId`),
				}}
				licenseTypes={commonCodes?.CMN067 ?? []}
				hideDialog={hideDialog}
			/>
		));
	};

	if (!form.watch(`employees.${index}`)) return <div />;

	return (
		<S.Container>
			<ContractEmployeeLicenseTable
				onRefetch={refetch}
				currentEmployee={{
					korMemberNm: form.getValues(`employees.${index}.base.employeeNm`),
					employeeId: form.getValues(`employees.${index}.base.employeeId`),
				}}
				items={employeeLicenseInfo}
				licenseTypes={commonCodes?.CMN067 ?? []}
			/>
			{!disabled && (
				<CRButton.IconButton
					iconLeft={Assets.icon.add}
					type='outlined'
					palette='gray'
					onClick={handleClickAddCareer}
					disabled={disabled}>
					자격증 추가
				</CRButton.IconButton>
			)}
		</S.Container>
	);
}

export default TrainingForm;
