import React from 'react';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import useSideModal from 'lib/hook/util/useSideModal';
import { Billing } from 'types/view/billing';

import * as S from './styles';
import { BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG } from './constant';
import InformationBar from '../../../ui/InformationBar';
import InformationListSheet from '../../../ui/InformationListSheet';
import InformationSheet from '../../../ui/InformationSheet';

interface Props {
	item: Billing;
}

function BillingSideModal({ item }: Props): React.ReactElement {
	const { hideSideModal } = useSideModal();

	return (
		<S.Container>
			<S.Header>
				<S.Title>청구 상세</S.Title>
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={hideSideModal} />
			</S.Header>
			<S.Content>
				<S.TitleContainer>
					<S.Title>김민수(300101) • 방문요양 • 오류</S.Title>
				</S.TitleContainer>
				<S.SummaryContainer>
					<InformationBar label='총 청구액' value='451,400원' suffix='(본인부담금: 142,000원)' />
					<InformationSheet
						items={[
							[{ label: '중증수급가산', value: '0원' }],
							[{ label: '산정 총액', value: '949,500원' }],
							[
								{ label: '사회복지사 가산금', value: '-' },
								{ label: '가산 점수', value: '-' },
							],
						]}
					/>
					<InformationListSheet
						title='수급자 정보'
						items={[
							{
								name: '김민수',
								gender: '남성',
								residentRegistrationNumber: '000000-0000000',
								ownExpenseRate: '15%',
							},
						]}
						config={[
							{
								key: 'name',
								label: '이름',
								width: '4rem',
							},
							{
								key: 'gender',
								label: '성별',
								width: '4rem',
							},
							{
								key: 'residentRegistrationNumber',
								label: '주민등록번호',
								width: '16rem',
							},
							{
								key: 'ownExpenseRate',
								label: '본인부담율',
							},
						]}
					/>
				</S.SummaryContainer>
				<S.SectionContainer>
					<S.SectionTitle>장기요양 급여 내역</S.SectionTitle>
					<CRTable.InformationRoot>
						<CRTable.InformationHead
							background={Colors.gray99}
							heads={BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG}
							borderBottom={`0.1rem solid ${Colors.gray90}`}
						/>
						<CRTable.Body>
							<CRTable.InformationRow
								item={{
									date: '03.01',
									service: '방문 요양(가족 90)',
									scheduleTime: '09:00~10:30',
									subtractionRate: '09:00~10:30',
									employee: '46.5시간',
									amount: '46.5시간',
									type: '자동',
									reporter: '미수정',
								}}
								renderKeys={[
									'date',
									'service',
									'scheduleTime',
									'subtractionRate',
									'employee',
									'amount',
									'type',
									'reporter',
								]}
							/>
							<CRTable.InformationRow
								item={{
									date: '03.01',
									service: '방문 요양(가족 90)',
									scheduleTime: '09:00~10:30',
									subtractionRate: '09:00~10:30',
									employee: '46.5시간',
									amount: '46.5시간',
									type: '자동',
									reporter: '미수정',
								}}
								renderKeys={[
									'date',
									'service',
									'scheduleTime',
									'subtractionRate',
									'employee',
									'amount',
									'type',
									'reporter',
								]}
							/>
							<CRTable.InformationRow
								item={{
									date: '03.01',
									service: '방문 요양(가족 90)',
									scheduleTime: '09:00~10:30',
									subtractionRate: '09:00~10:30',
									employee: '46.5시간',
									amount: '46.5시간',
									type: '자동',
									reporter: '미수정',
								}}
								renderKeys={[
									'date',
									'service',
									'scheduleTime',
									'subtractionRate',
									'employee',
									'amount',
									'type',
									'reporter',
								]}
							/>
							<CRTable.InformationRow
								item={{
									date: '03.01',
									service: '방문 요양(가족 90)',
									scheduleTime: '09:00~10:30',
									subtractionRate: '09:00~10:30',
									employee: '46.5시간',
									amount: '46.5시간',
									type: '자동',
									reporter: '미수정',
								}}
								renderKeys={[
									'date',
									'service',
									'scheduleTime',
									'subtractionRate',
									'employee',
									'amount',
									'type',
									'reporter',
								]}
							/>
							<CRTable.InformationRow
								item={{
									date: '03.01',
									service: '방문 요양(가족 90)',
									scheduleTime: '09:00~10:30',
									subtractionRate: '09:00~10:30',
									employee: '46.5시간',
									amount: '46.5시간',
									type: '자동',
									reporter: '미수정',
								}}
								renderKeys={[
									'date',
									'service',
									'scheduleTime',
									'subtractionRate',
									'employee',
									'amount',
									'type',
									'reporter',
								]}
							/>
							<CRTable.InformationRow
								item={{
									date: '03.01',
									service: '방문 요양(가족 90)',
									scheduleTime: '09:00~10:30',
									subtractionRate: '09:00~10:30',
									employee: '46.5시간',
									amount: '46.5시간',
									type: '자동',
									reporter: '미수정',
								}}
								renderKeys={[
									'date',
									'service',
									'scheduleTime',
									'subtractionRate',
									'employee',
									'amount',
									'type',
									'reporter',
								]}
								borderBottom='none'
							/>
						</CRTable.Body>
					</CRTable.InformationRoot>
				</S.SectionContainer>
			</S.Content>
			<S.ButtonContainer>
				<CRButton.Default>저장</CRButton.Default>
				<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
					취소
				</CRButton.Default>
			</S.ButtonContainer>
		</S.Container>
	);
}

export default BillingSideModal;
