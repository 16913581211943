import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 48rem;
	background: ${(props) => props.theme.colors.gray100};
	border-radius: 1.6rem;
`;

export const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0rem 1.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray99};
	border-bottom: 1px solid ${(props) => props.theme.colors.gray90};
`;

export const SearchIcon = styled.img`
	${(props) => props.theme.filters.gray60}
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0.8rem;
`;

export const SearchInput = styled.input`
	${(props) => props.theme.typography.body};
	flex: 1;
	padding: 2.4rem 0rem;
	box-sizing: border-box;
	border: none;
	outline: none;
	background: none;
	color: ${(props) => props.theme.colors.gray10};

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.8rem 1.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
	gap: 0.8rem;
`;

export const SearchResultContainer = styled.div`
	padding: 0.8rem 1.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
`;

export const SearchResultTitle = styled.div`
	${(props) => props.theme.label}
	color: ${(props) => props.theme.colors.gray60}
`;

export const SearchResultEmptyListContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2.4rem;
	box-sizing: border-box;
`;

export const SearchResultEmptyListDescription = styled.div`
	${(props) => props.theme.body}
	color: ${(props) => props.theme.colors.gray60}
`;

export const SearchResultListContainer = styled.ul`
	display: flex;
	flex-direction: column;
`;

export const SearchResultItemContainer = styled.li`
	height: 4.8rem;
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const SearchResultItemMainContainer = styled.span`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
`;

export const SearchResultItemSubContainer = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;
