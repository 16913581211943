import { styled } from 'styled-components';

export const Container = styled.div`

	display: flex;
	flex-direction: column;
	gap:1.2rem;
	background: ${(props) => props.theme.colors.gray100}; */
`;

export const inputContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const CenterSelectorContainer = styled.div`
	width: 300px;
`;
