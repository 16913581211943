import styled, { RuleSet, css } from 'styled-components';
import { ButtonPalette, ButtonSize, ButtonState, ButtonType } from '../type';

type ColorObjType = {
	[key in ButtonPalette]: {
		[key in ButtonType]: {
			[key in ButtonState]: RuleSet<object>;
		};
	};
};

type SizeObjType = {
	[key in ButtonSize]: RuleSet<object>;
};

const COLOR_ATTRIBUTE: ColorObjType = {
	primary: {
		filled: {
			default: css`
				background: ${(props) => props.theme.colors.primary60};
				color: ${(props) => props.theme.colors.gray100};
				& > img {
					filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(2724%)
						hue-rotate(290deg) brightness(115%) contrast(100%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.primary50};
				color: ${(props) => props.theme.colors.gray100};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary40};
				color: ${(props) => props.theme.colors.gray100};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
		tonal: {
			default: css`
				background: ${(props) => props.theme.colors.primary90};
				color: ${(props) => props.theme.colors.gray10};
				& > img {
					filter: brightness(0) saturate(100%) invert(8%) sepia(68%) saturate(5963%)
						hue-rotate(325deg) brightness(52%) contrast(124%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.primary80};
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary80};
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
		outlined: {
			default: css`
				background: ${(props) => props.theme.colors.gray100};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.primary60} inset;
				color: ${(props) => props.theme.colors.primary60};
				& > img {
					filter: brightness(0) saturate(100%) invert(57%) sepia(59%) saturate(5237%)
						hue-rotate(322deg) brightness(100%) contrast(102%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.primary99};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.primary60} inset;
				color: ${(props) => props.theme.colors.primary60};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary95};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.primary60} inset;
				color: ${(props) => props.theme.colors.primary60};
			`,
			disabled: css`
				background: transparent;
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
		text: {
			default: css`
				background: transparent;
				color: ${(props) => props.theme.colors.primary60};
				& > img {
					filter: brightness(0) saturate(100%) invert(44%) sepia(59%) saturate(2578%)
						hue-rotate(323deg) brightness(102%) contrast(101%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray100};
				color: ${(props) => props.theme.colors.primary60};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary95};
				color: ${(props) => props.theme.colors.primary60};
			`,
			disabled: css`
				background: transparent;
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
	},
	gray: {
		filled: {
			default: css`
				background: ${(props) => props.theme.colors.gray10};
				color: ${(props) => props.theme.colors.gray100};
				& > img {
					filter: invert(99%) sepia(0%) saturate(5699%) hue-rotate(154deg) brightness(109%)
						contrast(100%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray00};
				color: ${(props) => props.theme.colors.gray100};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary00};
				color: ${(props) => props.theme.colors.gray100};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
		tonal: {
			default: css`
				background: ${(props) => props.theme.colors.gray95};
				color: ${(props) => props.theme.colors.gray10};
				& > img {
					filter: invert(8%) sepia(16%) saturate(314%) hue-rotate(149deg) brightness(92%)
						contrast(94%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.gray80};
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
		outlined: {
			default: css`
				background: ${(props) => props.theme.colors.gray100};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray10};
				& > img {
					filter: invert(8%) sepia(16%) saturate(314%) hue-rotate(149deg) brightness(92%)
						contrast(94%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray99};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.gray95};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: transparent;
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
		text: {
			default: css`
				background: transparent;
				color: ${(props) => props.theme.colors.gray10};
				& > img {
					filter: invert(8%) sepia(16%) saturate(314%) hue-rotate(149deg) brightness(92%)
						contrast(94%);
				}
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray99};
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.gray95};
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: transparent;
				color: ${(props) => props.theme.colors.gray60};
				& > img {
					filter: invert(60%) sepia(4%) saturate(191%) hue-rotate(149deg) brightness(95%)
						contrast(90%);
				}
			`,
		},
	},
};

const SIZE_ATTRIBUTE: SizeObjType = {
	default: css`
		padding: 0.6rem 2.4rem;
		min-height: 4rem;
		${(props) => props.theme.typography.label};
	`,
	large: css`
		padding: 1.4rem 3.2rem;
		min-height: 5.6rem;
		${(props) => props.theme.typography.body};
	`,
	small: css`
		padding: 0.4rem 1.2rem 0.4rem 0.8rem;
		min-height: 3.2rem;
		border-radius: 0.8rem;
		${(props) => props.theme.typography.label};
	`,
	xSmall: css`
		padding: 0.4rem 1.2rem 0.4rem 0.4rem;
		min-height: 2.5rem;
		border-radius: 0.4rem;
		${(props) => props.theme.typography.label};
	`,
};

export const Container = styled.button<{
	$palette: ButtonPalette;
	$buttonType: ButtonType;
	$size: ButtonSize;
	$fullSize: boolean;
}>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.4rem;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 0.8rem;
	padding: 0.6rem 2.4rem 0.6rem 1.6rem;
	white-space: nowrap;
	min-height: 4rem;
	width: ${(props) => (props.$fullSize ? '100%' : 'auto')};
	${(props) => props.theme.typography.label};
	${(props) => SIZE_ATTRIBUTE[props.$size]};
	${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].default};
	&:hover {
		${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].hover};
	}
	&:active {
		${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].active};
	}
	&:disabled {
		${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].disabled};
	}
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const SyncStateButton = styled.div`
	position: relative;
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0.8rem;
`;

// export const LoadingContainer = styled.div`
// 	border-radius: inherit;
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background: 'rgba(255,255,255, 0.8)';
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// `;
