import React, { useMemo } from 'react';
import { EmployeeDetailInfoDTO } from 'types/api/employee';

import { CheckOption } from 'components/base/Selections/type';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { EmployeeBasicInfoFormViewType } from 'types/view/employee';
import { EditableEmployeeFormTypes } from 'lib/provider/employee';
import dayjs from 'dayjs';
import * as S from './styles';
import EmployeeBasicInfoView from './EmployeeBasicInfoView';
import EmployeeBasicInfoEdit from './EmployeeBasicInfoEdit';

interface Props {
	item?: EmployeeDetailInfoDTO | null;
	options: {
		workState: CheckOption[];
		duty: CheckOption[];
		day: CheckOption[];
		disabilityLevel: CheckOption[];
		healthCheckKind: CheckOption[];
		docReceiveMethod: CheckOption[];
	};
}

export default function EmployeeBasicInfoForm({ item, options }: Props): React.ReactElement {
	const { isFormEditable, startChangeEditSection, isEditSectionChanging, cancelChangeEditSection } =
		useEmployeePage();

	const currentEmployeeBasicInfo = useMemo(
		() =>
			({
				mobilePhoneNo: item?.mobilePhoneNo,
				disabilityGradeCd: item?.disabilityGradeCd,
				subMobilePhoneNo: item?.subMobilePhoneNo,
				employeeSpecialDesc: item?.employeeSpecialDesc,
				healthCheckDate: item?.healthCheckDate ? new Date(item?.healthCheckDate) : undefined,
				rsdnNo: item?.rsdnNo,
				basAddr: item?.basAddr,
				regionNm1: item?.regionNm1,
				regionNm2: item?.regionNm2,
				regionNm3: item?.regionNm3,
				addrLatitude: item?.addrLatitude ? Number(item?.addrLatitude) : '',
				addrLongitude: item?.addrLongitude ? Number(item?.addrLongitude) : '',
				roadNm: item?.roadNm,
				detailAddr: item?.detailAddr,
				zipCode: item?.zipCode,
				birthDt: item?.birthDt,
				korMemberNm: item?.korMemberNm,
				dutyCds:
					item?.dutyCds.map(
						(e) => options?.duty.find((item) => item.value === e.dutyCd) as CheckOption,
					) ?? [],
				twoPhoneYn: [
					{
						label: item?.twoPhoneYn ? '있음' : '없음',
						value: !!item?.twoPhoneYn,
					},
				],
				tagCallRequestYn: [
					{
						label: item?.tagCallRequestYn ? '진행' : '미진행',
						value: !!item?.tagCallRequestYn,
					},
				],
				tagCallDayCds:
					item?.tagCallDayCds.map(
						(e) => options?.day.find((item) => item.value === e.tagCallDayCd) as CheckOption,
					) ?? [],
				dementiaEduCompleteYn: [
					{
						label: item?.dementiaEduCompleteYn ? '수료' : '미수료',
						value: !!item?.dementiaEduCompleteYn,
					},
				],
				insurance: item?.insurance || '',
				accidentInsuranceAcqStateCd: item?.accidentInsuranceAcqStateCd,
				accidentInsuranceAcqStateNm: item?.accidentInsuranceAcqStateNm,
				accidentInsuranceAcqDate: item?.accidentInsuranceAcqDate,
				accidentInsuranceLossDate: item?.accidentInsuranceLossDate,
				employInsuranceAcqStateCd: item?.employInsuranceAcqStateCd,
				employInsuranceAcqStateNm: item?.employInsuranceAcqStateNm,
				employInsuranceAcqDate: item?.employInsuranceAcqDate,
				employInsuranceLossDate: item?.employInsuranceLossDate,
				healthInsuranceAcqStateCd: item?.healthInsuranceAcqStateCd,
				healthInsuranceAcqStateNm: item?.healthInsuranceAcqStateNm,
				healthInsuranceAcqDate: item?.healthInsuranceAcqDate,
				healthInsuranceLossDate: item?.healthInsuranceLossDate,
				pensionInsuranceAcqStateCd: item?.pensionInsuranceAcqStateCd,
				pensionInsuranceAcqStateNm: item?.pensionInsuranceAcqStateNm,
				pensionInsuranceAcqDate: item?.pensionInsuranceAcqDate,
				pensionInsuranceLossDate: item?.pensionInsuranceLossDate,
				docReceiveMethodCd: item?.docReceiveMethodCd,
				joinDate: item?.joinDate ? dayjs(item?.joinDate).format('YYYYMMDD') : '',
				monthPerWorkHourCnt: item?.monthPerWorkHourCnt ? String(item?.monthPerWorkHourCnt) : '',
				visitCareOffHourCnt: item?.visitCareOffHourCnt ? String(item.visitCareOffHourCnt) : '0',
				visitBathOffHourCnt: item?.visitBathOffHourCnt ? String(item.visitBathOffHourCnt) : '0',
				visitNursingOffHourCnt: item?.visitNursingOffHourCnt
					? String(item.visitNursingOffHourCnt)
					: '0',
			} as EmployeeBasicInfoFormViewType),
		[item, options],
	);

	const onStartChangeSection = () => {
		startChangeEditSection(EditableEmployeeFormTypes.기본정보);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableEmployeeFormTypes.기본정보) ? (
				<EmployeeBasicInfoEdit
					options={options}
					item={currentEmployeeBasicInfo}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<EmployeeBasicInfoView
					options={options}
					item={currentEmployeeBasicInfo}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}
