import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const MONITORING_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '8.8rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8.8rem',
		label: '확정 상태',
		sortKey: '확정 상태',
	},
	{
		width: '8rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '12rem',
		label: '장기요양인정번호',
		sortKey: '장기요양인정번호',
	},
	{
		width: '12rem',
		label: '급여 유형 상세',
		sortKey: '급여 유형 상세',
	},
	{
		width: '8rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '8rem',
		label: '담당자',
		sortKey: '담당자',
	},
	{
		width: '11.2rem',
		label: '근무 확인',
		sortKey: '근무 확인',
	},
	{
		width: '11.2rem',
		label: '일정 중복 확인',
		sortKey: '일정 중복 확인',
	},
	{
		width: '11.2rem',
		label: '자료생성 여부',
		sortKey: '자료생성 여부',
	},
	{
		width: '20rem',
		label: '데이터 갱신 완료 일시',
		sortKey: '데이터 갱신 완료 일시',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const COMPLETE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미완료',
		label: '미완료',
		color: 'yellow',
	},
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
];
