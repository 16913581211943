import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
	overflow: overlay;
`;

export const EllipsisText = styled.div`
	width: 100%;
	vertical-align: center;
	${(p) => p.theme.typography.label};
	color: ${(p) => p.theme.colors.primary60};
	line-height: 3.3rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
