import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	height: 100%;
	box-sizing: border-box;
`;

export const FilterGroup = styled.div``;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const RightHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: 0.8rem;
	align-items: center;
`;

export const RightTableContainer = styled.div``;

export const RightTabContainer = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

export const RightTabViewContainer = styled.div`
	flex: 1;
	overflow: auto;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const Title = styled.h4`
	color: ${(props) => props.theme.colors.gray10};
`;

export const TableContainer = styled.div`
	height: 100%;
	overflow: hidden;
	border-radius: 0.4rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableScrollContainer = styled.div`
	height: 100%;
	overflow-x: hidden;
	overflow-y: overlay;
`;

export const ScheduleSyncMenuContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const SyncStateButton = styled.div`
	position: relative;
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0.8rem;
	/* display: flex;
	align-items: center;
	gap: 0.8rem; */
`;

export const SendButtonContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
	display: flex;
	align-items: center;
	gap: 1.6rem;
`;
