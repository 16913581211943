import React from 'react';

import CRRsdNumber from 'components/base/CRRsdNumber';
import { RecipientInfoSummary } from 'types/view/recipient';

import { displayPhoneNumber } from 'lib';
import * as S from './styles';

interface Props {
	item?: RecipientInfoSummary;
}

function RecipientInformationTable({ item }: Props): React.ReactElement {
	return (
		<S.Table>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>이름</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.name}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>주요보호자</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.guardian}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>담당자</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.manager}</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>성별</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.gender}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>전화번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					{displayPhoneNumber(item?.phoneNumber)}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>본인부담율</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.ownExpense}</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>주민번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					<CRRsdNumber rdsNumber={item?.rsdn} key={item?.rsdn} />
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>타기관 이용</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.otherService}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>장기요양인정번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.longTermNo}</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>주소</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.address}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>이용서비스</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.service}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>장기요양 등급</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.longTermGrade}</S.TableValueColumn>
			</S.TableRow>
		</S.Table>
	);
}

export default React.memo(RecipientInformationTable);
