import { styled } from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	width: 60.4rem;
`;

export const EmptyContainer = styled.div`
	width: 100%;
	height: 8.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(p) => p.theme.colors.gray99};
	border-radius: 0.8rem;
`;
