import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const EMPLOYEE_DOCUMENT_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '4rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '4.8rem',
		label: '분류',
		sortKey: '분류',
	},
	{
		width: '12rem',
		label: '발급/등록일자',
		sortKey: '발급/등록일자',
	},
	{
		width: '32rem',
		label: '서류 유형',
		sortKey: '서류 유형',
	},
	{
		width: '20rem',
		label: '첨부파일',
		sortKey: '첨부파일',
	},

	{
		width: '24rem',
		label: '비고',
		sortKey: '비고',
	},
	{
		width: '12rem',
		label: '',
		sortKey: '',
	},
];

export const EMPLOYEE_SALARY_SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '성공',
		label: '성공',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
];
