import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
	width: 100%;
	min-width: 92.6rem;
`;

export const Table = styled.table`
	width: 100%;
	table-layout: fixed;
	padding-bottom: 2rem;
	border-collapse: separate;
	box-sizing: border-box;
`;

export const TableHeader = styled.thead`
	height: 5.7rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableHeaderColumn = styled.th`
	position: sticky;
	top: 17rem;
	z-index: 5;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
	background: ${(props) => props.theme.colors.gray100};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableHeaderColumnWithPadding = styled(TableHeaderColumn)`
	padding: 0rem 2.4rem;
	box-sizing: border-box;
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	cursor: pointer;
	height: 5.7rem;
	background: ${(props) => props.theme.colors.gray100};

	&:hover {
		background: ${(props) => props.theme.colors.primary95};
		border: 0.1rem solid ${(props) => props.theme.colors.primary90};
	}
`;

export const TableBodySubRow = styled(TableBodyRow)`
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableBodyIconContainer = styled.div`
	cursor: pointer;
	width: 5.7rem;
	height: 5.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TableBodySubLine = styled.div`
	width: 0.1rem;
	height: 100%;
	background: ${(props) => props.theme.colors.gray90};
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray00};
	white-space: nowrap;
	box-sizing: border-box;
	vertical-align: middle;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableBodyColumnWithPadding = styled(TableBodyColumn)`
	padding: 0rem 2.4rem;
`;

export const TableBodyToggleIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const StatusLabelContainer = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: 0.05rem 0.8rem;
	box-sizing: border-box;
	border-radius: 1.2rem;
`;

export const DoneStatusLabelContainer = styled(StatusLabelContainer)`
	background-color: ${(props) => props.theme.colors.greenLighten};
`;

export const ModifiedStatusLabelContainer = styled(StatusLabelContainer)`
	background-color: ${(props) => props.theme.colors.yellowLighten};
`;

export const EmptyStatusLabelContainer = styled(StatusLabelContainer)`
	background-color: ${(props) => props.theme.colors.redLighten};
`;

export const StatusBadge = styled.div`
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 0.4rem;
	margin-right: 0.6rem;
`;

export const DoneStatusBadge = styled(StatusBadge)`
	background: ${(props) => props.theme.colors.green};
`;

export const InProgressStatusBadge = styled(StatusBadge)`
	background: ${(props) => props.theme.colors.blue};
`;

export const PendingStatusBadge = styled(StatusBadge)`
	background: ${(props) => props.theme.colors.gray60};
`;

export const StatusLabel = styled.span`
	${(props) => props.theme.typography.label}
`;

export const DoneStatusLabel = styled(StatusLabel)`
	color: ${(props) => props.theme.colors.greenDarken};
`;

export const ModifiedStatusLabel = styled(StatusLabel)`
	color: ${(props) => props.theme.colors.yellowDarken};
`;

export const EmptyStatusLabel = styled(StatusLabel)`
	color: ${(props) => props.theme.colors.redDarken};
`;

export const ProgressContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ProgressBarContainer = styled.div`
	position: relative;
	width: 24rem;
	height: 0.2rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const ProgressBarPercentage = styled.div<{ percentage?: number }>`
	position: absolute;
	width: ${(props) => `${(props.percentage ?? 0) * 24}rem`};
	height: 0.2rem;
	background: ${(props) => props.theme.colors.primary60};
`;

export const ProgressBarLabel = styled.span`
	width: 6rem;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	text-align: right;
`;

export const ServiceDetail = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
`;
