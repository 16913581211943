import { QueryClient } from '@tanstack/react-query';

import Env from 'common/env';
import DevApi from 'lib/service/Api/DevApi';
import ProductApi from 'lib/service/Api/ProductApi';

import IApi from '../Api/IApi';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 10 * 1000,
		},
	},
});

function loadApi() {
	switch (Env.API_ENV) {
		case 'development':
			return new DevApi();
		case 'production':
			return new ProductApi();
		default:
			throw new Error();
	}
}

export function loadApplication(): { api: IApi; queryClient: QueryClient } {
	const api = loadApi() as IApi;

	return {
		api,
		queryClient,
	};
}
