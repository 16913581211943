import React, { CSSProperties, ReactNode } from 'react';

import CRSpinner from 'components/base/CRSpinner';
import Colors from 'common/colors';
import * as S from './styles';
import { ButtonPalette, ButtonSize, ButtonType } from '../type';

interface IProps {
	isLoading?: boolean;
	buttonType?: 'submit' | 'button';
	size?: ButtonSize;
	style?: CSSProperties;
	palette?: ButtonPalette;
	type?: ButtonType;
	children?: ReactNode;
	disabled?: boolean;
	fullSize?: boolean;
	onClick?: () => void;
	iconLeft?: string;
	iconRight?: string;
}

function CRIconButton({
	isLoading = false,
	style = {},
	buttonType = 'button',
	size = 'default',
	iconLeft,
	iconRight,
	children = '',
	palette = 'primary',
	type = 'filled',
	disabled = false,
	fullSize = false,
	onClick,
}: React.PropsWithChildren<IProps>) {
	return (
		<S.Container
			type={buttonType}
			$size={size}
			style={{
				...style,
				background: isLoading ? Colors.gray95 : undefined,
				color: isLoading ? Colors.gray60 : undefined,
			}}
			$palette={palette}
			$buttonType={type}
			$fullSize={fullSize}
			onClick={isLoading ? () => {} : onClick}
			disabled={disabled || isLoading}>
			{isLoading ? <CRSpinner floatMode={false} /> : iconLeft ? <S.Icon src={iconLeft} /> : null}
			{/* {iconLeft && <S.Icon src={iconLeft} />} */}
			{children}
			{iconRight && <S.Icon src={iconRight} />}
		</S.Container>
	);
}

export default CRIconButton;
