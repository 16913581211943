import React, { CSSProperties, ReactNode } from 'react';

import CRTable from 'components/base/CRTable';
import Colors from 'common/colors';

import * as S from './styles';

interface EDocFormRowProps {
	item: { [k: string]: ReactNode };
}

function EDocFormRow({ item }: EDocFormRowProps) {
	const customItem = { ...item };

	if (customItem?.inputs) {
		customItem.inputs = <S.TableAlign>{customItem.inputs}</S.TableAlign>;
	}
	const renderKeys = Object.keys(customItem);

	return (
		<CRTable.Row
			customStyle={{
				labelCol: {
					padding: 0,
				},
				description: {
					backgroundColor: Colors.gray95,
					textOverflow: 'unset',
					whiteSpace: 'pre-line',
					padding: '1rem',
					wordBreak: 'keep-all',
				},
				inputs: {
					padding: '1rem',
				},
				inputs2: {
					padding: '1rem',
				},
			}}
			item={customItem}
			renderKeys={renderKeys}
		/>
	);
}

interface EDocFormTableProps {
	children: ReactNode;
}

function EDocFormTableRoot({ children }: EDocFormTableProps) {
	const style: CSSProperties = {
		borderTop: `1px solid ${Colors.gray90}`,
		borderLeft: `1px solid ${Colors.gray90}`,
		borderRight: `1px solid ${Colors.gray90}`,
	};

	return <CRTable.Root style={style}>{children}</CRTable.Root>;
}

interface EDocFormTableInvisibleHeadProps {
	cols: { width: string }[];
}
function EDocFormTableInvisibleHead({ cols }: EDocFormTableInvisibleHeadProps) {
	return (
		<tr>
			{cols.map(({ width }, idx) => (
				// eslint-disable-next-line react/no-array-index-key
				<th key={idx} style={{ width, visibility: 'hidden' }} colSpan={1}>
					{' '}
				</th>
			))}
		</tr>
	);
}

interface EDocFormLabelColProps {
	children: ReactNode;
	rowSpan?: number;
	style?: CSSProperties;
}

function EDocStyledBody({ children, rowSpan, style }: EDocFormLabelColProps) {
	return (
		<CRTable.BodyStyled
			style={{
				background: Colors.gray95,
				width: '200px',

				...style,
			}}
			rowSpan={rowSpan}>
			{children}
		</CRTable.BodyStyled>
	);
}

export { EDocFormRow, EDocFormTableRoot, EDocFormTableInvisibleHead, EDocStyledBody };
