import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ko } from 'date-fns/esm/locale';

import Assets from 'assets';
import DatePicker from 'react-datepicker';
import CRInput from 'components/base/CRInput';
import dayjs from 'dayjs';
import CRButton from 'components/base/CRButton';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import * as Pop from '@radix-ui/react-popover';
import * as S from './styles';

interface IProps {
	title?: string;
	placeholder?: string;
	disabled?: boolean;
	value: {
		start: Date | null;
		end: Date | null;
	};
	onChangeValue?: (range: { start: Date | null; end: Date | null }) => void;
	minDate?: Date;
	maxDate?: Date;
	selected?: Date;
	monthsShown?: number;
	scrollToNowMonth?: boolean;
	showKorDay?: boolean;
}

enum DateFlag {
	Start = 'start',
	End = 'end',
}
function CRDateRangePicker({
	title,
	value,
	disabled,
	placeholder = '',
	onChangeValue,
	minDate,
	selected = dayjs().subtract(12, 'month').toDate(),
	maxDate,
	monthsShown = 13,
	scrollToNowMonth = true,
	showKorDay = false,
}: IProps): React.ReactElement {
	const nodeRef = useRef(null);
	const [date, setDate] = useState<{ start: Date | null; end: Date | null }>({
		start: null,
		end: null,
	});
	const [dateString, setDateString] = useState({
		start: '',
		end: '',
	});

	const [result, setResult] = useState<{
		start: string;
		end: string;
	}>({
		start: '',
		end: '',
	});

	const [showOptions, setShowOptions] = useState(false);

	const isValidate = useMemo(() => !!(date.start || (date.start && date.end)), [date]);
	const hasResult = useMemo(() => !!(result.start || (result.start && result.end)), [result]);

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);
	const nowMonthRef = useRef<HTMLSpanElement>(null);

	const getTotalMonth = () => {
		if (date.start && date.end) {
			const diff = dayjs(date.end).diff(dayjs(date.start), 'month') + 2;
			if (diff > 30) {
				return 30;
			}
			return diff > 9 ? diff : 10;
		}
		return 12;
	};

	const renderCustomHeader = (date: Date) => {
		const isCurrentMonth =
			date.getFullYear() === dayjs().year() && date.getMonth() === dayjs().month();
		return (
			<span ref={isCurrentMonth ? nowMonthRef : undefined}>{`${date.getFullYear()} ${
				date.getMonth() + 1
			}월`}</span>
		);
	};

	const onSelectDate = (dates: any) => {
		const [start, end] = dates;
		setDate({
			start,
			end,
		});
		setDateString({
			start: start ? dayjs(start).format('YYYYMMDD') : '',
			end: end ? dayjs(end).format('YYYYMMDD') : '',
		});
	};

	const onChangeDate = (value: string, flagType: DateFlag) => {
		const result = value.replaceAll('.', '');
		const updatedDate = { ...date };
		if (result.length === 8) {
			const parsedDate = dayjs(result);
			const isValid = parsedDate.isValid();

			if (isValid) {
				updatedDate[flagType] = parsedDate.toDate();
			} else {
				updatedDate[flagType] = null;
			}
		} else {
			updatedDate[flagType] = null;
		}

		setDate(updatedDate);

		// 날짜 문자열도 업데이트합니다.
		setDateString({
			...dateString,
			[flagType]: value,
		});
	};

	const renderText = () => {
		let resultText = `${result.start}`;

		if (result.end) {
			resultText += ` ~ ${result.end}`;
		} else {
			resultText += ` ~ 현재`;
		}
		return resultText;
	};

	const onClickApply = () => {
		if (isValidate) {
			onChangeValue?.(date);
			const korDay = ['일', '월', '화', '수', '목', '금', '토'];
			const start = `${dayjs(date.start).format('YY.MM.DD')}${
				showKorDay ? `(${korDay[dayjs(date.start).get('day')]})` : ''
			} `;
			const end = date.end
				? `${dayjs(date.end).format('YY.MM.DD')}${
						showKorDay ? `(${korDay[dayjs(date.end).get('day')]})` : ''
				  }`
				: '';

			setResult({
				start,
				end,
			});
		}
	};

	const renderCustomDay = (dayOfMonth: number, date: Date) => (
		<S.CustomDay>{dayOfMonth}</S.CustomDay>
	);

	const renderCalendarPopup = () => (
		<S.PopupContainer ref={nodeRef}>
			<S.CustomHeader>
				<S.Range>
					<CRInput.Default
						maxLength={8}
						type='number'
						onChange={(value) => onChangeDate(value, DateFlag.Start)}
						placeholder='시작일'
						value={dateString.start}
					/>
					<CRInput.Default
						type='number'
						maxLength={8}
						onChange={(value) => onChangeDate(value, DateFlag.End)}
						placeholder='종료일'
						value={dateString.end}
					/>
				</S.Range>
				<S.CustomCaption>
					{['일', '월', '화', '수', '목', '금', '토'].map((dayText) => (
						<S.CaptionText key={dayText}>{dayText}</S.CaptionText>
					))}
				</S.CustomCaption>
			</S.CustomHeader>
			<S.CalendarContainer ref={datePickerRef}>
				<DatePicker
					locale={ko}
					selectsRange
					selected={selected || date.start}
					startDate={date.start}
					endDate={date.end}
					onChange={(dates) => onSelectDate(dates)}
					shouldCloseOnSelect={false}
					minDate={minDate}
					maxDate={maxDate}
					inline
					renderCustomHeader={({ monthDate }) => (
						<S.CustomName>{renderCustomHeader(monthDate)}</S.CustomName>
					)}
					renderDayContents={renderCustomDay}
					monthsShown={monthsShown || getTotalMonth()}
				/>
			</S.CalendarContainer>
			<S.ButtonSection>
				<Pop.Close
					style={{
						background: 'transparent',
						border: 0,
						padding: 0,
						margin: 0,
						cursor: 'pointer',
					}}>
					<CRButton.Default type='text' palette='gray'>
						취소
					</CRButton.Default>
				</Pop.Close>
				{isValidate ? (
					<Pop.Close
						style={{
							background: 'transparent',
							border: 0,
							padding: 0,
							margin: 0,
							cursor: 'pointer',
						}}>
						<CRButton.Default
							type='filled'
							palette='primary'
							disabled={!isValidate}
							onClick={onClickApply}>
							적용
						</CRButton.Default>
					</Pop.Close>
				) : (
					<CRButton.Default
						type='filled'
						palette='primary'
						disabled={!isValidate}
						onClick={onClickApply}>
						적용
					</CRButton.Default>
				)}
			</S.ButtonSection>
		</S.PopupContainer>
	);

	useEffect(() => {
		if (nowMonthRef.current && scrollToNowMonth && showOptions) {
			nowMonthRef.current?.scrollIntoView();
		}
	}, [nowMonthRef, showOptions, scrollToNowMonth]);

	useEffect(() => {
		if (value) {
			setDate(value);
			setDateString({
				start: dayjs(value.start).format('YYYY.MM.DD'),
				end: value.end ? dayjs(value.end).format('YYYY.MM.DD') : '',
			});
			setResult({
				start: dayjs(value.start).format('YYYY.MM.DD'),
				end: value.end ? dayjs(value.end).format('YYYY.MM.DD') : '',
			});
		}
	}, [value]);

	return (
		<S.Container ref={filterRef}>
			<RdPopover
				showDivider={false}
				sideOffset={0}
				align='end'
				borderShape='small'
				openCallback={() => {
					setShowOptions(true);
					if (value) {
						setDate(value);
						setDateString({
							start: value.start ? dayjs(value.start).format('YYYYMMDD') : '',
							end: value.end ? dayjs(value.end).format('YYYYMMDD') : '',
						});
						const korDay = ['일', '월', '화', '수', '목', '금', '토'];
						const start = value.start
							? `${dayjs(value.start).format('YY.MM.DD')}${
									showKorDay ? `(${korDay[dayjs(value.start).get('day')]})` : ''
							  }`
							: '';
						const end = value.end
							? `${dayjs(value.end).format('YY.MM.DD')}${
									showKorDay ? `(${korDay[dayjs(value.end).get('day')]})` : ''
							  }`
							: '';

						setResult({
							start,
							end,
						});
					}
				}}
				closeCallback={() => {
					setDate({
						start: null,
						end: null,
					});
					setDateString({
						start: '',
						end: '',
					});
					setShowOptions(false);
				}}
				content={!disabled && renderCalendarPopup()}>
				<S.DefaultOption disabled={disabled}>
					<S.ChipLabel $isActive={disabled}>
						{hasResult ? renderText() : <S.PlaceHolder>{placeholder}</S.PlaceHolder>}
					</S.ChipLabel>
					<S.CalendarIcon src={Assets.icon.calendarToday} alt='calendarToday' />
				</S.DefaultOption>
			</RdPopover>
		</S.Container>
	);
}

export default CRDateRangePicker;
