import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-width: 102.4rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableRow = styled.div<{ $isHeader?: boolean }>`
	display: flex;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0 0.8rem;
	background: ${(props) => props.theme.colors.gray100};

	${(props) =>
		!props.$isHeader &&
		css`
			&:hover {
				cursor: pointer;
				background: ${(props) => props.theme.colors.gray99};
			}
		`};
`;

export const TableColumn = styled.div`
	height: 5.7rem;
	display: flex;
	align-items: center;
	padding: 1.6rem;
`;

export const TableBodyColumn = styled(TableColumn)`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
`;

export const TableHeaderColumn = styled(TableColumn)`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 0.8rem;
`;

export const QuestionMark = styled.img`
	width: 1.6rem;
	height: 1.6rem;
	object-fit: contain;
	margin-left: 0.4rem;
`;
