import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const TAG_AUTOMATION_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '8.8rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '12rem',
		label: '장기요양번호',
		sortKey: '장기요양번호',
	},
	{
		width: '8.8rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '9.6rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '9.6rem',
		label: '일자',
		sortKey: '일자',
	},
	{
		width: '9.6rem',
		label: '일정 상태',
		sortKey: '일정 상태',
	},
	{
		width: '15rem',
		label: '(원본) 일정계획시간',
		sortKey: '(원본) 일정계획시간',
	},
	{
		width: '15rem',
		label: '(수정) 일정계획시간',
		sortKey: '(수정) 일정계획시간',
	},
	{
		width: '11rem',
		label: '태그 상태',
		sortKey: '태그 상태',
	},
	{
		width: '11rem',
		label: '태그 유형',
		sortKey: '태그 유형',
	},
	{
		width: '11rem',
		label: '자동화 결과',
		sortKey: '자동화 결과',
	},
	{
		width: '12rem',
		label: '(원본) RFID',
		sortKey: '(원본) RFID',
	},
	{
		width: '12rem',
		label: '(수정) RFID',
		sortKey: '(수정) RFID',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const TAG_PLAN_EDIT_YN_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '정상',
		label: '정상',
		color: 'green',
	},
	{
		key: '수정',
		label: '수정',
		color: 'yellow',
	},
];

export const TAG_ERROR_TYPE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '정상',
		label: '정상',
		color: 'green',
	},
	{
		key: '확인필요',
		label: '확인필요',
		color: 'red',
	},
	{
		key: '수정',
		label: '수정',
		color: 'yellow',
	},
];

export const TAG_AUTOMATION_RESULT_CONFIG: CRStatusConfig[] = [
	{
		key: '자동화 완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
];

export const SALARY_RECORDING_PAPER = {
	crfName: 'template/CMN119_0054',
	params: [
		{
			recipientNm: '김주성',
			centerNm: '케어링 라온센터',
			centerMark: '21165000191',
			recipientBirthDt: '1947.07.21',
			longTermNo: 'L9910356272',
			longTermGradeNm: '2',
			year: '2023',
			schedule1Dt: '10/12',
			schedule1TotalMinute: '90',
			schedule1StartTime: '13:00',
			schedule1EndTime: '14:30',
			schedule1Chk1: '1',
			schedule1Chk2: '1',
			schedule1Chk3: '1',
			schedule1Chk4: '1',
			schedule1Chk5: '1',
			schedule1Chk6: '1',
			schedule1Minute1: '10',
			schedule1Minute2: '10',
			schedule1Minute3: '10',
			schedule1Minute4: '10',
			schedule1Minute5: '10',
			schedule1Chk7: '1',
			schedule1Chk8: '1',
			schedule1Minute6: '10',
			schedule1Chk9_1: '1',
			schedule1Chk9_2: '1',
			schedule1Chk9_3: '1',
			schedule1Chk10_1: '1',
			schedule1Chk10_2: '1',
			schedule1Chk10_3: '1',
			schedule1Chk11_1: '1',
			schedule1Chk11_2: '1',
			schedule1Chk11_3: '1',
			schedule1Mistake1: '1',
			schedule1Mistake2: '1',
			schedule1SpecialContent: '특이사항 내용입니다.',
			schedule1WorkerNm: '이대현',
			schedule1RecipientNm: '김주성',
			signSchedule1WorkerNm: '이대현',
			signSchedule1RecipientNm: '김주성',
			schedule2Dt: '10/13',
			schedule2TotalMinute: '90',
			schedule2StartTime: '13:00',
			schedule2EndTime: '14:30',
			schedule2Chk1: '1',
			schedule2Chk2: '1',
			schedule2Chk3: '1',
			schedule2Chk4: '1',
			schedule2Chk5: '1',
			schedule2Chk6: '1',
			schedule2Minute1: '10',
			schedule2Minute2: '10',
			schedule2Minute3: '10',
			schedule2Minute4: '10',
			schedule2Minute5: '10',
			schedule2Chk7: '1',
			schedule2Chk8: '1',
			schedule2Minute6: '10',
			schedule2Chk9_1: '1',
			schedule2Chk9_2: '1',
			schedule2Chk9_3: '1',
			schedule2Chk10_1: '1',
			schedule2Chk10_2: '1',
			schedule2Chk10_3: '1',
			schedule2Chk11_1: '1',
			schedule2Chk11_2: '1',
			schedule2Chk11_3: '1',
			schedule2Mistake1: '1',
			schedule2Mistake2: '1',
			schedule2SpecialContent: '특이사항 내용입니다.',
			schedule2WorkerNm: '이대현',
			schedule2RecipientNm: '김주성',
			signSchedule2WorkerNm: '이대현',
			signSchedule2RecipientNm: '김주성',
			schedule3Dt: '10/14',
			schedule3TotalMinute: '90',
			schedule3StartTime: '13:00',
			schedule3EndTime: '14:30',
			schedule3Chk1: '1',
			schedule3Chk2: '1',
			schedule3Chk3: '1',
			schedule3Chk4: '1',
			schedule3Chk5: '1',
			schedule3Chk6: '1',
			schedule3Minute1: '10',
			schedule3Minute2: '10',
			schedule3Minute3: '10',
			schedule3Minute4: '10',
			schedule3Minute5: '10',
			schedule3Chk7: '1',
			schedule3Chk8: '1',
			schedule3Minute6: '10',
			schedule3Chk9_1: '1',
			schedule3Chk9_2: '1',
			schedule3Chk9_3: '1',
			schedule3Chk10_1: '1',
			schedule3Chk10_2: '1',
			schedule3Chk10_3: '1',
			schedule3Chk11_1: '1',
			schedule3Chk11_2: '1',
			schedule3Chk11_3: '1',
			schedule3Mistake1: '1',
			schedule3Mistake2: '1',
			schedule3SpecialContent: '특이사항 내용입니다.',
			schedule3WorkerNm: '이대현',
			schedule3RecipientNm: '김주성',
			signSchedule3WorkerNm: '이대현',
			signSchedule3RecipientNm: '김주성',
			schedule4Dt: '10/15',
			schedule4TotalMinute: '90',
			schedule4StartTime: '13:00',
			schedule4EndTime: '14:30',
			schedule4Chk1: '1',
			schedule4Chk2: '1',
			schedule4Chk3: '1',
			schedule4Chk4: '1',
			schedule4Chk5: '1',
			schedule4Chk6: '1',
			schedule4Minute1: '10',
			schedule4Minute2: '10',
			schedule4Minute3: '10',
			schedule4Minute4: '10',
			schedule4Minute5: '10',
			schedule4Chk7: '1',
			schedule4Chk8: '1',
			schedule4Minute6: '10',
			schedule4Chk9_1: '1',
			schedule4Chk9_2: '1',
			schedule4Chk9_3: '1',
			schedule4Chk10_1: '1',
			schedule4Chk10_2: '1',
			schedule4Chk10_3: '1',
			schedule4Chk11_1: '1',
			schedule4Chk11_2: '1',
			schedule4Chk11_3: '1',
			schedule4Mistake1: '1',
			schedule4Mistake2: '1',
			schedule4SpecialContent: '특이사항 내용입니다.',
			schedule4WorkerNm: '이대현',
			schedule4RecipientNm: '김주성',
			signSchedule4WorkerNm: '이대현',
			signSchedule4RecipientNm: '김주성',
			schedule5Dt: '10/16',
			schedule5TotalMinute: '90',
			schedule5StartTime: '13:00',
			schedule5EndTime: '14:30',
			schedule5Chk1: '1',
			schedule5Chk2: '1',
			schedule5Chk3: '1',
			schedule5Chk4: '1',
			schedule5Chk5: '1',
			schedule5Chk6: '1',
			schedule5Minute1: '10',
			schedule5Minute2: '10',
			schedule5Minute3: '10',
			schedule5Minute4: '10',
			schedule5Minute5: '10',
			schedule5Chk7: '1',
			schedule5Chk8: '1',
			schedule5Minute6: '10',
			schedule5Chk9_1: '1',
			schedule5Chk9_2: '1',
			schedule5Chk9_3: '1',
			schedule5Chk10_1: '1',
			schedule5Chk10_2: '1',
			schedule5Chk10_3: '1',
			schedule5Chk11_1: '1',
			schedule5Chk11_2: '1',
			schedule5Chk11_3: '1',
			schedule5Mistake1: '1',
			schedule5Mistake2: '1',
			schedule5SpecialContent: '특이사항 내용입니다.',
			schedule5WorkerNm: '이대현',
			schedule5RecipientNm: '김주성',
			signSchedule5WorkerNm: '이대현',
			signSchedule5RecipientNm: '김주성',
			schedule6Dt: '10/17',
			schedule6TotalMinute: '90',
			schedule6StartTime: '13:00',
			schedule6EndTime: '14:30',
			schedule6Chk1: '1',
			schedule6Chk2: '1',
			schedule6Chk3: '1',
			schedule6Chk4: '1',
			schedule6Chk5: '1',
			schedule6Chk6: '1',
			schedule6Minute1: '10',
			schedule6Minute2: '10',
			schedule6Minute3: '10',
			schedule6Minute4: '10',
			schedule6Minute5: '10',
			schedule6Chk7: '1',
			schedule6Chk8: '1',
			schedule6Minute6: '10',
			schedule6Chk9_1: '1',
			schedule6Chk9_2: '1',
			schedule6Chk9_3: '1',
			schedule6Chk10_1: '1',
			schedule6Chk10_2: '1',
			schedule6Chk10_3: '1',
			schedule6Chk11_1: '1',
			schedule6Chk11_2: '1',
			schedule6Chk11_3: '1',
			schedule6Mistake1: '1',
			schedule6Mistake2: '1',
			schedule6SpecialContent:
				'특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.',
			schedule6WorkerNm: '이대현',
			schedule6RecipientNm: '김주성',
			signSchedule6WorkerNm: '이대현',
			signSchedule6RecipientNm: '김주성',
			schedule7Dt: '10/18',
			schedule7TotalMinute: '90',
			schedule7StartTime: '13:00',
			schedule7EndTime: '14:30',
			schedule7Chk1: '1',
			schedule7Chk2: '1',
			schedule7Chk3: '1',
			schedule7Chk4: '1',
			schedule7Chk5: '1',
			schedule7Chk6: '1',
			schedule7Minute1: '10',
			schedule7Minute2: '10',
			schedule7Minute3: '10',
			schedule7Minute4: '10',
			schedule7Minute5: '10',
			schedule7Chk7: '1',
			schedule7Chk8: '1',
			schedule7Minute6: '10',
			schedule7Chk9_1: '1',
			schedule7Chk9_2: '1',
			schedule7Chk9_3: '1',
			schedule7Chk10_1: '1',
			schedule7Chk10_2: '1',
			schedule7Chk10_3: '1',
			schedule7Chk11_1: '1',
			schedule7Chk11_2: '1',
			schedule7Chk11_3: '1',
			schedule7Mistake1: '1',
			schedule7Mistake2: '1',
			schedule7SpecialContent:
				'특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.',
			schedule7WorkerNm: '이대현',
			schedule7RecipientNm: '김주성',
			signSchedule7WorkerNm: '이대현',
			signSchedule7RecipientNm: '김주성',
		},
		{
			recipientNm: '김주성',
			centerNm: '케어링 라온센터',
			centerMark: '21165000191',
			recipientBirthDt: '1947.07.21',
			longTermNo: 'L9910356272',
			longTermGradeNm: '2',
			year: '2023',
			schedule1Dt: '10/12',
			schedule1TotalMinute: '90',
			schedule1StartTime: '13:00',
			schedule1EndTime: '14:30',
			schedule1Chk1: '1',
			schedule1Chk2: '1',
			schedule1Chk3: '1',
			schedule1Chk4: '1',
			schedule1Chk5: '1',
			schedule1Chk6: '1',
			schedule1Minute1: '10',
			schedule1Minute2: '10',
			schedule1Minute3: '10',
			schedule1Minute4: '10',
			schedule1Minute5: '10',
			schedule1Chk7: '1',
			schedule1Chk8: '1',
			schedule1Minute6: '10',
			schedule1Chk9_1: '1',
			schedule1Chk9_2: '1',
			schedule1Chk9_3: '1',
			schedule1Chk10_1: '1',
			schedule1Chk10_2: '1',
			schedule1Chk10_3: '1',
			schedule1Chk11_1: '1',
			schedule1Chk11_2: '1',
			schedule1Chk11_3: '1',
			schedule1Mistake1: '1',
			schedule1Mistake2: '1',
			schedule1SpecialContent: '특이사항 내용입니다.',
			schedule1WorkerNm: '이대현',
			schedule1RecipientNm: '김주성',
			signSchedule1WorkerNm: '이대현',
			signSchedule1RecipientNm: '김주성',
			schedule2Dt: '10/13',
			schedule2TotalMinute: '90',
			schedule2StartTime: '13:00',
			schedule2EndTime: '14:30',
			schedule2Chk1: '1',
			schedule2Chk2: '1',
			schedule2Chk3: '1',
			schedule2Chk4: '1',
			schedule2Chk5: '1',
			schedule2Chk6: '1',
			schedule2Minute1: '10',
			schedule2Minute2: '10',
			schedule2Minute3: '10',
			schedule2Minute4: '10',
			schedule2Minute5: '10',
			schedule2Chk7: '1',
			schedule2Chk8: '1',
			schedule2Minute6: '10',
			schedule2Chk9_1: '1',
			schedule2Chk9_2: '1',
			schedule2Chk9_3: '1',
			schedule2Chk10_1: '1',
			schedule2Chk10_2: '1',
			schedule2Chk10_3: '1',
			schedule2Chk11_1: '1',
			schedule2Chk11_2: '1',
			schedule2Chk11_3: '1',
			schedule2Mistake1: '1',
			schedule2Mistake2: '1',
			schedule2SpecialContent: '특이사항 내용입니다.',
			schedule2WorkerNm: '이대현',
			schedule2RecipientNm: '김주성',
			signSchedule2WorkerNm: '이대현',
			signSchedule2RecipientNm: '김주성',
			schedule3Dt: '10/14',
			schedule3TotalMinute: '90',
			schedule3StartTime: '13:00',
			schedule3EndTime: '14:30',
			schedule3Chk1: '1',
			schedule3Chk2: '1',
			schedule3Chk3: '1',
			schedule3Chk4: '1',
			schedule3Chk5: '1',
			schedule3Chk6: '1',
			schedule3Minute1: '10',
			schedule3Minute2: '10',
			schedule3Minute3: '10',
			schedule3Minute4: '10',
			schedule3Minute5: '10',
			schedule3Chk7: '1',
			schedule3Chk8: '1',
			schedule3Minute6: '10',
			schedule3Chk9_1: '1',
			schedule3Chk9_2: '1',
			schedule3Chk9_3: '1',
			schedule3Chk10_1: '1',
			schedule3Chk10_2: '1',
			schedule3Chk10_3: '1',
			schedule3Chk11_1: '1',
			schedule3Chk11_2: '1',
			schedule3Chk11_3: '1',
			schedule3Mistake1: '1',
			schedule3Mistake2: '1',
			schedule3SpecialContent: '특이사항 내용입니다.',
			schedule3WorkerNm: '이대현',
			schedule3RecipientNm: '김주성',
			signSchedule3WorkerNm: '이대현',
			signSchedule3RecipientNm: '김주성',
			schedule4Dt: '10/15',
			schedule4TotalMinute: '90',
			schedule4StartTime: '13:00',
			schedule4EndTime: '14:30',
			schedule4Chk1: '1',
			schedule4Chk2: '1',
			schedule4Chk3: '1',
			schedule4Chk4: '1',
			schedule4Chk5: '1',
			schedule4Chk6: '1',
			schedule4Minute1: '10',
			schedule4Minute2: '10',
			schedule4Minute3: '10',
			schedule4Minute4: '10',
			schedule4Minute5: '10',
			schedule4Chk7: '1',
			schedule4Chk8: '1',
			schedule4Minute6: '10',
			schedule4Chk9_1: '1',
			schedule4Chk9_2: '1',
			schedule4Chk9_3: '1',
			schedule4Chk10_1: '1',
			schedule4Chk10_2: '1',
			schedule4Chk10_3: '1',
			schedule4Chk11_1: '1',
			schedule4Chk11_2: '1',
			schedule4Chk11_3: '1',
			schedule4Mistake1: '1',
			schedule4Mistake2: '1',
			schedule4SpecialContent: '특이사항 내용입니다.',
			schedule4WorkerNm: '이대현',
			schedule4RecipientNm: '김주성',
			signSchedule4WorkerNm: '이대현',
			signSchedule4RecipientNm: '김주성',
			schedule5Dt: '10/16',
			schedule5TotalMinute: '90',
			schedule5StartTime: '13:00',
			schedule5EndTime: '14:30',
			schedule5Chk1: '1',
			schedule5Chk2: '1',
			schedule5Chk3: '1',
			schedule5Chk4: '1',
			schedule5Chk5: '1',
			schedule5Chk6: '1',
			schedule5Minute1: '10',
			schedule5Minute2: '10',
			schedule5Minute3: '10',
			schedule5Minute4: '10',
			schedule5Minute5: '10',
			schedule5Chk7: '1',
			schedule5Chk8: '1',
			schedule5Minute6: '10',
			schedule5Chk9_1: '1',
			schedule5Chk9_2: '1',
			schedule5Chk9_3: '1',
			schedule5Chk10_1: '1',
			schedule5Chk10_2: '1',
			schedule5Chk10_3: '1',
			schedule5Chk11_1: '1',
			schedule5Chk11_2: '1',
			schedule5Chk11_3: '1',
			schedule5Mistake1: '1',
			schedule5Mistake2: '1',
			schedule5SpecialContent: '특이사항 내용입니다.',
			schedule5WorkerNm: '이대현',
			schedule5RecipientNm: '김주성',
			signSchedule5WorkerNm: '이대현',
			signSchedule5RecipientNm: '김주성',
			schedule6Dt: '10/17',
			schedule6TotalMinute: '90',
			schedule6StartTime: '13:00',
			schedule6EndTime: '14:30',
			schedule6Chk1: '1',
			schedule6Chk2: '1',
			schedule6Chk3: '1',
			schedule6Chk4: '1',
			schedule6Chk5: '1',
			schedule6Chk6: '1',
			schedule6Minute1: '10',
			schedule6Minute2: '10',
			schedule6Minute3: '10',
			schedule6Minute4: '10',
			schedule6Minute5: '10',
			schedule6Chk7: '1',
			schedule6Chk8: '1',
			schedule6Minute6: '10',
			schedule6Chk9_1: '1',
			schedule6Chk9_2: '1',
			schedule6Chk9_3: '1',
			schedule6Chk10_1: '1',
			schedule6Chk10_2: '1',
			schedule6Chk10_3: '1',
			schedule6Chk11_1: '1',
			schedule6Chk11_2: '1',
			schedule6Chk11_3: '1',
			schedule6Mistake1: '1',
			schedule6Mistake2: '1',
			schedule6SpecialContent:
				'특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.',
			schedule6WorkerNm: '이대현',
			schedule6RecipientNm: '김주성',
			signSchedule6WorkerNm: '이대현',
			signSchedule6RecipientNm: '김주성',
			schedule7Dt: '10/18',
			schedule7TotalMinute: '90',
			schedule7StartTime: '13:00',
			schedule7EndTime: '14:30',
			schedule7Chk1: '1',
			schedule7Chk2: '1',
			schedule7Chk3: '1',
			schedule7Chk4: '1',
			schedule7Chk5: '1',
			schedule7Chk6: '1',
			schedule7Minute1: '10',
			schedule7Minute2: '10',
			schedule7Minute3: '10',
			schedule7Minute4: '10',
			schedule7Minute5: '10',
			schedule7Chk7: '1',
			schedule7Chk8: '1',
			schedule7Minute6: '10',
			schedule7Chk9_1: '1',
			schedule7Chk9_2: '1',
			schedule7Chk9_3: '1',
			schedule7Chk10_1: '1',
			schedule7Chk10_2: '1',
			schedule7Chk10_3: '1',
			schedule7Chk11_1: '1',
			schedule7Chk11_2: '1',
			schedule7Chk11_3: '1',
			schedule7Mistake1: '1',
			schedule7Mistake2: '1',
			schedule7SpecialContent:
				'특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.특이사항 내용입니다.',
			schedule7WorkerNm: '이대현',
			schedule7RecipientNm: '김주성',
			signSchedule7WorkerNm: '이대현',
			signSchedule7RecipientNm: '김주성',
		},
	],
};
