import CRScheduleCalendar from 'components/base/CRScheduleCalendar';
import dayjs from 'dayjs';
import { employeeScheduleAdapter } from 'lib/adapter/employee';
import { useMyAccountInfo, useSchedule } from 'lib/hook/react-query';
import React, { useState } from 'react';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import useDialog from 'lib/hook/util/useDialog';
import EmployeeScheduleDetailDialog from 'components/domain/dialog/EmployeeScheduleDetailDialog';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';

export default function EmployeeScheduleTab() {
	const [date, setDate] = useState(new Date());
	const { currentEmployee } = useEmployeePage();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: scheduleData, isLoading: scheduleDataLoading } = useSchedule(
		{
			type: 'employee',
			employeeId: currentEmployee?.employeeId,
			centerId: myAccountInfo?.centerId,
			yearMonth: dayjs(date).format('YYYYMM'),
		},
		employeeScheduleAdapter(myAccountInfo),
	);

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickDay = (date: Date) => {
		showDialog(() => (
			<EmployeeScheduleDetailDialog date={date} currentEmployee={currentEmployee} />
		));
	};

	return (
		<S.CalendarContainer>
			{scheduleDataLoading ? (
				<CRSpinner />
			) : (
				<CRScheduleCalendar
					type='employee'
					date={date}
					item={scheduleData}
					onChange={handleChangeDate}
					onClickDay={handleClickDay}
				/>
			)}
		</S.CalendarContainer>
	);
}
