import React from 'react';

import * as S from './styles';
import Assets from '../../../assets';

interface Props {
	sections?: {
		label: string;
		tags: {
			label: string;
			value: string | number;
		}[];
	}[];
	value?: {
		label: string;
		value: string | number;
	};
	onClick?: (item: { label: string; value: string | number }) => void;
}

function ConsultingTags({ sections = [], value, onClick }: Props): React.ReactElement {
	return (
		<S.Container>
			{sections.map((section) => (
				<S.SectionContainer key={section.label}>
					<S.SectionLabel>{section.label}</S.SectionLabel>
					<S.TagContainer>
						{section.tags.map((tag) => {
							const checked = value?.value === tag.value;
							return (
								<S.Tag key={tag.value} onClick={() => onClick?.(tag)} $checked={checked}>
									{checked && <S.CheckIcon src={Assets.icon.check} alt='check' />}
									{tag.label}
								</S.Tag>
							);
						})}
					</S.TagContainer>
				</S.SectionContainer>
			))}
		</S.Container>
	);
}

export default ConsultingTags;
