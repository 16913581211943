import React from 'react';

import * as S from './styles';

interface Props {
	label: string;
	value: string;
	suffix?: string;
}

function InformationBar({ label, value, suffix = '' }: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Label>{label}</S.Label>
			<S.Value>{value}</S.Value>
			<S.Suffix>{suffix}</S.Suffix>
		</S.Container>
	);
}

export default React.memo(InformationBar);
