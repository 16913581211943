import React from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRExcelUploader from 'components/base/CRInput/CRExcelUploader';

import * as S from './styles';

export default function ExcelUploadPage() {
	return (
		<S.Container>
			<S.ExcelUploaderContainer>
				<CRInputLabel label='센터'>
					<CRExcelUploader />
				</CRInputLabel>
			</S.ExcelUploaderContainer>
		</S.Container>
	);
}
