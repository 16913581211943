import React from 'react';

import * as S from './styles';

function IntegratedAssessmentDesireNursingCareStatusFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<colgroup>
				<col style={{ width: '20%' }} />
				<col style={{ width: '20%' }} />
				<col style={{ width: '60%' }} />
			</colgroup>
			<tr>
				<S.TableHeaderColumn colSpan={3}>확인</S.TableHeaderColumn>
			</tr>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesireNursingCareStatusFormHeader);
