import React from 'react';

import * as S from './styles';
import { InformationTableItemType } from './type';

interface Props {
	items: InformationTableItemType[][];
	roundBorder?: boolean;
}

function InformationTable({ items, roundBorder = false }: Props): React.ReactElement {
	const renderItem = (item: InformationTableItemType) => {
		if (item.type === 'labelValueNull') {
			return (
				<S.TableLabelColumn rowSpan={item.rowSpan} style={item.style} colSpan={item.colSpan ?? 2} />
			);
		}
		if (item.type === 'value') {
			return (
				<S.TableValueColumn
					title={typeof item.value === 'string' ? item.value : undefined}
					rowSpan={item.rowSpan}
					colSpan={item.colSpan}
					style={{ ...item.valueStyle }}
					onClick={item.onClick}
					$selected={item.selected}>
					{item.value}
				</S.TableValueColumn>
			);
		}
		if (item.type === 'label') {
			return (
				<S.TableLabelColumn
					rowSpan={item.rowSpan}
					colSpan={item.colSpan}
					style={{ ...item.labelStyle }}>
					{item.label}
				</S.TableLabelColumn>
			);
		}

		return (
			<>
				<S.TableLabelColumn
					rowSpan={item.labelRowSpan}
					colSpan={item.labelColSpan}
					style={{ maxWidth: '12rem', ...item.labelStyle }}>
					{item.label}
				</S.TableLabelColumn>
				<S.TableValueColumn
					title={typeof item.value === 'string' ? item.value : undefined}
					rowSpan={item.valueRowSpan}
					colSpan={item.valueColSpan}
					style={{ ...item.valueStyle }}
					$selected={item.selected}>
					{item.value}
				</S.TableValueColumn>
			</>
		);
	};

	const rowKeyExtractor = (rowIndex: number) => `row-${rowIndex}`;
	const colKeyExtractor = (rowIndex: number, colIndex: number) => `row-${rowIndex}-col-${colIndex}`;

	const renderTable = (component: JSX.Element) =>
		roundBorder ? <S.BorderContainer>{component}</S.BorderContainer> : component;

	return renderTable(
		<S.Table $roundBorder={roundBorder}>
			{items.map((cols, rowIndex) => (
				<S.TableRow key={rowKeyExtractor(rowIndex)}>
					{cols.map((col, colIndex) => (
						<React.Fragment key={colKeyExtractor(rowIndex, colIndex)}>
							{renderItem(col)}
						</React.Fragment>
					))}
				</S.TableRow>
			))}
		</S.Table>,
	);
}

export default React.memo(InformationTable);
