import React, { ChangeEvent, useRef } from 'react';

import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface Props {
	label?: string;
	onChange?: (files: File) => void;
}

function FileUploadButton({ label = '업로드', onChange }: Props): React.ReactElement {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		onChange?.(event.target.files[0]);
	};

	const onClickInput = () => {
		inputRef.current?.click();
	};

	return (
		<S.Container>
			<CRButton.Default type='outlined' palette='gray' size='xSmall' onClick={onClickInput}>
				{label}
			</CRButton.Default>
			<input
				ref={inputRef}
				hidden
				type='file'
				accept='application/vnd.ms-excel'
				onChange={handleChangeFile}
			/>
		</S.Container>
	);
}

export default FileUploadButton;
