import { css, styled } from 'styled-components';

export const BorderContainer = styled.div`
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: hidden;
`;

export const Table = styled.table<{ $roundBorder: boolean }>`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	${(props) =>
		props.$roundBorder &&
		css`
			border-collapse: collapse;
			border-style: hidden;
		`};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	padding: 0.4rem 0.8rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableValueColumn = styled.td<{ $selected?: boolean }>`
	${(props) => props.theme.typography.label};
	padding: 0.4rem 0.8rem;
	background: ${(props) =>
		props.$selected ? props.theme.colors.primary95 : props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	white-space: pre-line;
`;
