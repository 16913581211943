import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import { RecipientEnroll } from 'lib';
import {
	useCheckRealName,
	useCheckRecipientRsdnNo,
	useCreateRecipient,
	useMyAccountInfo,
} from 'lib/hook/react-query';

import CRInputMessage from 'components/base/CRInputMessage';
import { Toast } from 'components/base/CRToast';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import Assets from 'assets';
import * as S from './styles';
import CRDialog from '../../../base/CRDialog';
import { endpoint } from '../../../../lib/service/Api/endpoint';

interface Props {
	onClickClose: () => void;
	onSuccess: (recipientId: number) => void;
}

export type RecipientEnrollForm = {
	username: string;
	residenceNumber: string;
	center: {
		label: string;
		value: any;
	};
};

function RecipientEnrollDialog({ onClickClose, onSuccess }: Props) {
	const {
		control,
		getValues,
		setValue,
		handleSubmit,
		setError,
		formState: { errors, isValid },
	} = useForm<RecipientEnrollForm>({
		resolver: yupResolver(RecipientEnroll),
		mode: 'onChange',
	});
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />,
	);
	const { data: myAccountInfo } = useMyAccountInfo();
	const [isDuplicateValid, setIsDuplicateValid] = useState<boolean | null>(null);
	const [isRealName, setIsRealName] = useState(false);
	const [didCheckRealName, setDidCheckRealName] = useState(false);

	const checkRecipientRsdnNoMutation = useCheckRecipientRsdnNo();
	const createRecipientMutation = useCreateRecipient(async (client, returnData) => {
		if (returnData) {
			onClickClose();
			Toast.success('수급자를 추가했습니다.');
			await client.invalidateQueries([endpoint.getRecipients.key]);
			onSuccess(returnData.recipientId);
		} else {
			Toast.error('수급자 등록에 실패했습니다. 잠시후 다시 시도해주세요.');
		}
	});

	const checkRealNameMutation = useCheckRealName();

	const handleClickCheckRealName = async () => {
		const rsdnNo = getValues('residenceNumber');
		const foreignerYn = ['5', '6', '7', '8'].includes(rsdnNo[6]);
		const result = await checkRealNameMutation.mutateAsync({
			name: getValues('username'),
			rsdnNo,
			foreignerYn,
		});
		setDidCheckRealName(true);

		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 수급자를 생성할 수 있습니다.' />,
			);
			setIsRealName(true);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
			setIsRealName(true);
		}
	};

	const handleClickCheckRsdnNo = async () => {
		if (!getValues('residenceNumber')) {
			setError('residenceNumber', {
				message: '입력값을 확인해주세요',
			});
		}
		if (!getValues('username')) {
			setError('username', {
				message: '입력값을 확인해주세요',
			});
		}
		const result = await checkRecipientRsdnNoMutation.mutateAsync({
			name: getValues('username'),
			rsdnNo: getValues('residenceNumber'),
			centerId: getValues('center.value'),
		});

		if (result?.isEmployeeDuplicated) {
			setBanner(<CRBanner type='error' title='동일한 주민번호로 이미 직원에 등록되어있습니다.' />);
			setIsDuplicateValid(false);
		} else if (!result?.isCenterDuplicated && !result?.isRecipientDuplicated) {
			setBanner(
				<CRBanner type='success' title='새로운 수급자입니다. 실명 확인을 진행해 주십시오.' />,
			);
			setIsDuplicateValid(true);
		} else if (!result?.isCenterDuplicated && result?.isRecipientDuplicated) {
			setBanner(
				<CRBanner
					type='warning'
					title={`${result.centerNm}에 이미 존재하는 수급자입니다. (센터 추가 등록)`}
				/>,
			);
			setIsDuplicateValid(true);
		} else if (result?.isCenterDuplicated && result?.isRecipientDuplicated) {
			setBanner(<CRBanner type='error' title='센터내에 이미 존재하는 수급자입니다.' />);
			setIsDuplicateValid(false);
		}
		// 최후의 예외처리
		else {
			setBanner(<CRBanner type='error' title='잠시 후 다시 시도해주세요.' />);
			setIsDuplicateValid(false);
		}

		setDidCheckRealName(false);
	};

	const isNewUser = useMemo(
		() =>
			checkRecipientRsdnNoMutation.data?.isCenterDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isRecipientDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isEmployeeDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isMemberDuplicated === false,
		[checkRecipientRsdnNoMutation],
	);

	const isOtherCenterDuplicated = useMemo(
		() =>
			checkRecipientRsdnNoMutation.data?.isCenterDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isEmployeeDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isRecipientDuplicated === true &&
			checkRecipientRsdnNoMutation.data?.isMemberDuplicated === true,
		[checkRecipientRsdnNoMutation],
	);

	const handleClickCreateRecipient = async () => {
		await createRecipientMutation.mutateAsync({
			name: getValues('username'),
			rsdnNo: getValues('residenceNumber'),
			centerId: getValues('center.value'),
		});
	};

	useEffect(() => {
		if (myAccountInfo) {
			setValue('center', {
				label: myAccountInfo.centerNm,
				value: myAccountInfo.centerId,
			});
		}
	}, [myAccountInfo]);

	const isAllValid = !!(
		Object.keys(errors)?.length === 0 &&
		isDuplicateValid &&
		(isOtherCenterDuplicated || isRealName)
	);

	useEffect(() => {
		setIsDuplicateValid(false);
		setIsRealName(false);
		setDidCheckRealName(false);
	}, [getValues('username'), getValues('residenceNumber')]);

	return (
		<CRDialog
			title='수급자 등록'
			body={
				<S.Content>
					<S.SubTitle>중복 조회를 하고 새로운 수급자를 생성하세요.</S.SubTitle>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
							if (errors.username?.message) {
								setIsDuplicateValid(false);
							}
							return (
								<CRInputLabel
									label='이름'
									isRequired
									message={
										errors.username?.message && (
											<CRInputMessage type='error'>{errors.username?.message ?? ''}</CRInputMessage>
										)
									}>
									<CRInput.Default
										status={errors.username?.message ? 'error' : 'default'}
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='이름 입력'
									/>
								</CRInputLabel>
							);
						}}
						name='username'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel
								label='주민등록번호'
								isRequired
								message={
									errors.residenceNumber?.message && (
										<CRInputMessage type='error'>
											{errors.residenceNumber?.message ?? ''}
										</CRInputMessage>
									)
								}>
								<div
									style={{
										position: 'relative',
									}}>
									<CRInput.Default
										type='number'
										maxLength={13}
										status={errors.residenceNumber?.message ? 'error' : 'default'}
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='주민등록번호 입력'
									/>
									<S.RightButtonContainer>
										<CRButton.Default
											size='xSmall'
											disabled={!isValid}
											palette='gray'
											type='outlined'
											onClick={handleClickCheckRsdnNo}
											buttonType='button'>
											중복 조회
										</CRButton.Default>
										<CRButton.Default
											size='xSmall'
											disabled={!isDuplicateValid || !isNewUser || didCheckRealName}
											palette='gray'
											type='filled'
											onClick={handleClickCheckRealName}
											buttonType='button'>
											실명 확인
										</CRButton.Default>
									</S.RightButtonContainer>
								</div>
							</CRInputLabel>
						)}
						name='residenceNumber'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='소속센터' isRequired>
								<CRInput.Selector
									disabled
									items={[
										{
											label: myAccountInfo?.centerNm ?? '',
											value: myAccountInfo?.centerId,
										},
									]}
									autoComplete
									onChangeValue={onChange}
									currentValue={value}
									placeholder='센터 선택'
								/>
							</CRInputLabel>
						)}
						name='center'
						control={control}
					/>
					{banner}
				</S.Content>
			}
			footer={
				<>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClickClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isAllValid}
						palette='primary'
						size='default'
						onClick={handleSubmit(handleClickCreateRecipient)}>
						등록
					</CRButton.Default>
				</>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default React.memo(RecipientEnrollDialog);
