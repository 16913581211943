import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';
import { useForm } from 'react-hook-form';

import CRPageHeader from 'components/base/CRPageHeader';
import CRPageFooter from 'components/base/CRPageFooter';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import IntegratedKmmseForm from 'components/domain/form/IntegratedKmmseForm';
import IntegratedAssessmentPreviousDataDialog from 'components/domain/dialog/IntegratedAssessmentPreviousDataDialog';
import IntegratedKmmseRecipientInfoForm from 'components/domain/form/IntegratedKmmseRecipientInfoForm';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { Toast } from 'components/base/CRToast';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	useConfirmRecipientIntegratedAssessment,
	useDeleteRecipientIntegratedAssessment,
	useMyAccountInfo,
	useRecipientIntegratedAssessmentUpdateForm,
	useUpdateRecipientIntegratedAssessment,
} from 'lib/hook/react-query';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { flattenKmmseIntegratedAssessment, groupKmmseIntegratedAssessment } from 'lib/util/form';
import {
	IntegratedAssessmentKmmseForm,
	IntegratedAssessmentKmmseForms,
	IntegratedAssessmentType,
} from 'types/view/recipient';
import { IntegratedAssessmentFormDTO } from 'types/dto';

import { ResponseCode } from 'types/api';
import * as S from './styles';

function IntegratedAssessmentKmmseInfoPage(): React.ReactElement {
	const { showDialog, hideDialog, setCustomBackHandler } = useGlobalLayout();
	const form = useForm<IntegratedAssessmentKmmseForms>();
	const navigate = useNavigate();
	const params = useParams<{
		id: string;
		recipientIaId: string;
	}>();
	const { currentRecipient, currentRecipientInfoSummary } = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: previousData } = useRecipientIntegratedAssessmentUpdateForm({
		recipientIaId: params.recipientIaId ?? '',
		recipientId: params.id ?? '',
	});

	const isConfirmed = useMemo(
		() => previousData?.writeStateCd === 'CMN085.90',
		[previousData?.writeStateCd],
	);

	const deleteRecipientIntegratedAssessment = useDeleteRecipientIntegratedAssessment(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 삭제을 완료하였습니다.');
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessment.key,
					{
						recipientId: params.id,
						centerId: myAccountInfo?.centerId.toString(),
						targetYear: previousData?.targetYear,
					},
				]);
				hideDialog();
			} else {
				Toast.error('삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const updateRecipientIntegratedAssessment = useUpdateRecipientIntegratedAssessment(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 저장을 완료하였습니다.');
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessment.key,
					{
						recipientId: params.id,
						centerId: myAccountInfo?.centerId.toString(),
						targetYear: previousData?.targetYear,
					},
				]);
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessmentUpdateForm.key,
					{
						recipientIaId: params.recipientIaId ?? '',
						recipientId: params.id ?? '',
					},
				]);
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const confirmRecipientIntegratedAssessment = useConfirmRecipientIntegratedAssessment((client) => {
		client.invalidateQueries([
			endpoint.getRecipientIntegratedAssessment.key,
			{
				recipientId: params.id,
				centerId: myAccountInfo?.centerId.toString(),
				targetYear: previousData?.targetYear,
			},
		]);
		client.invalidateQueries([
			endpoint.getRecipientIntegratedAssessmentUpdateForm.key,
			{
				recipientIaId: params.recipientIaId ?? '',
				recipientId: params.id ?? '',
			},
		]);
	});

	const handleClickDelete = async () => {
		if (!params.recipientIaId) return;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='문서 삭제'
				hideDialog={hideDialog}
				content='인지기능 문서를 삭제하시겠습니까?'
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: async () => {
						await deleteRecipientIntegratedAssessment.mutateAsync({
							recipientIaId: Number(params.recipientIaId),
						});
						navigate(-1);
					},
				}}
			/>
		));
	};

	const totalPoint = useMemo(
		() =>
			(
				[
					'기억등록',
					'시간지남력',
					'장소기억력',
					'기억회상',
				] as (keyof IntegratedAssessmentKmmseForm)[]
			).reduce((accumulate, item) => {
				const value = form.getValues(item);
				return (
					accumulate +
					Object.keys(value ?? {}).filter((item) => (value as any)?.[item]?.checked).length
				);
			}, 0),
		[form.watch()],
	);

	const validateForm = (formItem: IntegratedAssessmentKmmseForms) => {
		if (!formItem.calendar?.length) {
			Toast.error('생년월일 양력/음력 기준을 입력해주세요.');
			return false;
		}
		if (!formItem.dementiaYn?.length) {
			Toast.error('치매여부를 입력해주세요.');
			return false;
		}
		if (!formItem.education) {
			Toast.error('학력정보를 입력해주세요.');
			return false;
		}
		if (!formItem.educationYear?.length) {
			Toast.error('교육연수를 입력해주세요.');
			return false;
		}
		if (!formItem.job?.length) {
			Toast.error('직업을 입력해주세요.');
			return false;
		}
		if (!formItem.writerNm?.length) {
			Toast.error('작성자를 입력해주세요.');
			return false;
		}
		if (!formItem.writeDate?.length) {
			Toast.error('작성일을 입력해주세요.');
			return false;
		}

		return true;
	};

	const handleClickCancel = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='인지기능 문서에 변경된 정보를 저장합니다.'
				hideDialog={hideDialog}
				successOption={{
					text: '저장',
					successCallback: async () => {
						hideDialog();
						if (form.getValues('writerNm')?.trim().length < 2) {
							Toast.error('작성자를 올바르게 입력해주세요.');
							return;
						}
						const formItem = form.getValues();
						if (!validateForm(formItem)) return;
						await updateRecipientIntegratedAssessment.mutateAsync({
							recipientIaId: Number(params.recipientIaId),
							evalDescTypeCd: form.getValues('type')?.[0]?.value,
							edulevelCd: form.getValues('education')?.value,
							eduYearCnt: Number(form.getValues('educationYear') || '0'),
							job: form.getValues('job'),
							dementiaYn: form.getValues('dementiaYn')?.[0]?.value,
							specialDesc: form.getValues('memo') ?? '',
							calendarKindCd: form.getValues('calendar')?.[0]?.value,
							totalPoint,
							writerNm: form.getValues('writerNm'),
							writeDate: form.getValues('writeDate'),
							content: {
								content: JSON.stringify(form.getValues()),
							},
						});

						navigate(-1);
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						navigate(-1);
					},
				}}
			/>
		));
	};

	const getContent = (formItem: IntegratedAssessmentKmmseForms) =>
		flattenKmmseIntegratedAssessment(formItem, [
			'type',
			'longTermEndDate',
			'longTermGradeCd',
			'longTermStartDate',
			'longTermNo',
			'recipientBirthDt',
			'recipientGenderCd',
			'recipientNm',
			'writeDate',
			'writerNm',
			'education',
			'dementiaYn',
			'educationYear',
			'job',
			'calendar',
			'memo',
		]);

	const handleClickSave = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;
		if (form.getValues('writerNm')?.trim().length < 2) {
			Toast.error('작성자를 올바르게 입력해주세요.');
			return;
		}

		const formItem = form.getValues();
		if (!validateForm(formItem)) return;

		const content = getContent(formItem);

		await updateRecipientIntegratedAssessment.mutateAsync({
			recipientIaId: Number(params.recipientIaId),
			evalDescTypeCd: form.getValues('type')?.[0]?.value,
			edulevelCd: form.getValues('education')?.value,
			eduYearCnt: Number(form.getValues('educationYear') || '0'),
			job: form.getValues('job'),
			dementiaYn: form.getValues('dementiaYn')?.[0]?.value,
			specialDesc: form.getValues('memo') ?? '',
			calendarKindCd: form.getValues('calendar')?.[0]?.value,
			totalPoint,
			writerNm: form.getValues('writerNm'),
			writeDate: form.getValues('writeDate'),
			content: {
				...content,
				REGIST: Object.keys(form.getValues('기억등록') ?? {}).filter(
					(item) => (form.getValues('기억등록') as any)?.[item]?.checked,
				).length,
				TIME: Object.keys(form.getValues('시간지남력') ?? {}).filter(
					(item) => (form.getValues('시간지남력') as any)?.[item]?.checked,
				).length,
				PLACE: Object.keys(form.getValues('장소기억력') ?? {}).filter(
					(item) => (form.getValues('장소기억력') as any)?.[item]?.checked,
				).length,
				RECALL: Object.keys(form.getValues('기억회상') ?? {}).filter(
					(item) => (form.getValues('기억회상') as any)?.[item]?.checked,
				).length,
			},
		});

		navigate(-1);
	};

	const handleClickConfirm = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;
		if (confirmRecipientIntegratedAssessment.isLoading) return;
		if (form.getValues('writerNm')?.trim().length < 2) {
			Toast.error('작성자를 올바르게 입력해주세요.');
			return;
		}
		const formItem = form.getValues();
		if (!validateForm(formItem)) return;

		const content = getContent(formItem);

		const confirmRequest = async () => {
			hideDialog();
			const res = await confirmRecipientIntegratedAssessment.mutateAsync({
				recipientIaId: Number(params.recipientIaId),
				evalDescTypeCd: form.getValues('type')?.[0]?.value,
				edulevelCd: form.getValues('education')?.value,
				eduYearCnt: Number(form.getValues('educationYear') || '0'),
				job: form.getValues('job'),
				dementiaYn: form.getValues('dementiaYn')?.[0]?.value,
				specialDesc: form.getValues('memo') ?? '',
				calendarKindCd: form.getValues('calendar')?.[0]?.value,
				writerNm: form.getValues('writerNm'),
				writeDate: form.getValues('writeDate'),
				totalPoint,
				content: {
					...content,
					REGIST: Object.keys(form.getValues('기억등록') ?? {}).filter(
						(item) => (form.getValues('기억등록') as any)?.[item]?.checked,
					).length,
					TIME: Object.keys(form.getValues('시간지남력') ?? {}).filter(
						(item) => (form.getValues('시간지남력') as any)?.[item]?.checked,
					).length,
					PLACE: Object.keys(form.getValues('장소기억력') ?? {}).filter(
						(item) => (form.getValues('장소기억력') as any)?.[item]?.checked,
					).length,
					RECALL: Object.keys(form.getValues('기억회상') ?? {}).filter(
						(item) => (form.getValues('기억회상') as any)?.[item]?.checked,
					).length,
				},
			});
			if (res === null) {
				return;
			}
			navigate(-1);
		};

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='확정'
				content={`${currentRecipient.name}(${currentRecipient.birthday}) 수급자의 인지기능 문서를 확정합니다.`}
				successOption={{
					text: '확정',
					successCallback: confirmRequest,
				}}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
			/>
		));
	};

	const loadFormFromData = (item: IntegratedAssessmentFormDTO) => {
		if (item?.content) {
			form.reset({
				기억등록: {
					기차: {
						value: '',
						checked: false,
					},
				},
				...groupKmmseIntegratedAssessment(item.content),
			});
		}
		if (item?.dementiaYn !== undefined) {
			form.setValue('dementiaYn', [{ value: item.dementiaYn, label: '' }]);
		}
		if (item?.eduYearCnt !== undefined) {
			form.setValue('educationYear', item.eduYearCnt.toString());
		}
		if (item?.edulevelCd) {
			form.setValue('education', { value: item.edulevelCd, label: '' });
		}
		if (item?.job) {
			form.setValue('job', item.job);
		}
		if (item?.calendarKindCd) {
			form.setValue('calendar', [{ value: item.calendarKindCd, label: '' }]);
		}
		if (item?.specialDesc) {
			form.setValue('memo', item.specialDesc);
		}

		if (previousData?.evalDescTypeCd) {
			form.setValue('type', [{ value: previousData.evalDescTypeCd, label: '' }]);
		}
		if (previousData?.writerNm) {
			form.setValue('writerNm', previousData.writerNm);
		}
		if (previousData?.writeDate) {
			form.setValue('writeDate', previousData.writeDate);
		}
		if (previousData?.recipientNm) {
			form.setValue('recipientNm', previousData.recipientNm);
		}
		if (previousData?.recipientBirthDt) {
			form.setValue('recipientBirthDt', previousData.recipientBirthDt);
		}
		if (previousData?.recipientGenderCd) {
			form.setValue('recipientGenderCd', previousData.recipientGenderCd);
		}
		if (previousData?.longTermStartDate) {
			form.setValue('longTermStartDate', previousData.longTermStartDate);
		}
		if (previousData?.longTermEndDate) {
			form.setValue('longTermEndDate', previousData.longTermEndDate);
		}
		if (previousData?.longTermGradeCd) {
			form.setValue('longTermGradeCd', previousData.longTermGradeCd);
		}
		if (previousData?.longTermNo) {
			form.setValue('longTermNo', previousData.longTermNo);
		}
	};

	const handleLoadPreviousData = (item: IntegratedAssessmentFormDTO) => {
		loadFormFromData(item);
	};

	const handleClickLoadPreviousData = async () => {
		showDialog(({ hideDialog }) => (
			<IntegratedAssessmentPreviousDataDialog
				title='인지기능 이력'
				hideDialog={hideDialog}
				recipientId={params.id}
				paperTypeCd={IntegratedAssessmentType.KmmseInfo}
				onClickLoad={handleLoadPreviousData}
			/>
		));
	};

	const forms = [
		{
			id: `#1`,
			label: '수급자 정보',
			component: <IntegratedKmmseRecipientInfoForm form={form} disabled={isConfirmed} />,
		},
		{
			id: `#2`,
			label: '인지기능',
			component: <IntegratedKmmseForm form={form} disabled={isConfirmed} />,
		},
	];

	useFullScreen();

	useEffect(() => {
		if (!previousData) return;

		loadFormFromData(previousData);
	}, [previousData]);

	useEffect(() => {
		setCustomBackHandler(() => handleClickCancel);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, []);

	return (
		<S.MainComponentContainer>
			<S.BodyContainer>
				<S.ContentContainer>
					<S.HeaderComponentContainer>
						<CRPageHeader
							headerTitle='인지기능'
							buttons={[
								{
									palette: 'primary',
									buttonType: 'button',
									type: 'outlined',
									onClick: handleClickDelete,
									children: '삭제',
								},
							]}
						/>
					</S.HeaderComponentContainer>
					<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
						{forms.map((form) => (
							<Accordion.Item key={form.id} value={form.id} asChild>
								<TaskAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
									</Accordion.Content>
								</TaskAccordion.Item>
							</Accordion.Item>
						))}
					</Accordion.Root>
				</S.ContentContainer>
			</S.BodyContainer>
			<S.FooterContainer>
				<S.FooterContentContainer>
					<CRPageFooter
						leftButtons={[
							{
								palette: 'gray',
								buttonType: 'button',
								type: 'outlined',
								onClick: handleClickLoadPreviousData,
								children: '불러오기',
							},
						]}
						rightButtons={
							isConfirmed
								? [
										{
											palette: 'gray',
											buttonType: 'button',
											type: 'text',
											onClick: () => navigate(-1),
											children: '확인',
										},
								  ]
								: [
										{
											palette: 'gray',
											buttonType: 'button',
											type: 'text',
											onClick: handleClickCancel,
											children: '취소',
										},
										{
											palette: 'gray',
											buttonType: 'button',
											type: 'outlined',
											onClick: handleClickSave,
											children: '저장',
										},
										{
											palette: 'primary',
											buttonType: 'button',
											type: 'filled',
											onClick: handleClickConfirm,
											children: '확정',
										},
								  ]
						}
					/>
				</S.FooterContentContainer>
			</S.FooterContainer>
		</S.MainComponentContainer>
	);
}

export default IntegratedAssessmentKmmseInfoPage;
