import styled from 'styled-components';

export const EmptyBox = styled.div`
	width: 100%;
	height: 72px;
`;

export const FixedContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	width: 100%;
	padding: 0.8rem 1rem;
	border-top: 1px solid ${(p) => p.theme.colors.gray90};
	background: ${(p) => p.theme.colors.white};
	position: fixed;
	max-width: 768px;
	margin: 0 auto;
	bottom: 0;
`;
