import React, { useCallback } from 'react';

import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import { useCommonCodes } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import { RecipientService } from 'types/view/recipient';

import dayjs from 'dayjs';
import { RECIPIENT_SERVICE_HEADER_CONFIG, RECIPIENT_SERVICE_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: RecipientService[];
	onClick?: (item: RecipientService) => void;
	onClickChangeEmployee?: (item: RecipientService) => void;
	onClickWithdrawal?: (item: RecipientService) => void;
}

function RecipientServiceTable({
	items = [],
	onClick,
	onClickChangeEmployee,
	onClickWithdrawal,
}: Props): React.ReactElement {
	const {
		data: commonCodes = {
			CMN079: [],
			CMN136: [],
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN079', 'CMN136', 'CMN006'] }, commonCodeAdapter);

	const Badge = useCallback(
		(children: string) => <CRStatus options={RECIPIENT_SERVICE_STATUS_CONFIG}>{children}</CRStatus>,
		[],
	);

	const ActionButton = useCallback((id: number, item?: RecipientService) => {
		if (!item) return <div />;
		return (
			<S.ButtonContainer>
				{/** 하드코딩 */}
				{item.status !== 'CMN079.90' && (
					<>
						<CRButton.Default
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={() => onClickChangeEmployee?.(item)}>
							직원 변경
						</CRButton.Default>
						<CRButton.Default
							size='xSmall'
							type='outlined'
							onClick={() => onClickWithdrawal?.(item)}>
							해지
						</CRButton.Default>
					</>
				)}
			</S.ButtonContainer>
		);
	}, []);

	const ServiceTypeComponent = useCallback(
		(status: string) => (
			<S.TextBox>{commonCodes.CMN136.find((item) => item.value === status)?.label}</S.TextBox>
		),
		[commonCodes],
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={RECIPIENT_SERVICE_HEADER_CONFIG} offset={1} disableBackBlind />
				{items.length ? (
					<CRTable.Body>
						{items.map((item, index) => (
							<CRTable.Row
								key={item.id}
								item={{ ...item, index: index + 1 }}
								renderKeys={[
									'index',
									'status',
									'serviceType',
									'service',
									'contractedDate',
									'periodOfUse',
									'employee',
									'center',
									'manager',
									'id',
								]}
								customRender={{
									status: Badge,
									id: ActionButton,
									serviceType: ServiceTypeComponent,
									contractedDate: (date) => dayjs(date).format('YYYY.MM.DD'),
								}}
								customStyle={{
									index: {
										textAlign: 'right',
									},
								}}
								onClick={onClick}
							/>
						))}
					</CRTable.Body>
				) : null}
			</CRTable.Root>
			{!items.length && <CRTable.Empty>급여 정보가 없습니다.</CRTable.Empty>}
		</S.Container>
	);
}

export default RecipientServiceTable;
