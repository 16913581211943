import styled from 'styled-components';

export const Container = styled.div<{ hasContents: boolean }>`
	display: inline-block;
	width: min-content;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.05rem 0.8rem;
	border-radius: 10rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray90};
	white-space: nowrap;
`;
