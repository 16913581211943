import { CRStatusConfig } from 'types/view/base';

export const APPROVAL_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '승인필요',
		color: 'yellow',
	},
	{
		key: '반려',
		color: 'red',
	},
	{
		key: '승인완료',
		color: 'green',
	},
	{
		key: '진행중',
		color: 'blue',
	},
];
