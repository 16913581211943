import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 67.5rem;
	box-sizing: border-box;
	gap: 2.4rem;
`;

export const Table = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	overflow-x: scroll;

	&,
	th,
	td {
		border: 0.1rem solid ${(props) => props.theme.colors.gray90};
		border-collapse: collapse;
	}
`;

export const TableHeader = styled.thead`
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableHeaderColumn = styled.th`
	${(props) => props.theme.typography.labelB}
	color: ${(props) => props.theme.colors.gray10};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
	height: 4.4rem;
	& + & {
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}
`;

export const TableHeaderColumnWithPadding = styled(TableHeaderColumn)`
	padding: 1.6rem;
	text-align: left;

	&:first-of-type {
		padding-left: 2.4rem;
	}

	&:last-of-type {
		padding-right: 2.4rem;
	}
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	cursor: pointer;
	height: 4.4rem;
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray00};
	white-space: pre-wrap;
	box-sizing: border-box;
	vertical-align: middle;
	padding: 0rem 1.6rem;

	&:first-of-type {
		border-left: none;
	}
`;

export const TableOptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.8rem;
	flex-wrap: wrap;
`;

export const TableContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.8rem;
	flex-wrap: wrap;
`;

export const TableContentVerticalContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	padding: 1.6rem 0rem;
`;
