import { useState } from 'react';

/**
 * 여러 섹션간을 이동하면서 폼 요소를 조작할때 사용하는 훅입니다.
 * @returns
 */
export const useSectionFocus = <T extends string>() => {
	// 현재 상호 작용중인 폼.
	const [currentEditForm, setCurrentEditForm] = useState<T>();
	// 다음에 상호작용할 폼
	// 다음 폼을 활성화 시킬때 값이 반영됩니다.
	const [nextEditForm, setNextEditForm] = useState<T>();

	// 다음 폼으로 이동을 시도할때 true가 됩니다.
	const isEditSectionChanging = Boolean(
		currentEditForm && nextEditForm && currentEditForm !== nextEditForm,
	);

	/**
	 * 현재 폼이 상호작용중 인지 확인하는 함수.
	 * @param editForm 현재 상호작용중인 폼
	 * @returns
	 */
	const isFormEditable = (editForm: T) => editForm === currentEditForm;

	/**
	 * 상호작용중인 섹션을 바꾸고 싶을때 사용하는 함수
	 *
	 * 사용법
	 * 	- 아무 섹션도 상호작용중이 아니라면 아래 함수에 원하는 폼 타입을 넣으면 됩니다
	 * 	- 하나의 섹션이 상호중이라면 다음에 상호작용할 폼타입을 넣습니다.
	 * 	- 다음에 상호작용할 폼은 nextEditForm에 저장되며 isEditSectionChanging이 true로 바뀝니다.
	 * 	- isEditSectionChanging의 true로 바뀌면 자식요소에서 필요한 로직을 수행시킵니다.
	 * 	- 로직 수행 후 이 함수를 다시 실행시키면 상호중인 폼이 변경됩니다.
	 *
	 * 주의사항
	 *  - 상호작용중인 섹션을 바꾸기 위해서는 이 함수를 2번 사용해야합니다
	 * 	- 최초 사용시 섹션이 바뀌는게 아니라 섹션을 변경중인 상태로 내부 상태가 바뀝니다.
	 * 	- 섹션 변경중인 상태에서 한번 더 함수를 실행시키면 조건에 따라서 활성화된 폼이 바뀝니다.
	 *
	 * @param editForm 다음에 상호작용할 폼
	 */
	const startChangeEditSection = (editForm: T) => {
		// 초기상태. 바로 원하는 폼을 상호작용중인 상태로 변경
		if (currentEditForm === undefined && nextEditForm === undefined) {
			setCurrentEditForm(editForm);
			setNextEditForm(undefined);
		}
		// 현재 상호작용중인 폼에 대해 토글 동작을 수행할때
		else if (currentEditForm === editForm && !isEditSectionChanging) {
			setCurrentEditForm(undefined);
			setNextEditForm(undefined);
		}
		// 현재 상호작용중인 폼이 있고 다음 상호작용중인 폼을 선택했을때 수행됨.
		// nextEditForm에 인자값을 저장. isEditSectionChanging이 바뀌게 됨.
		else if (currentEditForm && nextEditForm === undefined) {
			// setCurrentEditForm(editForm);
			setNextEditForm(editForm);
		}
		// 섹션이 변경중인 상태에서 한번 더 실행된경우 수행됨.
		// 이때 실제로 섹션이 바뀜
		else if (currentEditForm && nextEditForm) {
			setCurrentEditForm(nextEditForm);
			setNextEditForm(undefined);
		}
		// 최후의 예외처리.
		// 바로 섹션을 바꿔버림
		else {
			setCurrentEditForm(editForm);
			setNextEditForm(undefined);
		}
	};

	/*
	 * 바로 섹션을 바꿔주는 함수
	 */
	const setEditForm = (editForm?: T) => {
		setCurrentEditForm(editForm);
		setNextEditForm(undefined);
	};

	/*
	 * 섹션 변경중인 상태에서 취소를 할때 사용하는 함수.
	 */
	const cancelChangeEditSection = () => {
		setNextEditForm(undefined);
	};

	/*
	 * 폼 활성화 상태 초기화
	 */
	const resetSection = () => {
		setCurrentEditForm(undefined);
		setNextEditForm(undefined);
	};

	return {
		isFormEditable,
		isEditSectionChanging,
		startChangeEditSection,
		cancelChangeEditSection,
		currentEditForm,
		setEditForm,
		resetSection,
	};
};
