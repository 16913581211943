import React from 'react';
import { createPortal } from 'react-dom';

interface IProps {
	id?: 'root' | 'task_layout_portal';
	children: React.ReactNode;
}

function CRPortal({ children, id = 'root' }: IProps): React.ReactElement {
	const el = document.getElementById(id) as HTMLElement;

	if (!el) return <div />;

	return <>{createPortal(children, el)}</>;
}

export default CRPortal;
