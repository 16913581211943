import { STAFF_MOCK } from 'pages/dashboard/ApprovalPage/DocumentDetailPage/mock';
import { ApprovalStatus } from 'types/view/approval';
import { Role } from 'types/view/user';

export interface Employee {
	id: string;
	imgUrl: string;
	name: string;
	center: string;
	birth: string;
	statusInfo?: {
		status: ApprovalStatus;
		reason: string;
	};
	role: Role;
}

export interface ReferrerItem {
	id: string;
	members: Employee[];
}

export const initEmployeeData: Employee[] = [
	STAFF_MOCK.CHAEWON,
	STAFF_MOCK.JUNGRYEOL,
	STAFF_MOCK.TAEYOON,
	STAFF_MOCK.TOTORO,
	STAFF_MOCK.JIEUN,
	STAFF_MOCK.MEERAY,
];

export const initReferrerData: Employee[] = [
	STAFF_MOCK.CHAEWON,
	STAFF_MOCK.JUNGRYEOL,
	STAFF_MOCK.TAEYOON,
	STAFF_MOCK.TOTORO,
];
