import { styled } from 'styled-components';

export const Container = styled.div`
	width: 48rem;

	.popover_close_button {
		border: none;
		padding: 0;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 0 2.4rem 2.4rem;
`;

export const Title = styled.div`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray00};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ButtonContainerLeft = styled.div``;

export const ButtonContainerRight = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.8rem;
`;
