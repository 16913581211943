import React, { useDeferredValue, useMemo, useRef, useState } from 'react';

import CRPortal from 'components/base/CRPortal';
import * as S from './styles';

export interface IDialogContext {
	showDialog: (
		render: (config: { hideDialog: () => void }) => React.ReactElement,
		option?: { onClickBackground?: () => void },
	) => void;
	hideDialog: () => void;
	clearDialog: () => void;
}

export const DialogContext = React.createContext<IDialogContext>({
	showDialog: () => {},
	hideDialog: () => {},
	clearDialog: () => {},
});

function Dialog({
	children,
	hideDialog,
}: React.PropsWithChildren<{ hideDialog: () => void }>): React.ReactElement {
	return (
		<S.Container>
			<S.Background onClick={hideDialog} />
			<S.DialogContainer>{children}</S.DialogContainer>
		</S.Container>
	);
}

function DialogProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const [components, setComponents] = useState<React.ReactElement[]>([]);
	const cachedComponents = useRef<React.ReactElement[]>([]);
	const [isShowing, setIsShowing] = useState(false);
	const [backgroundClickEventListener, setBackgroundClickEventListener] = useState<() => void>(
		() => {},
	);
	const deferredIsShowing = useDeferredValue(isShowing);

	const clearDialog = () => {
		cachedComponents.current = [];
		setComponents(cachedComponents.current);
		setIsShowing(false);
	};

	const hideDialog = () => {
		if (cachedComponents.current.length > 1) {
			const newComponents = [...cachedComponents.current];
			newComponents.pop();
			cachedComponents.current = newComponents;
			setComponents(cachedComponents.current);
		} else {
			clearDialog();
		}
	};

	const showDialog = (
		render: (config: { hideDialog: () => void }) => React.ReactElement,
		option?: { onClickBackground?: () => void },
	) => {
		const newComponent = render({ hideDialog });
		setBackgroundClickEventListener(() => option?.onClickBackground || null);
		cachedComponents.current = cachedComponents.current?.length
			? [...cachedComponents.current, newComponent]
			: [newComponent];
		setComponents(cachedComponents.current);
		setIsShowing(true);
	};

	const value = useMemo(() => ({ showDialog, hideDialog, clearDialog }), [showDialog, hideDialog]);

	return (
		<DialogContext.Provider value={value}>
			{children}
			{Boolean(deferredIsShowing && components && components?.length > 0) && (
				<CRPortal>
					{components?.map((component, index) => (
						// eslint-disable-next-line
						<Dialog key={`dialog_${index}`} hideDialog={backgroundClickEventListener}>
							{component}
						</Dialog>
					))}
				</CRPortal>
			)}
		</DialogContext.Provider>
	);
}

export default DialogProvider;
