import React, { useCallback, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import useSideModal from 'lib/hook/util/useSideModal';
import { Billing } from 'types/view/billing';

import InformationBar from 'components/ui/InformationBar';
import SmallTitleAccordion from 'components/ui/radix/accordion/SmallTitleAccordion';
import InformationSheet from 'components/ui/InformationSheet';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import CRStatus from 'components/base/CRStatus';

import RecipientDetailBasicInformationTable from 'components/ui/RecipientDetailBasicInformationTable';
import EmployeeInformationTable from 'components/domain/table/EmployeeInformationTable';
import * as S from './styles';
import { BILLING_TASK_PROGRESS_STATUS_CONFIG } from '../../table/BillingTaskTable/constant';
import {
	BILLING_SIDE_MODAL_TABLE_EDITABLE_HEAD_CONFIG,
	BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG,
	LONG_TERM_SALARY_EDITABLE_LIST_RENDER_KEY,
	LONG_TERM_SALARY_LIST_RENDER_KEY,
	REPORT_STATUS_CONFIG,
} from './constant';
import { LONG_TERM_SALARY_LIST_MOCK } from './mock';

interface Props {
	item: Billing;
}

function BillingTaskSideModal({ item }: Props): React.ReactElement {
	const [editableLongTermCareSalary, setEditableLongTermCareSalary] = useState(false);
	const { hideSideModal } = useSideModal();

	const ReportStatusBadge = useCallback(
		(children: string) => <CRStatus options={REPORT_STATUS_CONFIG}>{children}</CRStatus>,
		[],
	);

	const DisableButton = useCallback(
		(isDisabled?: boolean) =>
			isDisabled ? (
				<S.BaseButton src={Assets.icon.refresh} alt='refresh' />
			) : (
				<S.DisableButton src={Assets.icon.deleteForever} alt='deleteForever' />
			),
		[],
	);

	const ReportNotify = useCallback(
		(children: string) =>
			children ? (
				<S.ReportNotify>
					작성
					<S.ReportNotifyIcon src={Assets.icon.info} alt='info' />
				</S.ReportNotify>
			) : (
				<S.ReportNotify>-</S.ReportNotify>
			),
		[],
	);

	const handleClickEditLongTermCareSalary = () => {
		setEditableLongTermCareSalary(true);
	};

	const handleClickCancelLongTermCareSalaryEdit = () => {
		setEditableLongTermCareSalary(false);
	};

	const handleClickSaveEditLongTermCareSalaryEdit = () => {
		setEditableLongTermCareSalary(false);
	};

	const handleClickClose = () => hideSideModal();

	return (
		<S.Container>
			<S.Header>
				<S.Title>청구 상세</S.Title>
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={handleClickClose} />
			</S.Header>
			<S.Content>
				<S.TitleContainer>
					<CRStatus options={BILLING_TASK_PROGRESS_STATUS_CONFIG}>검토필요</CRStatus>
					<S.Title>김민수(300101) • 방문요양 • 오류</S.Title>
				</S.TitleContainer>
				<S.SummaryContainer>
					<InformationBar label='총 청구액' value='451,400원' suffix='(본인부담금: 142,000원)' />
					<InformationSheet
						items={[
							[{ label: '중증수급가산', value: '0원', suffix: '/ 970,000원(한도액)' }],
							[{ label: '산정 총액', value: '949,500원' }],
							[
								{ label: '사회복지사 가산금', value: '-' },
								{ label: '가산 점수', value: '-' },
							],
						]}
					/>
					<S.InformationContainer>
						<Accordion.Root type='multiple' defaultValue={['1', '2', '3']}>
							<Accordion.Item value='1' asChild>
								<SmallTitleAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<SmallTitleAccordion.Trigger>수급자 기본 정보</SmallTitleAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<SmallTitleAccordion.Content>
											<RecipientDetailBasicInformationTable />
										</SmallTitleAccordion.Content>
									</Accordion.Content>
								</SmallTitleAccordion.Item>
							</Accordion.Item>
							<Accordion.Item value='2' asChild>
								<SmallTitleAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<SmallTitleAccordion.Trigger>요양요원 정보 01</SmallTitleAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<SmallTitleAccordion.Content>
											<EmployeeInformationTable />
										</SmallTitleAccordion.Content>
									</Accordion.Content>
								</SmallTitleAccordion.Item>
							</Accordion.Item>
							<Accordion.Item value='3' asChild>
								<SmallTitleAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<SmallTitleAccordion.Trigger>요양요원 정보 02</SmallTitleAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<SmallTitleAccordion.Content>
											<EmployeeInformationTable />
										</SmallTitleAccordion.Content>
									</Accordion.Content>
								</SmallTitleAccordion.Item>
							</Accordion.Item>
						</Accordion.Root>
					</S.InformationContainer>
				</S.SummaryContainer>
				<S.SectionContainer>
					<S.SectionHeaderContainer>
						<S.SectionTitle>장기요양 급여 내역</S.SectionTitle>
						{editableLongTermCareSalary ? (
							<>
								<CRButton.Default
									type='outlined'
									palette='gray'
									onClick={handleClickCancelLongTermCareSalaryEdit}>
									취소
								</CRButton.Default>
								<CRButton.Default
									type='filled'
									palette='gray'
									onClick={handleClickSaveEditLongTermCareSalaryEdit}>
									반영
								</CRButton.Default>
							</>
						) : (
							<CRButton.Default
								type='outlined'
								palette='gray'
								onClick={handleClickEditLongTermCareSalary}>
								수정
							</CRButton.Default>
						)}
					</S.SectionHeaderContainer>
					<S.SalaryListContainer>
						<CRTable.InformationRoot>
							<CRTable.InformationHead
								background={Colors.gray99}
								heads={
									editableLongTermCareSalary
										? BILLING_SIDE_MODAL_TABLE_EDITABLE_HEAD_CONFIG
										: BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG
								}
								borderBottom={`0.1rem solid ${Colors.gray90}`}
							/>
							<CRTable.Body>
								{editableLongTermCareSalary && (
									<CRTable.InformationRow
										item={{ button: '' }}
										renderKeys={['', 'button']}
										customRender={{
											button: (value: string | undefined) => (
												<CRButton.Default palette='primary' type='text' size='xSmall'>
													+ 일정추가
												</CRButton.Default>
											),
										}}
										mergeConfig={{
											button: {
												colSpan: 6,
											},
										}}
									/>
								)}
								{LONG_TERM_SALARY_LIST_MOCK.map((item, index) => (
									<CRTable.InformationRow
										item={item}
										renderKeys={
											editableLongTermCareSalary
												? LONG_TERM_SALARY_EDITABLE_LIST_RENDER_KEY
												: LONG_TERM_SALARY_LIST_RENDER_KEY
										}
										customStyle={{
											rfidSendingDetail: {
												textAlign: 'right',
											},
											subtractionRate: {
												textAlign: 'right',
											},
											amount: {
												textAlign: 'right',
											},
											isDisabled: {
												padding: 0,
												textAlign: 'center',
											},
										}}
										style={
											item.isDisabled
												? { background: Colors.gray95, color: Colors.gray60 }
												: undefined
										}
										customRender={{
											reportStatus: ReportStatusBadge,
											reportNotify: ReportNotify,
											isDisabled: DisableButton,
										}}
										borderBottom={
											index === LONG_TERM_SALARY_LIST_MOCK.length - 1 ? 'none' : undefined
										}
									/>
								))}
							</CRTable.Body>
						</CRTable.InformationRoot>
					</S.SalaryListContainer>
				</S.SectionContainer>
				<CRCheckBox>위 내용 대로 공단에 직접 청구 하였습니다.</CRCheckBox>
			</S.Content>
			<S.ButtonContainer>
				<CRButton.Default>저장</CRButton.Default>
				<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
					취소
				</CRButton.Default>
			</S.ButtonContainer>
		</S.Container>
	);
}

export default BillingTaskSideModal;
