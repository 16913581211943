import React from 'react';
import CRSwitch from 'components/base/Selections/CRSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import * as S from '../styles';
import { EDocFormRow, EDocFormTableRoot, EDocFormTableInvisibleHead } from '../EDocFormTable';
import { VisitNursingRecordFormFields } from '../types';

interface Props {
	disabled?: boolean;
}

export function VisitNursingRecordForm({ disabled }: Props) {
	const { control } = useFormContext<VisitNursingRecordFormFields>();

	return (
		<S.FormContainer>
			<S.SectionWrapper>
				<S.Container1>
					<S.Container2>
						<CRText typography='h3' color='red'>
							1) 건강관리
						</CRText>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '30%' }, { width: '70%' }]} />
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='혈압' />,
									inputs: (
										<S.TableAlign $textAlign='left' $padding='0 5%'>
											<S.InputBloodPressureContainer>
												<Controller
													name='bloodPressureStart'
													control={control}
													render={({ field }) => (
														<CRInput.RectangleInput
															{...field}
															disabled={disabled}
															width='77px'
															placeholder='최저혈압'
															maxLength={3}
															textAlign='center'
															max={999}
															type='number'
														/>
													)}
												/>
												<span>{' / '}</span>
												<Controller
													name='bloodPressureEnd'
													control={control}
													render={({ field }) => (
														<CRInput.RectangleInput
															{...field}
															disabled={disabled}
															width='77px'
															placeholder='최고혈압'
															maxLength={3}
															textAlign='center'
															max={999}
															type='number'
														/>
													)}
												/>
											</S.InputBloodPressureContainer>
										</S.TableAlign>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='맥박' />,
									inputs: (
										<Controller
											name='pulse'
											control={control}
											render={({ field }) => (
												<S.TableAlign $textAlign='left' $padding='0 5%'>
													<CRInput.RectangleInput
														{...field}
														disabled={disabled}
														width='77px'
														placeholder='0'
														suffix={<CRText typography='body' text='bpm' />}
														maxLength={3}
														textAlign='center'
														max={999}
														type='number'
													/>
												</S.TableAlign>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='체온' />,
									inputs: (
										<Controller
											name='temperature'
											control={control}
											render={({ field }) => (
												<S.TableAlign $textAlign='left' $padding='0 5%'>
													<CRInput.RectangleInput
														{...field}
														disabled={disabled}
														width='77px'
														placeholder='0'
														suffix={<CRText typography='body' text='℃' />}
														maxLength={2}
														textAlign='center'
														max={99}
														type='number'
													/>
												</S.TableAlign>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>
				</S.Container1>
				<S.Container1>
					<S.Container2>
						<S.labelWrapper>
							<CRIcon src={Assets.icon.assistWalker} alt='신체활동' />
							<CRText typography='h4'>건강관리</CRText>
						</S.labelWrapper>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='제공시간' />,
									inputs: (
										<Controller
											name='healthcareMinute'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													max={999}
													type='number'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='관절구축예방' />,
									inputs: (
										<Controller
											name='healthcareChk1'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='투약관리' />,
									inputs: (
										<Controller
											name='healthcareChk2'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='기초건강관리' />,
									inputs: (
										<Controller
											name='healthcareChk3'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='인지훈련' />,
									inputs: (
										<Controller
											name='healthcareChk4'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>
				</S.Container1>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<S.Container2>
					<CRText typography='h3' color='red'>
						2) 간호처치
					</CRText>
					<EDocFormTableRoot>
						<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='제공시간' />,
								inputs: (
									<Controller
										name='nursingcareMinute'
										control={control}
										render={({ field }) => (
											<CRInput.RectangleInput
												{...field}
												disabled={disabled}
												placeholder='0'
												suffix={<CRText typography='body' text='분' />}
												maxLength={3}
												textAlign='center'
												max={999}
												type='number'
											/>
										)}
									/>
								),
							}}
						/>
					</EDocFormTableRoot>
					<EDocFormTableRoot>
						<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='욕창관리' />,
								inputs: (
									<Controller
										name='nursingcareChk1'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='영양관리' />,
								inputs: (
									<Controller
										name='nursingcareChk2'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='통증관리' />,
								inputs: (
									<Controller
										name='nursingcareChk3'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='배설관리' />,
								inputs: (
									<Controller
										name='nursingcareChk4'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='당뇨발관리 등' />,
								inputs: (
									<Controller
										name='nursingcareChk5'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='호흡기 간호' />,
								inputs: (
									<Controller
										name='nursingcareChk6'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='투석 간호' />,
								inputs: (
									<Controller
										name='nursingcareChk7'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='구강간호' />,
								inputs: (
									<Controller
										name='nursingcareChk8'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
					</EDocFormTableRoot>
				</S.Container2>
			</S.SectionWrapper>
			<S.Container2>
				<CRText typography='h3' color='red'>
					3) 특이사항
				</CRText>
				<Controller
					name='specialContent'
					control={control}
					render={({ field }) => {
						const maxLength = 300;
						return (
							<CRInput.TextArea
								{...field}
								disabled={disabled}
								placeholder='특이사항을 작성해주세요'
								status={field?.value?.length > maxLength ? 'error' : 'default'}
								addOnBottom={`${maxLength}자까지 입력이 가능합니다.`}
								maxLength={maxLength}
							/>
						);
					}}
				/>
			</S.Container2>
		</S.FormContainer>
	);
}
