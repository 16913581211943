import { styled } from 'styled-components';

export const Container = styled.div`
	${(props) => props.theme.typography.label}
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const Badge = styled.span`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 0.6rem;
`;
