import { useContext } from 'react';
import { GlobalLayoutContext } from 'lib/provider/ui/Global';

export default function useGlobalLayout() {
	const context = useContext(GlobalLayoutContext);
	if (!context) {
		throw new Error('useGlobalLayout must be used within a GlobalLayoutContext');
	}
	return context;
}
