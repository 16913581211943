import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import Assets from 'assets';
import CRDropdown from 'components/base/CRDropdown';
import dayjs from 'dayjs';
import Draggable from 'react-draggable';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import * as Pop from '@radix-ui/react-popover';

import * as S from './styles';

interface Props {
	currentValue?: Date;
	placeholder?: string;
	disabled?: boolean;
	status?: 'default' | 'error';
	minYearMonth?: Date;
	onChangeValue?: (date: Date) => void;
}

function CRYearMonthPicker({
	currentValue,
	placeholder = '',
	disabled = false,
	status = 'default',
	minYearMonth,
	onChangeValue,
}: Props): React.ReactElement {
	const nodeRef = useRef(null);
	const [showOptions, setShowOptions] = useState(false);
	const [date, setDate] = useState<Date | null>(new Date());
	const [rangeDate, setRangeDate] = useState(new Date());

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);

	const handleToggleOptions = () => {
		setShowOptions((prev) => !prev);
	};

	const renderText = () => {
		let label = '';
		if (currentValue && dayjs(currentValue).isValid()) {
			label = dayjs(currentValue).format('YYYY년 M월');
		} else {
			label = placeholder;
		}
		return label;
	};

	const renderMonthCalendar = () => {
		const year = dayjs(rangeDate).get('year');
		const months = Array.from({ length: 12 })
			.fill(null)
			.map((e, i) =>
				dayjs(rangeDate)
					.set('month', i)
					.set('date', 1)
					.set('hour', 0)
					.set('minute', 0)
					.set('second', 0)
					.toDate(),
			);
		const caringStartDate = dayjs('2019.01.01').toDate();

		const onClickItem = (currentItemDate: Date) => {
			const isExist = date && dayjs(date).isSame(dayjs(currentItemDate));
			setDate(isExist ? null : currentItemDate);
			onChangeValue?.(currentItemDate);
			setShowOptions(false);
		};

		const isBeforeCaringStartDate = dayjs(rangeDate).subtract(1, 'year').isBefore(caringStartDate);

		return (
			<S.MonthYearWrapper>
				<S.MonthYearHeader>
					<S.MonthYearIcon
						src={Assets.icon.keyboardArrowLeft}
						alt='이전달'
						onClick={() =>
							!isBeforeCaringStartDate &&
							setRangeDate(dayjs(rangeDate).subtract(1, 'year').toDate())
						}
					/>
					<S.YearText>{year}</S.YearText>

					<S.MonthYearIcon
						src={Assets.icon.keyboardArrowRight}
						alt='다음달'
						onClick={() => setRangeDate(dayjs(rangeDate).add(1, 'year').toDate())}
					/>
				</S.MonthYearHeader>
				<S.MonthYearContainer>
					{[0, 1, 2].map((index) => (
						<S.MonthYearWeek key={index}>
							{months.slice(index * 4, index * 4 + 4).map((month) => {
								const targetDate = dayjs(month);
								const isActive = !!(dayjs(month).format('YYYYMM') === dayjs(date).format('YYYYMM'));
								const isDisabled = minYearMonth
									? !(
											dayjs(targetDate).isSame(minYearMonth, 'month') ||
											dayjs(targetDate).isAfter(minYearMonth, 'month')
									  )
									: false;
								return isDisabled ? (
									<S.MonthMonthYearItem
										$isDisabled={isDisabled}
										key={String(month)}
										$isActive={isActive}
										onClick={() => !isDisabled && onClickItem(month)}>
										{`${dayjs(month).get('month') + 1}월`}
									</S.MonthMonthYearItem>
								) : (
									<Pop.Close
										style={{
											background: 'transparent',
											border: 0,
											padding: 0,
											margin: 0,
											cursor: 'pointer',
										}}>
										<S.MonthMonthYearItem
											$isDisabled={isDisabled}
											key={String(month)}
											$isActive={isActive}
											onClick={() => !isDisabled && onClickItem(month)}>
											{`${dayjs(month).get('month') + 1}월`}
										</S.MonthMonthYearItem>
									</Pop.Close>
								);
							})}
						</S.MonthYearWeek>
					))}
				</S.MonthYearContainer>
			</S.MonthYearWrapper>
		);
	};

	const renderCalendarPopup = () => (
		<Draggable defaultPosition={{ x: 0, y: 0 }} bounds='body' nodeRef={nodeRef}>
			<CRDropdown style={{ width: 'fit-content' }}>
				<S.CalendarContainer ref={datePickerRef}>{renderMonthCalendar()}</S.CalendarContainer>
			</CRDropdown>
		</Draggable>
	);

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!filterRef.current?.contains(e.target as Node)) {
				setShowOptions(false);
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, []);

	return (
		<S.Container ref={filterRef} $disabled={disabled}>
			<RdPopover
				showDivider={false}
				sideOffset={0}
				align='end'
				borderShape='small'
				content={!disabled && renderCalendarPopup()}>
				<S.DefaultOption
					$error={status === 'error'}
					$showOptions={showOptions}
					onClick={handleToggleOptions}
					$disabled={false}>
					<S.ChipLabel $isDisabled={disabled} $isActive={!!currentValue}>
						{renderText()}
					</S.ChipLabel>
					<S.CalendarIcon
						$isDisabled={disabled}
						src={Assets.icon.calendarToday}
						alt='calendarToday'
					/>
				</S.DefaultOption>
			</RdPopover>

			{/* {!disabled && showOptions && renderCalendarPopup()} */}
		</S.Container>
	);
}

export default CRYearMonthPicker;
