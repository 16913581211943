import React from 'react';

export default function useDebounceValue<T>(value: T, delay = 500) {
	const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
	React.useEffect(() => {
		/* eslint-disable */
		const handler: NodeJS.Timeout = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}
