import { styled } from 'styled-components';

export const Container = styled.div`
	padding: 2.4rem;
	min-height: 53.2rem;
`;
export const Title = styled.h4`
	${(props) => props.theme.typography.h4};
	color: ${(props) => props.theme.colors.gray10};
	margin-bottom: 1.6rem;
`;
export const AccordionContainer = styled.div`
	padding: 1.6rem 0;
	border-radius: 0.8rem;
	background-color: ${(props) => props.theme.colors.gray99};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
