import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
`;

export const Title = styled.h3`
	color: ${(props) => props.theme.colors.gray60};
	white-space: pre-wrap;
	text-align: center;
`;

export const Description = styled.div`
	color: ${(props) => props.theme.colors.gray60};
	white-space: pre-wrap;
	text-align: center;
`;
