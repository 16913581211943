import React, { useEffect, useMemo } from 'react';
import { CheckOption } from 'components/base/Selections/type';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { EmployeeDTO } from 'types/api/employee';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { OtherBankBookFormTypes } from 'types/view/eDoc';
import { EDocOtherBankDTO } from 'types/api/eDoc';
import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<OtherBankBookFormTypes, any, undefined>;
}

// 타명의 통장 신청서
export default function EDocOtherBankBookForm({
	templateCode,
	currentEmployee,
	formContext,
}: Props) {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	const relationOptions = useMemo(
		() =>
			((eDocBeforeIssueInfo as unknown as EDocOtherBankDTO)?.data.relCds ?? []).map(
				(item) =>
					({
						label: item.korComCdNm,
						value: item.comCdId,
					} as CheckOption),
			),
		[eDocBeforeIssueInfo],
	);

	const bankOptions = useMemo(
		() =>
			((eDocBeforeIssueInfo as unknown as EDocOtherBankDTO)?.data.bankCds ?? []).map(
				(item) =>
					({
						label: item.korComCdNm,
						value: item.comCdId,
					} as CheckOption),
			),
		[eDocBeforeIssueInfo],
	);

	useEffect(() => {
		if (eDocBeforeIssueInfo) {
			formContext.setValue('data', eDocBeforeIssueInfo);
		}
	}, [eDocBeforeIssueInfo]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='신청인' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: currentEmployee?.korMemberNm || '',
							value: currentEmployee?.employeeId,
						}}
						items={[
							{
								label: currentEmployee?.korMemberNm || '',
								value: currentEmployee?.employeeId,
							},
						]}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='은행명' isRequired>
							<CRInput.Selector
								currentValue={value}
								items={bankOptions}
								onChangeValue={onChange}
								placeholder='은행명 선택'
							/>
						</CRInputLabel>
					)}
					name='bank'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='계좌번호' isRequired>
							<CRInput.Default
								type='number'
								value={value}
								onChange={onChange}
								placeholder='계좌번호 입력'
							/>
						</CRInputLabel>
					)}
					name='accountNumber'
					control={formContext.control}
				/>
				<S.SubFormContainer>
					<S.SubTitle>희망계좌 명의인</S.SubTitle>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='성명' isRequired>
								<CRInput.Default value={value} onChange={onChange} placeholder='이름 입력' />
							</CRInputLabel>
						)}
						name='name'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='생년월일' isRequired>
								<CRInput.Default
									type='number'
									value={value}
									onChange={onChange}
									placeholder='예시) 19280301'
								/>
							</CRInputLabel>
						)}
						name='birthDt'
						control={formContext.control}
					/>

					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='연락처' isRequired>
								<CRInput.Default
									type='number'
									value={value}
									onChange={onChange}
									maxLength={11}
									placeholder='연락처 입력'
								/>
							</CRInputLabel>
						)}
						name='phoneNumber'
						control={formContext.control}
					/>
					<CRInputLabel label='본인과의 관계' isRequired>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInput.Selector
									currentValue={value}
									items={relationOptions}
									onChangeValue={onChange}
									placeholder='관계 선택'
								/>
							)}
							name='relation'
							control={formContext.control}
						/>
						{formContext.watch('relation')?.value === 'CMN038.99' && (
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInput.Default onChange={onChange} value={value} placeholder='관계 입력' />
								)}
								name='relationEtc'
								control={formContext.control}
							/>
						)}
					</CRInputLabel>
				</S.SubFormContainer>
				<CRInputLabel label='타인명의 계좌 이용사유' isRequired>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInput.TextArea
								onChange={onChange}
								value={value}
								placeholder='타인명의 계좌 이용 사유 입력'
							/>
						)}
						name='reason'
						control={formContext.control}
					/>
				</CRInputLabel>
			</S.SubFormContainer>
		</S.Container>
	);
}
