import React from 'react';

import * as S from './styles';

function IntegratedBedsoreFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn style={{ width: '14%' }}>구분</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '22%' }}>척도</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '60%' }}>내용</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '6%' }}>점수</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedBedsoreFormHeader);
