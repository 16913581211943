import { CRTableHeadConfig } from 'types/view/base';

export const STAFF_CAREER_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '20rem',
		label: '근무기간',
	},
	{
		width: '24rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		width: '55.2rem',
		label: '비고',
		sortKey: '비고',
	},
	{
		width: '12rem',
		label: '',
		sortKey: '',
	},
];
