import { useContext } from 'react';

import { DialogContext } from 'lib/provider/ui/Dialog';

export default function useDialog() {
	const context = useContext(DialogContext);
	if (!context) {
		throw new Error('useDialog must be used within a DialogContext');
	}
	return context;
}
