import RouterPath from 'common/router';
import {
	useCenterManagers,
	useCommonCodes,
	useEmployeePageBaseInfo,
	useEmployeePageDetailInfo,
	useEmployees,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { useSectionFocus } from 'lib/hook/util/useSectionFocus';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EmployeeBaseInfoDTO, EmployeeDTO, EmployeeDetailInfoDTO } from 'types/api/employee';
import { EmployeeBasicStatusFormType } from 'types/view/employee';
import { Filter } from 'types/view/filter';

export enum EditableEmployeeFormTypes {
	기본정보 = 'basicInfo',
	타근무 = 'otherCorpWork',
	임금 = 'ownSalary',
}

export type EditableForm = 'basicInfo' | 'otherWorkCorp' | 'payInfo' | '';
interface EmployeePageContextType {
	employeesLoading: boolean;
	currentTab: string;
	setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
	employees: EmployeeDTO[];
	currentEmployee?: EmployeeDTO;
	currentEmployeeBase?: EmployeeBaseInfoDTO | null;
	currentEmployeeWorkState: EmployeeBasicStatusFormType;
	employeeDetailData?: EmployeeDetailInfoDTO | null;
	employeeDetailLoading: boolean;
	setCurrentEmployee: React.Dispatch<React.SetStateAction<EmployeeDTO | undefined>>;
	searchText: string;
	setSearchText: React.Dispatch<React.SetStateAction<string>>;
	currentFilter: {
		stateFilter: { label: string; value: any }[];
		dutyFilter: { label: string; value: any }[];
		managerFilter: { label: string; value: any }[];
	};
	setCurrentFilter: React.Dispatch<
		React.SetStateAction<{
			stateFilter: { label: string; value: any }[];
			dutyFilter: { label: string; value: any }[];
			managerFilter: { label: string; value: any }[];
		}>
	>;
	stateFilter: Filter<{ label: string; value: any }>;
	dutyFilter: Filter<{ label: string; value: any }>;
	managerFilter: Filter<{ label: string; value: any }>;
	startChangeEditSection: (formType: EditableEmployeeFormTypes) => void;
	isEditSectionChanging?: boolean;
	isFormEditable: (formType: EditableEmployeeFormTypes) => boolean;
	currentEditForm?: EditableEmployeeFormTypes;
	cancelChangeEditSection: () => void;
	setEditForm: (formType?: EditableEmployeeFormTypes) => void;
}

export const EmployeePageContext = React.createContext<EmployeePageContextType | null>(null);

function EmployeePageContextProvider({ children }: { children: React.ReactNode }) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const navigate = useNavigate();
	const location = useLocation();
	const [currentTab, setCurrentTab] = useState('basic');
	const [searchText, setSearchText] = useState('');
	const [currentEmployee, setCurrentEmployee] = useState<EmployeeDTO>();
	const [currentFilter, setCurrentFilter] = useState<{
		stateFilter: { label: string; value: any }[];
		dutyFilter: { label: string; value: any }[];
		managerFilter: { label: string; value: any }[];
	}>({
		stateFilter: [{ label: '근무중', value: 'CMN068.10' }],
		dutyFilter: [],
		managerFilter: [],
	});
	const {
		startChangeEditSection,
		isEditSectionChanging,
		isFormEditable,
		currentEditForm,
		cancelChangeEditSection,
		setEditForm,
	} = useSectionFocus<EditableEmployeeFormTypes>();
	const params = useParams<{ id: string }>();

	const { data: employeesData, isLoading: employeesLoading } = useEmployees({
		centerIds: myAccountInfo?.centerId ? [myAccountInfo.centerId] : [],
	});

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const { data: employeeDetailData, isLoading: employeeDetailLoading } = useEmployeePageDetailInfo({
		centerId: Number(currentEmployee?.centerId),
		memberId: Number(currentEmployee?.memberId),
		employeeId: Number(currentEmployee?.employeeId),
	});

	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: Number(currentEmployee?.centerId),
		memberId: Number(currentEmployee?.memberId),
		employeeId: Number(currentEmployee?.employeeId),
	});

	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN068', 'CMN070'],
	});

	const stateCommonCodes = useMemo(
		() =>
			commonCodes?.filter((item) => item.comCdGroupNm === 'CMN068' && item.etcDesc2 === '1') ?? [],
		[commonCodes],
	);

	const dutyCommonCodes = useMemo(
		() =>
			commonCodes?.filter((item) => item.comCdGroupNm === 'CMN070' && item.comCdDesc === '1') ?? [],
		[commonCodes],
	);

	const employees = useMemo(
		() =>
			(employeesData ?? [])
				?.filter((item) => {
					const isMatchWorkState =
						!currentFilter.stateFilter?.length ||
						currentFilter.stateFilter?.some((filter) => filter.label === item.workStateNm);
					const isMatchDuty =
						!currentFilter.dutyFilter?.length ||
						currentFilter.dutyFilter?.some((filter) => item.dutyNms?.includes(filter.label));
					const isMatchManager =
						!currentFilter.managerFilter?.length ||
						currentFilter.managerFilter?.some(
							(filter) => String(filter.value) === String(item.managerId),
						);

					return isMatchWorkState && isMatchDuty && isMatchManager;
				})
				?.filter(
					(item) =>
						item.korMemberNm.toLowerCase().includes(searchText.toLowerCase()) ||
						item.mobilePhoneNo?.includes(searchText) ||
						item.birthDt?.includes(searchText.replace(/\./g, '')),
				),
		[employeesData, searchText, currentFilter],
	);

	const stateFilter = useMemo(
		() =>
			({
				key: 'stateFilter',
				type: 'multi',
				options: stateCommonCodes.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '상태',
			} as Filter<{ label: string; value: any }>),
		[stateCommonCodes],
	);

	const dutyFilter = useMemo(
		() =>
			({
				key: 'dutyFilter',
				type: 'multi',
				options: dutyCommonCodes.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '직무',
			} as Filter<{ label: string; value: any }>),
		[dutyCommonCodes],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options: (centerManagers ?? [])?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})),
				placeholder: '사회복지사',
			} as Filter<{ label: string; value: any }>),
		[centerManagers],
	);

	const currentEmployeeWorkState = useMemo(
		() =>
			({
				status: employeeDetailData?.workStateCd,
				manager: employeeDetailData?.manager,
			} as EmployeeBasicStatusFormType),
		[employeeDetailData],
	);

	useEffect(() => {
		const paths = location.pathname.split('/');
		const parsedCurrentTab = paths[3];
		if (parsedCurrentTab) {
			setCurrentTab(parsedCurrentTab);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (employeesData?.length) {
			const id = params.id ?? '';
			const initialEmployee =
				employeesData.find((item) => Number(item.employeeId) === Number(id)) ?? employeesData?.[0];

			setCurrentEmployee(initialEmployee);

			if (window.location.pathname === RouterPath.employee().tab.key) {
				navigate(
					`/${RouterPath.employee().tab.uniqueKey}/${initialEmployee.employeeId}/${currentTab}`,
				);
			}
		}
	}, [employeesData, location, params, currentTab]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev.managerFilter.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...prev.managerFilter,
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	const value = useMemo(
		() => ({
			employeeDetailLoading,
			employeesLoading,
			currentTab,
			setCurrentTab,
			employees,
			currentEmployee,
			employeeDetailData,
			setCurrentEmployee,
			searchText,
			setSearchText,
			currentFilter,
			setCurrentFilter,
			stateFilter,
			dutyFilter,
			managerFilter,
			currentEmployeeBase,
			currentEmployeeWorkState,
			startChangeEditSection,
			isEditSectionChanging,
			isFormEditable,
			currentEditForm,
			cancelChangeEditSection,
			setEditForm,
		}),
		[
			currentEmployee,
			currentTab,
			employeesLoading,
			searchText,
			currentFilter,
			stateFilter,
			dutyFilter,
			managerFilter,
			currentEmployeeBase,
			employeeDetailData,
			currentEmployeeWorkState,
			employeeDetailLoading,
			startChangeEditSection,
			isEditSectionChanging,
			isFormEditable,
			currentEditForm,
			cancelChangeEditSection,
			setEditForm,
		],
	);

	return <EmployeePageContext.Provider value={value}>{children}</EmployeePageContext.Provider>;
}

export default EmployeePageContextProvider;
