import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const Title = styled.p`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const AddIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const ApprovalLineList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const ApprovalLineItem = styled.div<{ $hasItem?: boolean; $readOnly?: boolean }>`
	display: flex;
	gap: 1.6rem;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 1.6rem 2.4rem;
	height: 6.4rem;
	border-radius: 1.6rem;
	background: ${(props) =>
		props.$hasItem ? props.theme.colors.gray100 : props.theme.colors.gray95};

	cursor: ${(props) => (props.$readOnly ? 'auto' : 'pointer')};
`;

export const MessageContainer = styled.p<{ $hasItem?: boolean }>`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};

	${(props) =>
		props.$hasItem &&
		css`
			${(props) => props.theme.typography.label};
			color: ${(props) => props.theme.colors.gray00};
		`};
`;

export const ItemLeft = styled.div`
	display: flex;
	align-items: center;
	gap: 1.6rem;
`;

export const ItemRight = styled.div`
	display: flex;
	align-items: center;
`;

export const DeleteIcon = styled.img<{ $readOnly?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;

	${(props) =>
		props.$readOnly &&
		css`
			display: none;
		`}
`;
