import styled, { RuleSet, css } from 'styled-components';
import { InputStatus } from '../type';

type InputAttrObj = {
	[key in InputStatus]: RuleSet<object>;
};

const inputAttr: InputAttrObj = {
	default: css`
		box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
		background: ${(props) => props.theme.colors.gray100};
	`,
	focus: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.gray10} inset;
		background: ${(props) => props.theme.colors.gray99};
	`,
	error: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.primary60} inset;
		background: ${(props) => props.theme.colors.primary99};
	`,
	disabled: css`
		background: ${(props) => props.theme.colors.gray95};
	`,
};

export const Container = styled.div<{ $isDisabled?: boolean }>`
	width: 100%;
	cursor: ${(props) => (props.$isDisabled ? 'no-drop' : 'pointer')};
`;

export const InputContainer = styled.div<{
	$isFocused: boolean;
	$error: boolean;
	disabled: boolean;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1.4rem 1.6rem;
	height: 5.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
	border-radius: 0.8rem;
	margin: 0.4rem 0;
	gap: 0.8rem;

	& > input {
		${(props) => props.theme.typography.body};
		color: ${(props) => (!props.$error ? props.theme.colors.gray10 : props.theme.colors.primary60)};
		padding: 0;
		width: 100%;
		min-height: 2.8rem;
		background: transparent;
		border: none;
		outline: none;
		cursor: inherit;

		&::placeholder {
			${(props) => props.theme.typography.body};
			color: ${(props) => props.theme.colors.gray60};
		}

		&:disabled {
			color: ${(props) => props.theme.colors.gray60};
		}
	}

	${inputAttr.default};
	${(props) => props.$isFocused && inputAttr.focus};
	${(props) => props.$error && inputAttr.error};
	${(props) => props.disabled && inputAttr.disabled};
`;

export const Label = styled.label`
	${(props) => props.theme.typography.label};
`;

export const HelperText = styled.div<{ status: 'default' | 'error' }>`
	${(props) => props.theme.typography.label};
	color: ${(props) =>
		props.status === 'default' ? props.theme.colors.gray60 : props.theme.colors.primary60};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
	user-select: none;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const PrefixText = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
	white-space: nowrap;
`;

export const SuffixText = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
	white-space: nowrap;
`;
