import Colors from 'common/colors';
import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_SERVICE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '10rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8rem',
		label: '계약 유형',
		sortKey: '계약 유형',
	},
	{
		width: '12rem',
		label: '급여',
		sortKey: '급여',
	},
	{
		width: '14rem',
		label: '계약일',
		sortKey: '계약일',
	},
	{
		width: '22rem',
		label: '이용기간',
		sortKey: '이용기간',
	},
	{
		width: '8rem',
		label: '담당 직원',
		sortKey: '담당 직원',
	},
	{
		width: '8rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		width: '8rem',
		label: '담당자',
		sortKey: '담당자',
	},
	{
		width: '16rem',
		label: '',
		sortKey: '',
	},
];

export const RECIPIENT_SERVICE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: 'CMN079.10',
		label: '대기',
		color: 'yellow',
	},
	{
		key: 'CMN079.20',
		label: '계약진행중',
		color: 'yellow',
	},
	{
		key: 'CMN079.30',
		label: '이용중',
		color: 'green',
	},
	{
		key: 'CMN079.90',
		label: '종료',
		color: 'red',
	},
];
