import { CheckOption } from 'components/base/Selections/type';
import { MonitoringDetailsDTO } from 'types/api';

export enum MonitoringStatus {
	Done,
	InProgress,
	Pending,
}

export enum MonitoringDetailStatus {
	NEED_INSPECTION, // 검수 필요
	SUCCESS_INSPECTION, // 검수 완료
	SUCCESS_SAVE, // 저장완료
}

export type LoadMonitoringDataForm = {
	dataTypes: CheckOption[];
	yearMonth: Date;
};

export type MonitoringDataSyncForm = {
	yearMonth: Date;
};

export type MonitoringDetailForm = {
	scheduleMonitoringMemo: string;
	time: {
		workStartTime?: string;
		workEndTime?: string;
	};
	workConfirmYn?: CheckOption[];
	otherCenterWorkDuplConfirmYn?: CheckOption[];
	otherCorpWorkDuplConfirmYn?: CheckOption[];
	careIntervalWorkDuplConfirmYn?: CheckOption[];
	stateChangeRecordingSheetExpectYn?: CheckOption[];
	specialHoursRecordingSheetExpectYn?: CheckOption[];
	cognitiveProgramRecordingSheetExpectYn?: CheckOption[];
	biweeklyBathRecordingSheetExpectYn?: CheckOption[];
	scheduleMonitoringCompleteYn?: boolean;
	recogPlanTimeUnderOfferDescCd: CheckOption;
	carePlanTimeOverOfferDescCd: CheckOption;
	careMultipleUseOfferDescCd: CheckOption;
	visitCareFiveGradeOfferDescCd: CheckOption;
	bathOverOnceWeekOfferDescCd: CheckOption;
	recogPlanTimeUnderOfferDesc: string;
	carePlanTimeOverOfferDesc: string;
	careMultipleUseOfferDesc: string;
	visitCareFiveGradeOfferDesc: string;
	bathOverOnceWeekOfferDesc: string;
	etcSpecificDesc: string;
	data?: MonitoringDetailsDTO;
};
