import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	overflow: overlay;
`;

export const RecipientCustomContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.4rem;
	width: 100%;
	height: 100%;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const TextContainer = styled.div`
	& > div {
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray10};
	}
`;
