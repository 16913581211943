import React, { useMemo } from 'react';

import Assets from 'assets';
import CRStatus from 'components/base/CRStatus';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRTable from 'components/base/CRTable';
import Colors from 'components/../common/colors';
import InformationListSheet from 'components/ui/InformationListSheet';
import InformationBar from 'components/ui/InformationBar';
import useSideModal from 'lib/hook/util/useSideModal';
import { SalaryDetail } from 'types/view/salary';
import { CRStatusConfig } from 'types/view/base';

import * as S from './styles';

interface Props {
	item: SalaryDetail;
}

function SalaryDetailSideModal({ item }: Props): React.ReactElement {
	const { hideSideModal } = useSideModal();

	const statusConfig = useMemo(
		() =>
			[
				{
					key: '급여 예정',
					color: 'green',
				},
				{
					key: '이체 완료',
					color: 'green',
				},
				{
					key: '현금수령일 협의',
					color: 'green',
				},
				{
					key: '현금수령 예정',
					color: 'green',
				},
				{
					key: '현금수령 완료',
					color: 'green',
				},
			] as CRStatusConfig[],
		[],
	);

	const customRender = useMemo(
		() => ({
			value: (value: string | undefined) => <S.RightTextAlignment>{value}</S.RightTextAlignment>,
		}),
		[],
	);

	const updatableRender = useMemo(
		() => ({
			value: (value: string | undefined) => (
				<S.RightAlignment>
					<S.InputContainer>
						<S.Input value={value} placeholder='0' size={value?.length || 1} />
						<S.Suffix>원</S.Suffix>
					</S.InputContainer>
				</S.RightAlignment>
			),
		}),
		[],
	);

	const newFieldRender = useMemo(
		() => ({
			key: (value: string | undefined) => (
				<S.InputContainer>
					<S.KeyInput value={value} placeholder='항목 입력' size={value?.length || 6} />
				</S.InputContainer>
			),
			value: (value: string | undefined) => (
				<S.RightAlignment>
					<S.InputContainer>
						<S.Input value={value} placeholder='0' size={value?.length || 1} />
						<S.Suffix>원</S.Suffix>
					</S.InputContainer>
				</S.RightAlignment>
			),
		}),
		[],
	);

	const buttonRender = useMemo(
		() => ({
			button: (value: string | undefined) => (
				<CRButton.Default palette='primary' type='text' size='xSmall'>
					+ 내역 추가
				</CRButton.Default>
			),
		}),
		[],
	);

	const updatableCustomStyle = useMemo(
		() => ({
			value: {
				paddingRight: '1.1rem',
			},
		}),
		[],
	);

	const serviceCustomRender = useMemo(
		() => ({
			count: (value: string | undefined) => <S.RightTextAlignment>{value}</S.RightTextAlignment>,
			workingTime: (value: string | undefined) => (
				<S.RightAlignment>
					<S.InputContainer>
						<S.Input value={value} placeholder='0' size={value?.length || 1} />
						<S.Suffix>분</S.Suffix>
					</S.InputContainer>
				</S.RightAlignment>
			),
			hourlySalary: (value: string | undefined) => (
				<S.RightTextAlignment>{value}</S.RightTextAlignment>
			),
		}),
		[],
	);

	const onClickClose = () => hideSideModal();

	return (
		<S.Container>
			<S.Header>
				<S.Title>급여 상세</S.Title>
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={onClickClose} />
			</S.Header>
			<S.Content>
				<S.TitleContainer>
					<CRStatus options={statusConfig}>현금수령일 협의</CRStatus>
					<S.Title>
						23년 4월 • 김민수 수급자 (300101) • 김요보 직원 (640425) • 방문요양,방문간호
					</S.Title>
					<S.DescriptionContainer>
						<S.DescriptionItem>담당자 : 마음센터 김사복</S.DescriptionItem>
					</S.DescriptionContainer>
				</S.TitleContainer>
				<S.SummaryContainer>
					<InformationBar label='실수령액' value='451,000원' suffix='(총 급여: 493,080원)' />
					<InformationListSheet
						title='근로 정보'
						items={[
							{
								type: '가족요양 60분',
								totalWorkingTime: '40시간 30분(총 31일)',
								hourlySalary: '21,500원',
							},
							{
								type: '방문 간호',
								totalWorkingTime: '40시간 30분(총 31일)',
								hourlySalary: '21,500원',
							},
						]}
						config={[
							{
								key: 'type',
								label: (index: number) => (index < 11 ? `0${index + 1}` : index.toString()),
								width: '12rem',
							},
							{
								key: 'totalWorkingTime',
								label: '총 근무 시간',
								width: '16rem',
							},
							{
								key: 'hourlySalary',
								label: '시급',
								width: '16rem',
							},
						]}
					/>
				</S.SummaryContainer>
				<S.SectionContainer>
					<S.SectionTitle>급여 수령 내역</S.SectionTitle>
					<S.SectionContentContainer>
						<CRTable.InformationRoot>
							<CRTable.InformationHead
								heads={[
									{ label: '고정 월급', width: '61.6rem' },
									{ label: '13,700원', textAlign: 'right', width: '12rem' },
								]}
								borderBottom='none'
							/>
						</CRTable.InformationRoot>
						<S.SectionRowContentContainer>
							<CRTable.InformationRoot>
								<CRTable.InformationHead
									heads={[
										{ label: '지급 내역', width: '23.2rem' },
										{ label: '13,700원', textAlign: 'right', width: '12rem' },
									]}
									borderBottom={`0.1rem solid ${Colors.gray10}`}
								/>
								<CRTable.Body>
									<CRTable.InformationRow
										item={{ key: '기본급', value: '192,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '주휴수당', value: '38,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '연차수당', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '식대', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '기타수당', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationCollapsibleRow
										item={{
											key: '기타수당',
											value: '13,700원',
											details: [
												{ id: '1', key: '연장 근로수당', value: '0원' },
												{ id: '2', key: '야간 근로수당', value: '0원' },
												{ id: '2', key: '공휴일 근로수당', value: '0원' },
												{ id: '2', key: '주휴일 근로수당', value: '0원' },
											],
										}}
										renderKeys={['key', 'value']}
										detailRenderKeys={['key', 'value']}
										customRender={customRender}
										detailCustomRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고정야간수당', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고정휴일수당', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '근로자의날 수당', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
										borderBottom='none'
									/>
								</CRTable.Body>
							</CRTable.InformationRoot>
							<CRTable.InformationRoot>
								<CRTable.InformationHead
									heads={[
										{ label: '공제 내역', width: '23.2rem' },
										{ label: '13,700원', textAlign: 'right', width: '12rem' },
									]}
									borderBottom={`0.1rem solid ${Colors.gray10}`}
								/>
								<CRTable.Body>
									<CRTable.InformationRow
										item={{ key: '본인부담금', value: '192,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '운영비', value: '38,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '연말정산 공제액', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '국민연금', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '건강보험', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '노인장기요양보험', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고용보험', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '소득세', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '지방소득세', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '기타공제', value: '' }}
										renderKeys={['key', 'value']}
										customRender={updatableRender}
										customStyle={updatableCustomStyle}
									/>
									<CRTable.InformationRow
										item={{ key: '보완청구(23.02.12 15:00', value: '' }}
										renderKeys={['key', 'value']}
										customRender={updatableRender}
										customStyle={updatableCustomStyle}
									/>
								</CRTable.Body>
							</CRTable.InformationRoot>
						</S.SectionRowContentContainer>
						<S.SectionColumnContentContainer>
							<CRTable.InformationRoot>
								<CRTable.InformationHead
									heads={[
										{ label: '기타 내역', width: '61.6rem' },
										{ label: '13,700원', textAlign: 'right', width: '12rem' },
									]}
									borderBottom={`0.2rem solid ${Colors.gray10}`}
								/>
								<CRTable.Body>
									<CRTable.InformationRow
										item={{ key: '보완청구 (23.02.12 15:00~16:00 방문요양)', value: '192,400' }}
										renderKeys={['key', 'value']}
										customRender={updatableRender}
										customStyle={updatableCustomStyle}
									/>
									<CRTable.InformationRow
										item={{ key: '치매가족휴가제급여', value: '38,400' }}
										renderKeys={['key', 'value']}
										customRender={updatableRender}
										customStyle={updatableCustomStyle}
									/>
									<CRTable.InformationRow
										item={{ key: '장기근속장려금', value: '13,600' }}
										renderKeys={['key', 'value']}
										customRender={updatableRender}
										customStyle={updatableCustomStyle}
									/>
									<CRTable.InformationRow
										item={{ key: '식대', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '기타수당', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고정야간수당', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고정휴일수당', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '근로자의날 수당', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '', value: '' }}
										renderKeys={['key', 'value']}
										customRender={newFieldRender}
										customStyle={updatableCustomStyle}
									/>
									<CRTable.InformationRow
										item={{ button: '' }}
										renderKeys={['button']}
										customRender={buttonRender}
										mergeConfig={{
											button: {
												colSpan: 2,
											},
										}}
										customStyle={{
											button: {
												paddingLeft: '1.6rem',
											},
										}}
									/>
								</CRTable.Body>
							</CRTable.InformationRoot>
							<CRTable.InformationRoot>
								<CRTable.InformationHead
									heads={[
										{ label: '회사부담인건비', width: '61.6rem' },
										{ label: '13,700원', textAlign: 'right', width: '12rem' },
									]}
									borderBottom={`0.2rem solid ${Colors.gray10}`}
								/>
								<CRTable.Body>
									<CRTable.InformationRow
										item={{ key: '국민연금', value: '192,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '건강보험', value: '38,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '노인장기요양보험', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고용보험(퇴사실업급여)', value: '200,400원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '고용보험(회사고용안정)', value: '13,700원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '산재보험', value: '13,600원' }}
										renderKeys={['key', 'value']}
										customRender={customRender}
									/>
									<CRTable.InformationRow
										item={{ key: '배상책임보험액', value: '200,400' }}
										renderKeys={['key', 'value']}
										customRender={updatableRender}
										customStyle={updatableCustomStyle}
										borderBottom='none'
									/>
								</CRTable.Body>
							</CRTable.InformationRoot>
						</S.SectionColumnContentContainer>
					</S.SectionContentContainer>
				</S.SectionContainer>
				<S.SectionContainer>
					<S.SectionTitle>세부 근로 내역</S.SectionTitle>
					<CRTable.InformationRoot>
						<CRTable.InformationHead
							background={Colors.white}
							heads={[
								{ label: '서비스', width: '49.6rem', color: Colors.gray60 },
								{ label: '근무 시간', textAlign: 'right', width: '12rem', color: Colors.gray60 },
								{ label: '시급', textAlign: 'right', width: '12rem', color: Colors.gray60 },
							]}
							borderBottom={`0.1rem solid ${Colors.gray90}`}
						/>
						<CRTable.Body>
							<CRTable.InformationRow
								item={{
									service: '연장 근로',
									workingTime: '46.5',
									hourlySalary: '13,700원',
								}}
								renderKeys={['service', 'workingTime', 'hourlySalary']}
								customRender={serviceCustomRender}
								customStyle={{
									workingTime: {
										paddingRight: '0.4rem',
									},
								}}
							/>
							<CRTable.InformationRow
								item={{
									service: '야간 근로',
									workingTime: '46.5',
									hourlySalary: '13,700원',
								}}
								renderKeys={['service', 'workingTime', 'hourlySalary']}
								customRender={serviceCustomRender}
								borderBottom='none'
								customStyle={{
									workingTime: {
										paddingRight: '0.4rem',
									},
								}}
							/>
							<CRTable.InformationRow
								item={{
									service: '공휴일 근로',
									workingTime: '46.5',
									hourlySalary: '13,700원',
								}}
								renderKeys={['service', 'workingTime', 'hourlySalary']}
								customRender={serviceCustomRender}
								borderBottom='none'
								customStyle={{
									workingTime: {
										paddingRight: '0.4rem',
									},
								}}
							/>
							<CRTable.InformationRow
								item={{
									service: '주휴일 근로',
									workingTime: '46.5',
									hourlySalary: '13,700원',
								}}
								renderKeys={['service', 'workingTime', 'hourlySalary']}
								customRender={serviceCustomRender}
								borderBottom='none'
								customStyle={{
									workingTime: {
										paddingRight: '0.4rem',
									},
								}}
							/>
						</CRTable.Body>
					</CRTable.InformationRoot>
				</S.SectionContainer>
				<S.SectionContainer>
					<S.SectionTitle>급여 수령 방법</S.SectionTitle>
					<S.SectionContentContainer>
						<CRInputLabel label='수령 방식' type='left' isRequired>
							<CRCheckBoxGroup
								checkType='single'
								type='radio'
								appearanceType='button'
								gap={0.8}
								options={[
									{
										label: '계좌이체',
										value: '계좌이체',
									},
									{
										label: '현금수령',
										value: '현금수령',
									},
								]}
							/>
						</CRInputLabel>
						<CRInputLabel label='은행명' type='left' isRequired>
							<CRInput.Selector placeholder='은행 선택' />
						</CRInputLabel>
						<CRInputLabel label='계좌번호' type='left' isRequired>
							<CRInput.ButtonInput
								placeholder='계좌번호 입력'
								buttonText='계좌 확인'
								buttonOptions={{ type: 'outlined', palette: 'gray' }}
							/>
						</CRInputLabel>
						<CRInputLabel label='계좌주' type='left' isRequired>
							<CRInput.Default placeholder='계좌주 입력' />
						</CRInputLabel>
					</S.SectionContentContainer>
				</S.SectionContainer>
				<S.Divider />
				<S.NoteContainer>
					<CRInputLabel label='월별 급여 메모' type='left' isRequired>
						<CRInput.TextArea placeholder='월별 급여 메모 입력' />
					</CRInputLabel>
				</S.NoteContainer>
			</S.Content>
			<S.ButtonContainer>
				<CRButton.Default>저장</CRButton.Default>
				<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
					취소
				</CRButton.Default>
			</S.ButtonContainer>
		</S.Container>
	);
}

export default SalaryDetailSideModal;
