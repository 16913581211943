import { useContext } from 'react';

import { QuickSearchContext } from 'lib/provider/ui/QuickSearch';

export default function useQuickSearch() {
	const context = useContext(QuickSearchContext);
	if (!context) {
		throw new Error('useQuickSearch must be used within a QuickSearchContext');
	}
	return context;
}
