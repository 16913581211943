import Colors from 'common/colors';
import { CRTableHeadConfig } from 'types/view/base';

export const RECORDING_SHEET_DETAIL_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
	},
	{
		width: '12rem',
		label: '일자',
	},
	{
		width: '8rem',
		label: '수급자명',
	},
	{
		width: '8rem',
		label: '급여 종류',
	},
	{
		width: '9.6rem',
		label: '직원명',
	},
	{
		width: '12rem',
		label: '기록지 시간',
	},
	{
		width: '100%',
		label: '',
	},
];
