import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 0 2.4rem 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
