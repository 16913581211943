import React from 'react';

import * as S from './styles';

interface IProps extends React.PropsWithChildren {
	label: string;
	isRequired?: boolean;
	type?: 'left' | 'top' | 'left-sub';
	showOverflow?: boolean;
	message?: React.ReactNode;
	renderRightAddon?: React.ReactNode;
	betweenGap?: string | number;
}

function CRInputLabel({
	label,
	children,
	isRequired = false,
	type = 'top',
	showOverflow,
	message,
	renderRightAddon,
	betweenGap = 0,
}: IProps): React.ReactElement {
	if (['left', 'left-sub'].includes(type)) {
		return (
			<S.LeftContainer
				style={{
					gap: betweenGap,
				}}>
				<S.LabelContainer>
					<S.LeftLabel style={type === 'left-sub' ? { width: '10rem' } : undefined}>
						{label}
						{isRequired && <S.RequiredMark>*</S.RequiredMark>}
					</S.LeftLabel>
				</S.LabelContainer>
				<S.ChildrenContainer style={{ overflow: showOverflow ? 'visible' : 'hidden' }}>
					{children}
					{message}
				</S.ChildrenContainer>
			</S.LeftContainer>
		);
	}

	return (
		<S.Container
			style={{
				gap: betweenGap,
			}}>
			<S.LabelNormalContainer>
				<S.Label>
					{label}
					{isRequired && <S.RequiredMark>*</S.RequiredMark>}
				</S.Label>
				{renderRightAddon && <S.RightAddonContainer>{renderRightAddon}</S.RightAddonContainer>}
			</S.LabelNormalContainer>
			{children}
			{message}
		</S.Container>
	);
}

export default CRInputLabel;
