import styled from 'styled-components';

export const DefaultMessage = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;

export const ErrorMessage = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.primary60};
`;
