import { styled } from 'styled-components';

export const Container = styled.form`
	position: relative;
	padding: 1.6rem 2.4rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
