import { styled } from 'styled-components';

export const Container = styled.div`
	position: relative;
	min-height: 30rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 0.8rem;
`;
