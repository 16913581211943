import React from 'react';

import * as S from './styles';

function IntegratedKmmseFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn style={{ width: '50%' }} colSpan={3}>
				항목
			</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '30%' }}>반응</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '10%' }}>점수</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '10%' }}>점수</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedKmmseFormHeader);
