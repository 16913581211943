import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from '../base/useCRQuery';

export const useCenterAccountManagement = useCRQuery(
	endpoint.getCenterAccountManagement.key,
	'getCenterAccountManagement',
	{
		enabledKey: ['centerId'],
	},
);
