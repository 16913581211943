import { styled } from 'styled-components';

export const Container = styled.div`
	padding: 2.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	gap: 2.4rem;
	max-width: 1450rem;
	min-width: 80rem;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
`;

export const BannerContainer = styled.div`
	width: 100%;
`;

export const ContentContainer = styled.div`
	display: flex;
	gap: 2.4rem;
	width: 100%;
	height: 100%;

	justify-content: center;
`;

export const ContentLeft = styled.div`
	width: 48rem;
	flex-shrink: 0;
	height: 100%;
	padding: 1.6rem;
	box-sizing: border-box;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
	overflow: hidden;
`;

export const ContentRight = styled.div`
	flex: 1;
	padding: 1.6rem;
	border-radius: 1.6rem;
	overflow: hidden;
	background: ${(props) => props.theme.colors.gray100};
`;
