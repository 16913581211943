import Colors from 'common/colors';
import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';
import { LongTermSalary } from '../../../../types/view/billing';

export const BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG: CRTableHeadConfig[] = [
	{ label: '제공일', width: '8rem', color: Colors.gray60 },
	{ label: '서비스 명칭', width: '16rem', color: Colors.gray60 },
	{
		label: '일정 계획 시간',
		width: '12rem',
		color: Colors.gray60,
		textAlign: 'right',
	},
	{ label: 'RFID 전송내역', width: '16.8rem', color: Colors.gray60, textAlign: 'right' },
	{ label: '감산비율', width: '8.8rem', color: Colors.gray60, textAlign: 'right' },
	{ label: '요양요원', width: '12rem', color: Colors.gray60 },
	{ label: '수가', width: '9.6rem', color: Colors.gray60, textAlign: 'right' },
	{ label: '구분', width: '6.4rem', color: Colors.gray60 },
	{ label: '기록지', width: '9.6rem', color: Colors.gray60 },
	{ label: '상태변화 기록', width: '12rem', color: Colors.gray60 },
	{ label: '수정 여부', width: '12rem', color: Colors.gray60 },
];

export const BILLING_SIDE_MODAL_TABLE_EDITABLE_HEAD_CONFIG: CRTableHeadConfig[] = [
	{ label: '', width: '5.6rem', color: Colors.gray60 },
	...BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG,
];

export const REPORT_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '서명전',
		color: 'yellow',
	},
	{
		key: '서명완료',
		color: 'green',
	},
];

export const LONG_TERM_SALARY_LIST_RENDER_KEY: (keyof LongTermSalary)[] = [
	'date',
	'service',
	'scheduleTime',
	'rfidSendingDetail',
	'subtractionRate',
	'employee',
	'amount',
	'type',
	'reportStatus',
	'reportNotify',
	'isReportEdited',
];

export const LONG_TERM_SALARY_EDITABLE_LIST_RENDER_KEY: (keyof LongTermSalary)[] = [
	'isDisabled',
	...LONG_TERM_SALARY_LIST_RENDER_KEY,
];
