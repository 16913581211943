import React, { CSSProperties, ReactNode } from 'react';

import * as S from './styles';

interface Props {
	children: ReactNode;
	style?: CSSProperties;
}

function CRTableContainer({ children, style }: Props): React.ReactElement {
	return <S.Table style={style}>{children}</S.Table>;
}

export default React.memo(CRTableContainer);
