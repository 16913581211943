import { styled } from 'styled-components';

export const Container = styled.div`
	overflow: overlay;
	width: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
	max-width: 158.4rem;
	min-width: 90rem;
	margin: 0 auto;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Content = styled.div`
	display: flex;
	gap: 2.4rem;
	margin-top: 1.8rem;
`;

export const LeftSection = styled.div`
	width: 48rem;
	height: calc(100vh - 12.1rem);
	overflow: auto;
	padding-bottom: 4.8rem;
	padding-right: 1rem;
	box-sizing: border-box;
`;

export const RightSection = styled.div`
	flex: 1;
	height: calc(100vh - 14rem);
	overflow: auto;
`;

export const Divider = styled.div`
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin: 1.6rem 0;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 1.6rem;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1.6rem;
	& h3 {
		${(props) => props.theme.typography.h3};
		color: ${(props) => props.theme.colors.gray00};
	}
`;
