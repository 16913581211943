import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const BreadCrumbItem = styled.a<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.body}
	line-clamp: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${(props) => (props.$isActive ? props.theme.colors.gray10 : props.theme.colors.gray60)};
`;

export const Divider = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	margin: 0 1.6rem;
	filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(144%) hue-rotate(149deg)
		brightness(86%) contrast(83%);
`;
