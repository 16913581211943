import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import CRStatus from 'components/base/CRStatus';
import { Billing } from 'types/view/billing';

import { FilterType } from 'types/view/filter';
import * as S from './styles';
import {
	BILLING_TASK_HEADER_CONFIG,
	BILLING_TASK_PROGRESS_STATUS_CONFIG,
	BILLING_TASK_TABLE_FILTER_CONFIG,
} from './constant';

interface Props<T extends { label: string; value: any }> {
	items: Billing[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickBilling?: (billing: Billing) => void;
}

function BillingTaskTable<T extends { label: string; value: any }>({
	items = [],
	currentFilter,
	setCurrentFilter,
	searchValue,
	onChangeSearchValue,
	onClickBilling,
}: Props<T>): React.ReactElement {
	const BillingTaskStatusBadge = useCallback(
		(children: string) => (
			<CRStatus options={BILLING_TASK_PROGRESS_STATUS_CONFIG}>{children}</CRStatus>
		),
		[],
	);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				filters={BILLING_TASK_TABLE_FILTER_CONFIG}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				stickyMode
			/>
			<CRTable.Root>
				<CRTable.Head heads={BILLING_TASK_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item) => (
						<CRTable.Row
							key={item.id}
							item={item}
							renderKeys={[
								'recipient',
								'service',
								'ownExpenseRate',
								'',
								'schedule',
								'totalWorkHour',
								'totalAmount',
								'taskStatus',
							]}
							customRender={{
								taskStatus: BillingTaskStatusBadge,
							}}
							onClick={onClickBilling}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default BillingTaskTable;
