import { useContext } from 'react';

import { NavigationContext } from 'lib/provider/ui/Navigation';

export default function useNavigation() {
	const context = useContext(NavigationContext);
	if (!context) {
		throw new Error('useNavigation must be used within a NavigationContext');
	}
	return context;
}
