import { useContext } from 'react';

import { EmployeePageContext } from 'lib/provider/employee';

export default function useEmployeePage() {
	const context = useContext(EmployeePageContext);
	if (!context) {
		throw new Error('useEmployeePage must be used within a EmployeePageContext');
	}
	return context;
}
