import React from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, useForm } from 'react-hook-form';

import CRDialog from 'components/base/CRDialog';
import CRButton from 'components/base/CRButton';
import { Toast } from 'components/base/CRToast';
import useDialog from 'lib/hook/util/useDialog';
import {
	useMyAccountInfo,
	useUploadEmployeeDocument,
	useUploadRecipientDocument,
	useUploadRecipientRenewalDocument,
} from 'lib/hook/react-query';
import { UploadContractDocumentForm } from 'types/view/contract';

import * as S from './styles';

interface Props {
	contractDocumentId: number | number[];
	type?: 'employee' | 'recipient' | 'recipientRenewal';
}

function UploadContractDocument({
	type = 'recipient',
	contractDocumentId,
}: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		control,
		trigger,
		handleSubmit,
		formState: { isValid },
	} = useForm<UploadContractDocumentForm>();
	const { hideDialog } = useDialog();

	const uploadRecipientDocument = useUploadRecipientDocument();

	const uploadRecipientRenewalDocument = useUploadRecipientRenewalDocument();

	const uploadEmployeeDocument = useUploadEmployeeDocument();

	const onSubmit = async (data: UploadContractDocumentForm) => {
		try {
			if (!myAccountInfo?.centerId || !contractDocumentId) return;
			if (type === 'recipient') {
				await uploadRecipientDocument.mutateAsync({
					recipientContractId: contractDocumentId as number,
					remark: data.remark,
					file: {
						fileDetails: data.file,
					},
				});
				hideDialog();
				Toast.success('계약서를 성공적으로 업로드했습니다.');
				return;
			}

			if (type === 'employee') {
				await uploadEmployeeDocument.mutateAsync({
					employeeContractId: contractDocumentId as number,
					remark: data.remark,
					file: {
						fileDetails: data.file,
					},
				});
				hideDialog();
				Toast.success('계약서를 성공적으로 업로드했습니다.');
				return;
			}

			if (type === 'recipientRenewal') {
				await uploadRecipientRenewalDocument.mutateAsync({
					recipientContractIds: contractDocumentId as number[],
					remark: data.remark,
					file: {
						fileDetails: data.file,
					},
				});
				hideDialog();
				Toast.success('계약서를 성공적으로 업로드했습니다.');
				return;
			}
		} catch {
			Toast.error('계약서 업로드에 실패하였습니다. 잠시 후 다시 시도해주세요.');
		}
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='계약서 등록'
			body={
				<S.Container onSubmit={handleSubmit(onSubmit)}>
					<CRInputLabel label='서류 유형' isRequired>
						<CRInput.Selector
							currentValue={{ label: '계약서', value: '계약서F' }}
							items={[{ label: '계약서', value: '계약서F' }]}
							disabled
							placeholder='서류 유형 선택'
						/>
					</CRInputLabel>
					<Controller
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='첨부 파일' isRequired>
								<CRInput.FileUploader onChange={onChange} files={value} type='multiple' />
							</CRInputLabel>
						)}
						name='file'
					/>
					<CRInputLabel label='비고'>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.TextArea onChange={onChange} value={value} placeholder='비고 입력' />
							)}
							name='remark'
							control={control}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						등록
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default UploadContractDocument;
