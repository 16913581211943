import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';

import { CheckOption } from 'components/base/Selections/type';
import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import { displayBizNo, displayPhoneNumber } from 'lib';
import * as S from './styles';

function MyCenterBasicInformationView(): React.ReactElement {
	const { form, commonCodes } = useCenterInfo();

	const renderCustomLabel = (value: CheckOption[]) => (
		// eslint-disable-next-line
		<>{value ? value?.map((item) => item?.label)?.join(', ') : ''}</>
	);

	return (
		<S.Container>
			<CRInputLabel type='left' label='센터'>
				<CRInput.Default placeholder='센터' value={form.getValues('centerNm')} disabled />
			</CRInputLabel>
			<CRInputLabel type='left' label='서비스'>
				<CRInput.MultiSelector
					disabled
					activeColor={false}
					renderLabel={renderCustomLabel}
					fullSize
					options={commonCodes?.serviceOfferOptions}
					value={form.getValues('centerOfferServices')}
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='전화번호'>
				<CRInput.Default
					placeholder='전화번호 입력'
					value={
						form.getValues('centerPhoneNo')
							? displayPhoneNumber(form.getValues('centerPhoneNo'))
							: ''
					}
					disabled
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='주소'>
				<CRInput.Address
					disabled
					type='default'
					baseAddress={form.getValues('centerBasAddr')}
					detailAddress={form.getValues('centerDetailAddr')}
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='센터장'>
				<CRInput.Default
					placeholder='센터장 입력'
					value={form.getValues('centerChief')?.label || ''}
					disabled
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='사업자등록번호'>
				<CRInput.Default
					placeholder='사업자등록번호'
					value={displayBizNo(form.getValues('bizNo'))}
					disabled
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='센터 기호'>
				<CRInput.Default placeholder='센터 기호' value={form.getValues('centerMark')} disabled />
			</CRInputLabel>
			<CRInputLabel type='left' label='센터 직인'>
				<CRInput.FileUploader
					disabled
					files={form.getValues('stampFile')}
					placeholder='직인 업로드'
					type='single'
				/>
			</CRInputLabel>
		</S.Container>
	);
}

export default MyCenterBasicInformationView;
