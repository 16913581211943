import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 80rem;
	box-sizing: border-box;
	padding-top: 0.8rem;
	gap: 2.4rem;
`;

export const IntegratedAssessmentDirectContainer = styled.div`
	margin-left: 1.6rem;
`;

export const WarningMessageText = styled.div`
	${(props) => props.theme.typography.label}
	color : ${(props) => props.theme.colors.gray50};
`;

export const Divider = styled.div`
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
`;
