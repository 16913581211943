import React, { useCallback } from 'react';

import CRTableHeader from 'components/base/CRTableHeader';
import CRTable from 'components/base/CRTable';
import { PageInfo } from 'types/view/base';
import { SendHistoryContentDTO } from 'types/api/send';
import CRStatus from 'components/base/CRStatus';

import { CheckOption } from 'components/base/Selections/type';
import { v4 } from 'uuid';
import CRSpinner from 'components/base/CRSpinner';
import dayjs from 'dayjs';
import { Filter } from 'types/view/filter';
import Colors from 'common/colors';

import * as S from './styles';
import { SEND_HISTORY_HEADER_CONFIG } from './constant';

interface Props {
	isLoading?: boolean;
	items?: SendHistoryContentDTO[];
	searchValue?: string;
	currentValue: {
		[key in string]: CheckOption[];
	};
	onRefetch: () => void;
	pageInfo?: PageInfo;
	filters?: Array<Filter<CheckOption>>;
	setCurrentFilter: React.Dispatch<
		React.SetStateAction<{
			[key: string]: CheckOption[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickRow?: (task: SendHistoryContentDTO) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onSearch?: () => void;
}

function SendHistoryTable({
	isLoading,
	items = [],
	currentValue,
	searchValue,
	pageInfo,
	filters,
	setCurrentFilter,
	onChangeSearchValue,
	onClickRow,
	onChangePageInfo,
	onSearch,
	onRefetch,
}: Props): React.ReactElement {
	const SendStatusBadge = useCallback((value: string, item?: SendHistoryContentDTO) => {
		if (!item) return null;
		const options = [
			{ key: item.sendStateCd, label: item.sendStateNm, color: item.sendStateColor },
		];

		return <CRStatus options={options}>{value}</CRStatus>;
	}, []);

	const renderCountText = useCallback(
		(value: string | number) => <S.TextContainer>{value ?? 0}건</S.TextContainer>,
		[],
	);

	const renderSendDate = useCallback(
		(value: Date) => (
			<S.TextContainer>{value ? dayjs(value).format('YYYY.MM.DD HH:mm') : '-'}</S.TextContainer>
		),
		[],
	);

	return (
		<CRTable.BackBlind>
			<S.TableContainer>
				<CRTableHeader
					showRefresh
					onRefresh={onRefetch}
					onChangePageInfo={onChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentValue}
					filters={filters}
					searchValue={searchValue}
					placeholder='제목으로 검색'
					onChangeSearchValue={onChangeSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={onSearch}
					stickyMode
					showViewCount
				/>
				{isLoading ? (
					<CRSpinner />
				) : (
					<S.TableScrollContainer>
						<CRTable.Root
							style={{
								borderLeft: `0.1rem solid ${Colors.gray90}`,
								borderRight: `0.1rem solid ${Colors.gray90}`,
							}}>
							<CRTable.Head offset={-47} heads={SEND_HISTORY_HEADER_CONFIG} />
							<CRTable.Body>
								{items.map((item) => (
									<CRTable.Row
										key={v4()}
										item={item}
										renderKeys={[
											'sendDate',
											'sendTemplateTitle',
											'sendTitle',
											'totalCnt',
											'successCnt',
											'failCnt',
											'waitingCnt',
											'senderNm',
											'sendStateCd',
										]}
										customRender={{
											totalCnt: renderCountText,
											sendStateCd: SendStatusBadge,
											sendDate: renderSendDate,
											successCnt: renderCountText,
											failCnt: renderCountText,
											waitingCnt: renderCountText,
										}}
										onClick={onClickRow}
									/>
								))}
							</CRTable.Body>
						</CRTable.Root>
					</S.TableScrollContainer>
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}

export default SendHistoryTable;
