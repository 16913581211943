import React, { useMemo } from 'react';

import RouterPath from 'common/router';

import SendMessageTab from 'pages/dashboard/SendPage/SendMessageTab';
import CRTab from 'components/base/CRTab';

function SendPage(): React.ReactElement {
	const sendTab = useMemo(() => RouterPath.sendTab(), []);

	return (
		<CRTab.Default
			defaultActiveKey={sendTab.message.key}
			breadCrumb='발송하기'
			items={[
				{
					label: sendTab.message.label,
					key: sendTab.message.key,
					children: <SendMessageTab />,
				},
			]}
		/>
	);
}

export default SendPage;
