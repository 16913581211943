import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRTableHeader from 'components/base/CRTableHeader';
import CRButton from 'components/base/CRButton';
import RecipientConsultingTable from 'components/domain/table/RecipientConsultingTable';
import { RecipientConsulting } from 'types/view/recipient';
import { useCommonCodes, useEmployeeAdvices } from 'lib/hook/react-query';
import { recipientAdvicesAdapter } from 'lib/adapter/advice';
import { commonCodeAdapter } from 'lib/adapter/common';

import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import dayjs from 'dayjs';
import { Filter } from 'types/view/filter';
import * as S from './styles';

export default function EmployeeConsultingTab(): React.ReactElement {
	const navigate = useNavigate();
	const { currentEmployee } = useEmployeePage();

	const [searchValue, setSearchValue] = useState<string>('');

	const [currentFilter, setCurrentFilter] = useState<{
		dateFilter: { label: string; value: any }[];
		tagFilter: { label: string; value: any }[];
	}>({
		dateFilter: [],
		tagFilter: [],
	});

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const { data = [] } = useEmployeeAdvices(
		{
			employeeId: currentEmployee?.employeeId,
			fromDate:
				currentFilter.dateFilter?.[0]?.value?.start &&
				dayjs(currentFilter.dateFilter?.[0]?.value?.start).format('YYYY-MM-DD'),
			toDate:
				currentFilter.dateFilter?.[0]?.value?.end &&
				dayjs(currentFilter.dateFilter?.[0]?.value?.end).format('YYYY-MM-DD'),
			// tags:
			// 	currentFilter.tagFilter?.length > 0
			// 		? currentFilter.tagFilter.map((item) => item.value)
			// 		: null,
		},
		recipientAdvicesAdapter,
	);

	const { data: commonCodes = {} } = useCommonCodes(
		{ comCdGroupNms: ['CMN060'] },
		commonCodeAdapter,
	);

	const filters = useMemo(
		() =>
			[
				{
					key: 'dateFilter',
					type: 'calendar',
					placeholder: '일시',
				},
				{
					key: 'tagFilter',
					type: 'multi',
					placeholder: '태그 유형',
					options: commonCodes.CMN060,
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes.CMN060],
	);

	const filteredData = useMemo(
		() =>
			(data ?? [])
				?.filter((item) => {
					const isMatchTag =
						!currentFilter.tagFilter?.length ||
						currentFilter.tagFilter?.some((filter) => filter.label === item.type);

					return isMatchTag;
				})
				?.filter((item) => item?.consultant?.toLowerCase().includes(searchValue.toLowerCase())),
		[data, currentFilter, searchValue],
	);

	const handleClickAddRecipientConsulting = () => {
		navigate(RouterPath.employee().consultingNew.uniqueKey);
	};

	const handleClickRecipientConsulting = (consulting: RecipientConsulting) => {
		navigate(`${RouterPath.recipient().consultingDetail.uniqueKey}/${consulting.id}`);
	};

	return (
		<S.Container>
			<CRTableHeader
				searchValue={searchValue}
				onChangeSearchValue={handleSearchKeyword}
				currentFilter={currentFilter}
				filters={filters}
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddRecipientConsulting}>
						상담 추가
					</CRButton.IconButton>
				}
				placeholder='상담 대상으로 검색'
				setCurrentFilter={setCurrentFilter}
			/>
			<S.TableContainer>
				<RecipientConsultingTable
					items={filteredData}
					onClickRecipientConsulting={handleClickRecipientConsulting}
				/>
			</S.TableContainer>
		</S.Container>
	);
}
