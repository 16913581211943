import React, { useEffect, useMemo, useState } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import useDialog from 'lib/hook/util/useDialog';
import { SyncDialog } from 'components/domain/dialog/SyncDialog';
import CRSyncMenu from 'components/base/CRSyncMenu';
import {
	useAutomationLastSync,
	useMyAccountInfo,
	useSalaryAutomationUploadInfo,
	useSalarysAutoBilling,
	useSendSlack,
} from 'lib/hook/react-query';
import SalarySyncHistoryDialog from 'components/domain/dialog/SalarySyncHistoryDialog';
import { displayDateToYYYYMM } from 'lib';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api/base';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { UploadTypeCd } from 'types/api/common';
import dayjs from 'dayjs';
import SalaryTab from './SalaryTab';

function SalaryPage(): React.ReactElement {
	const salaryTab = useMemo(() => RouterPath.salaryTab(), []);
	const dialog = useDialog();
	const [isTempSync, setIsTempSync] = useState(false);
	const { mutateAsync: autoBillSalary, isLoading: isAutoBillingLoading } = useSalarysAutoBilling();
	const { data: myAccountInfo } = useMyAccountInfo();
	const centerId = myAccountInfo?.centerId;
	const { isLoading: isUpdateLogLoading } = useSalaryAutomationUploadInfo({
		centerId,
		uploadTypeCds: [UploadTypeCd.임금계산, UploadTypeCd['청구내역/청구내역상세']],
	});

	const { mutate: sendSlack } = useSendSlack();

	const { data: lastSyncData } = useAutomationLastSync({
		centerId,
		uploadTypeCds: [UploadTypeCd.임금계산, UploadTypeCd['청구내역/청구내역상세']],
	});

	const hasPermission = useHasPermission('센터장');

	useEffect(() => {
		if (!lastSyncData) return;

		// 업데이트 기록중 마지막 기록으로 동기화 완료여부 확인
		const isProgress = ['CMN108.REQUEST', 'CMN108.PROGRESS'].includes(lastSyncData.uploadStateCd);

		// 아직 동기화가 완료되지 않았다면 동기화 중으로 설정하고 탈출
		if (isProgress) {
			const minuteDiff = dayjs().diff(lastSyncData.uploadStartDate, 'minute');
			if (minuteDiff >= 10) {
				sendSlack({
					message: `청구 내역 동기화 자동화 이슈 발생! 확인 필요합니다.`,
				});
			}
			setIsTempSync(true);
			return;
		}
		// 동기화 완료되면 해제
		setIsTempSync(false);
	}, [lastSyncData]);

	const handleScheduleSync = async (data: { yearMonth: Date }) => {
		if (!centerId) {
			return;
		}
		if (isAutoBillingLoading) {
			return;
		}
		const YYYYMM = displayDateToYYYYMM(data.yearMonth);
		try {
			const res = await autoBillSalary({ centerId: `${centerId}`, yyyymm: YYYYMM });
			if (res?.code !== ResponseCode.SUCCESS) {
				throw new Error(res?.message);
			}
			setIsTempSync(true);
			Toast.success(
				'청구 내역 동기화 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
			);
		} catch {
			Toast.error('청구 내역 동기화 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		dialog.hideDialog();
	};

	const handleClickSyncButton = () => {
		dialog.showDialog(() => <SyncDialog title='청구 내역' onSync={handleScheduleSync} />);
	};

	const handleClickSyncHistory = () => {
		dialog.showDialog(() => <SalarySyncHistoryDialog />);
	};
	const isProgress = useMemo(() => isTempSync, [isTempSync]);

	return (
		<CRTab.Default
			defaultActiveKey={salaryTab.Salary.key}
			renderRightButton={
				<CRSyncMenu
					showSyncButton={hasPermission}
					syncDisabled={!hasPermission || isUpdateLogLoading}
					title={isProgress ? '청구 내역 동기화 중' : '청구 내역 동기화'}
					isProgress={isProgress}
					onClickSync={handleClickSyncButton}
					onClickSyncHistory={handleClickSyncHistory}
				/>
			}
			items={[
				{
					label: salaryTab.Salary.label,
					key: salaryTab.Salary.key,
					children: <SalaryTab />,
				},
			]}
			breadCrumb='임금관리'
		/>
	);
}

export default SalaryPage;
