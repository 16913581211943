import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from 'lib/hook/react-query/base/useCRQuery';

export const useInsurance = useCRQuery(endpoint.getInsurance.key, 'getInsurance', {
	enabledKey: ['centerId', 'params'],
});

export const useEmployeeInsurance = useCRQuery(
	endpoint.getEmployeeInsurance.key,
	'getEmployeeInsurance',
	{
		enabledKey: ['employeeId', 'centerId'],
	},
);

export const useInsurancePriorInfo = useCRQuery(
	endpoint.getInsurancePriorInfo.key,
	'getInsurancePriorInfo',
	{
		enabledKey: ['employeeId', 'centerId', 'insuranceTypeCd'],
	},
);

export const useInsuranceSalaryFeeInfo = useCRQuery(
	endpoint.getInsuranceSalaryFeeInfo.key,
	'getInsuranceSalaryFeeInfo',
	{
		enabledKey: ['centerId', 'targetDt', 'employeeId'],
	},
);

export const useInsuranceDetailInfo = useCRQuery(
	endpoint.getInsuranceDetailInfo.key,
	'getInsuranceDetailInfo',
	{
		enabledKey: ['employeeId', 'insuranceRequestId'],
	},
);
