import styled from 'styled-components';

export const Container = styled.form`
	padding: 2.4rem;
	box-sizing: border-box;
	width: 48rem;
	min-height: 20rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
