import React, { useEffect, useMemo } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AdmissionUseRequestFormTypes, AdmissionUseService } from 'types/view/eDoc';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { Recipient } from 'types/view/recipient';
import { EDocAdmissionUseRequestDTO } from 'types/api/eDoc';
import CRButton from 'components/base/CRButton';
import { v4 } from 'uuid';
import Assets from 'assets';
import { useMyAccountInfo } from 'lib/hook/react-query';
import * as S from './styles';

type ServiceWithId = AdmissionUseService & { id?: string };
type AdmissionUseRequestFormWithIdTypes = Omit<AdmissionUseRequestFormTypes, 'service'> & {
	service: ServiceWithId[];
};

interface Props {
	templateCode: string;
	currentRecipient?: Recipient;
	formContext: UseFormReturn<AdmissionUseRequestFormWithIdTypes, any, undefined>;
}

export default function EDocAdmissionUseRequestForm({
	templateCode,
	currentRecipient,
	formContext,
}: Props) {
	const { data: employmentCertificationIssueInfo } = useEDocIssueInfoBefore({
		recipientId: currentRecipient?.recipientId,
		centerId: Number(currentRecipient?.centerId),
		templateCode,
	});
	const { data: myAccountInfo } = useMyAccountInfo();

	const service = formContext.watch('service');

	const typesCodes = useMemo(
		() =>
			(
				(employmentCertificationIssueInfo as unknown as EDocAdmissionUseRequestDTO)?.data?.types ||
				[]
			)?.map((item) => ({
				label: item,
				value: item,
			})),
		[employmentCertificationIssueInfo],
	);

	const serviceTypesCodes = useMemo(
		() =>
			(
				(employmentCertificationIssueInfo as unknown as EDocAdmissionUseRequestDTO)?.data
					?.serviceTypes || []
			)?.map((item) => ({
				label: item,
				value: item,
			})),
		[employmentCertificationIssueInfo],
	);

	const handleClickAddService = () => {
		formContext.setValue('service', [
			...service,
			{
				id: v4(),
				serviceType: undefined,
				serviceDesc: undefined,
				serviceFee: undefined,
				serviceNumberOfMonth: undefined,
			},
		]);
	};

	const handleClickDeleteService = (id?: string) => {
		if (!id) return;
		formContext.setValue(
			'service',
			service.filter((item) => item.id !== id),
		);
	};

	useEffect(() => {
		if (employmentCertificationIssueInfo) {
			formContext.setValue('data', employmentCertificationIssueInfo);
		}
	}, [employmentCertificationIssueInfo]);

	useEffect(() => {
		formContext.setValue('service', [
			{
				id: v4(),
				serviceType: undefined,
				serviceDesc: undefined,
				serviceFee: undefined,
				serviceNumberOfMonth: undefined,
			},
		]);
	}, []);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='유형' isRequired>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={typesCodes}
								placeholder='유형 선택'
							/>
						)}
						name='type'
						control={formContext.control}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청일자' isRequired>
							<S.DateContainer>
								<CRInput.DatePicker
									value={value}
									onChangeValue={onChange}
									placeholder='신청일자 선택'
								/>
							</S.DateContainer>
						</CRInputLabel>
					)}
					name='requestDate'
					control={formContext.control}
				/>
				<CRInputLabel label='신청인' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: myAccountInfo?.userNm || '',
							value: myAccountInfo?.memberAccountId,
						}}
						items={[
							{
								label: myAccountInfo?.userNm || '',
								value: myAccountInfo?.memberAccountId,
							},
						]}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, value } }) => (
						<CRInputLabel label='신청인 생년월일' isRequired>
							<CRInput.Default
								onChange={onChange}
								value={value}
								placeholder='예시) 19280301'
								type='number'
								maxLength={8}
							/>
						</CRInputLabel>
					)}
					name='birthDt'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, value } }) => (
						<CRInputLabel label='이용기간' isRequired>
							<CRInput.DateRangePicker
								placeholder='00.00.00 ~ 00.00.00'
								onChangeValue={onChange}
								value={value}
							/>
						</CRInputLabel>
					)}
					name='startEndDate'
					control={formContext.control}
				/>

				{service?.map((item, index) => (
					<S.SubFormContainer key={item.id}>
						<S.SubFormHeader>
							<S.SubFormTitle>급여 이용{index + 1}</S.SubFormTitle>
							<CRButton.Default
								type='outlined'
								size='xSmall'
								onClick={() => handleClickDeleteService(item.id)}>
								삭제
							</CRButton.Default>
						</S.SubFormHeader>
						<CRInputLabel label='서비스 종류' isRequired>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										currentValue={value}
										onChangeValue={onChange}
										items={serviceTypesCodes}
										placeholder='서비스 종류 선택'
									/>
								)}
								name={`service.${index}.serviceType`}
								control={formContext.control}
							/>
						</CRInputLabel>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel label='서비스 내용' isRequired>
									<S.DateContainer>
										<CRInput.Default
											ref={ref}
											onBlur={onBlur}
											placeholder='서비스 내용 입력'
											onChange={onChange}
											value={value}
										/>
									</S.DateContainer>
								</CRInputLabel>
							)}
							name={`service.${index}.serviceDesc`}
							control={formContext.control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel label='수가' isRequired>
									<S.DateContainer>
										<CRInput.Default
											type='number'
											ref={ref}
											onBlur={onBlur}
											placeholder='수가 입력'
											suffix='원'
											onChange={onChange}
											value={value}
										/>
									</S.DateContainer>
								</CRInputLabel>
							)}
							name={`service.${index}.serviceFee`}
							control={formContext.control}
						/>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='횟수/월' isRequired>
									<S.DateContainer>
										<CRInput.Default
											type='number'
											placeholder='횟수/월 입력'
											suffix='회'
											onChange={onChange}
											value={value}
										/>
									</S.DateContainer>
								</CRInputLabel>
							)}
							name={`service.${index}.serviceNumberOfMonth`}
							control={formContext.control}
						/>
						<Controller
							render={() => (
								<CRInputLabel
									label='금액'
									isRequired
									message={
										<S.LabelDescription>수가와 횟수/월에 따라 자동 계산됩니다.</S.LabelDescription>
									}>
									<CRInput.Default
										disabled
										suffix='원'
										placeholder='0'
										type='number'
										maxLength={8}
										value={
											String(Number(item.serviceNumberOfMonth) * Number(item.serviceFee)) || '0'
										}
									/>
								</CRInputLabel>
							)}
							name='birthDt'
							control={formContext.control}
						/>
					</S.SubFormContainer>
				))}
				{service?.length < 6 && (
					<CRButton.IconButton
						palette='gray'
						type='tonal'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddService}>
						급여 이용 추가
					</CRButton.IconButton>
				)}
			</S.SubFormContainer>
		</S.Container>
	);
}
