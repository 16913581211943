import styled from 'styled-components';

export const Container = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;
