import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const OWN_EXPENSE_UPLOAD_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '15rem',
		label: '상태',
		sortKey: '상태',
		paddingLeft: '2.4rem',
	},
	{
		width: '15rem',
		label: '급여종류',
		sortKey: '급여종류',
	},
	{
		width: '30rem',
		label: '요청일시',
		sortKey: '요청일시',
	},
	{
		width: '30rem',
		label: '완료일시',
		sortKey: '완료일시',
	},
	{
		width: '20rem',
		label: '요청자',
		sortKey: '요청자',
	},
	{
		label: '',
		sortKey: '',
	},
];

export const UPLOAD_HISTORY_STATUS_OPTIONS: CRStatusConfig[] = [
	{ key: 'CMN108.REQUEST', label: '요청완료', color: 'gray' },
	{ key: 'CMN108.PROGRESS', label: '진행중', color: 'yellow' },
	{ key: 'CMN108.ERROR', label: '실패', color: 'red' },
	{ key: 'CMN108.PARTIALLY_COMPLETE', label: '부분완료', color: 'blue' },
	{ key: 'CMN108.COMPLETE', label: '완료', color: 'green' },
	{ key: 'CMN108.CLEAR', label: '초기화', color: 'gray' },
];
