import { AxiosResponse } from 'axios';
import { BaseResponse } from 'types/api';

class ApiError extends Error {
	extraInfo: { [k: string]: any };

	constructor(error: AxiosResponse<BaseResponse<any>>) {
		const message = `[${error.config.method?.toLocaleUpperCase()}] ${error.config.url}
${error?.data?.message}`;

		super(message);

		const apiType = error.config.url?.split('/')?.[1] || 'unknown';

		const configData =
			typeof error?.config?.data === 'string'
				? JSON.parse(error?.config?.data || '')
				: error?.config?.data;

		this.name = `ApiError / ${apiType}`;
		this.extraInfo = {
			requestParam: error.config.params,
			requestData: configData,
			responseData: error?.data,
		};
	}
}

export default ApiError;
