import React, { useState } from 'react';

import { SideNavigationSectionItem } from 'types/view/navigation';
import * as Accordion from '@radix-ui/react-accordion';
import TabMenuAccordion from 'components/ui/radix/accordion/TabMenuAccordion';
import LeftSideNavigationMenuItem from './LeftSideNavigationMenuItem';
import * as S from './styles';

interface IProps {
	item: SideNavigationSectionItem;
	onClick?: (path: string) => void;
}

function LeftSideNavigationSectionItem({ item, onClick }: IProps): React.ReactElement {
	const getSubMenuActive = (path: string) => !!window?.location.pathname.startsWith(path);
	const currentMenu = item.menus.find((menu) => getSubMenuActive(menu.path))?.label;
	const [value, setValue] = useState(currentMenu ? [currentMenu] : undefined);

	return (
		<>
			<S.SectionContainer>
				<S.SectionLabel>{item.label}</S.SectionLabel>
				{item.menus?.map((menu) =>
					menu?.children && menu.children?.length > 0 ? (
						<Accordion.Root type='multiple' key={menu.path} value={value} onValueChange={setValue}>
							<Accordion.Item value={menu.label} asChild>
								<TabMenuAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<TabMenuAccordion.Trigger>
												<S.TitleContainer $isActive={getSubMenuActive(menu.path)}>
													{menu.icon && <S.MenuIcon src={menu.icon} />}
													{menu.label}
												</S.TitleContainer>
											</TabMenuAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<TabMenuAccordion.Content>
											{menu.children?.map((item) => (
												<LeftSideNavigationMenuItem key={item.path} item={item} onClick={onClick} />
											))}
										</TabMenuAccordion.Content>
									</Accordion.Content>
								</TabMenuAccordion.Item>
							</Accordion.Item>
						</Accordion.Root>
					) : (
						<LeftSideNavigationMenuItem key={menu.path} item={menu} onClick={onClick} />
					),
				)}
			</S.SectionContainer>
			<S.SectionDivider />
		</>
	);
}

export default LeftSideNavigationSectionItem;
