import React from 'react';

import CRTable from 'components/base/CRTable';
import ContractManagementTable from 'components/domain/table/ContractManagementTable';

function ContractManagementTab(): React.ReactElement {
	return (
		<CRTable.BackBlind>
			<ContractManagementTable />
		</CRTable.BackBlind>
	);
}

export default ContractManagementTab;
