import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import dayjs from 'dayjs';
import { displayPhoneNumber } from 'lib';
import { useSalarysEmployeeSalaryDetail, useSendCashReceive } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import React from 'react';
import { ResponseCode } from 'types/api/base';

interface Props {
	employeeSalaryId: number;
	onClose: () => void;
}

function SendCashReceiveDialog({ employeeSalaryId, onClose }: Props) {
	const { data: salaryDetailInfo, isLoading: isSalaryDetailLoading } =
		useSalarysEmployeeSalaryDetail({
			employeeSalaryId,
		});

	const { mutateAsync: sendCashReceive, isLoading: isSending } = useSendCashReceive(
		(client, data) => {
			if (data?.data) {
				client.setQueryData(
					[endpoint.getSalarysEmployeeSalaryDetail.key, { employeeSalaryId }],
					data?.data,
				);
			}
		},
	);

	const onClickSend = async () => {
		if (isSending) return;
		const res = await sendCashReceive({ employeeSalaryId });
		if (res?.code !== ResponseCode.SUCCESS) {
			Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			return;
		}
		Toast.success('정상적으로 발송 요청을 하였습니다.');
		onClose();
	};

	if (isSalaryDetailLoading) return <CRSpinner />;

	if (!salaryDetailInfo) {
		Toast.error('임금정보가 존재하지 않습니다.');
		onClose();
		return null;
	}

	const employeeName = salaryDetailInfo.employeeNm;
	const phoneNumber = displayPhoneNumber(salaryDetailInfo.mobilePhoneNo);
	const salaryYm = dayjs(salaryDetailInfo.salaryYm).format('YYYY년 M월');

	return (
		<CRDialog
			showCloseButton={false}
			title='임금 현금 수령증을 발송 요청하시겠습니까?'
			body={
				<CRText
					text={`${employeeName}(${phoneNumber})님에게 ${salaryYm} 임금의\n임금 현금 수령증을 발송할 수 있도록 요청합니다.`}
					padding='0 2.4rem'
				/>
			}
			footer={
				<FlexContainer gap='0.8rem'>
					<CRButton.Default palette='gray' type='text' onClick={onClose}>
						취소
					</CRButton.Default>
					<CRButton.Default onClick={onClickSend}>발송 요청</CRButton.Default>
				</FlexContainer>
			}
		/>
	);
}

export default SendCashReceiveDialog;
