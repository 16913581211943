import styled from 'styled-components';

export const EmptyBox = styled.div`
	width: 100%;
	height: 164px;
`;

export const FixedContainer = styled.div`
	width: 100%;
	position: fixed;
	max-width: 768px;
	margin: 0 auto;
	top: 0;
	z-index: 10;
`;
