import React, { useEffect, useMemo } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import { CheckOption } from 'components/base/Selections/type';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import { useCommonCodes, useUpdateRecipientOtherServices } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { Recipient, RecipientOtherServiceFormViewType } from 'types/view/recipient';

import { yupResolver } from '@hookform/resolvers/yup';
import { RecipientOtherServiceFormResolver, displayPadTime } from 'lib';
import { Toast } from 'components/base/CRToast';
import dayjs from 'dayjs';
import useDialog from 'lib/hook/util/useDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { ErrorMessage } from 'components/base/CRInputMessage/styles';
import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	items?: RecipientOtherServiceFormViewType[];
	onlyEdit?: boolean;
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

function RecipientOtherServiceForm({
	currentRecipient,
	items,
	onlyEdit,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { data: commonCodes = [] } = useCommonCodes({ comCdGroupNms: ['CMN006', 'CMN020'] });
	const updateRecipientOtherServices = useUpdateRecipientOtherServices(
		async (client, res, variables) => {
			if (res?.recipientId) {
				await client.invalidateQueries([
					endpoint.getRecipient.key,
					{ recipientId: variables.recipientId },
				]);
				await client.invalidateQueries([
					endpoint.getRecipientBase.key,
					{ recipientId: variables.recipientId },
				]);

				onStartChangeSection?.();
				Toast.success('타급여를 수정했습니다.');
			} else {
				onCancelChangeSection?.();
			}
		},
	);
	const { control, handleSubmit, setValue, getValues, watch, formState } = useForm<{
		items: RecipientOtherServiceFormViewType[];
	}>({
		mode: 'onChange',
		defaultValues: {
			items:
				items?.length === 0
					? [
							{
								centerName: '',
								phoneNumber: '',
								memo: '',
								otherService: '',
								times: [
									{
										days: [],
										otherServiceUseStartTime: '',
										otherServiceUseEndTime: '',
									},
								],
							},
					  ]
					: items,
		},
		resolver: yupResolver(RecipientOtherServiceFormResolver),
	});

	const { showDialog } = useDialog();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'items',
	});

	const serviceCommonCodes = useMemo(
		() => (commonCodes ?? [])?.filter((item) => item.comCdGroupNm === 'CMN006'),
		[commonCodes],
	);

	const serviceOptions = useMemo(
		() =>
			serviceCommonCodes.map(
				(code) =>
					({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					} as CheckOption),
			),
		[serviceCommonCodes],
	);

	const checkValidOhterService = (form: { items: RecipientOtherServiceFormViewType[] }) =>
		form.items.every((item, idx1) =>
			item.times.every((time, idx2) => {
				const startTime = dayjs(`2000-01-01 ${displayPadTime(time.otherServiceUseStartTime)}:00`);
				const endTime = dayjs(`2000-01-01 ${displayPadTime(time.otherServiceUseEndTime)}:00`);
				const result = startTime.isBefore(endTime);

				if (!result) {
					Toast.error(`이용일시를 확인해주세요 [타급여${idx1 + 1}의 ${idx2 + 1}번째]`);
				}
				return result;
			}),
		);

	const checkDuplicateOtherService = (form: { items: RecipientOtherServiceFormViewType[] }) => {
		const { items } = form;
		const flatSchedule = items.flatMap((item, idx1) =>
			item.times.flatMap((time, idx2) => ({
				itemInfo: `타급여${idx1 + 1}`,
				timeInfo: `${idx2 + 1}번`,
				...time,
			})),
		);
		for (let i = 0; i < flatSchedule.length; i += 1) {
			for (let j = 0; j < flatSchedule.length; j += 1) {
				// eslint-disable-next-line no-continue
				if (i === j) continue;

				const timeOne = flatSchedule[i];
				const { otherServiceUseStartTime: _oneStart, otherServiceUseEndTime: _oneEnd } = timeOne;
				const timeTwo = flatSchedule[j];
				const { otherServiceUseStartTime: _twoStart, otherServiceUseEndTime: _twoEnd } = timeTwo;

				const oneStart = displayPadTime(_oneStart);
				const oneEnd = displayPadTime(_oneEnd);
				const twoStart = displayPadTime(_twoStart);
				const twoEnd = displayPadTime(_twoEnd);

				// 중복 시간이 있는지 체크
				const isDuplicated =
					// two의 시작시간이 one의 시간 번위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${twoStart}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// two의 종료시간이 one의 시간 번위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoEnd}:00`) &&
						dayjs(`2000-01-01 ${twoEnd}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// two의 시간 범위가 one의 시간 범위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${twoEnd}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// one의 시간 범위가 two의 시간 범위 안에 있을때
					(dayjs(`2000-01-01 ${twoStart}:00`).isBefore(`2000-01-01 ${oneStart}:00`) &&
						dayjs(`2000-01-01 ${oneEnd}:00`).isBefore(`2000-01-01 ${twoEnd}:00`)) ||
					// one의 시간범위와 two의 시간 범위가 동일할때
					(dayjs(`2000-01-01 ${oneStart}:00`).isSame(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${oneEnd}:00`).isSame(`2000-01-01 ${twoEnd}:00`));
				// 중복된 시간이 있다면 날짜 체크
				if (isDuplicated) {
					const oneDays = timeOne.days.map((day) => day.otherServiceUseDayCd);
					const twoDays = timeTwo.days.map((day) => day.otherServiceUseDayCd);

					const DuplicateInfo = [
						`${timeOne.itemInfo}의 ${timeOne.timeInfo}`,
						`${timeTwo.itemInfo}의 ${timeTwo.timeInfo}`,
					].sort();

					for (let k = 0; k < oneDays.length; k += 1) {
						for (let l = 0; l < twoDays.length; l += 1) {
							if (oneDays[k] === twoDays[l]) {
								Toast.error(
									`중복된 일정이 존재합니다. [${DuplicateInfo[0]}] [${DuplicateInfo[1]}]`,
									{ autoClose: 5000 },
								);
								return false;
							}
						}
					}
				}
			}
		}

		return true;
	};

	const onSubmitSuccess = async (form: { items: RecipientOtherServiceFormViewType[] }) => {
		if (updateRecipientOtherServices.isLoading) return;

		// 토스트까지 함수내부에서 처리
		if (!checkValidOhterService(form)) {
			onCancelChangeSection?.();
			return;
		}
		// 토스트까지 함수내부에서 처리
		if (!checkDuplicateOtherService(form)) {
			onCancelChangeSection?.();
			return;
		}

		await updateRecipientOtherServices.mutateAsync({
			recipientId: Number(currentRecipient?.recipientId),
			body: form.items.map((item) => ({
				otherCenterNm: item.centerName,
				otherCenterPhoneNo: item.phoneNumber,
				serviceTypeCd: item.otherService,
				remark: item.memo,
				times: item.times,
			})),
		});
	};

	const onSubmitFail = (errors: { items: FieldErrors<RecipientOtherServiceFormViewType>[] }) => {
		const item = errors?.items?.filter?.(Boolean)?.[0];
		onCancelChangeSection?.();
		if (!item) return;
		if (Object.keys(item).length === 1 && item.times) {
			const detailItem = item?.times?.filter?.(Boolean)?.[0] as FieldErrors<{
				otherServiceUseStartTime: string;
				otherServiceUseEndTime: string;
				days: string;
			}>;
			if (!Array.isArray(item?.times)) {
				Toast.error(item.times.message || '입력폼을 확인해주세요');
				return;
			}
			if (detailItem) {
				Toast.error(Object.values(detailItem)[0]?.message || '입력폼을 확인해주세요');
			}
		} else {
			Toast.error(Object.values(item)[0]?.message || '입력폼을 확인해주세요');
		}
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='타급여에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmitSuccess, onSubmitFail as any)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	const handleChangeTime =
		(index: number, timeIndex: number) =>
		(dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
			const days = dayOfTheWeeks.map((day) => ({ otherServiceUseDayCd: day }));
			setValue(`items.${index}.times.${timeIndex}.days`, days);
			setValue(
				`items.${index}.times.${timeIndex}.otherServiceUseStartTime`,
				startTime?.replace(':', ''),
			);
			setValue(
				`items.${index}.times.${timeIndex}.otherServiceUseEndTime`,
				endTime?.replace(':', ''),
			);
		};

	const handleClickAddOtherService = () => {
		append({
			centerName: '',
			phoneNumber: '',
			memo: '',
			otherService: '',
			times: [
				{
					days: [],
					otherServiceUseStartTime: '',
					otherServiceUseEndTime: '',
				},
			],
		});
	};

	const handleClickRemoveOtherService = (index: number) => () => {
		const previousTimes = getValues(`items`);
		setValue(
			`items`,
			previousTimes.filter((_, idx) => index !== idx),
		);
	};

	const handleAddTime = (index: number) => () => {
		const previousTimes = getValues(`items.${index}.times`);
		setValue(`items.${index}.times`, [
			...previousTimes,
			{
				days: [],
				otherServiceUseStartTime: '',
				otherServiceUseEndTime: '',
			},
		]);
	};

	const handleRemoveTime = (index: number, timeIndex: number) => {
		const previousTimes = getValues(`items.${index}.times`).filter((_, idx) => timeIndex !== idx);
		setValue(`items.${index}.times`, previousTimes);
	};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	return (
		<S.Container>
			<S.Form>
				{!onlyEdit && (
					<S.Label>
						타급여
						<S.ButtonContainer>
							<CRButton.Default
								size='xSmall'
								type='outlined'
								palette='gray'
								onClick={onSubmitHandler}>
								취소
							</CRButton.Default>
							<CRButton.Default
								buttonType='button'
								size='xSmall'
								type='filled'
								palette='gray'
								onClick={handleSubmit(onSubmitSuccess, onSubmitFail as any)}>
								저장
							</CRButton.Default>
						</S.ButtonContainer>
					</S.Label>
				)}
				{fields?.map((_, index) => (
					<>
						<S.ItemHeader>
							{`타급여  ${String(index + 1).padStart(2, '0')}`}
							<S.DeleteButtonContainer>
								<CRButton.Default
									size='xSmall'
									type='outlined'
									onClick={handleClickRemoveOtherService(index)}>
									삭제
								</CRButton.Default>
							</S.DeleteButtonContainer>
						</S.ItemHeader>
						<S.Table>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									센터명 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												status={errors.items?.[index]?.centerName?.message ? 'error' : 'default'}
												onChange={onChange}
												value={value}
												placeholder='센터명 입력'
												addOnBottom={formState?.errors?.items?.[index]?.centerName?.message}
											/>
										)}
										name={`items.${index}.centerName`}
										control={control}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn style={{ width: '10.4rem' }} rowSpan={3}>
									비고
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }} rowSpan={3}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TextArea
												addOnBottom={errors.items?.[index]?.memo?.message}
												typography='label'
												ref={ref}
												onBlur={onBlur}
												status={errors.items?.[index]?.memo?.message ? 'error' : 'default'}
												onChange={onChange}
												value={value}
												placeholder='비고 입력'
												numberOfLines={4}
											/>
										)}
										name={`items.${index}.memo`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									전화번호 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												type='number'
												ref={ref}
												onBlur={onBlur}
												status={errors.items?.[index]?.phoneNumber?.message ? 'error' : 'default'}
												onChange={onChange}
												value={value}
												placeholder='전화번호 입력'
												addOnBottom={formState?.errors?.items?.[index]?.phoneNumber?.message}
											/>
										)}
										name={`items.${index}.phoneNumber`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									타급여 종류 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
									<Controller
										render={({ field: { ref, onChange, value }, formState: { errors } }) => (
											<CRInput.Selector
												ref={ref}
												status={errors.items?.[index]?.otherService?.message ? 'error' : 'default'}
												items={serviceOptions}
												currentValue={serviceOptions.find((option) => option.value === value)}
												onChangeValue={(option) => onChange(option.value)}
												placeholder='타급여 종류 선택'
												type='small'
												addOnBottom={errors?.items?.[index]?.otherService?.message}
											/>
										)}
										name={`items.${index}.otherService`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									이용일시 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ verticalAlign: 'middle' }} colSpan={3}>
									<Controller
										render={({ formState: { errors } }) => (
											<S.TimePickerContainer>
												{watch(`items.${index}.times`).map((time, timeIndex) => (
													<React.Fragment key={time.id}>
														<CRInput.TimePicker
															selectedDayOfTheWeeks={time.days.map(
																(day) => day.otherServiceUseDayCd as DayOfTheWeek,
															)}
															start={transformTimeString(time.otherServiceUseStartTime)}
															end={transformTimeString(time.otherServiceUseEndTime)}
															onChange={handleChangeTime(index, timeIndex)}
															onDelete={() => handleRemoveTime(index, timeIndex)}
															disableDelete={timeIndex < 1}
														/>
														{errors.items?.[index]?.times?.[timeIndex] && (
															<ErrorMessage>
																{
																	Object.values(
																		errors?.items?.[index]?.times?.[timeIndex] as {
																			[key: string]: { message: string };
																		},
																	)[0].message
																}
															</ErrorMessage>
														)}
													</React.Fragment>
												))}
												<CRButton.IconButton
													onClick={handleAddTime(index)}
													iconLeft={Assets.icon.add}
													palette='gray'
													type='tonal'>
													이용일시 추가
												</CRButton.IconButton>
											</S.TimePickerContainer>
										)}
										name={`items.${index}.times`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
						</S.Table>
					</>
				))}
				<S.AddButtonContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						palette='gray'
						type='outlined'
						onClick={handleClickAddOtherService}>
						타급여 추가
					</CRButton.IconButton>
				</S.AddButtonContainer>
			</S.Form>
		</S.Container>
	);
}

export default RecipientOtherServiceForm;
