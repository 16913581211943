import { InformationTableItemType } from 'components/ui/InformationTable/type';

export const CONTRACT_LIST_TABLE_HEADER: InformationTableItemType[] = [
	{
		type: 'label',
		label: '문서명',
		labelStyle: {
			width: '24rem',
		},
	},
	{
		type: 'label',
		label: '파일',
		labelStyle: {
			width: '24rem',
		},
	},
	{
		type: 'label',
		label: '발송상태',
		labelStyle: {
			width: '12rem',
		},
	},
	{
		type: 'label',
		label: '',
	},
];
