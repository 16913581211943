import React, {
	MouseEvent,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRTableHeader from 'components/base/CRTableHeader';
import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import ContractCreateDialog from 'components/domain/dialog/ContractCreateDialog';
import {
	useCenterManagers,
	useChangeContractStatus,
	useCommonCodes,
	useContracts,
	useDeleteRenewalContract,
	useDeleteServiceContract,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { contractsAdapter } from 'lib/adapter/contract';
import { endpoint } from 'lib/service/Api/endpoint';
import { ContractRecord } from 'types/view/contract';

import { Filter } from 'types/view/filter';
import { displayShortDate } from 'lib';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { CRText } from 'components/base/CRText';
import { ResponseCode } from 'types/api';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { DeleteContractRequest } from 'types/api/contract';
import usePageFilter from 'lib/hook/util/usePageFilter';
import CRSpinner from 'components/base/CRSpinner';
import { useScroll } from 'lib/hook/util/useScroll';
import * as S from './styles';
import { CONTRACT_MANAGEMENT_HEADER_CONFIG, CONTRACT_STATUS_CONFIGS } from './constant';
import ContractCancelDialog from '../../dialog/ContractCancelDialog';
import ContractPendingDialog from '../../dialog/ContractPendingDialog';
import { Toast } from '../../../base/CRToast';

export enum SignStatus {
	Pending,
	Done,
}

export enum SendStatus {
	InProgress,
	Done,
	Failure,
}

function ContractManagementTable(): React.ReactElement {
	const { elementRef, saveScrollPosition } = useScroll();
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({
		stateFilter: [],
		serviceFilter: [],
		managerFilter: [],
	});
	const { showDialog, hideDialog } = useGlobalLayout();
	const haPermission = useHasPermission(['센터장']);

	const changeContractStatus = useChangeContractStatus((client) => {
		client.invalidateQueries([endpoint.getContracts.key]);
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		data = {
			totalPages: 0,
			content: [],
		},
		isLoading,
		refetch,
	} = useContracts(
		{
			page: pageInfo.page,
			size: pageInfo.size,
			serviceTypeCds: currentFilter?.serviceFilter?.map((e) => e.value),
			contractStateCds: currentFilter?.stateFilter?.map((e) => e.value),
			contractManagerIds: currentFilter?.managerFilter?.map((e) => e.value),
			keyword,
		},
		contractsAdapter,
	);

	const { mutate: deleteRenewalContract } = useDeleteRenewalContract(async (client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('계약 정보를 정상적으로 삭제하였습니다.');
			hideDialog();
			refetch();
		} else {
			hideDialog();
			Toast.error('계약 정보 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const { mutate: deleteServiceContract } = useDeleteServiceContract(async (client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('계약 정보를 정상적으로 삭제하였습니다.');
			hideDialog();
			refetch();
		} else {
			hideDialog();
			Toast.error('계약 정보 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const {
		data: commonCodes = {
			CMN082: [],
			CMN136: [],
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN082', 'CMN136', 'CMN006'] }, commonCodeAdapter);

	const filters = useMemo(
		() =>
			[
				{
					key: 'stateFilter',
					type: 'multi',
					placeholder: '상태',
					options: commonCodes.CMN082,
				},
				{
					key: 'serviceFilter',
					type: 'multi',
					placeholder: '급여 종류',
					options: commonCodes.CMN006?.filter((item) => item.data?.etcDesc1 === 'Y'),
				},
				{
					key: 'managerFilter',
					type: 'multi',
					placeholder: '사회복지사',
					options: (centerManagers ?? []).map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})),
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes.CMN006, centerManagers],
	);

	const handleClickItem = (item: ContractRecord) => {
		if (item.contractType === 'CMN136.30') {
			navigate(
				`/${RouterPath.myTaskOperate().contractRenewal.uniqueKey}/${
					item.serviceContractGroupUuid
				}/${RouterPath.myTaskOperate().recipientBasic.uniqueKey}`,
			);
			return;
		}
		if (item.contractType === 'CMN136.40') {
			navigate(
				`/${RouterPath.myTaskOperate().contractChangeEmployee.uniqueKey}/${item.id}/${
					RouterPath.myTaskOperate().recipientBasic.uniqueKey
				}`,
			);
			return;
		}
		saveScrollPosition();
		navigate(
			`/${RouterPath.myTaskOperate().contract.uniqueKey}/${item.id}/${
				RouterPath.myTaskOperate().recipientBasic.uniqueKey
			}`,
		);
	};

	const handleClickCancel = (item?: ContractRecord) => (event?: MouseEvent<HTMLDivElement>) => {
		event?.stopPropagation();
		showDialog(({ hideDialog }) => (
			<ContractCancelDialog hideDialog={hideDialog} contract={item} />
		));
	};

	const handleClickReStart =
		(item?: ContractRecord) => async (event?: MouseEvent<HTMLDivElement>) => {
			if (!myAccountInfo) return;
			if (!item) return;
			event?.stopPropagation();
			try {
				await changeContractStatus.mutateAsync({
					serviceContractId: item.id,
					centerId: myAccountInfo?.centerId,
					contractStateCd: 'CMN082.20',
					contractStateChangeDesc: '',
				});
				Toast.success('계약 상태를 진행중으로 변경했습니다.');
			} catch {
				Toast.error('상태 변경에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		};

	const handleCompleteEnroll =
		(item?: ContractRecord) => async (event?: MouseEvent<HTMLDivElement>) => {
			if (!myAccountInfo) return;
			if (!item) return;
			event?.stopPropagation();
			try {
				await changeContractStatus.mutateAsync({
					serviceContractId: item.id,
					centerId: myAccountInfo?.centerId,
					contractStateCd: 'CMN082.10',
					contractStateChangeDesc: '',
				});
				Toast.success('계약 상태를 배정대기로 변경했습니다.');
			} catch {
				Toast.error('상태 변경에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		};

	const handleClickPending = (item?: ContractRecord) => (event?: MouseEvent<HTMLDivElement>) => {
		event?.stopPropagation();
		showDialog(({ hideDialog }) => (
			<ContractPendingDialog hideDialog={hideDialog} contract={item} />
		));
	};

	const handleClickDelete = (item?: ContractRecord) => (event?: MouseEvent<HTMLDivElement>) => {
		event?.stopPropagation();
		showDialog(() => (
			<DefaultDialog
				title='계약 삭제'
				hideDialog={hideDialog}
				cancelOption={{
					text: '닫기',
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: () => {
						if (!haPermission || !item) return;
						const param: DeleteContractRequest = {
							serviceContractId: item.id,
						};
						if (item.contractType === 'CMN136.30') {
							param.serviceContractGroupUuid = item.serviceContractGroupUuid;
							deleteRenewalContract(param);
						} else {
							deleteServiceContract(param);
						}
					},
				}}
				content={
					<CRText>
						{item?.recipient}님의 계약 정보를 모두 삭제하시겠습니까?
						<br /> <CRText color='red'>삭제 후에는 복구할 수 없습니다.</CRText>{' '}
					</CRText>
				}
			/>
		));
	};

	const StatusComponent = useCallback(
		(status: string) => <CRStatus options={CONTRACT_STATUS_CONFIGS}>{status}</CRStatus>,
		[],
	);

	const ButtonComponent = useCallback((id: number, item?: ContractRecord) => {
		if (item?.status === 'CMN082.90') {
			return <div />;
		}
		if (item?.status === 'CMN082.00') {
			return (
				<S.ButtonContainer>
					<CRButton.Default
						size='xSmall'
						palette='gray'
						type='outlined'
						onClick={handleClickPending(item)}>
						보류
					</CRButton.Default>
					<CRButton.Default size='xSmall' type='outlined' onClick={handleClickCancel(item)}>
						취소
					</CRButton.Default>
					<CRButton.Default
						size='xSmall'
						palette='gray'
						type='filled'
						onClick={handleCompleteEnroll(item)}>
						등록 완료
					</CRButton.Default>
				</S.ButtonContainer>
			);
		}
		if (item?.status === 'CMN082.70') {
			return (
				<S.ButtonContainer>
					<CRButton.Default
						size='xSmall'
						palette='gray'
						type='outlined'
						onClick={handleClickReStart(item)}>
						계속 진행
					</CRButton.Default>
					<CRButton.Default size='xSmall' type='outlined' onClick={handleClickCancel(item)}>
						취소
					</CRButton.Default>
				</S.ButtonContainer>
			);
		}
		if (item?.status === 'CMN082.80') {
			return (
				<S.ButtonContainer>
					<CRButton.Default size='xSmall' type='outlined' onClick={handleClickDelete(item)}>
						삭제
					</CRButton.Default>
				</S.ButtonContainer>
			);
		}
		return (
			<S.ButtonContainer>
				<CRButton.Default
					size='xSmall'
					palette='gray'
					type='outlined'
					onClick={handleClickPending(item)}>
					보류
				</CRButton.Default>
				<CRButton.Default size='xSmall' type='outlined' onClick={handleClickCancel(item)}>
					취소
				</CRButton.Default>
			</S.ButtonContainer>
		);
	}, []);

	const ContractTypeComponent = useCallback(
		(type: string) => commonCodes?.CMN136.find((item) => item.value === type)?.label,
		[commonCodes],
	);

	const renderCustomContractDate = (val: string) => (val ? dayjs(val).format('YYYY.MM.DD') : '-');

	const renderCustomServicePeriod = (val?: string) => {
		if (!val) return ' - ';
		const [start, end] = val.split(' ~ ');
		const startDate = start ? dayjs(start).format('YYYY.MM.DD') : '';
		const endDate = end ? dayjs(end).format('YYYY.MM.DD') : '';

		return `${startDate} - ${endDate}`;
	};

	const handleSubmitContractAdd = (contractId: number) => {
		navigate(
			`/${RouterPath.myTaskOperate().contract.uniqueKey}/${contractId}/${
				RouterPath.myTaskOperate().recipientBasic.uniqueKey
			}`,
		);
	};

	const handleClickAddContract = () => {
		showDialog(({ hideDialog }) => <ContractCreateDialog onSubmit={handleSubmitContractAdd} />);
	};

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setKeyword(searchValue);
		setPageInfo({ ...pageInfo, page: 1 });
	};

	useEffect(() => {
		if (data.totalPages) {
			setPageInfo({ ...pageInfo, totalPages: data.totalPages });
		}
	}, [data.totalPages]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev.managerFilter.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...prev.managerFilter,
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				stickyMode
				filters={filters}
				searchValue={searchValue}
				pageInfo={pageInfo}
				onChangeSearchValue={handleSearchValue}
				onSearch={handleSearch}
				onChangePageInfo={(value) => setPageInfo(value)}
				showRefresh
				placeholder='수급자명, 생년월일, 전화번호 검색'
				showViewCount
				onRefresh={refetch}
				renderRightButton={
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						size='xSmall'
						onClick={handleClickAddContract}>
						계약 추가
					</CRButton.IconButton>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableScrollContainer ref={elementRef}>
					<CRTable.Root>
						<CRTable.Head heads={CONTRACT_MANAGEMENT_HEADER_CONFIG} offset={-47} />
						<CRTable.Body>
							{data.content.map((item, index) => (
								<CRTable.Row
									key={item.id}
									onClick={() => handleClickItem(item)}
									item={{ ...item, index: index + 1 }}
									renderKeys={[
										'index',
										'status',
										'contractType',
										'recipient',
										'birth',
										'services',
										'contractDate',
										'servicePeriod',
										'employees',
										'contractEndDate',
										'manager',
										'id',
									]}
									customStyle={{
										index: {
											textAlign: 'right',
										},
									}}
									customRender={{
										status: StatusComponent,
										id: ButtonComponent,
										contractDate: renderCustomContractDate,
										servicePeriod: renderCustomServicePeriod,
										birth: displayShortDate,
										contractEndDate: renderCustomContractDate,
										contractType: ContractTypeComponent,
									}}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.TableScrollContainer>
			)}
		</S.Container>
	);
}

export default ContractManagementTable;
