import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from 'lib/hook/react-query/base/useCRMutation';

export const useSyncPCorp = useCRMutation(endpoint.syncPCorp.key, 'syncPCorp');

export const useSyncMonitoring = useCRMutation(
	endpoint.syncMonitoringData.key,
	'syncMonitoringData',
);

export const useSyncMonitoringIndividual = useCRMutation(
	endpoint.syncMonitoringIndividual.key,
	'syncMonitoringIndividualData',
);

export const useSaveScheduleMonitoringDetail = useCRMutation(
	endpoint.saveScheduleMonitoringDetail.key,
	'saveScheduleMonitoringDetail',
);

export const useUpdateWorkConfirmStatus = useCRMutation(
	endpoint.updateWorkConfirmStatus.key,
	'updateWorkConfirmStatus',
);

export const useUpdateWorkDuplicationStatus = useCRMutation(
	endpoint.updateWorkDuplicationStatus.key,
	'updateWorkDuplicationStatus',
);

export const useUpdateMonitoringDetailItemCompleteStatus = useCRMutation(
	endpoint.updateMonitoringDetailItemCompleteStatus.key,
	'updateMonitoringDetailItemCompleteStatus',
);

export const useUpdateMonitoringDetailsCompleteStatus = useCRMutation(
	endpoint.updateMonitoringDetailsCompleteStatus.key,
	'updateMonitoringDetailsCompleteStatus',
);

export const useUpdateMonitoringCompleteStatus = useCRMutation(
	endpoint.updateMonitoringCompleteStatus.key,
	'updateMonitoringCompleteStatus',
);
