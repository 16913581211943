import React from 'react';

import { ScheduleItem } from 'types/view/task';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import * as S from './styles';

interface Props {
	item: ScheduleItem;
	isChecked?: boolean;
	onClickCheck?: (scheduleId: string) => void;
	onClickRow?: (subTask: ScheduleItem) => void;
}

function CollapsibleRow({
	item,
	isChecked = true,
	onClickCheck,
	onClickRow,
}: Props): React.ReactElement {
	return (
		<S.TableBodyRow onClick={() => onClickRow?.(item)}>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }}>
				{`${item.name} (${item.userNumber})`}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>{item.serviceType}</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding
				onClick={(e) => e.stopPropagation()}
				style={{ textAlign: 'left' }}>
				<CRCheckBox checked={isChecked} onChange={() => onClickCheck?.(item.id)}>
					공단 입력 완료
				</CRCheckBox>
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding />
		</S.TableBodyRow>
	);
}

export default CollapsibleRow;
