import CRDialog from 'components/base/CRDialog';
import React, { useState } from 'react';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Toast } from 'components/base/CRToast';
import { useCancelReservationMessage } from 'lib/hook/react-query';
import dayjs from 'dayjs';
import { endpoint } from 'lib/service/Api/endpoint';
import useDialog from 'lib/hook/util/useDialog';
import * as S from './styles';

interface Props {
	sendId?: number;
	onSuccess?: () => void;
	cancelInfo: {
		templateName?: string;
		messageTitleName?: string;
		sendReservationDate?: string;
	};
}

export default function ReservedMessageCancelDialog({ sendId, cancelInfo, onSuccess }: Props) {
	const [cancellationReason, setCancellationReason] = useState('');
	const { hideDialog } = useDialog();
	const { mutate: cancelReservationMessage } = useCancelReservationMessage((client, returnData) => {
		if (returnData?.sendId) {
			Toast.success('정상적으로 예약 발송 취소를 하였습니다.');
			onSuccess?.();
		}
	});

	const handleChangeCancellationReason = (value: string) => {
		setCancellationReason(value);
	};

	const handleSubmit = () => {
		if (!sendId) return;
		cancelReservationMessage({
			sendId,
			cancelDesc: cancellationReason,
		});
	};

	return (
		<CRDialog
			type='S'
			title='발송 취소'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<CRInputLabel label='문서 유형'>
						<CRInput.Default disabled value={cancelInfo?.templateName || ''} />
					</CRInputLabel>
					<CRInputLabel label='제목'>
						<CRInput.Default disabled value={cancelInfo?.messageTitleName || ''} />
					</CRInputLabel>
					<CRInputLabel label='발송 예정 일시'>
						<CRInput.Default
							disabled
							value={
								cancelInfo?.sendReservationDate
									? dayjs(cancelInfo.sendReservationDate).format('YYYY.MM.DD HH:mm')
									: ''
							}
						/>
					</CRInputLabel>
					<CRInputLabel
						label='취소 사유'
						isRequired
						renderRightAddon={`${cancellationReason?.length ?? 0}/20`}>
						<CRInput.Default
							onChange={handleChangeCancellationReason}
							value={cancellationReason}
							maxLength={20}
							placeholder='취소 사유 입력'
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						onClick={handleSubmit}
						disabled={!cancellationReason}>
						발송 취소
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
