import React, { useMemo } from 'react';
import { OtherCorpWork } from 'types/api/employee';
import { CheckOption } from 'components/base/Selections/type';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { v4 } from 'uuid';
import { EditableEmployeeFormTypes } from 'lib/provider/employee';
import * as S from './styles';
import OtherCorpWorkView from './OtherCorpWorkView';
import OtherCorpWorkEdit from './OtherCorpWorkEdit';

interface Props {
	item?: OtherCorpWork[];
	options: {
		day: CheckOption[];
	};
}

function OtherJobForm({ item, options }: Props): React.ReactElement {
	const { isFormEditable, startChangeEditSection, cancelChangeEditSection, isEditSectionChanging } =
		useEmployeePage();

	const currentEmployeeOtherCorpWorks = useMemo(
		() =>
			item?.map((otherCorpWork) => ({
				otherCorpWorkId: otherCorpWork.otherCorpWorkId,
				personalBusinessOwnerYn: [
					otherCorpWork?.personalBusinessOwnerYn
						? {
								label: '사업자',
								value: true,
						  }
						: {
								label: '근로자',
								value: false,
						  },
				],
				otherCorpNm: otherCorpWork.otherCorpNm,
				otherCorpPhoneNo: otherCorpWork.otherCorpPhoneNo,
				remark: otherCorpWork.remark,
				times: otherCorpWork.otherCorpWorkStartEndTimes.map((time) => ({
					...time,
					id: v4(),
					days: time.otherCorpWorkDays,
				})),
			})) as any,
		[item],
	);

	const onStartChangeSection = () => {
		startChangeEditSection(EditableEmployeeFormTypes.타근무);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableEmployeeFormTypes.타근무) ? (
				<OtherCorpWorkEdit
					items={currentEmployeeOtherCorpWorks}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<OtherCorpWorkView
					items={currentEmployeeOtherCorpWorks}
					options={options}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}

export default React.memo(OtherJobForm);
