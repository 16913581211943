import { IntegratedAssessmentDesireCommunicationStatusQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireCommunicationStatusQuestions: IntegratedAssessmentDesireCommunicationStatusQuestion[] =
	[
		{
			key: '의사소통능력',
			label: '의사\n소통',
			required: ['generate'],
			answer: [
				{
					label: '모두 이해하고 의사를 표현한다',
					value: '모두 이해하고 의사를 표현한다',
				},
				{
					label: '대부분 이해하고 의사를 표현한다',
					value: '대부분 이해하고 의사를 표현한다',
				},
				{
					label: '가끔 이해하고 의사를 표현한다',
					value: '가끔 이해하고 의사를 표현한다',
				},
				{
					label: '거의 이해하지 못하고 의사를 전달하지 못한다.',
					value: '거의 이해하지 못하고 의사를 전달하지 못한다.',
				},
			],
		},
		{
			key: '발음능력',
			label: '발음\n능력',
			required: ['generate'],
			answer: [
				{
					label: '정확하게 발음이 가능하다',
					value: '정확하게 발음이 가능하다',
				},
				{
					label: '웅얼거리는 소리로만 한다.',
					value: '웅얼거리는 소리로만 한다.',
				},
				{
					label: '간혹 어눌한 발음이 섞인다.',
					value: '간혹 어눌한 발음이 섞인다.',
				},
				{
					label: '전혀 발음하지 못한다.',
					value: '전혀 발음하지 못한다.',
				},
			],
		},
	];
