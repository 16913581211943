import React from 'react';

import * as S from './styles';

function IntegratedAssessmentDesireRehabilitationStatusFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<colgroup>
				<col style={{ width: '12rem' }} />
				<col style={{ width: '80%' }} />
			</colgroup>
			<tr>
				<S.TableHeaderColumn colSpan={2}>확인</S.TableHeaderColumn>
			</tr>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesireRehabilitationStatusFormHeader);
