import styled, { RuleSet, css } from 'styled-components';
import Typography from 'common/typography';
import { InputStatus } from '../type';

type InputAttrObj = {
	[key in InputStatus]: RuleSet<object>;
};

const inputAttr: Pick<InputAttrObj, 'default' | 'focus' | 'error' | 'disabled'> = {
	default: css`
		box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
		background: ${(props) => props.theme.colors.gray100};
	`,
	focus: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.gray10} inset;
		background: ${(props) => props.theme.colors.gray99};
	`,
	error: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.primary60} inset;
		background: ${(props) => props.theme.colors.primary99};
	`,
	disabled: css`
		color: ${(props) => props.theme.colors.gray60};
		background: ${(props) => props.theme.colors.gray95};
	`,
};

export const Container = styled.div<{ $isDisabled?: boolean }>`
	width: 100%;
	min-width: 16rem;
	cursor: ${(props) => (props.$isDisabled ? 'no-drop' : 'pointer')};
`;

export const InputContainer = styled.div<{
	$typography: keyof typeof Typography;
	$height?: string;
	$readOnly?: boolean;
	$isFocused: boolean;
	$error: boolean;
	disabled: boolean;
	$numberOfLines: number;
	$showBorder: boolean;
	$fixedHeight: boolean;
}>`
	display: grid;
	height: ${(props) => props.$height || 'auto'};
	border-radius: 0.8rem;
	box-sizing: border-box;
	overflow: ${(p) => (p.$fixedHeight ? 'hidden' : 'auto')};

	${inputAttr.default};
	${(props) => !props.$readOnly && props.$isFocused && inputAttr.focus};
	${(props) => !props.$readOnly && props.$error && inputAttr.error};
	${(props) => props.disabled && inputAttr.disabled}

	${(props) =>
		!props.$showBorder &&
		css`
			box-shadow: none;
			background-color: transparent;
		`};

	&::after {
		content: attr(data-test) ' ';
		white-space: pre-wrap;
		visibility: hidden;
		padding: 0.5rem;
		grid-area: 1 / 1 / 2 / 2;
		padding: 1.6rem;
	}

	& > textarea {
		${(props) => props.theme.typography[props.$typography]};
		width: 100%;
		min-width: 16rem;
		background-color: ${(props) => props.theme.colors.gray100};
		color: ${(props) => (!props.$error ? props.theme.colors.gray10 : props.theme.colors.primary60)};
		padding: 1.6rem;
		box-sizing: border-box;
		min-height: ${(props) => `${props.$numberOfLines * 2.8 + 3.2}rem`};
		background: transparent;
		border: none;
		outline: none;
		outline-style: none;
		resize: none;
		overflow: hidden;
		overflow-y: ${(p) => (p.$fixedHeight ? 'overlay' : 'hidden')};
		grid-area: 1 / 1 / 2 / 2;
		cursor: inherit;

		${(props) =>
			props.$readOnly &&
			css`
				box-shadow: none;
				background-color: transparent;
			`};

		${(props) =>
			props.disabled &&
			css`
				color: ${(props) => props.theme.colors.gray60};
			`};

		&::placeholder {
			${(props) => props.theme.typography[props.$typography]};
			color: ${(props) => props.theme.colors.gray60};
		}
	}
`;

export const Label = styled.label`
	${(props) => props.theme.typography.label};
`;

export const HelperText = styled.div<{ status: 'default' | 'error' }>`
	${(props) => props.theme.typography.label};
	color: ${(props) =>
		props.status === 'default' ? props.theme.colors.gray60 : props.theme.colors.primary60};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
	user-select: none;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;
