import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const EMPLOYEE_SALARY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '7.5rem',
		label: '연월',
		sortKey: '연월',
	},
	{
		width: '7rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '10rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '9.5rem',
		label: '수급자',
		sortKey: '수급자',
	},
	{
		width: '29rem',
		label: '급여종류',
		sortKey: '급여종류',
	},

	{
		width: '7rem',
		label: '근로일정',
		sortKey: '근로일정',
		textAlign: 'left',
	},
	{
		width: '29rem',
		label: '근로시간',
		sortKey: '근로시간',
		textAlign: 'left',
	},
	{
		width: '12rem',
		label: '임금',
		sortKey: '임금',
		textAlign: 'right',
	},
	{
		width: '10rem',
		label: '공제액',
		sortKey: '공제액',
		textAlign: 'right',
	},
	{
		width: '10rem',
		label: '본인부담금',
		sortKey: '본인부담금',
		textAlign: 'right',
	},
	{
		width: '12rem',
		label: '고용보험 실업급여',
		sortKey: '고용보험 실업급여',
		textAlign: 'right',
	},
	{
		width: '11.2rem',
		label: '실 수령액',
		sortKey: '실 수령액',
		textAlign: 'right',
	},
	{
		width: '7.5rem',
		label: '검수 상태',
		sortKey: '검수 상태',
	},
	{
		width: '7.5rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
	{
		width: '8.5rem',
		label: '사회복지사',
		sortKey: '사회복지사',
		textAlign: 'left',
	},
];

export const EMPLOYEE_SALARY_SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: 'CMN028.10',
		label: '대기',
		color: 'gray',
	},
	{
		key: 'CMN028.20',
		label: '발송중',
		color: 'yellow',
	},
	{
		key: 'CMN028.30',
		label: '성공',
		color: 'green',
	},
	{
		key: 'CMN028.40',
		label: '재발송',
		color: 'green',
	},
	{
		key: 'CMN028.99',
		label: '실패',
		color: 'red',
	},
];

export const EMPLOYEE_SALARY_COMPLETE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
];
