import React, { useEffect, useMemo, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import RenewalVisitCareContractForm from 'components/domain/form/RenewalVisitCareContractForm';
import RenewalVisitCareServiceForm from 'components/domain/form/RenewalVisitCareServiceForm';
import RenewalVisitBathServiceForm from 'components/domain/form/RenewalVisitBathServiceForm';
import RenewalVisitNursingServiceForm from 'components/domain/form/RenewalVisitNursingServiceForm';
import RenewalVisitBathContractForm from 'components/domain/form/RenewalVisitBathContractForm';
import RenewalVisitNursingContractForm from 'components/domain/form/RenewalVisitNursingContractForm';
import CRPortal from 'components/base/CRPortal';
import CRTab from 'components/base/CRTab';
import { CheckOption } from 'components/base/Selections/type';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';
import { ContractRenewalDetailServiceDTO } from '../../../types/dto';

function RenewalServiceInspectionPage(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractRenewalTask();

	const [tabs, setTabs] = useState<CheckOption<ContractRenewalDetailServiceDTO>[]>([]);
	const [currentService, setCurrentService] =
		useState<CheckOption<ContractRenewalDetailServiceDTO> | null>(tabs[0]);

	const handleChangeTab = (current: CheckOption<ContractRenewalDetailServiceDTO>) => {
		setCurrentService(current);
	};

	const forms = useMemo(() => {
		if (currentService?.data?.serviceTypeCd === 'CMN006.10') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <RenewalVisitCareServiceForm currentService={currentService} />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <RenewalVisitCareContractForm currentService={currentService} />,
				},
			];
		}
		if (currentService?.data?.serviceTypeCd === 'CMN006.20') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <RenewalVisitBathServiceForm currentService={currentService} />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <RenewalVisitBathContractForm currentService={currentService} />,
				},
			];
		}
		if (currentService?.data?.serviceTypeCd === 'CMN006.30') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <RenewalVisitNursingServiceForm currentService={currentService} />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <RenewalVisitNursingContractForm currentService={currentService} />,
				},
			];
		}

		return [];
	}, [currentService]);

	useEffect(() => {
		const newTabs = (form?.getValues('services') ?? []).map((item) => ({
			label: commonCodes?.CMN006?.find((code) => code.value === item.serviceTypeCd)?.label ?? '',
			value: `${item.serviceContractId}-${item.serviceTypeCd}`,
			data: item,
		}));
		setTabs(newTabs);
		setCurrentService(newTabs[0]);
	}, [form.getValues('services')?.length]);

	return (
		<>
			<CRPortal id='task_layout_portal'>
				<CRTab.Contract
					items={tabs}
					defaultActiveKey={currentService?.value}
					onChange={handleChangeTab}
					hideDelete
					canAdd={tabs.length < 3}
					addDisabled={disabled}
				/>
			</CRPortal>
			{!!forms.length && (
				<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
					{forms.map((form) => (
						<Accordion.Item value={form.id} asChild>
							<TaskAccordion.Item>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					))}
				</Accordion.Root>
			)}
		</>
	);
}

export default React.memo(RenewalServiceInspectionPage);
