import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	max-height: calc(100vh - 4.8rem);
	overflow: hidden;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	box-sizing: border-box;
`;

export const BodyContainer = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 0rem 2.4rem 2.4rem 2.4rem;
	gap: 1.6rem;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const HeaderTitle = styled.h3`
	flex: 1;
`;

export const CloseIcon = styled.img`
	cursor: pointer;
`;
