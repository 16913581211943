import React from 'react';

import Colors from 'common/colors';
import CRInputLabel from 'components/base/CRInputLabel';
import InformationSheet from 'components/ui/InformationSheet';
import { SalaryChangeOfferPlanBaseInfo } from 'types/view/integratedAssessment';

import * as S from './styles';

interface Props {
	item?: SalaryChangeOfferPlanBaseInfo;
}

function IntegratedSalaryOfferChangeRecipientInfoForm({ item }: Props): React.ReactElement {
	return (
		<S.Container>
			<CRInputLabel label='기본정보' type='left' isRequired>
				<InformationSheet
					align='flex-start'
					items={[
						[
							{
								label: '이름',
								value: item?.name ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '성별',
								value: item?.gender ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '생년월일',
								value: item?.birthday ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정번호',
								value: item?.longTermNo ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정등급',
								value: item?.longTermGrade ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
					]}
					type='gray'
				/>
			</CRInputLabel>
		</S.Container>
	);
}

export default IntegratedSalaryOfferChangeRecipientInfoForm;
