import CRDialog from 'components/base/CRDialog';
import React, { useMemo } from 'react';
import useDialog from 'lib/hook/util/useDialog';
import { Controller, useForm } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { LoadMonitoringDataForm } from 'types/view/monitoring';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadLongtermData } from 'lib';
import { MonitoringSyncDateDTO, MonitoringUploadTypeCds } from 'types/api';
import { CRText } from 'components/base/CRText';
import dayjs from 'dayjs';
import * as S from './styles';

interface Props {
	onSync: (data: LoadMonitoringDataForm) => void;
	completeSyncData: MonitoringSyncDateDTO[];
}

export function PCorpSyncDialog({ completeSyncData, onSync }: Props) {
	const { hideDialog } = useDialog();

	const {
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<LoadMonitoringDataForm>({
		resolver: yupResolver(LoadLongtermData),
	});

	const onSubmit = (data: LoadMonitoringDataForm) => {
		onSync?.(data);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	const dataTypes = useMemo(() => {
		const PCorpRFID = completeSyncData?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_기타_RFID_동기화,
		);

		const PCorpSchedule = completeSyncData?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_일정계획_동기화,
		);

		const PCorpTag = completeSyncData?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_태그_동기화,
		);
		return [
			{
				label: (
					<S.CheckBoxContainer>
						<CRText text='태그 내역' typography='body' color='gray10' />
						<CRText
							text={
								PCorpTag?.uploadStartDate
									? `최근 요청 일시 : ${dayjs(PCorpTag?.uploadStartDate).format(
											'YYYY.MM.DD HH:mm',
									  )}`
									: ''
							}
							typography='label'
							color='gray60'
						/>
					</S.CheckBoxContainer>
				),
				value: MonitoringUploadTypeCds.공단_태그_동기화,
			},
			{
				label: (
					<S.CheckBoxContainer>
						<CRText text='일정 계획' typography='body' color='gray10' />
						<CRText
							text={
								PCorpSchedule?.uploadStartDate
									? `최근 요청 일시 : ${dayjs(PCorpSchedule?.uploadStartDate).format(
											'YYYY.MM.DD HH:mm',
									  )}`
									: ''
							}
							typography='label'
							color='gray60'
						/>
					</S.CheckBoxContainer>
				),
				value: MonitoringUploadTypeCds.공단_일정계획_동기화,
			},
			{
				label: (
					<S.CheckBoxContainer>
						<CRText text='RFID 전송내용' typography='body' color='gray10' />
						<CRText
							text={
								PCorpRFID?.uploadStartDate
									? `최근 요청 일시 : ${dayjs(PCorpRFID?.uploadStartDate).format(
											'YYYY.MM.DD HH:mm',
									  )}`
									: ''
							}
							typography='label'
							color='gray60'
						/>
					</S.CheckBoxContainer>
				),
				value: MonitoringUploadTypeCds.공단_기타_RFID_동기화,
			},
		];
	}, [completeSyncData]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='공단 데이터 불러오기'
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel isRequired label='데이터 종류'>
								<CRCheckBoxGroup
									style={{
										width: '100%',
									}}
									direction='col'
									onChange={onChange}
									appearanceType='button'
									checkType='multiple'
									type='checkbox'
									gap={0.8}
									value={value}
									options={dataTypes}
								/>
							</CRInputLabel>
						)}
						name='dataTypes'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='연월'>
								<CRInput.YearMonthPicker
									placeholder='연월 선택'
									currentValue={value}
									onChangeValue={onChange}
								/>
							</CRInputLabel>
						)}
						name='yearMonth'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid}
						onClick={submitForm}>
						불러오기
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
