import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_OWN_EXPENSE_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '13.6rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '12rem',
		label: '변경 일자',
		sortKey: '변경 일자',
	},
	{
		width: '12rem',
		label: '본인부담율',
		sortKey: '본인부담율',
	},
	{
		width: '100%',
		label: '변경 사유',
		sortKey: '변경 사유',
	},
	{
		width: '12rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '12rem',
		label: '',
		sortKey: '',
	},
];

export const RECIPIENT_OWN_EXPENSE_HISTORY_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '현재',
		color: 'green',
	},
	{
		key: '이전',
		label: '이전 이력',
		color: 'gray',
	},
];
