import React, { useEffect, useRef, useState } from 'react';

import { useGetGenerateIntegratedAssessmentOpinion } from 'lib/hook/react-query';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { CRText } from 'components/base/CRText';
import CRSpinner from 'components/base/CRSpinner';
import useDialog from 'lib/hook/util/useDialog';
import * as S from './styles';

interface Props {
	params: {
		수급자정보: {
			성별: string;
			나이?: number;
			등급?: number;
			몸무게?: number;
			키?: number;
		};
		낙상위험도_점수?: number;
		욕창위험도_점수?: number;
		인지기능검사_점수?: number;
		[k: string]: any;
	};
	hideDialog?: () => void;
	onSuccess?: (data: string) => void;
}

function IntegratedAssessmentGenerateOpinionDialog({
	params,
	hideDialog,
	onSuccess,
}: Props): React.ReactElement {
	const { mutateAsync: getGenerateIntegratedAssessmentOpinion } =
		useGetGenerateIntegratedAssessmentOpinion();

	const [data, setData] = useState<string>();

	const isFetched = useRef<boolean>();

	const handleGenerateIntegratedAssessmentOpinion = async () => {
		const body = JSON.stringify(params);
		const res = await getGenerateIntegratedAssessmentOpinion({
			body,
		});
		if (res?.code !== ResponseCode.SUCCESS || !res?.data) {
			Toast.error('샘플 불러오기에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			hideDialog?.();
			return;
		}
		Toast.success('정상적으로 종합 의견 샘플을 불러왔습니다.');
		setData(res.data);
	};

	useEffect(() => {
		if (data) {
			// 줄바꿈 적용.
			onSuccess?.(data.replaceAll(/\. ?/g, '.\n').trim());
			hideDialog?.();
		}
	}, [data]);

	useEffect(() => {
		if (!isFetched.current) {
			handleGenerateIntegratedAssessmentOpinion();
			isFetched.current = true;
		}
	}, []);

	return (
		<CRDialog
			onClickClose={hideDialog}
			showCloseButton={false}
			showHeader={false}
			body={
				<FlexContainer align='center' direction='column' gap='2rem' padding='2.4rem 0 0 0'>
					<CRSpinner floatMode={false} size='4rem' />
					<FlexContainer align='center' direction='column'>
						<CRText typography='body' text='종합 의견 샘플 불러오는 중' />
						<CRText typography='body' color='gray60' text='(약 5~15초 소요)' />
					</FlexContainer>
				</FlexContainer>
			}
			footer={
				<FlexContainer justify='center' width='100%'>
					<S.ButtonContainer>
						<CRButton.Default type='tonal' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
					</S.ButtonContainer>
				</FlexContainer>
			}
		/>
	);
}

export default React.memo(IntegratedAssessmentGenerateOpinionDialog);
