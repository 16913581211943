import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 78rem;
	box-sizing: border-box;
	gap: 2.4rem;
`;

export const Table = styled.table`
	width: 100%;
	border-collapse: separate;
	overflow-x: scroll;

	&,
	th,
	td {
		border: 0.1rem solid ${(props) => props.theme.colors.gray90};
		border-collapse: collapse;
	}
`;

export const Description = styled.table`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray50}
`;

export const TableHeader = styled.thead`
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableHeaderColumn = styled.th`
	${(props) => props.theme.typography.labelB}
	color: ${(props) => props.theme.colors.gray10};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
	height: 4.4rem;
	& + & {
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}
`;

export const TableHeaderColumnWithPadding = styled(TableHeaderColumn)`
	padding: 1.6rem;
	text-align: left;

	&:first-of-type {
		padding-left: 2.4rem;
	}

	&:last-of-type {
		padding-right: 2.4rem;
	}
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	cursor: pointer;
	height: 4.4rem;
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray00};
	white-space: pre-wrap;
	box-sizing: border-box;
	vertical-align: middle;
	padding: 0rem 1.6rem;

	&:first-of-type {
		border-left: none;
	}
`;

export const TableContentContainer = styled.div`
	${(props) => props.theme.typography.label}
	text-align: center;
	width: 4rem;
`;

export const SelectItemContainer = styled.div`
	background: ${(props) => props.theme.colors.white};
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid var(--caring-theme-ref-neutral-variant-neutral-variant90_lighten, #e1e3e3);
	background: var(--caring-theme-ref-neutral-variant-neutral-variant100, #fff);
	width: 8rem;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
		0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
	div {
		${(p) => p.theme.typography.body}
		display: flex;
		justify-content: start;
		padding: 1rem 0 1rem 1.6rem;
		width: 100%;
	}
`;
export const TriggerContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 4.6rem;
	button {
		display: block;
		border: none;
		width: 4rem;
		padding: 0;
		background-color: white;
		${(p) => p.theme.typography.body}
	}
`;
