import React, { useMemo } from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import { Controller } from 'react-hook-form';
import * as S from './styles';

function MyCenterPcorpCertForm(): React.ReactElement {
	const { form } = useCenterInfo();
	const centerMark = form.watch('centerMark');

	return (
		<S.Container>
			<Controller
				control={form?.control}
				name='pcorpCertiFile'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='공동인증서 (파일)' isRequired>
						<CRInput.FileUploader
							title={value?.[0]?.originFileNm || ''}
							onChange={onChange}
							files={value}
							type='single'
							isAutoCertification
							uploadPath={process.env.REACT_APP_PCORP_FILE_PATH}
							allowedExtension={['.pfx', '.p12']}
							customObjectKey={centerMark}
						/>
					</CRInputLabel>
				)}
			/>
			<Controller
				control={form?.control}
				name='pcorpLoginPassword'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='인증서 비밀번호' isRequired>
						<CRInput.Password
							placeholder='인증서 비밀번호 입력'
							onChange={onChange}
							value={value}
							toggleSecret
						/>
					</CRInputLabel>
				)}
			/>
		</S.Container>
	);
}

export default MyCenterPcorpCertForm;
