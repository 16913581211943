import { styled } from 'styled-components';

export const Container = styled.div`
	padding: 0 2.4rem 1.6rem;
`;
export const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 0.8rem;
`;
