import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import _ from 'lodash';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import { SalaryChangeOfferPlanBaseForm } from 'types/view/integratedAssessment';

import * as S from './styles';
import CRCheckBox from '../../../base/Selections/CRCheckBox';

interface Props {
	form: UseFormReturn<SalaryChangeOfferPlanBaseForm>;
	onChange?: () => void;
}

function IntegratedSalaryOfferChangeForm({ form, onChange }: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		data: commonCodes = {
			CMN079: [],
			CMN174: [],
			CMN173: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006', 'CMN174', 'CMN173'] }, commonCodeAdapter);

	const serviceOptions = useMemo(
		() => (commonCodes.CMN006 || [])?.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes],
	);

	const isEtc = form.watch('salaryOfferChangeDivCd')?.value === 'CMN173.99';

	return (
		<S.Container>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='작성일' type='left' showOverflow>
						<CRInput.DatePicker placeholder='작성일 선택' value={value} onChangeValue={onChange} />
					</CRInputLabel>
				)}
				name='date'
				control={form.control}
			/>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='급여종류' type='left'>
						<CRCheckBoxGroup
							ref={ref}
							options={serviceOptions}
							type='radio'
							checkType='single'
							appearanceType='button'
							gap={0.8}
							value={value}
							onChange={onChange}
						/>
					</CRInputLabel>
				)}
				name='serviceType'
				control={form.control}
			/>
			<CRInputLabel label='변경, 반영 구분' type='left' showOverflow>
				<Controller
					render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
						<CRInput.Selector
							placeholder='구분 선택'
							items={commonCodes.CMN173}
							onChangeValue={onChange}
							currentValue={value}
						/>
					)}
					name='salaryOfferChangeDivCd'
					control={form.control}
				/>
				{isEtc && (
					<Controller
						render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
							<CRInput.Default
								placeholder='변경, 반영 구분 입력'
								onChange={onChange}
								value={value}
							/>
						)}
						name='etcSalaryOfferChangeDivNm'
						control={form.control}
					/>
				)}
			</CRInputLabel>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='상담자 의견' type='left'>
						<CRInput.TextArea
							placeholder='상담자 의견 입력'
							numberOfLines={5}
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='opinionDesc'
				control={form.control}
			/>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='급여구분' type='left' showOverflow>
						<S.CheckContainer>
							<S.AllCheckContainer>
								<CRCheckBox
									checked={commonCodes.CMN174.every((code) =>
										value?.find((item) => item.value === code.value),
									)}
									onChange={(e) => {
										onChange(e.target.checked ? commonCodes.CMN174 : []);
									}}>
									전체선택
								</CRCheckBox>
								<S.Divider />
								<S.CheckContentContainer>
									{_.chunk(commonCodes.CMN174, 3).map((codes) => (
										<S.CheckRowContainer>
											{codes.map((code) => (
												<S.CheckColumnContainer>
													<CRCheckBox
														checked={!!value?.find((item) => item.value === code.value)}
														onChange={(e) => {
															if (e.target.checked) {
																onChange([...(value ?? []), code]);
															} else {
																onChange(value.filter((item) => item.value !== code.value));
															}
														}}>
														{code.label}
													</CRCheckBox>
												</S.CheckColumnContainer>
											))}
										</S.CheckRowContainer>
									))}
								</S.CheckContentContainer>
							</S.AllCheckContainer>
						</S.CheckContainer>
					</CRInputLabel>
				)}
				name='salaryDivCdList'
				control={form.control}
			/>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='' type='left'>
						<CRInput.Default placeholder='기타 급여 구분 입력' value={value} onChange={onChange} />
					</CRInputLabel>
				)}
				name='etcSalaryDivNm'
				control={form.control}
			/>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='급여내용' type='left'>
						<CRInput.TextArea
							placeholder='급여 내용 입력'
							numberOfLines={5}
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='salaryDesc'
				control={form.control}
			/>
			<Controller
				render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='변경, 반영 사유' type='left'>
						<CRInput.TextArea
							placeholder='변경, 반영 사유 입력'
							numberOfLines={5}
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='changeDesc'
				control={form.control}
			/>
			<CRInputLabel label='작성자' type='left' isRequired>
				<CRInput.Selector
					disabled
					items={[
						{
							value: myAccountInfo?.centerNm,
							label: `${myAccountInfo?.userNm}(${myAccountInfo?.centerNm})`,
						},
					]}
					currentValue={{
						value: myAccountInfo?.centerNm,
						label: `${myAccountInfo?.userNm}(${myAccountInfo?.centerNm})`,
					}}
				/>
			</CRInputLabel>
		</S.Container>
	);
}

export default IntegratedSalaryOfferChangeForm;
