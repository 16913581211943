import React from 'react';

import { FieldPath, useFormContext } from 'react-hook-form';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';
import { SalaryFeeCdKorKeys } from 'components/domain/dialog/SalaryDetailDialog/types';
import CRFormTableInput from 'components/base/CRFormInput/CRFormTableInput';
import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function MyCenterFinanceVisitNormalFeeForm({ disabled }: Props): React.ReactElement {
	const { watch } = useFormContext<CenterFinanceDetailFormFields>();
	const salaryFee = watch('salaryFee');
	const makeInputName = (field: SalaryFeeCdKorKeys): FieldPath<CenterFinanceDetailFormFields> =>
		`salaryFee.${field}.salaryFeeValue`;

	const getAmt = (field: SalaryFeeCdKorKeys): string => salaryFee?.[`${field}`]?.salaryFeeValue;

	const renderFieldValue = (field: SalaryFeeCdKorKeys) =>
		disabled ? (
			getAmt(field)
		) : (
			<CRFormTableInput
				name={makeInputName(field)}
				disabled={disabled}
				maxLength={11}
				type='comma'
				suffix='원'
			/>
		);

	return (
		<S.Container>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn>일반 1등급</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('요양_일반_일반1등급')}</S.TableValueColumn>
					<S.TableLabelColumn>일반 2등급</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('요양_일반_일반2등급')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 3등급</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('요양_일반_일반3등급')}</S.TableValueColumn>
					<S.TableLabelColumn>일반 4등급</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('요양_일반_일반4등급')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 5등급</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('요양_일반_일반5등급')}</S.TableValueColumn>
					<S.TableLabelColumn colSpan={2} />
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default MyCenterFinanceVisitNormalFeeForm;
