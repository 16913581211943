import React from 'react';

import { CreateScheduleStatus, ScheduleItem } from 'types/view/task';
import * as S from './styles';

interface IProps {
	item: ScheduleItem;
	onClickRow?: (subTask: ScheduleItem) => void;
}

function CollapsibleRow({ item, onClickRow }: IProps): React.ReactElement {
	const renderStatusComponent = (status: CreateScheduleStatus) => {
		if (status === CreateScheduleStatus.BEFORE_REGISTER) {
			return (
				<S.DoneStatusLabelContainer>
					<S.DoneStatusLabel>등록 예정</S.DoneStatusLabel>
				</S.DoneStatusLabelContainer>
			);
		}
		if (status === CreateScheduleStatus.DONE) {
			return (
				<S.DoneStatusLabelContainer>
					<S.DoneStatusLabel>등록 완료</S.DoneStatusLabel>
				</S.DoneStatusLabelContainer>
			);
		}
		if (status === CreateScheduleStatus.MODIFIED) {
			return (
				<S.ModifiedStatusLabelContainer>
					<S.ModifiedStatusLabel>수정된 일정</S.ModifiedStatusLabel>
				</S.ModifiedStatusLabelContainer>
			);
		}
		return (
			<S.EmptyStatusLabelContainer>
				<S.EmptyStatusLabel>빈일정</S.EmptyStatusLabel>
			</S.EmptyStatusLabelContainer>
		);
	};

	const renderServiceDetail = (serviceItem: {
		days: number[];
		startTime: string;
		endTime: string;
	}) => {
		const korDays = ['월', '화', '수', '목', '금', '토', '일'];
		const fullDayText = serviceItem.days.map((e) => korDays[e]).join(', ');
		const fullTimeText = `${serviceItem.startTime}~${serviceItem.endTime}`;
		return (
			<S.ServiceDetail key={`${item.id}_${serviceItem.startTime}_${serviceItem.endTime}`}>
				{`${fullDayText} / ${fullTimeText}`}
			</S.ServiceDetail>
		);
	};

	return (
		<S.TableBodyRow
			onClick={(event) => {
				event.stopPropagation();
				onClickRow?.(item);
			}}>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }}>
				{`${item.name} (${item.userNumber})`}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>{item.serviceType}</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }}>
				{renderStatusComponent(item.status)}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>
				{item.items.map((e) => renderServiceDetail(e))}
			</S.TableBodyColumnWithPadding>
		</S.TableBodyRow>
	);
}

export default CollapsibleRow;
