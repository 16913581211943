import CRDialog from 'components/base/CRDialog';
import React from 'react';
import { RecordingSheetItem } from 'types/view/schedule';
import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import { useMyAccountInfo, useSendRecordingSheet } from 'lib/hook/react-query';
import {
	SendRecordingSheetRequestDTO,
	scheduleRecordingDetailRequestItemDTO,
} from 'types/api/schedule';
import dayjs from 'dayjs';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api';
import { useQueryClient } from '@tanstack/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import * as S from './styles';

interface Props {
	serviceStartDate: Date;
	serviceEndDate: Date;
	items: RecordingSheetItem[];
}

export function SendRecordingSheetDialog({ serviceStartDate, serviceEndDate, items }: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { showDialog, hideDialog } = useDialog();
	const client = useQueryClient();

	const renderBodyText = () => {
		if (!items.length) return '';
		return `${items[0].recipientNm}-${items[0].employeeNm}${
			items[0].employeeNm2 === '-' ? '' : `,${items[0].employeeNm2}`
		}${items.length > 1 ? ` 외 ${items.length - 1}건` : ''}`;
	};

	const { mutateAsync: sendRecordingSheet } = useSendRecordingSheet();

	const processScheduleDetails = (item: RecordingSheetItem) => {
		const scheduleRecordingDetailes: scheduleRecordingDetailRequestItemDTO[] = [];
		if (item.serviceTypeCd === 'CMN006.10' || item.serviceTypeCd === 'CMN006.30') {
			Object.entries(item.recordingDetails).forEach(([key, value]) => {
				value.forEach((item) => {
					const isRfid = !!(item.rfidStart && item.rfidEnd);
					const startTime = isRfid
						? dayjs(item.rfidStart).format('HHmm')
						: dayjs(item.planStart).format('HHmm');
					const endTime = isRfid
						? dayjs(item.rfidEnd).format('HHmm')
						: dayjs(item.planEnd).format('HHmm');
					const totalHourCnt = isRfid ? item.rfidTotalMin : item.planTotalMin;
					const scheduleRecordingDetail: scheduleRecordingDetailRequestItemDTO = {
						serviceDt: item.serviceYmd,
						startTime,
						endTime,
						totalHourCnt,
						recordingSheetSendMethodCd: 'CMN131.ESIGN',
						employees: [
							{
								employeeId: item.employeeId,
								familyYn: !!(item.familyYn === 'Y'),
							},
						],
					};

					scheduleRecordingDetailes.push(scheduleRecordingDetail);
				});
			});
		}
		if (item.serviceTypeCd === 'CMN006.20') {
			Object.entries(item.recordingDetails).forEach(([key, value]) => {
				if (value.length === 2) {
					let startTime = '';
					let endTime = '';
					let isValid = false;
					let totalHourCnt = 0;
					if (value[0].rfidStart && value[1].rfidStart && value[0].rfidEnd && value[1].rfidEnd) {
						if (
							dayjs(value[0].rfidStart).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].rfidStart).format('YYYY.MM.DD HH:mm') &&
							dayjs(value[0].rfidEnd).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].rfidEnd).format('YYYY.MM.DD HH:mm')
						) {
							isValid = true;
							startTime = dayjs(value[0].rfidStart).format('HHmm');
							endTime = dayjs(value[0].rfidEnd).format('HHmm');
							totalHourCnt = value[0].rfidTotalMin;
						}
					} else if (
						value[0].planStart &&
						value[1].planStart &&
						value[0].planEnd &&
						value[1].planEnd
					) {
						if (
							dayjs(value[0].planStart).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].planStart).format('YYYY.MM.DD HH:mm') &&
							dayjs(value[0].planEnd).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].planEnd).format('YYYY.MM.DD HH:mm')
						) {
							isValid = true;
							startTime = dayjs(value[0].planStart).format('HHmm');
							endTime = dayjs(value[0].planEnd).format('HHmm');
							totalHourCnt = value[0].planTotalMin;
						}
					}
					if (isValid) {
						const scheduleRecordingDetail: scheduleRecordingDetailRequestItemDTO = {
							serviceDt: value[0].serviceYmd,
							startTime,
							endTime,
							totalHourCnt,
							recordingSheetSendMethodCd: 'CMN131.ESIGN',
							employees: value.map((employee) => ({
								employeeId: employee.employeeId,
								familyYn: !!(employee.familyYn === 'Y'),
							})),
						};

						scheduleRecordingDetailes.push(scheduleRecordingDetail);
					}
				}
			});
		}
		const isError = scheduleRecordingDetailes.length !== Object.keys(item.recordingDetails).length;
		return isError ? [] : scheduleRecordingDetailes;
	};

	const handleClickSendRecordingSheet = async () => {
		if (!myAccountInfo) return;

		if (items.length === 1 && !Object.keys(items[0].recordingDetails).length) {
			hideDialog();
			if (!items[0]?.recipientId) {
				Toast.error('매칭된 수급자 정보가 존재하지 않습니다.');
				return;
			}
			if (items[0]?.recipientId && !items[0]?.recipientMobilePhoneNo) {
				Toast.error('수급자 연락처가 존재하지 않습니다.');
				return;
			}
			if (!items[0]?.employeeId) {
				Toast.error('매칭된 직원 정보가 존재하지 않습니다.');
				return;
			}
			if (items[0]?.employeeId && !items[0]?.employeeMobilePhoneNo) {
				Toast.error('직원 연락처가 존재하지 않습니다.');
				return;
			}
		}

		const params: SendRecordingSheetRequestDTO[] = items?.map((item) => ({
			centerId: item.centerId,
			centerNm: myAccountInfo.centerNm,
			serviceStartDt: dayjs(serviceStartDate).format('YYYYMMDD'),
			serviceEndDt: dayjs(serviceEndDate).format('YYYYMMDD'),
			serviceTypeCd: item.serviceTypeCd,
			serviceTypeNm: item.serviceTypeNm,
			recipientId: item.recipientId,
			recipientNm: item.recipientNm,
			longTermNo: item.longTermNo,
			scheduleRecordingDetailes: processScheduleDetails(item),
		}));

		const errorSchedules = params.filter((item) => item.scheduleRecordingDetailes.length === 0);

		if (errorSchedules.length === 1 && errorSchedules[0].serviceTypeCd === 'CMN006.20') {
			hideDialog();
			showDialog(() => (
				<CRDialog
					showCloseButton={false}
					title='RFID 시간 불일치'
					body={
						<S.TextContainer>
							방문목욕 진행시 두 직원의 RFID 시간이 일치해야 합니다.
						</S.TextContainer>
					}
					footer={
						<S.ButtonContainer>
							<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
								확인
							</CRButton.Default>
						</S.ButtonContainer>
					}
				/>
			));
		} else {
			// 단체 발송시 recordingDetails가 있는 기록지만 발송하고 요청 성공후 recordingDetails가 없는 기록지가 하나라도 있을시 그 건수만큼 실패했다고 토스트
			const validParams = params.filter((item) => item.scheduleRecordingDetailes.length !== 0);
			const res = await sendRecordingSheet(validParams);
			hideDialog();
			if (res.code === ResponseCode.SUCCESS) {
				client.invalidateQueries([endpoint.getRecordingSheets.key]);
				if (errorSchedules.length) {
					Toast.error(
						`총 ${errorSchedules.length}건의 기록지 발송에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`,
					);
				} else {
					Toast.success(`총 ${items.length}건의 기록지를 발송하였습니다.`);
				}
			}
			if (res.code === ResponseCode.ERROR) {
				Toast.error(res.message);
			}
		}
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='기록지 발송'
			body={<S.Container>{renderBodyText()}의 기록지를 발송합니다.</S.Container>}
			footer={
				<S.ButtonContainer>
					<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default onClick={handleClickSendRecordingSheet}>발송</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
