import React, { useEffect } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMyAccountInfo, useUpdateEmployeeLicense } from 'lib/hook/react-query';
import CRInputMessage from 'components/base/CRInputMessage';
import { EmployeeLicenseForm } from 'types/view/employee';
import { EmployeeLicense } from 'lib';
import {
	EmployeeDTO,
	EmployeeLicenseInfoDTO,
	SaveEmployeeLicenseRequestDTO,
} from 'types/api/employee';
import CRDialog from 'components/base/CRDialog';
import { CheckOption } from 'components/base/Selections/type';
import dayjs from 'dayjs';
import { Toast } from 'components/base/CRToast';
import { endpoint } from 'lib/service/Api/endpoint';
import * as S from './styles';

interface Props {
	item?: EmployeeLicenseInfoDTO;
	currentEmployee?: Partial<EmployeeDTO>;
	licenseTypes: CheckOption[];
	onDelete?: () => void;
	hideDialog: () => void;
}

export default function AddLicenseDialog({
	item,
	currentEmployee,
	licenseTypes,
	hideDialog,
	onDelete,
}: Props) {
	const {
		watch,
		setValue,
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<EmployeeLicenseForm>({
		resolver: yupResolver(EmployeeLicense),
		mode: 'onChange',
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const { mutate: updateEmployeeLicense } = useUpdateEmployeeLicense((client, returnData) => {
		if (returnData?.employeeId) {
			client.invalidateQueries([
				endpoint.getEmployeeLicenseInfo.key,
				{ employeeId: currentEmployee?.employeeId },
			]);
			hideDialog();
			Toast.success(item ? '자격증을 수정했습니다.' : '자격증을 추가했습니다.');
		}
	});
	const licenseTypeCd = watch('licenseTypeCd');

	const onSubmit = async (data: EmployeeLicenseForm) => {
		if (!myAccountInfo?.centerId || !currentEmployee?.employeeId) return;
		const param: SaveEmployeeLicenseRequestDTO = {
			employeeId: currentEmployee.employeeId,
			licenseTypeCd: data.licenseTypeCd,
			licenseNm: data.licenseNm,
			licenseNo: data.licenseNo,
			licenseIssueDate: dayjs(data.licenseIssueDate).format('YYYY-MM-DDT00:00:00'),
			licenseIssueInstitutionNm: data.licenseIssueInstitutionNm,
		};
		if (data.licenseFile?.length) {
			param.licenseFile = {
				fileDetails: data.licenseFile,
			};

			if (item?.licenseFile?.fileId) {
				param.licenseFile = {
					...param.licenseFile,
					fileId: item?.licenseFile.fileId,
				};
			}
		}

		if (item?.employeeLicenseId) {
			param.employeeLicenseId = item?.employeeLicenseId;
		}

		updateEmployeeLicense(param);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	useEffect(() => {
		if (!licenseTypes?.length) return;

		if (licenseTypeCd === 'CMN067.99') {
			setValue('licenseNm', '');
		} else {
			const matchedLicense = licenseTypes.find((item) => item.value === licenseTypeCd);
			if (matchedLicense) setValue('licenseNm', matchedLicense.label);
		}
	}, [licenseTypeCd, licenseTypes]);

	useEffect(() => {
		if (item) {
			setValue('licenseTypeCd', item.licenseTypeCd);
			setValue('licenseNm', item.licenseNm);
			setValue('licenseNo', item.licenseNo);
			setValue('licenseIssueDate', dayjs(item.licenseIssueDate).toDate());
			setValue('licenseIssueInstitutionNm', item.licenseIssueInstitutionNm);
			setValue('licenseFile', item.licenseFile?.fileDetails);
		}
	}, [item]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='자격증'
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.Content>
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => {
								const matchedItem = licenseTypes.find(
									(option) => option.value === watch('licenseTypeCd'),
								);
								const currentValue = matchedItem
									? {
											label:
												licenseTypes.find((option) => option.value === watch('licenseTypeCd'))
													?.label ?? '',
											value: licenseTypes.find((option) => option.value === watch('licenseTypeCd'))
												?.value,
									  }
									: undefined;
								return (
									<CRInputLabel
										label='종류'
										isRequired
										message={
											errors.licenseTypeCd?.message && (
												<CRInputMessage type='error'>
													{errors.licenseTypeCd?.message ?? ''}
												</CRInputMessage>
											)
										}>
										<CRInput.Selector
											items={licenseTypes}
											ref={ref}
											onChangeValue={(item) => onChange(item.value)}
											currentValue={currentValue}
											placeholder='종류 선택'
										/>
									</CRInputLabel>
								);
							}}
							name='licenseTypeCd'
							control={control}
						/>
						{licenseTypeCd === 'CMN067.99' && (
							<Controller
								render={({ field: { ref, onBlur, onChange, value }, formState: { errors } }) => (
									<div>
										<CRInput.Default
											ref={ref}
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											placeholder='직접입력'
										/>
										{errors.licenseNm?.message && (
											<CRInputMessage type='error'>
												{errors.licenseNm?.message ?? ''}
											</CRInputMessage>
										)}
									</div>
								)}
								name='licenseNm'
								control={control}
							/>
						)}

						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel
									isRequired
									label='자격증 번호'
									message={
										errors.licenseNo?.message && (
											<CRInputMessage type='error'>
												{errors.licenseNo?.message ?? ''}
											</CRInputMessage>
										)
									}>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='자격증 번호 입력'
									/>
								</CRInputLabel>
							)}
							name='licenseNo'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel
									isRequired
									label='발급일'
									message={
										errors.licenseIssueDate?.message && (
											<CRInputMessage type='error'>
												{errors.licenseIssueDate?.message ?? ''}
											</CRInputMessage>
										)
									}>
									<CRInput.DatePicker
										popupPosition={{
											x: 0,
											y: -100,
										}}
										placeholder='발급일 선택'
										onChangeValue={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='licenseIssueDate'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel
									isRequired
									label='발급 기관'
									message={
										errors.licenseIssueInstitutionNm?.message && (
											<CRInputMessage type='error'>
												{errors.licenseIssueInstitutionNm?.message ?? ''}
											</CRInputMessage>
										)
									}>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='발급 기관 입력'
									/>
								</CRInputLabel>
							)}
							name='licenseIssueInstitutionNm'
							control={control}
						/>
						<Controller
							name='licenseFile'
							control={control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='자격증 사본'>
									<CRInput.FileUploader
										title={`${currentEmployee?.korMemberNm} 님의 자격증`}
										onChange={onChange}
										files={value}
										type='multiple'
										onDelete={onDelete}
									/>
								</CRInputLabel>
							)}
						/>
					</S.Content>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
