export enum ContractStatusType {
	Registration = 'CMN082.00',
	Waiting = 'CMN082.10',
	Progress = 'CMN082.20',
	Pending = 'CMN082.70',
	Cancel = 'CMN082.80',
	Complete = 'CMN082.90',
}

// 본인부담금납부방법코드
export enum OwnExpensePayMethodType {
	// 자동이체
	Automatic = 'CMN041.10',
	// 직접이체
	Self = 'CMN041.20',
	// 임금공제
	WageDeduction = 'CMN041.30',
}
