import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
	padding: 0 2.4rem;
	overflow: overlay;
	z-index: 10;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem 0;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const SectionLabel = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;

export const SectionDivider = styled.div`
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const MenuIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 1.2rem;
`;

export const TitleContainer = styled.div<{ $isActive: boolean }>`
	${(props) => props.theme.typography.label};
	display: flex;
	align-items: center;

	${(props) =>
		props.$isActive &&
		css`
			${(props) => props.theme.typography.labelB};
			& > ${MenuIcon} {
				filter: invert(46%) sepia(20%) saturate(5945%) hue-rotate(323deg) brightness(103%)
					contrast(102%);
			}
		`}
`;

export const MenuLabel = styled.div`
	${(props) => props.theme.typography.label};
	flex: 1;
	display: flex;
	height: 4.8rem;
	align-items: center;
`;

export const DisabledMenuContainer = styled.div`
	cursor: pointer;
	color: ${(props) => props.theme.colors.gray10};
	display: flex;
	height: 4.8rem;
	align-items: center;
	padding: 0 1.6rem;
	border-radius: ${(props) => props.theme.radius.radius01};
	text-decoration: none;
`;

export const MenuContainer = styled(NavLink)`
	cursor: pointer;
	color: ${(props) => props.theme.colors.gray10};
	display: flex;
	height: 4.8rem;
	align-items: center;
	padding: 0 1.6rem;
	border-radius: ${(props) => props.theme.radius.radius01};
	text-decoration: none;

	&:hover {
		background-color: ${(props) => props.theme.colors.gray100};
	}

	&.active > ${MenuIcon} {
		filter: invert(46%) sepia(20%) saturate(5945%) hue-rotate(323deg) brightness(103%)
			contrast(102%);
	}

	&.active > ${MenuLabel} {
		${(props) => props.theme.typography.labelB};
		color: ${(props) => props.theme.colors.gray10};
	}
`;
