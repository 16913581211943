import React from 'react';

import { FieldPath, useFormContext } from 'react-hook-form';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';
import { SalaryFeeCdKorKeys } from 'components/domain/dialog/SalaryDetailDialog/types';
import CRFormTableInput from 'components/base/CRFormInput/CRFormTableInput';
import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function MyCenterFinanceVisitBathFeeForm({ disabled }: Props): React.ReactElement {
	const { watch } = useFormContext<CenterFinanceDetailFormFields>();
	const salaryFee = watch('salaryFee');
	const makeInputName = (field: SalaryFeeCdKorKeys): FieldPath<CenterFinanceDetailFormFields> =>
		`salaryFee.${field}.salaryFeeValue`;

	const getAmt = (field: SalaryFeeCdKorKeys): string => salaryFee?.[`${field}`]?.salaryFeeValue;

	const renderFieldValue = (field: SalaryFeeCdKorKeys) =>
		disabled ? (
			getAmt(field)
		) : (
			<CRFormTableInput
				name={makeInputName(field)}
				disabled={disabled}
				maxLength={11}
				type='comma'
				suffix='원'
			/>
		);

	return (
		<S.Container>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn
						style={{
							paddingBlock: '0',
							height: '3.3rem',
						}}
						colSpan={2}>
						60시간 미만
					</S.TableLabelColumn>
					<S.TableLabelColumn
						style={{
							paddingBlock: '0',
							height: '3.3rem',
						}}
						colSpan={2}>
						60시간 이상
					</S.TableLabelColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 40분</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						{renderFieldValue('목욕_60시간미만_가족40분')}
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 40분</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						{renderFieldValue('목욕_60시간이상_가족40분')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 60분</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('목욕_60시간미만_가족60분')}</S.TableValueColumn>
					<S.TableLabelColumn>가족 60분</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('목욕_60시간이상_가족60분')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 40분</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('목욕_60시간미만_일반40분')}</S.TableValueColumn>
					<S.TableLabelColumn>일반 40분</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('목욕_60시간이상_일반40분')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 60분</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('목욕_60시간미만_일반60분')}</S.TableValueColumn>
					<S.TableLabelColumn>일반 60분</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('목욕_60시간이상_일반60분')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가정내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간미만_가정내목욕40분')}
					</S.TableValueColumn>
					<S.TableLabelColumn>가정내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간이상_가정내목욕40분')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가정내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간미만_가정내목욕60분')}
					</S.TableValueColumn>
					<S.TableLabelColumn>가정내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간이상_가정내목욕60분')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>차량내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간미만_차량내목욕40분')}
					</S.TableValueColumn>
					<S.TableLabelColumn>차량내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간이상_차량내목욕40분')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>차량내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간미만_차량내목욕60분')}
					</S.TableValueColumn>
					<S.TableLabelColumn>차량내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('목욕_60시간이상_차량내목욕60분')}
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default MyCenterFinanceVisitBathFeeForm;
