import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: overlay;
`;

export const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	margin: 12rem auto;
	box-sizing: border-box;
`;

export const Logo = styled.img`
	display: flex;
	justify-content: center;
	margin-bottom: 3.2rem;
	height: 4rem;
`;

export const Form = styled.form``;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	margin-top: 3.2rem;
`;
