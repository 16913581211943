import React from 'react';

import CRRequiredMark from 'components/base/CRRequiredMark';
import * as S from './styles';

interface Props {
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesirePhysicalConditionFormHeader({
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.TableHeader>
			<colgroup>
				<col style={{ width: '10%' }} />
				<col />
				<col style={{ width: '10%' }} />
				<col style={{ width: '10%' }} />
				<col />
				<col style={{ width: '10%' }} />
				<col style={{ width: '10%' }} />
				<col />
				<col style={{ width: '10%' }} />
			</colgroup>
			<tr>
				<S.TableHeaderColumn colSpan={2}>
					기본동작 항목
					{showGenerateRequiredFields && <CRRequiredMark />}
				</S.TableHeaderColumn>
				<S.TableHeaderColumn>확인</S.TableHeaderColumn>
				<S.TableHeaderColumn colSpan={2}>
					일상생활동작 항목
					{showGenerateRequiredFields && <CRRequiredMark />}
				</S.TableHeaderColumn>
				<S.TableHeaderColumn>확인</S.TableHeaderColumn>
				<S.TableHeaderColumn colSpan={2}>
					수단적일상생활 항목
					{showGenerateRequiredFields && <CRRequiredMark />}
				</S.TableHeaderColumn>
				<S.TableHeaderColumn>확인</S.TableHeaderColumn>
			</tr>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesirePhysicalConditionFormHeader);
