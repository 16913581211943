import { styled } from 'styled-components';

export const Container = styled.div`
	${(props) => props.theme.typography.bodyB}
	color: ${(props) => props.theme.colors.gray60};
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 3.7rem);
`;
