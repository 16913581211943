import React from 'react';

import * as S from './styles';

interface Props {
	BannerComponent?: React.ReactNode;
	LeftContentComponent?: React.ReactNode;
	RightContentComponent?: React.ReactNode;
}

function InformationLayout({
	BannerComponent,
	LeftContentComponent = <div />,
	RightContentComponent = <div />,
}: Props): React.ReactElement {
	return (
		<S.Container>
			{!!BannerComponent && <S.BannerContainer>{BannerComponent}</S.BannerContainer>}
			<S.ContentContainer>
				<S.ContentLeft>{LeftContentComponent}</S.ContentLeft>
				<S.ContentRight>{RightContentComponent}</S.ContentRight>
			</S.ContentContainer>
		</S.Container>
	);
}

export default InformationLayout;
