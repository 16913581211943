import React from 'react';

import * as S from './styles';

function CRInformationTableContainer({ children }: React.PropsWithChildren): React.ReactElement {
	return (
		<S.Content>
			<S.Table>{children}</S.Table>
		</S.Content>
	);
}

export default CRInformationTableContainer;
