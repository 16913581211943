import { styled } from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	position: sticky;
	z-index: 20;
	top: 2.4rem;
	height: 6.4rem;
	background: ${(props) => props.theme.colors.gray99};
	padding-bottom: 2.4rem;
`;

export const HeaderLeft = styled.div``;

export const HeaderRight = styled.div``;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
`;

export const Duration = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
`;
