import { styled } from 'styled-components';

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	width: 48rem;
	background: ${(props) => props.theme.colors.gray100};
	box-sizing: border-box;
`;

export const Header = styled.div`
	display: flex;
	height: 8rem;
	justify-content: space-between;
	box-sizing: border-box;
	align-items: center;
	padding: 2.4rem;
`;

export const CloseIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const Title = styled.h4``;

export const TextContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
`;

export const StatusLabelContainer = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: 0.05rem 0.8rem;
	box-sizing: border-box;
	border-radius: 1.2rem;
`;

export const DoneStatusLabelContainer = styled(StatusLabelContainer)`
	background-color: ${(props) => props.theme.colors.greenLighten};
`;

export const ModifiedStatusLabelContainer = styled(StatusLabelContainer)`
	background-color: ${(props) => props.theme.colors.yellowLighten};
`;

export const EmptyStatusLabelContainer = styled(StatusLabelContainer)`
	background-color: ${(props) => props.theme.colors.redLighten};
`;

export const StatusLabel = styled.span`
	${(props) => props.theme.typography.label}
`;

export const DoneStatusLabel = styled(StatusLabel)`
	color: ${(props) => props.theme.colors.greenDarken};
`;

export const ModifiedStatusLabel = styled(StatusLabel)`
	color: ${(props) => props.theme.colors.yellowDarken};
`;

export const EmptyStatusLabel = styled(StatusLabel)`
	color: ${(props) => props.theme.colors.redDarken};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	height: calc(100vh - 21.7rem);
	padding: 0 2.4rem 2.4rem;
	overflow-y: scroll;
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
`;

export const EditButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const StatusContainer = styled.div``;

export const ContentItemContainer = styled.div`
	margin-top: 1.6rem;
`;

export const ContentTitle = styled.p`
	display: flex;
	align-items: center;
`;

export const RequiredMark = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.primary60};
`;

export const SubTitle = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
`;

export const ScheduleDetail = styled.div`
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
	padding: 2.4rem;
	margin-top: 0.4rem;
`;

export const AccordionContentContainer = styled.div``;

export const AccordionContentItem = styled.div`
	display: flex;
	gap: 0.8rem;
	box-sizing: border-box;
	margin-bottom: 0.8rem;
`;
export const DetailLabel = styled.span`
	${(props) => props.theme.typography.label};
	width: 12rem;
	min-width: 12rem;
	color: ${(props) => props.theme.colors.gray60};
`;

export const DetailValue = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
	width: 100%;
	white-space: break-spaces;
`;

export const TimePickerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	margin-top: 0.8rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const ErrorMessage = styled.p`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.primary60};
	white-space: pre-wrap;
	margin-top: 0.8rem;
`;

export const RadioContainer = styled.div``;

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	width: 16.8rem;
	height: 4.9rem;
	padding: 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	display: flex;
	align-items: center;
	position: relative;
	box-sizing: border-box;
	height: 4.9rem;
	padding: 0.8rem;
	box-sizing: border-box;
	vertical-align: middle;
`;

export const CheckGroupContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	gap: 1.6rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
`;

export const TagInfoContainer = styled.div``;

export const QuestionMark = styled.img`
	width: 1.6rem;
	height: 1.6rem;
	object-fit: contain;
	margin-left: 0.4rem;
	cursor: pointer;
`;

export const TagText = styled.li`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.9rem;
	height: 2.4rem;
	border-radius: 50%;
	background: ${(props) => props.theme.colors.primary90};
`;

export const InputContainer = styled.div``;
