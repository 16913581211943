import React, {
	ChangeEventHandler,
	ForwardedRef,
	KeyboardEvent,
	KeyboardEventHandler,
	forwardRef,
	useState,
} from 'react';

import CRButton, { IButtonProps } from 'components/base/CRButton/CRDefaultButton';
import Assets from 'assets';

import * as S from './styles';

interface IProps {
	buttonOptions: Omit<IButtonProps, 'size'>;
	value?: number | string;
	id?: string;
	selectedValue?: any;
	buttonText?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	onClickButton?: () => void;
	onClickDeleteButton?: () => void;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
}

function CRButtonInput(
	{
		value,
		selectedValue,
		placeholder = '',
		status = 'default',
		id,
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		buttonText = '',
		onChange,
		onClickButton,
		onClickDeleteButton,
		onPressEnter,
		buttonOptions,
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const [isFocused, setIsFocused] = useState(false);
	const onFocus = () => setIsFocused(true);
	const onBlur = () => setIsFocused(false);
	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};
	const renderButtonComponent = () => {
		if (disabled) return null;
		return selectedValue ? (
			<S.Icon src={Assets.icon.close} alt='닫기' onClick={onClickDeleteButton} />
		) : (
			<CRButton
				palette={buttonOptions.palette}
				type={buttonOptions.type}
				size='xSmall'
				onClick={onClickButton}>
				{buttonText}
			</CRButton>
		);
	};

	return (
		<S.Container>
			<S.InputContainer
				$isSelected={selectedValue}
				$isFocused={isFocused}
				$error={status === 'error'}
				disabled={disabled}>
				<input
					ref={ref}
					id={id}
					value={selectedValue || value}
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={onBlur}
					onFocus={onFocus}
					onChange={onChange}
					onKeyUp={onKeyUp}
				/>
				{renderButtonComponent()}
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRButtonInput);
