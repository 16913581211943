import React, { useCallback } from 'react';
import CRTable from 'components/base/CRTable';
import { MessageTabESignHistoryDTO } from 'types/api/send';
import CRStatus from 'components/base/CRStatus';

import { v4 } from 'uuid';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';
import { ESIGN_HISTORY_HEADER_CONFIG, SIGN_STATUS_CONFIG } from './constant';

interface Props {
	isLoading?: boolean;
	items?: MessageTabESignHistoryDTO[];
	onClickRow?: (task: MessageTabESignHistoryDTO) => void;
}

function EmployeeESignTable({ isLoading, items = [], onClickRow }: Props): React.ReactElement {
	const SignStatusBadge = useCallback(
		(value: string | number) => <CRStatus options={SIGN_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	return (
		<S.Container>
			{isLoading ? (
				<CRSpinner />
			) : (
				<CRTable.Root>
					<CRTable.Head heads={ESIGN_HISTORY_HEADER_CONFIG} />
					<CRTable.Body>
						{items?.map((item) => (
							<CRTable.Row
								key={v4()}
								item={item}
								renderKeys={[
									'lastEsignStateNm',
									'lastActionDate',
									'edocNm',
									'edocTitle',
									'signatoryNms',
									'edocSenderNm',
								]}
								customRender={{
									lastEsignStateNm: SignStatusBadge,
								}}
								onClick={onClickRow}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			)}
		</S.Container>
	);
}

export default EmployeeESignTable;
