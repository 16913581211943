import CRInputLabel from 'components/base/CRInputLabel';
import React from 'react';
import * as S from './styles';

export default function LossCancelHelp() {
	return (
		<S.FormItemContainer>
			<CRInputLabel label='도움말' type='left-sub' showOverflow betweenGap='2.4rem'>
				<S.HelpText>
					<S.HelpTextTitle>상실 취소 신고</S.HelpTextTitle>
					<S.FormItemContainer>
						<S.HelpTextLabel>
							1. 단순 착오, 또는 퇴사 번복으로 인해 상실 신고했던 보험을 취소 처리하는 경우 신청
						</S.HelpTextLabel>
						<S.HelpTextLabel>
							2. 건강보험의 경우, 상실일 기준 14일이 경과한 경우, 근로자 자필서명이 들어간 근로자
							확인서 별도로 제출 필요
						</S.HelpTextLabel>
					</S.FormItemContainer>
				</S.HelpText>
			</CRInputLabel>
		</S.FormItemContainer>
	);
}
