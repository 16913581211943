import { useContext } from 'react';

import { ContractTaskContext } from 'lib/provider//contract';

export default function useContractTask() {
	const context = useContext(ContractTaskContext);
	if (!context) {
		throw new Error('useContractTask must be used within a ContractTaskContext');
	}
	return context;
}
