import React, { useMemo } from 'react';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import dayjs from 'dayjs';
import {
	useCommonCodes,
	useEmployeePageScheduleDetail,
	useEmployees,
	useMyAccountInfo,
	useRecipients,
	useUpdateEmployeeScheduleDetail,
} from 'lib/hook/react-query';
import { employeeScheduleDetailAdapter } from 'lib/adapter/employee';
import { CheckOption } from 'components/base/Selections/type';
import CRSpinner from 'components/base/CRSpinner';
import CRButton from 'components/base/CRButton';
import { EmployeeScheduleDetailForm } from 'types/view/schedule';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeScheduleDetail } from 'lib';
import { SaveScheduleDetailRequestDTO } from 'types/api/schedule';
import { Toast } from 'components/base/CRToast';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeDTO } from 'types/api/employee';
import * as S from './styles';
import EmployeeRecipientInfoView from './EmployeeScheduleInfoView';
import { EmployeeScheduleEdit } from './EmployeeScheduleEdit';

interface Props {
	date: Date;
	currentEmployee?: EmployeeDTO;
}

export default function EmployeeScheduleDetailDialog({ date, currentEmployee }: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { hideDialog } = useDialog();
	const { data: recipientsData, isLoading: recipientsLoading } = useRecipients({
		centerIds: myAccountInfo?.centerId,
	});
	const { data: employeesData, isLoading: employeesLoading } = useEmployees({
		centerIds: myAccountInfo?.centerId ? [myAccountInfo.centerId] : [],
	});

	const { data: scheduleData, isLoading: scheduleDetailLoading } = useEmployeePageScheduleDetail(
		{
			employeeId: currentEmployee?.employeeId,
			centerId: myAccountInfo?.centerId,
			date: dayjs(date).format('YYYYMMDD'),
		},
		employeeScheduleDetailAdapter(myAccountInfo?.centerId),
	);

	const { mutate: updateEmployeeScheduleDetail } = useUpdateEmployeeScheduleDetail(
		(client, returnData) => {
			if (returnData?.employeeId) {
				client.invalidateQueries([
					endpoint.getScheduleDetail.key,
					{
						employeeId: currentEmployee?.employeeId,
						centerId: myAccountInfo?.centerId,
						date: dayjs(date).format('YYYYMMDD'),
					},
				]);
				client.invalidateQueries([
					endpoint.getSchedule.key,
					{
						yearMonth: dayjs(date).format('YYYYMM'),
						centerId: myAccountInfo?.centerId,
						employeeId: currentEmployee?.employeeId,
					},
				]);
				hideDialog();
				Toast.success(`${dayjs(date).format('YYYY.MM.DD')} 일 일정을 수정했습니다.`);
			}
		},
	);

	const methods = useForm<EmployeeScheduleDetailForm>({
		resolver: yupResolver(EmployeeScheduleDetail),
		reValidateMode: 'onChange',
	});

	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN013', 'CMN083'],
	});

	const isLoading = useMemo(
		() => recipientsLoading || employeesLoading || scheduleDetailLoading,
		[recipientsLoading, employeesLoading, scheduleDetailLoading],
	);

	const genderOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN013')
				.map(
					(item) =>
						({
							label: item.korComCdAliasNm,
							value: item.comCdId,
						} as CheckOption),
				),
		[commonCodes],
	);

	const serviceOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN083')
				.map(
					(item) =>
						({
							label: item.korComCdAliasNm,
							value: item.comCdId,
						} as CheckOption),
				),
		[commonCodes],
	);

	const employeesOptions = useMemo(
		() =>
			(employeesData ?? []).map(
				(item) =>
					({
						label: item.korMemberNm,
						value: {
							id: item.employeeId,
							name: item.korMemberNm,
							duty: item.dutyNms,
							birth: item.birthDt,
						},
					} as CheckOption),
			),
		[employeesData],
	);

	const recipientsOptions = useMemo(
		() =>
			(recipientsData?.recipients ?? []).map(
				(item) =>
					({
						label: item.korMemberNm,
						value: {
							id: item.recipientId,
							name: item.korMemberNm,
							duty: '',
							birth: item.birthDt,
						},
					} as CheckOption),
			),
		[recipientsData],
	);

	const onSubmit = (data: EmployeeScheduleDetailForm) => {
		if (!myAccountInfo?.centerId) return;

		const params = data.items.map((item) => {
			const param: SaveScheduleDetailRequestDTO = {
				serviceSchedulePlanId1: Number(item.scheduleId),
				serviceSchedulePlanId2: null,
				deleteYn: item.deleteYn,
				centerId: myAccountInfo?.centerId,
				recipientId: Number(item.recipient?.value.id),
				serviceKindCd: item.service?.value,
				serviceKindNm: item.service?.label || '',
				employeeId1: Number(currentEmployee?.employeeId),
				serviceStartDt: dayjs(date).format('YYYYMMDD'),
				serviceStartTime: item.time.startTime.replace(':', ''),
				serviceEndTime: item.time.endTime.replace(':', ''),
			};

			if (item.employee) {
				param.employeeId2 = Number(item.employee.value.id);
			}

			return param;
		});

		updateEmployeeScheduleDetail(params);
	};

	const submitForm = () => {
		methods.trigger().then((isValid) => {
			if (isValid) {
				methods.handleSubmit(onSubmit)();
			}
		});
	};

	const checkOverlapTime = () => {
		const items = methods.watch('items');

		if (!items?.length) return [];

		const caringCenterItems =
			scheduleData?.caringCenter?.map((item) => ({
				scheduleId: item.serviceSchedulePlanId,
				time: {
					startTime: `${item.serviceStartTime.substring(0, 2)}:${item.serviceStartTime.substring(
						2,
						4,
					)}`,
					endTime: `${item.serviceEndTime.substring(0, 2)}:${item.serviceEndTime.substring(2, 4)}`,
				},
			})) ?? [];

		const totalItems = [...(items.filter((item) => !item.deleteYn) || []), ...caringCenterItems];

		const overlapIds: (string | number)[] = [];

		for (let i = 0; i < items.length; i += 1) {
			for (let j = i + 1; j < totalItems.length; j += 1) {
				const schedule1 = totalItems[i].time;
				const schedule2 = totalItems[j].time;

				if (schedule1.startTime < schedule2.endTime && schedule1.endTime > schedule2.startTime) {
					// 시간이 겹치는 경우
					overlapIds.push(totalItems[i].scheduleId);
					overlapIds.push(totalItems[j].scheduleId);
				}
			}
		}
		return overlapIds;
	};

	const isValid = () => {
		const isAllFilled = methods
			.watch('items')
			?.filter((item) => !item.deleteYn)
			?.every((item) => {
				if (item?.service?.value === 'CMN083.20') {
					return !!(
						item.employee &&
						item.recipient &&
						item.time.endTime?.length === 5 &&
						item.time.startTime.length === 5
					);
				}
				return !!(
					item.recipient &&
					item.time.endTime?.length === 5 &&
					item.time.startTime?.length === 5
				);
			});

		return isAllFilled;
	};

	const overlapIds = checkOverlapTime();

	return (
		<FormProvider {...methods}>
			<form>
				<CRDialog
					title='일정 상세'
					type='S'
					onClickClose={hideDialog}
					body={
						<S.Container>
							{isLoading ? (
								<CRSpinner />
							) : (
								<>
									<S.Title>{dayjs(date).format('M월D일')}</S.Title>
									<EmployeeRecipientInfoView
										item={scheduleData}
										options={{
											gender: genderOptions,
											employees: employeesOptions,
										}}
									/>
									<EmployeeScheduleEdit
										item={scheduleData}
										overlapIds={overlapIds}
										options={{
											services: serviceOptions,
											employees: employeesOptions,
											recipients: recipientsOptions,
										}}
									/>
								</>
							)}
						</S.Container>
					}
					footer={
						<S.ButtonContainer>
							<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
								취소
							</CRButton.Default>
							<CRButton.Default
								palette='primary'
								size='default'
								onClick={submitForm}
								disabled={!isValid() || !!overlapIds?.length}>
								저장
							</CRButton.Default>
						</S.ButtonContainer>
					}
				/>
			</form>
		</FormProvider>
	);
}
