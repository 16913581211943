import React, { useMemo, useState } from 'react';
import CRTab from 'components/base/CRTab';
import RouterPath from 'common/router';
import TagAutomationTab from './TagAutomationTab';

export default function TagAutomationPage() {
	const tagAutomationTab = useMemo(() => RouterPath.scheduleOperate().tagAutomation, []);

	return (
		<CRTab.Default
			defaultActiveKey={tagAutomationTab.key}
			breadCrumb='태그 수정 자동화 결과'
			items={[
				{
					label: tagAutomationTab.label,
					key: tagAutomationTab.key,
					children: <TagAutomationTab />,
				},
			]}
		/>
	);
}
