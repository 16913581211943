import React, { ChangeEvent, useRef, useState } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import * as Popover from '@radix-ui/react-popover';
import { Controller, useForm } from 'react-hook-form';
import * as S from './styles';

interface Props {
	documentId: string;
	taskName: string;
	onClose?: () => void;
}

export default function OpinionPopup({ documentId, taskName, onClose }: Props) {
	const closeRef = useRef<any>();
	const { control, watch } = useForm<{ opinion: string }>();
	const opinion = watch('opinion');
	const onClickDraft = () => {
		closeRef.current?.click();
	};

	const onClickDeny = () => {
		if (!opinion?.length) return alert('반려는 사유가 필수입니다.');
		onClose?.();
		alert(`반려 성공\n사유: ${opinion}`);
		closeRef.current?.click();
		return null;
	};

	const onClickApproval = () => {
		onClose?.();
		alert(`승인 성공\n사유: ${opinion}`);
		return null;
	};

	const renderTitleText = (taskName: string) =>
		`'${taskName}' 결재를 요청받았습니다. 의견을 작성해주세요.`;
	return (
		<S.Container>
			<S.InputContainer>
				{taskName && <S.Title>{renderTitleText(taskName)}</S.Title>}
				<CRInputLabel label='의견'>
					<Controller
						name='opinion'
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInput.TextArea numberOfLines={6} onChange={onChange} value={value} />
						)}
					/>
				</CRInputLabel>
				<CRInputLabel label='마감일' isRequired>
					<CRInput.TimePicker />
				</CRInputLabel>
				<S.ButtonContainer>
					<S.ButtonContainerLeft>
						{/* <Popover.Close className='popover_close_button'> */}
						<CRButton.Default palette='gray' type='tonal' onClick={onClickDraft}>
							임시 저장하고 닫기
						</CRButton.Default>
						{/* </Popover.Close> */}
					</S.ButtonContainerLeft>
					<S.ButtonContainerRight>
						{/* <Popover.Close className='popover_close_button'> */}
						<CRButton.Default onClick={onClickDeny} type='outlined'>
							반려
						</CRButton.Default>
						{/* </Popover.Close> */}
						{/* <Popover.Close className='popover_close_button'> */}
						<CRButton.Default onClick={onClickApproval}>승인</CRButton.Default>
						{/* </Popover.Close> */}
					</S.ButtonContainerRight>
				</S.ButtonContainer>
				<Popover.Close ref={closeRef} style={{ display: 'none' }} />
			</S.InputContainer>
		</S.Container>
	);
}
