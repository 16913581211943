import React, {
	ChangeEvent,
	KeyboardEvent,
	KeyboardEventHandler,
	useEffect,
	useRef,
	useState,
} from 'react';

import { CheckOption } from 'components/base/Selections/type';
import { CRText } from 'components/base/CRText';
import * as S from './styles';
import SearchDropDown from './SearchDropdown';

interface IProps {
	id?: string;
	currentValue?: CheckOption;
	items?: CheckOption[];
	maxLength?: number;
	placeholder?: string;
	type?: 'default' | 'small';
	searchKey?: string[];
	visibleKey?: string[];
	disabled?: boolean;
	onChange?: (...event: any[]) => void;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
}

function CRSearchSelector({
	id,
	items,
	placeholder = '',
	type = 'default',
	maxLength = 50,
	currentValue,
	disabled = false,
	onChange,
	onPressEnter,
	searchKey = [],
	visibleKey,
}: IProps): React.ReactElement {
	const [searchText, setSearchText] = useState(currentValue?.label);
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > maxLength) return;
		setSearchText(e.target.value);
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};

	const handleClickSearchItem = (item: CheckOption) => {
		onChange?.(item);
		setSearchText(item.label);
		setIsFocused(false);
	};

	const searchItems = !searchText
		? []
		: items?.filter(
				(item) =>
					item.label.toLowerCase().includes(searchText.toLowerCase()) ||
					item?.children?.find((child) =>
						child.label.toLowerCase().includes(searchText.toLowerCase()),
					) ||
					searchKey?.find((key) =>
						item.value[key].toLowerCase().includes(searchText.toLowerCase()),
					),
		  ) ?? [];

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!ref.current?.contains(e.target as Node)) {
				setIsFocused(false);
				setSearchText(currentValue?.label || '');
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, [currentValue]);

	return (
		<S.Container $isDisabled={false} ref={ref}>
			<S.InputContainer
				$isFocused={isFocused}
				$error={false}
				disabled={disabled}
				onClick={() => {
					if (!disabled) setIsFocused(true);
				}}>
				{isFocused ? (
					<input
						type={type}
						id={id}
						// eslint-disable-next-line
						autoFocus
						value={searchText}
						disabled={disabled}
						maxLength={maxLength}
						placeholder={placeholder}
						onChange={handleChange}
						onKeyUp={onKeyUp}
					/>
				) : (
					<div>{currentValue?.label || <CRText text={placeholder} color='gray60' />}</div>
				)}
			</S.InputContainer>
			{isFocused && (
				<SearchDropDown
					searchText={searchText}
					searchItems={searchItems}
					onClickSearchItem={handleClickSearchItem}
					visibleKey={visibleKey}
				/>
			)}
		</S.Container>
	);
}

export default CRSearchSelector;
