import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 102.4rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableRow = styled.div`
	display: flex;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0 0.8rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableColumn = styled.div`
	height: 5.7rem;
	display: flex;
	align-items: center;
	padding: 1.6rem;
`;

export const TableBodyColumn = styled(TableColumn)`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
`;

export const TableHeaderColumn = styled(TableColumn)`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	overflow: hidden;
	text-overflow: ellipsis;
`;
