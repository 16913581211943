import { FileDTO, FileUploadRequestDTO } from '../dto';
import { BaseResponse } from './base';

export interface GetEmployeesRequestDTO {
	workStateCds?: string[];
	dutyCds?: string[];
	managerIds?: string[];
	centerIds: number[];
}
export interface EmployeeDTO {
	number: string;
	employeeId: number;
	centerId: number;
	centerNm: string;
	korMemberNm: string;
	birthDt: string;
	mobilePhoneNo: string;
	workStateNm: string;
	dutyCds: string;
	dutyNms: string;
	managerNm: string;
	managerId: number;
	memberId: number;
}

export type GetEmployeesResponse = BaseResponse<EmployeeDTO[]>;

export interface GetEmployeeBaseInfoRequestDTO {
	centerId?: number;
	employeeId?: number;
	memberId?: number;
}

export interface EmployeeBaseInfoDTO {
	memberId: number;
	employeeId: number;
	centerId: number;
	korMemberNm: string;
	mobilePhoneNo: string;
	manager: string;
	managerNm: string;
	rsdnNo: string;
	insurance: string;
	fullAddr: string;
	dutyNm: string;
	healthCheckDate: string;
	contractInfo: string;
	accidentInsuranceAcqStateCd?: string;
	accidentInsuranceAcqStateNm?: string;
	accidentInsuranceAcqDate?: string;
	accidentInsuranceLossDate?: string;
	employInsuranceAcqStateCd?: string;
	employInsuranceAcqStateNm?: string;
	employInsuranceAcqDate?: string;
	employInsuranceLossDate?: string;
	healthInsuranceAcqStateCd?: string;
	healthInsuranceAcqStateNm?: string;
	healthInsuranceAcqDate?: string;
	healthInsuranceLossDate?: string;
	pensionInsuranceAcqStateCd?: string;
	pensionInsuranceAcqStateNm?: string;
	pensionInsuranceAcqDate?: string;
	pensionInsuranceLossDate?: string;
	joinDate?: string;
	dutyNms?: string;
	birthDt?: string;
}

export type GetEmployeeBaseInfoResponse = BaseResponse<EmployeeBaseInfoDTO>;

export interface OtherCorpWorkStartEndTime {
	otherCorpWorkTimeId: string;
	otherCorpWorkStartTime: string;
	otherCorpWorkEndTime: string;
	otherCorpWorkDays: {
		otherCorpWorkDayCd: string;
		otherCorpWorkDayId: string;
	}[];
}

export interface OtherCorpWork {
	id?: string;
	otherCorpWorkId: string;
	personalBusinessOwnerYn?: boolean;
	otherCorpNm: string;
	otherCorpPhoneNo: string;
	remark: string;
	otherCorpWorkStartEndTimes: OtherCorpWorkStartEndTime[];
}

export interface GetEmployeeDetailInfoRequestDTO {
	centerId: number;
	employeeId: number;
	memberId: number;
}

export interface TagCallDayCD {
	tagCallDayId?: string;
	tagCallDayCd: string;
}

export interface DutyCD {
	employeeDutyId?: string;
	dutyCd: string;
}
export interface EmployeeDetailInfoDTO {
	employeeId: number;
	birthDt: string;
	centerId: number;
	workStateCd: string;
	userNm: string;
	korMemberNm: string;
	mobilePhoneNo: string;
	rsdnNo: string;
	twoPhoneYn: boolean;
	subMobilePhoneNo: string;
	zipCode: string;
	basAddr: string;
	detailAddr: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	disabilityGradeCd: string;
	dementiaEduCompleteYn: boolean;
	employeeSpecialDesc: string;
	tagCallRequestYn: boolean;
	healthCheckDate: string;
	insurance: string;
	salaryPayTypeCd: string;
	salaryAccountNo: string;
	salaryAccountBankCd: string;
	salaryAccountDepositorNm: string;
	salaryAccountValidYn: boolean;
	dutyCds: DutyCD[];
	tagCallDayCds: TagCallDayCD[];
	otherCorpWorks: OtherCorpWork[];
	managerNm: string;
	manager: string;
	accidentInsuranceAcqStateCd?: string;
	accidentInsuranceAcqStateNm?: string;
	accidentInsuranceAcqDate?: string;
	accidentInsuranceLossDate?: string;
	employInsuranceAcqStateCd?: string;
	employInsuranceAcqStateNm?: string;
	employInsuranceAcqDate?: string;
	employInsuranceLossDate?: string;
	healthInsuranceAcqStateCd?: string;
	healthInsuranceAcqStateNm?: string;
	healthInsuranceAcqDate?: string;
	healthInsuranceLossDate?: string;
	pensionInsuranceAcqStateCd?: string;
	pensionInsuranceAcqStateNm?: string;
	pensionInsuranceAcqDate?: string;
	pensionInsuranceLossDate?: string;
	addrLatitude?: string;
	addrLongitude?: string;
	docReceiveMethodCd?: string;
	joinDate: string;
	monthPerWorkHourCnt: number;
	visitCareOffHourCnt: number;
	visitBathOffHourCnt: number;
	visitNursingOffHourCnt: number;
}

export type GetEmployeeDetailInfoResponse = BaseResponse<EmployeeDetailInfoDTO>;

export interface SaveEmployeeBaseInfoRequestDTO {
	employeeId: number;
	centerId: number;
	korMemberNm: string;
	zipCode: string;
	basAddr: string;
	detailAddr: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	rsdnNo: string;
	birthDt: string;
	dutyCds: { dutyCd: string }[];
	mobilePhoneNo: string;
	dementiaEduCompleteYn: boolean;
	twoPhoneYn: boolean;
	subMobilePhoneNo: string;
	disabilityGradeCd: string;
	employeeSpecialDesc: string;
	tagCallRequestYn: boolean;
	tagCallDayCds: { tagCallDayCd: string }[];
	addrLatitude: number;
	addrLongitude: number;
	docReceiveMethodCd: string;
	joinDate?: string;
	monthPerWorkHourCnt?: number;
	visitCareOffHourCnt?: number;
	visitBathOffHourCnt?: number;
	visitNursingOffHourCnt?: number;
}

export interface SaveEmployeeBaseInfoDTO {
	employeeId: number;
}

export type SaveEmployeeBaseInfoResponse = BaseResponse<SaveEmployeeBaseInfoDTO>;

export interface SaveEmployeeWorkStateRequestDTO {
	employeeId: number;
	centerId: number;
	workStateCd: string;
}

export type SaveEmployeeWorkStateResponse = BaseResponse<SaveEmployeeBaseInfoDTO>;

export interface EmployeeCheckDuplicateRequest {
	name: string;
	rsdnNo: string;
	centerId: number;
}

export interface EmployeeCheckDuplicateDTO {
	isCenterDuplicated: boolean;
	isEmployeeDuplicated: boolean;
	recipientDuplicated: boolean;
	centers: string[];
	member?: {
		korMemberNm: string;
		birthDt: string;
		rsdnNo: string;
		rsdnNo7: string;
		genderCd: string;
		memberAccounts: number;
		memberId: number;
		createdAt: string;
		createdId: number;
		updatedAt: string;
		updatedId: number;
	};
	employeeId?: number;
	memberId?: number;
}

export type EmployeeCheckDuplicateResponse = BaseResponse<EmployeeCheckDuplicateDTO>;

export interface CreateEmployeeRequestDTO {
	name: string;
	rsdnNo: string;
	centerId: number;
}

export interface CreateEmployeeDTO {
	employeeId: number;
	memberId: number;
}

export type CreateEmployeeResponse = BaseResponse<CreateEmployeeDTO>;

export interface WorkTime {
	id?: string;
	otherCorpWorkTimeId?: number;
	otherCorpWorkStartTime: string;
	otherCorpWorkEndTime: string;
	days: {
		otherCorpWorkDayCd: string;
	}[];
}

export interface OtherCorpWorkSave {
	personalBusinessOwnerYn: boolean;
	otherCorpNm: string;
	otherCorpPhoneNo: string;
	remark: string;
	times: WorkTime[];
}

export interface SaveEmployeeOtherCorpWorkRequestDTO {
	employeeId: number;
	otherCorpWorks: OtherCorpWorkSave[];
}

export interface SaveEmployeeOtherCorpWorkDTO {
	employeeId: number;
}

export type SaveEmployeeOtherCorpWorkResponse = BaseResponse<SaveEmployeeOtherCorpWorkDTO>;

export interface SaveEmployeePayInfoRequestDTO {
	employeeId: number;
	centerId: number;
	salaryPayTypeCd: string;
	salaryAccountNo: string;
	salaryAccountBankCd: string;
	salaryAccountDepositorNm: string;
	salaryAccountValidYn: boolean;
}

export interface SaveEmployeePayInfoDTO {
	employeeId: number;
}

export type SaveEmployeePayInfoResponse = BaseResponse<SaveEmployeePayInfoDTO>;

export interface EmployeeHealthCheckRequestDTO {
	employeeId?: number;
}

export interface EmployeeHealthCheckDTO {
	employeeId: number;
	healthCheckDate: string;
	healthCheckKindCd: string;
	healthCheckInstitutionNm: string;
	centerNm: string;
	managerNm: string;
	remark: string;
	employeeHealthCheckId: number;
	healthCheckFile: FileDTO;
}

export type EmployeeHealthCheckResponse = BaseResponse<EmployeeHealthCheckDTO[]>;

export interface SaveEmployeeHealthCheckRequestDTO {
	employeeId: number;
	healthCheckDate: string;
	healthCheckKindCd: string;
	healthCheckInstitutionNm: string;
	healthCheckFile?: FileUploadRequestDTO;
	remark: string;
	employeeHealthCheckId?: 1;
}

export interface SaveEmployeeHealthCheckDTO {
	employeeId: number;
}

export type SaveEmployeeHealthCheckResponse = BaseResponse<SaveEmployeeHealthCheckDTO>;

export interface DeleteEmployeeHealthCheckRequestDTO {
	employeeHealthCheckId: number;
}

export interface DeleteEmployeeHealthCheckDTO {
	employeeId: number;
}

export type DeleteEmployeeHealthCheckResponse = BaseResponse<DeleteEmployeeHealthCheckDTO>;

export interface EmployeeCareerInfoRequestDTO {
	employeeId?: number;
}

export interface EmployeeCareerInfoDTO {
	employeeId: number;
	workStartDate: string;
	workEndDate: string;
	companyNm: string;
	remark: string;
	employeeWorkCareerId: number;
}

export type EmployeeCareerInfoResponse = BaseResponse<EmployeeCareerInfoDTO[]>;

export type SaveEmployeeCareerRequestDTO = {
	employeeId: number;
	workStartDate: string;
	workEndDate: string | null;
	companyNm: string;
	remark: string;
	files?: any[];
	employeeWorkCareerId?: number;
};

export interface SaveEmployeeCareerDTO {
	employeeId?: number;
}

export type SaveEmployeeCareerResponse = BaseResponse<SaveEmployeeCareerDTO>;

export interface DeleteEmployeeCareerRequestDTO {
	employeeWorkCareerId: number;
}

export interface DeleteEmployeeCareerDTO {
	employeeId: number;
}

export type DeleteEmployeeCareerResponse = BaseResponse<DeleteEmployeeCareerDTO>;

// 자격증
export interface EmployeeLicenseInfoRequestDTO {
	employeeId?: number;
}

export interface EmployeeLicenseInfoDTO {
	employeeId: number;
	licenseTypeCd: string;
	licenseNm: string;
	licenseNo: string;
	licenseIssueDate: string;
	employeeLicenseId: number;
	licenseExpirationDate: string;
	licenseFile: FileDTO;
	licenseIssueInstitutionNm: string;
}

export type EmployeeLicenseInfoResponse = BaseResponse<EmployeeLicenseInfoDTO[]>;

export type SaveEmployeeLicenseRequestDTO = {
	employeeId: number;
	licenseTypeCd: string;
	licenseNm: string;
	licenseNo: string;
	licenseIssueDate: string;
	licenseIssueInstitutionNm: string;
	employeeLicenseId?: number;
	licenseFile?: FileDTO;
};

export interface SaveEmployeeLicenseDTO {
	employeeId?: number;
}

export type SaveEmployeeLicenseResponse = BaseResponse<SaveEmployeeLicenseDTO>;

export interface DeleteEmployeeLicenseRequestDTO {
	employeeLicenseId: number;
}

export interface DeleteEmployeeLicenseDTO {
	employeeId: number;
}

export type DeleteEmployeeLicenseResponse = BaseResponse<DeleteEmployeeLicenseDTO>;

// 직원 -> 교육
export interface EmployeeEduInfoRequestDTO {
	employeeId?: number;
	year: number;
}

export interface EmployeeEduInfoDTO {
	employeeId: number;
	eduTypeCd: string;
	eduCompleteDate: string;
	eduInstitutionNm: string;
	employeeEduId: number;
	eduCompleteCertiFile: FileDTO;
}

export type EmployeeEduInfoResponse = BaseResponse<EmployeeEduInfoDTO[]>;

export type SaveEmployeeEducationRequestDTO = {
	employeeId: number;
	eduTypeCd: string;
	eduCompleteDate: string;
	eduInstitutionNm: string;
	employeeEduId?: number; // 해당키를 보내면 수정 안보내면 등록
	eduCompleteCertiFile?: FileDTO;
};

export interface SaveEmployeeEducationDTO {
	employeeId?: number;
}

export type SaveEmployeeEducationResponse = BaseResponse<SaveEmployeeEducationDTO>;

export interface DeleteEmployeeEducationRequestDTO {
	employeeEduId: number;
}

export interface DeleteEmployeeEducationDTO {
	employeeId: number;
}

export type DeleteEmployeeEducationResponse = BaseResponse<DeleteEmployeeEducationDTO>;

// 시회복지사 이력

export interface EmployeeHistoryOfManagerRequestDTO {
	employeeId?: number;
}

export interface EmployeeHistoryOfManagerDTO {
	employeeManagerHistId: number;
	currentYn: boolean;
	managerChangeDate: string;
	centerId: number;
	centerNm: string;
	managerNm: string;
	consultOpinion: string;
	managerId: number;
	managerChangeDesc: string;
	managerChangeReason: string;
}

export type EmployeeHistoryOfManagerResponse = BaseResponse<EmployeeHistoryOfManagerDTO[]>;

export interface SaveEmployeeHistoryOfManagerRequestDTO {
	employeeId: number;
	employeeManagerHistId?: number;
	managerChangeDate: string;
	centerId: number;
	managerId: number;
	consultOpinionDesc: string;
	managerChangeDesc: string;
}

export interface SaveEmployeeHistoryOfManagerDTO {
	employeeId: number;
}

export type SaveEmployeeHistoryOfManagerResponse = BaseResponse<SaveEmployeeHistoryOfManagerDTO>;

export interface EmployeeSalarysRequest {
	centerId?: number;
	employeeId?: number;
	managerIds?: number[];
	keyword?: string;
}

export interface SalarySendHistory {
	centerId: number;
	receiveTargetDivCd: string;
	receiveTargetDivNm: string;
	receiverId: number;
	sendDetailId: number;
	sendId: number;
	sendRequestEntityNm: string;
	sendRequestEntityIdNm: string;
	sendRequestEntityIdValue: number;
	edocSendId: number;
	sendDivCd: string;
	sendDivNm: string;
	burdenAmtPayStateCd: string;
	sendTemplateTypeNm: string;
	sendTitle: string;
	sendStateCd: string;
	sendStateNm: string;
	sendRequestDate: string;
	targetYm?: string;
	targetWeekSeq?: number;
	sendUuid?: string;
	edocNo?: string;
}

export interface CashSendHistory {
	centerId: number;
	receiveTargetDivCd: string;
	receiveTargetDivNm: string;
	receiverId: number;
	sendDetailId: number;
	sendId: number;
	sendRequestEntityNm: string;
	sendRequestEntityIdNm: string;
	sendRequestEntityIdValue: number;
	edocSendId: number;
	sendDivNm: string;
	burdenAmtPayStateCd: string;
	sendTemplateTypeNm: string;
	sendTitle: string;
	sendStateCd: string;
	sendStateNm: string;
	sendRequestDate: string;
	targetYm?: string;
	targetWeekSeq?: number;
	sendUuid?: string;
	edocNo?: string;
}

export interface SalaryServiceScheduleSummary {
	employeeSalaryId: number;
	serviceNm: string;
	recipientId: number;
	recipientNm: string;
	totalWorkHours: number;
	totalWorkCount: number;
	totalSalaryAmt: number;
	div: number;
	totalWorkHourInfo: string;
}

export interface SalaryServiceSchedule {
	salaryServiceScheduleId: number;
	employeeSalaryId: number;
	salaryYm: string;
	employeeId: number;
	employeeNm: string;
	employeeBirthDt?: string;
	providerNo?: string;
	providerDivNm?: string;
	centerId: number;
	recipientId: number;
	recipientNm: string;
	longTermNo: string;
	serviceTypeCd?: string;
	serviceTypeNm?: string;
	serviceKindCd?: string;
	serviceKindNm?: string;
	serviceKindDetailNm?: string;
	serviceYm: string;
	serviceStartDt: string;
	serviceStartTime: string;
	serviceEndDt: string;
	serviceEndTime: string;
	serviceOfferHourCnt: number;
	familyYn?: boolean;
	familyRelNm?: string;
	serviceFeeNm?: string;
	serviceFeeAmt: number;
	automationDivNm?: string;
	automationProcessDesc?: string;
	serviceChangeNm?: string;
	serviceNm?: string;
	longTermGradeCd?: string;
	longTermGradeNm?: string;
	workHourCnt: number;
	restHourCnt: number;
	fixNightPay: number;
	fixHolidayPay: number;
	salaryAmt: number;
	basicSalaryAmt: number;
	serviceHourCnt: number;
	mealPay: number;
	mealEtcPay: number;
	nightPay: number;
	weekRestPay: number;
	annualPay: number;
	basicWeekRestAnnualPay: number;
	severePay: number;
	dementiaFamilyVacaSalaryAmt: number;
	ordinaryWage: number;
	holidayPay: number;
}

export interface SalaryItem {
	salaryItemId: number;
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	employeeId: number;
	salaryItemCd: string;
	salaryItemAmt: number;
	salaryItemNm: string;
	salaryCertiItemNm: string;
	payDeductDivCd: string;
	payDeductDivNm: string;
	payDeductGroupNm: string;
	orderSeq: number;
	remark?: string;
	taxFreeYn: boolean;
	taxFreeLimitAmt: number;
	amtInputPosbYn: boolean;
	calculationFormulaDesc?: string;
}

export interface EmployeeSalaryFeeStandard {
	employeeSalaryFeeStandardId: number;
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	employeeId: number;
	salaryFeeCd: string;
	salaryFeeNm: string;
	salaryFeeTypeCd: string;
	salaryFeeTypeNm: string;
	orderSeq: number;
	serviceTypeCd?: string;
	serviceTypeNm?: string;
	serviceTypeDetailCd?: string;
	serviceTypeDetailNm?: string;
	screenDisplayYn?: boolean;
	salaryFeeValue: string;
	salaryFeeStandardStartDate: string;
	salaryFeeStandardEndDate: string;
}

export interface BurdenAmtDeduct {
	burdenAmtDeductId: number;
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	employeeId: number;
	recipientId: number;
	recipientNm: string;
	visitCareBurdenAmt: number;
	visitNursingBurdenAmt: number;
	visitBathBurdenAmt: number;
	burdenAmt: number;
	burdenAmtDeductYn: boolean;
	burdenAmtDeductDt?: string;
	burdenAmtDeductDesc?: string;
}

export interface SalaryServiceSummary {
	employeeSalaryId: number;
	totalServiceHourCnt: number;
	totalServiceWorkCnt: number;
	totalServiceSalaryAmt: number;
	visitCareServiceHourCnt: number;
	visitCareServiceWorkCnt: number;
	visitCareServiceSalaryAmt: number;
	visitBathServiceHourCnt: number;
	visitBathServiceWorkCnt: number;
	visitBathServiceSalaryAmt: number;
	visitNursingServiceHourCnt: number;
	visitNursingServiceWorkCnt: number;
	visitNursingServiceSalaryAmt: number;
}

export interface EmployeeSalaryDTO {
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	centerNm: string;
	centerMark?: string;
	centerAddr?: string;
	centerZipCode: string;
	bizNo: string;
	representativeNm: string;
	employeeId: number;
	employeeNm: string;
	dutyCd: string;
	dutyNm: string;
	salaryCalculationTypeCd: string;
	salaryCalculationTypeNm: string;
	salaryPayTypeCd: string;
	salaryPayTypeNm: string;
	birthDt?: string;
	mobilePhoneNo?: string;
	mobilePhoneNo4?: string;
	serviceChangeNm: string;
	recipientNm: string;
	salaryAccountBankCd: string;
	salaryAccountBankNm: string;
	salaryAccountNo: string;
	salaryAccountDepositorNm: string;
	salaryAccountValidYn: boolean;
	dementiaEduCompleteYn: boolean;
	paySumAmt: number;
	deductSumAmt: number;
	realPayAmt: number;
	companyLaborCost: number;
	employInsuranceRate: number;
	taxSalarySumAmt: number;
	taxFreeSalarySumAmt: number;
	weeklyAvgWorkHourCnt: number;
	extendWorkHourCnt: number;
	extendWorkPay: number;
	holidayWorkHourCnt: number;
	holidayWorkPay: number;
	nightWorkHourCnt: number;
	nightWorkPay: number;
	sundayWorkHourCnt: number;
	sundayWorkPay: number;
	salaryMemo: string;
	totalWorkHourCnt: number;
	totalWorkDayCnt: number;
	accidentInsuranceAcqStateCd?: string;
	accidentInsuranceAcqStateNm?: string;
	accidentInsuranceAcqDate?: string;
	accidentInsuranceLossDate?: string;
	accidentInsuranceAmt: number;
	employInsuranceAcqStateCd?: string;
	employInsuranceAcqStateNm?: string;
	employInsuranceAcqDate?: string;
	employInsuranceLossDate?: string;
	employInsuranceAmt: number;
	healthInsuranceAcqStateCd?: string;
	healthInsuranceAcqStateNm?: string;
	healthInsuranceAcqDate?: string;
	healthInsuranceLossDate?: string;
	healthInsuranceAmt: number;
	pensionInsuranceAcqStateCd?: string;
	pensionInsuranceAcqStateNm?: string;
	pensionInsuranceAcqDate?: string;
	pensionInsuranceLossDate?: string;
	pensionInsuranceAmt: number;
	managerId?: number;
	managerNm?: string;
	salarySendId: number;
	salarySendTitle: string;
	salarySendDate: string;
	salarySendStateCd: string;
	salarySendStateNm: string;
	cashSendId: number;
	cashSendTitle: string;
	cashSendDate: string;
	cashSendStateCd: string;
	cashSendStateNm: string;
	insuranceSummary?: string;
	salarySendHists: SalarySendHistory[];
	cashSendHists: CashSendHistory[];
	salaryServiceScheduleSummarys: SalaryServiceScheduleSummary[];
	salaryServiceSchedules: SalaryServiceSchedule[];
	salaryItems: SalaryItem[];
	employeeSalaryFeeStandards: EmployeeSalaryFeeStandard[];
	burdenAmtDeducts: BurdenAmtDeduct[];
	salaryServiceSummary: SalaryServiceSummary[];
	salaryServiceConcatSummary?: string;
	centerStampFileObjectKey?: string;
	salaryEdocNo?: string;
	cashEdocNo?: string;
	salaryCompleteYn: boolean;
}

export type EmployeeSalarysResponse = BaseResponse<EmployeeSalaryDTO[]>;

// 직원 퇴사 정보

export interface EmployeeResignInfoRequest {
	employeeId?: string;
	centerId?: number;
}

export interface EmployeeResignInfoDTO {
	centerId: number;
	employeeId: number;
	serviceStartDate: string;
	serviceStateChangeDate: string;
	serviceChangeDesc: string;
	serviceChangeDescCd: string;
	serviceChangeReason: string;
	contractLeaveServiceResponses: [
		{
			centerId: number;
			recipientId: number;
			employeeId: number;
			serviceTypeCd: string;
			serviceStateCd: string;
			serviceContractId: number;
			serviceContractDetailId: number;
			serviceContractProviderId: number;
			serviceStartDate: string;
			centerNm: string;
			employeeNm: string;
			serviceTypeCdNm: string;
			managerId: number;
		},
	];
	edocParams: [
		{
			nm: string;
			signNm: string;
			centerNm: string;
			birthDt: string;
			workStartDate: string;
			leaveHopeYear: string;
			leaveHopeMonth: string;
			leaveHopeDay: string;
			assignTaskNm: string;
			leaveChk1: string;
			leaveChk2: string;
			leaveChk3: string;
			leaveChk4: string;
			leaveChk5: string;
			leaveChk6: string;
			leaveChk7: string;
			leaveChk8: string;
			leaveChk9: string;
			leaveComCdId: string;
			leaveEtcDesc: string;
			year: string;
			month: string;
			day: string;
			leaveYear: string;
			leaveMonth: string;
			leaveDay: string;
			leaveReason: string;
		},
	];
	lastEsignYn: false;
}

export type EmployeeResignInfoResponse = BaseResponse<EmployeeResignInfoDTO>;

// 직원 퇴사
export interface ResignEmployeeRequest {
	centerId: number;
	employeeId: number;
	serviceStartDate: string;
	serviceStateChangeDate: string;
	serviceChangeDescCd: string;
	serviceChangeEtcDesc?: string;
	serviceChangeDesc: string;
	insuranceLossRequestYn: boolean;
	wardResignDischargeRequestYn: boolean;
	incompletePaperConfirmYn: boolean;
	calculateConfirmYn: boolean;
}

// eslint-disable-next-line
export interface ResignEmployeeDTO {
	centerId: number;
	employeeId: number;
}

export type ResignEmployeeResponse = BaseResponse<ResignEmployeeDTO>;

export type UpdateEmployeeTagCallRequest = {
	employeeId: number;
	tagCallDayCds: string[];
}[];
export type UpdateEmployeeTagCallData = null;

export type UpdateEmployeeTagCallResponse = BaseResponse<UpdateEmployeeTagCallData>;

export enum EmployeeAffiliationType {
	RSDN_NO = 'RSDN_NO',
	BIRTH_DT = 'BIRTH_DT',
	NAME = 'NAME',
	LONGTERM_NO = 'LONGTERM_NO',
	DELETE = 'DELETE',
	GET = 'GET',
}

export interface UpdateEmployeeAffiliationRequest {
	targetId: number;
	centerId: number;
	target: EmployeeAffiliationType;
	updateValue?: string;
}

export interface UpdateEmployeeAffiliationDTO {
	employeeId: number;
	centerId: number;
	longTermUsePlanDetail: {
		value: 'string';
		count: number;
	};
	longTermUsePlanWelfare: {
		value: 'string';
		count: number;
	};
	longTermUsePlan: {
		value: 'string';
		count: number;
	};
	salaryServiceSchedule: {
		value: 'string';
		count: number;
	};
	salaryOfferPlan: {
		value: 'string';
		count: number;
	};
	recordingSheet: {
		value: 'string';
		count: number;
	};
	recordingSheetSendMapping: {
		value: 'string';
		count: number;
	};
	burdenAmtDeduct: {
		value: 'string';
		count: number;
	};
	burdenAmtCharge: {
		value: 'string';
		count: number;
	};
	burdenRateHist: {
		value: 'string';
		count: number;
	};
	consult: {
		value: 'string';
		count: number;
	};
	serviceContract: {
		value: 'string';
		count: number;
	};
	serviceGuardian: {
		value: 'string';
		count: number;
	};
	serviceUseDay: {
		value: 'string';
		count: number;
	};
	serviceUseTime: {
		value: 'string';
		count: number;
	};
	serviceContractProviderMatching: {
		value: 'string';
		count: number;
	};
	serviceContractDetail: {
		value: 'string';
		count: number;
	};
	serviceContractProvider: {
		value: 'string';
		count: number;
	};
	recipientContractWorkDay: {
		value: 'string';
		count: number;
	};
	recipientContractWorkTime: {
		value: 'string';
		count: number;
	};
	recipientContract: {
		value: 'string';
		count: number;
	};
	employeeContractWorkDay: {
		value: 'string';
		count: number;
	};
	employeeContractWorkTime: {
		value: 'string';
		count: number;
	};
	employeeContract: {
		value: 'string';
		count: number;
	};
	servicePcorpCharge: {
		value: 'string';
		count: number;
	};
	serviceSchedulePlan: {
		value: 'string';
		count: number;
	};
	recipientManagerHist: {
		value: 'string';
		count: number;
	};
	recipientChangeHist: {
		value: 'string';
		count: number;
	};
	recipientPaper: {
		value: 'string';
		count: number;
	};
	recipientIa: {
		value: 'string';
		count: number;
	};
	longTermGradeHist: {
		value: 'string';
		count: number;
	};
	scheduleCreation: {
		value: 'string';
		count: number;
	};
	otherServiceUse: {
		value: 'string';
		count: number;
	};
	otherServiceUseTime: {
		value: 'string';
		count: number;
	};
	otherServiceUseDay: {
		value: 'string';
		count: number;
	};
	recipientAffiliation: {
		value: 'string';
		count: number;
	};
	recipientGuardian: {
		value: 'string';
		count: number;
	};
	recipient: {
		value: 'string';
		count: number;
	};
	memberChangeHist: {
		value: 'string';
		count: number;
	};
	member: {
		value: 'string';
		count: number;
	};
	centerAccountRecordDetail: {
		value: 'string';
		count: number;
	};
	cashReceiptId: {
		value: 'string';
		count: number;
	};
	serviceStateChangeId: {
		value: 'string';
		count: number;
	};
	sendDetail: {
		value: 'string';
		count: number;
	};
	sendResult: {
		value: 'string';
		count: number;
	};
	send: {
		value: 'string';
		count: number;
	};
	edocSend: {
		value: 'string';
		count: number;
	};
	edocProgressHist: {
		value: 'string';
		count: number;
	};
	edocParam: {
		value: 'string';
		count: number;
	};
	esign: {
		value: 'string';
		count: number;
	};
	esignStep: {
		value: 'string';
		count: number;
	};
	esignDetail: {
		value: 'string';
		count: number;
	};
}

export type UpdateEmployeeAffiliationResponse = BaseResponse<UpdateEmployeeAffiliationDTO>;

export interface GetEmployeesExcelRequest {
	centerId: string;
}
export interface GetEmployeesExcelDTO {
	workStateNm: string;
	employeeNm: string;
	birthDt: string;
	genderNm: string;
	mobilePhoneNo: string;
	basAddr: string;
	detailAddr: string;
	zipCode: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	addrLatitude: number;
	addrLongitude: number;
	managerNm: string;
	workMobilePhoneNo: string;
	centerNm: string;
	workDayCnt: number;
	serviceDays: string;
	serviceMinute: number;
	employeeLicenseFileUrls: string[];
	employeeHealthCheckFileUrls: string[];
	employeeId: number;
	centerId: number;
	joinDate: string;
	resignDate: string;
	monthPerWorkHourCnt: number;
	dementiaEduCompleteYn: boolean;
	disabilityGradeCd: string;
	dutyNm: string;
	rsdnNo: string;
}
export type GetEmployeesExcelResponse = BaseResponse<GetEmployeesExcelDTO[]>;
