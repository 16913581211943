export type BUILD_ENV_TYPES = 'local' | 'development' | 'stage' | 'production';

export class EnvConfig {
	static SITE_NAME = process.env.REACT_APP_SITE_NAME || '';

	static BASE_URL = process.env.REACT_APP_BASE_URL || '';

	static EFORM_URL = process.env.REACT_APP_EFORM_URL || '';

	static PUBLIC_HOLIDAY_URL = process.env.REACT_APP_PUBLIC_HOLIDAY_URL || '';

	static BUILD_ENV: BUILD_ENV_TYPES = process.env.REACT_APP_BUILD_ENV;

	static ENABLE_SENTRY = process.env.REACT_APP_ENABLE_SENTRY === 'true';
}
