import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';
import useDialog from 'lib/hook/util/useDialog';

import NewTaskDialog from 'components/domain/dialog/NewTaskDialog';
import { useMyTaskCount } from 'lib/hook/react-query';
import PendingTab from './PendingTab';
import ProgressTab from './ProgressTab';
import CompleteTab from './CompleteTab';

function MyTaskPage(): React.ReactElement {
	const { showDialog } = useDialog();
	const myTaskTab = useMemo(() => RouterPath.myTaskTab(), []);
	const { data: countData } = useMyTaskCount();

	const handleClickNewTask = () => {
		showDialog(({ hideDialog }) => <NewTaskDialog onClickClose={hideDialog} />);
	};

	const progressCount = useMemo(
		() =>
			countData?.data?.myTaskCounts?.find((item) => item.taskProgressStateNm === '진행중')?.count ??
			'00',
		[countData],
	);
	const waitCount = useMemo(
		() =>
			countData?.data?.myTaskCounts?.find((item) => item.taskProgressStateNm === '시작전')?.count ??
			'00',
		[countData],
	);

	return (
		<CRTab.Default
			defaultActiveKey={myTaskTab.progress.key}
			items={[
				{
					label: `${myTaskTab.progress.label}(${progressCount}건)`,
					key: myTaskTab.progress.key,
					children: <ProgressTab onClickNewTask={handleClickNewTask} />,
				},
				{
					label: `${myTaskTab.pending.label}(${waitCount}건)`,
					key: myTaskTab.pending.key,
					children: <PendingTab onClickNewTask={handleClickNewTask} />,
				},
				{
					label: myTaskTab.complete.label,
					key: myTaskTab.complete.key,
					children: <CompleteTab onClickNewTask={handleClickNewTask} />,
				},
			]}
			breadCrumb='내 업무'
		/>
	);
}

export default MyTaskPage;
