import React, { useCallback } from 'react';
import Assets from 'assets';

import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import { useEmployeePageHistoryOfManager } from 'lib/hook/react-query';

import { EmployeeDTO } from 'types/api/employee';
import { employeeHistoryOfManagerAdapter } from 'lib/adapter/employee';
import { EmployeeManagerHistoryType } from 'types/view/employee';
import useDialog from 'lib/hook/util/useDialog';
import dayjs from 'dayjs';
import * as S from './styles';
import {
	EMPLOYEE_MANAGER_HISTORY_TABLE_HEADER_CONFIG,
	EMPLOYEE_MANAGER_HISTORY_STATUS_CONFIG,
} from './constants';
import CRDialog from '../../../base/CRDialog';
import EmployeeAddHistoryOfManagerDialog from '../EmployeeAddHistoryOfManagerDialog';

interface Props {
	currentEmployee?: EmployeeDTO;
}

function EmployeeManagerHistoryDialog({ currentEmployee }: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { data: historyOfManager } = useEmployeePageHistoryOfManager(
		{
			employeeId: currentEmployee?.employeeId,
		},
		employeeHistoryOfManagerAdapter,
	);

	const Status = useCallback(
		(status: string) => (
			<CRStatus options={EMPLOYEE_MANAGER_HISTORY_STATUS_CONFIG}>{status}</CRStatus>
		),
		[],
	);

	const handleClickAddHistoryOfEmployee = (editItem?: EmployeeManagerHistoryType) => {
		showDialog(({ hideDialog }) => (
			<EmployeeAddHistoryOfManagerDialog
				currentEmployee={currentEmployee}
				onClickClose={hideDialog}
				editItem={editItem}
			/>
		));
	};

	const ButtonContainer = useCallback(
		(id: string, item?: EmployeeManagerHistoryType) => (
			<CRButton.Default
				type='outlined'
				palette='gray'
				size='xSmall'
				onClick={() => item && handleClickAddHistoryOfEmployee(item)}>
				수정
			</CRButton.Default>
		),
		[],
	);

	return (
		<CRDialog
			type='L'
			onClickClose={hideDialog}
			title='사회복지사 이력'
			body={
				<CRTable.Root>
					<CRTable.Head offset={-77} heads={EMPLOYEE_MANAGER_HISTORY_TABLE_HEADER_CONFIG} />
					<CRTable.Body>
						{historyOfManager?.map((item) => (
							<CRTable.Row
								key={item.id}
								item={item}
								customRender={{
									status: Status,
									id: ButtonContainer,
									date: (item) => dayjs(item).format('YYYY.MM.DD'),
									center: (item) => item.name,
									manager: (item) => item.name,
								}}
								renderKeys={[
									'status',
									'date',
									'center',
									'manager',
									'note',
									'consultantOpinion',
									'managerChangeDesc',
									'id',
								]}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			}
			footer={
				<S.FooterRightSideContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='outlined'
						palette='gray'
						onClick={() => handleClickAddHistoryOfEmployee()}>
						사회복지사 변경
					</CRButton.IconButton>
				</S.FooterRightSideContainer>
			}
		/>
	);
}

export default React.memo(EmployeeManagerHistoryDialog);
