import Colors from 'common/colors';
import Typography from 'common/typography';
import styled from 'styled-components';

interface InputElementProps {
	$textAlign: 'left' | 'center' | 'right';
	width: number | string;
	$typography?: keyof typeof Typography;
	$color?: keyof typeof Colors;
}

export const InputContainer = styled.div<{ width?: string | number }>`
	width: ${(p) => p.width};
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const InputElement = styled.input<InputElementProps>`
	width: ${(p) => p.width};
	min-height: 3.8rem;
	padding: 0.4rem 0.9rem;
	border: 0.1rem solid ${(p) => p.theme.colors.gray80};
	background-color: ${(p) => p.theme.colors.white};
	text-align: ${(p) => p.$textAlign};
	color: ${(p) => p.theme.colors.gray20};
	${(p) => p.$typography}
	&:disabled {
		background-color: ${(p) => p.theme.colors.gray90};
	}
	&::placeholder {
		color: ${(p) => p.theme.colors.gray70};
	}
`;
