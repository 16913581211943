import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import { employeeManagerHistory } from 'lib';
import {
	useCenterManagers,
	useMyAccountInfo,
	useUpdateEmployeeHistoryOfManager,
} from 'lib/hook/react-query';

import {
	EmployeeDTO,
	EmployeeDetailInfoDTO,
	SaveEmployeeHistoryOfManagerRequestDTO,
} from 'types/api/employee';
import { EmployeeManagerHistoryType } from 'types/view/employee';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import { Toast } from 'components/base/CRToast';
import { endpoint } from 'lib/service/Api/endpoint';
import * as S from './styles';

interface Props {
	currentEmployee?: EmployeeDTO;
	editItem?: EmployeeManagerHistoryType;
	onClickClose?: () => void;
}

export type EmployeeAddHistoryOfManagerForm = {
	changeDate: Date;
	center: {
		label: string;
		value: string;
	};
	manager: {
		label: string;
		value: string;
	};
	consultantOpinion?: string;
	managerChangeDesc: string;
};

function EmployeeAddHistoryOfManagerDialog({ currentEmployee, editItem, onClickClose }: Props) {
	const {
		control,
		setValue,
		handleSubmit,
		trigger,
		watch,
		formState: { isValid },
	} = useForm<EmployeeAddHistoryOfManagerForm>({
		resolver: yupResolver(employeeManagerHistory),
	});
	const { hideDialog } = useDialog();

	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const { mutate: updateEmployeeHistoryOfManager } = useUpdateEmployeeHistoryOfManager(
		(client, returnData, variables) => {
			client.invalidateQueries([
				endpoint.getEmployeeHistoryOfManager.key,
				{ employeeId: currentEmployee?.employeeId },
			]);
			const matchedManager = centerManagers?.find(
				(item) => item.memberAccountId === variables.managerId,
			);
			client.setQueryData(
				[
					endpoint.getEmployeeDetailInfo.key,
					{
						centerId: Number(currentEmployee?.centerId),
						memberId: Number(currentEmployee?.memberId),
						employeeId: Number(currentEmployee?.employeeId),
					},
				],
				(data: any) => {
					const newCache = {
						...(data as EmployeeDetailInfoDTO),
						managerNm: `${matchedManager?.centerNm} ${matchedManager?.userNm}`,
					} as EmployeeDetailInfoDTO;
					return newCache;
				},
			);

			if (returnData?.employeeId) {
				Toast.success('사회복지사 이력을 수정했습니다.');
				onClickClose?.();
			}
		},
	);

	const onSubmit = async (data: EmployeeAddHistoryOfManagerForm) => {
		if (!currentEmployee?.employeeId) return;
		const param: SaveEmployeeHistoryOfManagerRequestDTO = {
			employeeId: currentEmployee.employeeId,
			managerChangeDate: dayjs(data.changeDate).format('YYYYMMDD'),
			centerId: Number.parseInt(data.center.value, 10),
			managerId: Number.parseInt(data.manager.value, 10),
			consultOpinionDesc: data.consultantOpinion ?? '',
			managerChangeDesc: data.managerChangeDesc,
		};
		if (editItem?.id) {
			param.employeeManagerHistId = Number.parseInt(editItem.id, 10);
		}
		updateEmployeeHistoryOfManager(param);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	// useEffect(() => {
	// 	if (myAccountInfo) {
	// 		setValue('changeDate', editItem ? dayjs(editItem.date).toDate() : dayjs().toDate());
	// 		setValue('center', {
	// 			label: myAccountInfo.centerNm,
	// 			value: myAccountInfo.centerId.toString(),
	// 		});
	// 		setValue('changeDate', new Date());
	// 	}
	// }, [myAccountInfo]);

	useEffect(() => {
		if (!myAccountInfo) return;
		if (editItem) {
			setValue('changeDate', dayjs(editItem.date).toDate());
			setValue('center', { label: editItem.center.name, value: editItem.center.id });
			setValue('manager', { label: editItem.manager.name, value: editItem.manager.id });
			setValue('consultantOpinion', editItem.consultantOpinion);
			setValue('managerChangeDesc', editItem.managerChangeDesc);
		} else {
			setValue('changeDate', dayjs().toDate());
			setValue('center', {
				label: myAccountInfo.centerNm,
				value: myAccountInfo.centerId.toString(),
			});
		}
	}, [editItem, myAccountInfo]);

	console.log(watch());
	return (
		<CRDialog
			type='S'
			title='사회복지사 변경'
			onClickClose={hideDialog}
			body={
				<S.Form onSubmit={handleSubmit(onSubmit, (err) => console.log('err', err))}>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='변경일' isRequired>
								<CRInput.DatePicker
									disabled={!!editItem}
									onChangeValue={onChange}
									value={value}
									minDate={new Date('2019.01.01')}
									placeholder='변경일자 선택'
								/>
							</CRInputLabel>
						)}
						name='changeDate'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='소속' isRequired>
								<CRInput.Selector
									disabled
									items={[
										{
											label: myAccountInfo?.centerNm ?? '',
											value: myAccountInfo?.centerId.toString(),
										},
									]}
									autoComplete
									onChangeValue={onChange}
									currentValue={value}
									placeholder='소속 선택'
								/>
							</CRInputLabel>
						)}
						name='center'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='사회복지사' isRequired>
								<CRInput.Selector
									disabled={!!editItem}
									items={centerManagers?.map((item) => ({
										label: item.userNm,
										value: String(item.memberAccountId),
									}))}
									onChangeValue={onChange}
									currentValue={value}
									placeholder='사회복지사 선택'
								/>
							</CRInputLabel>
						)}
						name='manager'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='상담자 의견'>
								<CRInput.TextArea
									ref={ref}
									placeholder='상담자 의견 입력'
									onChange={onChange}
									onBlur={onBlur}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='consultantOpinion'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='변경 사유' isRequired>
								<CRInput.TextArea
									ref={ref}
									placeholder='변경 사유 입력'
									onChange={onChange}
									onBlur={onBlur}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='managerChangeDesc'
						control={control}
					/>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClickClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid}
						onClick={submitForm}>
						등록
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default EmployeeAddHistoryOfManagerDialog;
