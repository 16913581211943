import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import InformationTable from 'components/ui/InformationTable';
import CRDialog from 'components/base/CRDialog';
import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface Props {
	templateTitle?: string;
	messageTitle?: string;
	description?: string;
	receiverName?: string;
	hideDialog?: () => void;
	onClickSend?: () => void;
}

function ContractSendDialog({
	templateTitle = '',
	messageTitle = '',
	description = '',
	receiverName = '',
	onClickSend,
	hideDialog,
}: Props): React.ReactElement {
	return (
		<CRDialog
			title='발송 상세'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<S.BodyContainer>
						<CRInputLabel label='발송 정보'>
							<InformationTable
								items={[
									[
										{
											label: '템플릿명',
											value: templateTitle,
										},
									],
									[
										{
											label: '제목',
											value: messageTitle,
										},
									],
									[
										{
											label: '수신자',
											value: receiverName,
										},
									],
								]}
							/>
						</CRInputLabel>
						<CRInputLabel label='발송 내용'>
							<CRInput.TextArea
								disabled
								numberOfLines={10}
								value={description.replace(/<br\/>|\\n/g, '\n')}
							/>
						</CRInputLabel>
					</S.BodyContainer>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={onClickSend}>
						발송
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(ContractSendDialog);
