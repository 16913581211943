import styled, { css } from 'styled-components';

export const Container = styled.button<{
	$isActive: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 4rem;
	height: 4rem;
	background: transparent;
	border: none;
	border-radius: 100%;
	user-select: none;
	outline: none;
	cursor: pointer;

	& > img {
		filter: brightness(0) saturate(100%) invert(7%) sepia(10%) saturate(505%) hue-rotate(148deg)
			brightness(100%) contrast(93%);
	}

	${(props) =>
		props.$isActive &&
		css`
			background: ${(props) => props.theme.colors.primary90};
			& > img {
				filter: brightness(0) saturate(100%) invert(63%) sepia(92%) saturate(4769%)
					hue-rotate(323deg) brightness(102%) contrast(101%);
			}
		`};

	&:hover {
		background: ${(props) => props.theme.colors.primary95};
		& > img {
			filter: brightness(0) saturate(100%) invert(63%) sepia(92%) saturate(4769%) hue-rotate(323deg)
				brightness(102%) contrast(101%);
		}
	}
	&:active {
		background: ${(props) => props.theme.colors.primary90};
		& > img {
			filter: brightness(0) saturate(100%) invert(63%) sepia(92%) saturate(4769%) hue-rotate(323deg)
				brightness(102%) contrast(101%);
		}
	}
	&:disabled {
		background: transparent;
		& > img {
			filter: brightness(0) saturate(100%) invert(64%) sepia(0%) saturate(814%) hue-rotate(241deg)
				brightness(90%) contrast(84%);
		}
	}
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;
