import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const SyncStateButton = styled.div`
	position: relative;
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0.8rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;
