import { styled } from 'styled-components';

export const Content = styled.div`
	width: fit-content;
	height: max-content;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: hidden;
`;

export const Table = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
`;
