import Typography from 'common/typography';
import { styled } from 'styled-components';

export const Container = styled.div<{ $typography: keyof typeof Typography }>`
	${(props) => props.theme.typography[props.$typography]};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const EllipsisText = styled.span`
	width: 50px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const CountText = styled.span`
	${(props) => props.theme.typography.label};
`;
