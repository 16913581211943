import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 80rem;
	box-sizing: border-box;
	gap: 2.4rem;
`;
