import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import useDialog from 'lib/hook/util/useDialog';
import { AutomationUploadDTO } from 'types/api/common';
import dayjs from 'dayjs';
import { useAutomationUploadInfo, useMyAccountInfo } from 'lib/hook/react-query';
import * as S from './styles';
import {
	SCHEDULE_SYNC_HISTORY_TABLE_HEADER_CONFIG,
	SCHEDULE_SYNC_HISTORY_STATUS_CONFIG,
} from './constants';
import CRDialog from '../../../base/CRDialog';
import { ScheduleSyncResultDialog } from '../ScheduleSyncResultDialog';

function TagErrorSyncHistoryDialog(): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: automationInfo } = useAutomationUploadInfo({
		centerId: myAccountInfo?.centerId,
		uploadTypeCds: ['CMN107.RFID'],
	});
	const { showDialog, hideDialog } = useDialog();

	const handleClickScheduleSyncResult = (item: AutomationUploadDTO) => () => {
		showDialog(() => <ScheduleSyncResultDialog item={item} />);
	};

	const Status = useCallback(
		(status: string) => <CRStatus options={SCHEDULE_SYNC_HISTORY_STATUS_CONFIG}>{status}</CRStatus>,
		[],
	);
	// eslint-disable-next-line
	const CreatedNm = useCallback((name: string) => <>{name ?? '-'}</>, []);

	const Date = useCallback(
		// eslint-disable-next-line
		(name: string) => <>{name ? dayjs(name).format('YYYY.MM.DD HH:mm') : '-'}</>,
		[],
	);

	const ButtonContainer = useCallback(
		(data: number, item?: AutomationUploadDTO) => (
			<S.OptionContainer>
				{item?.uploadResultDesc && item.uploadStateCd !== 'CMN108.ERROR' && (
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='xSmall'
						onClick={handleClickScheduleSyncResult(item)}>
						결과
					</CRButton.Default>
				)}
			</S.OptionContainer>
		),
		[],
	);

	const renderSyncRange = useCallback(
		(uploadTargetYm: string) => (
			<S.TextBox>
				{`${dayjs(uploadTargetYm).startOf('M').format('YYYY.MM.DD')} ~ ${dayjs(uploadTargetYm)
					.endOf('M')
					.format('YYYY.MM.DD')}`}
			</S.TextBox>
		),
		[],
	);
	return (
		<CRDialog
			type='L'
			onClickClose={hideDialog}
			title='태그 내역 동기화 실행 이력'
			body={
				<div style={{ position: 'relative' }}>
					<CRTable.Root>
						<CRTable.Head heads={SCHEDULE_SYNC_HISTORY_TABLE_HEADER_CONFIG} />
						<CRTable.Body>
							{automationInfo?.map((item) => (
								<CRTable.Row
									key={item.uploadHistId}
									item={item}
									customRender={{
										uploadStateNm: Status,
										createdNm: CreatedNm,
										uploadStartDate: Date,
										uploadEndDate: Date,
										uploadTargetYm: renderSyncRange,
										uploadHistId: ButtonContainer,
									}}
									renderKeys={[
										'uploadStateNm',
										'uploadStartDate',
										'uploadEndDate',
										'uploadTargetYm',
										'createdNm',
										'uploadHistId',
									]}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</div>
			}
		/>
	);
}

export default React.memo(TagErrorSyncHistoryDialog);
