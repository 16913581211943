import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	overflow: overlay;
`;

export const ServiceTypeBadgeContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const ServiceTypeBadge = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
	background: ${(props) => props.theme.colors.gray90};
	border-radius: 100rem;
	height: 2.4rem;
	padding: 0 0.8rem;
	box-sizing: border-box;
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 3.3rem;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const DayCount = styled(TextBox)``;

export const TextContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	width: 100%;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;
