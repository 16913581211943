import { styled } from 'styled-components';

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const ContentContainer = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.white};
	padding: 0rem 0rem 2.4rem 0rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const Blind = styled.div`
	position: sticky;
	z-index: 20;
	width: 100%;
	top: 0rem;
	height: 2.4rem;
	background: ${(props) => props.theme.colors.white};
`;

export const Content = styled.div`
	/* width: fit-content; */
	width: 100%;
	height: 100%;
	padding: 0rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
`;
