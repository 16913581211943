import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const RECORDING_SHEET_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '',
		sortKey: '',
		checkbox: true,
	},
	{
		width: '9.6rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '13.6rem',
		label: '전화번호',
		sortKey: '전화번호',
	},
	{
		width: '12rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '9.6rem',
		label: '직원1',
		sortKey: '직원1',
	},
	{
		width: '13.6rem',
		label: '직원1 전화 번호',
		sortKey: '직원1 전화 번호',
	},
	{
		width: '9.6rem',
		label: '직원2',
		sortKey: '직원2',
	},
	{
		width: '13.6rem',
		label: '직원2 전화 번호',
		sortKey: '직원2 전화 번호',
	},
	{
		width: '12rem',
		label: '기록지',
		sortKey: '기록지',
	},
	{
		width: '12rem',
		label: '발송여부',
		sortKey: '발송여부',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '100%',
		label: ' ',
		sortKey: ' ',
	},
];

export const TAG_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '정상',
		label: '정상',
		color: 'green',
	},
	{
		key: '오류',
		label: '오류',
		color: 'red',
	},
];

export const RECORDING_SHEET_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '발송',
		label: '발송',
		color: 'green',
	},
	{
		key: '미발송',
		label: '미발송',
		color: 'red',
	},
];

export const SALARY_RECORDING_PAPER = {
	crfeNm: 'template/CMN119_0053.crfe',
	params: [
		{
			centerNm: '케어링 라온센터',
			centerMark: '21165000191',
			recipientBirthDt: '1947.07.21',
			longTermNo: 'L9910356272',
			longTermGradeNm: '2',
			year: '2023',
			month: '10',
			day: '12',
			totalMinute: '50',
			startTime: '12:00',
			endTime: '13:00',
			carNumber: '12다 1234',
			carChk1: '1',
			carChk2: '1',
			noCarChk1: '1',
			noCarChk2: '1',
			noCarChk3: '1',
			provideChk1: '1',
			provideChk2: '1',
			provideChk3: '1',
			beforeBathChk1: '1',
			beforeBathChk2: '1',
			beforeBathChk3: '1',
			afterBathChk1: '1',
			afterBathChk2: '1',
			afterBathChk3: '1',
			specialContent:
				'특이사항입니다\n특이사항입니다\n특이사항입니다\n특이사항입니다\n특이사항입니다\n',
			worker1Nm: '이대현',
			signWorker1Nm: '이대현',
			worker2Nm: '최대현',
			signWorker2Nm: '이대현',
			recipientNm: '정은구',
			signRecipientNm: '정은구',
		},
		{
			centerNm: '케어링 라온센터',
			centerMark: '21165000191',
			recipientBirthDt: '1947.07.21',
			longTermNo: 'L9910356272',
			longTermGradeNm: '2',
			year: '2023',
			month: '10',
			day: '13',
			totalMinute: '50',
			startTime: '12:00',
			endTime: '13:00',
			carNumber: '12다 1234',
			carChk1: '1',
			carChk2: '1',
			noCarChk1: '1',
			noCarChk2: '1',
			noCarChk3: '1',
			provideChk1: '1',
			provideChk2: '1',
			provideChk3: '1',
			beforeBathChk1: '1',
			beforeBathChk2: '1',
			beforeBathChk3: '1',
			afterBathChk1: '1',
			afterBathChk2: '1',
			afterBathChk3: '1',
			specialContent:
				'특이사항입니다\n특이사항입니다\n특이사항입니다\n특이사항입니다\n특이사항입니다\n',
			worker1Nm: '이대현',
			signWorker1Nm: '이대현',
			worker2Nm: '최대현',
			signWorker2Nm: '이대현',
			recipientNm: '정은구',
			signRecipientNm: '정은구',
		},
		{
			centerNm: '케어링 라온센터',
			centerMark: '21165000191',
			recipientBirthDt: '1947.07.21',
			longTermNo: 'L9910356272',
			longTermGradeNm: '2',
			year: '2023',
			month: '10',
			day: '14',
			totalMinute: '50',
			startTime: '12:00',
			endTime: '13:00',
			carNumber: '12다 1234',
			carChk1: '1',
			carChk2: '1',
			noCarChk1: '1',
			noCarChk2: '1',
			noCarChk3: '1',
			provideChk1: '1',
			provideChk2: '1',
			provideChk3: '1',
			beforeBathChk1: '1',
			beforeBathChk2: '1',
			beforeBathChk3: '1',
			afterBathChk1: '1',
			afterBathChk2: '1',
			afterBathChk3: '1',
			specialContent:
				'특이사항입니다\n특이사항입니다\n특이사항입니다\n특이사항입니다\n특이사항입니다\n',
			worker1Nm: '이대현',
			signWorker1Nm: '이대현',
			worker2Nm: '최대현',
			signWorker2Nm: '이대현',
			recipientNm: '정은구',
			signRecipientNm: '정은구',
		},
	],
};
