import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
`;

export const TableContainer = styled.div`
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-top: 0rem;
	border-bottom: 0rem;
	min-height: 30rem;
	height: 100%;
	overflow-y: scroll;
`;
