import React from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { MyCenterAddAccount, MyCenterDocument } from 'lib';

import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';

import { ResponseCode } from 'types/api';
import { AddCenterAccountForm } from 'types/view/accountManagement';
import { CheckOption } from 'components/base/Selections/type';
import {
	CenterAccountManagementContentDTO,
	CenterAccountSaveRequest,
} from 'types/api/accountManagement';
import {
	useSaveCenterAccount,
	useUpdateCenterAccount,
} from 'lib/hook/react-query/mutation/accountManagement';
import * as S from './styles';

interface Props {
	item?: CenterAccountManagementContentDTO;
	bankOptions: CheckOption[];
	hideDialog: () => void;
	onRefresh: () => void;
}

enum EXCEPT_BANK {
	신한은행 = 'CMN037.088',
}

export default function AddCenterAccountDialog({
	item,
	bankOptions,
	hideDialog,
	onRefresh,
}: Props) {
	const {
		control,
		handleSubmit,
		trigger,
		watch,
		formState: { isValid },
	} = useForm<AddCenterAccountForm>({
		resolver: yupResolver(MyCenterAddAccount),
		mode: 'onChange',
		defaultValues: {
			centerAccountBankCd: bankOptions?.find(
				(option) => option.value === item?.centerAccountBankCd,
			),
			centerAccountAliasNm: item?.centerAccountAliasNm || '',
			centerAccountNo: item?.centerAccountNo || '',
			centerAccountPassword: item?.centerAccountPassword || '',
			centerAccountBizNo: item?.centerAccountBizNo || '',
			centerAccountLoginId: item?.centerAccountLoginId || '',
			centerAccountLoginPassword: item?.centerAccountLoginPassword || '',
		},
	});

	const { data: myAccountInfo } = useMyAccountInfo();

	const { mutate: saveCenterAccount } = useSaveCenterAccount((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			hideDialog();
			Toast.success(`정상적으로 계좌 추가를 완료하였습니다.`);
			onRefresh();
		} else {
			Toast.error(`계좌 추가에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
		}
	});

	const { mutate: updateCenterAccount } = useUpdateCenterAccount((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			hideDialog();
			Toast.success(`정상적으로 계좌 수정을 완료하였습니다.`);
			onRefresh();
		} else {
			Toast.error(`계좌 수정에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
		}
	});

	const onSubmit = async (data: AddCenterAccountForm) => {
		if (!myAccountInfo?.centerId) return;
		const isEditMode = !!item;
		const params: CenterAccountSaveRequest = {
			centerId: myAccountInfo.centerId,
			centerAccountBankCd: data.centerAccountBankCd.value,
			centerAccountNo: data.centerAccountNo,
			centerAccountBizNo: data.centerAccountBizNo,
			centerAccountAliasNm: data.centerAccountAliasNm,
			centerAccountPassword: data.centerAccountPassword,
		};

		if (data.centerAccountBankCd.value === EXCEPT_BANK.신한은행) {
			params.centerAccountLoginId = data.centerAccountLoginId;
			params.centerAccountLoginPassword = data.centerAccountLoginPassword;
		}

		if (isEditMode) {
			params.centerAccountId = item.centerAccountId;
			updateCenterAccount(params);
		} else {
			saveCenterAccount(params);
		}
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title={`계좌 ${item ? '수정' : '추가'}`}
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.Content>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel isRequired label='은행'>
									<CRInput.Selector
										onChangeValue={onChange}
										currentValue={value}
										items={bankOptions}
										placeholder='은행 선택'
									/>
								</CRInputLabel>
							)}
							name='centerAccountBankCd'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel isRequired label='계좌 별칭'>
									<CRInput.Default
										onChange={onChange}
										value={value}
										placeholder='계좌 별칭 입력'
										maxLength={50}
									/>
								</CRInputLabel>
							)}
							name='centerAccountAliasNm'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel isRequired label='계좌 번호'>
									<CRInput.Default
										type='number'
										onChange={onChange}
										value={value}
										placeholder='계좌 번호 입력'
										maxLength={255}
									/>
								</CRInputLabel>
							)}
							name='centerAccountNo'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
								<CRInputLabel label='계좌 비밀번호' isRequired>
									<CRInput.Password
										onChange={onChange}
										value={value}
										placeholder='비밀번호 입력'
										toggleSecret
									/>
								</CRInputLabel>
							)}
							name='centerAccountPassword'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel isRequired label='사업자등록번호'>
									<CRInput.Default
										type='number'
										onChange={onChange}
										value={value}
										placeholder='사업자등록번호 입력'
										maxLength={12}
									/>
								</CRInputLabel>
							)}
							name='centerAccountBizNo'
							control={control}
						/>
						{watch('centerAccountBankCd')?.value === EXCEPT_BANK.신한은행 && (
							<>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel isRequired label='로그인 아이디'>
											<CRInput.Default
												onChange={onChange}
												value={value}
												placeholder='로그인 아이디 입력'
												maxLength={50}
											/>
										</CRInputLabel>
									)}
									name='centerAccountLoginId'
									control={control}
								/>
								<Controller
									render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
										<CRInputLabel label='로그인 비밀번호' isRequired>
											<CRInput.Password
												onChange={onChange}
												value={value}
												placeholder='로그인 비밀번호 입력'
												toggleSecret
												maxLength={255}
											/>
										</CRInputLabel>
									)}
									name='centerAccountLoginPassword'
									control={control}
								/>
							</>
						)}
					</S.Content>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						{item ? '수정' : '추가'}
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
