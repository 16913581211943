import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import Assets from 'assets';
import * as S from './styles';

interface IProps {
	value?: string;
	placeholder?: string;
	onChange?: (searchValue: string) => void;
	onSearch?: () => void;
}

function CRSearch({ value = '', placeholder = '', onChange, onSearch }: IProps) {
	const [isFolded, setIsFolded] = useState(true);
	const [isAfterClear, setIsAfterClear] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const searchRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (value === '' && isAfterClear) {
			onSearch?.();
			setIsAfterClear(false);
		}
	}, [value, isAfterClear]);

	const handleToggleSearch = () => {
		if (!isFolded) {
			if (!value) {
				setIsFolded(true);
			} else {
				onSearch?.();
			}
		} else {
			setIsFolded(false);
		}
		if (inputRef?.current) {
			inputRef.current.focus();
		}
	};

	const handleInputClose = () => {
		if (onChange) onChange('');
		setIsAfterClear(true);
		inputRef.current?.focus();
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSearch?.();
		}
	};
	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!searchRef.current?.contains(e.target as Node)) {
				if (!value) {
					setIsFolded(true);
				}
			}
		};
		document.addEventListener('mousedown', handleMouseOut);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut);
		};
	}, [value]);

	return (
		<S.Container ref={searchRef}>
			<S.SearchIcon src={Assets.icon.search} onClick={handleToggleSearch} />
			<S.SearchBar $isFolded={isFolded}>
				<S.SearchInput
					ref={inputRef}
					placeholder={placeholder}
					value={value}
					onChange={(e) => {
						if (onChange) onChange(e.target.value);
					}}
					onKeyDown={onKeyDown}
				/>
			</S.SearchBar>
			{!isFolded && (
				<S.CloseIcon
					$isWriting={!!value}
					src={Assets.icon.closeCircle}
					onClick={handleInputClose}
				/>
			)}
		</S.Container>
	);
}

export default CRSearch;
