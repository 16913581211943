import { CRTableHeadConfig } from 'types/view/base';

export const HEALTH_SCREENING_HISTORY_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '검진일',
		sortKey: '검진일',
	},
	{
		width: '12rem',
		label: '종류',
		sortKey: '종류',
	},
	{
		width: '16rem',
		label: '검진기관',
		sortKey: '검진기관',
	},
	{
		width: '24rem',
		label: '검진 결과',
		sortKey: '검진 결과',
	},
	{
		width: '12rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '100%',
		label: '비고',
		sortKey: '비고',
	},
	{
		width: '12.9rem',
		label: ' ',
		sortKey: '',
	},
];
