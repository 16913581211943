import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 2.4rem;
	flex: 1;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	overflow: auto;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	box-sizing: border-box;
`;

export const SectionTitle = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: space-between;
	color: ${(props) => props.theme.colors.gray10};
`;

export const LeftSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	flex: 1;
	min-width: 92.8rem;
`;

export const RightSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 60rem;
	min-width: 55rem;
	height: 100%;
`;

export const TableContainer = styled.div`
	height: 27.4rem;
	overflow: auto;
`;

export const ButtonContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const Badge = styled.div`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
`;

export const SignStatusBadge = styled.div<{ $isActive: boolean; $color: 'green' | 'red' }>`
	${(props) => props.theme.typography.label};
	display: flex;
	align-items: center;
	gap: 0.8rem;

	color: ${(props) => (props.$isActive ? props.theme.colors.gray00 : props.theme.colors.gray60)};

	${Badge} {
		background: ${(props) =>
			props.$isActive ? props.theme.colors[props.$color || 'green'] : props.theme.colors.gray};
	}
`;

export const SignDateText = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
`;

export const Icon = styled.img<{ $isSingleUploaded?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;
	${(props) => props.theme.filters.gray60};
`;

export const FileList = styled.ul`
	padding: 1.6rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const FileListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
	& + & {
		margin-top: 0.8rem;
	}
`;

export const DownloadContainer = styled.a`
	display: flex;
	align-items: center;
`;

export const FileInfo = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	flex: 1;
	cursor: pointer;
`;

export const FileName = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	text-decoration: underline;
`;

export const FileSize = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const DownloadIcon = styled(Icon)`
	filter: brightness(0) saturate(100%) invert(61%) sepia(6%) saturate(120%) hue-rotate(149deg)
		brightness(94%) contrast(84%);
	cursor: pointer;
`;

export const TextAreaContainer = styled.div`
	height: 40rem;
	overflow: auto;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray95};
`;

export const TableLabel = styled.div``;
export const TableValue = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
`;
