import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckOption } from 'components/base/Selections/type';
import { Toast } from 'components/base/CRToast';
import {
	useCenterList,
	useCenterManagers,
	useCommonCodes,
	useEmployees,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import { CommonCodeDTO } from 'types/api/common';
import { WorkExecuteLogData, WorkExecuteLogFormType } from 'types/view/workExecuteLog';
import {
	useRecipientConsultClient,
	useRecipientWorkExecuteLogServiceSchedulePlan,
} from 'lib/hook/react-query/query/workExecuteLog';
import dayjs from 'dayjs';
import {
	GetRecipientWorkExecuteLogNewDTO,
	GetWorkExecuteLogServiceSchedulePlanDTO,
	UpdateRecipientWorkExecuteLogRequest,
} from 'types/api/workExecuteLog';
import { displayPadTime } from 'lib/util';
import { useUpdateRecipientWorkExecuteLog } from 'lib/hook/react-query/mutation/workExecuteLog';
import { ConsultClientGuardianInfo, ResponseCode } from 'types/api';
import RouterPath from 'common/router';
import {
	WorkExecuteLogCode,
	insuranceTypes,
	writeCompleteYnOptions,
} from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	VisitStatusCode,
	salaryOfferVisitOption,
} from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogVisitForm/constant';
import { useCenterChiefs } from 'lib/hook/react-query/query/centerInfo';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';

interface WorkExecuteLogContextType {
	form: UseFormReturn<WorkExecuteLogFormType>;
	commonCodes?: {
		[key: string]: CheckOption<CommonCodeDTO>[];
	};
	workExecuteLogNewData?: GetRecipientWorkExecuteLogNewDTO;
	workExecuteLogServiceSchedulePlan?: GetWorkExecuteLogServiceSchedulePlanDTO[];
	advisorOptions?: ConsultClientGuardianInfo[];
	saveWorkExecuteLog?: () => void;
	validate?: (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => void;
	isLoaded?: boolean;
	setBaseData: React.Dispatch<React.SetStateAction<WorkExecuteLogData | undefined>>;
}

export const WorkExecuteLogContext = React.createContext<WorkExecuteLogContextType | null>(null);

function WorkExecuteLogProvider({ children }: { children: React.ReactNode }) {
	const { currentRecipient } = useRecipientPage();
	const [isInitForm, setIsInitForm] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const [isLoaded] = useState(false);
	const [baseData, setBaseData] = useState<WorkExecuteLogData>();
	const params = useParams<{ id?: string; detailId?: string }>();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManager } = useCenterManagers({ centerId: myAccountInfo?.centerId });
	const { data: centers } = useCenterList();
	const { data: consultClient } = useRecipientConsultClient({
		centerId: myAccountInfo?.centerId,
		recipientId: params.id,
	});
	const { data: centerChiefs } = useCenterChiefs();
	const { data: employeesData } = useEmployees({
		centerIds: myAccountInfo?.centerId ? [myAccountInfo.centerId] : [],
	});

	const employeesOptions = useMemo(
		() =>
			(employeesData ?? []).map(
				(item) =>
					({
						label: item.korMemberNm,
						value: {
							id: item.employeeId,
							name: item.korMemberNm,
							duty: item.dutyNms,
							birth: item.birthDt,
						},
					} as CheckOption),
			),
		[employeesData],
	);

	const centerChiefOptions = useMemo(
		() =>
			centerChiefs
				?.filter((item) => item?.centerId === myAccountInfo?.centerId)
				?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})) || [],
		[centerChiefs, myAccountInfo],
	);

	const advisorOptions =
		consultClient?.[0] && consultClient?.[0].guardians?.length
			? [
					...consultClient[0].guardians.filter((item) => !item.selfGuardianYn),
					{
						clientDivCd: consultClient?.[0]?.clientDivCd,
						clientId: consultClient?.[0]?.clientId,
						clientNm: consultClient?.[0]?.clientNm,
						clientBirth: consultClient?.[0]?.clientBirth,
					},
			  ]
			: [];

	const isNew = useMemo(() => {
		if (params.detailId && typeof !Number.isNaN(Number(params.detailId))) {
			return false;
		}
		return true;
	}, [params.detailId]);

	const centerOption = (centers ?? []).map(
		(item) => ({ label: item.centerChiefNm, value: item.centerChiefId } as CheckOption),
	);

	const centerManagerOption = (centerManager ?? []).map(
		(item) => ({ label: item.userNm, value: item.memberAccountId } as CheckOption),
	);

	const saveWorkExecuteLogMutation = useUpdateRecipientWorkExecuteLog((client, returnData) => {
		client.invalidateQueries([endpoint.getRecipientWorkExecuteLogDetail.key]);
		client.invalidateQueries([endpoint.getWorkExecuteLog.key]);
	});

	const form = useForm<WorkExecuteLogFormType>({
		defaultValues: {
			workExecuteLogDivCd: [],
			workExecuteLogStateCd: undefined,
			visitDt: undefined,
			visitor:
				centerManagerOption.find((item) => item.value === myAccountInfo?.memberAccountId) ||
				undefined,
			time: undefined,
			facilityManager: undefined,
			visitSchedule: undefined,
			visitPlaceNm: '수급자 가정',
			salaryOfferVisitYn: [salaryOfferVisitOption.find((item) => item.value)],
			writeCompleteYn: writeCompleteYnOptions[0],
			consultDesc: '',
		},
	});

	const { data: workExecuteLogServiceSchedulePlan } = useRecipientWorkExecuteLogServiceSchedulePlan(
		{
			centerId: myAccountInfo?.centerId,
			recipientId: Number(params.id),
			year: String(dayjs().get('year')),
			searchDate: form.getValues('visitDt') && dayjs(form.getValues('visitDt')).format('YYYYMMDD'),
		},
	);

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN006', 'CMN183', 'CMN184'],
		},
		commonCodeAdapter,
	);

	const serviceTypeOption =
		commonCodes?.CMN006?.filter((item) => item.data?.etcDesc1 === 'Y') || [];

	const validate = () => {
		const initHash = (target: string) => {
			navigate(target);
			setTimeout(() => {
				navigate(location.pathname, {
					replace: true,
				});
			});
		};

		if (!form.getValues('workExecuteLogDivCd')?.length) {
			Toast.error('기본정보 - 구분을 입력해주세요.');
			initHash('#1');
			return false;
		}
		if (!form.getValues('workExecuteLogStateCd')) {
			Toast.error('방문정보 - 상태를 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitDt')) {
			Toast.error('방문정보 - 방문(상담) 일시 - 날짜를 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (form.formState.errors?.time?.message) {
			Toast.error('방문정보 - 방문(상담) 시간을 확인해주세요');
			initHash('#2');
			return false;
		}

		if (!form.getValues('time')?.startTime) {
			Toast.error('방문정보 - 방문(상담) 시작 시간을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (form.getValues('time')?.startTime?.length !== 5) {
			Toast.error('올바른 시작 시간을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('time')?.endTime) {
			Toast.error('방문정보 - 방문(상담) 종료 시간을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (form.getValues('time')?.endTime?.length !== 5) {
			Toast.error('올바른 종료 시간을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitor')) {
			Toast.error('방문정보 - 방문자를 입력해주세요');
			initHash('#2');
			return false;
		}

		if (!form.getValues('facilityManager')) {
			Toast.error('방문정보 - 시설장(관리책임자)을 입력해주세요');
			initHash('#2');
			return false;
		}

		if (
			form.getValues('salaryOfferVisitYn')?.[0].value === true &&
			!form.getValues('visitSchedule')?.length
		) {
			Toast.error('방문정보 - 요양보호사 방문일정을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (
			form.getValues('salaryOfferVisitYn')?.[0].value === false &&
			!form.getValues('serviceTypeCd')?.length
		) {
			Toast.error('방문정보 - 급여 종류를 선택해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitPlaceNm')) {
			Toast.error('방문정보 - 방문장소를 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('salaryOfferVisitYn')?.[0]?.value) {
			if (!form.getValues('visitDenyDesc')) {
				Toast.error('방문정보 - 방문불가 사유를 입력해주세요.');
				initHash('#2');
				return false;
			}
		}

		if (!form.getValues('client')?.length) {
			Toast.error('상담 - 상담자를 입력해주세요.');
			initHash('#3');
			return false;
		}

		if (form.getValues('salaryOfferVisitYn')?.[0].value === false && !form.getValues('employee1')) {
			Toast.error('급여 및 인지활동 프로그램 제공 확인 - 급여제공자1을 입력해주세요.');
			initHash('#5');
			return false;
		}

		if (
			form.getValues('salaryOfferVisitYn')?.[0].value === false &&
			form.getValues('serviceTypeCd')?.[0].value === ServiceTypeCd.방문목욕 &&
			!form.getValues('employee2')
		) {
			Toast.error('급여 및 인지활동 프로그램 제공 확인 - 급여제공자2를 입력해주세요.');
			initHash('#5');
			return false;
		}

		return true;
	};

	const goBackPage = () => {
		navigate(
			`${RouterPath.recipient().tab.key}/${params.id}/${
				RouterPath.recipient().workExecuteLogTab.uniqueKey
			}`,
			{
				replace: true,
			},
		);
	};

	const saveWorkExecuteLog = async () => {
		if (saveWorkExecuteLogMutation.isLoading) return;

		if (!validate() || !myAccountInfo?.centerId || !baseData) return;

		const isNonVisit = form.getValues('salaryOfferVisitYn')?.[0]?.value === false;

		const employees = form.getValues('visitSchedule')?.[0]?.data?.employeeId?.split(',');

		const param: UpdateRecipientWorkExecuteLogRequest = {
			workExecuteLogDivCd: form.getValues('workExecuteLogDivCd')?.[0]?.value,
			workExecuteLogStateCd: form.getValues('workExecuteLogStateCd')?.value,
			centerId: myAccountInfo.centerId,
			recipientId: baseData?.recipient.recipientId,
			recipientNm: baseData.recipient.recipientNm,
			recipientBirthDt: baseData.recipient.birthDt,
			recipientGenderCd: baseData.recipient.genderCd,
			longTermNo: baseData.recipient.longTermNo || '',
			longTermMajorChangeNo: baseData.recipient.longTermMajorChangeNo || '',
			longTermMinorChangeNo: baseData.recipient.longTermMinorChangeNo || '',
			longTermGradeCd: baseData.recipient.longTermGradeCd || '',
			visitorId: form.getValues('visitor')?.value,
			facilityManagerNm: form.getValues('facilityManager')?.label || '',
			facilityManagerId: form.getValues('facilityManager')?.value,
			visitDt: dayjs(form.getValues('visitDt')).format('YYYYMMDD'),
			startTime: form.getValues('time.startTime').replace(':', ''),
			endTime: form.getValues('time.endTime').replace(':', ''),
			serviceStartDt: !isNonVisit
				? form.getValues('visitSchedule')?.[0]?.data?.serviceStartDt
				: undefined,
			serviceStartTime: !isNonVisit
				? form.getValues('visitSchedule')?.[0]?.data?.serviceStartTime
				: undefined,
			serviceEndDt: !isNonVisit
				? form.getValues('visitSchedule')?.[0]?.data?.serviceStartDt
				: undefined,
			serviceEndTime: !isNonVisit
				? form.getValues('visitSchedule')?.[0]?.data?.serviceEndTime
				: undefined,
			visitPlaceNm: form.getValues('visitPlaceNm') || '',
			salaryOfferVisitYn: form.getValues('salaryOfferVisitYn')?.[0].value,
			serviceTypeCd: isNonVisit
				? form.getValues('serviceTypeCd')?.[0]?.value
				: form.getValues('visitSchedule')?.[0]?.data?.serviceTypeCd,
			clientDivCd: form.getValues('client')?.[0]?.data?.clientDivCd,
			clientId: form.getValues('client')?.[0]?.data?.clientId,
			writeCompleteYn: form.getValues('writeCompleteYn').value,
		};

		// param.employeeId1 = form.getValues('employee1')?.value?.id;
		// if (form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕) {
		// 	param.employeeId2 = form.getValues('employee2')?.value?.id;
		// }

		if (isNonVisit) {
			param.employeeId1 = form.getValues('employee1')?.value?.id;
			if (form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕) {
				param.employeeId2 = form.getValues('employee2')?.value?.id;
			}
		} else {
			param.employeeId1 = Number(employees[0]);
			if (employees?.[1]) {
				param.employeeId2 = Number(employees[1]);
			}
		}

		if (baseData?.data?.workExecuteLogId) {
			param.workExecuteLogId = baseData.data.workExecuteLogId;
		}

		if (form.getValues('visitDenyDesc')) {
			param.visitDenyDesc = form.getValues('visitDenyDesc');
		}

		if (form.getValues('client')?.[0] && form.getValues('client')?.[0]?.data?.mainGuardianYn) {
			param.clientMainGuardianYn = true;
			param.clientPhoneNo = form.getValues('client')[0].data.telNum;
		}

		if (form.getValues('consultDesc')) {
			param.consultDesc = form.getValues('consultDesc');
		}

		if (form.getValues('workExecuteLogDivCd')?.[0].value === WorkExecuteLogCode.프로그램관리자) {
			if (form.getValues('recogStimulationNeedDesc')) {
				param.recogStimulationNeedDesc = form.getValues('recogStimulationNeedDesc');
			}
			if (form.getValues('recogStimulationProvisionMethodDesc')) {
				param.recogStimulationProvisionMethodDesc = form.getValues(
					'recogStimulationProvisionMethodDesc',
				);
			}
			if (form.getValues('physicalAbilityNeedDesc')) {
				param.physicalAbilityNeedDesc = form.getValues('physicalAbilityNeedDesc');
			}
			if (form.getValues('physicalAbilityProvisionMethodDesc')) {
				param.physicalAbilityProvisionMethodDesc = form.getValues(
					'physicalAbilityProvisionMethodDesc',
				);
			}
		}
		if (form.getValues('salaryrecogActpgOfferConfirmDesc')) {
			param.salaryrecogActpgOfferConfirmDesc = form.getValues('salaryrecogActpgOfferConfirmDesc');
		}
		if (form.getValues('salaryrecogActpgOfferActionDesc')) {
			param.salaryrecogActpgOfferActionDesc = form.getValues('salaryrecogActpgOfferActionDesc');
		}

		if (form.getValues('physicalConditionDesc')) {
			param.physicalConditionDesc = form.getValues('physicalConditionDesc');
		}

		if (form.getValues('diseaseDesc')) {
			param.diseaseDesc = form.getValues('diseaseDesc');
		}

		if (form.getValues('recogStateDesc')) {
			param.recogStateDesc = form.getValues('recogStateDesc');
		}

		if (form.getValues('communicationDesc')) {
			param.communicationDesc = form.getValues('communicationDesc');
		}

		if (form.getValues('nutritionalStateDesc')) {
			param.nutritionalStateDesc = form.getValues('nutritionalStateDesc');
		}

		if (form.getValues('familyEnvironmentDesc')) {
			param.familyEnvironmentDesc = form.getValues('familyEnvironmentDesc');
		}

		if (form.getValues('totalOpinionDesc')) {
			param.totalOpinionDesc = form.getValues('totalOpinionDesc');
		}

		if (form.getValues('salaryGoalDesc')) {
			param.salaryGoalDesc = form.getValues('salaryGoalDesc');
		}

		if (form.getValues('needSalaryDesc')) {
			param.needSalaryDesc = form.getValues('needSalaryDesc');
		}

		if (form.getValues('provisionMethodDesc')) {
			param.provisionMethodDesc = form.getValues('provisionMethodDesc');
		}

		const result = await saveWorkExecuteLogMutation.mutateAsync(param);
		if (result?.code === ResponseCode.SUCCESS) {
			setIsInitForm(false);
			form.reset();
			Toast.success('업무수행일지를 저장했습니다.');
			goBackPage();
		}
	};

	const isTimeWithinRange = (
		standardStart: string,
		standardEnd: string,
		start: string,
		end: string,
	) => {
		const timeToMinutes = (time: string) => {
			const [hours, minutes] = time.split(':').map(Number);
			return hours * 60 + minutes;
		};
		const standardStartTime = timeToMinutes(standardStart);
		const standardEndTime = timeToMinutes(standardEnd);
		const startTime = timeToMinutes(start);
		const endTime = timeToMinutes(end);

		if (startTime <= standardEndTime && endTime >= standardStartTime) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (form.getValues('salaryOfferVisitYn')?.[0]?.value === true) {
			form.setValue('visitDenyDesc', '');
		}
	}, [form.getValues('salaryOfferVisitYn')]);

	useEffect(() => {
		const schedule = form.getValues('visitSchedule');
		const time = form.getValues('time');

		if (
			schedule?.length &&
			schedule?.[0]?.data?.serviceStartTime &&
			schedule?.[0]?.data?.serviceEndTime
		) {
			if (time?.startTime?.length === 5 && time?.endTime?.length === 5) {
				const isValid = isTimeWithinRange(
					displayPadTime(schedule[0].data.serviceStartTime),
					displayPadTime(schedule[0].data.serviceEndTime),
					time.startTime,
					time.endTime,
				);
				if (isValid) {
					form.clearErrors('time');
				} else {
					form.setError('time', {
						message: '요양보호사 방문일정에 벗어나지 않도록 설정해 주십시오.',
					});
				}
			}
		}
	}, [form.getValues('time'), form.getValues('visitSchedule')]);

	useEffect(() => {
		if (
			baseData &&
			!isInitForm &&
			commonCodes &&
			centerOption?.length &&
			advisorOptions?.length &&
			centerChiefOptions?.length &&
			serviceTypeOption?.length &&
			employeesOptions?.length
		) {
			const mainGuardian = advisorOptions?.find((item) => item.mainGuardianYn);
			const writeCompleteYn =
				writeCompleteYnOptions.find((item) => item.value === baseData.data?.writeCompleteYn) ??
				writeCompleteYnOptions[0];
			const defaultForm: WorkExecuteLogFormType = {
				physicalConditionDesc: `${isNew ? '[도움 필요 항목]\n' : ''}${
					baseData?.recipientIa?.physicalConditionDesc || ''
				}`,
				client: [],
				diseaseDesc: baseData?.recipientIa?.diseaseDesc || '',
				recogStateDesc: baseData?.recipientIa?.recogStateDesc || '',
				communicationDesc: baseData?.recipientIa?.communicationDesc || '',
				nutritionalStateDesc: baseData?.recipientIa?.nutritionalStateDesc || '',
				familyEnvironmentDesc: baseData?.recipientIa?.familyEnvironmentDesc || '',
				totalOpinionDesc: baseData?.recipientIa?.totalOpinionDesc || '',
				salaryGoalDesc: baseData?.salaryOfferPlan?.salaryGoalDesc || '',
				needSalaryDesc: baseData?.salaryOfferPlan?.needSalaryDesc || '',
				provisionMethodDesc: baseData?.salaryOfferPlan?.provisionMethodDesc || '',
				writeCompleteYn,
			};
			if (baseData.data) {
				const workExecuteLogDivCd = commonCodes.CMN183.find(
					(item) => item.value === baseData.data?.workExecuteLogDivCd,
				);
				const workExecuteLogStateCd = commonCodes.CMN184.find(
					(item) => item.value === baseData.data?.workExecuteLogStateCd,
				);
				const visitDt = dayjs(baseData.data.visitDt).toDate();
				const time = {
					startTime: displayPadTime(baseData.data.startTime),
					endTime: displayPadTime(baseData.data.endTime),
				};
				const facilityManager = centerChiefOptions.find(
					(item) => item.value === baseData.data?.facilityManagerId,
				);
				const employeeId = [baseData.data?.employeeId1, baseData.data?.employeeId2]
					.filter(Boolean)
					.join(',');
				const employee1 = employeesOptions?.find(
					(item) => item.value?.id === baseData.data?.employeeId1,
				);
				const employee2 = employeesOptions?.find(
					(item) => item.value?.id === baseData.data?.employeeId2,
				);

				const serviceTypeCd = serviceTypeOption?.find(
					(item) => item.value === baseData.data?.serviceTypeCd,
				);

				const visitSchedule =
					baseData.data.serviceStartDt &&
					baseData.data.serviceTypeCd &&
					baseData.data.serviceStartTime &&
					baseData.data.serviceEndTime
						? {
								label: '',
								value:
									employeeId +
									baseData.data.serviceStartDt +
									baseData.data.serviceTypeCd +
									baseData.data.serviceStartTime +
									baseData.data.serviceEndTime,
								data: {
									employeeId,
									serviceStartDt: baseData.data.serviceStartDt,
									serviceTypeCd: baseData.data.serviceTypeCd,
									serviceStartTime: baseData.data.serviceStartTime,
									serviceEndTime: baseData.data.serviceEndTime,
								},
						  }
						: undefined;

				const client = [
					{
						label: '',
						value: baseData.data.clientId,
						data: {
							clientDivCd: baseData.data.clientDivCd,
							clientId: baseData.data.clientId,
							telNum: baseData.data.clientMainPhoneNo,
						},
					},
				];

				const salaryOfferVisitYn = baseData.data?.salaryOfferVisitYn
					? salaryOfferVisitOption[0]
					: salaryOfferVisitOption[1];

				defaultForm.workExecuteLogDivCd = workExecuteLogDivCd ? [workExecuteLogDivCd] : [];
				defaultForm.workExecuteLogStateCd = workExecuteLogStateCd;
				defaultForm.visitDt = visitDt;
				defaultForm.visitor =
					centerManagerOption.find((item) => item.value === baseData.data?.visitorId) || undefined;
				defaultForm.time = time;
				defaultForm.facilityManager = facilityManager;
				defaultForm.visitSchedule = visitSchedule ? [visitSchedule] : [];
				defaultForm.visitPlaceNm = baseData.data?.visitPlaceNm || '';
				defaultForm.salaryOfferVisitYn = [salaryOfferVisitYn];
				defaultForm.visitDenyDesc = baseData.data.visitDenyDesc;
				defaultForm.client = baseData.data.clientId ? client : [];
				defaultForm.consultDesc = baseData.data.consultDesc;

				defaultForm.salaryrecogActpgOfferConfirmDesc =
					baseData.data.salaryrecogActpgOfferConfirmDesc || '';
				defaultForm.salaryrecogActpgOfferActionDesc =
					baseData.data.salaryrecogActpgOfferActionDesc || '';

				defaultForm.physicalConditionDesc = baseData.data.physicalConditionDesc;
				defaultForm.diseaseDesc = baseData.data.diseaseDesc;
				defaultForm.recogStateDesc = baseData.data.recogStateDesc;
				defaultForm.communicationDesc = baseData.data.communicationDesc;
				defaultForm.nutritionalStateDesc = baseData.data.nutritionalStateDesc;
				defaultForm.familyEnvironmentDesc = baseData.data.familyEnvironmentDesc;
				defaultForm.totalOpinionDesc = baseData.data.totalOpinionDesc;
				defaultForm.salaryGoalDesc = baseData.data.salaryGoalDesc;
				defaultForm.needSalaryDesc = baseData.data.needSalaryDesc;
				defaultForm.provisionMethodDesc = baseData.data.provisionMethodDesc;
				defaultForm.recogStimulationNeedDesc = baseData.data.recogStimulationNeedDesc;
				defaultForm.recogStimulationProvisionMethodDesc =
					baseData.data.recogStimulationProvisionMethodDesc;
				defaultForm.physicalAbilityNeedDesc = baseData.data.physicalAbilityNeedDesc;
				defaultForm.physicalAbilityProvisionMethodDesc =
					baseData.data.physicalAbilityProvisionMethodDesc;
				defaultForm.employee1 = employee1;
				defaultForm.employee2 = employee2;
				defaultForm.serviceTypeCd = serviceTypeCd ? [serviceTypeCd] : undefined;
			} else {
				defaultForm.workExecuteLogDivCd =
					baseData.recipient.longTermGradeCd !== 'CMN042.5'
						? [insuranceTypes[0]]
						: [insuranceTypes[1]];

				defaultForm.facilityManager = centerOption?.[0];
				defaultForm.workExecuteLogStateCd = commonCodes?.CMN184?.find(
					(item) => item.value === VisitStatusCode.방문예정, // 초기 방문예정으로 설정
				);

				if (mainGuardian) {
					const client = [
						{
							label: '',
							value: mainGuardian.clientId,
							data: mainGuardian,
						},
					];
					defaultForm.client = client;
				}

				// defaultForm.serviceTypeCd = serviceTypeOption[0] ? [serviceTypeOption[0]] : undefined;
			}

			form.reset(defaultForm);
			setIsInitForm(true);
		}
	}, [
		baseData,
		commonCodes,
		isInitForm,
		centerOption,
		isNew,
		advisorOptions,
		centerChiefOptions,
		employeesOptions,
	]);

	useEffect(() => {
		if (currentRecipient && !currentRecipient.mainGuardianNm) {
			goBackPage();
		}
	}, [currentRecipient]);

	const value = useMemo(
		() => ({
			form,
			commonCodes: {
				...commonCodes,
				centerManagerOption,
				centerOption,
				centerChiefOptions,
				serviceTypeOption,
				employeesOptions,
			},
			saveWorkExecuteLog,
			workExecuteLogNewData: baseData,
			workExecuteLogServiceSchedulePlan: workExecuteLogServiceSchedulePlan || undefined,
			validate,
			isLoaded,
			setBaseData,
			advisorOptions: advisorOptions || undefined,
		}),
		[
			form,
			commonCodes,
			centerManagerOption,
			saveWorkExecuteLog,
			isLoaded,
			baseData,
			setBaseData,
			advisorOptions,
			serviceTypeOption,
		],
	);

	return <WorkExecuteLogContext.Provider value={value}>{children}</WorkExecuteLogContext.Provider>;
}
export default React.memo(WorkExecuteLogProvider);
