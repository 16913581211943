import React from 'react';
import { useLocation } from 'react-router-dom';

import { SideNavigationMenuItem } from 'types/view/navigation';
import * as S from './styles';

interface IProps {
	item: SideNavigationMenuItem;
	onClick?: (path: string) => void;
}

function LeftSideNavigationMenuItem({ item, onClick }: IProps): React.ReactElement {
	const location = useLocation();
	const fullPath = location.pathname + location.search;
	const isCurrent = !!fullPath.startsWith(item.path);

	if (item.disableDefaultEvent) {
		return (
			<S.DisabledMenuContainer onClick={() => onClick?.(item.path)}>
				{item.icon && <S.MenuIcon src={item.icon} />}
				<S.MenuLabel>{item.label}</S.MenuLabel>
				{item.addOnButton}
			</S.DisabledMenuContainer>
		);
	}
	return (
		<S.MenuContainer to={isCurrent ? fullPath : item.path} replace>
			{item.icon && <S.MenuIcon src={item.icon} />}
			<S.MenuLabel>{item.label}</S.MenuLabel>
			{item.addOnButton}
		</S.MenuContainer>
	);
}

export default LeftSideNavigationMenuItem;
