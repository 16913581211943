import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

export const ResultContainer = styled.div`
	display: flex;
	align-items: center;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0.8rem 2.4rem;
	box-sizing: border-box;
	gap: 1rem;
`;

export const ResultMessageListContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: overlay;
`;

export const ResultMessageContainer = styled.div`
	padding: 0.8rem 2.4rem;
	box-sizing: border-box;
	& + & {
		border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}
	&:last-of-type {
		border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}
`;
