import styled from 'styled-components';

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	width: ${(p) => p.width};
	height: ${(p) => p.height};
	padding: 0.8rem;
	box-sizing: border-box;
	color: ${(props) => props.theme.colors.gray60};
	background: ${(props) => props.theme.colors.gray99};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;
