import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from '../base/useCRQuery';

export const useCenterAccounts = useCRQuery(endpoint.getCenterAccounts.key, 'getCenterAccounts');
export const useCenterAccountRecords = useCRQuery(
	endpoint.getCenterAccountRecords.key,
	'getCenterAccountRecords',
	{
		enabledKey: ['centerAccountIds'],
	},
);
export const useCenterAccountRecordTargets = useCRQuery(
	endpoint.getCenterAccountRecordTargets.key,
	'getCenterAccountRecordTargets',
	{ enabledKey: ['centerId'] },
);

export const useCenterAccountRecordSummarys = useCRQuery(
	endpoint.getCenterAccountRecordSummarys.key,
	'getCenterAccountRecordSummary',
);
