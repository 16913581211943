import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { DocumentItem } from 'types/view/approval';
import CRAvatar from 'components/base/CRAvatar';
import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import * as S from './styles';
import RelatedTask from '../../RelatedTask';
import testPdf from './test_pdf.pdf';

export type ApprovalInput = {
	type: { label: string; value: any };
	title: string;
	content: string;
	relatedWork: string;
	attachment: File[];
};

export interface Props {
	item?: DocumentItem;
}

export default function ApprovalContent({ item }: Props) {
	const renderUpdate = () => {
		if (!item?.updatedAt) return null;
		return (
			<S.LastUpdate>
				{`${dayjs(item.updatedAt).format('YY.MM.DD HH:mm')}에 업데이트 되었습니다.`}
			</S.LastUpdate>
		);
	};

	if (!item) return <S.Container>로딩=</S.Container>;

	return (
		<S.Container>
			<S.Title>결재 내용</S.Title>
			<S.ContentContainer>
				<S.ContentItem>
					<S.DocumentInfo>
						<S.DocumentId>{item.id}</S.DocumentId>
						<S.DocumentTitle>{item.title}</S.DocumentTitle>
						<S.DownloadPDF href={testPdf} download>
							<CRButton.Default palette='gray' type='outlined' size='xSmall'>
								PDF 다운받기
							</CRButton.Default>
						</S.DownloadPDF>
					</S.DocumentInfo>
					<S.Author>
						<CRAvatar.Default src={item.author.imgUrl} alt={item.author.name} size='default' />
						<span>{`${item.author.name} 님이 결재를 요청했습니다.`}</span>
					</S.Author>
				</S.ContentItem>
				<S.Divider />
				<S.ContentItem>
					<S.DocumentContent>{item.content}</S.DocumentContent>
				</S.ContentItem>
				<S.ContentItem>
					<CRInputLabel label='연결 업무'>
						<RelatedTask item={item.relatedTask} />
					</CRInputLabel>
					{renderUpdate()}
				</S.ContentItem>
				<S.ContentItem>
					<CRInput.FileDownloader items={item.files} />
				</S.ContentItem>
			</S.ContentContainer>
		</S.Container>
	);
}
