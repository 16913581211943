import { css } from 'styled-components';

const Filters = {
	gray10: css`
		filter: invert(7%) sepia(16%) saturate(342%) hue-rotate(148deg) brightness(102%) contrast(92%);
	`,
	gray20: css`
		filter: invert(14%) sepia(9%) saturate(385%) hue-rotate(149deg) brightness(92%) contrast(84%);
	`,
	gray30: css`
		filter: invert(14%) sepia(9%) saturate(385%) hue-rotate(149deg) brightness(92%) contrast(84%);
	`,
	gray40: css`
		filter: invert(38%) sepia(5%) saturate(241%) hue-rotate(131deg) brightness(91%) contrast(85%);
	`,
	gray50: css`
		filter: invert(54%) sepia(0%) saturate(529%) hue-rotate(7deg) brightness(86%) contrast(87%);
	`,
	gray60: css`
		filter: invert(63%) sepia(5%) saturate(134%) hue-rotate(149deg) brightness(90%) contrast(89%);
	`,
	gray70: css`
		filter: invert(77%) sepia(0%) saturate(1614%) hue-rotate(38deg) brightness(90%) contrast(91%);
	`,
	gray80: css`
		filter: invert(84%) sepia(0%) saturate(5006%) hue-rotate(273deg) brightness(91%) contrast(102%);
	`,
	gray90: css`
		filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(160deg) brightness(91%) contrast(95%);
	`,
	gray95: css`
		filter: invert(90%) sepia(0%) saturate(4%) hue-rotate(252deg) brightness(105%) contrast(100%);
	`,
	gray99: css`
		filter: invert(100%) sepia(74%) saturate(734%) hue-rotate(288deg) brightness(119%) contrast(93%);
	`,
	gray100: css`
		filter: invert(99%) sepia(0%) saturate(407%) hue-rotate(305deg) brightness(114%) contrast(100%);
	`,
	primary60: css`
		filter: invert(47%) sepia(99%) saturate(3030%) hue-rotate(325deg) brightness(106%)
			contrast(101%);
	`,

	primary98: css`
		filter: invert(99%) sepia(2%) saturate(1153%) hue-rotate(314deg) brightness(110%) contrast(110%);
	`,
	sbGreen: css`
		filter: brightness(0) saturate(100%) invert(72%) sepia(14%) saturate(1174%) hue-rotate(92deg)
			brightness(99%) contrast(83%);
	`,
};

export default Filters;
