import React, { useEffect } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { Controller, UseFormReturn } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import { InsuranceBaseFormType } from 'types/view/insurance';
import CRInput from 'components/base/CRInput';
import * as S from './styles';

interface Props {
	context: UseFormReturn<InsuranceBaseFormType, any, undefined>;
}

export default function InsuranceBaseForm({ context }: Props) {
	useEffect(
		() => () => {
			context.reset();
		},
		[],
	);
	return (
		<Accordion.Item value='신고 내용' asChild>
			<TaskAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<TaskAccordion.Content>
						<S.FormContainer>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInputLabel
										label='요청 사항'
										type='left-sub'
										isRequired
										showOverflow
										betweenGap='2.4rem'>
										<CRInput.TextArea
											numberOfLines={4}
											onChange={onChange}
											value={value}
											placeholder='요청 사항 입력'
											fixedHeight
										/>
									</CRInputLabel>
								)}
								name='requestDesc'
								control={context.control}
							/>
							<Controller
								render={({ field: { onChange, value } }) => (
									<div
										style={{
											width: '57.2rem',
										}}>
										<CRInputLabel
											label='파일 첨부'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.FileUploader
												onChange={onChange}
												files={value}
												placeholder='파일 업로드'
											/>
										</CRInputLabel>
									</div>
								)}
								name='attachFile'
								control={context.control}
							/>
						</S.FormContainer>
					</TaskAccordion.Content>
				</Accordion.Content>
			</TaskAccordion.Item>
		</Accordion.Item>
	);
}
