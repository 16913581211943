import React, { useMemo } from 'react';

import Assets from 'assets';
import CRTableHeader from 'components/base/CRTableHeader';
import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import { useCommonCodes, useEmployeePageLicenseInfo } from 'lib/hook/react-query';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import EmployeeLicenseTable from 'components/domain/table/EmployeeLicenseTable';
import AddLicenseDialog from 'components/domain/dialog/AddLicenseDialog';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';

export default function EmployeeLicenseTab() {
	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN067'],
	});

	const licenseTypes = useMemo(
		() =>
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN067')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [],
		[commonCodes],
	);
	const { showDialog } = useDialog();
	const { currentEmployee } = useEmployeePage();

	const {
		data: employeeLicenseInfo,
		isLoading,
		refetch,
	} = useEmployeePageLicenseInfo({
		employeeId: currentEmployee?.employeeId,
	});

	const handleRefetch = () => {
		refetch();
	};

	const handleClickAddCareer = () => {
		showDialog(({ hideDialog }) => (
			<AddLicenseDialog
				onDelete={handleRefetch}
				currentEmployee={currentEmployee}
				licenseTypes={licenseTypes}
				hideDialog={hideDialog}
			/>
		));
	};

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={{}}
				filters={[]}
				hideSearchButton
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddCareer}>
						자격증 추가
					</CRButton.IconButton>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<EmployeeLicenseTable
						onRefetch={handleRefetch}
						currentEmployee={currentEmployee}
						items={employeeLicenseInfo}
						licenseTypes={licenseTypes}
					/>
				</S.TableContainer>
			)}
		</S.Container>
	);
}
