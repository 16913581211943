import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import React, { useMemo, useState } from 'react';
import { Filter } from 'types/view/filter';
import { useCommonCodes, useMessageTabSendHistory, useTemplateList } from 'lib/hook/react-query';
import EmployeeMessageTable from 'components/domain/table/EmployeeMessageTable';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import dayjs from 'dayjs';
import { MessageTabSendHistoryDTO } from 'types/api/send';
import useDialog from 'lib/hook/util/useDialog';
import EmployeeMessageTabSendDetailDialog from 'components/domain/dialog/EmployeeMessageTabSendDetailDialog';
import * as S from './styles';

export default function MessageTab() {
	const { showDialog, hideDialog } = useDialog();
	const [searchValue, setSearchValue] = useState<string>('');
	const { currentEmployee } = useEmployeePage();

	const [currentFilter, setCurrentFilter] = useState<{
		rangeFilter: CheckOption[];
		templateTypeFilter: CheckOption[];
		sendStatusFilter: CheckOption[];
	}>({
		rangeFilter: [],
		templateTypeFilter: [],
		sendStatusFilter: [],
	});

	const { data: messageSendHistory, isLoading } = useMessageTabSendHistory({
		type: 'employees',
		id: currentEmployee?.employeeId,
		params: {
			sendStartDate:
				currentFilter.rangeFilter?.[0]?.value?.start &&
				dayjs(currentFilter.rangeFilter[0].value.start).format('YYYY-MM-DDT00:00:00'),
			sendEndDate:
				currentFilter.rangeFilter?.[0]?.value?.end &&
				dayjs(currentFilter.rangeFilter[0].value.end).format('YYYY-MM-DDT23:59:59'),
			sendStateCds: currentFilter.sendStatusFilter?.map((item) => item.value).join(', '),
			sendTemplateIds: currentFilter.templateTypeFilter?.map((item) => item.value).join(', '),
		},
	});

	const messageHistoryData = useMemo(
		() =>
			(messageSendHistory || [])?.filter((item) =>
				item.sendTitle.toLowerCase().includes(searchValue.toLowerCase()),
			),
		[messageSendHistory, searchValue],
	);

	const { data: templateList } = useTemplateList();
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN028'] });

	const rangeFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'calendar',
				placeholder: '발송 일시',
			} as Filter<CheckOption>),
		[],
	);

	const templateTypeFilter = useMemo(
		() =>
			({
				key: 'templateTypeFilter',
				type: 'multi',
				options: (templateList ?? []).map((item) => ({
					label: item.sendTemplateTitle,
					value: item.sendTemplateId,
					data: item,
				})),
				placeholder: '템플릿',
			} as Filter<CheckOption>),
		[templateList],
	);

	const sendStatusFilter = useMemo(
		() =>
			({
				key: 'sendStatusFilter',
				type: 'multi',
				options: (commonCodes?.filter((item) => item.comCdGroupNm === 'CMN028') || [])?.map(
					(item) => ({
						label: item.korComCdAliasNm,
						value: item.comCdId,
					}),
				),
				placeholder: '발송 상태',
			} as Filter<CheckOption>),
		[commonCodes],
	);

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleClickRow = (item: MessageTabSendHistoryDTO) => {
		showDialog(() => (
			<EmployeeMessageTabSendDetailDialog
				employeeId={currentEmployee?.employeeId}
				sendDetailId={item.sendDetailId}
				onClose={hideDialog}
			/>
		));
	};

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={[rangeFilter, templateTypeFilter, sendStatusFilter]}
				placeholder='제목으로 검색'
				searchValue={searchValue}
				onChangeSearchValue={handleSearchKeyword}
			/>
			<S.TableContainer>
				<EmployeeMessageTable
					isLoading={isLoading}
					items={messageHistoryData}
					onClickRow={handleClickRow}
				/>
			</S.TableContainer>
		</S.Container>
	);
}
