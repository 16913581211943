import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import * as S from './styles';
import './style.css';

interface Props {
	children: React.ReactNode;
	content: React.ReactNode;
	side?: 'top' | 'right' | 'bottom' | 'left';
	fitContent?: boolean;
}

export default function RDTooltip({
	children,
	content,
	side = 'bottom',
	fitContent = false,
}: Props) {
	return (
		<HoverCard.Root openDelay={200} closeDelay={0}>
			<HoverCard.Trigger
				style={{
					display: 'inline-flex',
					alignItems: 'center',
					...(fitContent
						? {
								width: '100%',
								height: '100%',
						  }
						: {}),
				}}>
				{children}
			</HoverCard.Trigger>
			<HoverCard.Portal>
				<S.HoverCardContent
					side={side}
					sideOffset={5}
					style={{
						pointerEvents: 'all',
					}}>
					{content}
					<S.HoverCardArrow />
				</S.HoverCardContent>
			</HoverCard.Portal>
		</HoverCard.Root>
	);
}
