import React, { useState } from 'react';

import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import ApprovalLineSelectorDialog from 'components/domain/dialog/ApprovalLineSelectorDialog';
import { Employee } from 'components/domain/dialog/ApprovalLineSelectorDialog/mock';
import useFullScreen from 'lib/hook/util/useFullScreen';
import RouterPath from 'common/router';
import { useLocation } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import * as S from './styles';
import ApprovalForm from '../../../../components/domain/form/ApprovalForm';
import ApprovalLine from '../ApprovalLine';
import Referrer from '../Referrer';

export default function NewDocumentPage() {
	const { showDialog } = useDialog();
	const location = useLocation();

	const [approvalLine, setApprovalLine] = useState<Employee[]>([]);
	const [referrer, setReferrer] = useState<Employee[]>([]);

	const handleApplyApproval = (items: Employee[]) => setApprovalLine(items);

	const handleApplyReferrer = (items: Employee[]) => setReferrer(items);
	const handleDeleteReferrer = (ids: string[]) => {
		const newReferrer = referrer?.filter((item) => !ids.includes(item.id));
		setReferrer(newReferrer);
	};
	const handleDeleteApprovalItem = (id: string) => {
		const newApprovalLine = approvalLine?.filter((item) => id !== item.id);
		setApprovalLine(newApprovalLine);
	};

	const handleToggleApprovalDialog = () => {
		showDialog(() => (
			<ApprovalLineSelectorDialog
				title='결재선 설정'
				items={approvalLine}
				onApply={handleApplyApproval}
			/>
		));
	};

	const handleToggleReferrerDialog = () => {
		showDialog(() => (
			<ApprovalLineSelectorDialog
				title='참조 설정'
				items={referrer}
				onApply={handleApplyReferrer}
			/>
		));
	};

	const handleApprovalDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		const items = [...approvalLine];
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setApprovalLine(items);
	};

	useFullScreen();

	return (
		<S.Container>
			<S.Header>
				<S.Title>신규 문서 작성</S.Title>
				<CRButton.Default buttonType='submit' form='approval-form'>
					상신하기
				</CRButton.Default>
			</S.Header>
			<S.Content>
				<S.LeftSection>
					<ApprovalLine
						onDragEnd={handleApprovalDragEnd}
						items={approvalLine}
						onToggleDialog={handleToggleApprovalDialog}
						onDelete={handleDeleteApprovalItem}
					/>
					<S.Divider />
					<Referrer
						items={referrer}
						onToggleDialog={handleToggleReferrerDialog}
						onDelete={handleDeleteReferrer}
					/>
				</S.LeftSection>
				<S.RightSection>
					<ApprovalForm />
				</S.RightSection>
			</S.Content>
		</S.Container>
	);
}
