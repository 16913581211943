import React from 'react';

import CRTable from 'components/base/CRTable';
import BillingTable from 'components/domain/table/BillingTable';
import BillingSideModal from 'components/domain/sideModal/BillingSideModal';
import useSideModal from 'lib/hook/util/useSideModal';
import { Billing } from 'types/view/billing';

import { BILLING_LIST_MOCK } from './mock';

function BillingTab(): React.ReactElement {
	const { showSideModal } = useSideModal();

	const handleClickDetailItem = (item: Billing) => {
		showSideModal(<BillingSideModal item={item} />);
	};

	return (
		<CRTable.BackBlind>
			<BillingTable
				currentFilter={{}}
				items={BILLING_LIST_MOCK}
				onClickBilling={handleClickDetailItem}
			/>
		</CRTable.BackBlind>
	);
}

export default BillingTab;
