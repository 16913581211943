import styled from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 48rem;
	height: calc(100vh - 5.6rem);
	overflow: hidden;
	box-sizing: border-box;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background-color: ${(props) => props.theme.colors.gray100};
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 4rem 2.4rem 1.6rem 2.4rem;
	align-items: center;
`;

export const FormAreaContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem 2.4rem 7.8rem 2.4rem;
	overflow: overlay;
	box-sizing: border-box;
`;

export const FormInputContainer = styled.div``;

export const FormLabel = styled.label`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	margin-bottom: 0.4rem;
`;

export const FormButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 0.8rem;
	padding: 2rem 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
`;

export const ConsultingAddButtonContainer = styled.div`
	display: flex;
	box-sizing: border-box;
`;

export const ConsultingAddButton = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 3.2rem;
	padding: 0rem 1.6rem;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.primary60};
	border-radius: ${(props) => props.theme.radius.radius01};
	outline: none;
	border: none;
	cursor: pointer;
`;

export const ConsultingAddButtonIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0.8rem;
	filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(111deg) brightness(106%) contrast(101%);
`;

export const ConsultingAddButtonLabel = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray100};
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin-bottom: 1.6rem;
`;

export const ControllerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1.8rem;
	padding: 0rem 2.4rem;
	padding-top: 2.4rem;
	box-sizing: border-box;
`;

export const SecondControllerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const ChipController = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const SearchContainer = styled.div`
	flex: 1;
	display: flex;
	margin-bottom: 1.6rem;
`;

export const CRHistoryTreeContainer = styled.div`
	height: calc(100vh - 28.4rem);
	overflow-x: hidden;
	overflow-y: scroll;
`;

export const CRHistoryTreeScrollContainer = styled.div`
	width: 100%;
	overflow: visible;
	padding: 0rem 2.4rem;
	padding-bottom: 3.6rem;
	box-sizing: border-box;
`;
