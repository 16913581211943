import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import Typography from './typography';
import Colors from './colors';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    font-family: Pretendard;
    color: ${(props) => props.theme.colors.gray10}
  }
  h1 {
    ${Typography.h1}
  }
  h2 {
    ${Typography.h2}
  }
  h3 {
    ${Typography.h3}
  }
  h4 {
    ${Typography.h4}
  }
  body {
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: ${Colors.gray99};
    /* user-select: none; */
  }
  div {
    ${Typography.body}
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox  */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  div[data-radix-popper-content-wrapper] {
		z-index: 999 !important;
	}

  *::-webkit-scrollbar {
    position: absolute;
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
    background-color: transparent; /* or add it to the track */
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  *::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background-color: #E1E3E3; /* 스크롤바에 호버 시 색상 변경 */
  }

  *::-webkit-scrollbar-thumb:vertical {
    background-color: #E1E3E3;
    border-radius: 10px;
    background-clip: content-box;
  }

  *::-webkit-scrollbar-thumb:horizontal {
    background-color: #E1E3E3;
    border-radius: 10px;
    background-clip: content-box;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export default GlobalStyle;
