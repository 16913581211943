import styled, { css } from 'styled-components';

export const TableBodyRow = styled.tr<{ $isFocused: boolean }>`
	cursor: pointer;
	height: 3.3rem;
	background: ${(props) =>
		props.$isFocused ? props.theme.colors.primary90 : props.theme.colors.gray100};
	&:hover {
		${(props) =>
			!props.$isFocused &&
			css`
				background: ${(props) => props.theme.colors.gray99};
			`}
	}
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray00};
	white-space: nowrap;
	box-sizing: border-box;
	vertical-align: middle;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:first-of-type {
		border-left: none;
	}
`;

export const TableBodyColumnWithPadding = styled(TableBodyColumn)`
	height: 3.3rem;
	padding: 0rem 0.8rem;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const HighlightText = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;
