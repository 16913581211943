import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
	overflow: overlay;
`;
