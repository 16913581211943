import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '15.3rem',
		label: '일시',
		sortKey: '일시',
	},
	{
		width: '100%',
		label: '내용',
		sortKey: '내용',
	},
];

export const SEND_HISTORY_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
	{
		key: '재발송',
		label: '재발송',
		color: 'blue',
	},
	{
		key: '발송중',
		label: '발송중',
		color: 'yellow',
	},
];
