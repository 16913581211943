import React, { PropsWithChildren } from 'react';
import * as S from './styles';

interface IProps {
	isActive?: boolean;
	disabled?: boolean;
	icon: string;
	onClick?: () => void;
}

function CRIconToggleButton({
	icon,
	children,
	isActive = false,
	disabled = false,
	onClick,
}: PropsWithChildren<IProps>) {
	return (
		<S.Container $isActive={disabled ? false : isActive} disabled={disabled} onClick={onClick}>
			{icon && <S.Icon src={icon} />}
			{children}
		</S.Container>
	);
}

export default CRIconToggleButton;
