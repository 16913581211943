import React, { useState } from 'react';

import Assets from 'assets';
import CRTableHeader from 'components/base/CRTableHeader';
import CRButton from 'components/base/CRButton';
import { useMyAccountInfo } from 'lib/hook/react-query';
import CRSpinner from 'components/base/CRSpinner';
import dayjs from 'dayjs';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import RecipientWorkExecuteLogTable from 'components/domain/table/RecipientWorkExecuteLogTable';
import { useWorkExecuteLogList } from 'lib/hook/react-query/query/workExecuteLog';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import { Toast } from 'components/base/CRToast';
import * as S from './styles';

const YEAR_RANGE = Array.from(
	{ length: dayjs().year() - 2018 },
	(_, index) => dayjs().year() - index,
);

export default function RecipientWorkExecuteLogTab() {
	const { data: myAccountInfo } = useMyAccountInfo();
	const [currentYear, setCurrentYear] = useState<number>(YEAR_RANGE[0]);
	const navigate = useNavigate();

	const { currentRecipient } = useRecipientPage();

	const {
		data: workExecuteLogData,
		refetch,
		isLoading,
	} = useWorkExecuteLogList({
		centerId: myAccountInfo?.centerId,
		recipientId: currentRecipient?.recipientId,
		year: String(currentYear),
	});

	const handleRefresh = async () => refetch();

	const handleClickAddEducation = () => {
		if (currentRecipient?.mainGuardianNm) {
			navigate(
				`/${RouterPath.recipient().tab.uniqueKey}/${currentRecipient?.recipientId}/${
					RouterPath.recipient().workExecuteLogTab.uniqueKey
				}/new/${RouterPath.recipient().workExecuteLog.key}#1`,
			);
		} else {
			Toast.error('보호자 없이는 일지를 추가할 수 없습니다.');
		}
	};

	const onClickYearFilter = (year: number) => {
		setCurrentYear(year);
	};

	const renderYearFilter = () => (
		<S.ButtonContainer>
			{YEAR_RANGE?.map((year) => (
				<CRButton.Default
					key={year}
					size='small'
					palette='gray'
					type={year === currentYear ? 'filled' : 'outlined'}
					onClick={() => onClickYearFilter(year)}>
					{`${year}년`}
				</CRButton.Default>
			))}
		</S.ButtonContainer>
	);
	return (
		<S.Container>
			<CRTableHeader
				currentFilter={{}}
				filters={[]}
				hideSearchButton
				renderCustomFilter={renderYearFilter()}
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddEducation}>
						일지 추가
					</CRButton.IconButton>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<RecipientWorkExecuteLogTable
						items={workExecuteLogData || []}
						onRefresh={handleRefresh}
					/>
				</S.TableContainer>
			)}
		</S.Container>
	);
}
