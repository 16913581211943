import { styled } from 'styled-components';

export const FooterRightSideContainer = styled.div`
	flex: 1;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 160rem;
	height: 90vh;
	overflow: hidden;
`;

export const Header = styled.div`
	padding: 2.4rem 2.4rem 1.6rem;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 7.6rem;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const Content = styled.div`
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	margin: 0.1rem;
	flex: 1;
	overflow: auto;

	& > * {
		user-select: auto;
	}
`;
