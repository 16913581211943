import styled from 'styled-components';

export const FormContainer = styled.form`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	width: 100%;
	height: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
	gap: 1.6rem;
	overflow: hidden;
`;

export const FormAreaContainer = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const ConsultingTagContainer = styled.div`
	flex: 1;
	max-height: 32rem;
`;
