import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import useCRMutation from '../base/useCRMutation';

export const useContracts = useCRQuery(endpoint.getContracts.key, 'getContracts');

export const useRecipientContracts = useCRQuery(
	endpoint.getRecipientContractServices.key,
	'getRecipientContractServices',
	{
		enabledKey: ['recipientId', 'centerIds'],
	},
);

export const useEmployeeContracts = useCRQuery(
	endpoint.getEmployeeContractServices.key,
	'getEmployeeContractServices',
	{
		enabledKey: ['employeeId', 'centerIds'],
	},
);

export const useContractDetail = useCRQuery(endpoint.getContractDetail.key, 'getContractDetail', {
	enabledKey: ['serviceContractId'],
});

export const useContractRenewalDetail = useCRQuery(
	endpoint.getContractRenewalDetail.key,
	'getContractRenewalDetail',
	{
		enabledKey: ['serviceContractGroupUuid'],
	},
);

export const useContractChangeEmployeeDetail = useCRQuery(
	endpoint.getContractChangeEmployeeDetail.key,
	'getContractChangeEmployeeDetail',
	{
		enabledKey: ['serviceContractId'],
	},
);

export const useContractSendStatus = useCRQuery(
	endpoint.getContractSendStatus.key,
	'getContractSendStatus',
	{
		enabledKey: ['serviceContractId'],
	},
);

export const useWageDeductionEmployees = useCRQuery(
	endpoint.getWageDeductionEmployees.key,
	'getWageDeductionEmployees',
	{
		enabledKey: ['centerId', 'recipientId'],
	},
);

export const useServiceTerminationInfo = useCRQuery(
	endpoint.getServiceTerminationInfo.key,
	'getServiceTerminationInfo',
	{
		enabledKey: ['serviceContractDetailId'],
	},
);

export const useContractDocument = useCRQuery(endpoint.getDocument.key, 'getDocument');

export const useDocumentTemplate = useCRQuery(
	endpoint.getDocumentTemplate.key,
	'getDocumentTemplate',
);

export const useEmployeeWage = useCRQuery(endpoint.getEmployeeWage.key, 'getEmployeeWage');
