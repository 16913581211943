import React from 'react';

import Assets from 'assets';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import InformationTable from 'components/ui/InformationTable';

import dayjs from 'dayjs';
import { useRecipientSendHistoryDetail } from 'lib/hook/react-query';
import { CRText } from 'components/base/CRText';
import * as S from './styles';

interface Props {
	recipientId?: number;
	sendDetailId?: number;
	onClose?: () => void;
}

function RecipientMessageTabSendDetailDialog({
	recipientId,
	sendDetailId,
	onClose,
}: Props): React.ReactElement {
	const { data } = useRecipientSendHistoryDetail({
		recipientId,
		sendDetailId,
	});
	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>발송 상세</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={onClose} />
			</S.HeaderContainer>
			<S.BodyContainer>
				<CRInputLabel label='발송 정보'>
					<InformationTable
						items={[
							[
								{
									label: '템플릿명',
									value: data?.sendTemplateTitle ?? '',
								},
							],
							[
								{
									label: '제목',
									value: data?.sendTitle ?? '',
								},
							],
							[
								{
									label: '발송자',
									value: data?.senderNm ?? '',
								},
							],
							[
								{
									label: '발송일시',
									value: data?.sendDate ? dayjs(data.sendDate).format('YYYY.MM.DD HH:mm') : '',
								},
							],
							[
								{
									label: '발송상태',
									value: (
										<S.SendStatusContainer>
											<CRText typography='label' color='gray00' text={data?.sendStateNm || ''} />
											{data?.sendResultDesc ? (
												<>
													<br />
													<CRText
														typography='label'
														color='gray60'
														text={`사유 : ${data.sendResultDesc}`}
													/>
												</>
											) : (
												''
											)}
										</S.SendStatusContainer>
									),
								},
							],
						]}
					/>
				</CRInputLabel>
				<CRInputLabel label='발송 내용'>
					<CRInput.TextArea
						fixedHeight
						disabled
						numberOfLines={10}
						value={data?.messageDesc.replace(/<br\/>|\\n/g, '\n')}
					/>
				</CRInputLabel>
			</S.BodyContainer>
		</S.Container>
	);
}

export default React.memo(RecipientMessageTabSendDetailDialog);
