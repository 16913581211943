import React from 'react';
import * as S from './styles';

interface Props {
	type?: string;
	padding?: string;
}

function CRTaskBadge({ type, padding = '0 0.4rem' }: Props) {
	if (type === '요양') {
		return (
			<S.NormalBadge $bg='primary90' $textColor='primary20' $padding={padding}>
				{type}
			</S.NormalBadge>
		);
	}
	if (type === '목욕') {
		return (
			<S.NormalBadge $bg='greenLighten' $textColor='greenDarken' $padding={padding}>
				{type}
			</S.NormalBadge>
		);
	}
	if (type === '간호') {
		return (
			<S.NormalBadge $bg='blueLighten' $textColor='blueDarken' $padding={padding}>
				{type}
			</S.NormalBadge>
		);
	}
	return <S.EmptyBadge />;
}

export default CRTaskBadge;
