import React from 'react';

import * as S from './styles';

function IntegratedAssessmentDesireRecipientCaregiverNeedsFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn style={{ width: '14rem' }}>구분</S.TableHeaderColumn>
			<S.TableHeaderColumn>확인</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesireRecipientCaregiverNeedsFormHeader);
