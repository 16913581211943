import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem 2.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const SubTitle = styled.p`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
	margin-bottom: -0.8rem;
`;
