import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';

import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import { QueryObserverResult } from '@tanstack/react-query';
import { GetWorkExecuteLogDTO } from 'types/api/workExecuteLog';
import { displayPadTime } from 'lib';
import CRStatus from 'components/base/CRStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import useDialog from 'lib/hook/util/useDialog';
import { useEFormFileName, useMyAccountInfo } from 'lib/hook/react-query';
import { VISIT_STATE_STATUS_CONFIG } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogVisitForm/constant';
import { WRITE_COMPLETE_YN_CONFIG } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm/constants';
import createWorkExecuteLogPreviewEdocParam from './createWorkExecuteLogPreviewEdocParam';

import * as S from './styles';
import { STAFF_EDUCATION_LIST_TABLE_HEADER_CONFIG } from './constant';

interface Props {
	items?: GetWorkExecuteLogDTO[] | null;
	offset?: number;
	onRefresh: () => Promise<QueryObserverResult<GetWorkExecuteLogDTO[] | null, unknown>>;
}

function RecipientWorkExecuteLogTable({
	items = [],
	onRefresh,
	offset = 0,
}: Props): React.ReactElement {
	const { mutateAsync: getEFormFileName } = useEFormFileName();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const navigate = useNavigate();
	const location = useLocation();
	const handleClickEditEducation = (item?: GetWorkExecuteLogDTO) => {
		if (!item) return;
		navigate(
			`${location.pathname}/${RouterPath.recipient().workExecuteLogDetail.uniqueKey}/${
				item.workExecuteLogId
			}/${RouterPath.recipient().workExecuteLog.key}`,
		);
	};

	const handleClickPreview = async (item?: GetWorkExecuteLogDTO) => {
		const fileNameRes = await getEFormFileName({
			paperTypeCd: 'CMN119.0073',
			targetDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
		});
		if (item && myAccountInfo && fileNameRes?.data?.eformFileNm) {
			const eDocParamValue = createWorkExecuteLogPreviewEdocParam(
				myAccountInfo,
				item,
				fileNameRes?.data?.eformFileNm,
			);

			showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={eDocParamValue} />);
		}
	};

	const renderOption = (value: number, item?: GetWorkExecuteLogDTO) => (
		<S.ButtonContainer>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickPreview(item)}>
				미리보기
			</CRButton.Default>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickEditEducation(item)}>
				수정
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderVisitDate = (value: string, item?: GetWorkExecuteLogDTO) => {
		const visitDate = value ? dayjs(value).format('YYYY.MM.DD') : '';
		return <span>{`${visitDate}`}</span>;
	};

	const renderVisitSchedule = (value: string, item?: GetWorkExecuteLogDTO) => {
		const startTime = item?.serviceStartTime ? displayPadTime(item.serviceStartTime) : '';
		const endTime = item?.serviceEndTime ? ` ~${displayPadTime(item.serviceEndTime)}` : '';

		return <span>{`${startTime}${endTime}`}</span>;
	};

	const renderVisitTime = (value: string, item?: GetWorkExecuteLogDTO) => {
		if (!item?.startTime || !item?.endTime) return '';
		const startTime = item?.startTime ? displayPadTime(item.startTime) : '';
		const endTime = item?.endTime ? displayPadTime(item.endTime) : '';

		const startTimeParts = startTime.split(':');
		const endTimeParts = endTime.split(':');

		// 시간과 분을 정수형으로 파싱합니다.
		const hours1 = Number(startTimeParts[0]);

		const minutes1 = Number(startTimeParts[1]);
		const hours2 = Number(endTimeParts[0]);
		const minutes2 = Number(endTimeParts[1]);

		// 첫 번째 시간과 두 번째 시간을 분으로 변환합니다.
		const totalMinutes1 = hours1 * 60 + minutes1;
		const totalMinutes2 = hours2 * 60 + minutes2;

		// 두 시간의 차이를 계산합니다. 절대값을 사용하여 음수가 되지 않도록 합니다.
		const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

		return (
			<span>{`${startTime}${endTime ? ` ~${endTime}` : ''}${
				!Number.isNaN(differenceInMinutes) ? `(${differenceInMinutes}분)` : ''
			}`}</span>
		);
	};

	const renderWorkExecuteLogStatus = useCallback(
		(status: string) => <CRStatus options={VISIT_STATE_STATUS_CONFIG}>{status}</CRStatus>,
		[],
	);

	const renderWriteCompleteStatus = useCallback(
		(completeYn: boolean) => (
			<CRStatus options={WRITE_COMPLETE_YN_CONFIG}>
				{completeYn ? '작성 완료' : '작성 예정'}
			</CRStatus>
		),
		[],
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={STAFF_EDUCATION_LIST_TABLE_HEADER_CONFIG} offset={offset} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.workExecuteLogId}
							item={item}
							renderKeys={[
								'workExecuteLogStateCd',
								'writeCompleteYn',
								'visitDt',
								'startTime',
								'endTime',
								'visitorNm',
								'workExecuteLogId',
							]}
							customRender={{
								workExecuteLogStateCd: renderWorkExecuteLogStatus,
								writeCompleteYn: renderWriteCompleteStatus,
								visitDt: renderVisitDate,
								startTime: renderVisitSchedule,
								endTime: renderVisitTime,
								workExecuteLogId: renderOption,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecipientWorkExecuteLogTable;
