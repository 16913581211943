import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray100};
`;

export const MenuLeaderContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
`;

export const MenuTailContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 2.4rem 0 1.6rem;
`;

export const PopoverClose = styled(Popover.Close)`
	display: flex;
	align-items: center;
	padding: 0;
	background: none;
	border: none;
`;

export const MenuContainer = styled.div`
	display: flex;
	cursor: pointer;
	background: transparent;
	border: none;
	width: 5.6rem;
	height: 5.6rem;
	margin-right: 1.6rem;
	align-items: center;
	justify-content: center;
`;

export const MenuIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const Logo = styled.img`
	height: 2.4rem;
	margin-right: 1.6rem;
`;

export const LogoContainer = styled.div`
	cursor: pointer;
	padding: 0 1.4rem;
	margin-right: 1.6rem;
`;

export const ERPText = styled.div`
	${(props) => props.theme.typography.bodyB}
	white-space:nowrap;
`;

export const CenterText = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	padding: 0 1.6rem;
	white-space: nowrap;
	/* cursor: pointer; */
`;

export const MyInformationButtonContainer = styled.div`
	display: flex;
	padding: 0 1.6rem;
	box-sizing: border-box;
	cursor: pointer;
`;

export const MyInformationButtonText = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	margin-right: 0.4rem;
	position: relative;
	white-space: nowrap;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	cursor: pointer;
	background: transparent;
	border: none;
	width: 4rem;
	height: 4rem;
	border-radius: 2rem;
	align-items: center;
	justify-content: center;

	&:hover {
		background: ${(props) => props.theme.colors.primary90};

		& > img {
			filter: invert(49%) sepia(30%) saturate(6517%) hue-rotate(323deg) brightness(103%)
				contrast(101%);
		}
	}
`;

export const DropdownItem = styled.div`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: center;
	width: 16rem;
	min-height: 4.8rem;
	height: fit-content;
	user-select: none;
	padding: 0 1.6rem 0;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.gray95};
	}
`;

export const PopoverItemContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 20rem;
	padding: 0.8rem 1.6rem;
`;
