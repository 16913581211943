import React, { useEffect, useMemo, useState } from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import CRBanner from 'components/base/CRBanner';
import {
	useCheckRealName,
	useEmployeePageDuplicateCheck,
	useMyAccountInfo,
} from 'lib/hook/react-query';

import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import Assets from 'assets';
import { EmployeeCheckDuplicateDTO } from '../../../../types/api';
import * as S from './styles';

interface Props {
	data: {
		name: string;
		rsdn: string;
	};
	onChange?: (data: { name: string; rsdn: string }) => void;
	disabled?: boolean;
	onConfirm?: (data: { name: string; rsdn: string; result: EmployeeCheckDuplicateDTO }) => void;
}

function EmployeeDuplicationCheckForm({
	data,
	disabled,
	onChange,
	onConfirm,
}: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const [didCheckDuplicate, setDidCheckDuplicate] = useState(false);
	const [didCheckRealName, setDidCheckRealName] = useState(false);
	const [result, setResult] = useState<EmployeeCheckDuplicateDTO | null>(null);
	const [isRealName, setIsRealName] = useState(false);
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />,
	);

	const checkDuplicate = useEmployeePageDuplicateCheck();
	const checkRealNameMutation = useCheckRealName();

	const onClickDuplicate = async () => {
		if (!myAccountInfo) return;
		if (!data.name) return;
		if (!data.rsdn) return;
		if (data?.rsdn?.length !== 13) {
			setBanner(
				<CRBanner
					type='error'
					title='주민등록번호 13자리를 모두 입력해야 중복 조회가 가능합니다.'
				/>,
			);
			return;
		}

		const regex = /[ㄱ-ㅎ]/g;

		const hasForbiddenConsonant = regex.test(data.name);

		if (hasForbiddenConsonant) {
			setBanner(
				<CRBanner type='error' title='직원 이름을 모두 입력해야 중복 조회가 가능합니다.' />,
			);
			return;
		}

		const result = await checkDuplicate.mutateAsync({
			name: data.name,
			rsdnNo: data.rsdn,
			centerId: myAccountInfo.centerId,
		});

		if (!result?.data) {
			setBanner(<CRBanner type='error' title='중복 조회에 실패하였습니다.' />);
			return;
		}

		setDidCheckDuplicate(true);
		setResult(result.data);

		if (result?.data?.recipientDuplicated) {
			setBanner(
				<CRBanner type='error' title='동일한 주민번호로 이미 수급자에 등록되어있습니다.' />,
			);
			setDidCheckDuplicate(false);
			setDidCheckRealName(false);
			return;
		}

		if (!result?.data?.isCenterDuplicated && result?.data?.isEmployeeDuplicated) {
			let centerName = result.data.centers.length > 0 ? result.data.centers[0] : '타';
			if (result?.data.centers.length > 1) {
				centerName += ` 외 ${result.data.centers.length - 1}개 센터`;
			}
			setBanner(
				<CRBanner
					type='warning'
					title={`${centerName}에 이미 존재하는 직원입니다.(센터 추가 등록)`}
				/>,
			);
			setDidCheckRealName(false);
			return;
		}

		if (result?.data?.isCenterDuplicated && result.data.isEmployeeDuplicated) {
			setBanner(<CRBanner type='success' title='센터내에 존재하는 직원입니다.' />);
		} else {
			setBanner(<CRBanner type='success' title='새로운 직원입니다 실명 확인을 진행해 주십시오.' />);
		}

		setDidCheckRealName(false);
	};

	const handleClickCheckRealName = async () => {
		const foreignerYn = ['5', '6', '7', '8'].includes(data.rsdn?.[6]);
		const result = await checkRealNameMutation.mutateAsync({
			name: data.name,
			rsdnNo: data.rsdn,
			foreignerYn,
		});
		setDidCheckRealName(true);
		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 직원을 생성할 수 있습니다.' />,
			);
			setIsRealName(true);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
			setIsRealName(true);
		}
	};

	const handleConfirm = () => {
		if (!result) return;
		onConfirm?.({ ...data, result });
	};

	const isNewUser = useMemo(
		() =>
			result?.isCenterDuplicated === false &&
			result?.isEmployeeDuplicated === false &&
			result?.recipientDuplicated === false,
		[result],
	);

	const isValid =
		result?.isCenterDuplicated ||
		result?.isEmployeeDuplicated ||
		(!result?.recipientDuplicated && isRealName);

	const shouldRenderConfirm = useMemo(() => {
		const didRealNameCheck = (isNewUser && isRealName) || !isNewUser;
		return didCheckDuplicate && didRealNameCheck && !disabled;
	}, [isNewUser, isRealName, didCheckDuplicate, disabled]);

	useEffect(() => {
		setDidCheckDuplicate(false);
		setIsRealName(false);
		setResult(null);
		setBanner(<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />);
		setDidCheckRealName(false);
	}, [data]);

	return (
		<>
			<CRInputLabel type='left-sub' label='이름'>
				<CRInput.Default
					placeholder='이름 입력'
					value={data.name}
					onChange={(name) => onChange?.({ ...data, name })}
					disabled={disabled}
				/>
			</CRInputLabel>
			<CRInputLabel type='left-sub' label='주민번호'>
				<CRInput.Default
					placeholder='주민번호 입력'
					onChange={(rsdn) => onChange?.({ ...data, rsdn })}
					value={data.rsdn}
					disabled={disabled}
					type='number'
					maxLength={13}
					suffix={
						<S.ValidateButtonContainer>
							<CRButton.Default
								onClick={onClickDuplicate}
								size='xSmall'
								palette='gray'
								type='outlined'
								buttonType='button'
								disabled={disabled || !(data.name && data.name)}>
								중복 조회
							</CRButton.Default>
							<CRButton.Default
								size='xSmall'
								disabled={!didCheckDuplicate || !isNewUser || didCheckRealName}
								palette='gray'
								type='filled'
								onClick={handleClickCheckRealName}
								buttonType='button'>
								실명 확인
							</CRButton.Default>
						</S.ValidateButtonContainer>
					}
				/>
			</CRInputLabel>
			{!disabled && banner}
			{shouldRenderConfirm && (
				<S.ButtonContainer>
					<CRButton.Default
						onClick={handleConfirm}
						type='filled'
						buttonType='button'
						disabled={disabled && !isValid}>
						확정
					</CRButton.Default>
				</S.ButtonContainer>
			)}
		</>
	);
}

export default EmployeeDuplicationCheckForm;
