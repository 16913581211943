import React, { useMemo } from 'react';

import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import RecipientManagerHistoryDialog from 'components/domain/dialog/RecipientManagerHistoryDialog';
import RecipientAddHistoryOfManagerDialog from 'components/domain/dialog/RecipientAddHistoryOfManagerDialog';
import { CheckOption } from 'components/base/Selections/type';
import { RecipientManagerHistoryType } from 'types/view/recipient';
import useDialog from 'lib/hook/util/useDialog';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	useCommonCodes,
	useMyAccountInfo,
	useUpdateRecipientInUse,
	useUpdateRecipientInWaiting,
} from 'lib/hook/react-query';
import { GetRecipientsData } from 'types/api/common';

import { Toast } from 'components/base/CRToast';
import * as S from './styles';

function RecipientBasicStatusFormView(): React.ReactElement {
	const dialog = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { currentRecipient, currentRecipientStatus } = useRecipientPage();
	const updateRecipientInUse = useUpdateRecipientInUse((client, res, variables) => {
		if (res?.recipientId) {
			client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: variables.recipientId },
			]);
			client.setQueryData<GetRecipientsData | undefined>(
				[endpoint.getRecipients.key, { centerIds: myAccountInfo?.centerId }],
				(data) => {
					if (!data) return data;
					const newData: GetRecipientsData = {
						count: data.count,
						recipients: [...data.recipients],
					};
					const recipients = newData?.recipients ?? [];
					const index = recipients?.findIndex(
						(item) => Number(item.recipientId) === variables.recipientId,
					);
					if (index === -1) return { ...data };
					recipients[index] = {
						...recipients[index],
						stateNm: '수급중',
					};

					return { count: data.count, recipients: [...recipients] };
				},
			);
			Toast.success(`${currentRecipient?.name}의 상태를 수정했습니다.`);
		}
	});
	const updateRecipientWaiting = useUpdateRecipientInWaiting((client, res, variables) => {
		if (res?.recipientId) {
			client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: variables.recipientId },
			]);
			client.setQueryData<GetRecipientsData>(
				[endpoint.getRecipients.key, { centerIds: myAccountInfo?.centerId }],
				(data) => {
					if (!data) return data;
					const newData: GetRecipientsData = {
						count: data.count,
						recipients: [...data.recipients],
					};
					const recipients = newData?.recipients ?? [];
					const index = recipients?.findIndex(
						(item) => Number(item.recipientId) === variables.recipientId,
					);
					if (index === -1) return { ...data };
					recipients[index] = {
						...recipients[index],
						stateNm: '대기중',
					};

					return { count: data.count, recipients: [...recipients] };
				},
			);
			Toast.success(`${currentRecipient?.name}의 상태를 수정했습니다.`);
		} else {
			Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});
	const { data: recipientStatusCode } = useCommonCodes({ comCdGroupNms: ['CMN058'] });

	const statusFilter = (recipientStatusCode ?? [])
		?.filter((item) => item.etcDesc1 === 'Y')
		.map(
			(item) =>
				({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				} as CheckOption),
		);

	const currentStatus = useMemo(
		() => statusFilter.find((item) => item.value === currentRecipientStatus.status),
		[currentRecipientStatus.status, statusFilter],
	);

	const handleChangeSelector = async (item: CheckOption) => {
		switch (item.value) {
			case 'CMN058.10':
				await updateRecipientInUse.mutateAsync({
					recipientId: Number(currentRecipient?.recipientId),
				});
				return;
			case 'CMN058.20':
				await updateRecipientWaiting.mutateAsync({
					recipientId: Number(currentRecipient?.recipientId),
				});
				return;
			default:
				return;
		}
	};

	const handleClickAddHistoryOfEmployee = () => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientAddHistoryOfManagerDialog
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
			/>
		));
	};

	const handleClickEditHistoryOfEmployee = (item: RecipientManagerHistoryType) => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientAddHistoryOfManagerDialog
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
				editItem={item}
			/>
		));
	};

	const handleClickHistoryOfEmployee = () => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientManagerHistoryDialog
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
				onClickAdd={handleClickAddHistoryOfEmployee}
				onClickEdit={handleClickEditHistoryOfEmployee}
			/>
		));
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';

	return (
		<S.Table $isDisabled={isResign}>
			<S.TableLabelColumn style={{ width: '9.6rem' }}>상태</S.TableLabelColumn>
			<S.TableValueColumn style={{ width: '44rem' }}>
				<CRInput.Selector
					disabled={isResign}
					items={
						isResign
							? [
									{
										label: '퇴소',
										value: '퇴소',
									},
							  ]
							: statusFilter
					}
					type='small'
					placeholder='상태 선택'
					currentValue={
						isResign
							? {
									label: '퇴소',
									value: '퇴소',
							  }
							: currentStatus
					}
					onChangeValue={handleChangeSelector}
				/>
			</S.TableValueColumn>
			<S.TableLabelColumn style={{ width: '9.6rem' }}>사회복지사</S.TableLabelColumn>
			<S.TableValueColumn style={{ width: '44rem' }}>
				{currentRecipientStatus.manager}
				<S.RightButtonContainer>
					<CRButton.Default
						disabled={isResign}
						size='xSmall'
						palette='gray'
						type='outlined'
						onClick={handleClickHistoryOfEmployee}>
						변경/이력
					</CRButton.Default>
				</S.RightButtonContainer>
			</S.TableValueColumn>
		</S.Table>
	);
}

export default RecipientBasicStatusFormView;
