import React from 'react';

import ConsultingFormHelper from 'components/domain/helper/ConsultingForm';

import * as S from './styles';

function Helper(): React.ReactElement {
	return (
		<S.Container>
			<ConsultingFormHelper />
		</S.Container>
	);
}

export default Helper;
