import React, { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import CRInput from 'components/base/CRInput';

import Assets from 'assets';
import useDialog from 'lib/hook/util/useDialog';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { useCenterManagers, useMyAccountInfo, useSendTargetList } from 'lib/hook/react-query';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import { CheckOption } from 'components/base/Selections/type';
import { unique } from 'lib';
import { Filter } from 'types/view/filter';
import { SendTarget } from 'types/view/send';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { SendTargetListRequestDTO } from 'types/api/send';
import { useVirtualizer } from '@tanstack/react-virtual';
import CRChips from 'components/base/CRChips';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import * as S from './styles';

interface Props {
	sendTargets?: SendTarget[];
	targetInfo: SendTargetListRequestDTO;
	onApply?: (targets: SendTarget[]) => void;
}

interface OptionObj {
	label: string;
	value: string;
}

interface TargetFilter {
	recipient?: OptionObj[];
	employee?: OptionObj[];
}

enum TemplateId {
	임금명세서 = '1',
	장기요양급여비용명세서 = '2',
	일정표 = '3',
	기타 = '4',
}

export default function SendTargetDialog({ sendTargets = [], targetInfo, onApply }: Props) {
	const { hideDialog } = useDialog();
	const [searchText, setSearchText] = useState('');
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});
	const [selectedTargets, setSelectedTargets] = useState<SendTarget[]>(sendTargets);
	const [isAllRecipient, setIsAllRecipient] = useState<boolean>(false);
	const [isAllEmployee, setIsAllEmployee] = useState<boolean>(false);

	const [currentFilter, setCurrentFilter] = useState<{
		managerFilter: CheckOption[];
	}>({
		managerFilter: [],
	});

	const { data: sendTargetList } = useSendTargetList(targetInfo);

	const isRecipientSelectorAvailable = (() => {
		const { templateId } = targetInfo;
		if (!templateId) return true;
		return ![TemplateId.임금명세서].includes(templateId as TemplateId);
	})();

	const isEmployeeSelectorAvailable = (() => {
		const { templateId } = targetInfo;
		if (!templateId) return true;
		return ![TemplateId.장기요양급여비용명세서].includes(templateId as TemplateId);
	})();

	const [filter, setFilter] = useState<TargetFilter>({
		...(isRecipientSelectorAvailable
			? { recipient: [{ label: '수급자', value: '수급자' }] }
			: { employee: [{ label: '직원', value: '직원' }] }),
	});

	const selectedTargetType = Object.keys(filter)[0] as keyof TargetFilter;

	const recipientList = useMemo(
		() =>
			(sendTargetList?.sendTargetRecipients || [])
				.filter(
					(item) =>
						!currentFilter.managerFilter?.length ||
						currentFilter.managerFilter?.some((filter) => filter.value === item.managerId),
				)
				.map(
					(item) =>
						({
							id: item.recipientId,
							name: item?.recipientNm ?? '',
							type: 'recipient',
							birthDate: item?.birthDt ?? '',
							description: item?.mainGuardianNm ?? '',
						} as SendTarget),
				)
				.filter(
					(item) =>
						item.name.toLowerCase().includes(searchText.toLowerCase()) ||
						item.description?.includes(searchText),
				),
		[sendTargetList, currentFilter, searchText],
	);

	const employeeList = useMemo(
		() =>
			(sendTargetList?.sendTargetEmployee || [])

				.filter(
					(item) =>
						!currentFilter.managerFilter?.length ||
						currentFilter.managerFilter?.some((filter) => filter.value === item.managerId),
				)
				.map(
					(item) =>
						({
							id: item.employeeId,
							name: item.employeeNm ?? '',
							type: 'employee',
							birthDate: item.birthDt ?? '',
							description: item?.mobilePhoneNo
								? item.mobilePhoneNo.substring(item.mobilePhoneNo.length - 4)
								: '',
						} as SendTarget),
				)
				.filter(
					(item) =>
						item.name.toLowerCase().includes(searchText.toLowerCase()) ||
						item.description?.includes(searchText),
				),
		[sendTargetList, currentFilter, searchText],
	);

	useEffect(() => {
		const selectedRecipients = selectedTargets.filter((t) => t.type === 'recipient');
		if (recipientList.length === selectedRecipients.length && selectedRecipients.length > 0) {
			setIsAllRecipient(true);
		} else {
			setIsAllRecipient(false);
		}

		const selectedEmployeeList = selectedTargets.filter((t) => t.type === 'employee');
		if (employeeList.length === selectedEmployeeList.length && selectedEmployeeList.length > 0) {
			setIsAllEmployee(true);
		} else {
			setIsAllEmployee(false);
		}
	}, [employeeList, recipientList, selectedTargets]);

	const recipientScrollContainerRef = useRef<HTMLDivElement>(null);
	const recipientListVirtualizer = useVirtualizer({
		count: recipientList?.length || 0,
		getScrollElement: () => recipientScrollContainerRef.current,
		estimateSize: () => 52,
		overscan: 50,
	});

	const employeeScrollContainerRef = useRef<HTMLDivElement>(null);
	const employeeListVirtualizer = useVirtualizer({
		count: employeeList?.length || 0,
		getScrollElement: () => employeeScrollContainerRef.current,
		estimateSize: () => 52,
		overscan: 50,
	});

	const selectedTargetScrollContainerRef = useRef<HTMLUListElement>(null);
	const selectedTargetListVirtualizer = useVirtualizer({
		count: selectedTargets?.length || 0,
		getScrollElement: () => selectedTargetScrollContainerRef.current,
		estimateSize: () => 48,
		overscan: 50,
	});

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options: unique(centerManagers ?? [], (item) => item.memberAccountId)?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})),
				placeholder: '사회복지사',
			} as Filter<CheckOption>),
		[centerManagers],
	);

	const renderSearchUserInfo = (target: SendTarget) => (
		<S.TargetContainer>
			<S.TargetName>{target?.name}</S.TargetName>
			<S.TargetDescription>{`${target?.birthDate}${
				target.description ? ` ・ ${target.description}` : ''
			}`}</S.TargetDescription>
		</S.TargetContainer>
	);

	const handleClickTarget = (target: SendTarget) => () => {
		let newTargets = [...(selectedTargets || [])];
		const matchedItem = selectedTargets?.find(
			(item) => item.id === target.id && item.type === target.type,
		);

		if (matchedItem) {
			newTargets =
				selectedTargets?.filter((item) => !(item.id === target.id && item.type === target.type)) ??
				[];
		} else {
			newTargets?.push(target);
		}

		setSelectedTargets(newTargets);
	};

	const handleDeleteTarget = (target: SendTarget) => () => {
		const newSendTargets =
			selectedTargets?.filter((item) => !(item.id === target.id && item.type === target.type)) ??
			[];
		setSelectedTargets(newSendTargets);
	};

	const handleToggleAll =
		(type: 'recipient' | 'employee') => (e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				const newTargets = [...(selectedTargets || [])].concat(
					type === 'recipient' ? recipientList : employeeList,
				);
				const uniqueTargetList: SendTarget[] = Array.from(
					new Set(newTargets.map((item) => item.id)),
				).map((id) => newTargets.find((item) => item.id === id) as SendTarget);
				setSelectedTargets(uniqueTargetList);
			} else {
				const newTargets = [...(selectedTargets || [])];
				if (type === 'recipient') {
					const removedTargets = newTargets?.filter(
						(item) => !recipientList.find((target) => target.id === item.id),
					);
					setSelectedTargets(removedTargets);
				}

				if (type === 'employee') {
					const removedTargets = newTargets?.filter(
						(item) => !employeeList.find((target) => target.id === item.id),
					);
					setSelectedTargets(removedTargets);
				}
			}
		};

	const handleApplySendTarget = () => {
		onApply?.(selectedTargets);
	};

	const handleClickReset = () => {
		setSelectedTargets([]);
	};

	const handleChangeChips = (item: OptionObj, key: string) => {
		const currentSelectedKey = Object.keys(filter)[0];
		if (key === currentSelectedKey) return;

		setFilter({
			[key]: filter[key as keyof typeof filter] ? undefined : [item],
		});
	};

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<CRDialog
			type='M'
			title='수신자'
			body={
				<S.SearchContainer>
					<S.InputSection>
						<S.SearchBarContainer>
							<CRInput.Search
								placeholder='이름 검색'
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
							/>
							<CRFilterGroup
								filters={[managerFilter]}
								currentFilter={currentFilter}
								setCurrentFilter={setCurrentFilter}
							/>
						</S.SearchBarContainer>
						<S.CategorySection>
							<S.Group>
								<FlexContainer gap='0.8rem' margin='0 0 0.8rem 0'>
									{isRecipientSelectorAvailable && (
										<CRChips.Default
											filterKey='recipient'
											currentValue={filter?.recipient}
											options={[{ label: '수급자', value: '수급자' }]}
											onChangeValue={handleChangeChips}
										/>
									)}
									{isEmployeeSelectorAvailable && (
										<CRChips.Default
											filterKey='employee'
											currentValue={filter?.employee}
											options={[{ label: '직원', value: '직원' }]}
											onChangeValue={handleChangeChips}
										/>
									)}
								</FlexContainer>
								{selectedTargetType === 'recipient' && (
									<>
										<S.GroupTitle>
											수급자
											<S.ButtonContainer>
												<CRCheckBox
													checked={isAllRecipient}
													onChange={handleToggleAll('recipient')}>
													전체
												</CRCheckBox>
											</S.ButtonContainer>
										</S.GroupTitle>
										<S.ScrollContainer ref={recipientScrollContainerRef}>
											<div
												style={{
													height: `${recipientListVirtualizer.getTotalSize()}px`,
													width: '100%',
													position: 'relative',
													overflowAnchor: 'none',
												}}>
												{recipientListVirtualizer?.getVirtualItems()?.map((virtualItem) => {
													const item = recipientList?.[virtualItem.index];
													const isSelected = !!selectedTargets?.find(
														(target) => target.id === item.id && target.type === item.type,
													);
													return (
														<S.UserListItem
															key={item.id}
															onClick={handleClickTarget(item)}
															style={{
																top: 0,
																left: 0,
																height: `${virtualItem.size}px`,
																position: 'absolute',
																transform: `translateY(${virtualItem.start}px)`,
															}}>
															{renderSearchUserInfo(item)}
															{isSelected && <S.Icon src={Assets.icon.check} alt='check' />}
														</S.UserListItem>
													);
												})}
											</div>
										</S.ScrollContainer>
									</>
								)}
								{selectedTargetType === 'employee' && (
									<>
										<S.GroupTitle>
											직원
											<S.ButtonContainer>
												<CRCheckBox checked={isAllEmployee} onChange={handleToggleAll('employee')}>
													전체
												</CRCheckBox>
											</S.ButtonContainer>
										</S.GroupTitle>
										<S.ScrollContainer ref={employeeScrollContainerRef}>
											<div
												style={{
													height: `${employeeListVirtualizer.getTotalSize()}px`,
													width: '100%',
													position: 'relative',
													overflowAnchor: 'none',
												}}>
												{employeeListVirtualizer?.getVirtualItems()?.map((virtualItem) => {
													const item = employeeList?.[virtualItem.index];
													if (!item) return null;
													const isSelected = !!selectedTargets?.find(
														(target) => target.id === item.id && target.type === item.type,
													);
													return (
														<S.UserListItem
															key={item.id}
															onClick={handleClickTarget(item)}
															style={{
																top: 0,
																left: 0,
																height: `${virtualItem.size}px`,
																position: 'absolute',
																transform: `translateY(${virtualItem.start}px)`,
															}}>
															{renderSearchUserInfo(item)}
															{isSelected && <S.Icon src={Assets.icon.check} alt='check' />}
														</S.UserListItem>
													);
												})}
											</div>
										</S.ScrollContainer>
									</>
								)}
							</S.Group>
						</S.CategorySection>
					</S.InputSection>
					<S.SelectSection>
						<S.TitleContainer>
							<S.GroupTitle>선택({selectedTargets?.length ?? 0})</S.GroupTitle>
							<CRButton.Default type='text' palette='gray' size='xSmall' onClick={handleClickReset}>
								초기화
							</CRButton.Default>
						</S.TitleContainer>
						<S.SelectItemContainer ref={selectedTargetScrollContainerRef}>
							<div
								style={{
									height: `${selectedTargetListVirtualizer.getTotalSize()}px`,
									width: '100%',
									position: 'relative',
									overflowAnchor: 'none',
								}}>
								{selectedTargetListVirtualizer?.getVirtualItems()?.map((virtualItem) => {
									const item = selectedTargets?.[virtualItem.index];
									if (!item) return null;
									return (
										<S.UserListItem
											key={item.id}
											onClick={handleDeleteTarget(item)}
											style={{
												top: 0,
												left: 0,
												height: `${virtualItem.size}px`,
												position: 'absolute',
												transform: `translateY(${virtualItem.start}px)`,
											}}>
											{renderSearchUserInfo(item)}
											<S.DeleteIcon src={Assets.icon.close} />
										</S.UserListItem>
									);
								})}
							</div>
						</S.SelectItemContainer>
					</S.SelectSection>
				</S.SearchContainer>
			}
			footer={
				<S.ButtonSection>
					<CRButton.Default type='text' palette='gray' onClick={() => hideDialog()}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!selectedTargets.length}
						type='filled'
						palette='primary'
						onClick={handleApplySendTarget}>
						저장
					</CRButton.Default>
				</S.ButtonSection>
			}
			onClickClose={hideDialog}
		/>
	);
}
