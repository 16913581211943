import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './styles';

interface Props {
	offset?: number;
}

function CRScrollSpyContainer({
	children,
	offset = 0,
}: React.PropsWithChildren<Props>): React.ReactElement {
	const isScrolling = useRef(false);
	const isLocalHashChange = useRef(false);
	const offsetRef = useRef(0);
	const navigate = useNavigate();
	const { hash, pathname } = useLocation();

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		ref.current?.scrollTo({ top: 0 });
		offsetRef.current = offset;
		const io = new IntersectionObserver((entries) => {
			entries.reverse().forEach((entry) => {
				if (isScrolling.current) return;
				if (entry.intersectionRatio > 0) {
					const href = entry.target.getAttribute('href');
					const expanded = entry.target.getAttribute('aria-expanded') === 'true';
					if (!expanded) return;
					if (!href) return;

					if (expanded && href) {
						isLocalHashChange.current = true;
						navigate(href);
					}
				}
			});
		});

		const anchors = ref.current?.querySelectorAll('div');
		anchors?.forEach((anchor) => {
			io.observe(anchor);
		});
		return () => {
			io.disconnect();
		};
	}, [pathname]);

	useEffect(() => {
		setTimeout(() => {
			if (isLocalHashChange.current) {
				isLocalHashChange.current = false;
				return;
			}

			const anchor = ref.current?.querySelector(`[href='${hash}']`);
			if (!anchor) return;

			const { top, height } = anchor.getBoundingClientRect();

			isScrolling.current = true;
			ref.current?.scrollBy({
				top: top - height + offsetRef.current,
				behavior: 'smooth',
			});
		}, 0);
	}, [pathname, hash]);

	useEffect(() => {
		let scrollTimeout: number;
		const handleScrollEvent = () => {
			window.clearTimeout(scrollTimeout);
			scrollTimeout = window.setTimeout(() => {
				isScrolling.current = false;
			}, 100);
		};

		ref.current?.addEventListener('scroll', handleScrollEvent);
		return () => {
			ref.current?.removeEventListener('scroll', handleScrollEvent);
		};
	}, []);

	return <S.Container ref={ref}>{children}</S.Container>;
}

export default React.memo(CRScrollSpyContainer);
