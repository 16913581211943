import { NavigateFunction } from 'react-router-dom';

import { LocalNavigationSection } from 'types/view/task';

export function generateIntegratedAssessmentDesireInfoScrollSpy(navigate: NavigateFunction) {
	const integratedAssessmentDesireInfoScrollSpy: LocalNavigationSection[] = [
		{
			label: '욕구사정',
			onClick: () => navigate(`#1`),
			subSections: [
				{
					label: '',
					items: [
						{
							label: '수급자 정보',
							key: `#1`,
							onClick: () => navigate(`#1`),
						},
						{
							label: '일반상태',
							key: `#2`,
							onClick: () => navigate(`#2`),
						},
						{
							label: '주요질병상태',
							key: `#3`,
							onClick: () => navigate(`#3`),
						},
						{
							label: '신체상태 (일상생활 수행능력)',
							key: `#4`,
							onClick: () => navigate(`#4`),
						},
						{
							label: '재활상태',
							key: `#5`,
							onClick: () => navigate(`#5`),
						},
						{
							label: '간호처치상태',
							key: `#6`,
							onClick: () => navigate(`#6`),
						},
						{
							label: '인지상태',
							key: `#7`,
							onClick: () => navigate(`#7`),
						},
						{
							label: '의사소통',
							key: `#8`,
							onClick: () => navigate(`#8`),
						},
						{
							label: '가족 및 지지 체계',
							key: `#9`,
							onClick: () => navigate(`#9`),
						},
						{
							label: '주거환경상태',
							key: `#10`,
							onClick: () => navigate(`#10`),
						},
						{
							label: '복지용구 이용 희망',
							key: `#11`,
							onClick: () => navigate(`#11`),
						},
						{
							label: '수급자 및 보호자 개별 욕구',
							key: `#12`,
							onClick: () => navigate(`#12`),
						},
						{
							label: '최종 종합 의견',
							key: `#13`,
							onClick: () => navigate(`#13`),
						},
					],
				},
			],
		},
	];

	return integratedAssessmentDesireInfoScrollSpy;
}
