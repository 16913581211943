import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const ItemContainer = styled.div<{ $disabled: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray100};
	height: 5.6rem;

	${(props) =>
		props.$disabled &&
		css`
			background: ${(props) => props.theme.colors.gray95};
		`}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
