import React from 'react';

import Colors from 'common/colors';
import InformationSheet from 'components/ui/InformationSheet';

function IntegratedFallFormScoreInfo(): React.ReactElement {
	return (
		<InformationSheet
			title='점수해석'
			align='flex-start'
			items={[
				[
					{
						label: '11점 이상',
						value: '낙상 위험 아주 높음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
				[
					{
						label: '5-10점',
						value: '낙상 위험 높음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
				[
					{
						label: '4점 이하',
						value: '낙상 위험 낮음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
			]}
			type='gray'
		/>
	);
}

export default React.memo(IntegratedFallFormScoreInfo);
