export async function toDataURL(url: string) {
	const blob = await fetch(url).then((res) => res.blob());
	return URL.createObjectURL(blob);
}

export const downloadAndConvertToBase64 = (url: string): Promise<string> =>
	new Promise((resolve, reject) => {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					// reader.result에 Base64 문자열이 들어 있습니다.
					const base64String = (reader?.result as string)?.split(',')[1];
					resolve(base64String);
				};
				// 바이너리 데이터를 Base64로 변환
				reader.readAsDataURL(blob);
			})
			.catch((error) => {
				reject(error);
			});
	});

// 파일 확장자 얻을 때
export const getFileExtension = (fileName?: string) => {
	if (!fileName) return '';
	const parts = fileName.split('.');
	return parts[parts.length - 1];
};
