import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import * as S from './styles';
import IntegratedAssessmentDesireAssistiveDevicesFormHeader from './IntegratedAssessmentDesireAssistiveDevicesFormHeader';
import CRCheckBox from '../../../base/Selections/CRCheckBox';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesireAssistiveDevicesForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireAssistiveDevicesFormHeader />
				<S.TableBody>
					<S.TableBodyRow>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('이동변기')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										이동변기
									</CRCheckBox>
								)}
								name='이동변기'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('성인용보행기')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										성인용보행기
									</CRCheckBox>
								)}
								name='성인용보행기'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('안전손잡이')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										안전손잡이
									</CRCheckBox>
								)}
								name='안전손잡이'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('미끄럼방지용품')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										미끄럼방지용품
									</CRCheckBox>
								)}
								name='미끄럼방지용품'
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('수동휠체어')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										수동휠체어
									</CRCheckBox>
								)}
								name='수동휠체어'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('전동침대')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										전동침대
									</CRCheckBox>
								)}
								name='전동침대'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('수동침대')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										수동침대
									</CRCheckBox>
								)}
								name='수동침대'
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										checked={form.watch('배회감지기')}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											onChange(event.target.checked);
										}}>
										배회감지기
									</CRCheckBox>
								)}
								name='배회감지기'
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn colSpan={4}>
							<S.TableContentContainer>
								경사로 (
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<CRCheckBox
											checkBoxType='checkbox'
											checked={form.watch('실내경사로')}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												onChange(event.target.checked);
											}}>
											실내
										</CRCheckBox>
									)}
									name='실내경사로'
									control={form.control}
								/>
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<CRCheckBox
											checkBoxType='checkbox'
											checked={form.watch('실외경사로')}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												onChange(event.target.checked);
											}}>
											실외
										</CRCheckBox>
									)}
									name='실외경사로'
									control={form.control}
								/>
								)
							</S.TableContentContainer>
						</S.TableBodyColumn>
					</S.TableBodyRow>
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='복지용구판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireAssistiveDevicesForm;
