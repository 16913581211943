const Radius = {
	radius01: '0.8rem',
	radius02: '1.6rem',
	radius03: '2.4rem',
	radius04: '3.2rem',
};

export type RadiusTypes = keyof typeof Radius;

export default Radius;
