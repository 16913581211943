import { styled } from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
	padding: 1.6rem;
	height: 100%;
	box-sizing: border-box;
	min-width: 158rem;
	overflow: auto;
`;

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.6rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const SyncTime = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
`;

export const ContentContainer = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	background: ${(props) => props.theme.colors.gray100};
`;
export const InnerContainer = styled.div`
	width: 100%;
	height: calc(100% - 10.1rem);
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;

export const EmptyContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	flex: 1;
`;

export const EmptyTitle = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray60};
`;

export const EmptyDescription = styled.p`
	${(props) => props.theme.typography.body};
	text-align: center;
	color: ${(props) => props.theme.colors.gray60};
`;
