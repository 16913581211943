import React, { useEffect, useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';

import { Controller, UseFormReturn } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { InsuranceEditDetailFormType } from 'types/view/insurance';
import InformationTable from 'components/ui/InformationTable';
import dayjs from 'dayjs';
import { useCommonCodes } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import { InsuranceDetailDTO } from 'types/api/insurance';
import { displayMinuteToHourMinute } from 'lib';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import CRSwitch from 'components/base/Selections/CRSwitch';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import useDialog from 'lib/hook/util/useDialog';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import CRStatus from 'components/base/CRStatus';
import CRButton from 'components/base/CRButton';
import * as S from './styles';
import { InsuranceState } from '..';

interface Props {
	item: InsuranceDetailDTO;
	disabled?: boolean;
	context: UseFormReturn<InsuranceEditDetailFormType, any, undefined>;
}
export default function InsuranceLossView({ item, disabled, context }: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN161'],
		},
		commonCodeAdapter,
	);

	const { showDialog } = useDialog();

	const {
		isEachSelect,
		employInsuranceApproveYn,
		accidentInsuranceApproveYn,
		pensionInsuranceApproveYn,
		healthInsuranceApproveYn,
	} = context.watch();

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '24rem',
					},
				},
				{
					type: 'label',
					label: '상실 일자',
					labelStyle: {
						width: '24rem',
					},
				},
			],
		];
		if (item.insuranceRequestDetails?.employInsuranceRequestDate) {
			items.push([
				{
					type: 'value',
					value: '고용보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.employInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails?.employInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}
		if (item.insuranceRequestDetails?.accidentInsuranceRequestDate) {
			items.push([
				{
					type: 'value',
					value: '산재보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails.accidentInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.accidentInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}
		if (item.insuranceRequestDetails?.pensionInsuranceRequestDate) {
			items.push([
				{
					type: 'value',
					value: '국민연금',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails.pensionInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.pensionInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}
		if (item.insuranceRequestDetails?.healthInsuranceRequestDate) {
			items.push([
				{
					type: 'value',
					value: '건강보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails.healthInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.healthInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (items.length === 1) {
			items.push([
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
			]);
		}

		return items;
	}, [item]);

	const renderResultSelect = () => {
		const isEmployInsuranceExist =
			item.insuranceRequestDetails?.employInsuranceRequestAmt ||
			item.insuranceRequestDetails?.employInsuranceRequestDate;
		const isAccidentInsuranceExist =
			item.insuranceRequestDetails?.accidentInsuranceRequestAmt ||
			item.insuranceRequestDetails?.accidentInsuranceRequestDate;
		const isPensionInsuranceExist =
			item.insuranceRequestDetails?.pensionInsuranceRequestAmt ||
			item.insuranceRequestDetails?.pensionInsuranceRequestDate;
		const isHealthInsuranceExist =
			item.insuranceRequestDetails?.healthInsuranceRequestAmt ||
			item.insuranceRequestDetails?.healthInsuranceRequestDate;

		const commonOptions = [
			{
				label: '완료',
				value: true,
			},
			{
				label: '반려',
				value: false,
			},
		];

		if (isEachSelect) {
			return (
				<S.ResultSelectContainer>
					{isEmployInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								고용보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										style={{
											width: 'auto',
										}}
										disabled={disabled}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='employInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isAccidentInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								산재보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='accidentInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isPensionInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								국민연금 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='pensionInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isHealthInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								건강보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='healthInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
				</S.ResultSelectContainer>
			);
		}
		return (
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.Selector
						topOffset={-285}
						disabled={disabled}
						items={commonCodes?.CMN161.filter((item) => item?.value !== InsuranceState.부분완료)}
						currentValue={value}
						onChangeValue={onChange}
						placeholder='결과 선택'
					/>
				)}
				name='insuranceRequestStateCd'
				control={context.control}
			/>
		);
	};

	const renderCancelDesc = () => {
		if (!item || item.insuranceRequestState.id === InsuranceState.완료) return null;
		const insuranceState = context.watch('insuranceRequestStateCd')?.value as InsuranceState;

		const isExistDenyInsurance = [
			employInsuranceApproveYn?.[0]?.value,
			accidentInsuranceApproveYn?.[0]?.value,
			pensionInsuranceApproveYn?.[0]?.value,
			healthInsuranceApproveYn?.[0]?.value,
		]
			.filter((item) => item !== undefined)
			.some((item) => !item);

		return (
			((isEachSelect && isExistDenyInsurance) ||
				[InsuranceState.반려됨, InsuranceState.철회].includes(insuranceState)) && (
				<CRInputLabel label='반려 사유' type='left-sub' isRequired showOverflow betweenGap='2.4rem'>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.Default
								disabled={disabled}
								value={item?.cancelDesc || value}
								onChange={onChange}
								placeholder='반려 사유 입력'
							/>
						)}
						name='cancelDesc'
						control={context.control}
					/>
				</CRInputLabel>
			)
		);
	};

	const renderEDoc = () => {
		if (!item?.edocInfo) return null;

		const handleClickEDocPreview = (edocNo: string) => {
			if (!edocNo) return;
			showDialog(() => <EDocNoDialog dialogType='M' edocNo={edocNo} />);
		};

		const eDocInformationTableItems = useMemo(() => {
			const options = [
				{
					key: item.edocInfo.signState?.id || '',
					label: item.edocInfo.signState?.text || '',
					color: item.edocInfo.signState?.color || '',
				},
			];
			const items: InformationTableItemType[][] = [
				[
					{
						type: 'label',
						label: '서명상태',
						labelStyle: {
							height: '3.3rem',
							verticalAlign: 'middle',
						},
					},
					{
						type: 'label',
						label: '파일',
						labelStyle: {
							height: '3.3rem',
							verticalAlign: 'middle',
						},
					},
					{
						type: 'label',
						label: '',
						labelStyle: {
							height: '3.3rem',
							verticalAlign: 'middle',
						},
					},
				],
				[
					{
						type: 'value',
						value: <CRStatus options={options}>{item.edocInfo?.signState.id}</CRStatus>,
						valueStyle: {
							verticalAlign: 'middle',
						},
					},
					{
						type: 'value',
						value: item.edocInfo.title,
						valueStyle: {
							verticalAlign: 'middle',
							height: '3.3rem',
						},
					},
					{
						type: 'value',
						value: (
							<CRButton.Default
								onClick={() => handleClickEDocPreview(item.edocInfo.edocNo)}
								size='xSmall'
								type='outlined'
								palette='gray'>
								미리보기
							</CRButton.Default>
						),
						valueStyle: {
							verticalAlign: 'middle',
							height: '3.3rem',
						},
					},
				],
			];

			return items;
		}, [item]);

		return (
			<CRInputLabel label='발급 서류' type='left-sub' showOverflow betweenGap='2.4rem'>
				<InformationTable roundBorder items={eDocInformationTableItems} />
			</CRInputLabel>
		);
	};

	useEffect(() => {
		const defaultForm: InsuranceEditDetailFormType = {
			insuranceRequestStateCd: item.insuranceRequestState
				? {
						label: item.insuranceRequestState.text,
						value: item.insuranceRequestState.id,
				  }
				: undefined,
			responseDesc: item?.responseDesc || '',
			insuranceRequestDetails: item.insuranceRequestDetails || undefined,
		};

		if (item.insuranceRequestState?.id === InsuranceState.부분완료) {
			defaultForm.employInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.employInsuranceApproveYn,
				},
			];
			defaultForm.accidentInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.accidentInsuranceApproveYn,
				},
			];
			defaultForm.healthInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.healthInsuranceApproveYn,
				},
			];
			defaultForm.pensionInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.pensionInsuranceApproveYn,
				},
			];
			defaultForm.isEachSelect = true;
		}
		context.reset(defaultForm);
		// if (item.insuranceRequestState) {
		// 	context.setValue('insuranceRequestStateCd', {
		// 		label: item.insuranceRequestState.text,
		// 		value: item.insuranceRequestState.id,
		// 	});
		// }

		// if (item.responseDesc) {
		// 	context.setValue('responseDesc', item.responseDesc);
		// }

		// if (item.insuranceRequestDetails) {
		// 	context.setValue('insuranceRequestDetails', item.insuranceRequestDetails);

		// 	// if()
		// }
	}, [item]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='신고 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<InformationTable items={informationTableItems} />
								</CRInputLabel>
								<CRInputLabel
									label='상실 사유'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<CRInput.Selector
										disabled
										currentValue={{
											label: item?.lossDesc || '',
											value: item?.lossDesc || '',
										}}
										items={[
											{
												label: item?.lossDesc || '',
												value: item?.lossDesc || '',
											},
										]}
										placeholder='상실 사유 선택'
									/>
									{item?.lossDetailDesc && (
										<CRInput.Selector
											disabled
											currentValue={{
												label: item?.lossDetailDesc || '',
												value: item?.lossDetailDesc || '',
											}}
											items={[
												{
													label: item?.lossDetailDesc || '',
													value: item?.lossDetailDesc || '',
												},
											]}
											placeholder='구체적 상실 사유 선택'
										/>
									)}
								</CRInputLabel>
								<CRInputLabel
									label='상실월 급여'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<CRInput.Default
										value={item?.currentMonthSalaryAmt?.toLocaleString()}
										disabled
										suffix='원'
									/>
								</CRInputLabel>
								<CRInputLabel
									label='상실월 근로시간'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<CRInput.Default
										value={displayMinuteToHourMinute(item?.currentMonthWorkHourCnt)}
										disabled
									/>
								</CRInputLabel>
								<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
									<CRInput.TextArea
										disabled
										numberOfLines={4}
										value={item.requestDesc || ''}
										placeholder=''
										fixedHeight
									/>
								</CRInputLabel>
								<div
									style={{
										width: '57.2rem',
									}}>
									{item.attachFile?.fileDetails &&
										item.attachFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
											<CRInputLabel
												label='파일 첨부'
												type='left-sub'
												showOverflow
												betweenGap='2.4rem'>
												<CRInput.FileUploader
													disabled
													files={item.attachFile?.fileDetails}
													placeholder='파일 업로드'
													type='multiple'
												/>
											</CRInputLabel>
										)}
								</div>
								{renderEDoc()}
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='업무 담당자 답변'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.TextArea
												disabled={disabled}
												numberOfLines={4}
												onChange={onChange}
												value={disabled ? item?.responseDesc : value}
												placeholder='업무 담당자 답변 입력'
												fixedHeight
											/>
										</CRInputLabel>
									)}
									name='responseDesc'
									control={context.control}
								/>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='4대보험 신고 결과' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>4대보험 신고 결과</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='보험별 선택 여부'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<S.SwitchContainer>
												<CRSwitch
													disabled={disabled}
													onChange={onChange}
													value={value}
													type='icon'
												/>
											</S.SwitchContainer>
										)}
										name='isEachSelect'
										control={context.control}
									/>
								</CRInputLabel>
								<CRInputLabel
									label='결과 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									{renderResultSelect()}
								</CRInputLabel>
								{renderCancelDesc()}
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
