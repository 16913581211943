import { CheckOption } from 'components/base/Selections/type';
import { InsurancePriorInfoDTO, InsuranceRequestDetail } from 'types/api/insurance';
import { FileDetailDTO } from 'types/dto';

export enum InsCancelCds {
	근무이력없음 = 'CMN202.10',
	월60시간미만근무 = 'CMN202.20',
}

export type InsuranceExcelDownloadForm = {
	state: CheckOption;
	insuranceType: CheckOption[];
};

export type InsuranceRequestTypeFormType = {
	requestType: CheckOption<any>;
};

export type InsuranceAcquirementFormType = {
	requestDesc: string;
	insuranceType: CheckOption[];
	attachFile: FileDetailDTO[];
	weekPerWorkHourCnt: number; // 주 소정근로시간
	accidentInsuranceRequestAmt?: string; // 산재보험
	accidentInsuranceRequestDate: string;
	pensionInsuranceRequestAmt?: string; // 국민연금
	pensionInsuranceRequestDate: string;
	employInsuranceRequestAmt?: string; // 고용보험
	employInsuranceRequestDate: string;
	healthInsuranceRequestAmt?: string; // 건강보험
	healthInsuranceRequestDate: string;
	unemploymentBenefitPreconditionFullYn: CheckOption[];
	// 피부양자
	dependents: {
		dependentNm: string; // 이름
		dependentRsdnNo: string; // 주민번호
		dependentRelCd?: CheckOption; // 관계
		dependentRegistDt: string; // 등록일
		isForeign: CheckOption[]; // 국적
		dependentCountryCd?: CheckOption; // 나라 코드
		dependentStayStartDt: string; // 체류 시작일
		dependentStayEndDt: string; // 체류 종료일
	}[];
	birthDate: string;
	edocNo?: string;
};

export type InsuranceLossFormType = {
	insuranceType: CheckOption[];
	accidentInsuranceRequestDate: string; // 산재보험
	pensionInsuranceRequestDate: string; // 국민연금
	employInsuranceRequestDate: string;
	healthInsuranceRequestDate: string;
	lossDescCd?: CheckOption;
	lossDetailDescCd?: CheckOption;
	currentMonth: {
		serviceType?: CheckOption;
		salaryFee?: CheckOption;
		count: string;
		hour: string;
	}[];
	requestDesc: string;
	attachFile: FileDetailDTO[];
	thisSalaryInfo: {
		totalWorkHourCnt: number;
		paySumAmt: number;
	};
	issueCareerChangeCert: CheckOption[]; // 이직 확인서 요청 여부
	attachFileRequestDesc: string; // 이직 확인서 요청사항
	careerChangeAttachFile: FileDetailDTO[]; // 이작 확인서 첨부파일
	edocNo?: string;
};

export type InsuranceCancelFormType = {
	insuranceType?: CheckOption[];
	requestDesc: string;
	attachFile: FileDetailDTO[];
	data?: InsurancePriorInfoDTO;
	edocNo?: string;
};

export type InsuranceChangeSalaryFormType = {
	insuranceType?: CheckOption[];
	accidentInsuranceRequestAmt: string; // 산재보험
	pensionInsuranceRequestAmt: string; // 국민연금
	employInsuranceRequestAmt: string;
	healthInsuranceRequestAmt: string;
	salaryChangeDescCd: CheckOption;
	salaryChangeYm: Date;
	requestDesc: string;
	attachFile: FileDetailDTO[];
	data?: InsurancePriorInfoDTO;
};

export type InsuranceBaseFormType = {
	requestDesc: string;
	attachFile: FileDetailDTO[];
};

export type InsuranceDateChangeFormType = {
	insuranceType: CheckOption[];
	accidentInsuranceRequestDate: string; // 산재보험
	pensionInsuranceRequestDate: string; // 국민연금
	employInsuranceRequestDate: string;
	healthInsuranceRequestDate: string;
	requestDesc: string;
	attachFile: FileDetailDTO[];
	data?: InsurancePriorInfoDTO;
	unemploymentBenefitPreconditionFullYn: CheckOption[];
};
export type InsuranceLossDateChangeFormType = {
	insuranceType: CheckOption[];
	accidentInsuranceRequestDate: string; // 산재보험
	pensionInsuranceRequestDate: string; // 국민연금
	employInsuranceRequestDate: string;
	healthInsuranceRequestDate: string;
	requestDesc: string;
	attachFile: FileDetailDTO[];
};

export type InsuranceEditDetailFormType = {
	insuranceRequestStateCd?: CheckOption;
	responseDesc?: string;
	attachFileResponseDesc?: string;
	cancelDesc?: string;
	isEachSelect?: boolean;
	insuranceRequestDetails?: InsuranceRequestDetail;
	employInsuranceApproveYn?: CheckOption[];
	accidentInsuranceApproveYn?: CheckOption[];
	healthInsuranceApproveYn?: CheckOption[];
	pensionInsuranceApproveYn?: CheckOption[];
};
