import React, { ChangeEvent, useMemo } from 'react';

import InformationLayout from 'components/domain/layout/InformationLayout';
import EmployeeListTable from 'components/domain/table/EmployeeListTable';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import PartTimeEmployeeDetailBasicInformationTable from 'components/domain/table/PartTimeEmployeeDetailBasicInformationTable';

import EmployeeEnrollDialog from 'components/domain/dialog/EmployeeEnrollDialog';
import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';
import { Outlet, useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';

import { EmployeeDTO } from 'types/api/employee';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';

import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { CRText } from 'components/base/CRText';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useEmployeesExcelInfo, useMyAccountInfo } from 'lib/hook/react-query';
import { Toast } from 'components/base/CRToast';
import { exportEmployeesInfoTransfer } from 'lib/util/xlsx/EmployeesInfoTransfer';
import * as S from './styles';
import CRButton from '../../../components/base/CRButton';
import Assets from '../../../assets';
import CRInput from '../../../components/base/CRInput';
import CRTab from '../../../components/base/CRTab';
import { TAB_CONFIG } from './config';

function EmployeePage(): React.ReactElement {
	const { breadCrumbs, showDialog } = useGlobalLayout();
	const navigate = useNavigate();

	const {
		currentTab,
		employees,
		currentEmployee,
		currentEmployeeBase,
		setCurrentEmployee,
		searchText,
		setSearchText,
		currentFilter,
		setCurrentFilter,
		stateFilter,
		dutyFilter,
		managerFilter,
		setEditForm,
		cancelChangeEditSection,
	} = useEmployeePage();

	const { mutateAsync: getEmployeesExcelInfo, isLoading: excelDownloadLoading } =
		useEmployeesExcelInfo();
	const { data: myAccountInfo } = useMyAccountInfo();

	const handleClickTab = (tab: TaskTabProps) => {
		navigate(`/${RouterPath.employee().tab.uniqueKey}/${currentEmployee?.employeeId}/${tab.key}`);
	};

	const handleClickDetailItem = (item: EmployeeDTO) => {
		setCurrentEmployee(item);
		setEditForm(undefined);
		cancelChangeEditSection?.();
		navigate(`/${RouterPath.employee().tab.uniqueKey}/${item?.employeeId}/${currentTab}`);
	};

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const downloadEmployeesExcelInfoTransferHandler = async () => {
		if (!myAccountInfo?.centerId) return;
		const res = await getEmployeesExcelInfo({
			centerId: String(myAccountInfo.centerId),
		});

		if (!res || !res.length) {
			Toast.error('다운로드 할 자료가 존재하지 않습니다. 관리자에게 문의해주세요.');
			return;
		}

		const fileName = `직원목록_${myAccountInfo.centerNm}`.replace(/\s/g, '_');

		try {
			exportEmployeesInfoTransfer(fileName, res);
			Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
		} catch {
			Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
		}
	};

	const handleClickEmployeeEnroll = () => {
		const onSuccess = (recipientId?: number) => {
			if (!recipientId) return;
			navigate(`/${RouterPath.employee().tab.uniqueKey}/${recipientId}/basic?isNew=true`);
		};
		showDialog(({ hideDialog }) => (
			<EmployeeEnrollDialog hideDialog={hideDialog} onSuccess={onSuccess} />
		));
	};

	const LeftContentComponent = useMemo(
		() => (
			<S.Container>
				<S.Header>
					<S.Title>직원 목록</S.Title>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='filled'
						palette='primary'
						size='xSmall'
						onClick={handleClickEmployeeEnroll}>
						직원 추가
					</CRButton.IconButton>
				</S.Header>
				<CRInput.Search
					placeholder='직원명, 생년월일, 전화번호 검색'
					value={searchText}
					onChange={handleChangeSearchText}
				/>
				<CRFilterGroup
					filters={[stateFilter, dutyFilter, managerFilter]}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
				/>
				<S.TableContainer>
					<EmployeeListTable
						items={employees}
						searchValue={searchText}
						onClickRow={handleClickDetailItem}
						currentItem={currentEmployee}
					/>
				</S.TableContainer>
				<S.LeftLayoutBottomContainer>
					<CRText text={`총 ${employees?.length}명`} />
					<CRButton.IconButton
						isLoading={excelDownloadLoading}
						size='small'
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.download}
						onClick={downloadEmployeesExcelInfoTransferHandler}>
						엑셀 다운로드
					</CRButton.IconButton>
				</S.LeftLayoutBottomContainer>
			</S.Container>
		),
		[
			currentFilter,
			currentEmployee,
			searchText,
			dutyFilter,
			stateFilter,
			managerFilter,
			currentTab,
			excelDownloadLoading,
		],
	);

	const RightContentComponent = useMemo(
		() => (
			<S.Container>
				<S.RightTableContainer>
					<Accordion.Root
						type='multiple'
						style={{
							width: '100%',
							marginBottom: 0,
						}}
						defaultValue={['기본 정보']}>
						<Accordion.Item value='기본 정보' asChild>
							<TaskAccordion.Item
								style={{
									marginBottom: 0,
								}}>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger
											style={{
												padding: 0,
											}}>
											직원 정보
										</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content
										style={{
											borderTop: 'none',
											padding: '0.8rem 0',
										}}>
										<PartTimeEmployeeDetailBasicInformationTable item={currentEmployeeBase} />
									</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					</Accordion.Root>
				</S.RightTableContainer>
				<S.RightTabContainer>
					<CRTab.Task items={TAB_CONFIG} defaultActiveKey={currentTab} onChange={handleClickTab} />
					<S.RightTabViewContainer>
						<S.TabView>
							<Outlet />
						</S.TabView>
					</S.RightTabViewContainer>
				</S.RightTabContainer>
			</S.Container>
		),
		[currentTab, handleClickTab, currentEmployeeBase],
	);

	if (breadCrumbs.length > 1) {
		return <Outlet />;
	}

	return (
		<InformationLayout
			LeftContentComponent={LeftContentComponent}
			RightContentComponent={RightContentComponent}
		/>
	);
}

export default EmployeePage;
