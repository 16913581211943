import { css, styled } from 'styled-components';

export const CustomHeaderContainer = styled.div`
	margin-bottom: 0.8rem;
`;

export const HeaderTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.6rem;
`;

export const SendButtonContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
	display: flex;
	align-items: center;
	gap: 1.6rem;
`;

export const LabelContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	padding: 0.5rem 0;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray10};
`;

export const HeaderBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FilterButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
`;

export const Divider = styled.div`
	width: 0.1rem;
	height: 1.6rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const MonthDate = styled.h4`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	${(props) => props.theme.typography.h4};
	color: ${(props) => props.theme.colors.gray00};
`;

export const HeaderButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;

	& > div {
		display: flex;
		gap: 0.8rem;
	}
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const CustomDay = styled.div<{ $hasError: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	height: 100%;

	${(props) =>
		props.$hasError &&
		css`
			/* cursor: pointer; */
			background: ${(props) => props.theme.colors.primary95};
		`};
`;

export const DayNumberContainer = styled.div<{ $isHoliday?: boolean; $isSunday?: boolean }>`
	${(props) => props.theme.typography.bodyB};
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 3.7rem;
	padding: 0 0.8rem;
	color: ${(props) => props.theme.colors.gray00};

	${(props) =>
		props.$isHoliday &&
		css`
			color: ${(props) => props.theme.colors.primary60};
		`}

	${(props) =>
		props.$isSunday &&
		css`
			color: ${(props) => props.theme.colors.primary60} !important;
		`}
`;

export const Today = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	box-sizing: border-box;
	width: 2.4rem;
	height: 2.4rem;
	background: ${(props) => props.theme.colors.primary60};
	margin: 0 0.4rem;
	color: ${(props) => props.theme.colors.gray100};
`;

export const DayTaskContainer = styled.div`
	flex: 1;
	padding: 0.5rem 0.8rem;
`;

export const DayTaskItem = styled.div<{ $isHoliday?: boolean }>`
	${(props) => props.theme.typography.label};

	${(props) =>
		props.$isHoliday &&
		css`
			background: ${props.theme.colors.redLighten};
			padding: 0 0.8rem;
			border-radius: 0.4rem;
			color: ${props.theme.colors.redDarken};
		`}
`;

export const TaskTitle = styled.div`
	display: flex;
	align-items: center;
`;
export const Label = styled.div`
	display: flex;
	align-items: center;
`;

export const TaskCircle = styled.div<{ $taskColor?: string }>`
	width: 1.4rem;
	height: 1.4rem;
	margin-right: 0.4rem;
	border-radius: 50%;
	background-color: ${(props) => props.$taskColor ?? props.theme.colors.primary60};
`;

export const TaskText = styled.div`
	${(props) => props.theme.typography.label};
	display: inline-block;
	text-align: left;
	justify-content: flex-start;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const TimeTable = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const Container = styled.div<{ $height?: string }>`
	.react-datepicker {
		border: none;
		background: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__aria-live {
		display: none;
	}

	.react-datepicker__header {
		min-width: 80rem;
		width: 100%;
		background: none;
		border-bottom: 0.2rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day-names {
		display: flex;
		width: 100%;
		margin: 0;
		margin-bottom: 0.8rem;
	}

	.react-datepicker__day-name {
		${(props) => props.theme.typography.label};
		display: flex;
		justify-content: flex-end;
		flex: 1;
		margin: 0;
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__day-name:first-child {
		color: ${(props) => props.theme.colors.primary60};
	}

	.react-datepicker__month {
		margin: 0;
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
		border-bottom: 0.2rem solid ${(props) => props.theme.colors.gray90};
		overflow: auto;
		height: ${(props) => (props.$height ? `calc(${props.$height})` : 'auto')};
		min-width: 80rem;
	}

	.react-datepicker__month-container {
		overflow: auto;
	}

	.react-datepicker__day--weekend {
		background: ${(props) => props.theme.colors.gray99};
	}

	.react-datepicker__week {
		display: flex;
	}

	.react-datepicker__week + .react-datepicker__week {
		border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day {
		width: 100%;
		min-height: 14.8rem;
		margin: 0;
		box-sizing: border-box;
		border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day--outside-month ${DayNumberContainer} {
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__day--keyboard-selected {
		background: none;
	}
`;
