import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import useCRMutation from '../base/useCRMutation';

export const useCommonCodes = useCRQuery(endpoint.geCommonCodes.key, 'getCommonCodes');

export const useCommonCenters = useCRQuery(endpoint.getCommonCenters.key, 'getCommonCenters');

export const useMyAccountInfo = useCRQuery(endpoint.getMyAccountInfo.key, 'getMyAccountInfo');

export const useAutomationUploadInfo = useCRQuery(
	endpoint.getAutomationUploadList.key,
	'getAutomationUploadList',
);

export const useInquireIndividualInfo = useCRMutation(
	endpoint.inquireIndividualInfo.key,
	'inquireIndividualInfo',
);

export const useAutomationUploadList = useCRQuery(
	endpoint.getAutomationUploadList.key,
	'getAutomationUploadList',
);

export const useSalaryAutomationUploadInfo = useCRQuery(
	endpoint.getAutomationUploadList.key,
	'getAutomationUploadList',
	{ enabledKey: ['centerId', 'uploadTypeCds'], keepPreviousData: false },
);

export const useAutomationLastSync = useCRQuery(
	endpoint.getAutomationLastSync.key,
	'getAutomationLastSync',
	{ enabledKey: ['centerId', 'uploadTypeCds'] },
);

export const useCheckAccountOwner = useCRMutation(
	endpoint.checkAccountOwner.key,
	'checkAccountOwner',
);

export const useSendSlack = useCRMutation(endpoint.sendSlack.key, 'sendSlack');

export const useCheckRealName = useCRMutation(endpoint.checkRealName.key, 'checkRealName');
