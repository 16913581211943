import React, { useCallback } from 'react';
import CRTable from 'components/base/CRTable';
import {
	MessageTabReservationHistoryDTO,
	MessageTabSendHistoryDTO,
	SendHistoryContentDTO,
} from 'types/api/send';
import CRStatus from 'components/base/CRStatus';

import { v4 } from 'uuid';
import CRSpinner from 'components/base/CRSpinner';
import dayjs from 'dayjs';
import * as S from './styles';
import { SEND_HISTORY_HEADER_CONFIG, SEND_STATUS_CONFIG } from './constant';

interface Props {
	isLoading?: boolean;
	items?: MessageTabReservationHistoryDTO[];
	onClickRow?: (task: MessageTabReservationHistoryDTO) => void;
}

function EmployeeReservationTable({
	isLoading,
	items = [],
	onClickRow,
}: Props): React.ReactElement {
	const SendStatusBadge = useCallback(
		(value: string) => <CRStatus options={SEND_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	const renderSendDate = useCallback(
		(value: string) => <S.TextContainer>{dayjs(value).format('YYYY.MM.DD HH:mm')}</S.TextContainer>,
		[],
	);

	return (
		<S.Container>
			{isLoading ? (
				<CRSpinner />
			) : (
				<CRTable.Root>
					<CRTable.Head heads={SEND_HISTORY_HEADER_CONFIG} />
					<CRTable.Body>
						{items?.map((item) => (
							<CRTable.Row
								key={v4()}
								item={item}
								renderKeys={[
									'sendDate',
									'sendTemplateTitle',
									'sendTitle',
									'senderNm',
									'sendStateNm',
								]}
								customRender={{
									sendStateNm: SendStatusBadge,
									sendDate: renderSendDate,
								}}
								onClick={onClickRow}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			)}
		</S.Container>
	);
}

export default EmployeeReservationTable;
