import React, { ReactNode } from 'react';
import Assets from 'assets';
import * as S from './styles';
import { BannerType } from './type';

export interface BannerProps {
	type?: BannerType;
	title?: ReactNode;
	content?: ReactNode;
	showTypeIcon?: boolean;
	rightButton?: ReactNode;
	onClickBanner?: () => void;
	onClose?: () => void;
}

const icons = {
	default: Assets.icon.info,
	error: Assets.icon.info,
	info: Assets.icon.info,
	warning: Assets.icon.warning,
	success: Assets.icon.checkCircle,
};

function CRBanner({
	type = 'default',
	title,
	content,
	showTypeIcon = true,
	rightButton,
	onClickBanner,
	onClose,
}: BannerProps): React.ReactElement {
	if (title) {
		return (
			<S.Container $type={type} onClick={onClickBanner} $hasAction={!!onClickBanner}>
				{showTypeIcon && <S.LeftIcon $type={type} src={icons[type]} alt='bannerIcon' />}
				<S.ContentContainer>
					<S.Title $hasLeftButton={showTypeIcon} $hasRightButton={!!rightButton}>
						{title}
					</S.Title>
					<S.Text $hasLeftButton={showTypeIcon} $hasRightButton={!!rightButton}>
						{content}
					</S.Text>
				</S.ContentContainer>
				{onClose && (
					<S.ButtonContainer
						role='presentation'
						onClick={(e) => {
							e.stopPropagation();
							onClose?.();
						}}>
						<S.RightCloseIcon src={Assets.icon.close} $type={type} />
					</S.ButtonContainer>
				)}
				{!onClose && rightButton && (
					<S.ButtonContainer role='presentation' onClick={(e) => e.stopPropagation()}>
						{rightButton}
					</S.ButtonContainer>
				)}
			</S.Container>
		);
	}
	return (
		<S.Container $type={type} onClick={onClickBanner} $hasAction={!!onClickBanner}>
			{}
			{showTypeIcon && <S.LeftIcon $type={type} src={icons[type]} alt='bannerIcon' />}
			<S.Text $hasLeftButton={showTypeIcon} $hasRightButton={!!rightButton}>
				{content}
			</S.Text>
			{onClose && (
				<S.ButtonContainer
					style={{
						paddingLeft: '2.4rem',
					}}
					role='presentation'
					onClick={(e) => {
						e.stopPropagation();
						onClose?.();
					}}>
					<S.RightCloseIcon src={Assets.icon.close} $type={type} />
				</S.ButtonContainer>
			)}
			{!onClose && rightButton && (
				<S.ButtonContainer role='presentation' onClick={(e) => e.stopPropagation()}>
					{rightButton}
				</S.ButtonContainer>
			)}
		</S.Container>
	);
}

export default CRBanner;
