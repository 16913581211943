import React, { useState } from 'react';

import Assets from 'assets';
import * as S from './styles';

interface IProps {
	item: {
		key: string;
		label: string;
		onClick?: () => void;
	};
	isFirst?: boolean;
	isLast?: boolean;
	isActive?: boolean;
	isDone?: boolean;
	hideProgress?: boolean;
}

function ScrollSpySection({
	item,
	isFirst,
	isLast,
	isActive,
	isDone,
	hideProgress,
}: IProps): React.ReactElement {
	return (
		<S.ItemContainer $isActive={isActive} onClick={item.onClick}>
			{!hideProgress && (
				<S.CheckLineContainer>
					<S.CheckIconContainer>
						<S.CheckIcon src={Assets.icon.checkCircle} $isActive={isActive} $isDone={isDone} />
					</S.CheckIconContainer>
					<S.Line $isFirst={isFirst} $isLast={isLast} $isActive={isActive} />
				</S.CheckLineContainer>
			)}
			<S.SectionItem key={item.label} $isActive={isActive}>
				{item.label}
			</S.SectionItem>
		</S.ItemContainer>
	);
}

export default React.memo(ScrollSpySection);
