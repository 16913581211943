import { CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_LONG_TERM_GRADE_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '13.6rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '12rem',
		label: '변경 일자',
		sortKey: '변경 일자',
	},
	{
		width: '9.6rem',
		label: '인지등급',
		sortKey: '인지등급',
	},
	{
		width: '9.6rem',
		label: '취득일',
		sortKey: '취득일',
	},
	{
		width: '9.6rem',
		label: '종료일',
		sortKey: '종료일',
	},
	{
		width: '20rem',
		label: '인정번호',
		sortKey: '인정번호',
	},
	{
		width: '100%',
		label: '변경 사유',
		sortKey: '변경 사유',
	},
	{
		width: '12rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];
