import { CRTableHeadConfig } from '../../../../types/view/base';

export const ALARM_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '40rem',
		label: '내용',
		sortKey: '내용',
	},
	{
		width: '24rem',
		label: '대상자',
		sortKey: '대상자',
	},
	{
		width: '16rem',
		label: '날짜',
		sortKey: '날짜',
	},
	{
		width: '32rem',
		label: '주담당자',
		sortKey: '주담당자',
	},
	{
		width: '32rem',
		label: '부담당자',
		sortKey: '부담당자',
	},
];
