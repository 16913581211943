import { MyAccountInfoDTO } from 'types/api';
import { EmployeeHistoryOfManagerDTO } from 'types/api/employee';
import {
	EmployeeScheduleDetailDTO,
	RecipientScheduleDetailDTO,
	ScheduleDTO,
	ScheduleDetailCenterItem,
} from 'types/api/schedule';
import { EmployeeManagerHistoryType } from 'types/view/employee';
import {
	ProcessedSchedule,
	ProcessedEmployeeScheduleDetail,
	ScheduleCategory,
	ScheduleItemType,
	ScheduleType,
	ProcessedRecipientScheduleDetail,
} from 'types/view/schedule';
import { v4 } from 'uuid';

export const employeeHistoryOfManagerAdapter = (items: EmployeeHistoryOfManagerDTO[] | null) =>
	(items ?? []).map(
		(item) =>
			({
				id: (item.employeeManagerHistId ?? 0).toString(),
				status: item.currentYn ? '현재' : '이전',
				date: item.managerChangeDate,
				center: {
					id: item.centerId.toString(),
					name: item.centerNm,
				},
				manager: {
					id: item.managerId.toString(),
					name: item.managerNm,
				},
				note: item.managerChangeDesc,
				consultantOpinion: item.consultOpinion,
				managerChangeDesc: item.managerChangeReason,
			} as EmployeeManagerHistoryType),
	);

export const employeeScheduleAdapter =
	(myAccountInfo?: MyAccountInfoDTO | null) => (data: ScheduleDTO | null) => {
		if (!data) return null;
		const processedData: ScheduleType[] = [];

		const formatTime = (time: string) => {
			if (time.length === 4) {
				return `${time.slice(0, 2)}:${time.slice(2)}`;
			}
			if (time.length === 6) {
				return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
			}
			return time;
		};

		const uniqueDates =
			Array.from(
				new Set([
					...data.holidays.map((item) => item.holidayDt),
					...data.otherCorpWorks.map((item) => item.otherCorpWorkDt),
					...data.schedules.map((item) => item.serviceStartDt),
					...data.errorSchedules.map((item) => item.errorDt),
				]),
			).sort() ?? [];
		uniqueDates?.forEach((date) => {
			const items: ScheduleItemType[] = [];
			data.holidays.forEach((holiday) => {
				if (holiday.holidayDt === date) {
					items.push({
						kind: ScheduleCategory.HOLIDAYS,
						title: holiday.holidayNm,
					});
				}
			});

			data.otherCorpWorks.forEach((otherCorpWork) => {
				if (otherCorpWork.otherCorpWorkDt === date) {
					items.push({
						kind: ScheduleCategory.OTHER,
						title: '타근무',
						startTime: formatTime(otherCorpWork.otherCorpWorkStartTime),
						endTime: formatTime(otherCorpWork.otherCorpWorkEndTime),
					});
				}
			});

			data.schedules.forEach((schedule) => {
				if (schedule.serviceStartDt === date) {
					items.push({
						kind:
							schedule.centerId === myAccountInfo?.centerId
								? ScheduleCategory.MY_CENTER
								: ScheduleCategory.CARING_CENTER,
						title: schedule.serviceKindNm,
						startTime: formatTime(schedule.serviceStartTime),
						endTime: formatTime(schedule.serviceEndTime),
						name: schedule.recipientNm,
						centerId: schedule.centerId,
					});
				}
			});

			const checkOverlapTime = () => {
				const caringCenterItems =
					items
						?.filter((item) =>
							[ScheduleCategory.CARING_CENTER, ScheduleCategory.MY_CENTER].includes(item.kind),
						)
						?.map((item) => ({
							scheduleId: v4(),
							time: {
								startTime: `${item?.startTime?.substring(0, 2)}:${item?.startTime?.substring(
									2,
									4,
								)}`,
								endTime: `${item?.endTime?.substring(0, 2)}:${item?.endTime?.substring(2, 4)}`,
							},
						})) ?? [];

				const overlapIds: (string | number)[] = [];

				for (let i = 0; i < items.length; i += 1) {
					for (let j = i + 1; j < caringCenterItems.length; j += 1) {
						const schedule1 = caringCenterItems[i].time;
						const schedule2 = caringCenterItems[j].time;

						if (
							schedule1.startTime < schedule2.endTime &&
							schedule1.endTime > schedule2.startTime
						) {
							// 시간이 겹치는 경우
							overlapIds.push(caringCenterItems[i].scheduleId);
							overlapIds.push(caringCenterItems[j].scheduleId);
						}
					}
				}
				return overlapIds;
			};

			const hasDuplicatedSchedule = checkOverlapTime();

			const hasError =
				!!data.errorSchedules.find((errorSchedule) => errorSchedule.errorDt === date) ||
				hasDuplicatedSchedule?.length > 0;

			const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6)}`;
			const processedDate = {
				date: formattedDate,
				hasError,
				items,
			};

			processedData.push(processedDate);
		});

		return {
			schedules: processedData,
			lastSyncUpdate: data.syncUpdateAt,
		} as ProcessedSchedule;
	};

export const employeeScheduleDetailAdapter =
	(centerId?: number) => (data: EmployeeScheduleDetailDTO | null) => {
		if (!data || !centerId) return null;

		return {
			employee: [data.employee],
			recipients: data.recipients,
			caringCenter: data.schedules.filter((schedule) => schedule.centerId !== centerId),
			myCenter: data.schedules.filter((schedule) => schedule.centerId === centerId),
			otherCorpWorks: data.otherCorpWorks,
		} as ProcessedEmployeeScheduleDetail;
	};

export const recipientScheduleDetailAdapter =
	(centerId?: number) => (data: RecipientScheduleDetailDTO | null) => {
		if (!data || !centerId) return null;
		const currentCenterSchedules = data.schedules.filter(
			(schedule) => schedule.centerId === centerId,
		);

		const otherSchedule = currentCenterSchedules.filter(
			(item) => item.serviceKindNm !== '방문목욕',
		);
		const bathSchedule = currentCenterSchedules.filter((item) => item.serviceKindNm === '방문목욕');
		const uniqueEntries = new Set();
		const uniqueBathSchedule: ScheduleDetailCenterItem[] = [];
		bathSchedule.forEach((item) => {
			const key = `${item.serviceStartDt}-${item.serviceStartTime}-${item.serviceEndTime}`;
			if (!uniqueEntries.has(key)) {
				uniqueEntries.add(key);
				uniqueBathSchedule.push(item);
			}
		});

		return {
			employees: data.employees,
			recipient: [data.recipient],
			caringCenter: data.schedules.filter((schedule) => schedule.centerId !== centerId),
			myCenter: otherSchedule.concat(uniqueBathSchedule),
			otherServiceUses: data.otherServiceUses,
		} as ProcessedRecipientScheduleDetail;
	};
