import styled from 'styled-components';

export const Container = styled.div`
	width: 96rem;
	height: 80rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	height: 7.6rem;
	padding: 2.4rem 2.4rem 1.6rem;
	color: ${(props) => props.theme.colors.gray00};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const SearchContainer = styled.div`
	display: flex;
	flex: 1;
	height: 64.4rem;
`;

export const TargetContainer = styled.li`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const TargetName = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
`;

export const TargetDescription = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const SearchBarContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	gap: 0.8rem;
	padding: 1.6rem 2.4rem;
	box-sizing: border-box;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const InputSection = styled.div`
	width: 50%;
	flex: 1;
`;

export const CategorySection = styled.div`
	max-height: 50rem;
	padding: 1.6rem 2.4rem;
	box-sizing: border-box;
	height: 50rem;
`;

export const Group = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	box-sizing: border-box;
	& + & {
		margin-top: 2.4rem;
	}
`;

export const GroupTitle = styled.span`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.6rem;
	box-sizing: border-box;
	color: ${(props) => props.theme.colors.gray60};
`;

export const ButtonContainer = styled.div``;

export const GroupItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${(props) => props.theme.typography.body};
	padding: 0.8rem 1.6rem;
	color: ${(props) => props.theme.colors.gray10};
	cursor: pointer;
`;

export const SelectSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	flex: 1;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray99};
	overflow: hidden;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 1.6rem 0 0;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 2.4rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const Reset = styled.span``;

export const SelectItemContainer = styled.ul`
	overflow-y: auto;
	padding: 0 2.4rem;
	height: 58rem;
`;

export const DeleteIcon = styled.img`
	display: none;
	width: 2.4rem;
	height: 2.4rem;
	user-select: none;
`;

export const UserListItem = styled.li`
	${(props) => props.theme.typography.body};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.8rem 1.6rem;
	border-radius: 0.8rem;
	color: ${(props) => props.theme.colors.gray00};
	margin-bottom: 4px;

	&:hover {
		background: ${(props) => props.theme.colors.gray100};
	}

	&:hover > ${DeleteIcon} {
		display: flex;
	}
	cursor: pointer;
`;

export const ButtonSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.8rem;
	height: 8rem;
	padding: 0 2.4rem;
	box-sizing: border-box;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const ScrollContainer = styled.div`
	overflow-y: auto;
	height: 40rem;
`;
