import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 2.4rem;
`;

export const Description = styled.div`
	white-space: pre-wrap;
	margin-bottom: 1.6rem;
`;

export const WageContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 1.6rem;
	background-color: ${(props) => props.theme.colors.gray99};
	box-sizing: border-box;
	margin-bottom: 0.4rem;
`;

export const WageLabel = styled.p`
	flex: 1;
	color: ${(props) => props.theme.colors.gray60};
`;

export const PriceLabel = styled.p`
	${(props) => props.theme.typography.bodyB}
`;

export const InfoContainer = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.gray60};
`;

export const InfoIcon = styled.img`
	width: 1.6rem;
	height: 1.6rem;
	margin-right: 0.4rem;
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

export const LeftButtonContainer = styled.div`
	flex: 1;
`;
