import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import ScheduleErrorEditTable from 'components/domain/table/ScheduleErrorEditTable';
import RegisterSideModal from 'components/domain/sideModal/RegisterSideModal';
import useSideModal from 'lib/hook/util/useSideModal';
import { CreateScheduleStatus, ScheduleItem } from 'types/view/task';

import * as S from './styles';

function ScheduleErrorEditPage(): React.ReactElement {
	const { showSideModal } = useSideModal();
	const [checkStatus, setCheckStatus] = useState<string[]>([]);
	const navigate = useNavigate();

	const [currentFilter, setCurrentFilter] = useState('all');

	const dummy = useMemo(
		() => [
			{
				id: v4(),
				name: '장투영',
				userNumber: 381110,
				serviceType: '방문요양',
				status: CreateScheduleStatus.ERROR,
				items: [
					{
						days: [0, 1],
						startTime: '12:00',
						endTime: '14:00',
					},
					{
						days: [3, 5],
						startTime: '14:00',
						endTime: '16:00',
					},
				],
			},
			{
				id: v4(),
				name: '장삼영',
				userNumber: 381112,
				serviceType: '방문요양',
				status: CreateScheduleStatus.ERROR,
				items: [
					{
						days: [0, 1],
						startTime: '12:00',
						endTime: '14:00',
					},
					{
						days: [3, 5],
						startTime: '14:00',
						endTime: '16:00',
					},
				],
			},
		],
		[],
	);
	const handleClickRow = (schedule: ScheduleItem) =>
		showSideModal(<RegisterSideModal item={schedule} />);

	const handleClickApproval = () => {
		navigate(RouterPath.approvalDocument().new.key);
	};

	const handleClickCheck = (scheduleId: string) => {
		const exist = checkStatus.find((id) => id === scheduleId);
		if (exist) {
			setCheckStatus(checkStatus.filter((id) => id !== scheduleId));
		} else {
			setCheckStatus(checkStatus.concat(scheduleId));
		}
	};

	const handleToggleAllCheck = (checked: boolean) => {
		if (checked) {
			setCheckStatus(dummy.map((e) => e.id));
		} else {
			setCheckStatus([]);
		}
	};

	const renderCustomFilter = () => (
		<S.CustomFilterContainer>
			<S.CustomFilterChip
				onClick={() => setCurrentFilter('all')}
				$isActive={currentFilter === 'all'}>
				전체
			</S.CustomFilterChip>
		</S.CustomFilterContainer>
	);

	const isNextStepReady = dummy.length === checkStatus.length;

	return (
		<CRTable.BackBlind
			stickyHeader={
				<S.Header>
					<S.HeaderLeft>
						<S.Title>일정 오류 수정 - 23년 5월 일정 생성 (120건)</S.Title>
						<S.Duration>시작일: 2023.04.10 ~ 마감일: 2023.04.19 (D-13)</S.Duration>
					</S.HeaderLeft>
					<S.HeaderRight>
						<CRButton.Default disabled={!isNextStepReady} onClick={handleClickApproval}>
							결제 요청
						</CRButton.Default>
					</S.HeaderRight>
				</S.Header>
			}>
			<ScheduleErrorEditTable
				onClickCheck={handleClickCheck}
				onToggleAllCheck={handleToggleAllCheck}
				renderCustomFilter={renderCustomFilter()}
				currentFilter={[] as any}
				onClickRow={handleClickRow}
				checkStatus={checkStatus}
				items={dummy}
			/>
		</CRTable.BackBlind>
	);
}

export default ScheduleErrorEditPage;
