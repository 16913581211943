import styled from 'styled-components';

export const LeftSideComponentContainer = styled.div`
	padding: 2.4rem 2.8rem;
	box-sizing: border-box;
`;

export const MainComponentContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const HeaderComponentContainer = styled.div`
	margin-bottom: 1.6rem;
`;

export const BodyContainer = styled.div`
	flex: 1;
	overflow: overlay;
`;

export const ContentContainer = styled.div`
	width: 100%;
	max-width: 108rem;
	min-width: 80rem;
	padding: 0rem 2.4rem;
	padding-bottom: 2.4rem;
	box-sizing: border-box;
	margin: auto;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const FooterContentContainer = styled.div`
	width: 100%;
	max-width: 108rem;
	min-width: 80rem;
	padding: 0rem 2.4rem;
`;
