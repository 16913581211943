import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'lib/hook/auth/useAuth';

function ProtectedRouter({ children }: { children: JSX.Element }): React.ReactElement {
	const { isLoggedIn, passwordChangeNeed } = useAuth();
	const location = useLocation();

	if (passwordChangeNeed && location.pathname === '/new-password') {
		return children;
	}

	if (!isLoggedIn) {
		return <Navigate to='/sign-in' state={{ from: location }} />;
	}

	return children;
}

export default React.memo(ProtectedRouter);
