import styled from 'styled-components';

export const Container = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	padding: 1.2rem 2.4rem;
	box-sizing: border-box;
`;
