import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { InsuranceRequestTypeFormType } from 'types/view/insurance';
import { useCommonCodes } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import * as S from './styles';
import AcquirementHelp from './AcquirementHelp';
import LossHelp from './LossHelp';
import AcquirementCancelHelp from './AcquirementCancelHelp';
import ChangeChargeHelp from './ChangeChargeHelp';
import LossCancelHelp from './LossCancelHelp';

export enum InsuranceReqType {
	취득 = 'CMN073.10',
	상실 = 'CMN073.20',
	취득취소 = 'CMN073.30',
	상실취소 = 'CMN073.31',
	이직확인서 = 'CMN073.50',
	취득일자정정 = 'CMN073.60',
	상실일자정정 = 'CMN073.61',
	세무문서요청 = 'CMN073.70',
	신고금액변경 = 'CMN073.40',
	기타 = 'CMN073.80',
}

interface Props {
	context: UseFormReturn<InsuranceRequestTypeFormType, any, undefined>;
}

export default function RequestTypeForm({ context }: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN073'],
		},
		commonCodeAdapter,
	);

	return (
		<Accordion.Item value='신고 유형' asChild>
			<TaskAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<TaskAccordion.Trigger>신고 유형</TaskAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<TaskAccordion.Content>
						<S.FormContainer>
							<S.FormItemContainer
								style={{
									width: '57.2rem',
								}}>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='신고 유형'
											type='left-sub'
											isRequired
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.Selector
												onChangeValue={onChange}
												currentValue={value}
												items={commonCodes?.CMN073}
												placeholder='신고 유형 선택'
											/>
										</CRInputLabel>
									)}
									name='requestType'
									control={context.control}
								/>
							</S.FormItemContainer>
							{context?.watch('requestType')?.value === InsuranceReqType.취득 && (
								<AcquirementHelp />
							)}
							{context?.watch('requestType')?.value === InsuranceReqType.상실 && <LossHelp />}
							{context?.watch('requestType')?.value === InsuranceReqType.취득취소 && (
								<AcquirementCancelHelp />
							)}
							{context?.watch('requestType')?.value === InsuranceReqType.상실취소 && (
								<LossCancelHelp />
							)}
							{context?.watch('requestType')?.value === InsuranceReqType.신고금액변경 && (
								<ChangeChargeHelp />
							)}
						</S.FormContainer>
					</TaskAccordion.Content>
				</Accordion.Content>
			</TaskAccordion.Item>
		</Accordion.Item>
	);
}
