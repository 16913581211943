import React, { HTMLAttributes } from 'react';
import * as S from './styles';

function CRAccordionContent(
	{ children, ...rest }: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>,
	ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
	return (
		<S.Container ref={ref} {...rest}>
			{children}
		</S.Container>
	);
}

export default React.forwardRef(CRAccordionContent);
