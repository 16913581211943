import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import * as Select from '@radix-ui/react-select';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import Colors from 'common/colors';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import * as S from './styles';
import { integratedAssessmentDesirePhysicalConditionQuestions } from './constant';
import IntegratedAssessmentDesirePhysicalConditionFormHeader from './IntegratedAssessmentDesirePhysicalConditionFormHeader';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesirePhysicalConditionForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Description>
				상(전적인 도움), 중(수행도움), 하(준비/지켜보기 도움), 최하(혼자수행), -(해당없음)
			</S.Description>
			<S.Table>
				<IntegratedAssessmentDesirePhysicalConditionFormHeader
					showGenerateRequiredFields={showGenerateRequiredFields}
				/>
				<S.TableBody>
					{integratedAssessmentDesirePhysicalConditionQuestions.map((items, chunkIndex) => (
						<S.TableBodyRow>
							{items.map((item, index) => (
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => {
										// 초기값 셋팅
										if (!value) {
											onChange('하');
										}

										return (
											<>
												<S.TableBodyColumn>{chunkIndex + 1}</S.TableBodyColumn>
												<S.TableBodyColumn>{item.label}</S.TableBodyColumn>
												<S.TableBodyColumn>
													<S.TableContentContainer>
														<Select.Root
															name={item.key}
															value={value as string}
															// defaultValue='하'
															onValueChange={onChange}>
															<S.TriggerContainer>
																<Select.Trigger>{value}</Select.Trigger>
															</S.TriggerContainer>
															<Select.Content
																position='popper'
																alignOffset={-10}
																sideOffset={7}
																color={Colors.white}>
																<Select.Group>
																	<S.SelectItemContainer>
																		<Select.Item value='상'>상</Select.Item>
																		<Select.Item value='중'>중</Select.Item>
																		<Select.Item value='하'>하</Select.Item>
																		<Select.Item value='최하'>최하</Select.Item>
																		<Select.Item value='-'>-</Select.Item>
																	</S.SelectItemContainer>
																</Select.Group>
															</Select.Content>
														</Select.Root>
													</S.TableContentContainer>
												</S.TableBodyColumn>
											</>
										);
									}}
									name={item.key}
									control={form.control}
								/>
							))}
						</S.TableBodyRow>
					))}
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='신체상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesirePhysicalConditionForm;
