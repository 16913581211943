import { Alarm } from 'types/view/alarm';

export const AlarmMock: Alarm[] = [
	{
		allimId: 1,
		allimDesc: '수급자 장기요양등급 만료 n일 전입니다.',
		allimConfirmDate: '2023.08.16',
		allimTargetLinkList: [
			{
				allimTargetLinkId: 11,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '김케어',
			},
		],
		mainEmployee: 'OO센터 / 김주담 / 010-1234-1234',
		subEmployee: 'OO센터 / 김주담 / 010-1234-1234',
	},
	{
		allimId: 2,
		allimDesc: '수급자 장기요양등급 만료 n일 전입니다.',
		allimConfirmDate: '2023.08.15',
		allimTargetLinkList: [
			{
				allimTargetLinkId: 1,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '김케어 (500505)',
			},
			{
				allimTargetLinkId: 2,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '홍가가 (560231)',
			},
			{
				allimTargetLinkId: 3,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '홍가나 (560231)',
			},
			{
				allimTargetLinkId: 4,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '홍가다 (560231)',
			},
			{
				allimTargetLinkId: 5,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '홍가라 (560231)',
			},
			{
				allimTargetLinkId: 6,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '김요보 (560231)',
			},
			{
				allimTargetLinkId: 7,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '손현준 (560231)',
			},
			{
				allimTargetLinkId: 8,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '발현준 (560231)',
			},
		],
		mainEmployee: 'OO센터 / 김주담 / 010-1234-1234',
		subEmployee: 'OO센터 / 김주담 / 010-1234-1234',
	},
	{
		allimId: 3,
		allimDesc: '수급자 장기요양등급 만료 n일 전입니다.',
		allimConfirmDate: '2023.08.13',
		allimTargetLinkList: [
			{
				allimTargetLinkId: 99,
				allimTargetDivCd: 'asd',
				allimTargetId: 3,
				allimTargetLinkUrl: 'sds',
				allimTargetName: '김케어',
			},
		],
		mainEmployee: 'OO센터 / 김주담 / 010-1234-1234',
		subEmployee: 'OO센터 / 김주담 / 010-1234-1234',
	},
];
