import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	height: 8rem;
	width: 100%;
	flex-direction: row;
	align-items: center;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const LeftButtonContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 0.8rem;
`;

export const RightButtonContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 0.8rem;
`;
