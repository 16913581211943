import React, { Children, ReactNode, isValidElement, useState } from 'react';

interface FunnelProps<T extends readonly string[]> {
	step: T[number];
	children: ReactNode;
}

interface StepProps<T extends readonly string[]> {
	// eslint-disable-next-line react/no-unused-prop-types
	name: T[number];
	children?: ReactNode;
}

function Funnel<T extends readonly string[]>({ step, children }: FunnelProps<T>) {
	const validElement = Children.toArray(children)?.filter(isValidElement);
	const targetElement = validElement.find((child) => (child.props as StepProps<T>)?.name === step);

	if (!targetElement) {
		return null;
	}

	return targetElement;
}

function Step<T extends readonly string[]>({ children }: StepProps<T>) {
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

const useFunnel = <T extends readonly string[]>(defaultStep: T[number]) => {
	const [step, setStep] = useState<T[number]>(defaultStep);

	const FunnelElement = Object.assign(
		(props: Omit<FunnelProps<T>, 'step'>) => <Funnel step={step} {...props} />,
		{ Step: (props: StepProps<T>) => <Step<T> {...props} /> },
	);

	return [FunnelElement, setStep] as const;
};

export default useFunnel;
