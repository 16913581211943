import React from 'react';

import { useLocation } from 'react-router-dom';
import * as S from './styles';
import menu, { hiddenMenuPageList } from './constant';
import MyCenterLeftSideNavigationItem from './MyCenterLeftSideNavigationItem';

function MyCenterLayout({ children }: React.PropsWithChildren): React.ReactElement {
	const location = useLocation();
	const hideMenu = hiddenMenuPageList.includes(location.pathname);
	return (
		<S.Container>
			<S.ContentContainer>
				{!hideMenu && (
					<S.LeftContainer>
						{menu?.map((item) => (
							<MyCenterLeftSideNavigationItem item={item} />
						))}
						{/* <CRLeftSideNavigation items={menu} /> */}
					</S.LeftContainer>
				)}
				<S.MainContentContainer>
					<S.TaskContainer>{children}</S.TaskContainer>
				</S.MainContentContainer>
			</S.ContentContainer>
		</S.Container>
	);
}

export default React.memo(MyCenterLayout);
