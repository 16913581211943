import Colors from 'common/colors';
import { css, styled } from 'styled-components';

export const Container = styled.div<{ $isDisabled?: boolean }>`
	display: flex;
	justify-content: space-between;

	${(props) =>
		props.$isDisabled &&
		css`
			& > img {
				${(props) => props.theme.filters.gray60};
				cursor: no-drop;
			}
		`};
`;

export const Text = styled.p<{ $isDisabled?: boolean; $color?: keyof typeof Colors }>`
	${(props) => props.theme.typography.label};
	color: ${(props) =>
		props?.$color ? props.theme.colors[props.$color] : props.theme.colors.gray00};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray99};
			color: ${(props) => props.theme.colors.gray60}; ;
		`};
`;
