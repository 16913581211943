import { CRStatusConfig } from 'types/view/base';

export enum VisitStatusCode {
	방문예정 = 'CMN184.10',
	방문완료 = 'CMN184.20',
	미방문 = 'CMN184.11',
}

export const VISIT_STATE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: VisitStatusCode.방문예정,
		label: '방문예정',
		color: 'yellow',
	},
	{
		key: VisitStatusCode.방문완료,
		label: '방문완료',
		color: 'green',
	},
	{
		key: VisitStatusCode.미방문,
		label: '미방문',
		color: 'red',
	},
];

export const salaryOfferVisitOption = [
	{
		label: '방문',
		value: true,
	},
	{
		label: '미방문',
		value: false,
	},
];
