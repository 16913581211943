import React from 'react';
import Assets from 'assets';
import Colors from 'common/colors';
import * as S from './styles';
import CRButton from '../CRButton';
import CRSpinner from '../CRSpinner';
import { ButtonSize } from '../CRButton/type';

interface Props {
	size?: ButtonSize;
	title?: string;
	isProgress?: boolean;
	onClickSync?: () => void;
	onClickSyncHistory?: () => void;
	syncDisabled?: boolean;
	syncHistoryDisabled?: boolean;
	showSyncButton?: boolean;
	showHistoryButton?: boolean;
	leftIcon?: string;
}

export default function CRSyncMenu({
	size = 'default',
	title,
	isProgress,
	onClickSync,
	onClickSyncHistory,
	syncDisabled,
	syncHistoryDisabled,
	showSyncButton = true,
	showHistoryButton = true,
	leftIcon = Assets.icon.refresh,
}: Props) {
	return (
		<S.Container>
			{showSyncButton && (
				<CRButton.Default
					disabled={syncDisabled || isProgress}
					style={{
						padding: '0 2.4rem 0 1.6rem',
						background: isProgress ? Colors.gray95 : undefined,
						color: isProgress ? Colors.gray60 : undefined,
					}}
					type='outlined'
					palette='gray'
					size='default'
					onClick={onClickSync}>
					<S.SyncStateButton>
						{isProgress ? <CRSpinner /> : <S.Icon src={leftIcon} />}
					</S.SyncStateButton>
					{title}
				</CRButton.Default>
			)}
			{showHistoryButton && (
				<CRButton.Default
					disabled={syncHistoryDisabled}
					style={{
						padding: '0 0.8rem',
					}}
					type='outlined'
					palette='gray'
					size='default'
					onClick={onClickSyncHistory}>
					<S.Icon src={Assets.icon.accessTimeBlack} />
				</CRButton.Default>
			)}
		</S.Container>
	);
}
