import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';
import React, { useMemo } from 'react';
import ApprovalProgressTab from './ApprovalProgressTab';

export default function ApprovalPage() {
	const approvalTab = useMemo(() => RouterPath.approvalTab(), []);

	return (
		<div>
			<CRTab.Default
				breadCrumb='전자결재'
				defaultActiveKey={approvalTab.progress.key}
				items={[
					{
						label: `${approvalTab.progress.label}(00건)`,
						key: approvalTab.progress.key,
						children: <ApprovalProgressTab />,
					},
					{
						label: approvalTab.complete.label,
						key: approvalTab.complete.key,
						children: <div>dksjd</div>,
					},
				]}
			/>
		</div>
	);
}
