import styled, { css } from 'styled-components';

export const Container = styled.div`
	position: relative;
	user-select: none;
`;

export const SearchResultContainer = styled.ul`
	width: 32rem;
	height: 28rem;
	overflow: auto;
	box-sizing: border-box;
`;

export const SearchResultItem = styled.li`
	padding: 0 1.6rem;
`;

export const CustomHeader = styled.div`
	padding: 0.8rem 1.6rem 0;
`;

export const Range = styled.div`
	display: flex;
	gap: 0.8rem;
	padding: 0 2.4rem;
	margin-top: 0.8rem;
	margin-bottom: 1.2rem;
`;

export const Title = styled.p`
	${(props) => props.theme.typography.labelB};
	white-space: nowrap;
	width: 100%;
	padding: 0 2.4rem;
	box-sizing: border-box;
`;

export const ChipLabel = styled.span`
	${(props) => props.theme.typography.body}
	white-space: nowrap;
	padding-left: 0.4rem;
`;

export const CloseIconContainer = styled.div<{ $showOptions?: boolean }>`
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.8rem;
	height: 1.8rem;
	margin: 0.7rem 0 0.7rem 0.4rem;
	& > img {
		width: 100%;
		height: 100%;
	}
`;

export const DefaultOption = styled.div<{
	$isActive?: boolean;
	disabled?: boolean;
	$showOptions?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: fit-content;
	height: 3.2rem;
	padding: 0.4rem 1.2rem 0.4rem 0.8rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};
	user-select: none;
	cursor: pointer;
	${(props) =>
		!props.$isActive &&
		!props.disabled &&
		css`
			&:hover {
				background: ${(props) => props.theme.colors.gray99};
			}

			&:active {
				background: ${(props) => props.theme.colors.gray95};
			}
		`};

	${(props) =>
		props.$isActive &&
		css`
			background: ${(props) => props.theme.colors.primary95};
			border: 0.1rem solid ${(props) => props.theme.colors.primary90};
			& > ${ChipLabel} {
				color: ${(props) => props.theme.colors.primary10};
			}
		`};

	${(props) =>
		props.$showOptions &&
		css`
			& > ${CloseIconContainer} {
				transform: rotate(180deg);
			}
		`};

	${(props) =>
		props.disabled &&
		css`
			background: ${(props) => props.theme.colors.gray90};
			border: 0.1rem solid transparent;
			& > ${ChipLabel} {
				color: ${(props) => props.theme.colors.gray60};
			}
		`};

	width: fit-content;
	min-width: 9.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;
