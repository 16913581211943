import styled, { css } from 'styled-components';

export const SelectionNone = css`
	${(props) => props.theme.noneSelection}
`;

export const Container = styled.div<{ $type: 'default' | 'day' | 'time' }>`
	display: flex;
	width: 100%;

	min-width: ${(props) => (props.$type === 'time' ? 'auto' : '43rem')};

	flex-direction: column;
	background: ${(props) => props.theme.colors.gray100};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 1.6rem;
	box-sizing: border-box;
	border-radius: ${(props) => props.theme.radius.radius01};
	overflow: hidden;

	${(props) =>
		['time', 'days'].includes(props.$type) &&
		css`
			padding: 0;
			border: 0;
		`}
`;

export const DayOfWeeksContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const DayOfWeekContainer = styled.div<{ $isSelected?: boolean; $isDisabled?: boolean }>`
	cursor: pointer;
	${(props) => props.theme.typography.label}
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 1.2rem;
	${SelectionNone}
	${(props) =>
		props.$isSelected
			? css`
					border: 0.1rem solid
						${props.$isDisabled ? props.theme.colors.gray60 : props.theme.colors.primary60};
					color: ${props.theme.colors.gray100};
					background: ${props.$isDisabled
						? props.theme.colors.gray60
						: props.theme.colors.primary60};
			  `
			: css`
					border: 0.1rem solid ${props.theme.colors.gray90};
					color: ${props.theme.colors.gray10};
					background: ${props.theme.colors.gray100};
			  `}
`;

export const WeekContainer = styled.div<{ $isSelected?: boolean; $isDisabled?: boolean }>`
	cursor: pointer;
	${(props) => props.theme.typography.label}
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.9rem 0.75rem;
	height: 2.4rem;
	border-radius: 1.2rem;
	white-space: nowrap;
	${SelectionNone}
	${(props) =>
		props.$isSelected
			? css`
					border: 0.1rem solid
						${props.$isDisabled ? props.theme.colors.gray60 : props.theme.colors.primary60};
					color: ${props.theme.colors.gray100};
					background: ${props.$isDisabled
						? props.theme.colors.gray60
						: props.theme.colors.primary60};
			  `
			: css`
					border: 0.1rem solid ${props.theme.colors.gray90};
					color: ${props.theme.colors.gray10};
					background: ${props.theme.colors.gray100};
			  `}
`;

export const TimeContainer = styled.div<{ $isDisabled?: boolean }>`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	padding: 1.4rem 1.6rem;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
	overflow: hidden;

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray95};
		`};
`;

export const TimeTextDivider = styled.span`
	color: ${(props) => props.theme.colors.gray60};
`;

export const TimeTextInput = styled.input`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	width: 6rem;
	text-align: center;
	border: none;
	outline: none;
	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const TimeIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const ActionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2.4rem;
`;
