import { styled } from 'styled-components';

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 0 2.4rem 1.6rem;
`;

export const SubFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	/* margin-bottom: 5rem; */
`;

export const ResignDateContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const SubTitle = styled.p`
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray10};
`;

export const CheckBoxContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;

	& > span {
		${(props) => props.theme.typography.body};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
