import { useLayoutEffect, useState } from 'react';
import useKakaoGeolocation from './useKakaoLocation';

export type DaumPostcodeData = {
	address: string;
	sigungu: string;
	sido: string;
	zonecode: string;
	latitude?: number;
	longitude?: number;
	roadname: string;
	bname: string;
};

declare global {
	type DaumPostcodeSearchData = {
		q?: string;
		count?: boolean;
	};

	type DaumPostcodeOption = {
		oncomplete?: (data: DaumPostcodeData) => void;
		onclose?: () => void;
		onresize?: () => void;
		onsearch?: (data: DaumPostcodeSearchData) => void;
		width?: number | string;
		height?: number | string;
		animation?: boolean;
		focusInput?: boolean;
		autoMapping?: boolean;
	};

	type PostcodeOperator = {
		open: () => void;
		embed: (wrap: Element, options: { q: string; autoClose: boolean }) => void;
	};

	interface Window {
		daum: {
			Postcode: new (options?: DaumPostcodeOption) => PostcodeOperator;
			postcode: {
				load: (fn: () => void) => void;
			};
		};
	}
}

const useDaumPostcode = () => {
	const [resPostCode, setResPostCode] = useState<DaumPostcodeData>();
	const openPostcodeModal = () => {
		window.daum.postcode.load(() => {
			const postcode = new window.daum.Postcode({
				oncomplete: (data) => {
					setResPostCode(data);
				},
			});
			postcode.open();
		});
	};

	const reset = () => {
		setResPostCode(undefined);
	};

	const loadCDN = () => {
		const ID = 'daum-postcode';
		const CDN = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

		const exist = document.getElementById(ID);

		if (!exist) {
			const script = document.createElement('script');
			script.src = CDN;
			script.id = ID;
			document.body.append(script);
		}
	};

	const clearCDN = () => {
		const ID = 'daum-postcode';

		const cdnScript = document.getElementById(ID);
		cdnScript?.remove();
	};

	useLayoutEffect(() => {
		loadCDN();

		return () => {
			clearCDN();
		};
	}, []);

	return { resPostCode, openPostcodeModal, reset };
};

export default useDaumPostcode;
