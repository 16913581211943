import React from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface Props {
	onSubmit?: () => void;
	onClose?: () => void;
	onCancel?: () => void;
}

function RecipientContractRenewDialog({ onSubmit, onClose, onCancel }: Props): React.ReactElement {
	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>갱신</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={onClose} />
			</S.HeaderContainer>
			<S.BodyContainer>
				인정등급 만료나 갱신으로 인해 현재 이용중인 모든 급여를 종료하고 새로운 계약을 추가하여
				갱신합니다.
			</S.BodyContainer>
			<S.FooterContainer>
				<CRButton.Default palette='gray' type='text' onClick={onCancel}>
					취소
				</CRButton.Default>
				<CRButton.Default onClick={onSubmit}>추가</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default React.memo(RecipientContractRenewDialog);
