const Elevation = {
	elevation01:
		'0rem 0.8rem 0.8rem rgba(0, 0, 0, 0.08), 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.0675), 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.05), 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.025)',
	elevation02:
		'0rem 1.6rem 1.6rem rgba(0, 0, 0, 0.08), 0rem 0.8rem 0.8rem rgba(0, 0, 0, 0.0675), 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.05), 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.025)',
	elevation03:
		'0rem 2.4rem 2.4rem rgba(0, 0, 0, 0.08), 0rem 1.2rem 1.2rem rgba(0, 0, 0, 0.0675), 0rem 0.8rem 0.8rem rgba(0, 0, 0, 0.05), 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.025)',
	elevation04:
		'0rem 6.4rem 6.4rem rgba(0, 0, 0, 0.08), 0rem 3.2rem 3.2rem rgba(0, 0, 0, 0.0675), 0rem 1.6rem 1.6rem rgba(0, 0, 0, 0.05), 0rem 0.8rem 0.8rem rgba(0, 0, 0, 0.025)',
};

export default Elevation;
