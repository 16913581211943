import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';

import Assets from 'assets';
import CRDropdown from 'components/base/CRDropdown';
import dayjs from 'dayjs';
import CRButton from 'components/base/CRButton';
import Draggable from 'react-draggable';
import { FilterType } from 'types/view/filter';
import * as S from './styles';

interface IProps<T extends { label: string; value: any }> {
	currentValue?: T[];
	placeholder?: string;
	filterKey?: string;
	onChangeValue?: (item: T, key: string, filterType: FilterType) => void;
}

function MonthCalendarChip<T extends { label: string; value: any }>({
	currentValue = [],
	placeholder = '',
	filterKey = '',
	onChangeValue,
}: IProps<T>): React.ReactElement {
	const [showOptions, setShowOptions] = useState(false);
	const [date, setDate] = useState<Date | null>(currentValue?.[0]?.value ?? null);
	const [rangeDate, setRangeDate] = useState(new Date());

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);

	const isActive = useMemo(() => currentValue?.[0]?.value, [currentValue]);

	const handleToggleOptions = () => {
		setShowOptions((prev) => !prev);
	};

	const handleStart = () => console.log('here');

	const renderText = () => {
		let label = '';
		if (currentValue?.length && currentValue[0]?.value) {
			const result = `${placeholder} : ${dayjs(currentValue[0].value).format('YYYY.MM')}`;
			label = result;
		} else {
			label = placeholder;
		}
		return label;
	};

	const isValidDate = useMemo(() => dayjs(date).isValid() ?? false, [date]);

	const onClickApply = () => {
		if (isValidDate) {
			onChangeValue?.(
				{
					label: filterKey,
					value: date,
				} as T,
				filterKey,
				'month_calendar',
			);
			setShowOptions(false);
		}
	};

	const onClickInit = (e: MouseEvent) => {
		e.stopPropagation();
		onChangeValue?.(
			{
				label: filterKey,
				value: undefined,
			} as T,
			filterKey,
			'calendar',
		);
		setDate(null);
	};

	const renderMonthCalendar = () => {
		const year = dayjs(rangeDate).get('year');
		const months = Array.from({ length: 12 })
			.fill(null)
			.map((e, i) => dayjs(rangeDate).set('month', i).toDate());
		const caringStartDate = dayjs('2020.01.01').toDate();

		const onClickItem = (currentItemDate: Date) => {
			const isExist = date && dayjs(date).isSame(dayjs(currentItemDate));
			setDate(isExist ? null : currentItemDate);
		};
		return (
			<S.MonthYearWrapper>
				<S.MonthYearHeader>
					<S.MonthYearIcon
						src={Assets.icon.keyboardArrowLeft}
						alt='이전달'
						onClick={() => setRangeDate(dayjs(rangeDate).subtract(1, 'year').toDate())}
					/>
					<S.YearText>{year}</S.YearText>

					<S.MonthYearIcon
						src={Assets.icon.keyboardArrowRight}
						alt='다음달'
						onClick={() => setRangeDate(dayjs(rangeDate).add(1, 'year').toDate())}
					/>
				</S.MonthYearHeader>
				<S.MonthYearContainer>
					{[0, 1, 2].map((index) => (
						<S.MonthYearWeek key={index}>
							{months.slice(index * 4, index * 4 + 4).map((month) => {
								const isActive = !!(
									dayjs(month).format('YYYY.MM') === dayjs(date).format('YYYY.MM')
								);
								return (
									<S.MonthMonthYearItem
										key={String(month)}
										$isActive={isActive}
										onClick={() => onClickItem(month)}>
										{`${dayjs(month).get('month') + 1}월`}
									</S.MonthMonthYearItem>
								);
							})}
						</S.MonthYearWeek>
					))}
				</S.MonthYearContainer>
			</S.MonthYearWrapper>
		);
	};

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!filterRef.current?.contains(e.target as Node)) {
				setShowOptions(false);
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, []);

	useEffect(() => {
		if (!showOptions) {
			setDate(null);
		} else if (currentValue?.[0]?.value) {
			setDate(currentValue?.[0].value);
		}
	}, [showOptions]);

	return (
		<S.Container ref={filterRef}>
			<S.DefaultOption
				$showOptions={showOptions}
				$isActive={isActive}
				onClick={handleToggleOptions}
				disabled={false}>
				<S.ChipLabel>{renderText()}</S.ChipLabel>
				{isActive ? (
					<S.CloseIconContainer onClick={onClickInit}>
						<img src={Assets.icon.closeCircle} alt='keyboardArrowBottom' />
					</S.CloseIconContainer>
				) : (
					<S.ArrowIconContainer src={Assets.icon.keyboardArrowBottom} alt='keyboardArrowBottom' />
				)}
			</S.DefaultOption>

			{showOptions && (
				<Draggable onStart={handleStart}>
					<CRDropdown style={{ width: 'fit-content' }}>
						<S.CalendarContainer ref={datePickerRef}>{renderMonthCalendar()}</S.CalendarContainer>
						<S.ButtonSection>
							<CRButton.Default type='text' palette='gray' onClick={handleToggleOptions}>
								취소
							</CRButton.Default>
							<CRButton.Default
								type='filled'
								palette='primary'
								disabled={!isValidDate}
								onClick={onClickApply}>
								적용
							</CRButton.Default>
						</S.ButtonSection>
					</CRDropdown>
				</Draggable>
			)}
		</S.Container>
	);
}

export default MonthCalendarChip;
