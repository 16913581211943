import styled from 'styled-components';

type JustifyContent =
	| 'flex-start'
	| 'flex-end'
	| 'center'
	| 'space-between'
	| 'space-around'
	| 'space-evenly';

type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

interface FlexContainerProps {
	justify?: JustifyContent;
	align?: AlignItems;
	direction?: FlexDirection;
	gap?: string;
	wrap?: FlexWrap;
	margin?: string;
	padding?: string;
	width?: string;
	height?: string;
}

interface FlexItemProps {
	flex?: string;
	shrink?: number;
	grow?: number;
	basis?: string;
}

// FlexContainer 스타일 컴포넌트
export const FlexContainer = styled.div<FlexContainerProps>`
	display: flex;
	justify-content: ${(p) => p.justify || 'flex-start'};
	align-items: ${(p) => p.align || 'stretch'};
	flex-direction: ${(p) => p.direction || 'row'};
	gap: ${(p) => p.gap || '0'};
	flex-wrap: ${(p) => p.wrap || 'nowrap'};
	margin: ${(p) => p.margin};
	padding: ${(p) => p.padding};
	width: ${(p) => p.width};
	height: ${(p) => p.height};
`;

// FlexItem 스타일 컴포넌트
export const FlexItem = styled.div<FlexItemProps>`
	flex: ${(p) => p.flex || '1'};
	flex-shrink: ${(p) => p.shrink || 1};
	flex-grow: ${(p) => p.grow || 0};
	flex-basis: ${(p) => p.basis || 'auto'};
`;
