import styled, { css } from 'styled-components';

export const Container = styled.div`
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.4rem;
	overflow: hidden;
`;

export const Table = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-style: hidden;
`;

export const TableHeader = styled.thead`
	height: 3.3rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableBody = styled.tbody``;

export const TableHeaderColumn = styled.th<{ $isSortable?: boolean }>`
	height: 3.3rem;
	z-index: 5;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
	text-align: left;
	padding: 0rem 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:first-of-type {
		border-left: none;
	}
`;

export const TableBodyRow = styled.tr`
	cursor: pointer;
	height: 3.3rem;
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray00};
	white-space: nowrap;
	box-sizing: border-box;
	vertical-align: middle;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0rem 0.8rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:first-of-type {
		border-left: none;
	}
`;

export const FileNameText = styled.a`
	text-decoration: underline;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const BaseBadge = styled.span`
	width: 1rem;
	height: 1rem;
	border-radius: 0.5rem;
`;

export const DoneBadge = styled(BaseBadge)`
	background-color: ${(props) => props.theme.colors.green};
`;

export const PendingBadge = styled(BaseBadge)`
	background-color: ${(props) => props.theme.colors.primary60};
`;

export const StatusContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const StatusText = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
`;
