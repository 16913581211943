import React from 'react';

import CRTab from 'components/base/CRTab';
import MessageTab from './MessageTab';
import ReservationMessageTab from './ReservationMessageTab';
import ESignMessageTab from './ESignMessageTab';

export default function RecipientMessageTab() {
	return (
		<CRTab.Detail
			defaultActiveKey='history'
			menuContainerStyle={{ padding: '0rem 1.6rem', gap: '2.4rem' }}
			items={[
				{
					label: '메시지',
					key: 'history',
					children: <MessageTab />,
				},
				{
					label: '전자서명',
					key: 'eSign',
					children: <ESignMessageTab />,
				},
				{
					label: '예약',
					key: 'reservation',
					children: <ReservationMessageTab />,
				},
			]}
		/>
	);
}
