import React, { useCallback } from 'react';

import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import { useCommonCodes } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import { EmployeeService } from 'types/view/employee';

import dayjs from 'dayjs';
import { RECIPIENT_SERVICE_HEADER_CONFIG, EMPLOYEE_SERVICE_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: EmployeeService[];
	onClick?: (item: EmployeeService) => void;
	onClickWithdrawal?: (item: EmployeeService) => void;
}

function EmployeeServiceTable({
	items = [],
	onClick,
	onClickWithdrawal,
}: Props): React.ReactElement {
	const {
		data: commonCodes = {
			CMN079: [],
			CMN136: [],
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN079', 'CMN136', 'CMN006'] }, commonCodeAdapter);

	const Badge = useCallback(
		(children: string) => <CRStatus options={EMPLOYEE_SERVICE_STATUS_CONFIG}>{children}</CRStatus>,
		[],
	);

	const ActionButton = useCallback((id: number, item?: EmployeeService) => {
		if (!item) return <div />;
		return (
			<S.ButtonContainer>
				{/* 하드코딩 */}
				{item.status !== 'CMN079.90' && (
					<CRButton.Default size='xSmall' type='outlined' onClick={() => onClickWithdrawal?.(item)}>
						해지
					</CRButton.Default>
				)}
			</S.ButtonContainer>
		);
	}, []);

	const ServiceTypeComponent = useCallback(
		(status: string) => (
			<div>{commonCodes.CMN136.find((item) => item.value === status)?.label}</div>
		),
		[commonCodes],
	);

	const renderContractedDate = useCallback(
		(contractedDate: string) => (
			<S.TextBox>{contractedDate ? dayjs(contractedDate).format('YYYY.MM.DD') : ''}</S.TextBox>
		),
		[commonCodes],
	);

	const renderPeriodOfUse = useCallback(
		(periodOfUse: string) => {
			if (!periodOfUse) return '';
			const [start, end] = periodOfUse.split('~');
			let duration = `${dayjs(start.trim()).format('YYYY.MM.DD')}`;
			if (end?.trim()) {
				duration += ` - ${dayjs(end.trim()).format('YYYY.MM.DD')}`;
			} else {
				duration += ` ~ `;
			}
			return <S.TextBox>{duration}</S.TextBox>;
		},
		[commonCodes],
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={RECIPIENT_SERVICE_HEADER_CONFIG} offset={1} disableBackBlind />
				{items.length ? (
					<CRTable.Body>
						{items.map((item, index) => (
							<CRTable.Row
								key={item.id}
								item={{ ...item, index: index + 1 }}
								toolTipKey={['employee', 'manager']}
								renderKeys={[
									'index',
									'status',
									'contractTypeCdNm',
									'service',
									'contractedDate',
									'periodOfUse',
									'recipient',
									'center',
									'manager',
									'id',
								]}
								customRender={{
									status: Badge,
									id: ActionButton,
									serviceType: ServiceTypeComponent,
									contractedDate: renderContractedDate,
									periodOfUse: renderPeriodOfUse,
								}}
								customStyle={{
									index: {
										textAlign: 'right',
									},
								}}
								onClick={onClick}
							/>
						))}
					</CRTable.Body>
				) : null}
			</CRTable.Root>
			{!items.length && <CRTable.Empty>급여 정보가 없습니다.</CRTable.Empty>}
		</S.Container>
	);
}

export default EmployeeServiceTable;
