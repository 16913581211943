import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import Assets from 'assets';
import dayjs from 'dayjs';
import { regex } from 'lib';
import * as S from './styles';
import CRInput from '..';

type TimeAttribute = {
	start: string;
	end: string;
	date: string;
};

interface Props {
	disable?: {
		date?: boolean;
		start?: boolean;
		end?: boolean;
	};
	error?: {
		date?: boolean;
		start?: boolean;
		end?: boolean;
		message?: string;
	};
	hide?: {
		date?: boolean;
		start?: boolean;
		end?: boolean;
	};
	date?: string;
	startTime?: string;
	placeholder?: {
		start: string;
		end: string;
	};
	endTime?: string;
	maxLength?: 4 | 6;
	onChange?: (date: string, start: string, end: string) => void;
}

function CRDateTimePicker({
	disable = {
		date: false,
		start: false,
		end: false,
	},
	error = {
		date: false,
		start: false,
		end: false,
		message: '',
	},
	hide = {
		date: false,
		start: false,
		end: false,
	},
	maxLength = 4,
	onChange,
	date,
	startTime,
	endTime,
	placeholder,
}: Props): React.ReactElement {
	const [time, setTime] = useState<TimeAttribute>({
		start: '',
		end: '',
		date: dayjs().format('YYYYMMDD'),
	});

	const onChangeDate = (date: Date) => {
		setTime({
			...time,
			date: dayjs(date).format('YYYYMMDD'),
		});
		onChange?.(dayjs(date).format('YYYYMMDD'), time.start, time.end);
	};

	const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const numericOnly = e.target.value.replace(/\D/g, '');
		let formattedTime = numericOnly;
		const hour = Number(numericOnly.substring(0, 2)) < 24 ? numericOnly.substring(0, 2) : 23;
		const minute = Number(numericOnly.substring(2, 4)) < 60 ? numericOnly.substring(2, 4) : 59;
		const second = Number(numericOnly.substring(4, 6)) < 60 ? numericOnly.substring(4, 6) : 59;

		if (numericOnly.length > 1) {
			formattedTime = `${hour}`;
		}
		if (numericOnly.length > 2) {
			formattedTime = `${hour}:${minute}`;
		}
		if (numericOnly.length > 4) {
			formattedTime = `${hour}:${minute}:${second}`;
		}

		setTime({
			...time,
			[e.target.id]: formattedTime,
		});

		if (e.target.id === 'start') {
			onChange?.(dayjs(date).format('YYYYMMDD'), formattedTime, time.end);
		} else {
			onChange?.(dayjs(date).format('YYYYMMDD'), time.start, formattedTime);
		}
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const isNumber = regex.number.test(String(e.key));
		const isFunctionKey = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key);

		if (!isFunctionKey && !isNumber) {
			e.preventDefault();
			return;
		}
	};

	useEffect(() => {
		setTime({
			date: date ?? dayjs().format('YYYYMMDD'),
			start: startTime ?? '',
			end: endTime ?? '',
		});
	}, [date, startTime, endTime]);

	const timeInputMaxLength = maxLength + Math.floor(maxLength / 2) - 1;
	const defaultPlaceholder = Array.from({ length: maxLength / 2 })
		.fill('00')
		.join(':');

	return (
		<>
			<S.Container>
				<S.SectionContainer>
					{hide.date ?? (
						<S.DateContainer>
							<CRInput.DatePicker
								onChangeValue={onChangeDate}
								disabled={disable?.date}
								value={dayjs(time.date).toDate()}
							/>
						</S.DateContainer>
					)}
				</S.SectionContainer>
				<S.SectionContainer>
					{hide.start ?? (
						<S.InputContainer
							$isDisable={disable?.start}
							$hasError={!!(error.message && error.start)}>
							<S.Input
								id='start'
								onChange={onChangeInput}
								onKeyDown={onKeyDown}
								disabled={disable?.start}
								value={time.start}
								maxLength={timeInputMaxLength}
								placeholder={placeholder?.start || defaultPlaceholder}
							/>
							<S.Icon src={Assets.icon.accessTimeBlack} alt='accessTime' />
						</S.InputContainer>
					)}
					{hide.end ?? (
						<>
							~
							<S.InputContainer
								$isDisable={disable?.end}
								$hasError={!!(error.message && error.end)}>
								<S.Input
									id='end'
									onChange={onChangeInput}
									onKeyDown={onKeyDown}
									disabled={disable?.end}
									value={time.end}
									placeholder={placeholder?.end || defaultPlaceholder}
									maxLength={timeInputMaxLength}
								/>
								<S.Icon src={Assets.icon.accessTimeBlack} alt='accessTime' />
							</S.InputContainer>
						</>
					)}
				</S.SectionContainer>
			</S.Container>
			{error?.message && <S.ErrorMessage>{error.message}</S.ErrorMessage>}
		</>
	);
}

export default CRDateTimePicker;
