import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: calc(100vh - 5.6rem);
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

export const BodyContainer = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	background-color: ${(props) => props.theme.colors.gray99};
`;

export const ContentContainer = styled.div`
	width: 108rem;
	height: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
`;

export const DetailContainer = styled.div`
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.white};
	padding: 2.4rem;
	box-sizing: border-box;
`;

export const DetailHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	padding-bottom: 1.6rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const DetailBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1.6rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const FileContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 1.6rem;
`;

export const DetailTitleContainer = styled.div``;

export const DetailInformationRow = styled.div`
	display: flex;
	align-items: center;
`;

export const DetailInformationKey = styled.div`
	${(props) => props.theme.typography.labelB}
	color: ${(props) => props.theme.colors.gray60};
	width: 6.4rem;
	min-width: 6.4rem;
	max-width: 6.4rem;
`;

export const DetailInformationValue = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray50};
	flex: 1;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
	margin-bottom: 1.6rem;
`;

export const Title = styled.h3`
	flex: 1;
`;

export const ManagerText = styled.div`
	${(props) => props.theme.typography.body}
`;

export const DateText = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60}
`;

export const DetailDescription = styled.div`
	${(props) => props.theme.typography.body}
	padding: 1.6rem 0rem;
	white-space: pre-wrap;
	padding-bottom: 8rem;
`;

export const OpinionContainer = styled.div`
	padding: 1.6rem;
	box-sizing: border-box;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const OpinionTitle = styled.div`
	${(props) => props.theme.typography.bodyB}
	color: ${(props) => props.theme.colors.gray50}
`;

export const OpinionDescription = styled.div`
	${(props) => props.theme.typography.body}
	white-space: pre-wrap;
`;
