import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const RESERVED_MESSAGE_HISTORY_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '15.1rem',
		label: '발송 예정 일시',
		sortKey: '발송 예정 일시',
	},
	{
		width: '42.4rem',
		label: '문서 유형',
		sortKey: '문서 유형',
	},
	{
		width: '100%',
		label: '제목',
		sortKey: '제목',
	},
	{
		width: '12rem',
		label: '예약 건수',
		sortKey: '예약 건수',
	},
	{
		width: '12rem',
		label: '발송자',
		sortKey: '발송자',
	},
	{
		width: '12rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
];

export const SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'yellow',
	},
	{
		key: '취소',
		label: '취소',
		color: 'red',
	},
];
