import { GetWorkExecuteLogDetailDTO } from 'types/api/workExecuteLog';
import { WorkExecuteLogData } from 'types/view/workExecuteLog';

export const workExecuteLogDetailAdapter = (
	item: GetWorkExecuteLogDetailDTO | null,
): WorkExecuteLogData | null => {
	if (!item) return null;

	const recipient = {
		recipientId: item.recipientId,
		recipientNm: item.recipientNm,
		genderCd: item.recipientGenderCd,
		genderNm: item.recipientGenderNm,
		birthDt: item.recipientBirthDt,
		longTermGradeCd: item.longTermGradeCd,
		longTermGradeNm: item.longTermGradeNm,
		longTermNo: item.longTermNo,
		longTermMajorChangeNo: item.longTermMajorChangeNo,
		longTermMinorChangeNo: item.longTermMinorChangeNo,
	};

	const recipientIa = {
		physicalConditionDesc: item.physicalConditionDesc || '',
		diseaseDesc: item.diseaseDesc || '',
		recogStateDesc: item.recogStateDesc || '',
		communicationDesc: item.communicationDesc || '',
		nutritionalStateDesc: item.nutritionalStateDesc || '',
		familyEnvironmentDesc: item.familyEnvironmentDesc || '',
		totalOpinionDesc: item.totalOpinionDesc || '',
	};

	const salaryOfferPlan = {
		salaryGoalDesc: item.salaryGoalDesc || '',
		needSalaryDesc: item.needSalaryDesc || '',
		provisionMethodDesc: item.provisionMethodDesc || '',
	};

	return {
		recipient,
		recipientIa,
		salaryOfferPlan,
		data: item,
	};
};
