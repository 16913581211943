import { styled } from 'styled-components';

export const Container = styled.div`
	padding: 0 2.4rem 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const TextContainer = styled.div`
	padding: 0 2.4rem 1.6rem;
`;
