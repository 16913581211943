import React from 'react';

import { ExtractTypeFromArray } from 'types/view/util';

import * as S from './styles';

interface Props<T extends unknown[]> {
	title: string;
	items: T;
	config?: {
		key: ExtractTypeFromArray<T>;
		label?: string | ((index: number) => string);
		render?: (item: ExtractTypeFromArray<T>) => React.ReactElement;
		width?: string;
	}[];
}

function InformationListSheet<T extends any[]>({
	title = '',
	items,
	config = [],
}: Props<T>): React.ReactElement {
	return (
		<S.Container>
			<S.Title>{title}</S.Title>
			{items.map((item, index: number) => (
				<S.Item key={Object.values(item).join('-')}>
					{config.map((option) => {
						const key = `${String(option.key)}-${index}`;
						const width = option.width ?? '4rem';
						const style = {
							minWidth: width,
							maxWidth: width,
						} as React.CSSProperties;
						const label = typeof option.label === 'function' ? option.label(index) : option.label;

						if (option.render) {
							return option.render(item);
						}

						return (
							<S.Information key={key}>
								<S.ItemLabel>{label}</S.ItemLabel>
								<S.ItemValue style={style}>{item[option.key]}</S.ItemValue>
							</S.Information>
						);
					})}
				</S.Item>
			))}
		</S.Container>
	);
}

export default React.memo(InformationListSheet);
