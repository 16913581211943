import React, { useMemo, useState, MouseEvent } from 'react';

import Assets from 'assets';

import * as S from './styles';
import CRCollapsibleDetailRow from './CRCollapsibleDetailRow';

type DetailKeys<T extends { details?: unknown[] }> = T['details'] extends Array<infer U>
	? keyof U
	: never;

interface Props<K, T extends { details?: K[] }> {
	item: T;
	renderKeys?: (keyof T | '')[];
	customRender?: {
		[key in keyof T]?: (value: T[key]) => React.ReactElement;
	};
	hasLoadableDetails?: boolean;
	detailRenderKeys?: (DetailKeys<T> | keyof K | '')[];
	detailCustomRender?: {
		[key in DetailKeys<T> | keyof K]?: (value: K[DetailKeys<T> | keyof K]) => React.ReactElement;
	};
	detailKey?: DetailKeys<T> | keyof K | '';
	loaderKeys?: (keyof T)[];
	defaultOpen?: boolean;
	dataLoader?: (param: { [key in keyof T]: any }) => Promise<K[]>;
	onClickDetail?: (detail: K) => void;
	onClick?: (item: T) => void;
}

function CRCollapsibleRow<K, T extends { details?: K[] }>({
	item,
	renderKeys = [],
	detailRenderKeys = [],
	customRender = {},

	hasLoadableDetails = false,
	detailCustomRender = {},
	detailKey = '',
	loaderKeys = [],
	defaultOpen = false,
	dataLoader,
	onClickDetail,
	onClick,
}: Props<K, T>): React.ReactElement {
	const [isLoading, setIsLoading] = useState(false);
	const [isFetched, setIsFetched] = useState(!dataLoader);
	const [details, setDetails] = useState<K[]>(item.details ?? []);
	const [isCollapsed, setIsCollapsed] = useState(defaultOpen);

	const shouldRenderDetail = useMemo(
		() => isCollapsed && !isLoading && !!details.length,
		[isCollapsed, isLoading, details.length],
	);

	const handleToggleCollapse = async (event: MouseEvent<HTMLImageElement>): Promise<void> => {
		try {
			event.stopPropagation();
			if (isFetched) {
				setIsCollapsed((prev) => !prev);
			} else {
				setIsLoading(true);
				const param = loaderKeys.reduce(
					(accumulate, key) => ({ [key]: item[key], ...accumulate }),
					{},
				) as { [key in keyof T]: any };
				const loadedData = await dataLoader?.(param);
				setIsFetched(true);
				setDetails(loadedData ?? []);
				setIsLoading(false);
				setIsCollapsed(true);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	return (
		<>
			<S.TableBodyRow onClick={() => onClick?.(item)}>
				<S.TableBodyColumn>
					<S.TableBodyIconContainer>
						{(hasLoadableDetails || !!details.length) && (
							<S.TableBodyToggleIcon
								src={isCollapsed ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom}
								alt={isCollapsed ? 'keyboardArrowTop' : 'keyboardArrowBottom'}
								onClick={handleToggleCollapse}
							/>
						)}
					</S.TableBodyIconContainer>
				</S.TableBodyColumn>
				{renderKeys.map((renderKey: keyof T | '') => {
					if (renderKey === '') {
						return <S.TableBodyColumnWithPadding key={renderKey.toString()} />;
					}
					return (
						<S.TableBodyColumnWithPadding key={renderKey.toString()}>
							{
								(customRender[renderKey]
									? customRender[renderKey]?.(item[renderKey])
									: item[renderKey as keyof T]) as React.ReactNode
							}
						</S.TableBodyColumnWithPadding>
					);
				})}
				<S.TableBodyColumnWithPadding />
			</S.TableBodyRow>
			{shouldRenderDetail &&
				details.map((detail: K, index: number) => (
					<CRCollapsibleDetailRow
						key={(detail[detailKey as DetailKeys<T> | keyof K] ?? index).toString()}
						item={detail}
						detailRenderKeys={detailRenderKeys}
						detailCustomRender={detailCustomRender}
						onClick={onClickDetail}
					/>
				))}
		</>
	);
}

export default React.memo(CRCollapsibleRow) as typeof CRCollapsibleRow;
