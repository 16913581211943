import { css, styled } from 'styled-components';

export const Container = styled.ul``;

export const ResultItem = styled.li`
	${(props) => props.theme.typography.body};
	display: flex;
	gap: 1rem;
	color: ${(props) => props.theme.colors.gray00};
	padding: 0.8rem 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const Status = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const StatusCircle = styled.div<{ $status: 'complete' | 'fail' }>`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 0.6rem;

	${(props) =>
		props.$status === 'complete' &&
		css`
			background: ${props.theme.colors.green};
		`}

	${(props) =>
		props.$status === 'fail' &&
		css`
			background: ${props.theme.colors.red};
		`}
`;
