import styled from 'styled-components';

export const ItemContainer = styled.div`
	padding: 1.6rem 0;
`;

export const DropdownItem = styled.div`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: center;
	height: fit-content;
	user-select: none;

	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.gray95};
	}
`;

export const IconContainer = styled.div`
	width: 4rem;
	height: 4rem;
	border-radius: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.primary95};
	}
`;
