import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import Assets from 'assets';
import { CheckOption } from 'components/base/Selections/type';
import Colors from 'common/colors';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import * as S from './styles';

interface IProps {
	options?: CheckOption[];
	value?: CheckOption[];
	disabled?: boolean;
	placeholder?: string;
	onChange?: (value: CheckOption[]) => void;
	showAllSelect?: boolean;
	fullSize?: boolean;
	containerStyle?: CSSProperties;
	activeColor?: boolean;
	renderLabel?: (item: CheckOption[]) => React.ReactElement;
}

function CRMultiSelector({
	value = [],
	options = [],
	disabled,
	placeholder = '',
	onChange,
	showAllSelect = false,
	fullSize = false,
	containerStyle,
	activeColor = true,
	renderLabel,
}: IProps): React.ReactElement {
	const [isAllSelect, setIsAllSelect] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const isChecked = useCallback(
		(item: { label: string; value: any }) => !!value?.find((e) => e?.value === item?.value),
		[value],
	);

	const renderText = () => {
		let label = <div />;
		if (value?.length && options?.length) {
			label = renderLabel ? (
				renderLabel(value)
			) : (
				<div>{value[0].label + (value.length > 1 ? ` 외 ${value.length - 1}건` : '')}</div>
			);
		} else {
			label = <S.PlaceHolderText>{placeholder}</S.PlaceHolderText>;
		}
		return label;
	};

	const onClickAllSelect = () => {
		if (isAllSelect && value?.length === options.length) {
			onChange?.([]);
		} else {
			onChange?.(options);
		}
	};

	const onClickItem = (option: CheckOption) => {
		const isChecked = !!value.find((value) => value.value === option.value);
		if (isChecked) {
			onChange?.(value.filter((val) => val.value !== option.value));
		} else {
			onChange?.([...value, option]);
		}
	};

	useEffect(() => {
		if (value?.length === options?.length) {
			setIsAllSelect(true);
		} else {
			setIsAllSelect(false);
		}
	}, [value, options]);

	return (
		<RdPopover
			openCallback={() => setShowOptions(true)}
			closeCallback={() => setShowOptions(false)}
			sideOffset={0}
			showDivider={false}
			borderShape='none'
			content={
				!disabled && (
					<S.ItemListContainer
						style={{
							width: fullSize ? wrapperRef.current?.clientWidth : 'auto',
							top: wrapperRef.current?.clientHeight ? wrapperRef.current.clientHeight / 2 : 0,
							left: 0,
							...containerStyle,
						}}>
						{showAllSelect && (
							<S.ItemContainer
								$isActive={isAllSelect}
								key='all'
								onClick={onClickAllSelect}
								style={{
									borderBottom: `0.1rem solid ${Colors.gray90}`,
								}}>
								<S.Input
									$isActive={isAllSelect}
									src={isAllSelect ? Assets.icon.checkSquareFilled : Assets.icon.checkSquare}
								/>

								<S.ChipLabel>전체 선택</S.ChipLabel>
							</S.ItemContainer>
						)}
						{options?.map((option) => (
							<S.ItemContainer
								$isActive={activeColor ? isChecked(option) : false}
								key={option.value}
								onClick={() => {
									onClickItem(option);
								}}>
								<S.Input
									$isActive={isChecked(option)}
									src={isChecked(option) ? Assets.icon.checkSquareFilled : Assets.icon.checkSquare}
								/>

								<S.ChipLabel>{option.label}</S.ChipLabel>
							</S.ItemContainer>
						))}
					</S.ItemListContainer>
				)
			}>
			<S.Container ref={wrapperRef}>
				<S.DefaultOption
					$showOptions={showOptions}
					$isActive={activeColor ? value?.length > 0 : false}
					$disabled={disabled}>
					<S.ChipLabel>{renderText()}</S.ChipLabel>
					{!disabled && (
						<S.ArrowIconContainer src={Assets.icon.keyboardArrowBottom} alt='keyboardArrowBottom' />
					)}
				</S.DefaultOption>
			</S.Container>
		</RdPopover>
	);
}

export default CRMultiSelector;
