import { CRTableHeadConfig } from 'types/view/base';

export const ACCOUNT_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '7rem',
		label: '종류',
		sortKey: '종류',
	},
	{
		width: '24rem',
		label: '유형',
		sortKey: '유형',
	},
	{
		width: '30rem',
		label: '메모',
		sortKey: '메모',
	},
	{
		width: '14rem',
		label: '입금처/출금처',
		sortKey: '입금처/출금처',
	},
	{
		width: '14rem',
		label: '입금액',
		sortKey: '입금액',
	},
	{
		width: '14rem',
		label: '출금액',
		sortKey: '출금액',
	},
	{
		width: '14rem',
		label: '잔액',
		sortKey: '잔액',
	},
	{
		width: '16rem',
		label: '계좌명',
		sortKey: '계좌명',
	},
	{
		width: '16rem',
		label: '이체 일시',
		sortKey: '이체 일시',
	},
];
