import { CRStatusConfig, CRTableHeadConfig } from '../../../../types/view/base';

export const STAFF_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.2rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '7rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '9.6rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '6.2rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8.5rem',
		label: '직무',
		sortKey: '직무',
	},
	{
		width: '100%',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];
