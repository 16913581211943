import React, { useMemo, useState } from 'react';

import CRTableHeader from 'components/base/CRTableHeader';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { CheckOption } from 'components/base/Selections/type';
import { Filter } from 'types/view/filter';
import CRSpinner from 'components/base/CRSpinner';
import CRButton from 'components/base/CRButton';
import Assets from 'assets';
import { useIssuableEDocPaperTypes, useRecipientEDocList } from 'lib/hook/react-query/query/edoc';
import dayjs from 'dayjs';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import RecipientDocumentTable from 'components/domain/table/RecipientDocumentTable';
import useDialog from 'lib/hook/util/useDialog';
import { RecipientDocumentIssueDialog } from 'components/domain/dialog/RecipientDocumentIssueDialog';
import CreateEDocDialog from 'components/domain/dialog/CreateEDocDialog';
import { Toast } from 'components/base/CRToast';
import * as S from './styles';

export default function RecipientDocumentTab() {
	const dialog = useDialog();
	const { data: eDocPaperCodes } = useIssuableEDocPaperTypes({
		paperMenu: 'recipient',
	});

	const [currentFilter, setCurrentFilter] = useState<{
		rangeFilter: CheckOption[];
		paperTypeCd: CheckOption[];
	}>({
		rangeFilter: [],
		paperTypeCd: [],
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const { currentRecipient } = useRecipientPage();
	const {
		data: recipientEDocList,
		isLoading,
		refetch,
	} = useRecipientEDocList({
		centerId: myAccountInfo?.centerId,
		recipientId: currentRecipient?.recipientId,
		paperStandardStartDate:
			currentFilter.rangeFilter?.[0]?.value?.start &&
			dayjs(currentFilter.rangeFilter[0].value.start).format('YYYYMMDD'),
		paperStandardEndDate:
			currentFilter.rangeFilter?.[0]?.value?.end &&
			dayjs(currentFilter.rangeFilter[0].value.end).format('YYYYMMDD'),
		paperTypeCds: currentFilter.paperTypeCd?.map((item) => item.value),
	});

	const handleRefetch = () => {
		refetch();
	};

	const durationFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'calendar',
				placeholder: '일시',
			} as Filter<CheckOption>),
		[],
	);

	const paperTypeFilter = useMemo(
		() =>
			({
				key: 'paperTypeCd',
				type: 'single',
				options: (eDocPaperCodes?.paperTypeCds || [])?.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '서류 유형',
			} as Filter<CheckOption>),
		[eDocPaperCodes],
	);

	const handleClickIssueButton = () => {
		if (!currentRecipient?.phoneNumber) {
			Toast.error('주보호자의 연락처가 존재하지 않습니다.');
			return;
		}
		dialog.showDialog(() => (
			<RecipientDocumentIssueDialog
				currentRecipient={currentRecipient}
				title='서류 발급'
				menuType='recipient'
				myAccountInfo={myAccountInfo}
			/>
		));
	};

	const handleClickDocumentEnrollButton = () => {
		dialog.showDialog(() => (
			<CreateEDocDialog
				title='서류 등록'
				paperMenu='recipient'
				targetId={currentRecipient?.recipientId}
			/>
		));
	};

	return (
		<S.Container>
			<CRTableHeader
				hideSearchButton
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={[durationFilter, paperTypeFilter]}
				renderRightButton={
					<S.RightButtonContainer>
						<CRButton.Default
							size='xSmall'
							palette='gray'
							type='outlined'
							onClick={handleClickDocumentEnrollButton}>
							등록
						</CRButton.Default>
						<CRButton.IconButton
							size='xSmall'
							type='filled'
							iconLeft={Assets.icon.add}
							onClick={handleClickIssueButton}>
							발급
						</CRButton.IconButton>
					</S.RightButtonContainer>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<RecipientDocumentTable items={recipientEDocList} onRefetch={handleRefetch} />
				</S.TableContainer>
			)}
		</S.Container>
	);
}
