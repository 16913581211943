import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import OwnExpensePayHistoryTab from './OwnExpensePayHistoryTab';

function OwnExpensePayHistoryPage(): React.ReactElement {
	const ownExpenseTab = useMemo(() => RouterPath.ownExpenseTab(), []);

	return (
		<CRTab.Default
			defaultActiveKey={ownExpenseTab.ownExpensePayHistory.key}
			items={[
				{
					label: ownExpenseTab.ownExpensePayHistory.label,
					key: ownExpenseTab.ownExpensePayHistory.key,
					children: <OwnExpensePayHistoryTab />,
				},
			]}
			breadCrumb='본인부담금 납부 이력'
		/>
	);
}

export default OwnExpensePayHistoryPage;
