import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';

import { CheckOption } from 'components/base/Selections/type';
import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import { Controller } from 'react-hook-form';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import * as S from './styles';

function MyCenterBasicInformationForm(): React.ReactElement {
	const { commonCodes, form } = useCenterInfo();

	const renderCustomLabel = (value: CheckOption[]) => (
		// eslint-disable-next-line
		<>{value?.map((item) => item.label).join(', ')}</>
	);

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		form.reset({
			...form.getValues(),
			centerBasAddr: postcodeData.address,
			centerZipCode: postcodeData.zonecode,
			centerAddrLatitude: postcodeData.latitude,
			centerAddrLongitude: postcodeData.longitude,
		});
	};

	const handleChangeDetailAddress = (detailAddress: string) => {
		form.setValue('centerDetailAddr', detailAddress);
	};

	return (
		<S.Container>
			<Controller
				control={form?.control}
				name='centerNm'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='센터' isRequired>
						<CRInput.Default placeholder='센터명' onChange={onChange} value={value} />
					</CRInputLabel>
				)}
			/>
			<Controller
				control={form?.control}
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='서비스' isRequired>
						<CRInput.MultiSelector
							options={commonCodes?.serviceOfferOptions}
							activeColor={false}
							renderLabel={renderCustomLabel}
							fullSize
							value={value}
							placeholder='서비스 선택'
							onChange={onChange}
						/>
					</CRInputLabel>
				)}
				name='centerOfferServices'
			/>
			<Controller
				control={form?.control}
				name='centerPhoneNo'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='전화번호' isRequired>
						<CRInput.Default placeholder='센터 전화번호' value={value} onChange={onChange} />
					</CRInputLabel>
				)}
			/>
			<CRInputLabel type='left' label='주소' isRequired>
				<CRInput.Address
					type='default'
					onChangeBaseAddress={handleChangeBaseAddress}
					onChangeDetailAddress={handleChangeDetailAddress}
					baseAddress={form.watch('centerBasAddr')}
					detailAddress={form.watch('centerDetailAddr')}
				/>
			</CRInputLabel>
			<Controller
				control={form?.control}
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='센터장' isRequired>
						<CRInput.Selector
							autoComplete
							items={commonCodes?.centerChiefOptions}
							currentValue={value}
							placeholder='센터장 선택'
							onChangeValue={onChange}
						/>
					</CRInputLabel>
				)}
				name='centerChief'
			/>
			<Controller
				control={form?.control}
				name='bizNo'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='사업자등록번호' isRequired>
						<CRInput.Default placeholder='사업자등록번호 입력' value={value} onChange={onChange} />
					</CRInputLabel>
				)}
			/>
			<Controller
				control={form?.control}
				name='centerMark'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='센터 기호' isRequired>
						<CRInput.Default placeholder='센터 기호 입력' value={value} onChange={onChange} />
					</CRInputLabel>
				)}
			/>
			<Controller
				control={form?.control}
				name='stampFile'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='센터 직인' isRequired>
						<CRInput.FileUploader
							uploadPath='stamp'
							title={value?.[0]?.originFileNm || ''}
							onChange={onChange}
							files={value}
							allowedExtension={['.png', '.jpg', '.jpeg', '.gif', '.svg', '.bmp', '.webp']}
							type='single'
						/>
					</CRInputLabel>
				)}
			/>
		</S.Container>
	);
}

export default MyCenterBasicInformationForm;
