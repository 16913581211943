import CRScheduleCalendar from 'components/base/CRScheduleCalendar';
import dayjs from 'dayjs';
import { useMyAccountInfo, useSchedule } from 'lib/hook/react-query';
import React, { useState } from 'react';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { recipientScheduleAdapter } from 'lib/adapter/recipient';
import useDialog from 'lib/hook/util/useDialog';
import RecipientScheduleDetailDialog from 'components/domain/dialog/RecipientScheduleDetailDialog';
import * as S from './styles';

export default function RecipientScheduleTab() {
	const [date, setDate] = useState(new Date());
	const { currentRecipient } = useRecipientPage();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: scheduleData } = useSchedule(
		{
			type: 'recipient',
			recipientId: currentRecipient?.recipientId,
			centerId: myAccountInfo?.centerId,
			yearMonth: dayjs(date).format('YYYYMM'),
		},
		recipientScheduleAdapter(myAccountInfo),
	);

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickDay = (date: Date) => {
		showDialog(() => (
			<RecipientScheduleDetailDialog date={date} currentRecipient={currentRecipient} />
		));
	};

	return (
		<S.CalendarContainer>
			<CRScheduleCalendar
				date={date}
				item={scheduleData}
				onChange={handleChangeDate}
				onClickDay={handleClickDay}
				type='recipient'
			/>
		</S.CalendarContainer>
	);
}
