import React from 'react';

import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import * as S from './styles';
import { FlexContainer } from '../../../base/CRFlexLayout/styles';
import { CRText } from '../../../base/CRText';
import CRProgressBar from '../../../base/CRProgressBar';
import EDocClientTopFloating from '../EDocClientTopFloating';

export interface EDocClientHeaderProps {
	recipientNm: string;
	currentDate: string;
	writtenFormCount: number;
	totalFormCount: number;
	startTime: string;
	endTime: string;
	totalMinute: number;
}

function EDocClientHeader({
	recipientNm,
	writtenFormCount,
	totalFormCount,
	startTime,
	currentDate,
	endTime,
	totalMinute,
}: EDocClientHeaderProps) {
	const remainFormCount = totalFormCount - writtenFormCount;
	const progressPercent = writtenFormCount / totalFormCount;
	return (
		<EDocClientTopFloating>
			<S.Container>
				<FlexContainer direction='column'>
					<FlexContainer justify='space-between' margin='0 0 1.5rem 0'>
						<FlexContainer gap='1rem'>
							<S.ProfileBackground>
								<CRIcon src={Assets.icon.person} />
							</S.ProfileBackground>
							<CRText color='white' typography='h4' text={recipientNm} />
						</FlexContainer>
						<S.DateWrapper>
							<CRText color='white' text={currentDate} />
						</S.DateWrapper>
					</FlexContainer>
					<FlexContainer justify='space-between' direction='column'>
						<FlexContainer justify='space-between'>
							<FlexContainer align='center'>
								<CRText color='white' typography='bodyB' text={writtenFormCount} />
								<CRText color='white' typography='label' text='개' />
								<CRText
									typography='label'
									color='primary80'
									text=' 작성 완료'
									margin='0 0 0 0.5rem'
								/>
							</FlexContainer>
							<FlexContainer align='center'>
								<CRText color='white' typography='bodyB' text={remainFormCount} />
								<CRText color='white' typography='label' text='개' />
								<CRText typography='label' color='primary80' text=' 남음' margin='0 0 0 0.5rem' />
							</FlexContainer>
						</FlexContainer>
						<CRProgressBar
							barColor='white'
							backgroundColor='primary50'
							percentage={progressPercent}
							height='0.4rem'
						/>
						<FlexContainer margin='1.5rem 0 0 0' justify='space-between'>
							<FlexContainer gap='1.5rem' align='center'>
								<FlexContainer gap='0.5rem' align='center'>
									<CRText typography='label' color='primary80' text='시작' />
									<CRText color='white' text={startTime} />
								</FlexContainer>
								<FlexContainer gap='0.5rem' align='center'>
									<CRText typography='label' color='primary80' text='종료' />
									<CRText color='white' text={endTime} />
								</FlexContainer>
							</FlexContainer>
							<FlexContainer align='center'>
								<CRText typography='label' color='primary80' text='총 시간' />
								<CRText margin='0 0 0 1rem' typography='h3' color='white' text={totalMinute} />
								<CRText color='white' text='분' />
							</FlexContainer>
						</FlexContainer>
					</FlexContainer>
				</FlexContainer>
			</S.Container>
		</EDocClientTopFloating>
	);
}

export default EDocClientHeader;
