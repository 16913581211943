import React from 'react';

import * as S from './styles';

interface Props<T> {
	item: T;
	detailRenderKeys?: (keyof T | '')[];
	detailCustomRender?: {
		[key in keyof T | any]?: (value: T[keyof T | any]) => React.ReactElement;
	};
	onClick?: (item: T) => void;
}

function CRCollapsibleDetailRow<T>({
	item,
	detailRenderKeys = [],
	detailCustomRender = {},
	onClick,
}: Props<T>): React.ReactElement {
	return (
		<S.TableBodySubRow onClick={() => onClick?.(item)}>
			<S.TableBodyColumn>
				<S.TableBodyIconContainer>
					<S.TableBodySubLine />
				</S.TableBodyIconContainer>
			</S.TableBodyColumn>
			{detailRenderKeys.map((renderKey: keyof T | '') => {
				if (renderKey === '') {
					return <S.TableBodyColumnWithPadding key={renderKey.toString()} />;
				}
				return (
					<S.TableBodyColumnWithPadding key={renderKey.toString()}>
						{
							(detailCustomRender[renderKey]
								? detailCustomRender[renderKey]?.(item[renderKey as keyof T])
								: item[renderKey as keyof T]) as React.ReactNode
						}
					</S.TableBodyColumnWithPadding>
				);
			})}
			<S.TableBodyColumnWithPadding />
		</S.TableBodySubRow>
	);
}

export default React.memo(CRCollapsibleDetailRow) as typeof CRCollapsibleDetailRow;
