import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import {
	useCreateRecipientIntegratedAssessmentSalaryOfferChangePlan,
	useMyAccountInfo,
	useRecipientIntegratedAssessmentSalaryOfferChangePlans,
} from 'lib/hook/react-query';
import { IntegratedAssessmentSalaryOfferPlanChangePlanListDTO } from 'types/dto';
import { endpoint } from 'lib/service/Api/endpoint';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';

import * as S from './styles';

interface Props {
	selectedItem?: IntegratedAssessmentSalaryOfferPlanChangePlanListDTO;
	onClick?: (item: IntegratedAssessmentSalaryOfferPlanChangePlanListDTO) => void;
}

function IntegratedAssessmentSalaryOfferList({ selectedItem, onClick }: Props): React.ReactElement {
	const { currentRecipient, currentRecipientInfoSummary } = useRecipientPage();
	const params = useParams<{ id: string; salaryOfferPlanId: string }>();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data = [], isLoading } = useRecipientIntegratedAssessmentSalaryOfferChangePlans({
		salaryOfferPlanId: params.salaryOfferPlanId ?? '',
	});

	const createRecipientIntegratedAssessmentSalaryOfferChangePlan =
		useCreateRecipientIntegratedAssessmentSalaryOfferChangePlan((client) => {
			client.invalidateQueries([
				endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlans.key,
				{ salaryOfferPlanId: params.salaryOfferPlanId ?? '' },
			]);
		});

	const handleClickAddChangePlan = async () => {
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;
		if (!myAccountInfo) return;
		await createRecipientIntegratedAssessmentSalaryOfferChangePlan.mutateAsync({
			salaryOfferPlanId: params.salaryOfferPlanId ?? '',
			recipientNm: currentRecipientInfoSummary.name,
			recipientBirthDt: currentRecipient.birthday,
			recipientGenderCd: currentRecipientInfoSummary.genderCd,
			longTermGradeCd: currentRecipientInfoSummary.longTermGradeCd,
			writeDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
			writerId: myAccountInfo.memberAccountId,
			salaryKindCd: '',
			salaryDivCdList: [],
			etcSalaryDivNm: '',
			salaryOfferChangeDivCd: '',
			etcSalaryOfferChangeDivNm: '',
			opinionDesc: '',
			salaryDesc: '',
			changeDesc: '',
			longTermNo: currentRecipientInfoSummary.longTermNo ?? '',
		});
	};

	const header = [
		{
			type: 'label',
			label: '작성일',
			labelStyle: {
				width: '9.6rem',
			},
		},
		{
			type: 'label',
			label: '변경, 반영 구분',
			labelStyle: {
				width: '10.8rem',
			},
		},
		{
			type: 'label',
			label: '작성자',
		},
	] as InformationTableItemType[];

	const list = (data ?? []).map((item) => {
		const selected = item.salaryOfferChangePlanId === selectedItem?.salaryOfferChangePlanId;
		const onClickItem = () => onClick?.(item);
		return [
			{
				type: 'value',
				value: dayjs(item.writeDate).format('YYYY.MM.DD'),
				valueStyle: {
					width: '9.6rem',
					cursor: 'pointer',
				},
				selected,
				onClick: onClickItem,
			},
			{
				type: 'value',
				value: item.salaryOfferChangeDivNm,
				valueStyle: {
					width: '10.8rem',
					cursor: 'pointer',
				},
				selected,
				onClick: onClickItem,
			},
			{
				type: 'value',
				value: item.writerNm,
				valueStyle: {
					cursor: 'pointer',
				},
				selected,
				onClick: onClickItem,
			},
		];
	}) as InformationTableItemType[][];

	useEffect(() => {
		if (!isLoading && data?.length) {
			onClick?.(data[0]);
		}
	}, [isLoading, data?.length]);

	return (
		<S.ListContainer>
			<InformationTable items={[header]} />
			<S.ListContentContainer>
				<InformationTable items={list} />
			</S.ListContentContainer>
			<S.ListButtonContainer>
				<CRButton.IconButton
					onClick={handleClickAddChangePlan}
					iconLeft={Assets.icon.add}
					palette='gray'
					type='outlined'
					fullSize>
					신규 등록
				</CRButton.IconButton>
			</S.ListButtonContainer>
		</S.ListContainer>
	);
}

export default IntegratedAssessmentSalaryOfferList;
