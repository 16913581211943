import React, { useLayoutEffect, useState } from 'react';
import CRTab from 'components/base/CRTab';
import RouterPath from 'common/router';
import { TabProps } from 'components/base/CRTab/MainTab/type';
import { Outlet, useLocation, useNavigate } from 'react-router';

export default function SendHistoryPage() {
	const sendHistoryTab = RouterPath.sendHistoryTab() as {
		message: { key: string; label: string };
		esign: { key: string; label: string };
		reserve: { key: string; label: string };
		[key: string]: { key: string; label: string };
	};
	const [currentTab, setCurrentTab] = useState<TabProps>();
	const navigate = useNavigate();
	const handleChangeTab = (item: TabProps) => {
		setCurrentTab(item);
		navigate(item.key);
	};
	const tt = useLocation();
	useLayoutEffect(() => {
		const paths = tt.pathname.split('/');
		const currentPath = paths[paths.length - 1];
		if (currentPath === 'history') {
			navigate(sendHistoryTab.message.key, {
				replace: true,
			});
		} else {
			const tabInfo = sendHistoryTab[currentPath];
			setCurrentTab(tabInfo);
		}
	}, []);

	return (
		<CRTab.Default
			onChange={handleChangeTab}
			breadCrumb='발송 이력'
			defaultActiveKey={currentTab?.key}
			items={[
				{
					label: sendHistoryTab.message.label,
					key: sendHistoryTab.message.key,
					children: <Outlet />,
				},
				{
					label: sendHistoryTab.esign.label,
					key: sendHistoryTab.esign.key,
					children: <Outlet />,
				},
				{
					label: sendHistoryTab.reserve.label,
					key: sendHistoryTab.reserve.key,
					children: <Outlet />,
				},
			]}
		/>
	);
}
