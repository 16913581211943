import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.8rem;
`;

const inputAttr: any = {
	default: css`
		box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
		background: ${(props) => props.theme.colors.gray100};
	`,
	focus: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.gray10} inset;
		background: ${(props) => props.theme.colors.gray99};
	`,
	error: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.primary60} inset;
		background: ${(props) => props.theme.colors.primary99};
	`,
	disabled: css`
		background: ${(props) => props.theme.colors.gray95};
	`,
};

export const AddressContainer = styled.div<{
	$type?: 'default' | 'table';
	$disabled?: boolean;
	$error?: boolean;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1.4rem 1.6rem;
	height: 5.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
	border-radius: 0.8rem;
	gap: 0.8rem;
	box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
	background: ${(props) => props.theme.colors.gray100};

	& > input {
		${(props) => props.theme.typography.body};
		color: ${(props) => (!props.$error ? props.theme.colors.gray10 : props.theme.colors.primary60)};
		padding: 0;
		width: 100%;
		min-height: 2.8rem;
		background: transparent;
		border: none;
		outline: none;
		cursor: inherit;

		&::placeholder {
			${(props) => props.theme.typography.body};
			color: ${(props) => props.theme.colors.gray60};
		}
	}

	${(props) =>
		props.$type === 'table' &&
		css`
			padding: 0.4rem 0.8rem;
			border-radius: 0.4rem;
			box-shadow: 0 0 0 0.1rem
				${!props.$error ? props.theme.colors.gray90 : props.theme.colors.primary60} inset;
			background: ${props.$disabled ? props.theme.colors.gray95 : props.theme.colors.gray100};
			height: auto;
			margin: 0;

			& > input {
				${(props) => props.theme.typography.label};
				padding: 0;
				width: 100%;
				min-height: 2.6rem;
				background: transparent;
				border: none;
				outline: none;
				color: ${!props.$error ? props.theme.colors.gray10 : props.theme.colors.primary60};
			}

			& > input::placeholder {
				${(props) => props.theme.typography.label};
				color: ${(props) => props.theme.colors.gray60};
			}
		`};

	& img {
		cursor: pointer;
		width: 2.4rem;
		height: 2.4rem;
	}

	${(props) =>
		props.$disabled &&
		css`
			cursor: no-drop;
			background: ${(props) => props.theme.colors.gray95};

			& > input {
				color: ${props.theme.colors.gray60};
			}
		`};

	&:disabled img {
		filter: brightness(0) saturate(100%) invert(63%) sepia(5%) saturate(155%) hue-rotate(149deg)
			brightness(90%) contrast(89%);
	}
`;

export const HelperText = styled.div<{ status?: 'default' | 'error' }>`
	${(props) => props.theme.typography.label};
	color: ${(props) =>
		props.status === 'default' ? props.theme.colors.gray60 : props.theme.colors.primary60};
`;
