import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	min-width: 112rem;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
`;
