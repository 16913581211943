import { css, styled } from 'styled-components';

export const DayContainer = styled.ul`
	display: inline-flex;
	gap: 0.8rem;
	align-items: center;
`;

export const Day = styled.li<{ $isSelected?: boolean; $isDisabled?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.4rem;
	height: 2.4rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 50%;
	color: ${(props) => props.theme.colors.gray10};

	font-family: 'Pretendard';
	font-feature-settings: tnum;
	font-variant-numeric: tabular-nums;
	font-style: normal;
	font-weight: 700;
	font-size: 1.4rem;
	line-height: 100%;
	cursor: pointer;

	${(props) =>
		props.$isSelected &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.primary60};
			color: ${(props) => props.theme.colors.gray100};
			background: ${(props) => props.theme.colors.primary60};
		`}

	${(props) =>
		props.$isDisabled &&
		props.$isSelected &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.gray60};
			color: ${(props) => props.theme.colors.gray100};
			background: ${(props) => props.theme.colors.gray60};
		`};
`;
