import CRInputLabel from 'components/base/CRInputLabel';
import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import Colors from 'common/colors';
import { CRText } from 'components/base/CRText';
import * as S from './styles';

export default function LossHelp() {
	return (
		<S.FormItemContainer>
			<CRInputLabel label='도움말' type='left-sub' showOverflow betweenGap='2.4rem'>
				<S.HelpText>
					<Accordion.Item value='개인사유' asChild style={{ marginBottom: '0.8rem' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>1. 개인사유</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild style={{}}>
								<TaskAccordion.Content
									style={{
										borderTop: 0,
										background: Colors.gray99,
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: 0,
									}}>
									<S.HelpTextLabel>a. 자진퇴사</S.HelpTextLabel>
									<S.HelpTextLabel>b. 타 직장 이직</S.HelpTextLabel>
									<S.HelpTextLabel>
										c. 가족요양/목욕 근로자가 타직장 160시간 이상 근무
									</S.HelpTextLabel>
									<S.HelpTextLabel>
										d. 근로자의 건강악화, 입원으로 근로자가 퇴직 의사를 먼저 밝힌 경우
									</S.HelpTextLabel>
									<S.HelpTextLabel>e. (기간제) 수급자 매칭 거부</S.HelpTextLabel>
									<S.HelpTextLabel>
										f. 근로복지공단에서 조건부 계약만료 처리 반려(근로자 안내 필요)
									</S.HelpTextLabel>
									<S.HelpTextLabel>
										g. (무기계약직) 수급자 또는 사회복지사에게 신체적 또는 정신적 피해를 입힌
										경우(상급자 판단)
									</S.HelpTextLabel>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='조건부계약만료' asChild style={{ marginBottom: '0.8rem' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>2. 계약만료(조건부 계약종료)</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild style={{}}>
								<TaskAccordion.Content
									style={{
										borderTop: 0,
										background: Colors.gray99,
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: 0,
									}}>
									<S.HelpTextLabel>
										a. (기간제) 근로계약서의 근로자 유의사항을 지키지 않아 기관 손해 발생
									</S.HelpTextLabel>
									<S.HelpTextLabel>
										b. (기간제) 담당 수급자가 사망, 입원, 등외판정 등으로 계약종료
									</S.HelpTextLabel>
									<S.HelpTextLabel>
										c. (기간제) 담당 수급자의 근로자 교체요구, 거부 등으로 계약종료
									</S.HelpTextLabel>
									<S.HelpTextLabel>
										d. (기간제) 수급자 또는 사회복지사에게 신체적 또는 정신적 피해를 입힌 경우 -
										상급자 판단
									</S.HelpTextLabel>
									<S.HelpTextLabel>e. 근로복지공단에서 개인사유 처리 반려</S.HelpTextLabel>
									<S.HelpTextLabel>f. (회사 측 권유) 케어링 내 타 센터로 이관</S.HelpTextLabel>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='고용보험이중취득' asChild style={{ marginBottom: '0.8rem' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										disabled
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>3. 고용보험의 이중취득</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='고용보험비적용' asChild style={{ marginBottom: '0' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>4. 고용보험 비적용</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild style={{}}>
								<TaskAccordion.Content
									style={{
										borderTop: 0,
										background: Colors.gray99,
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: 0,
									}}>
									<S.HelpTextLabel>a. 근로자 사망</S.HelpTextLabel>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<S.Divider />
					<S.FormItemContainer>
						<S.HelpTextLabel>• 상실일</S.HelpTextLabel>
						<S.HelpTextDesc>
							• 이중취득 : 직권 상실처리된 날(=타 사업장 고용보험 취득일)
						</S.HelpTextDesc>
						<S.HelpTextDesc>• 근로자 사망 : 사망일 다음 날</S.HelpTextDesc>
						<S.HelpTextDesc>
							• 개인사정, 조건부 계약종료 : 퇴직일(=마지막 근무일 다음 날)
							<S.HelpTextDesc>• 보류하다가 퇴직 시, 퇴직의사를 밝힌 날</S.HelpTextDesc>
						</S.HelpTextDesc>
						<S.HelpTextDesc>• 60세 도달 : 60세가 된 날의 다음 날</S.HelpTextDesc>
						<S.HelpTextDesc>• 의료급여수급권자 : 수급권자가 된 날</S.HelpTextDesc>
						<S.HelpTextDesc>• 근무 중 상실 시, 60시간 미만 근무가 발생한 월의 1일</S.HelpTextDesc>
						<S.HelpTextLabel>
							• 국민연금/고용/산재는 상실일 기준 익월 15일까지 신고, 건강은 상실일 기준 14일 이내
							신고
						</S.HelpTextLabel>
					</S.FormItemContainer>
				</S.HelpText>
			</CRInputLabel>
		</S.FormItemContainer>
	);
}
