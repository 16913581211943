import React, { useCallback } from 'react';

import CRTableHeader, { Filter } from 'components/base/CRTableHeader';

import { PageInfo } from 'types/view/base';
import { MonitoringContentDTO } from 'types/api/monitoring';
import CRTable from 'components/base/CRTable';
import { FilterType } from 'types/view/filter';
import * as S from './styles';

import { MONITORING_HEADER_CONFIG } from './constant';

interface Props<T extends { label: string; value: any }> {
	items?: MonitoringContentDTO[];
	searchValue?: string;
	currentValue: {
		[key in string]: T[];
	};
	pageInfo?: PageInfo;
	filters?: Array<Filter<T>>;
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickTask?: (task: MonitoringContentDTO) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onSearch?: () => void;
}

function DeprecatedMonitoringTable<T extends { label: string; value: any }>({
	items = [],
	currentValue,
	searchValue,
	pageInfo,
	filters,
	setCurrentFilter,
	onChangeSearchValue,
	onClickTask,
	onChangePageInfo,
	onSearch,
}: Props<T>): React.ReactElement {
	const renderTaskCount = useCallback(
		(value: number | string) => <div>{`${Number(value) > 0 ? Number(value) : 0}개`}</div>,
		[],
	);

	return (
		<S.Container>
			<CRTableHeader
				onChangePageInfo={onChangePageInfo}
				pageInfo={pageInfo}
				currentFilter={currentValue}
				stickyMode
				filters={filters}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				onSearch={onSearch}
			/>
			<CRTable.Root>
				<CRTable.Head heads={MONITORING_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item) => (
						<CRTable.Row
							key={item.id}
							item={item}
							renderKeys={[
								'servicePeriod',
								'recipient',
								'EmployeeName',
								'serviceType',
								'taskCount',
								'status',
							]}
							customRender={{
								taskCount: renderTaskCount,
							}}
							onClick={onClickTask}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default DeprecatedMonitoringTable;
