import { IntegratedAssessmentDesireNursingCareStatusQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireNursingCareStatusQuestions: IntegratedAssessmentDesireNursingCareStatusQuestion[] =
	[
		{
			key: '호흡',
			label: '호흡',
			answer: [
				{
					label: '기관지 절개관 간호',
					value: '기관지 절개관 간호',
				},
				{
					label: '흡인',
					value: '흡인',
				},
				{
					label: '산소요법',
					value: '산소요법',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '영양',
			label: '영양',
			answer: [
				{
					label: '경관영양',
					value: '경관영양',
					disabled: true,
					options: [
						{ label: '비위관', value: '비위관' },
						{ label: '위관', value: '위관' },
					],
				},
				{
					label: '치료식이',
					value: '치료식이',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '배설',
			label: '배설',
			answer: [
				{
					label: '투석간호',
					value: '투석간호',
				},
				{
					label: '유치도뇨관',
					value: '유치도뇨관',
				},
				{
					label: '단순도뇨',
					value: '단순도뇨',
				},
				{
					label: '방광루',
					value: '방광루',
				},
				{
					label: '장루간호',
					value: '장루간호',
				},
			],
		},
		{
			key: '상처',
			label: '상처',
			answer: [
				{
					label: '상처간호',
					value: '상처간호',
				},
				{
					label: '당뇨발간호',
					value: '당뇨발간호',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '욕창단계',
			label: '단계',
			answer: [
				{
					label: '1단계',
					value: '1단계',
				},
				{
					label: '2단계',
					value: '2단계',
				},
				{
					label: '3단계',
					value: '3단계',
				},
				{
					label: '4단계',
					value: '4단계',
				},
			],
		},
		{
			key: '욕창부위',
			label: '부위',
			answer: [
				{
					label: '머리',
					value: '머리',
				},
				{
					label: '등',
					value: '등',
				},
				{
					label: '어깨',
					value: '어깨',
				},
				{
					label: '팔꿈치',
					value: '팔꿈치',
				},
				{
					label: '엉덩이',
					value: '엉덩이',
				},
				{
					label: '뒤꿈치',
					value: '뒤꿈치',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '암발생부위',
			label: '암 발생부위',
			answer: [
				{
					label: '폐',
					value: '폐',
				},
				{
					label: '위',
					value: '위',
				},
				{
					label: '대장',
					value: '대장',
				},
				{
					label: '간',
					value: '간',
				},
				{
					label: '전립선',
					value: '전립선',
				},
				{
					label: '유방',
					value: '유방',
				},
				{
					label: '담낭 및 기타 담도',
					value: '담낭 및 기타 담도',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '일반통증부위',
			label: '일반통증부위',
			answer: [
				{
					label: '머리',
					value: '머리',
				},
				{
					label: '상지',
					value: '상지',
				},
				{
					label: '하지',
					value: '하지',
				},
				{
					label: '허리',
					value: '허리',
				},
				{
					label: '등',
					value: '등',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
	];
