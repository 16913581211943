import React, { CSSProperties, ReactNode } from 'react';
import Typography from 'common/typography';
import Colors from 'common/colors';
import * as S from './styles';

type DisplayPropertyValue =
	| 'block'
	| 'inline'
	| 'inline-block'
	| 'none'
	| 'flex'
	| 'grid'
	| 'table'
	| 'inline-table'
	| 'table-row'
	| 'table-cell';

interface Prop {
	children?: ReactNode;
	text?: ReactNode;
	typography?: keyof typeof Typography;
	color?: keyof typeof Colors;
	margin?: string;
	padding?: string;
	display?: DisplayPropertyValue;
	style?: CSSProperties;
}

export function CRText({
	children,
	text,
	typography = 'body',
	color = 'gray20',
	margin,
	padding,
	display,
	style = {},
}: Prop) {
	return (
		<S.Typography
			style={style}
			$typography={typography}
			$color={color}
			$margin={margin}
			$display={display}
			$padding={padding}>
			{text ?? children}
		</S.Typography>
	);
}
