import styled from 'styled-components';

export const InputLabelContainer = styled.div`
	width: 27rem;
	height: 100%;
`;

export const ContentWrapper = styled.div`
	height: calc(100% - 11rem);
	width: 100%;
`;
