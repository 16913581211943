import Colors from 'common/colors';
import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1.6rem;
	gap: 1.6rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const ScrollableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1.6rem;
	gap: 1.6rem;
	box-sizing: border-box;
	overflow: overlay;
`;

export const TableContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	border-radius: 0.4rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray99};
`;

export const OwnExpenseHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
	overflow: hidden;
`;

export const OwnExpenseItem = styled.div`
	flex: 1;
	overflow: hidden;
	box-sizing: border-box;
`;

export const OwnExpensePayItem = styled.div`
	flex: 1;
	overflow: hidden;
	box-sizing: border-box;
	div:nth-child(1) {
		width: 100%;
	}
`;

export const OwnExpenseContentContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
	overflow: hidden;
`;

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	padding: 1.2rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	box-sizing: border-box;
	padding: 0 1.2rem;
	background: ${(props) => props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const RightButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const WithdrawalButtonContainer = styled.div``;

export const CalendarContainer = styled.div`
	padding: 1.6rem;
	overflow: auto;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Circle = styled.span<{ color: keyof typeof Colors }>`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 0.7rem;
	margin-right: 0.4rem;
	background-color: ${(p) => p.theme.colors[p.color]};
`;

export const Line = styled.div`
	width: 0.1rem;
	height: 1.6rem;
	background-color: ${(p) => p.theme.colors.gray90};
`;
