import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useCreateRecipientIntegratedAssessment = useCRMutation(
	endpoint.createRecipientIntegratedAssessment.key,
	'createRecipientIntegratedAssessment',
);

export const useUpdateRecipientIntegratedAssessment = useCRMutation(
	endpoint.updateRecipientIntegratedAssessment.key,
	'updateRecipientIntegratedAssessment',
);

export const useConfirmRecipientIntegratedAssessment = useCRMutation(
	endpoint.confirmRecipientIntegratedAssessment.key,
	'confirmRecipientIntegratedAssessment',
);

export const useDeleteRecipientIntegratedAssessment = useCRMutation(
	endpoint.deleteRecipientIntegratedAssessment.key,
	'deleteRecipientIntegratedAssessment',
);

export const useDeleteRecipientIntegratedAssessmentSalaryOffer = useCRMutation(
	endpoint.deleteRecipientIntegratedAssessmentSalaryOffer.key,
	'deleteRecipientIntegratedAssessmentSalaryOffer',
);

export const useUploadRecipientIntegratedAssessmentSalaryOffer = useCRMutation(
	endpoint.uploadRecipientIntegratedAssessmentSalaryOffer.key,
	'uploadRecipientIntegratedAssessmentSalaryOffer',
);

export const useDeleteRecipientIntegratedAssessmentSalaryOfferChangePlan = useCRMutation(
	endpoint.deleteRecipientIntegratedAssessmentSalaryOfferChangePlan.key,
	'deleteRecipientIntegratedAssessmentSalaryOfferChangePlan',
);

export const useCreateRecipientIntegratedAssessmentSalaryOfferChangePlan = useCRMutation(
	endpoint.createRecipientIntegratedAssessmentSalaryOfferChangePlan.key,
	'createRecipientIntegratedAssessmentSalaryOfferChangePlan',
);

export const useUpdateRecipientIntegratedAssessmentSalaryOfferChangePlan = useCRMutation(
	endpoint.updateRecipientIntegratedAssessmentSalaryOfferChangePlan.key,
	'updateRecipientIntegratedAssessmentSalaryOfferChangePlan',
);

export const useRecipientIntegratedAssessmentSalaryOfferSend = useCRMutation(
	endpoint.getRecipientIntegratedAssessmentSalaryOfferSend.key,
	'getRecipientIntegratedAssessmentSalaryOfferSend',
);
