import { CRTableHeadConfig } from 'types/view/base';

export const SEND_HISTORY_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '18.2rem',
		label: '발송일시',
		sortKey: '발송일시',
	},
	{
		width: '26rem',
		label: '문서유형',
		sortKey: '문서유형',
	},
	{
		width: '100%',
		label: '제목',
		sortKey: '제목',
	},
	{
		width: '8.8rem',
		label: '총 건수',
		sortKey: '총 건수',
	},
	{
		width: '8.8rem',
		label: '성공 건수',
		sortKey: '성공건수',
	},
	{
		width: '8.8rem',
		label: '실패 건수',
		sortKey: '실패건수',
	},
	{
		width: '8.8rem',
		label: '대기 건수',
		sortKey: '대기건수',
	},
	{
		width: '10rem',
		label: '발송자',
		sortKey: '발송자',
	},
	{
		width: '12rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
];
