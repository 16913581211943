import { useContext } from 'react';

import { WorkExecuteLogContext } from 'lib/provider/workExecuteLog';

export default function useWorkExecuteLog() {
	const context = useContext(WorkExecuteLogContext);
	if (!context) {
		throw new Error('useWorkExecuteLog must be used within a WorkExecuteLogContext');
	}
	return context;
}
