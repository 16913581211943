import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	useCenterManagers,
	useCommonCodes,
	useEmployees,
	useMyAccountInfo,
	useRecipient,
	useRecipientBase,
	useRecipients,
} from 'lib/hook/react-query';
import {
	Recipient,
	RecipientBasicInfoFormType,
	RecipientBasicStatusFormType,
	RecipientGuardianFormViewType,
	RecipientInfoSummary,
	RecipientLongTermFormViewType,
	RecipientOtherServiceFormViewType,
	RecipientOwnExpenseFormViewType,
	RecipientScheduleAutomationFormViewType,
} from 'types/view/recipient';
import { Filter } from 'types/view/filter';
import { useSectionFocus } from 'lib/hook/util/useSectionFocus';
import { isLongTermGradeExpireSoon } from 'lib/util';
import { RecipientDTO } from 'types/api/common';
import dayjs from 'dayjs';
import RouterPath from '../../../common/router';

export enum EditableRecipientFormTypes {
	기본정보 = 'basicInfo',
	장기요양인정 = 'longTerm',
	보호자 = 'guardian',
	타급여 = 'otherService',
	본인부담금 = 'ownExpense',
	일정등록자동화 = 'scheduleAutomation',
}

interface RecipientPageContextType {
	isLoading: boolean;
	currentTab: string;
	recipients: Recipient[];
	currentRecipient?: Recipient;
	currentRecipientInfoSummary: RecipientInfoSummary;
	currentRecipientStatus: RecipientBasicStatusFormType;
	currentRecipientBase: RecipientBasicInfoFormType;
	currentRecipientLongTermCertification: RecipientLongTermFormViewType;
	currentRecipientGuardians: RecipientGuardianFormViewType[];
	currentRecipientScheduleAutomations: RecipientScheduleAutomationFormViewType[];
	currentRecipientOtherServices: RecipientOtherServiceFormViewType[];
	currentRecipientOwnExpense: RecipientOwnExpenseFormViewType;
	setCurrentRecipient: React.Dispatch<React.SetStateAction<Recipient | undefined>>;
	searchText: string;
	setSearchText: React.Dispatch<React.SetStateAction<string>>;
	currentFilter: {
		stateFilter: { label: string; value: any }[];
		serviceFilter: { label: string; value: any }[];
		managerFilter: { label: string; value: any }[];
		expiredFilter: { label: string; value: any }[];
	};
	setCurrentFilter: React.Dispatch<
		React.SetStateAction<{
			stateFilter: { label: string; value: any }[];
			serviceFilter: { label: string; value: any }[];
			managerFilter: { label: string; value: any }[];
			expiredFilter: { label: string; value: any }[];
		}>
	>;
	stateFilter: Filter<{ label: string; value: any }>;
	serviceFilter: Filter<{ label: string; value: any }>;
	managerFilter: Filter<{ label: string; value: any }>;
	expiredFilter: Filter<{ label: string; value: any }>;
	startChangeEditSection: (formType: EditableRecipientFormTypes) => void;
	isEditSectionChanging?: boolean;
	isFormEditable: (formType: EditableRecipientFormTypes) => boolean;
	currentEditForm?: EditableRecipientFormTypes;
	cancelChangeEditSection: () => void;
	setEditForm: (formType: EditableRecipientFormTypes) => void;
}

export const RecipientPageContext = React.createContext<RecipientPageContextType | null>(null);

function RecipientPageContextProvider({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const location = useLocation();
	const [currentTab, setCurrentTab] = useState('basic');
	const [searchText, setSearchText] = useState('');
	const [currentRecipient, setCurrentRecipient] = useState<Recipient>();
	const [currentFilter, setCurrentFilter] = useState<{
		stateFilter: { label: string; value: any }[];
		serviceFilter: { label: string; value: any }[];
		managerFilter: { label: string; value: any }[];
		expiredFilter: { label: string; value: any }[];
	}>({
		stateFilter: [{ label: '수급중', value: 'CMN058.10' }],
		serviceFilter: [],
		managerFilter: [],
		expiredFilter: [],
	});

	const {
		startChangeEditSection,
		isEditSectionChanging,
		isFormEditable,
		currentEditForm,
		cancelChangeEditSection,
		setEditForm,
		resetSection,
	} = useSectionFocus<EditableRecipientFormTypes>();

	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: recipientsData, isLoading } = useRecipients({ centerIds: myAccountInfo?.centerId });
	const { data: recipientData } = useRecipient({
		recipientId: Number(currentRecipient?.recipientId),
	});
	const { data: employees } = useEmployees({
		centerIds: myAccountInfo?.centerId ? [myAccountInfo.centerId] : [],
	});

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const { data: recipientBaseData } = useRecipientBase({
		recipientId: Number(currentRecipient?.recipientId),
	});

	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN058', 'CMN006'],
	});

	const stateCommonCodes = useMemo(
		() => commonCodes?.filter((item) => item.comCdGroupNm === 'CMN058') ?? [],
		[commonCodes],
	);

	const serviceCommonCodes = useMemo(
		() =>
			commonCodes?.filter((item) => item.comCdGroupNm === 'CMN006' && item.etcDesc1 === 'Y') ?? [],
		[commonCodes],
	);

	const recipients = useMemo(
		() =>
			(recipientsData?.recipients ?? [])
				?.filter((item) => {
					const isMatchState =
						!currentFilter.stateFilter?.length ||
						currentFilter.stateFilter?.some((filter) => filter.label === item.stateNm);
					const isMatchService =
						!currentFilter.serviceFilter?.length ||
						currentFilter.serviceFilter?.some((filter) =>
							item.serviceNm.includes(filter.label.charAt(2)),
						);
					const isMatchManager =
						!currentFilter.managerFilter?.length ||
						currentFilter.managerFilter?.some(
							(filter) => String(filter.value) === String(item.managerId),
						);

					const isMatchExpired =
						!currentFilter.expiredFilter?.length || isLongTermGradeExpireSoon(item.longTermEndDate);

					return isMatchState && isMatchService && isMatchManager && isMatchExpired;
				})
				?.filter(
					(item) =>
						item.korMemberNm.toLowerCase().includes(searchText.toLowerCase()) ||
						item.mobilePhoneNo?.includes(searchText) ||
						item.birthDt?.includes(searchText.replace(/\./g, '')) ||
						item.mainGuardianNm?.includes(searchText),
				)
				.sort((a: RecipientDTO, b: RecipientDTO) => {
					if (currentFilter.expiredFilter?.length) {
						return dayjs(a.longTermEndDate).isAfter(dayjs(b.longTermEndDate)) ? 1 : -1;
					}
					return 1;
				})
				.map(
					(item) =>
						({
							id: item.memberId,
							name: item.korMemberNm,
							recipientId: item.recipientId,
							rdsn: item.korMemberNm,
							birthday: item.birthDt,
							phoneNumber: item.mobilePhoneNo,
							centerId: item.centerId,
							centerName: item.centerNm,
							state: item.stateNm,
							service: item.serviceNm,
							managerId: item.managerId,
							managerName: item.managerNm,
							longTermEndDate: item.longTermEndDate,
						} as Recipient),
				),
		[recipientsData?.recipients, searchText, currentFilter],
	);

	const currentRecipientInfoSummary = useMemo(
		() =>
			({
				name: recipientData?.recipient.recipientNm ?? '',
				guardian: recipientData?.mainGuardianInfo ?? '',
				manager: recipientData?.recipient.managerNm ?? '',
				gender: recipientData?.recipient.genderNm ?? '',
				genderCd: recipientData?.recipient.genderCd ?? '',
				phoneNumber: recipientData?.recipient.mobilePhoneNo ?? '',
				ownExpense: recipientData?.recipient.burdenRateNm ?? '',
				rsdn: recipientData?.recipient.rsdnNo ?? '',
				otherService: recipientData?.otherServiceUseSummary ?? '',
				longTermNo: recipientData?.recipient.longTermNo ?? '',
				address: [
					recipientData?.recipient.baseAddr ?? '',
					recipientData?.recipient.detailAddr ?? '',
					recipientData?.recipient.zipCode ? `(${recipientData?.recipient.zipCode})` : '',
				]
					.join(' ')
					.trim(),
				service: recipientData?.services ?? '',
				longTermGrade: recipientData?.recipient.longTermGradeNm ?? '',
				longTermGradeCd: recipientData?.recipient.longTermGradeCd ?? '',
			} as RecipientInfoSummary),
		[recipientData],
	);

	const currentRecipientStatus = useMemo(
		() =>
			({
				status: recipientBaseData?.recipient.recipientStateCd,
				manager: recipientBaseData?.recipient.managerNm,
			} as RecipientBasicStatusFormType),
		[recipientBaseData],
	);

	const currentRecipientBase = useMemo(
		() =>
			({
				name: recipientBaseData?.recipient.recipientNm,
				address: {
					zipCode: recipientBaseData?.recipient.zipCode,
					basAddr: recipientBaseData?.recipient.baseAddr,
					detailAddr: recipientBaseData?.recipient.detailAddr,
					addrLatitude: recipientBaseData?.recipient.addrLatitude,
					addrLongitude: recipientBaseData?.recipient.addrLongitude,
					regionNm1: recipientBaseData?.recipient.regionNm1,
					regionNm2: recipientBaseData?.recipient.regionNm2 || '',
					regionNm3: recipientBaseData?.recipient.regionNm3 || '',
					roadNm: recipientBaseData?.recipient.roadNm || '',
				},
				korMemberNm: recipientBaseData?.recipient.rsdnNo,
				birthday: recipientBaseData?.recipient.birthDt,
				memo: recipientBaseData?.recipient.recipientSpecialDesc,
				signType: recipientBaseData?.recipient.signMethodCd,
			} as RecipientBasicInfoFormType),
		[recipientBaseData],
	);

	const currentRecipientLongTermCertification = useMemo(
		() =>
			({
				longTermNo: recipientBaseData?.recipient.longTermNo,
				longTermMajorChangeNo: recipientBaseData?.recipient.longTermMajorChangeNo,
				longTermMinorChangeNo: recipientBaseData?.recipient.longTermMinorChangeNo,
				longTermGrade: recipientBaseData?.recipient.longTermGradeNm,
				longTermGradeCd: recipientBaseData?.recipient.longTermGradeCd,
				longTermGradePeriod: recipientBaseData?.recipient.longTermPeriod,
				ownExpenseRate: recipientBaseData?.recipient.burdenRateNm,
				welfareEquipment:
					recipientBaseData?.longTermUsePlanWelfares?.map((welfares) => welfares.welfareItemCd) ||
					[],
			} as RecipientLongTermFormViewType),
		[recipientBaseData],
	);

	const currentRecipientScheduleAutomations = useMemo(
		() =>
			recipientBaseData?.scheduleAutomations.map((automation) => {
				const employee1 = employees?.find(
					(item) => item?.employeeId === automation.employeeId1.employeeId,
				);
				const employee2 = employees?.find(
					(item) => item?.employeeId === automation.employeeId2?.employeeId,
				);
				return {
					serviceKindCd: automation.serviceKindCd,
					employeeId1: employee1
						? {
								label: employee1.korMemberNm,
								value: {
									id: employee1.employeeId,
									name: employee1.korMemberNm,
									duty: employee1.dutyNms,
									birth: employee1.birthDt,
								},
						  }
						: undefined,
					employeeId2: employee2
						? {
								label: employee2.korMemberNm,
								value: {
									id: employee2.employeeId,
									name: employee2.korMemberNm,
									duty: employee2.dutyNms,
									birth: employee2.birthDt,
								},
						  }
						: undefined,
					days: automation.scheduleAutomationDays.map((item) => item.scheduleAutomationDayCd),
					monthMethod: automation.monthUseCnt ? 'count' : automation.monthAmt ? 'amount' : '',
					monthAmt: automation.monthAmt,
					monthUseCnt: automation.monthUseCnt,
					time: {
						serviceStartTime: automation.serviceStartTime,
						serviceEndTime: automation.serviceEndTime,
					},
					holidayExceptYn: automation.holidayExceptYn,
					monthAmtOverYn: automation.monthAmtOverYn,
					automationFeeCd: automation.automationFeeCd,
					managerId: automation.managerId,
					totalDayYn: automation.totalDayYn
						? [
								{
									label: '전체 선택',
									value: true,
								},
						  ]
						: [],
				};
			}) as RecipientScheduleAutomationFormViewType[],
		[recipientBaseData, employees],
	);

	const currentRecipientGuardians = useMemo(() => {
		if (!recipientBaseData?.guardians?.length) {
			return [
				{
					name: '',
					phoneNumber: '',
					address: {
						basAddr: '',
						detailAddr: '',
						zipCode: '',
						regionNm1: '',
						regionNm2: '',
						regionNm3: '',
						roadNm: '',
					},
					birthday: '',
					relationship: '',
					isMain: true,
					scheduleReceiveYn: true,
					longTermPayFeeCertiReceiveYn: true,
					docReceiveMethodCd: '',
				},
			] as RecipientGuardianFormViewType[];
		}
		return recipientBaseData?.guardians.map((guardian) => ({
			id: guardian.recipientGuardianId,
			name: guardian.recipientGuardianNm,
			phoneNumber: guardian.mobilePhoneNo,
			address: {
				basAddr: guardian.baseAddr,
				detailAddr: guardian.detailAddr,
				zipCode: guardian.zipCode,
				addrLatitude: guardian.addrLatitude,
				addrLongitude: guardian.addrLongitude,
				regionNm1: guardian.regionNm1,
				regionNm2: guardian.regionNm2 || '',
				regionNm3: guardian.regionNm3 || '',
				roadNm: guardian.roadNm || '',
			},
			birthday: guardian.birthDt,
			relationship: guardian.guardianRelCd,
			isMain: guardian.mainGuardianYn,
			scheduleReceiveYn: guardian.scheduleReceiveYn,
			longTermPayFeeCertiReceiveYn: guardian?.longTermPayFeeCertiReceiveYn,
			docReceiveMethodCd: guardian?.docReceiveMethodCd || '',
		})) as RecipientGuardianFormViewType[];
	}, [recipientBaseData]);

	const currentRecipientOtherServices = useMemo(
		() =>
			recipientBaseData?.otherServices.map((otherService) => ({
				centerName: otherService.otherCenterNm,
				phoneNumber: otherService.otherCenterPhoneNo,
				memo: otherService.remark,
				otherService: otherService.serviceTypeCd,
				times: otherService.times,
			})) as RecipientOtherServiceFormViewType[],
		[recipientBaseData],
	);

	const currentRecipientOwnExpense = useMemo(
		() =>
			({
				payMethod: { label: '', value: recipientBaseData?.burdenAmtPays.burdenAmtPayMethodCd },
				bank: recipientBaseData?.burdenAmtPays.burdenAmtAutotransBankCd,
				account: recipientBaseData?.burdenAmtPays.burdenAmtAutotransAccountNo,
				accountOwner: recipientBaseData?.burdenAmtPays.burdenAmtAutotransAccountDepositorNm,
				name: recipientBaseData?.burdenAmtPays.cashReceiptIssuerNm,
				receipt: {
					label: '',
					value: recipientBaseData?.burdenAmtPays.cashReceiptIssueYn,
				},
				receiptMethod: {
					label: '',
					value: recipientBaseData?.burdenAmtPays.cashReceiptIssueMethodCd,
				},
				receiptNumber: recipientBaseData?.burdenAmtPays.cashReceiptIssuerNo,
				payDeductEmployeeId: recipientBaseData?.burdenAmtPays.burdenAmtPayDeductEmployeeId,
			} as RecipientOwnExpenseFormViewType),
		[recipientBaseData],
	);

	const stateFilter = useMemo(
		() =>
			({
				key: 'stateFilter',
				type: 'multi',
				options: stateCommonCodes.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '상태',
			} as Filter<{ label: string; value: any }>),
		[stateCommonCodes],
	);

	const serviceFilter = useMemo(
		() =>
			({
				key: 'serviceFilter',
				type: 'multi',
				options: serviceCommonCodes.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '급여 종류',
			} as Filter<{ label: string; value: any }>),
		[serviceCommonCodes],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options: (centerManagers ?? [])?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})),
				placeholder: '사회복지사',
			} as Filter<{ label: string; value: any }>),
		[centerManagers],
	);

	const expiredFilter = useMemo(
		() =>
			({
				key: 'expiredFilter',
				type: 'toggle',
				options: [
					{
						label: '만료',
						value: '만료',
					},
				],
			} as Filter<{ label: string; value: any }>),
		[],
	);

	useEffect(() => {
		if (recipientsData?.recipients?.length) {
			const id = params.id ?? '';
			const initialRecipient =
				recipientsData.recipients.find((item) => Number(item?.recipientId) === Number(id)) ??
				recipientsData?.recipients[0];

			setCurrentRecipient({
				id: initialRecipient.memberId,
				name: initialRecipient.korMemberNm,
				recipientId: initialRecipient.recipientId,
				rdsn: initialRecipient.korMemberNm,
				birthday: initialRecipient.birthDt,
				phoneNumber: initialRecipient.mobilePhoneNo,
				centerId: initialRecipient.centerId,
				centerName: initialRecipient.centerNm,
				state: initialRecipient.stateNm,
				service: initialRecipient.serviceNm,
				managerId: initialRecipient.managerId,
				managerName: initialRecipient.managerNm,
				mainGuardianNm: initialRecipient.mainGuardianNm,
			} as Recipient);

			if (window.location.pathname === RouterPath.recipient().tab.key) {
				navigate(
					`/${RouterPath.recipient().tab.uniqueKey}/${initialRecipient.recipientId}/${currentTab}`,
				);
			}
		}
	}, [recipientsData, params, location, currentTab]);

	useEffect(() => {
		const paths = location.pathname.split('/');
		const parsedCurrentTab = paths[3];
		if (parsedCurrentTab) {
			setCurrentTab(parsedCurrentTab);
		}
		resetSection();
	}, [location.pathname]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev.managerFilter.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...prev.managerFilter,
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	const value = useMemo(
		() => ({
			isLoading,
			currentTab,
			recipients,
			currentRecipient,
			setCurrentRecipient,
			currentRecipientInfoSummary,
			currentRecipientStatus,
			currentRecipientBase,
			currentRecipientLongTermCertification,
			currentRecipientScheduleAutomations,
			currentRecipientGuardians,
			currentRecipientOtherServices,
			currentRecipientOwnExpense,
			searchText,
			setSearchText,
			currentFilter,
			setCurrentFilter,
			stateFilter,
			serviceFilter,
			managerFilter,
			expiredFilter,
			startChangeEditSection,
			isEditSectionChanging,
			isFormEditable,
			currentEditForm,
			cancelChangeEditSection,
			setEditForm,
		}),
		[
			currentRecipient,
			currentTab,
			isLoading,
			recipients,
			currentRecipientInfoSummary,
			currentRecipientStatus,
			currentRecipientBase,
			currentRecipientLongTermCertification,
			currentRecipientScheduleAutomations,
			currentRecipientGuardians,
			currentRecipientOtherServices,
			currentRecipientOwnExpense,
			searchText,
			currentFilter,
			stateFilter,
			serviceFilter,
			managerFilter,
			expiredFilter,
			startChangeEditSection,
			isEditSectionChanging,
			isFormEditable,
			currentEditForm,
			cancelChangeEditSection,
			setEditForm,
		],
	);

	return <RecipientPageContext.Provider value={value}>{children}</RecipientPageContext.Provider>;
}

export default RecipientPageContextProvider;
