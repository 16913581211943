import React from 'react';

import * as S from './styles';

function IntegratedAssessmentDesireAssistiveDevicesFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn colSpan={4}>확인</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesireAssistiveDevicesFormHeader);
