import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: calc(100vh - 5.6rem);
`;

export const ContentContainer = styled.div`
	height: calc(100vh - 5.6rem);
	display: flex;
	position: relative;
	box-sizing: border-box;
`;
export const LeftContainer = styled.div`
	width: 28rem;
	min-width: 28rem;
	box-sizing: border-box;
	overflow-y: overlay;
	transition: all 300ms ease 0s;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	white-space: nowrap;
	background: ${(props) => props.theme.colors.gray99};
	padding: 1.6rem 2.8rem;
`;

export const MainContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const TaskContainer = styled.div`
	flex: 1;
	width: 100%;
	box-sizing: border-box;
	overflow: auto;
`;
