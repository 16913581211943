import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
	overflow: auto;
`;

export const Table = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	padding-bottom: 2.4rem;
	overflow-x: scroll;
`;

export const TableHeader = styled.thead`
	height: 5.7rem;
	background: ${(props) => props.theme.colors.gray100};
	border-radius: 1.6rem;
`;

export const TableHeaderColumn = styled.th`
	position: sticky;
	top: 8rem;
	z-index: 5;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
	text-align: left;
	background: ${(props) => props.theme.colors.gray100};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableHeaderFirstColumn = styled(TableHeaderColumn)`
	padding: 0rem 1.6rem 0 2.4rem;
`;

export const TableHeaderLastColumn = styled(TableHeaderColumn)`
	padding: 0rem 2.4rem 0 1.6rem;
`;

export const TableHeaderColumnWithPadding = styled(TableHeaderColumn)`
	padding: 0rem 1.6rem;
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	height: 5.7rem;
	background: ${(props) => props.theme.colors.gray100};

	&:nth-child(2n + 1) {
		background: ${(props) => props.theme.colors.gray99};
	}

	&:hover {
		background: ${(props) => props.theme.colors.primary90};

		& button {
			display: flex;
		}
	}
`;

export const TableBodySubRow = styled(TableBodyRow)`
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableBodyIconContainer = styled.div`
	cursor: pointer;
	width: 5.7rem;
	height: 5.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TableBodySubLine = styled.div`
	width: 0.1rem;
	height: 100%;
	background: ${(props) => props.theme.colors.gray90};
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray00};
	white-space: nowrap;
	/* box-sizing: content-box; */
	vertical-align: middle;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const TableBodyColumnWithPadding = styled(TableBodyColumn)`
	padding: 0rem 1.6rem;
`;

export const TableBodyToggleIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const StatusLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const statusBadge = css`
	display: flex;
	align-items: center;
	width: fit-content;
	height: 2.4rem;
	border-radius: 100rem;
	padding: 0 0.8rem;
`;

export const DoneStatusBadge = styled.div`
	${statusBadge};
	background: #d5efe0;
	color: #2e563f;
`;

export const PendingStatusBadge = styled.div`
	${statusBadge}
	background: #FFEFC5;
	color: #6d5516;
`;

export const FailureStatusBadge = styled.div`
	${statusBadge}
	background: #FFDADA;
	color: #680019;
`;

export const TableButton = styled.button`
	${(props) => props.theme.typography.label};
	display: none;
	align-items: center;
	padding: 0 0.8rem;
	min-height: 3.2rem;
	color: ${(props) => props.theme.colors.primary100};
	border-radius: 0.4rem;
	border: none;
	background-color: ${(props) => props.theme.colors.primary60};
	cursor: pointer;

	& > img {
		filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
	}

	& > span {
		color: ${(props) => props.theme.colors.primary100};
		${(props) => props.theme.typography.bodyB};
		white-space: nowrap;
	}
`;

export const TextContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
`;

export const SendStatusContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
`;
