import React, { useState } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import { useChangeContractStatus, useMyAccountInfo } from 'lib/hook/react-query';
import CRDialog from 'components/base/CRDialog';
import * as S from './styles';
import { ContractRecord } from '../../../../types/view/contract';
import { endpoint } from '../../../../lib/service/Api/endpoint';

interface Props {
	hideDialog: () => void;
	contract?: ContractRecord;
}

function ContractPendingDialog({ contract, hideDialog }: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const changeContractStatus = useChangeContractStatus((client) => {
		client.invalidateQueries([endpoint.getContracts.key]);
	});

	const [description, setDescription] = useState('');

	const handleSubmit = async () => {
		if (!myAccountInfo) return;
		if (!contract) return;

		await changeContractStatus.mutateAsync({
			serviceContractId: contract.id,
			centerId: myAccountInfo?.centerId,
			contractStateCd: 'CMN082.70',
			contractStateChangeDesc: description,
		});

		hideDialog();
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='보류'
			type='S'
			body={
				<S.Container>
					<S.SubTitle>{`${contract?.recipient} 님의 계약을 보류 상태로 변경합니다.`}</S.SubTitle>
					<CRInputLabel label='사유'>
						<CRInput.TextArea
							numberOfLines={5}
							placeholder='사유 입력'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleSubmit}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default ContractPendingDialog;
