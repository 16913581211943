import React from 'react';

import * as dS from '../styles';
import * as gS from '../grayStyles';
import { InformationSheetSectionProperties } from '../type';

interface Props {
	item: InformationSheetSectionProperties;
	type?: 'default' | 'gray';
	align?: 'space-between' | 'flex-start';
}

function BaseSectionInformation({ item, type = 'default', align }: Props): React.ReactElement {
	const S = type === 'default' ? dS : gS;

	return (
		<S.Information style={{ justifyContent: align }}>
			<S.ItemLabel
				style={{
					maxWidth: item.labelWidth,
					minWidth: item.labelWidth,
					...item.labelStyle,
				}}>
				{item.label}
			</S.ItemLabel>
			<S.ItemValue
				style={{
					maxWidth: item.valueWidth,
					minWidth: item.valueWidth,
					...item.valueStyle,
				}}>
				{item.value} {item.suffix && <S.SuffixValue>{item.suffix}</S.SuffixValue>}
			</S.ItemValue>
		</S.Information>
	);
}

export default React.memo(BaseSectionInformation);
