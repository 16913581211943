import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CheckOption } from 'components/base/Selections/type';
import CRTableHeader from 'components/base/CRTableHeader';
import CRButton from 'components/base/CRButton';
import EmployeeContractAddDialog from 'components/domain/dialog/EmployeeContractAddDialog';
import ContractWithdrawalDialog from 'components/domain/dialog/ContractWithdrawalDialog';
import EmployeeServiceTable from 'components/domain/table/EmployeeServiceTable';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useEmployeeContracts } from 'lib/hook/react-query';
import { employeeServicesAdapter } from 'lib/adapter/recipient';
import { Filter } from 'types/view/filter';
import { EmployeeService } from 'types/view/employee';

import * as S from './styles';
import RouterPath from '../../../../common/router';

function EmployeeContractTab(): React.ReactElement {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { showDialog } = useGlobalLayout();
	const { currentEmployee } = useEmployeePage();
	const [currentFilter, setCurrentFilter] = useState<{
		statusFilter: CheckOption[];
		serviceFilter: CheckOption[];
	}>({
		statusFilter: [],
		serviceFilter: [],
	});

	const {
		data: commonCodes = {
			CMN079: [],
			CMN136: [],
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN079', 'CMN136', 'CMN006'] }, commonCodeAdapter);

	const { data = [], refetch } = useEmployeeContracts(
		{
			employeeId: currentEmployee?.employeeId,
			centerIds: currentEmployee?.centerId,
			contractStateCds: ['CMN082.90'],
		},
		employeeServicesAdapter,
	);

	const filters = useMemo(
		() =>
			[
				{
					key: 'statusFilter',
					type: 'multi',
					placeholder: '상태',
					options: commonCodes.CMN079,
				},
				{
					key: 'serviceFilter',
					type: 'multi',
					placeholder: '급여',
					options: commonCodes.CMN006.filter((item) => item.data?.etcDesc1 === 'Y'),
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes],
	);

	const filteredData = useMemo(
		() =>
			data
				.filter(
					(item) =>
						!currentFilter.statusFilter?.length ||
						currentFilter.statusFilter.some((filter) => filter.value === item.status),
				)
				.filter(
					(item) =>
						!currentFilter.serviceFilter?.length ||
						currentFilter.serviceFilter.some((filter) => filter.label === item.service),
				),
		[data, currentFilter],
	);

	const handleRefetch = () => {
		refetch();
	};

	const handleSubmitContractAdd = (contractId: number) => {
		navigate(
			`/${RouterPath.employee().tab.uniqueKey}/${params.id}/${
				RouterPath.employee().contract.uniqueKey
			}/${RouterPath.myTaskOperate().contract.uniqueKey}/${contractId}/${
				RouterPath.myTaskOperate().recipientBasic.uniqueKey
			}`,
		);
	};

	const handleClickAddContract = () => {
		if (!currentEmployee) return;
		showDialog(() => (
			<EmployeeContractAddDialog
				currentEmployee={currentEmployee}
				onSubmit={handleSubmitContractAdd}
			/>
		));
	};

	// const handleClickRenewContract = () => {
	// 	showDialog(({ hideDialog }) => (
	// 		<EmployeeContractRenewDialog onCancel={hideDialog} onClose={hideDialog} />
	// 	));
	// };

	const handleClickWithdrawal = (item: EmployeeService) => {
		showDialog(() => (
			<ContractWithdrawalDialog detailId={item.contractServiceId} onRefetch={handleRefetch} />
		));
	};

	useEffect(() => {
		setCurrentFilter({
			statusFilter: [],
			serviceFilter: [],
		});
	}, [currentEmployee]);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				renderRightButton={
					<CRButton.Default size='xSmall' onClick={handleClickAddContract}>
						계약 추가
					</CRButton.Default>
				}
				filters={filters}
				placeholder='Place Holder'
			/>
			<S.TableContainer>
				<EmployeeServiceTable items={filteredData} onClickWithdrawal={handleClickWithdrawal} />
			</S.TableContainer>
		</S.Container>
	);
}

export default EmployeeContractTab;
