import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background: ${(props) => props.theme.colors.gray100};
	box-sizing: border-box;
`;

export const Header = styled.div`
	display: flex;
	height: 6.4rem;
	padding: 1.6rem 2.4rem;
	justify-content: space-between;
	box-sizing: border-box;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	height: calc(100vh - 19.3rem);
	padding: 2.4rem;
	box-sizing: border-box;
	max-width: 80rem;
	overflow-y: overlay;
	overflow-x: hidden;
`;

export const CloseIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	align-items: flex-start;
`;

export const Title = styled.h4``;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const DescriptionItem = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.gray50};
	gap: 0.8rem;
`;

export const SummaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const SummaryBar = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	align-items: center;
	padding: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const SummaryBarLabel = styled.span`
	${(props) => props.theme.typography.bodyB}
`;

export const SummaryBarValue = styled.span`
	${(props) => props.theme.typography.body}
`;

export const SummaryBarSuffix = styled.span`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray60};
`;

export const WorkingInformationContainer = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const WorkingInformationTitle = styled.div`
	${(props) => props.theme.typography.bodyB}
`;

export const WorkingInformationItem = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const WorkingInformationItemLabel = styled.span`
	color: ${(props) => props.theme.colors.gray60};
`;

export const WorkingInformationItemValue = styled.span``;

export const SalaryListContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 123.2rem;
	overflow-x: overlay;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SectionHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
	margin-bottom: 0.8rem;
`;

export const SectionTitle = styled.h4`
	flex: 1;
`;

export const SectionContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const SectionRowContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
`;

export const SectionColumnContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const RightTextAlignment = styled.div`
	${(props) => props.theme.typography.label}
	width: 100%;
	text-align: right;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	padding: 1.6rem 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
`;

export const InformationContainer = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const ReportNotify = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const ReportNotifyIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	${(props) => props.theme.filters.gray60}
`;

export const BaseButton = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const DisableButton = styled(BaseButton)`
	${(props) => props.theme.filters.primary60}
`;
