import { styled } from 'styled-components';

export const Container = styled.form`
	padding: 1.6rem 2.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
