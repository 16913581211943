import React, { useEffect, useMemo, useState } from 'react';

import ScheduleTaskTable from 'components/domain/table/ScheduleTaskTable';
import CRTable from 'components/base/CRTable';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { Filter } from 'components/base/CRTableHeader';
import { PageInfo } from 'types/view/base';
import { useMyTaskWait, useMyTaskTypes } from 'lib/hook/react-query';
import { FilterType } from 'types/view/filter';

interface IProps {
	onClickNewTask: () => void;
}

function PendingTab<T extends { label: string; value: any }>({
	onClickNewTask,
}: IProps): React.ReactElement {
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [searchValue, setSearchValue] = useState('');
	const { data: myTaskTypes } = useMyTaskTypes();
	const filters = useMemo(
		() =>
			[
				{
					key: 'taskTypeCd',
					type: 'single',
					options:
						myTaskTypes?.data?.map(
							(item) =>
								({
									label: item.taskTypeNm,
									value: item.taskTypeCd,
								} as T),
						) ?? [],
					placeholder: '업무유형',
				},
			] as Array<Filter<T>>,
		[myTaskTypes],
	);
	const [currentFilter, setCurrentFilter] = useState<{
		[key: string]: T[];
	}>({});

	const [keyword, setKeyword] = useState('');
	const { data: myTask } = useMyTaskWait({
		size: pageInfo.size,
		page: pageInfo.page,
		keyword,
		taskTypeCd: currentFilter?.taskTypeCd?.[0].value,
	});

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const onChangeFilter = (item: T, key: string, filterType: FilterType) => {
		const prevFilter = Object.keys(currentFilter).length === 0 ? {} : { ...currentFilter };

		if (filterType === 'single') {
			const newItems = { ...prevFilter };
			const exist = prevFilter[key]?.find((selectedItem) => selectedItem.value === item.value);
			if (exist) {
				delete newItems[key];
			} else {
				newItems[key] = [item];
			}
			setCurrentFilter({
				...newItems,
			});
		} else if (['multi', 'toggle'].includes(filterType)) {
			if (!prevFilter[key]) {
				setCurrentFilter({
					...prevFilter,
					[key]: [item],
				});
			} else {
				const newItems = { ...prevFilter };
				const exist = prevFilter[key].find((selectedItem) => selectedItem.value === item.value);
				if (exist) {
					newItems[key] = prevFilter[key].filter((prevItem) => prevItem.value !== item.value);
				} else {
					newItems[key] = [...prevFilter[key], item];
				}
				setCurrentFilter(newItems);
			}
		} else if (['calendar', 'month_calendar'].includes(filterType)) {
			setCurrentFilter({
				...prevFilter,
				[key]: [item],
			});
		}
	};

	useEffect(() => {
		setPageInfo({
			...pageInfo,
			totalPages: myTask?.data?.myTaskData.totalPages ?? 0,
		});
	}, [myTask]);

	return (
		<CRTable.BackBlind>
			<ScheduleTaskTable
				filters={filters}
				onChangeFilter={onChangeFilter}
				currentValue={currentFilter}
				items={myTask?.data?.myTaskData.content ?? []}
				onClickButton={onClickNewTask}
				pageInfo={pageInfo}
				onChangePageInfo={handleChangePageInfo}
				searchValue={searchValue}
				onChangeSearchValue={handleSearchKeyword}
				onSearch={handleSearch}
			/>
		</CRTable.BackBlind>
	);
}

export default PendingTab;
