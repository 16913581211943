import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import RecipientBasicForm from 'components/domain/form/RecipientBasicForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import ParentalControlsForm from 'components/domain/form/ParentalControlsForm';
import CashReceiptForm from 'components/domain/form/CashReceiptForm';
import OtherServiceUsageForm from 'components/domain/form/OtherServiceUsageForm';
import LongTermCareRecognitionForm from 'components/domain/form/LongTermCareRecognitionForm';
import RecipientManagerForm from 'components/domain/form/RecipientManagerForm';

function RecipientBasicPage(): React.ReactElement {
	const forms = [
		{
			id: '#1',
			label: '담당자',
			component: <RecipientManagerForm />,
		},
		{
			id: '#1',
			label: '기본 정보',
			component: <RecipientBasicForm />,
		},
		{
			id: '#2',
			label: '장기요양인정 정보',
			component: <LongTermCareRecognitionForm />,
		},
		{
			id: '#3',
			label: '보호자 정보',
			component: <ParentalControlsForm />,
		},
		{
			id: '#4',
			label: '타급여 이용 정보',
			component: <OtherServiceUsageForm />,
		},
		{
			id: '#5',
			label: '현금영수증 정보',
			component: <CashReceiptForm />,
		},
	];

	return (
		<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
			{forms.map((form) => (
				<Accordion.Item value={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
		</Accordion.Root>
	);
}

export default React.memo(RecipientBasicPage);
