import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	min-width: 92rem;
	overflow: overlay;
	box-sizing: border-box;
`;

export const TableContainer = styled.div`
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-top: 0rem;
	border-bottom: 0rem;
	height: 100%;
	width: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const TableScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;
