import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import { Recipient } from 'types/view/recipient';
import { AddOwnExpenseFormValidator } from 'lib';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	useCommonCodes,
	useCreateRecipientHistoryOfOwnExpense,
	useMyAccountInfo,
} from 'lib/hook/react-query';

import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	onClickClose?: () => void;
}

export type RecipientAddHistoryOfManagerForm = {
	changeDate: Date;
	ownExpense: string;
	burdenRateChangeDesc: string;
	center: {
		label: string;
		value: number;
	};
};

function RecipientAddHistoryOfOwnExpenseDialog({ currentRecipient, onClickClose }: Props) {
	const { control, getValues, setValue, watch } = useForm<RecipientAddHistoryOfManagerForm>({
		resolver: yupResolver(AddOwnExpenseFormValidator),
	});
	const { data: ownExpenseRateCommonCodes = [] } = useCommonCodes({ comCdGroupNms: ['CMN043'] });
	const { data: myAccountInfo } = useMyAccountInfo();

	const rateCommonCodes = useMemo(
		() => [...(ownExpenseRateCommonCodes ?? [])].reverse(),
		[ownExpenseRateCommonCodes],
	);
	const createRecipientHistoryOfOwnExpenseMutation = useCreateRecipientHistoryOfOwnExpense(
		(client) => {
			client.invalidateQueries([
				endpoint.getRecipientHistoryOfOwnExpense.key,
				{ recipientId: currentRecipient?.recipientId },
			]);
			client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: Number(currentRecipient?.recipientId) },
			]);
		},
	);

	const handleClickAdd = async () => {
		const success = await createRecipientHistoryOfOwnExpenseMutation.mutateAsync({
			recipientId: currentRecipient?.recipientId,
			burdenRateStartDate: dayjs(getValues('changeDate').toString()).format('YYYYMMDD'),
			burdenRateCd: getValues('ownExpense'),
			burdenRateChangeDesc: getValues('burdenRateChangeDesc'),
		});
		if (success?.recipientId) {
			Toast.success('본인부담율 이력을 추가했습니다.');
			onClickClose?.();
		}
	};

	useEffect(() => {
		if (myAccountInfo) {
			setValue('center', {
				label: myAccountInfo.centerNm,
				value: myAccountInfo.centerId,
			});
		}
	}, [myAccountInfo]);

	return (
		<CRDialog
			title='본인부담율 이력 추가'
			body={
				<S.Content>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='변경일' isRequired>
								<CRInput.DatePicker
									onChangeValue={onChange}
									value={value}
									placeholder='변경일자 선택'
								/>
							</CRInputLabel>
						)}
						name='changeDate'
						control={control}
					/>
					<Controller
						render={({ field: { value }, formState: { errors } }) => (
							<CRInputLabel label='소속' isRequired>
								<CRInput.Selector
									disabled
									items={[
										{
											label: myAccountInfo?.centerNm ?? '',
											value: myAccountInfo?.centerId,
										},
									]}
									autoComplete
									currentValue={value}
									placeholder='소속 선택'
								/>
							</CRInputLabel>
						)}
						name='center'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='본인부담율' isRequired>
								<CRCheckBoxGroup
									checkType='single'
									type='radio'
									direction='col'
									appearanceType='button'
									gap={0.4}
									options={rateCommonCodes.map((item) => ({
										label: item.engComCdAliasNm,
										value: item.comCdId,
									}))}
									onChange={(ids: CheckOption[]) => onChange(ids[0].value)}
									value={[{ label: '', value }]}
								/>
							</CRInputLabel>
						)}
						name='ownExpense'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='변경 사유' isRequired>
								<CRInput.TextArea
									ref={ref}
									placeholder='변경 사유 입력'
									onChange={onChange}
									onBlur={onBlur}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='burdenRateChangeDesc'
						control={control}
					/>
				</S.Content>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClickClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={
							!(watch('burdenRateChangeDesc') && watch('changeDate') && watch('ownExpense'))
						}
						palette='primary'
						size='default'
						onClick={handleClickAdd}>
						등록
					</CRButton.Default>
				</S.ButtonContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default RecipientAddHistoryOfOwnExpenseDialog;
