import React, {
	ChangeEvent,
	FocusEvent,
	FocusEventHandler,
	ForwardedRef,
	forwardRef,
	useState,
} from 'react';

import * as S from './styles';

interface IProps {
	minTime?: string;
	maxTime?: string;
	value?: string;
	id?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	onChange?: (...event: any[]) => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	prefix?: string;
	suffix?: React.ReactNode;
}

function CRTime(
	{
		minTime,
		maxTime,
		value,
		id,
		status = 'default',
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		placeholder = '',
		onChange,
		onBlur,
		prefix,
		suffix,
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const [isFocused, setIsFocused] = useState(false);
	const onFocus = () => setIsFocused(true);
	const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
		setIsFocused(false);
		onBlur?.(e);
	};

	const onChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
		const numericOnly = event.target.value.replace(/\D/g, '');
		let formattedTime = numericOnly;

		if (numericOnly.length > 2) {
			formattedTime = `${numericOnly.substring(0, 2)}:${numericOnly.substring(2)}`;
		}
		// 시간 제한: 24시 이하, 분 제한: 60분 이하
		if (parseInt(numericOnly.substring(0, 2), 10) > 23) {
			formattedTime = '00:';
		}
		if (parseInt(numericOnly.substring(2), 10) > 59) {
			formattedTime = `${numericOnly.substring(0, 2)}:00`;
		}

		if (minTime && maxTime && numericOnly.length === 4) {
			const startTime = numericOnly.substring(0, 2);
			const endTime = numericOnly.substring(2);
			const hours = parseInt(startTime, 10);
			const minutes = parseInt(endTime, 10);

			const minTimeArray = minTime.split(':');
			const maxTimeArray = maxTime.split(':');

			const minHours = parseInt(minTimeArray[0], 10);
			const minMinutes = parseInt(minTimeArray[1], 10);
			const maxHours = parseInt(maxTimeArray[0], 10);
			const maxMinutes = parseInt(maxTimeArray[1], 10);

			if (hours < minHours || (hours === minHours && minutes < minMinutes)) {
				formattedTime = `${minTimeArray[0]}:${minTimeArray[1]}`;
			} else if (hours > maxHours || (hours === maxHours && minutes > maxMinutes)) {
				formattedTime = `${maxTimeArray[0]}:${maxTimeArray[1]}`;
			}
		}

		onChange?.(formattedTime);
	};

	return (
		<S.Container $isDisabled={disabled}>
			<S.InputContainer $isFocused={isFocused} $error={status === 'error'} disabled={disabled}>
				{prefix && <S.PrefixText>{prefix}</S.PrefixText>}
				<input
					style={{ textAlign: prefix ? 'right' : 'left' }}
					ref={ref}
					id={id}
					value={value}
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={handleBlur}
					onFocus={onFocus}
					onChange={onChangeTime}
				/>
				{suffix && <S.SuffixText>{suffix}</S.SuffixText>}
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRTime);
