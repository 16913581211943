import { CRStatusConfig } from 'types/view/base';

export const CENTER_FINANCE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '이전 이력',
		label: '이전 이력',
		color: 'gray',
	},
	{
		key: '현재 적용중',
		label: '현재 적용중',
		color: 'green',
	},
	{
		key: '적용 예정',
		label: '적용 예정',
		color: 'yellow',
	},
];
