import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Assets from 'assets';
import { ContractServiceUseDayDTO } from 'types/dto';
import Colors from 'common/colors';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import InformationSheet from 'components/ui/InformationSheet';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import * as S from './styles';
import { CheckOption } from '../../../base/Selections/type';
import EmptyDescription from '../../../ui/EmptyDescription';

interface Props {
	currentService: CheckOption<any>;
}

function RenewalVisitCareServiceForm({ currentService }: Props): React.ReactElement {
	const params = useParams<{ id: string }>();
	const { form, disabled } = useContractRenewalTask();

	const index =
		form?.getValues('services').findIndex((item) => {
			const [serviceContractId, serviceTypeCd] = currentService.value.split('-');

			return (
				item.serviceContractId === Number(serviceContractId) && item.serviceTypeCd === serviceTypeCd
			);
		}) ?? 0;

	const handleChangeTime =
		(timeIndex: number) =>
		(dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
			const days = dayOfTheWeeks.map(
				(day) => ({ serviceUseDayCd: day } as ContractServiceUseDayDTO),
			);
			form?.setValue(`services.${index}.use.serviceTimes.${timeIndex}.serviceUseDays`, days);
			form?.setValue(
				`services.${index}.use.serviceTimes.${timeIndex}.serviceUseStartTime`,
				startTime?.replace(':', ''),
			);
			form?.setValue(
				`services.${index}.use.serviceTimes.${timeIndex}.serviceUseEndTime`,
				endTime?.replace(':', ''),
			);
		};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	const handleAddTime = () => {
		const previousTimes = form?.getValues(`services.${index}.use.serviceTimes`) ?? [];
		const newItem = [
			...previousTimes,
			{
				serviceUseStartTime: '',
				serviceUseEndTime: '',
				serviceContractDetailId: Number(params.id),
				remark: '',
				serviceUseDays: [],
			},
		];
		form?.setValue(`services.${index}.use.serviceTimes`, newItem);
	};

	const handleRemoveTime = (timeIndex: number) => () => {
		const previousTimes = form?.getValues(`services.${index}.use.serviceTimes`) ?? [];
		const newItem = previousTimes.filter((item, idx) => idx !== timeIndex);
		form?.setValue(`services.${index}.use.serviceTimes`, newItem);
	};

	useEffect(() => {
		if (!form.getValues(`services.${index}.use.serviceTimes`)?.length) {
			form?.setValue(`services.${index}.use.serviceTimes`, [
				{
					serviceUseStartTime: '',
					serviceUseEndTime: '',
					serviceContractDetailId: Number(params.id),
					remark: '',
					serviceUseDays: [],
				},
			]);
		}
	}, [form.getValues(`services.${index}.use.serviceTimes`)?.length]);

	return (
		<S.Container key={currentService.value}>
			<CRInputLabel type='left' label='급여 시작일' isRequired showOverflow>
				<Controller
					name={`services.${index}.use.serviceStartDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInput.DatePicker
							placeholder='급여 시작일'
							value={value ? dayjs(value).toDate() : undefined}
							onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
							disabled={disabled}
						/>
					)}
				/>
				<Controller
					name={`services.${index}.use.sameDayContractYn`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRCheckBox checked={value} onChange={onChange} disabled={disabled}>
							당일계약
						</CRCheckBox>
					)}
				/>
				{form.watch(`services.${index}.use.sameDayContractYn`) && (
					<Controller
						name={`services.${index}.use.sameDayContractDesc`}
						control={form?.control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInput.TextArea
								placeholder='특이사항을 입력해주세요.'
								onChange={onChange}
								value={value}
								disabled={disabled}
							/>
						)}
					/>
				)}
			</CRInputLabel>
			<Controller
				name={`services.${index}.use.serviceEndDate`}
				control={form?.control}
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel type='left' label='급여 종료일' isRequired showOverflow>
						<CRInput.DatePicker
							placeholder='급여 종료일'
							value={value ? dayjs(value).toDate() : undefined}
							onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
			/>
			<CRInputLabel type='left' label='타급여'>
				{form?.watch(`services.${index}.use.otherServices`)?.length ? (
					form?.watch(`services.${index}.use.otherServices`)?.map((item) => (
						<InformationSheet
							title='타급여 01'
							items={[
								[
									{
										label: '센터명',
										value: item.otherServiceCenterNm,
										labelWidth: '6.4rem',
										valueStyle: {
											color: Colors.gray10,
											textAlign: 'left',
										},
									},
								],
								[
									{
										label: '전화번호',
										value: item.otherServiceCenterPhoneNumber,
										labelWidth: '6.4rem',
										valueStyle: {
											color: Colors.gray10,
											textAlign: 'left',
										},
									},
								],
								[
									{
										label: '이용 급여',
										value: item.otherServiceTypeNm,
										labelWidth: '6.4rem',
										valueStyle: {
											color: Colors.gray10,
											textAlign: 'left',
										},
									},
								],
								[
									{
										label: '이용일시',
										value: item.otherServiceUsePeriod,
										labelWidth: '6.4rem',
										valueStyle: {
											color: Colors.gray10,
											textAlign: 'left',
										},
									},
								],
							]}
							type='gray'
						/>
					))
				) : (
					<S.EmptyContainer>
						<EmptyDescription description='타급여 이용 정보가 없습니다.' />
					</S.EmptyContainer>
				)}
			</CRInputLabel>
			<Controller
				control={form?.control}
				name={`services.${index}.use.serviceTimes`}
				render={({ field: { value }, formState: { errors } }) => (
					<CRInputLabel type='left' label='급여 이용 일정' isRequired>
						<S.TimePickerContainer>
							{form?.watch(`services.${index}.use.serviceTimes`)?.map((time, timeIndex) => {
								const key = `${form?.watch(`services.${index}.serviceContractId`)}-${timeIndex}`;
								return (
									<CRInput.TimePicker
										key={key}
										selectedDayOfTheWeeks={time.serviceUseDays?.map(
											(day) => day.serviceUseDayCd as DayOfTheWeek,
										)}
										start={transformTimeString(time.serviceUseStartTime)}
										end={transformTimeString(time.serviceUseEndTime)}
										onChange={handleChangeTime(timeIndex)}
										onDelete={handleRemoveTime(timeIndex)}
										disabled={disabled}
										disableDelete={form?.watch(`services.${index}.use.serviceTimes`).length === 1}
									/>
								);
							})}
							{!disabled && (
								<CRButton.IconButton
									onClick={handleAddTime}
									iconLeft={Assets.icon.add}
									palette='gray'
									type='tonal'>
									이용일시 추가
								</CRButton.IconButton>
							)}
						</S.TimePickerContainer>
					</CRInputLabel>
				)}
			/>
			<Controller
				control={form?.control}
				name={`services.${index}.use.monthPerWorkHourCnt`}
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='월소정 근로시간' isRequired>
						<CRInput.Default
							suffix='시간'
							placeholder='월소정 근로시간 입력'
							type='number'
							value={value}
							onChange={onChange}
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
			/>
		</S.Container>
	);
}

export default RenewalVisitCareServiceForm;
