import React, { useEffect, useMemo, useState } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCheckRealName,
	useCheckRecipientRsdnNo,
	useCommonCodes,
	useCreateContracts,
	useCreateRecipient,
	useMyAccountInfo,
} from 'lib/hook/react-query';

import { Controller, useForm } from 'react-hook-form';
import { EmployeeCreateContractForm } from 'types/view/contract';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractCreateValidator } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import CRInputMessage from 'components/base/CRInputMessage';
import CRBanner from 'components/base/CRBanner';
import { endpoint } from 'lib/service/Api/endpoint';
import { Toast } from 'components/base/CRToast';
import { EmployeeDTO } from 'types/api';
import CRSpinner from 'components/base/CRSpinner';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import * as S from './styles';
import { CheckRecipientRsdnNData } from '../../../../types/api/common';

interface Props {
	currentEmployee?: EmployeeDTO;
	onSubmit?: (contractId: number) => void;
}

function EmployeeContractAddDialog({ currentEmployee, onSubmit }: Props): React.ReactElement {
	const {
		control,
		watch,
		getValues,
		setValue,
		setError,
		formState: { errors },
	} = useForm<EmployeeCreateContractForm>({
		resolver: yupResolver(contractCreateValidator),
		mode: 'onChange',
	});
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />,
	);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { hideDialog } = useDialog();
	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006'] }, commonCodeAdapter);
	const options = useMemo(
		() => commonCodes.CMN006.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes.CMN006],
	);

	const createRecipientMutation = useCreateRecipient((client) => {
		client.invalidateQueries([endpoint.getRecipients.key]);
	});

	const [isDuplicateValid, setIsDuplicateValid] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [isRealName, setIsRealName] = useState(false);
	const [didCheckDuplicate, setCheckDuplicate] = useState(false);
	const [didCheckRealName, setDidCheckRealName] = useState(false);
	const [checkDuplicateResult, setCheckDuplicateResult] = useState<CheckRecipientRsdnNData | null>(
		null,
	);

	const checkRecipientRsdnNoMutation = useCheckRecipientRsdnNo();
	const checkRealNameMutation = useCheckRealName();
	const createContractMutation = useCreateContracts((client, returnData) => {
		if (returnData.code === '200') {
			Toast.success('계약이 추가되었습니다.');
			client.invalidateQueries([endpoint.getEmployeeContractServices.key]);
		}
		hideDialog();
	});

	const { name, rsdnNo, serviceType, recipientId } = watch();

	const isNewUser = useMemo(
		() =>
			checkDuplicateResult?.isCenterDuplicated === false &&
			checkDuplicateResult?.isEmployeeDuplicated === false &&
			checkDuplicateResult?.isRecipientDuplicated === false,
		[checkDuplicateResult],
	);

	const isAllValid = !!(
		Object.keys(errors)?.length === 0 &&
		didCheckDuplicate &&
		isValid &&
		getValues('serviceType')?.length &&
		(!isNewUser || isRealName)
	);

	const handleFormSubmit = async () => {
		if (createContractMutation.isLoading) return;
		if (!myAccountInfo?.centerId || !isAllValid) return;

		if (isDuplicateValid) {
			const result = await createContractMutation.mutateAsync({
				contractTypeCd: 'CMN136.10',
				centerId: myAccountInfo.centerId,
				recipientId,
				employeeId: currentEmployee?.employeeId,
				serviceTypeCds: serviceType.map((item) => item.value),
			});

			if (!result.data?.serviceContract.serviceContractId) return;
			onSubmit?.(result.data?.serviceContract.serviceContractId);
		} else {
			const createRecipientResult = await createRecipientMutation.mutateAsync({
				name,
				rsdnNo,
				centerId: String(myAccountInfo.centerId),
			});

			if (createRecipientResult?.recipientId) {
				const result = await createContractMutation.mutateAsync({
					contractTypeCd: 'CMN136.10',
					centerId: myAccountInfo.centerId,
					recipientId: createRecipientResult?.recipientId,
					employeeId: currentEmployee?.employeeId,
					serviceTypeCds: serviceType.map((item) => item.value),
				});

				if (!result.data?.serviceContract.serviceContractId) return;
				onSubmit?.(result.data?.serviceContract.serviceContractId);
			}
		}
	};

	const handleClickCheckRealName = async () => {
		const foreignerYn = ['5', '6', '7', '8'].includes(rsdnNo?.[6]);
		const result = await checkRealNameMutation.mutateAsync({
			name,
			rsdnNo,
			foreignerYn,
		});
		setDidCheckRealName(true);
		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 직원을 생성할 수 있습니다.' />,
			);
			setIsRealName(true);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
			setIsRealName(true);
		}
	};

	const handleClickDuplicateCheck = async () => {
		if (
			!myAccountInfo?.centerId ||
			errors.name?.message ||
			errors.rsdnNo?.message ||
			!name ||
			!rsdnNo
		)
			return;
		const result = await checkRecipientRsdnNoMutation.mutateAsync({
			name,
			rsdnNo,
			centerId: String(myAccountInfo.centerId),
		});

		setCheckDuplicateResult(result);

		if (result?.recipientId) {
			setValue('recipientId', result?.recipientId);
		}

		if (result?.isMemberDuplicated && !result?.recipientId) {
			setBanner(<CRBanner type='error' title='동일한 주민번호로 이미 직원에 등록되어있습니다.' />);
			setIsValid(false);
			setIsDuplicateValid(false);
			setCheckDuplicate(false);
			return;
		}

		if (result?.isRecipientDuplicated && result?.isCenterDuplicated) {
			setIsValid(true);
			setIsDuplicateValid(true);
			setCheckDuplicate(true);
			setBanner(<CRBanner type='success' title='센터내에 존재하는 수급자입니다.' />);
		}
		if (!result?.isCenterDuplicated && !result?.isRecipientDuplicated) {
			setIsValid(true);
			setCheckDuplicate(true);
			setIsDuplicateValid(false);
			setBanner(<CRBanner type='success' title='새로운 수급자입니다.' />);
		}
		if (result?.isRecipientDuplicated && !result?.isCenterDuplicated) {
			setIsValid(true);
			setCheckDuplicate(true);
			setIsDuplicateValid(false);
			setBanner(
				<CRBanner
					type='warning'
					title={`${result?.centerNm}에 이미 존재하는 수급자입니다. (센터 추가 등록)`}
				/>,
			);
		}

		setDidCheckRealName(false);
	};

	useEffect(() => {
		setDidCheckRealName(false);
		setIsDuplicateValid(false);
		setIsValid(false);
		setIsRealName(false);
		setCheckDuplicateResult(null);
		setCheckDuplicate(false);
		setBanner(<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />);
	}, [name, rsdnNo]);

	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>계약 추가</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={hideDialog} />
			</S.HeaderContainer>
			<S.BodyContainer>
				<S.BodyContent>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='서비스 종류' isRequired>
								<CRCheckBoxGroup
									checkType='multiple'
									type='checkbox'
									direction='col'
									appearanceType='button'
									gap={0.4}
									options={options}
									onChange={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='serviceType'
						control={control}
					/>
					<CRCardFormLayout label='수급자' isRequired>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
								if (errors.name?.message) {
									setIsDuplicateValid(false);
								}
								return (
									<CRInputLabel
										label='이름'
										message={
											errors.name?.message && (
												<CRInputMessage type='error'>{errors.name?.message ?? ''}</CRInputMessage>
											)
										}>
										<CRInput.Default
											status={errors.name?.message ? 'error' : 'default'}
											ref={ref}
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											placeholder='이름 입력'
										/>
									</CRInputLabel>
								);
							}}
							name='name'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
								if (errors.name?.message) {
									setIsDuplicateValid(false);
								}
								return (
									<CRInputLabel
										label='주민등록번호'
										message={
											errors.rsdnNo?.message && (
												<CRInputMessage type='error'>{errors.rsdnNo?.message ?? ''}</CRInputMessage>
											)
										}>
										<div
											style={{
												position: 'relative',
											}}>
											<CRInput.Default
												type='number'
												status={errors.rsdnNo?.message ? 'error' : 'default'}
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='주민등록번호 입력'
												maxLength={13}
											/>
											<S.RightButtonContainer>
												<CRButton.Default
													onClick={handleClickDuplicateCheck}
													size='xSmall'
													palette='gray'
													type='outlined'
													buttonType='button'>
													중복 조회
												</CRButton.Default>
												<CRButton.Default
													size='xSmall'
													disabled={!isNewUser || didCheckRealName}
													palette='gray'
													type='filled'
													onClick={handleClickCheckRealName}
													buttonType='button'>
													실명 확인
												</CRButton.Default>
											</S.RightButtonContainer>
										</div>
									</CRInputLabel>
								);
							}}
							name='rsdnNo'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='소속센터'>
									<CRInput.Selector
										disabled
										items={[
											{
												label: myAccountInfo?.centerNm || '',
												value: myAccountInfo?.centerNm || '',
											},
										]}
										autoComplete
										onChangeValue={onChange}
										currentValue={{
											label: myAccountInfo?.centerNm || '',
											value: myAccountInfo?.centerNm || '',
										}}
										placeholder='센터 선택'
									/>
								</CRInputLabel>
							)}
							name='center'
							control={control}
						/>
						{banner}
					</CRCardFormLayout>
				</S.BodyContent>
			</S.BodyContainer>
			<S.FooterContainer>
				<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
					취소
				</CRButton.Default>
				<CRButton.Default
					style={{
						position: 'relative',
					}}
					disabled={
						!isAllValid || createRecipientMutation.isLoading || createContractMutation.isLoading
					}
					onClick={handleFormSubmit}>
					{createRecipientMutation.isLoading || createContractMutation.isLoading ? (
						<CRSpinner />
					) : (
						'등록'
					)}
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default React.memo(EmployeeContractAddDialog);
