import styled, { css } from 'styled-components';
import { SideModalModeType } from '.';

export const Container = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const MainContentContainer = styled.div<{ $isShowing?: boolean; $maxWidth?: number }>`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const MainContent = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const RightContainer = styled.div<{
	$isShowing: boolean;
	$sideModalMode: SideModalModeType;
	$maxWidth: number;
}>`
	width: ${(props) => (props.$isShowing ? `${(props.$maxWidth ?? 0) / 10}rem` : '0')};
	min-width: ${(props) => (props.$isShowing ? `${(props.$maxWidth ?? 0) / 10}rem` : '0')};
	height: calc(100vh - 5.6rem);
	box-sizing: border-box;
	overflow-y: scroll;
	transform: ${(props) =>
		props.$isShowing ? 'translateX(0)' : `translateX(${(props.$maxWidth ?? 0) / 10}rem)`};
	transition: all 0.2s ease 0s;
	white-space: nowrap;
	background: ${(props) => props.theme.colors.gray99};
	${(props) =>
		props.$sideModalMode === SideModalModeType.FullScreen &&
		css`
			position: absolute;
			z-index: 10;
			right: 0;
		`}
	overflow: hidden;
`;

export const Content = styled.div`
	width: fit-content;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;
