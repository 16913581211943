import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const LeftContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

export const LabelContainer = styled.span`
	display: flex;
	height: 5.8rem;
	align-items: center;
	justify-content: space-between;
`;

export const LabelNormalContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Label = styled.span`
	${(props) => props.theme.typography.label};
	margin-bottom: 0.4rem;
`;

export const ChildrenContainer = styled.span`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: 0.8rem;
`;

export const LeftLabel = styled.span`
	${(props) => props.theme.typography.label};
	width: 13.6rem;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const RightAddonContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;
