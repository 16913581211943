import styled, { css } from 'styled-components';

export const ChipLabel = styled.span`
	${(props) => props.theme.typography.body}
	white-space: nowrap;
	padding-left: 0.4rem;
`;

export const CheckIcon = styled.img`
	display: block;
	width: 2.4rem;
	height: 2.4rem;
`;

export const Container = styled.div<{ disabled: boolean; $isActive: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: fit-content;
	height: 3.2rem;
	padding: 0.4rem 1.2rem 0.4rem 0.8rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};
	user-select: none;
	cursor: pointer;
	${(props) =>
		!props.$isActive &&
		!props.disabled &&
		css`
			&:hover {
				background: ${(props) => props.theme.colors.gray99};
			}

			&:active {
				background: ${(props) => props.theme.colors.gray95};
			}
		`};

	${(props) =>
		props.$isActive &&
		css`
			background: ${(props) => props.theme.colors.primary95};
			border: 0.1rem solid ${(props) => props.theme.colors.primary90};
			& > ${ChipLabel} {
				color: ${(props) => props.theme.colors.primary10};
			}
		`};

	${(props) =>
		props.disabled &&
		css`
			background: ${(props) => props.theme.colors.gray90};
			border: 0.1rem solid transparent;
			& > ${ChipLabel} {
				color: ${(props) => props.theme.colors.gray60};
			}
		`};
`;
