import { styled } from 'styled-components';

export const Container = styled.form`
	position: relative;
	padding: 1.6rem 2.4rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Empty = styled.div`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34.5rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const SubFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const CheckBoxContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;

	& > span {
		${(props) => props.theme.typography.body};
	}
`;
