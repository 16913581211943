import React, {
	ChangeEvent,
	KeyboardEvent,
	KeyboardEventHandler,
	useEffect,
	useRef,
	useState,
} from 'react';

import { CheckOption } from 'components/base/Selections/type';
import { CRText } from 'components/base/CRText';
import Assets from 'assets';
import * as S from './styles';
import SearchDropDown from './SearchDropdown';

interface IProps {
	id?: string;
	currentValue?: CheckOption;
	items?: CheckOption[];
	maxLength?: number;
	placeholder?: string;
	searchKey?: string[];
	onChange?: (...event: any[]) => void;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
}

function CRTableSearchSelector({
	id,
	items,
	placeholder = '',
	maxLength = 50,
	currentValue,
	onChange,
	onPressEnter,
	searchKey = [],
}: IProps): React.ReactElement {
	const [searchText, setSearchText] = useState(currentValue?.label);
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > maxLength) return;
		setSearchText(e.target.value);
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};

	const handleClickSearchItem = (item: CheckOption) => {
		onChange?.(item);
		setSearchText(item.label);
		setIsFocused(false);
	};

	const searchItems = !searchText
		? []
		: items?.filter(
				(item) =>
					item.label.toLowerCase().includes(searchText.toLowerCase()) ||
					item?.children?.find((child) =>
						child.label.toLowerCase().includes(searchText.toLowerCase()),
					) ||
					searchKey?.find((key) =>
						item.value[key].toLowerCase().includes(searchText.toLowerCase()),
					),
		  ) ?? [];

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!ref.current?.contains(e.target as Node)) {
				setIsFocused(false);
				setSearchText(currentValue?.label || '');
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, [currentValue]);

	return (
		<S.Container $isDisabled={false} ref={ref}>
			<S.InputContainer
				onClick={(e) => {
					setIsFocused(true);
				}}>
				<CRText
					text={currentValue?.label || placeholder}
					typography='label'
					color={currentValue?.label ? 'gray10' : 'gray60'}
				/>
				<S.Icon src={Assets.icon.unfoldMore} />
				{isFocused && (
					<S.DropdownContainer>
						<S.InputContainer $isFocused={isFocused} $error={false} disabled={false}>
							<input
								id={id}
								// eslint-disable-next-line
							autoFocus
								value={searchText}
								disabled={false}
								maxLength={maxLength}
								onChange={handleChange}
								onKeyUp={onKeyUp}
							/>
						</S.InputContainer>
						<SearchDropDown
							tableInput
							searchText={searchText}
							searchItems={searchItems}
							onClickSearchItem={handleClickSearchItem}
						/>
					</S.DropdownContainer>
				)}
			</S.InputContainer>
		</S.Container>
	);
}

export default CRTableSearchSelector;
