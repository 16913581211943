import React from 'react';

import Assets from 'assets';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { CRText } from 'components/base/CRText';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';

function DashBoardNotFoundPage(): React.ReactElement {
	return (
		<FlexContainer
			width='100vw'
			height='100vh'
			align='center'
			justify='center'
			style={{ backgroundColor: '#FAFAFA' }}
			direction='column'
			gap='1.6rem'>
			<CRIcon src={Assets.icon.errorPage} alt='error' width='10rem' height='10rem' />
			<FlexContainer direction='column' gap='0.8rem'>
				<CRText typography='h3' color='gray60' text='요청하신 페이지는 없습니다.' />
				<CRText color='gray60' text='입력하신 주소가 정확한지 다시 한 번 확인해 주십시오.' />
			</FlexContainer>
			<a href='/'>
				<CRButton.Default onClick={() => {}} type='outlined' palette='gray'>
					되돌아가기
				</CRButton.Default>
			</a>
		</FlexContainer>
	);
}

export default DashBoardNotFoundPage;
