import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import TaskLayout from 'components/domain/layout/TaskLayout';
import CRScrollSpy from 'components/base/CRScrollSpy';
import CRPageHeader from 'components/base/CRPageHeader';
import CRScrollSpyContainer from 'components/base/CRScrollSpyContainer';
import CRPageFooter from 'components/base/CRPageFooter';
import RouterPath from 'common/router';
import useFullScreen from 'lib/hook/util/useFullScreen';

import * as S from './styles';
import { generateContractScrollSpy } from './constant';
import useContractRenewalTask from '../../../lib/hook/view/contract/useContractRenewalTask';
import DefaultDialog from '../../../components/domain/dialog/DefaultDialog';
import useGlobalLayout from '../../../lib/hook/util/useGlobalLayout';

function ContractRenewalTaskPage(): React.ReactElement {
	const { showDialog, setCustomBackHandler } = useGlobalLayout();
	const { renewalRecipientContract, saveRenewalRecipientContractTemp, form, disabled } =
		useContractRenewalTask();
	const navigate = useNavigate();
	const location = useLocation();

	const isCompleted = disabled;

	const LeftSideComponent = useMemo(() => {
		const scrollSpySections = generateContractScrollSpy(navigate, form, async () => {
			if (isCompleted) return;

			await saveRenewalRecipientContractTemp?.({ disableToast: true });
		});
		return (
			<S.LeftSideComponentContainer>
				<CRScrollSpy currentKey={location.pathname + location.hash} sections={scrollSpySections} />
			</S.LeftSideComponentContainer>
		);
	}, [location, navigate, form.watch()]);

	const title = useMemo(() => {
		if (location.pathname.includes('recipient-basic')) return '수급자 정보';
		if (location.pathname.includes('contract-service')) return '급여 정보';
		if (location.pathname.includes('contract-inspection')) return '계약 점검';
		return '';
	}, [location.pathname]);

	const hasTab = useMemo(
		() => ['contract-service', 'manager'].some((item) => location.pathname.includes(item)),
		[location.pathname],
	);

	const handleClickPrevious = () => {
		const splitPathname = location.pathname.split('/');
		const newPathname = splitPathname.slice(0, splitPathname.length - 1).join('/');
		if (location.pathname.includes('recipient-basic')) return;
		if (location.pathname.includes('contract-service')) {
			navigate(`${newPathname}/recipient-basic`);
			return;
		}
		if (location.pathname.includes('contract-inspection')) {
			navigate(`${newPathname}/contract-service`);
			return;
		}
	};

	const handleClickNext = async () => {
		const splitPathname = location.pathname.split('/');
		const newPathname = splitPathname.slice(0, splitPathname.length - 1).join('/');
		if (location.pathname.includes('recipient-basic')) {
			navigate(`${newPathname}/contract-service`);
			return;
		}
		if (location.pathname.includes('contract-service')) {
			if (!isCompleted) {
				await saveRenewalRecipientContractTemp?.();
			}
			navigate(`${newPathname}/contract-inspection`);
			return;
		}
		if (location.pathname.includes('contract-inspection')) {
			return;
		}
	};

	const goBackPage = () => {
		navigate(`${RouterPath.contract()}`);
	};

	useFullScreen();

	const handleClickCancel = useCallback(() => {
		if (isCompleted) {
			goBackPage();
			return;
		}
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='계약서에 변경된 정보를 저장하고 나갑니다.'
				hideDialog={hideDialog}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						goBackPage();
					},
				}}
				successOption={{
					text: '저장',
					successCallback: async () => {
						await saveRenewalRecipientContractTemp?.();
						hideDialog();
						goBackPage();
					},
				}}
			/>
		));
	}, [isCompleted]);

	useEffect(() => {
		setCustomBackHandler(() => handleClickCancel);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, [handleClickCancel]);

	return (
		<TaskLayout LeftSideComponent={LeftSideComponent}>
			<S.MainComponentContainer $hasTab={hasTab}>
				<CRScrollSpyContainer offset={hasTab ? -50 : 0}>
					<S.ContentContainer>
						<S.HeaderComponentContainer>
							<CRPageHeader headerTitle={title} />
						</S.HeaderComponentContainer>
						<Outlet />
					</S.ContentContainer>
				</CRScrollSpyContainer>
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							leftButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'outlined',
									onClick: handleClickPrevious,
									disabled: location.pathname.includes('recipient-basic'),
									children: '이전',
								},
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'outlined',
									disabled: location.pathname.includes('contract-inspection'),
									onClick: handleClickNext,
									children: '다음',
								},
							]}
							rightButtons={
								isCompleted
									? [
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'text',
												onClick: goBackPage,
												children: '확인',
											},
									  ]
									: [
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'text',
												onClick: handleClickCancel,
												children: '취소',
											},
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'outlined',
												onClick: () => saveRenewalRecipientContractTemp?.(),
												children: '저장',
											},
											{
												palette: 'primary',
												buttonType: 'button',
												type: 'filled',
												onClick: renewalRecipientContract,
												children: '갱신완료',
											},
									  ]
							}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			</S.MainComponentContainer>
		</TaskLayout>
	);
}

export default ContractRenewalTaskPage;
