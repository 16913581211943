import { ko } from 'date-fns/esm/locale';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';

import Colors from 'common/colors';
import dayjs from 'dayjs';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { ProcessedSchedule, ScheduleCategory, ScheduleItemType } from 'types/view/schedule';
import CRButton from '../CRButton';
import CRTableMonthSelector from '../CRTableMonthSelector';
import {
	CALENDAR_FILTER,
	CalendarFilterType,
	EMPLOYEE_LABELS,
	RECIPIENT_LABELS,
} from './constants';
import * as S from './styles';

export interface ReactDatePickerCustomHeaderProps {
	monthDate: Date;
	date: Date;
	customHeaderCount: number;
	prevMonthButtonDisabled: boolean;
	nextMonthButtonDisabled: boolean;
	prevYearButtonDisabled: boolean;
	nextYearButtonDisabled: boolean;
	changeYear(year: number): void;
	changeMonth(month: number): void;
	decreaseMonth(): void;
	increaseMonth(): void;
	decreaseYear(): void;
	increaseYear(): void;
}

export interface Props {
	type?: 'employee' | 'recipient';
	date?: Date;
	item?: ProcessedSchedule | null;
	height?: string;
	onChange: (date: Date) => void;
	onClickDay?: (date: Date) => void;
	renderRightButton?: ReactNode;
}

export default function CRScheduleCalendar({
	type = 'recipient',
	date,
	height,
	item,
	onChange,
	onClickDay,
	renderRightButton,
}: Props) {
	const [currentFilter, setCurrentFilter] = useState<CalendarFilterType>(CalendarFilterType.ALL);
	const { data: myAccountInfo } = useMyAccountInfo();

	const schedules = useMemo(() => {
		if (!item?.schedules?.length) return [];

		if (currentFilter === CalendarFilterType.ALL) return item?.schedules;
		if (currentFilter === CalendarFilterType.MY_CENTER) {
			return item.schedules.map((schedule) => ({
				...schedule,
				items: schedule.items.filter(
					(item) =>
						(item.kind === ScheduleCategory.MY_CENTER &&
							item?.centerId === myAccountInfo?.centerId) ||
						item.kind === ScheduleCategory.HOLIDAYS,
				),
			}));
		}
		if (currentFilter === CalendarFilterType.CARING_CENTER) {
			return item.schedules.map((schedule) => ({
				...schedule,
				items: schedule.items.filter(
					(item) =>
						(item?.centerId !== myAccountInfo?.centerId &&
							item.kind === ScheduleCategory.CARING_CENTER) ||
						item.kind === ScheduleCategory.HOLIDAYS,
				),
			}));
		}
		if (currentFilter === CalendarFilterType.OTHER) {
			return item.schedules.map((schedule) => ({
				...schedule,
				items: schedule.items.filter(
					(item) => item.kind === ScheduleCategory.OTHER || item.kind === ScheduleCategory.HOLIDAYS,
				),
			}));
		}
		return item.schedules;
	}, [item?.schedules, currentFilter]);

	const getTaskColor = (taskCategory: ScheduleCategory, hasError = false) => {
		if (hasError) return Colors.primary60;
		switch (taskCategory) {
			case ScheduleCategory.OTHER: {
				return Colors.yellow;
			}
			case ScheduleCategory.MY_CENTER: {
				return Colors.green;
			}
			case ScheduleCategory.CARING_CENTER: {
				return Colors.blue;
			}
			default: {
				return Colors.primary60;
			}
		}
	};

	const handleClickFilter = (value: CalendarFilterType) => setCurrentFilter(value);

	const renderCustomHeader = (e: ReactDatePickerCustomHeaderProps) => {
		const label = type === 'recipient' ? RECIPIENT_LABELS : EMPLOYEE_LABELS;

		return (
			<S.CustomHeaderContainer>
				<S.HeaderTop>
					<CRTableMonthSelector value={date || new Date()} onChange={onChange} />
					{renderRightButton && renderRightButton}
				</S.HeaderTop>
				<S.HeaderBottom>
					<S.FilterButtonContainer>
						{CALENDAR_FILTER.map((item, index) => (
							<Fragment key={item.value}>
								<CRButton.Default
									type={item.value === currentFilter ? 'filled' : 'outlined'}
									palette='gray'
									size='small'
									onClick={() => handleClickFilter(item.value)}>
									{item.value === CalendarFilterType.OTHER
										? type === 'recipient'
											? '타급여'
											: '타근무'
										: item.label}
								</CRButton.Default>
								{index === 0 && <S.Divider />}
							</Fragment>
						))}
					</S.FilterButtonContainer>
					<S.LabelContainer>
						{label.map((e) => (
							<S.Label key={e.label}>
								<S.TaskCircle $taskColor={e.taskColor} />
								<span>{e.label}</span>
							</S.Label>
						))}
					</S.LabelContainer>
				</S.HeaderBottom>
			</S.CustomHeaderContainer>
		);
	};

	const renderCalendarTaskItem = (task: ScheduleItemType, index: number, hasError = false) =>
		task.kind === ScheduleCategory.HOLIDAYS ? (
			<S.DayTaskItem $isHoliday>
				<S.TaskTitle>
					<S.TaskText>{task.title} </S.TaskText>
				</S.TaskTitle>
			</S.DayTaskItem>
		) : (
			<S.DayTaskItem key={index}>
				<S.TaskTitle>
					<S.TaskCircle $taskColor={getTaskColor(task.kind, hasError)} />
					<S.TaskText>{task.title}</S.TaskText>
				</S.TaskTitle>
				<S.TimeTable>{`${task.startTime} ~ ${task.endTime}${
					task.name ? ` ${task.name}` : ''
				}`}</S.TimeTable>
			</S.DayTaskItem>
		);

	const renderCustomDay = (e: number, date: Date) => {
		const currentDate = dayjs(date).format('YYYY-MM-DD');

		const currentTask = schedules?.find((schedule) => schedule.date === currentDate);

		const isFirstDay = dayjs(date).get('date') === 1;

		const isSunday = dayjs(date).get('day') === 0;

		const isToday = dayjs().format('YYMMDD') === dayjs(date).format('YYMMDD');

		const normalDay = isToday ? (
			<S.Today>{dayjs(date).get('date')}</S.Today>
		) : (
			dayjs(date).format('D')
		);

		const firstDay = isToday ? (
			<>
				{dayjs(date).get('month') + 1}월<S.Today>{dayjs(date).get('date')}</S.Today>일
			</>
		) : (
			dayjs(date).set('date', 1).format('M월 D일')
		);

		return (
			<S.CustomDay $hasError={!!currentTask?.hasError} onClick={() => onClickDay?.(date)}>
				<S.DayNumberContainer $isHoliday={false} $isSunday={!!isSunday}>
					{isFirstDay ? firstDay : normalDay}
				</S.DayNumberContainer>
				<S.DayTaskContainer>
					{currentTask?.items
						.slice(0, 3)
						.map((item, index) => renderCalendarTaskItem(item, index, !!currentTask?.hasError))}
					{currentTask?.items && currentTask.items.length > 3 && (
						<S.DayTaskItem>
							<S.TaskText>{`외 ${currentTask.items.length - 3}건`} </S.TaskText>
						</S.DayTaskItem>
					)}
				</S.DayTaskContainer>
			</S.CustomDay>
		);
	};

	return (
		<S.Container $height={height}>
			<DatePicker
				selected={date}
				locale={ko}
				minDate={dayjs(date).startOf('month').toDate()}
				maxDate={dayjs(date).endOf('month').toDate()}
				renderCustomHeader={renderCustomHeader}
				renderDayContents={renderCustomDay}
				inline
				onChange={onChange}
			/>
		</S.Container>
	);
}
