import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { CheckOption } from 'components/base/Selections/type';
import AcquirementHelp from 'pages/dashboard/InsuranceRequestPage/RequestTypeForm/AcquirementHelp';
import LossHelp from 'pages/dashboard/InsuranceRequestPage/RequestTypeForm/LossHelp';
import AcquirementCancelHelp from 'pages/dashboard/InsuranceRequestPage/RequestTypeForm/AcquirementCancelHelp';
import ChangeChargeHelp from 'pages/dashboard/InsuranceRequestPage/RequestTypeForm/ChangeChargeHelp';
import { InsuranceReqType } from 'pages/dashboard/InsuranceRequestPage/RequestTypeForm';
import LossCancelHelp from 'pages/dashboard/InsuranceRequestPage/RequestTypeForm/LossCancelHelp';
import * as S from './styles';

interface Props {
	requestType: CheckOption;
}

export default function RequestTypeView({ requestType }: Props) {
	return (
		<Accordion.Item value='신고 유형' asChild>
			<TaskAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<TaskAccordion.Trigger>신고 유형</TaskAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<TaskAccordion.Content>
						<S.FormContainer>
							<S.FormItemContainer
								style={{
									width: '57.2rem',
								}}>
								<CRInputLabel
									label='신고 유형'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<CRInput.Selector
										disabled
										currentValue={requestType}
										items={[requestType]}
										placeholder='신고 유형 선택'
									/>
								</CRInputLabel>
							</S.FormItemContainer>
							{requestType?.value === InsuranceReqType.취득 && <AcquirementHelp />}
							{requestType?.value === InsuranceReqType.상실 && <LossHelp />}
							{requestType?.value === InsuranceReqType.취득취소 && <AcquirementCancelHelp />}
							{requestType?.value === InsuranceReqType.상실취소 && <LossCancelHelp />}
							{requestType?.value === InsuranceReqType.신고금액변경 && <ChangeChargeHelp />}
						</S.FormContainer>
					</TaskAccordion.Content>
				</Accordion.Content>
			</TaskAccordion.Item>
		</Accordion.Item>
	);
}
