import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const INSURANCE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '',
		sortKey: '',
		checkbox: true,
	},
	{
		width: '9rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '10rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '10rem',
		label: '생년 월일',
		sortKey: '생년 월일',
	},
	{
		width: '15rem',
		label: '득실 타입',
		sortKey: '득실 타입',
	},
	{
		width: '14rem',
		label: '국민연금 신고 금액',
		sortKey: '국민연금 신고 금액',
	},
	{
		width: '14rem',
		label: '국민연금 신고 일자',
		sortKey: '국민연금 신고 일자',
	},
	{
		width: '14rem',
		label: '건강보험 신고 금액',
		sortKey: '건강보험 신고 금액',
	},
	{
		width: '14rem',
		label: '건강보험 신고 일자',
		sortKey: '건강보험 신고 일자',
	},
	{
		width: '14rem',
		label: '고용보험 신고 금액',
		sortKey: '고용보험 신고 금액',
	},
	{
		width: '14rem',
		label: '고용보험 신고 일자',
		sortKey: '고용보험 신고 일자',
	},
	{
		width: '14rem',
		label: '산재보험 신고 금액',
		sortKey: '산재보험 신고 금액',
	},
	{
		width: '14rem',
		label: '산재보험 신고 일자',
		sortKey: '산재보험 신고 일자',
	},
	{
		width: '15rem',
		label: '생성일시',
		sortKey: '생성일시',
	},
	{
		width: '10.8rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];

export const TAG_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '정상',
		label: '정상',
		color: 'green',
	},
	{
		key: '오류',
		label: '오류',
		color: 'red',
	},
];
