import React, { ChangeEvent, useState } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { useInquireIndividualInfo, useMyAccountInfo, useMyMenuInfo } from 'lib/hook/react-query';
import CRDialog from 'components/base/CRDialog';
import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import { IndividualInfoCode } from 'types/api/common';
import { myTabMenuAdapter } from 'lib/adapter/common';
import * as S from './styles';

interface Props {
	menuId?: number;
	onSuccess: () => void;
}
export default function RsdReadingDialog({ menuId, onSuccess }: Props) {
	const [reason, setReason] = useState('');
	const { hideDialog } = useDialog();
	const { mutate: inquireIndividualInfo } = useInquireIndividualInfo(() => {
		onSuccess?.();
		hideDialog();
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: menus } = useMyMenuInfo(myTabMenuAdapter);
	const handleChangeReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setReason(e.target.value);
	};

	const handleSubmit = () => {
		if (!menus?.origin?.length || !myAccountInfo?.memberAccountId || !menuId) return;
		const params = {
			memberId: myAccountInfo.memberAccountId,
			menuId,
			personalInfoBrowseDesc: reason,
			personalInfoBrowseItems: [
				{
					personalInfoBrowseDivCd: IndividualInfoCode['CMN175.10'],
				},
			],
		};
		inquireIndividualInfo(params);
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='주민등록번호 열람'
			body={
				<S.Container>
					<CRInputLabel label='사유' isRequired>
						<CRInput.TextArea
							placeholder='사유 입력'
							onChange={handleChangeReason}
							value={reason}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.FooterContainer>
					<CRButton.Default
						type='text'
						palette='gray'
						onClick={() => {
							hideDialog?.();
						}}>
						취소
					</CRButton.Default>
					<CRButton.Default disabled={!reason} onClick={handleSubmit}>
						확인
					</CRButton.Default>
				</S.FooterContainer>
			}
		/>
	);
}
