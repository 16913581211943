import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useUpdateEmployeeTagCall = useCRMutation(
	endpoint.updateEmployeeTagCall.key,
	'updateEmployeeTagCall',
);

export const useDeleteEmployee = useCRMutation(
	endpoint.updateEmployeeAffiliation.key,
	'deleteEmployee',
);

export const useEmployeesExcelInfo = useCRMutation(
	endpoint.getEmployeesExcelInfo.key,
	'getEmployeesExcelInfo',
);
