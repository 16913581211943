import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	height: 8rem;
	flex-direction: row;
	align-items: center;
`;

export const HeaderTitle = styled.div`
	flex: 1;
	${(props) => props.theme.typography.h3}
	color: ${(props) => props.theme.colors.gray10}
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 0.8rem;
`;
