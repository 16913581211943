import { toDataURL } from 'lib/util/file';
import { FileDetailDTO } from 'types/dto';
import { useCreateDownloadUrl } from '../react-query';

export const useDownload = () => {
	const { mutateAsync: createDownloadUrl } = useCreateDownloadUrl();

	const downloadFilesWithFiles = async (files?: FileDetailDTO[]) => {
		if (!files?.length) return;

		const downloadPromises = [];

		// eslint-disable-next-line
		for (const item of files) {
			const objectKey = `${item.filePathNm}${item.transFileNm}`;
			if (objectKey) {
				downloadPromises.push(
					createDownloadUrl({ objectKey }).then((downloadResponse) => {
						if (downloadResponse.data?.objectKey && downloadResponse.data?.url) {
							const { url } = downloadResponse.data;
							const imgPath = url;
							return { url: imgPath, fileName: item.originFileNm };
						}
						return null;
					}),
				);
			}
		}

		const downloadResults = await Promise.all(downloadPromises);
		// eslint-disable-next-line
		for (const result of downloadResults) {
			if (result) {
				const { url, fileName } = result;
				const a = document.createElement('a');
				// eslint-disable-next-line
				a.href = await toDataURL(url);
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}
		}
	};

	const downloadFileByObjectKey = async (fileInfo: { fileName?: string; objectKey?: string }[]) => {
		const downloadPromises = [];
		/* eslint-disable */ 
		for (const { objectKey, fileName } of fileInfo) {
			if (objectKey && fileName) {
				downloadPromises.push(
					createDownloadUrl({ objectKey }).then((downloadResponse) => {
						if (downloadResponse.data?.objectKey && downloadResponse.data?.url) {
							const { url } = downloadResponse.data;
							const imgPath = url;
							return { url: imgPath, fileName: fileName };
						}
						return null;
					}),
				);
			}
		}

		const downloadResults = await Promise.all(downloadPromises);
		// eslint-disable-next-line
		for (const result of downloadResults) {
			if (result) {
				const { url, fileName } = result;
				const a = document.createElement('a');
				// eslint-disable-next-line
				a.href = await toDataURL(url);
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}
		}
	};

	const openFile = async (file: FileDetailDTO) => {
		const objectKey = `${file.filePathNm}${file.transFileNm}`;
			createDownloadUrl({ objectKey}).then((downloadResponse) => {
				if (downloadResponse.data?.objectKey && downloadResponse.data?.url) {
					const { url } = downloadResponse.data;

					if (url) {
						window.open(url)
					}

				}
			})
	};


	return { downloadFiles: downloadFilesWithFiles, downloadFileByObjectKey ,openFile};
};
