import CRDialog from 'components/base/CRDialog';
import React from 'react';
import { v4 } from 'uuid';
import useDialog from 'lib/hook/util/useDialog';
import { AutomationUploadDTO } from 'types/api/common';
import * as S from './styles';

interface Props {
	item: AutomationUploadDTO;
}

export function SalarySyncResultDialog({ item }: Props) {
	const { hideDialog } = useDialog();
	const processedData = item.uploadResultDesc.split('\n');
	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='동기화 결과'
			body={
				<S.Container>
					<S.ResultItem>
						<S.Status>
							<S.StatusCircle $status='complete' />
							완료 {item.uploadCompleteCnt ?? 0}건
						</S.Status>
						<S.Status>
							<S.StatusCircle $status='fail' />
							실패 {item.uploadRemainCnt ?? 0}건
						</S.Status>
					</S.ResultItem>
					{processedData.map((item) => (
						<S.ResultItem key={v4()}>{item}</S.ResultItem>
					))}
				</S.Container>
			}
		/>
	);
}
