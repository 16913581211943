import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from '../base/useCRQuery';

export const useCenterSalaryFeeDetail = useCRQuery(
	endpoint.getCenterSalaryFeeDetail.key,
	'getCenterSalaryFeeDetail',
	{
		enabledKey: ['centerId', 'salaryFeeStandardStartDate', 'salaryFeeStandardEndDate'],
	},
);
