import React from 'react';
import CRInput from 'components/base/CRInput';
import { useController, useFormContext } from 'react-hook-form';

interface Props extends React.ComponentProps<typeof CRInput.TableInput> {
	name: string;
}

function CRFormTableInput({ name, ...rest }: Props) {
	const methods = useFormContext();
	const { field } = useController({ name, control: methods.control });

	return (
		<CRInput.TableInput
			{...rest}
			{...field}
			status={methods.formState.errors[name]?.message ? 'error' : 'default'}
			addOnBottom={
				methods.formState.errors[name]?.message && `${methods.formState.errors[name]?.message}`
			}
		/>
	);
}

export default CRFormTableInput;
