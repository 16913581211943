import React, { useState } from 'react';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import {
	useRecipientIntegratedAssessmentPreviousData,
	useRecipientIntegratedAssessmentPreviousList,
} from 'lib/hook/react-query';
import { IntegratedAssessmentPreviousListDataDTO } from 'types/dto';

import { Toast } from 'components/base/CRToast';
import * as S from './styles';

interface Props {
	title?: string;
	recipientId?: string;
	paperTypeCd?: string;
	hideDialog?: () => void;
	onClickLoad?: (item: any) => void;
}

function IntegratedAssessmentPreviousDataDialog({
	title = '',
	recipientId = '',
	paperTypeCd = '',
	hideDialog,
	onClickLoad,
}: Props): React.ReactElement {
	const [selectedData, setSelectedData] = useState<IntegratedAssessmentPreviousListDataDTO | null>(
		null,
	);
	const { data: previousDataList = [] } = useRecipientIntegratedAssessmentPreviousList({
		recipientId,
		paperTypeCd,
	});

	const { data: previousData, isLoading } = useRecipientIntegratedAssessmentPreviousData({
		recipientId,
		recipientIaId: selectedData?.recipientIa.toString() ?? '',
	});

	const handleClickData = (item: IntegratedAssessmentPreviousListDataDTO) => {
		setSelectedData(item);
	};

	const handleClickLoadPreviousData = () => {
		if (!previousData) return;

		Toast.success(`정상적으로 이력을 불러왔습니다.`);
		onClickLoad?.(previousData);
		hideDialog?.();
	};

	return (
		<CRDialog
			type='S'
			title={title}
			onClickClose={hideDialog}
			body={
				<S.Container>
					{(previousDataList ?? []).map((item) => (
						<S.ContentContainer
							onClick={() => handleClickData(item)}
							$isSelected={selectedData?.recipientIa === item.recipientIa}>
							<S.ContentDateText>
								{dayjs(item.writeDate).format('YYYY년 MM월 DD일')}
							</S.ContentDateText>
							<S.ContentDescriptionText>{`${item.centerNm} ${item.writerNm}`}</S.ContentDescriptionText>
						</S.ContentContainer>
					))}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						onClick={handleClickLoadPreviousData}
						disabled={isLoading}>
						불러오기
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default IntegratedAssessmentPreviousDataDialog;
