import React, { useState } from 'react';

import { ApprovalItem } from 'types/view/approval';
import CRTable from 'components/base/CRTable';
import ApprovalProgressTable from 'components/domain/table/ApprovalProgressTable';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import Assets from 'assets';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { STAFF_MOCK } from '../DocumentDetailPage/mock';

export default function ApprovalProgressTab(): React.ReactElement {
	const [currentFilter, setCurrentFilter] = useState('all');
	const navigate = useNavigate();

	const dummy: ApprovalItem[] = [
		{
			id: '1032',
			title: '[업무] 4월 청구',
			center: '마음센터',
			author: '킴사복',
			createdAt: '23.10.11 00:00',
			approvalLine: [STAFF_MOCK.TAEYOON, STAFF_MOCK.MEERAY, STAFF_MOCK.CHAEWON, STAFF_MOCK.PORORO],
			status: '반려',
		},
		{
			id: '1033',
			title: '[업무] 3월 급여',
			center: '마음센터',
			author: '양사복',
			createdAt: '23.00.00 00:00',
			approvalLine: [STAFF_MOCK.TOTORO, STAFF_MOCK.PORORO, STAFF_MOCK.CRONG],
			status: '승인완료',
		},
		{
			id: '1034',
			title: '[업무] 4월 급여',
			center: '마음센터',
			author: '손사복',
			createdAt: '23.00.00 00:00',
			approvalLine: [STAFF_MOCK.TOTORO, STAFF_MOCK.PORORO, STAFF_MOCK.CRONG],
			status: '진행중',
		},
	];

	const renderCustomFilter = () => (
		<S.CustomFilterContainer>
			<S.CustomFilterChip
				onClick={() => setCurrentFilter('all')}
				$isActive={currentFilter === 'all'}>
				전체
			</S.CustomFilterChip>
			<S.Divider />
			<S.CustomFilterRight>
				<S.CustomFilterChip
					onClick={() => setCurrentFilter('error')}
					$isActive={currentFilter === 'error'}>
					승인필요
				</S.CustomFilterChip>
				<S.CustomFilterChip
					onClick={() => setCurrentFilter('normal')}
					$isActive={currentFilter === 'normal'}>
					진행중
				</S.CustomFilterChip>
			</S.CustomFilterRight>
		</S.CustomFilterContainer>
	);

	const handleClickRow = (item: ApprovalItem) => {
		navigate(`${RouterPath.approvalOperate().document.key}/${item.id}`);
	};

	const onClickNewDocumentPage = () => {
		navigate(RouterPath.approvalOperate().new.key);
	};

	const renderRightButton = (
		<CRButton.IconButton
			size='xSmall'
			iconLeft={Assets.icon.add}
			type='filled'
			palette='primary'
			onClick={onClickNewDocumentPage}>
			신규 문서
		</CRButton.IconButton>
	);

	return (
		// <S.Container>
		<CRTable.BackBlind>
			<ApprovalProgressTable
				currentFilter={[] as any}
				items={dummy}
				onClickRow={handleClickRow}
				renderRightButton={renderRightButton}
				renderCustomFilter={renderCustomFilter()}
			/>
		</CRTable.BackBlind>
	);
}
