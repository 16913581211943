import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from 'lib/hook/react-query/base/useCRQuery';

export const useMonthlyBurdenCharges = useCRQuery(
	endpoint.getMonthlyBurdenCharges.key,
	'getMonthlyBurdenCharges',
	{
		enabledKey: ['centerId'],
	},
);

export const useMonthlyBurdenChargeDetail = useCRQuery(
	endpoint.getMonthlyBurdenChargeDetail.key,
	'getMonthlyBurdenChargeDetail',
);

export const useMonthlyBurdenChargeSummary = useCRQuery(
	endpoint.getMonthlyBurdenChargesSummary.key,
	'getMonthlyBurdenChargesSummary',
	{
		enabledKey: ['centerId'],
	},
);

export const useBurdenPayHistories = useCRQuery(
	endpoint.getBurdenPayHistories.key,
	'getBurdenPayHistories',
	{
		enabledKey: ['centerId'],
	},
);

export const useBurdenPayHistoryDetail = useCRQuery(
	endpoint.getBurdenPayHistoryDetail.key,
	'getBurdenPayHistoryDetail',
);
