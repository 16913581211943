import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import { RecipientScheduleAutomationFormViewType } from 'types/view/recipient';
import { useCommonCodes } from 'lib/hook/react-query';

import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { displayPadTime } from 'lib';
import { CRText } from 'components/base/CRText';
import { commonCodeAdapter } from 'lib/adapter/common';
import { CheckOption } from 'components/base/Selections/type';
import { EmployeeDTO } from 'types/api';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';

import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import * as S from './styles';

interface Props {
	items?: RecipientScheduleAutomationFormViewType[];
	employees?: EmployeeDTO[];
	centerManagers?: CheckOption[];
	onChangeMode: () => void;
}

function RecipientScheduleAutomationView({
	items,
	employees,
	centerManagers,
	onChangeMode,
}: Props): React.ReactElement {
	const { data: commonCodes } = useCommonCodes(
		{ comCdGroupNms: ['CMN083', 'CMN181'] },
		commonCodeAdapter,
	);

	const { currentRecipientStatus } = useRecipientPage();

	const serviceKindOptions = useMemo(() => commonCodes?.CMN083 || [], [commonCodes]);
	const automationFeeOptions = useMemo(() => commonCodes?.CMN181 || [], [commonCodes]);

	const getServiceInfo = (item: RecipientScheduleAutomationFormViewType) => {
		if (!serviceKindOptions.length || !automationFeeOptions.length || !item) return '';
		const serviceKind = serviceKindOptions.find((option) => option.value === item.serviceKindCd);
		const automationFee = automationFeeOptions.find(
			(option) => option.value === item.automationFeeCd,
		);
		const result = (
			<S.ServiceInfoContainer>
				{serviceKind?.label || '-'}
				{automationFee?.label && (
					<CRText text={`(${automationFee?.label})`} color='gray60' typography='label' />
				)}
			</S.ServiceInfoContainer>
		);
		return result;
	};

	const getProgramManager = (item: RecipientScheduleAutomationFormViewType) => {
		const programManager = centerManagers?.find((manager) => manager.value === item.managerId);

		return programManager ? `${programManager.label}(${programManager.value})` : '-';
	};

	const getTime = (item: RecipientScheduleAutomationFormViewType) => {
		const result = `${displayPadTime(item.time.serviceStartTime)} ~ ${displayPadTime(
			item.time.serviceEndTime,
		)}`;
		return result;
	};

	const getMonthValue = (item: RecipientScheduleAutomationFormViewType) => {
		if (!item.monthUseCnt && !item.monthAmt) {
			return <S.TableLabelColumn style={{ width: '13rem' }} colSpan={2} />;
		}
		if (item.monthUseCnt) {
			return (
				<>
					<S.TableLabelColumn style={{ width: '13rem' }}>
						월 최대
						<br />
						이용횟수
					</S.TableLabelColumn>
					<S.TableValueColumn>{item.monthUseCnt} 회</S.TableValueColumn>
				</>
			);
		}
		return (
			<>
				<S.TableLabelColumn style={{ width: '13rem' }}>최대 월금액</S.TableLabelColumn>
				<S.TableValueColumn>
					{item.monthAmt ? Number(item.monthAmt).toLocaleString() : ''} 원
				</S.TableValueColumn>
			</>
		);
	};

	const getEmployeeName = (item: RecipientScheduleAutomationFormViewType) => {
		if (!employees?.length || !item) return '';
		const employee1 = employees.find(
			(employee) => employee.employeeId === item.employeeId1?.value?.id,
		);
		const employee2 = employees.find(
			(employee) => employee.employeeId === item.employeeId2?.value?.id,
		);
		const result = `${employee1 ? employee1.korMemberNm : '-'}${
			employee2 ? `, ${employee2.korMemberNm}` : ''
		}`;
		return result;
	};

	const getDays = (item: RecipientScheduleAutomationFormViewType) => {
		const korDays: string[] = [];
		const displayDayOfWeek = (dayOfWeek: DayOfTheWeek) => {
			switch (dayOfWeek) {
				case DayOfTheWeek.Mon:
					return '월';
				case DayOfTheWeek.Tue:
					return '화';
				case DayOfTheWeek.Wed:
					return '수';
				case DayOfTheWeek.Thu:
					return '목';
				case DayOfTheWeek.Fri:
					return '금';
				case DayOfTheWeek.Sat:
					return '토';
				case DayOfTheWeek.Sun:
					return '일';
				default:
					return '';
			}
		};

		item.days.forEach((day) => korDays.push(displayDayOfWeek(day as DayOfTheWeek)));

		return korDays.filter(Boolean).join(', ');
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';
	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');

	const monthMethodOption = [
		{
			label: '월 이용횟수',
			value: 'count',
		},
		{
			label: '월금액',
			value: 'amount',
		},
	];

	const holidayExceptionOptions = [
		{
			label: '미포함',
			value: true,
		},
		{
			label: '포함',
			value: false,
		},
	];

	const monthAmountOverOptions = [
		{
			label: '초과 불가',
			value: false,
		},
		{
			label: '초과 허용',
			value: true,
		},
	];
	return (
		<S.Container>
			<S.Label>
				일정 등록 자동화 설정
				{!isResign && (
					<S.ButtonContainer>
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			{items && items?.length > 0 ? (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{items?.map((item, index) => (
						<>
							<S.ItemHeader>
								<CRText typography='label'>{`급여일정 ${formatIndex(index)}`}</CRText>
							</S.ItemHeader>
							<S.Table $isDisabled={isResign}>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '13rem' }}>급여 종류</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										{getServiceInfo(item)}
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '13rem' }}>직원</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										{getEmployeeName(item)}
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '13rem' }}>이용 요일</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<S.LabelContainer
											style={{
												justifyContent: 'space-between',
											}}>
											{getDays(item)}
											<CRCheckBoxGroup
												disabled
												checkType='multiple'
												type='checkbox'
												gap={0.8}
												value={item.totalDayYn}
												options={[
													{
														label: '전체 선택',
														value: true,
													},
												]}
											/>
										</S.LabelContainer>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '13rem' }}>등록 기준</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<CRCheckBoxGroup
											disabled
											checkType='single'
											type='radio'
											gap={0.8}
											value={
												!item?.monthUseCnt && !item.monthAmt
													? []
													: [item?.monthUseCnt ? monthMethodOption[0] : monthMethodOption[1]]
											}
											options={monthMethodOption}
										/>
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '13rem' }}>이용일정</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										{getTime(item)}
									</S.TableValueColumn>
									{getMonthValue(item)}
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '13rem' }}>
										공휴일
										<br /> 근무일정
									</S.TableLabelColumn>
									<S.TableValueColumn
										style={{
											verticalAlign: 'middle',
										}}>
										<CRCheckBoxGroup
											disabled
											checkType='single'
											type='radio'
											gap={0.8}
											value={[
												item?.holidayExceptYn
													? holidayExceptionOptions[0]
													: holidayExceptionOptions[1],
											]}
											options={holidayExceptionOptions}
										/>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '13rem' }}>월 한도액</S.TableLabelColumn>
									<S.TableValueColumn>
										<CRCheckBoxGroup
											disabled
											checkType='single'
											type='radio'
											gap={0.8}
											value={[
												item?.monthAmtOverYn
													? monthAmountOverOptions[1]
													: monthAmountOverOptions[0],
											]}
											options={monthAmountOverOptions}
										/>

										{/* {docReceiveMethodOptions?.find(
									(option) => option?.value === item.docReceiveMethodCd,
								)?.label || '-'} */}
									</S.TableValueColumn>
								</S.TableRow>
								{item.managerId && (
									<S.TableRow>
										<S.TableLabelColumn style={{ width: '13rem' }}>
											프로그램 <br /> 관리자
										</S.TableLabelColumn>
										<S.TableValueColumn style={{ width: '24rem' }}>
											{getProgramManager(item)}
										</S.TableValueColumn>
										<S.TableLabelColumn colSpan={2} />
									</S.TableRow>
								)}
							</S.Table>
						</>
					))}
				</>
			) : (
				<S.EmptyContainer>
					<CRText typography='label' color='gray60' text='등록된 급여일정이 없습니다.' />
				</S.EmptyContainer>
			)}
		</S.Container>
	);
}

export default RecipientScheduleAutomationView;
