import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRRequiredMark from 'components/base/CRRequiredMark';
import * as S from './styles';
import { integratedAssessmentDesireBasicStatusQuestions } from './constant';
import IntegratedAssessmentDesireBasicStatusHeader from './IntegratedAssessmentDesireBasicStatusHeader';
import CRCheckBox from '../../../base/Selections/CRCheckBox';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesireBasicStatusForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireBasicStatusHeader />
				<S.TableBody>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => (
							<S.TableBodyRow>
								<S.TableBodyColumn rowSpan={5}>영양</S.TableBodyColumn>
								<S.TableBodyColumn>
									{integratedAssessmentDesireBasicStatusQuestions[0].label}
									{showGenerateRequiredFields &&
										integratedAssessmentDesireBasicStatusQuestions[0].required?.includes(
											'generate',
										) && <CRRequiredMark />}
								</S.TableBodyColumn>
								<S.TableBodyColumn>
									<S.TableContentVerticalContainer>
										{(integratedAssessmentDesireBasicStatusQuestions[0].answer ?? []).map(
											(answer) => (
												<CRCheckBox
													checkBoxType={integratedAssessmentDesireBasicStatusQuestions[0].type}
													checked={
														form.watch(integratedAssessmentDesireBasicStatusQuestions[0].key) ===
														answer.value
													}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target.checked) {
															onChange(answer.value);
														} else {
															onChange(undefined);
														}
													}}>
													{answer.label}
												</CRCheckBox>
											),
										)}
									</S.TableContentVerticalContainer>
								</S.TableBodyColumn>
							</S.TableBodyRow>
						)}
						name={integratedAssessmentDesireBasicStatusQuestions[0].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[1];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[1].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<CRCheckBox
														checkBoxType={item.type}
														checked={form.watch(item.key)?.includes(answer.value)}
														onChange={(event: ChangeEvent<HTMLInputElement>) => {
															const targetValue = value as string[];

															if (event.target.checked) {
																onChange([...(targetValue ?? []), answer.value]);
															} else {
																onChange(
																	(targetValue ?? []).filter((item) => item !== answer.value),
																);
															}
														}}>
														{answer.label}
													</CRCheckBox>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireBasicStatusQuestions[1].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value}
														/>
													)}
													name='식사형태기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[1].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[2];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[2].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<CRCheckBox
														checkBoxType={item.type}
														checked={form.watch(item.key)?.includes(answer.value)}
														onChange={(event: ChangeEvent<HTMLInputElement>) => {
															const targetValue = value as string[];

															if (event.target.checked) {
																onChange([...(targetValue ?? []), answer.value]);
															} else {
																onChange(
																	(targetValue ?? []).filter((item) => item !== answer.value),
																);
															}
														}}>
														{answer.label}
													</CRCheckBox>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireBasicStatusQuestions[2].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value}
														/>
													)}
													name='소화상태기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[2].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[3];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[3].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType={item.type}
													checked={form.watch(item.key) === answer.value}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target.checked) {
															onChange(answer.value);
														} else {
															onChange(undefined);
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[3].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[4];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[4].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType={item.type}
													checked={form.watch(item.key) === answer.value}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target.checked) {
															onChange(answer.value);
														} else {
															onChange(undefined);
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[4].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[5];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn rowSpan={2}>배설</S.TableBodyColumn>
									<S.TableBodyColumn>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[5].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType={item.type}
													checked={form.watch(item.key)?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const targetValue = value as string[];

														if (event.target.checked) {
															onChange([...(targetValue ?? []), answer.value]);
														} else {
															onChange((targetValue ?? []).filter((item) => item !== answer.value));
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[5].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[6];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[6].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentContainer>
											{(item.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType={item.type}
													checked={form.watch(item.key)?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const targetValue = value as string[];

														if (event.target.checked) {
															onChange([...(targetValue ?? []), answer.value]);
														} else {
															onChange((targetValue ?? []).filter((item) => item !== answer.value));
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[6].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[7];

							return (
								<S.TableBodyRow>
									<S.TableBodyColumn colSpan={2}>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[7].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											{(item.answer ?? []).map((answer) => (
												<>
													<CRCheckBox
														checkBoxType={item.type}
														checked={form.watch(item.key) === answer.value}
														onChange={(event: ChangeEvent<HTMLInputElement>) => {
															if (event.target.checked) {
																onChange(answer.value);
															} else {
																onChange(undefined);
															}
														}}>
														{answer.label}
													</CRCheckBox>
													{answer.options && (
														<S.TableContentContainer>
															<Controller
																render={({
																	field: { ref, onChange, value: optionValue },
																	formState: { errors },
																}) => (
																	<S.TableOptionContainer>
																		(
																		{answer.options?.map((option) => (
																			<CRCheckBox
																				checkBoxType='checkbox'
																				checked={form.watch('보행상태')?.includes(option.value)}
																				onChange={(event: ChangeEvent<HTMLInputElement>) => {
																					const targetValue = optionValue as string[];

																					if (event.target.checked) {
																						onChange([...(targetValue ?? []), option.value]);
																					} else {
																						onChange(
																							(targetValue ?? []).filter(
																								(item) => item !== option.value,
																							),
																						);
																					}
																				}}>
																				{option.label}
																			</CRCheckBox>
																		))}
																		)
																	</S.TableOptionContainer>
																)}
																name='보행상태'
																control={form.control}
															/>
														</S.TableContentContainer>
													)}
												</>
											))}
											{form.watch(integratedAssessmentDesireBasicStatusQuestions[7].key) ===
												'기타' && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value}
														/>
													)}
													name='보행기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[7].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[8];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn colSpan={2}>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[8].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<CRCheckBox
														checkBoxType={item.type}
														checked={form.watch(item.key) === answer.value}
														onChange={(event: ChangeEvent<HTMLInputElement>) => {
															if (event.target.checked) {
																onChange(answer.value);
															} else {
																onChange(undefined);
															}
														}}>
														{answer.label}
													</CRCheckBox>
												))}
											</S.TableContentContainer>
											{form.watch(integratedAssessmentDesireBasicStatusQuestions[8].key) ===
												'기타' && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value}
														/>
													)}
													name='치매기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[8].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[9];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn colSpan={2}>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[9].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(item.answer ?? []).map((answer) => (
													<CRCheckBox
														checkBoxType={item.type}
														checked={form.watch(item.key)?.includes(answer.value)}
														onChange={(event: ChangeEvent<HTMLInputElement>) => {
															const targetValue = value as string[];

															if (event.target.checked) {
																onChange([...(targetValue ?? []), answer.value]);
															} else {
																onChange(
																	(targetValue ?? []).filter((item) => item !== answer.value),
																);
															}
														}}>
														{answer.label}
													</CRCheckBox>
												))}
											</S.TableContentContainer>
											{form
												.watch(integratedAssessmentDesireBasicStatusQuestions[9].key)
												?.includes('기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value}
														/>
													)}
													name='치매증상기타'
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[9].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[10];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn colSpan={2}>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[10].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											{(item.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType={item.type}
													checked={form.watch(item.key) === answer.value}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target.checked) {
															onChange(answer.value);
														} else {
															onChange(undefined);
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[10].key}
						control={form.control}
					/>
					<Controller
						render={({ field: { ref, onChange, value }, formState: { errors } }) => {
							const item = integratedAssessmentDesireBasicStatusQuestions[11];
							return (
								<S.TableBodyRow>
									<S.TableBodyColumn colSpan={2}>
										{item.label}
										{showGenerateRequiredFields &&
											integratedAssessmentDesireBasicStatusQuestions[11].required?.includes(
												'generate',
											) && <CRRequiredMark />}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											{(item.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType={item.type}
													checked={form.watch(item.key) === answer.value}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target.checked) {
															onChange(answer.value);
														} else {
															onChange(undefined);
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							);
						}}
						name={integratedAssessmentDesireBasicStatusQuestions[11].key}
						control={form.control}
					/>
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='일반상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireBasicStatusForm;
