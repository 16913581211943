import { css, styled } from 'styled-components';

export const Container = styled.div`
	padding: 2.4rem;
`;

export const CustomFilterContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const CustomFilterChip = styled.button<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray100};
	padding: 0.2rem 1.2rem;
	cursor: pointer;
	box-sizing: border-box;

	${(props) =>
		props.$isActive &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.gray10};
			background: ${(props) => props.theme.colors.gray10};
			color: ${(props) => props.theme.colors.gray100};
		`};
`;

export const CustomFilterRight = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Divider = styled.div`
	height: 1.6rem;
	width: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin: 0 0.8rem;
`;
