import { Toast } from 'components/base/CRToast';
import * as XLSX from 'sheetjs-style';
import { EmployeeSalarysTransferData } from 'types/api/salary';

export const exportExcelSalaryTransfer = (
	fileName: string,
	data: EmployeeSalarysTransferData[],
) => {
	const excelData = data.map(
		({ salaryAccountBankCd, salaryAccountNo, employeeNm, realPayAmt }) => ({
			입금은행: salaryAccountBankCd,
			입금계좌: salaryAccountNo,
			고객관리성명: employeeNm,
			입금액: realPayAmt,
			출금통장표시내용: '',
			입금통장표시내용: '',
		}),
	);

	const workSheet = XLSX.utils.json_to_sheet(excelData);

	const headerStyle = {
		fill: {
			fgColor: { rgb: 'DDDDDD' },
		},
		alignment: {
			horizontal: 'center',
		},
	};

	workSheet.A1.s = headerStyle;
	workSheet.B1.s = headerStyle;
	workSheet.C1.s = headerStyle;
	workSheet.D1.s = headerStyle;
	workSheet.E1.s = headerStyle;
	workSheet.F1.s = headerStyle;

	const columnStyles = [
		{ width: 12 },
		{ width: 20 },
		{ width: 15 },
		{ width: 12 },
		{ width: 20 },
		{ width: 20 },
	];
	workSheet['!cols'] = columnStyles;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};
