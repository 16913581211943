import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 160rem;
	height: 88rem;
	overflow: hidden;
	border-radius: 1.6rem;
`;

export const Header = styled.div`
	padding: 2.4rem 2.4rem 1.6rem;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 7.6rem;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const Table = styled.table`
	width: 100%;
	/* border: 0.1rem solid ${(props) => props.theme.colors.gray90}; */
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	padding: 1rem 1.2rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	box-sizing: border-box;
	padding: 1rem 1.2rem;
	background: ${(props) => props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	&:last-child {
		border-right: 0;
	}
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
`;

export const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const LeftButtonContainer = styled.div``;

export const RightButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
