import React from 'react';
import { CRText } from 'components/base/CRText';
import { ServiceKindCds } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { MonitoringDetailItemDTO } from 'types/api';
import Assets from 'assets';
import * as S from './styles';

interface Props {
	monitoringDetailItem?: MonitoringDetailItemDTO | null;
}

export function TagTimeError({ monitoringDetailItem }: Props) {
	// 인지요양이면서 태그 시간분배가 오류일떄
	const isCognitiveCareError =
		monitoringDetailItem?.tagTimeDistributionErrorYn === true &&
		monitoringDetailItem?.serviceKindCd === ServiceKindCds.인지요양;

	const isGeneralNursingOrFamilyNursingError =
		monitoringDetailItem?.tagTimeDistributionErrorYn === true &&
		[ServiceKindCds.방문요양, ServiceKindCds.가족요양].includes(
			monitoringDetailItem?.serviceKindCd as ServiceKindCds,
		);

	// 시간오류-수가일치여부
	const renderAmountMatchYn = () => {
		if (monitoringDetailItem?.serviceFeeMatchYn === undefined) return '-';
		if (monitoringDetailItem?.serviceFeeMatchYn)
			return <CRText text='일치' color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				justify='space-between'
				style={{
					width: '100%',
				}}>
				<CRText text='불일치' color='primary60' typography='label' />
				<RDTooltip
					side='top'
					content={
						<CRText
							color='gray100'
							typography='label'
							text={`RFID 시간을\n수가에 맞게 수정해 주십시오.`}
						/>
					}>
					<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
				</RDTooltip>
			</FlexContainer>
		);
	};

	// 시간오류-일정계획오류
	const renderServiceSchedulePlanTimeErrorYn = () => {
		if (monitoringDetailItem?.serviceSchedulePlanTimeErrorYn === undefined) return '-';
		if (!monitoringDetailItem?.serviceSchedulePlanTimeErrorYn)
			return <CRText text='정상' color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				justify='space-between'
				style={{
					width: '100%',
				}}>
				<CRText text='오류' color='primary60' typography='label' />
				<RDTooltip
					side='top'
					content={
						<CRText
							color='gray100'
							typography='label'
							text={
								monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕
									? `일정 계획 총 시간을\n40분 또는 60분으로 수정해 주십시오.`
									: `일정 계획 시간을\n30분 이상 480분이하 범위 내\n30분 단위로 수정해 주십시오.`
							}
						/>
					}>
					<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
				</RDTooltip>
			</FlexContainer>
		);
	};

	// 시간오류-일반요양2시간간격 중복여부
	const renderCareIntervalWorkDuplYn = () => {
		if (monitoringDetailItem?.careIntervalWorkErrorYn === undefined) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.careIntervalWorkErrorYn ? '오류' : '정상'}
				color={monitoringDetailItem?.careIntervalWorkErrorYn ? 'primary60' : 'gray00'}
				typography='label'
			/>
		);
	};

	// 시간오류-태그시간분배오류여부
	const renderTagTimeDistributionYn = () => {
		if (monitoringDetailItem?.tagTimeDistributionErrorYn === undefined) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.tagTimeDistributionErrorYn ? '오류' : '정상'}
				color={monitoringDetailItem?.tagTimeDistributionErrorYn ? 'primary60' : 'gray00'}
				typography='label'
			/>
		);
	};

	// 시간오류-목욕 태그 미겹침
	const renderBathTagNonOverlapYn = () => {
		if (monitoringDetailItem?.bathTagNonOverlapYn === undefined) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.bathTagNonOverlapYn ? '오류' : '정상'}
				color={monitoringDetailItem?.bathTagNonOverlapYn ? 'primary60' : 'gray00'}
				typography='label'
			/>
		);
	};

	// 태그정보-신체활동
	const renderPhysicalActivity = () => {
		const time = monitoringDetailItem?.physicalActivitySupptOfferHourCnt;
		const error = monitoringDetailItem?.physicalActivitySupptErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-인지자극활동
	const renderRecogStimulationActivity = () => {
		const time = monitoringDetailItem?.recogStimulationActivityHourCnt;
		const error = monitoringDetailItem?.recogStimulationActivityErrorYn;

		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;
		let cognitiveCareErrorMessage = '';

		const isFamilyEmployee = monitoringDetailItem?.employeeFamilyYn;
		if (isFamilyEmployee === true && time < 60) {
			cognitiveCareErrorMessage = '60분 이상을 제공해야합니다.';
		}
		if (isFamilyEmployee === false && time !== 60) {
			cognitiveCareErrorMessage = '60분을 제공해야합니다.';
		}

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && cognitiveCareErrorMessage && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text={cognitiveCareErrorMessage} />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양만 제공이 가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-일상생활 함께하기
	const renderDailyLifeTogether = () => {
		const time = monitoringDetailItem?.dailylifeTogetherHourCnt;
		const error = monitoringDetailItem?.dailylifeTogetherErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;
		const isFamilyEmployee = monitoringDetailItem?.employeeFamilyYn;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && isFamilyEmployee === false && (time < 60 || time > 120) && (
					<RDTooltip
						side='top'
						content={<CRText color='gray100' typography='label' text='60분 이상이어야 합니다.' />}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양만 제공이 가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-인지행동변화관리
	const renderRecogBehaviorChange = () => {
		const time = monitoringDetailItem?.recogBehaviorChangeHourCnt;
		const error = monitoringDetailItem?.recogBehaviorChangeErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-정서지원
	const renderCommuhelpCompanionEncourage = () => {
		const time = monitoringDetailItem?.commuhelpCompanionEncourageHourCnt;
		const error = monitoringDetailItem?.commuhelpCompanionEncourageErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && time > 60 && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='60분을 초과할 수 없습니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-가사 및 일상생활
	const renderHworkDailylifeSupptOffer = () => {
		const time = monitoringDetailItem?.hworkDailylifeSupptOfferHourCnt;
		const error = monitoringDetailItem?.hworkDailylifeSupptOfferErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && time > 90 && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='90분을 초과할 수 없습니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	return (
		<S.CheckGroupContainer>
			<S.ContentTitle>
				<CRText text='시간 오류 확인' color='gray10' typography='bodyB' />
			</S.ContentTitle>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn>수가 일치 여부</S.TableLabelColumn>
					<S.TableValueColumn>{renderAmountMatchYn()}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일정 계획 오류</S.TableLabelColumn>
					<S.TableValueColumn>{renderServiceSchedulePlanTimeErrorYn()}</S.TableValueColumn>
				</S.TableRow>
				{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문요양 && (
					<>
						<S.TableRow>
							<S.TableLabelColumn>일반요양 2시간 간격</S.TableLabelColumn>
							<S.TableValueColumn>{renderCareIntervalWorkDuplYn()}</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn>태그 시간 분배</S.TableLabelColumn>
							<S.TableValueColumn>{renderTagTimeDistributionYn()}</S.TableValueColumn>
						</S.TableRow>
					</>
				)}
				{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
					<S.TableRow>
						<S.TableLabelColumn>태그 미겹침</S.TableLabelColumn>
						<S.TableValueColumn>{renderBathTagNonOverlapYn()}</S.TableValueColumn>
					</S.TableRow>
				)}
			</S.Table>
			{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문요양 && (
				<S.TagInfoContainer>
					<S.SubTitle>태그 정보</S.SubTitle>
					<S.ScheduleDetail>
						<S.AccordionContentItem>
							<S.DetailLabel>신체활동</S.DetailLabel>
							<S.DetailValue>{renderPhysicalActivity()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>인지자극활동</S.DetailLabel>
							<S.DetailValue>{renderRecogStimulationActivity()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>일상생활 함께하기</S.DetailLabel>
							<S.DetailValue>{renderDailyLifeTogether()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>인지행동 변화관리</S.DetailLabel>
							<S.DetailValue>{renderRecogBehaviorChange()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>정서지원</S.DetailLabel>
							<S.DetailValue>{renderCommuhelpCompanionEncourage()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>가사 및 일상생활</S.DetailLabel>
							<S.DetailValue>{renderHworkDailylifeSupptOffer()}</S.DetailValue>
						</S.AccordionContentItem>
					</S.ScheduleDetail>
				</S.TagInfoContainer>
			)}
		</S.CheckGroupContainer>
	);
}
