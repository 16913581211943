import dayjs from 'dayjs';
import { displayPadTime } from 'lib';
import { MyAccountInfoDTO } from 'types/api';
import { GetWorkExecuteLogDTO } from 'types/api/workExecuteLog';

export default function createWorkExecuteLogPreviewEdocParam(
	centerInfo: MyAccountInfoDTO,
	item: GetWorkExecuteLogDTO,
	crfeNm: string,
) {
	const params = [
		{
			centerNm: centerInfo?.centerNm ?? '',
			recipientNm: item?.recipientNm ?? '',
			signRecipientNm: '',
			longTermNo: item?.longTermNo ?? '',
			facilityManagerNm: item?.facilityManagerNm ?? '',
			signFacilityManagerNm: item?.facilityManagerNm ?? '',
			longTermGradeNm: item?.longTermGradeNm ?? '',
			physicalConditionDesc: item?.physicalConditionDesc ?? '',
			diseaseDesc: item?.diseaseDesc ?? '',
			recogStateDesc: item?.recogStateDesc ?? '',
			communicationDesc: item?.communicationDesc ?? '',
			nutritionalStateDesc: item?.nutritionalStateDesc ?? '',
			familyEnvironmentDesc: item?.familyEnvironmentDesc ?? '',
			totalOpinionDesc: item?.totalOpinionDesc ?? '',
			salaryGoalDesc: item?.salaryGoalDesc ?? '',
			needSalaryDesc: item?.needSalaryDesc ?? '',
			provisionMethodDesc: item?.provisionMethodDesc ?? '',
			recogStimulationNeedDesc: item?.recogStimulationNeedDesc ?? '',
			recogStimulationProvisionMethodDesc: item?.recogStimulationProvisionMethodDesc ?? '',
			physicalAbilityNeedDesc: item?.physicalAbilityNeedDesc ?? '',
			physicalAbilityProvisionMethodDesc: item?.physicalAbilityProvisionMethodDesc ?? '',
			consultDesc: item?.consultDesc ?? '',
			salaryrecogActpgOfferConfirmDesc: item?.salaryrecogActpgOfferConfirmDesc ?? '',
			salaryrecogActpgOfferActionDesc: item?.salaryrecogActpgOfferActionDesc ?? '',
			employeeNm: item?.employeeNm1
				? `${item.employeeNm1}${item.employeeNm2 ? `, ${item.employeeNm2}` : ''}`
				: '',
			signEmployeeNm: '',
			visitDate:
				item?.visitDt && item?.startTime && item?.endTime
					? `${dayjs(item.visitDt).format('YYYY.MM.DD')}(${displayPadTime(
							item.startTime,
					  )} ~ ${displayPadTime(item.endTime)})`
					: '',
			serviceDate:
				item?.serviceStartDt && item?.serviceStartTime && item?.serviceEndTime
					? `${dayjs(item.serviceStartDt).format('YYYY.MM.DD')}(${displayPadTime(
							item.serviceStartTime,
					  )} ~ ${displayPadTime(item.serviceEndTime)})`
					: '',
			visitorNm: item?.visitorNm ?? '',
			signVisitorNm: item?.visitorNm ?? '',
			visitPlaceNm: item?.visitPlaceNm ?? '',
			salaryOfferVisitDesc: item.salaryOfferVisitYn ? '방문' : '미방문',
			visitDenyDesc: item?.visitDenyDesc ?? '',
		},
	];
	return {
		crfeNm,
		params,
	};
}
