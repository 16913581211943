import styled from 'styled-components';

export const ListContainer = styled.ul`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const StyledListItem = styled.li`
	display: flex;
	> span {
		word-break: keep-all;
		text-align: left;
	}
`;

export const BulletContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.4rem;
	height: 2.4rem;
`;
