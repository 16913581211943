import { styled } from 'styled-components';

export const Container = styled.div`
	height: 100%;
	padding: 2.4rem;
	display: flex;
	box-sizing: border-box;
`;

export const CenterSelectorContainer = styled.div`
	width: 300px;
`;

export const ExcelUploaderContainer = styled.div`
	padding: 2.4rem;
	background: ${(props) => props.theme.colors.gray100};
	border-radius: 1.6rem;
	flex: 1;
`;
