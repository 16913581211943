import { CheckOption } from 'components/base/Selections/type';
import { FileDetailDTO } from 'types/dto';

export type IPPermitItem = {
	id?: any;
	address: string;
	// visible: boolean;
};

export type CenterInfoFormType = {
	centerNm: string;
	centerAliasNm: string;
	centerOfferServices: CheckOption[];
	centerBasAddr: string;
	centerDetailAddr: string;
	centerZipCode: string;
	centerAddrLatitude: number;
	centerAddrLongitude: number;
	centerPhoneNo: string;
	centerChief: CheckOption;
	bizNo: string;
	centerMark: string;
	stampFile: FileDetailDTO[];
	w4cCodeValue: string;
	centerApiNm: string;
	workLeaveLimitCd?: CheckOption[];
	workLeavePermitIps: IPPermitItem[];
	pcorpCertiFile?: FileDetailDTO[];
	pcorpLoginPassword: string;
};

export enum WorkLeaveLimitCd {
	ALL = 'CMN185.10',
	PARTIALLY_ALLOWED = 'CMN185.20',
}
