import { keyframes, styled } from 'styled-components';
import * as HoverCard from '@radix-ui/react-hover-card';

export const HoverCardContent = styled(HoverCard.Content)`
	border-radius: 0.6rem;
	padding: 1rem 1.6rem;
	width: fit-content;
	max-width: 30rem;
	background: ${(props) => props.theme.colors.gray10};
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	will-change: transform, opacity;
	pointer-events: auto;

	&[data-side='top'] {
		animation-name: slideDownAndFade;
	}
	&[data-side='right'] {
		animation-name: slideLeftAndFade;
	}
	&[data-side='bottom'] {
		animation-name: slideUpAndFade;
	}
	&[data-side='left'] {
		animation-name: slideRightAndFade;
	}
	${(props) => props.theme.typography.label};
	color: #fffbff;
`;

export const HoverCardArrow = styled(HoverCard.Arrow)`
	fill: ${(props) => props.theme.colors.gray10};
`;

const slideUpAndFade = keyframes`
	0% {
	  opacity: 0;
	  transform: translateY(2px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
`;

export const slideRightAndFade = keyframes`
	0% {
	  opacity: 0;
	  transform: translateX(-2px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
`;

export const slideDownAndFade = keyframes`
	0% {
	  opacity: 0;
	  transform: translateY(-2px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
`;

export const slideLeftAndFade = keyframes`
	0% {
	  opacity: 0;
	  transform: translateX(2px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
`;
