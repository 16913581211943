import React from 'react';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import { Controller, useForm } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { InsuranceExcelDownload } from 'lib';
import { InsuranceExcelDownloadForm } from 'types/view/insurance';
import {
	useInsuranceAcqExcelDownload,
	useInsuranceEtcExcelDownload,
	useInsuranceLossExcelDownload,
} from 'lib/hook/react-query/mutation/insurance';
import { ResponseCode } from 'types/api';
import { Toast } from 'components/base/CRToast';
import {
	exportInsuranceAcqTransfer,
	exportInsuranceEtcTransfer,
	exportInsuranceLossTransfer,
} from 'lib/util/xlsx/exportInsuranceTransfer';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CommonCodeETCFlag } from 'types/api/common';
import * as S from './styles';

enum InsuranceOption {
	취득 = '취득',
	상실 = '상실',
	기타 = '기타',
}

const insuranceTypes = [
	{
		label: '취득',
		value: InsuranceOption.취득,
	},
	{
		label: '상실',
		value: InsuranceOption.상실,
	},
	{
		label: '기타 (취소, 신고금액 변경 등)',
		value: InsuranceOption.기타,
	},
];

export default function InsuranceExcelDownloadDialog() {
	const {
		control,
		getValues,
		trigger,
		handleSubmit,
		formState: { isValid },
	} = useForm<InsuranceExcelDownloadForm>({
		mode: 'onChange',
		resolver: yupResolver(InsuranceExcelDownload),
	});

	const { data: myAccountInfo } = useMyAccountInfo();

	const { mutate: getEtcInsuranceExcelInfo, isLoading: isEtcExcelLoading } =
		useInsuranceEtcExcelDownload((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				const fileName = `4대보험_${myAccountInfo?.centerNm}`.replace(/\s/g, '_');
				try {
					exportInsuranceEtcTransfer(fileName, returnData.data ?? []);
					Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
				} catch {
					Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
				}
			} else {
				Toast.error('데이터가 존재하지 않습니다. 관리자에게 문의해주세요.');
				return;
			}
		});

	const { mutate: getAcqInsuranceExcelInfo, isLoading: isAcqExcelLoading } =
		useInsuranceAcqExcelDownload((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				const fileName = `4대보험(취득)_${myAccountInfo?.centerNm}`.replace(/\s/g, '_');
				try {
					exportInsuranceAcqTransfer(fileName, returnData.data ?? []);
					Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
				} catch {
					Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
				}
			} else {
				Toast.error('데이터가 존재하지 않습니다. 관리자에게 문의해주세요.');
				return;
			}
		});

	const { mutate: getLossInsuranceExcelInfo, isLoading: isLossExcelLoading } =
		useInsuranceLossExcelDownload((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				const fileName = `4대보험(상실)_${myAccountInfo?.centerNm}`.replace(/\s/g, '_');
				try {
					exportInsuranceLossTransfer(fileName, returnData.data ?? []);
					Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
				} catch {
					Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
				}
			} else {
				Toast.error('데이터가 존재하지 않습니다. 관리자에게 문의해주세요.');
				return;
			}
		});

	const currentDate = dayjs().startOf('month').startOf('day');
	const { hideDialog } = useDialog();

	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN161'] }, commonCodeAdapter);

	const isBeforeJanuary2024 = (date: dayjs.Dayjs) =>
		date.startOf('month').isBefore('2024-01-01', 'month');

	const renderLastMonthText = () => {
		const lastMonthDate = currentDate.subtract(1, 'month');

		if (isBeforeJanuary2024(lastMonthDate)) {
			return '';
		}
		return `${lastMonthDate.format('YYYY년 M월')}`;
	};

	const onSubmit = (data: InsuranceExcelDownloadForm) => {
		if (isAcqExcelLoading) return;
		const insuranceType = data.insuranceType?.[0].value;
		if (insuranceType === InsuranceOption.기타) {
			getEtcInsuranceExcelInfo({
				centerId: myAccountInfo?.centerId,
				stateCode: getValues('state')?.value,
			});
		} else if (insuranceType === InsuranceOption.취득) {
			getAcqInsuranceExcelInfo({
				centerId: myAccountInfo?.centerId,
				stateCode: getValues('state')?.value,
			});
		} else if (insuranceType === InsuranceOption.상실) {
			getLossInsuranceExcelInfo({
				centerId: myAccountInfo?.centerId,
				stateCode: getValues('state')?.value,
			});
		}
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='4대보험 엑셀 다운로드'
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel isRequired label='상태'>
								<CRInput.Selector
									placeholder='상태 선택'
									currentValue={value}
									onChangeValue={onChange}
									items={commonCodes?.CMN161?.filter(
										(item) => item?.data?.etcDesc1 === CommonCodeETCFlag.Y,
									)}
								/>
							</CRInputLabel>
						)}
						name='state'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel isRequired label='종류'>
								<CRCheckBoxGroup
									direction='col'
									onChange={onChange}
									appearanceType='button'
									checkType='single'
									type='radio'
									gap={0.8}
									value={value}
									options={insuranceTypes}
								/>
							</CRInputLabel>
						)}
						name='insuranceType'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid || isAcqExcelLoading || isEtcExcelLoading || isLossExcelLoading}
						onClick={submitForm}>
						다운로드
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
