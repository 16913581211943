import React from 'react';

import { CRTableHeadConfig } from 'types/view/base';

import * as S from './styles';

interface Props {
	heads: CRTableHeadConfig[];
	borderBottom?: string;
	background?: string;
}

function CRInformationTableHead({ heads, borderBottom, background }: Props): React.ReactElement {
	return (
		<S.TableHeader>
			<tr>
				{heads.map(({ label, ...rest }) => (
					<S.TableHeaderColumnWithPadding key={label} style={{ borderBottom, background, ...rest }}>
						{label}
					</S.TableHeaderColumnWithPadding>
				))}
			</tr>
		</S.TableHeader>
	);
}

export default React.memo(CRInformationTableHead);
