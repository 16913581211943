import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRBanner from 'components/base/CRBanner';
import { CheckOption } from 'components/base/Selections/type';
import ConsultingTags from 'components/ui/ConsultingTags';
import { Toast } from 'components/base/CRToast';
import {
	useAdviceClientOptions,
	useAdviceMethods,
	useAdviceTags,
	useCreateAdvice,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { AddAdviceFormType } from 'types/view/advice';
import { AdviceClientOptionDTO } from 'types/dto';

import { ResponseCode } from 'types/api';
import { CRText } from 'components/base/CRText';
import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import * as S from './styles';

export const ClientDivCdObj: { [k: string]: string } = {
	'CMN110.10': '보호자',
	'CMN110.20': '수급자',
	'CMN110.30': '직원',
};

function ConsultingFormHelper(): React.ReactElement {
	const { setIsRightSideFolded } = useGlobalLayout();
	const { control, watch, getValues } = useForm<AddAdviceFormType>({
		mode: 'onChange',
		defaultValues: {
			date: { date: dayjs().format('YYYYMMDD'), start: dayjs().format('HH:mm') },
		},
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: adviceMethods } = useAdviceMethods();
	const { data: adviceTags } = useAdviceTags();
	const { data: adviceClientOptions = [] } = useAdviceClientOptions({
		centerId: myAccountInfo?.centerId,
		keyword: '',
	});

	const addAdviceMutation = useCreateAdvice((client) => {
		client.invalidateQueries([endpoint.getRecipientAdvices.key]);
		client.invalidateQueries([endpoint.getEmployeeAdvices.key]);
	});

	const consultingMethodOptions = useMemo(
		() =>
			(adviceMethods ?? []).map(
				(method) =>
					({
						label: method.text,
						value: method.id,
					} as CheckOption<any>),
			),
		[adviceMethods],
	);

	const clientOptions = useMemo(
		() =>
			(adviceClientOptions ?? []).map(
				(client) =>
					({
						label: client.clientNm,
						value: {
							id: client.clientId,
							name: client.clientNm,
							duty: ClientDivCdObj[client.clientDivCd],
							birth: client.clientBirth,
						},
						data: client,
						children: client?.guardians
							?.filter((item) => !item.selfGuardianYn)
							?.map((guardian) => ({
								label: guardian.clientNm,
								value: {
									name: guardian.clientNm,
									duty: guardian.mainGuardianYn ? '주보호자' : '보호자',
									id: guardian.telNum,
								},
								data: { ...guardian, recipientId: client.clientId },
							})),
					} as CheckOption<AdviceClientOptionDTO>),
			),
		[adviceClientOptions],
	);

	const sections = useMemo(
		() =>
			(adviceTags ?? []).map((adviceTag) => ({
				label: adviceTag.text,
				value: '',
				tags: adviceTag.dtos?.map((dto) => ({
					label: dto.text,
					value: dto.id,
				})),
			})),
		[adviceTags],
	);

	const disabled = useMemo(
		() =>
			!(
				getValues('date.date') &&
				getValues('date.start') &&
				getValues('client') &&
				getValues('method') &&
				getValues('tag') &&
				getValues('advisorOpinion') &&
				getValues('managerOpinion')
			),
		[watch()],
	);

	const handleSubmitAdvice = async () => {
		if (!myAccountInfo) return;

		const form = getValues();
		const date = form.date?.date ?? '00000000';
		const start = form.date?.start ?? '00:00:00';

		const res = await addAdviceMutation.mutateAsync({
			consultDate: dayjs(
				`${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)} ${start}`,
			).format('YYYY-MM-DDTHH:mm:ss'),
			writerId: Number(myAccountInfo?.memberAccountId),
			consultMethodCd: form.method?.value,
			attachFile: {
				fileDetails: form.files ?? [],
			},
			clientDivCd: form.client?.data?.clientDivCd ?? '',
			clientId: form.client?.data?.clientId ?? 0,
			recipientId: form?.client?.data?.recipientId,
			centerId: myAccountInfo.centerId,
			consultDesc: form.advisorOpinion ?? '',
			consultActionDesc: form.managerOpinion ?? '',
			consultTagId: Number(form.tag?.value) ?? 0,
		});
		if (res.code === ResponseCode.SUCCESS) {
			Toast.success('정상적으로 상담이 등록 되었습니다.');
			setIsRightSideFolded(true);
		} else {
			Toast.error('상담 등록을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};

	const handleClickCancel = () => {
		setIsRightSideFolded(true);
	};

	return (
		<S.FormContainer>
			<S.TitleContainer>
				<CRText typography='h3' text='신규 상담' />
				<CRIcon src={Assets.icon.close} onClick={handleClickCancel} style={{ cursor: 'pointer' }} />
			</S.TitleContainer>
			<S.FormAreaContainer>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담일자' isRequired>
							<CRInput.DateTimePicker
								hide={{ end: true }}
								date={value?.date}
								startTime={value?.start}
								onChange={(date, start) => {
									onChange({ date, start });
								}}
								maxLength={4}
							/>
						</CRInputLabel>
					)}
					name='date'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담 방법' isRequired>
							<CRInput.Selector
								placeholder='상담 방법 선택'
								items={consultingMethodOptions}
								onChangeValue={onChange}
								currentValue={value}
							/>
						</CRInputLabel>
					)}
					name='method'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담자' isRequired>
							<CRInput.SearchSelector
								items={clientOptions}
								currentValue={value}
								placeholder='상담자 선택'
								onChange={onChange}
							/>
						</CRInputLabel>
					)}
					name='client'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='태그' isRequired>
							<ConsultingTags sections={sections} value={value} onClick={onChange} />
						</CRInputLabel>
					)}
					name='tag'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담자 의견' isRequired>
							<CRInput.TextArea
								value={value}
								placeholder='상담자 의견 입력 (주민등록번호, 전화번호 등 개인정보 입력 금지)'
								numberOfLines={5}
								onChange={onChange}
								fixedHeight
							/>
						</CRInputLabel>
					)}
					name='advisorOpinion'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='사회복지사 의견' isRequired>
							<CRInput.TextArea
								value={value}
								placeholder='사회복지사 의견 입력 (주민등록번호, 전화번호 등 개인정보 입력 금지)'
								numberOfLines={5}
								onChange={onChange}
								fixedHeight
							/>
						</CRInputLabel>
					)}
					name='managerOpinion'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='첨부 파일'>
							<CRInput.FileUploader
								type='multiple'
								placeholder='파일 업로드'
								files={value}
								onChange={onChange}
							/>
						</CRInputLabel>
					)}
					name='files'
					control={control}
				/>
				<CRBanner content='주민등록번호, 전화번호 등 개인정보 작성 금지' type='warning' />
			</S.FormAreaContainer>
			<S.FormButtonContainer>
				<CRButton.Default type='text' palette='gray' size='default' onClick={handleClickCancel}>
					취소
				</CRButton.Default>
				<CRButton.Default
					palette='primary'
					size='default'
					disabled={disabled}
					onClick={handleSubmitAdvice}>
					등록
				</CRButton.Default>
			</S.FormButtonContainer>
		</S.FormContainer>
	);
}

export default ConsultingFormHelper;
