import { styled } from 'styled-components';

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	width: 48rem;
	max-height: calc(100vh - 4.8rem);
	overflow: hidden;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	box-sizing: border-box;
	max-height: 7.6rem;
`;

export const BodyContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: overlay;
`;

export const BodyContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 1.6rem;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const HeaderTitle = styled.h3`
	flex: 1;
`;

export const CloseIcon = styled.img`
	cursor: pointer;
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;
