import React, { useState } from 'react';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import CRButton from 'components/base/CRButton';
import { ResponseCode, UpdateMonitoringCompleteStatusRequest } from 'types/api';
import { useUpdateMonitoringCompleteStatus } from 'lib/hook/react-query/mutation/monitoring';
import { endpoint } from 'lib/service/Api/endpoint';
import { Toast } from 'components/base/CRToast';
import * as S from './styles';

interface Props {
	title: string;
	content: string;
	param: UpdateMonitoringCompleteStatusRequest;
}

export function MonitoringCompleteStatusDialog({ title, content, param }: Props) {
	const { hideDialog } = useDialog();
	const [isLoading, setIsLoading] = useState(false);
	const { mutate: updateCompleteStatus } = useUpdateMonitoringCompleteStatus(
		async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				await client.invalidateQueries([endpoint.getMonitoringSchedules.key]);
				Toast.success(
					param.completeYn ? '일정을 모두 확정하였습니다.' : '모든 일정 확정을 취소했습니다.',
				);
			} else {
				Toast.error(
					`${
						param.completeYn ? '일정 확정' : '확정 취소'
					}에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.`,
				);
			}
			setTimeout(() => setIsLoading(false), 0.2);
			hideDialog();
		},
	);

	const handleClickComplete = async () => {
		if (isLoading) return;
		setIsLoading(true);
		updateCompleteStatus(param);
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title={title}
			body={<S.Container>{content}</S.Container>}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.IconButton
						isLoading={isLoading}
						palette='primary'
						size='default'
						onClick={handleClickComplete}>
						{param.completeYn ? '확정' : '확정 취소'}
					</CRButton.IconButton>
				</S.ButtonContainer>
			}
		/>
	);
}
