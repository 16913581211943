export const centerMock = [
	{
		id: 2,
		signInCode: 'E0372',
		signInId: 'admin',
		signInPassword: '6808501345',
		w4cCode: '',
		centerNumber: '21165000178',
		name: '케어링 서초방문요양센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '김은정',
		phone: '010-9698-6585',
		address: {
			zipCode: '06619',
			address: '서울 서초구 서초대로 396',
			addressDetail: '402-3호',
			fullAddress: '(06619) 서울 서초구 서초대로 396 402-3호',
		},
		account: '신한은행 140-013-141585 케어링 서초 방문요양',
		apiName: 'SEOCHO',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013141585',
				pw: '1638',
				idnumber: '680-85-01345',
				signInId: 'caring1',
				signInPw: 'zpdjfld139~!',
				name: '서초입출금통장',
			},
		],
		disabled: false,
		modusignFolderId: '1ea38c50-f388-11ed-8655-e396b9063402',
		shortName: ' 서초방문요양',
	},
	{
		id: 3,
		signInCode: 'E0320',
		signInId: 'admin',
		signInPassword: '8538501185',
		centerNumber: '31168000280',
		name: '케어링 강남센터',
		serviceType: '방문요양, 방문목욕, 방문간호',
		chiefName: '이수영',
		phone: '010-4321-1523',
		address: {
			zipCode: '06114',
			address: '서울 강남구 강남대로 514',
			addressDetail: '5층 501호',
			fullAddress: '(06114) 서울 강남구 강남대로 514 5층 501호',
		},
		account: '신한  140-012-894128  케어링강남센터',
		apiName: 'GANGNAM',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140012894128',
				pw: '1638',
				idnumber: '853-85-01185',
				signInId: 'caring133',
				signInPw: 'zpdjfld139~!',
				name: '강남입출금통장',
			},
		],
		disabled: false,
		modusignFolderId: '25364990-f388-11ed-a228-557b5d6c6548',
		shortName: ' 강남',
	},
	{
		id: 4,
		signInCode: 'E0311',
		signInId: 'admin',
		signInPassword: '2788501588',
		w4cCode: '',
		centerNumber: '31168000276',
		name: '케어링 논현센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '조유경',
		phone: '02-554-3488',
		address: {
			zipCode: '06114',
			address: '서울 강남구 강남대로 514',
			addressDetail: '5층 502호',
			fullAddress: '(06114) 서울 강남구 강남대로 514 5층 502호',
		},
		account: '140-013-769832 / 신한은행 / 케어링(주)',
		apiName: 'NONHYEON',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140-013-769857',
				pw: '1638',
				idnumber: '2788501588',
				signInId: 'caring278',
				signInPw: 'zpdjfld139~!',
				name: '논현센터 센터장& 사복 급여 통장',
			},
			{
				bank: '신한은행',
				number: '140012880032',
				pw: '1638',
				idnumber: '507-81-15401',
				signInId: 'caring132',
				signInPw: 'zpdjfld139~!',
				name: '논현입출금통장',
			},
			{
				bank: '신한은행',
				number: '140-013-769832',
				pw: '1638',
				idnumber: '2788501588',
				signInId: 'caring278',
				signInPw: 'zpdjfld139~!',
				name: '논현센터운영통',
			},
		],
		disabled: false,
		modusignFolderId: '280aed60-f388-11ed-8aaf-dbaa2f5f6882',
		shortName: ' 논현',
	},
	{
		id: 5,
		signInCode: 'E0379',
		signInId: 'admin',
		signInPassword: '6668501785',
		centerNumber: '21165000187',
		name: '케어링 고은센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '선유정',
		phone: '02-541-3506',
		address: {
			zipCode: '06619',
			address: '서울 서초구 서초대로 396',
			addressDetail: '402-2호',
			fullAddress: '(06619) 서울 서초구 서초대로 396 402-2호',
		},
		account: '140-013-287145 신한 케어링 고은센터',
		apiName: 'GOEUN',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013287145',
				pw: '1638',
				idnumber: '666-85-01785',
				signInId: 'C1946394',
				signInPw: 'zpdjfld139~!',
				name: '고은입출금통장',
			},
		],
		disabled: false,
		modusignFolderId: '1bf694c0-f388-11ed-b09b-31bd4cfdfc1a',
		shortName: ' 고은',
	},
	{
		id: 6,
		signInCode: 'E0378',
		signInId: 'admin',
		signInPassword: '1318557084',
		centerNumber: '21165000186',
		name: '케어링 가온센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '김다솔',
		phone: '02-541-3503',
		address: {
			zipCode: '06619',
			address: '서울 서초구 서초대로 396',
			addressDetail: '402-1호',
			fullAddress: '(06619) 서울 서초구 서초대로 396 402-1호',
		},
		account: '140-013-285501 신한 케어링 가온센터',
		apiName: 'GAON',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013285501',
				pw: '1638',
				idnumber: '131-85-57084',
				signInId: 'C1945975',
				signInPw: 'zpdjfld139~!',
				name: '가온입출금통장',
			},
		],
		disabled: false,
		modusignFolderId: '1984d170-f388-11ed-a228-557b5d6c6548',
		shortName: ' 가온',
	},
	{
		id: 10,
		signInCode: 'E0394',
		signInId: 'admin',
		signInPassword: '2788501628',
		w4cCode: '',
		centerNumber: '21168000306',
		name: '케어링 나래센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '박광준',
		phone: '070-4299-4405',
		address: {
			zipCode: '06114',
			address: '서울 강남구 강남대로 526',
			addressDetail: '901호',
			fullAddress: '(06114) 서울 강남구 강남대로 526 901호',
		},
		account: '신한 140-013-389972',
		apiName: 'NARAE',
		main: false,
		accounts: [],
		disabled: true,
		shortName: ' 나래',
	},
	{
		id: 12,
		signInCode: 'F0226',
		signInId: 'admin',
		signInPassword: '1048532683',
		centerNumber: '21165000190',
		name: '케어링 다솜센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '김무량',
		phone: '070-4337-4029',
		address: {
			zipCode: '06619',
			address: '서울 서초구 서초대로 396',
			addressDetail: '402-4호',
			fullAddress: '(06619) 서울 서초구 서초대로 396 402-4호',
		},
		account: '신한은행 140-013-501275',
		apiName: 'DASOM',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013501275',
				pw: '1638',
				idnumber: '1048532683',
				signInId: 'caring3',
				signInPw: 'zpdjfld139~!',
				name: '다솜입출금통장',
			},
		],
		disabled: false,
		modusignFolderId: '22724790-f388-11ed-9ec9-55b8d0fe77ae',
		shortName: ' 다솜',
	},
	{
		id: 13,
		signInCode: 'F0227',
		signInId: 'admin',
		signInPassword: '7438501627',
		w4cCode: '',
		centerNumber: '21165000191',
		name: '케어링 라온센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '박세영',
		phone: '070-4337-4226',
		address: {
			zipCode: '06619',
			address: '서울 서초구 서초대로 396',
			addressDetail: '402-5호',
			fullAddress: '(06619) 서울 서초구 서초대로 396 402-5호',
		},
		account: '140-013-501080 신한',
		apiName: 'LAON',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013501080',
				pw: '1638',
				idnumber: '743-85-01627',
				signInId: 'caring4',
				signInPw: 'zpdjfld139~!',
				name: '라온입출금통장',
			},
		],
		disabled: false,
		modusignFolderId: 'd0946670-efbc-11ed-beb8-e7027a436f9b',
		shortName: ' 라온',
	},
	{
		id: 14,
		signInCode: 'E0386',
		signInId: 'admin',
		signInPassword: '4878500882',
		w4cCode: '',
		centerNumber: '21168000295',
		name: '케어링 복지용구센터',
		serviceType: '복지용구',
		chiefName: '김영욱',
		phone: '',
		account: '',
		apiName: '',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013329167',
				pw: '1638',
				idnumber: '4878500882',
				signInId: 'caring12',
				signInPw: 'zpdjfld139~!',
				name: '',
			},
			{
				bank: '신한은행',
				number: '140013015177',
				pw: '1638',
				idnumber: '4878500882',
				signInId: 'caring12',
				signInPw: 'zpdjfld139~!',
				name: '주거래계좌',
			},
		],
		disabled: false,
		shortName: ' 복지용구',
	},
	{
		id: 15,
		signInCode: '',
		signInId: '',
		signInPassword: '',
		w4cCode: '',
		centerNumber: '22726000436',
		name: '케어링 대구센터',
		serviceType: '방문요양, 방문목욕',
		chiefName: '박은우',
		phone: '010-4141-2326',
		address: {
			zipCode: '42123',
			address: '대구 수성구 달구벌대로 2330',
			addressDetail: '교보빌딩 6층 601호',
			fullAddress: '(42123) 대구 수성구 달구벌대로 2330 교보빌딩 6층 601호',
		},
		account: '140-013-824809 신한',
		apiName: 'DAEGU',
		main: false,
		accounts: [
			{
				bank: '신한은행',
				number: '140013824734',
				pw: '1638',
				idnumber: '4418501930',
				signInId: 'caring6',
				signInPw: 'zpdjfld139~!',
				name: '대구센터 입출금',
			},
		],
		stamp: 'fc69640d-774e-4460-a181-15f19c57ac11.png',
		disabled: false,
		shortName: ' 대구',
	},
	{
		id: 16,
		signInCode: '',
		signInId: '',
		signInPassword: '',
		w4cCode: '',
		centerNumber: '',
		name: '케어링 합류예정_대전센터',
		serviceType: '',
		chiefName: '',
		phone: '',
		account: '',
		apiName: '',
		main: false,
		accounts: [],
		disabled: false,
		shortName: ' 합류예정_대전',
	},
];
