import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background: ${(props) => props.theme.colors.gray100};
	box-sizing: border-box;
`;

export const Header = styled.div`
	display: flex;
	height: 6.4rem;
	padding: 1.6rem 2.4rem;
	justify-content: space-between;
	box-sizing: border-box;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	height: calc(100vh - 19.3rem);
	padding: 2.4rem;
	box-sizing: border-box;
	overflow-y: overlay;
	overflow-x: hidden;
`;

export const CloseIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	align-items: flex-start;
`;

export const Title = styled.h4``;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const DescriptionItem = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.gray50};
	gap: 0.8rem;
`;

export const SummaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const SummaryBar = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	align-items: center;
	padding: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const SummaryBarLabel = styled.span`
	${(props) => props.theme.typography.bodyB}
	min-width: 8rem;
`;

export const SummaryBarValue = styled.span`
	${(props) => props.theme.typography.body}
`;

export const SummaryBarSuffix = styled.span`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray60};
`;

export const SalaryListContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SectionTitle = styled.h4`
	margin-bottom: 0.8rem;
`;

export const SectionContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const SectionRowContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
`;

export const SectionColumnContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	min-height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const NoteContainer = styled.div``;

export const RightAlignment = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const RightTextAlignment = styled.div`
	${(props) => props.theme.typography.label}
	width: 100%;
	text-align: right;
`;

export const InputContainer = styled.div`
	padding: 0rem 1.2rem;
	width: fit-content;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
`;

export const KeyInput = styled.input`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	border: none;
	outline: none;
	padding: 0;
	margin: 0;

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
		border: none;
		outline: none;
		padding: 0;
		margin: 0;
	}
`;

export const Input = styled.input`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	text-align: right;

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
		border: none;
		outline: none;
		padding: 0;
		margin: 0;
	}
`;

export const Suffix = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	padding: 1.6rem 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
`;
