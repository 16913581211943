import React, { useEffect, useRef } from 'react';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';

import CRTable from 'components/base/CRTable';
import { Recipient } from 'types/view/recipient';

import { useLocation, useParams } from 'react-router-dom';
import {
	calculateLongTermGradeExpireLeftDate,
	displayBirthDay,
	isLongTermGradeExpireSoon,
	queryStringToObject,
} from 'lib';
import { CRText } from 'components/base/CRText';
import * as S from './styles';
import { RECIPIENT_LIST_TABLE_HEADER_CONFIG } from './constant';

interface Props {
	items: Recipient[];
	searchValue?: string;
	currentItem?: Recipient;
	onClickRecipient?: (recipient: Recipient) => void;
}

function RecipientListTable({
	items = [],
	currentItem,
	searchValue,
	onClickRecipient,
}: Props): React.ReactElement {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const params = useParams<{ id: string }>();
	const { search } = useLocation();

	// 새로 등록된 수급자 위치로 스크롤
	useEffect(() => {
		const queryObj = queryStringToObject(search);
		if (queryObj.isNew === 'true') {
			const targetScrollIndex = items.findIndex((item) => String(item.recipientId) === params.id);
			if (targetScrollIndex !== -1) {
				scrollContainerRef.current?.scroll(0, (targetScrollIndex - 9) * 33);
			}
		}
	}, [items, scrollContainerRef, params, search]);

	const rowRenderer = (virtualRow: VirtualItem, index: number) => {
		const item = items?.[virtualRow.index];
		if (!item) return null;

		const indexRenderer = (val: number) => {
			if (isLongTermGradeExpireSoon(item?.longTermEndDate)) {
				return <CRText typography='label' color='primary60' text={val} />;
			}
			return val;
		};

		const birthdayRenderer = (val: string) => {
			if (isLongTermGradeExpireSoon(item?.longTermEndDate)) {
				return <CRText typography='label' color='primary60' text={displayBirthDay(val)} />;
			}
			return displayBirthDay(val);
		};

		const nameRenderer = (val: string, item?: { longTermEndDate?: string }) => {
			if (isLongTermGradeExpireSoon(item?.longTermEndDate)) {
				return <CRText typography='label' color='primary60' text={val} />;
			}
			return val;
		};

		const serviceRenderer = (val: string, item?: { longTermEndDate?: string }) => {
			if (isLongTermGradeExpireSoon(item?.longTermEndDate)) {
				return (
					<S.EllipsisText>
						{`(${calculateLongTermGradeExpireLeftDate(item?.longTermEndDate)}일) ${val}`}
					</S.EllipsisText>
				);
			}
			return val;
		};

		const stateRenderer = (val: string) => {
			if (isLongTermGradeExpireSoon(item?.longTermEndDate)) {
				return <CRText typography='label' color='primary60' text={val} />;
			}
			return val;
		};

		const managerNameRenderer = (val: string) => {
			if (isLongTermGradeExpireSoon(item?.longTermEndDate)) {
				return <CRText typography='label' color='primary60' text={val} />;
			}
			return val;
		};

		const isFocused = item.recipientId === currentItem?.recipientId;

		return (
			<CRTable.Row
				isFocused={isFocused}
				key={item.recipientId}
				item={{ index: virtualRow.index + 1, ...item }}
				searchValue={searchValue}
				searchKey={['name', 'birthday']}
				toolTipKey={['service']}
				customRender={{
					index: indexRenderer,
					name: nameRenderer,
					birthday: birthdayRenderer,
					state: stateRenderer,
					service: serviceRenderer,
					managerName: managerNameRenderer,
				}}
				style={{
					width: '100%',
					display: 'flex',
					position: 'absolute',
					transform: `translateY(${virtualRow.start}px)`,
				}}
				customStyle={{
					index: {
						width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[0].width,
						flexShrink: 0,
						textAlign: 'right',
						lineHeight: '3.3rem',
					},
					name: {
						width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[1].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					birthday: {
						width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[2].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					state: {
						width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[3].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					service: {
						width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[4].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					managerName: {
						width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[5].width,
						lineHeight: '3.3rem',
					},
				}}
				renderKeys={['index', 'name', 'birthday', 'state', 'service', 'managerName']}
				onClick={onClickRecipient}
			/>
		);
	};

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => 33,
		overscan: 30,
	});

	return (
		<S.Container ref={scrollContainerRef}>
			<CRTable.Root>
				<CRTable.Head offset={1} heads={RECIPIENT_LIST_TABLE_HEADER_CONFIG} />
				<CRTable.Body
					style={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						position: 'relative',
					}}>
					{rowVirtualizer?.getVirtualItems()?.map(rowRenderer)}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default React.memo(RecipientListTable);
