import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_CONSULTING_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '16rem',
		label: '상담일자',
	},
	{
		width: '12rem',
		label: '상담대상',
		sortKey: '상담대상',
	},
	{
		width: '6.4rem',
		label: '관계',
		sortKey: '관계',
	},
	{
		width: '12rem',
		label: '태그유형',
		sortKey: '태그유형',
	},
	{
		width: '14rem',
		label: '태그',
		sortKey: '태그',
	},
	{
		width: '12rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];

export const RECIPIENT_DOCUMENT_STATUS: CRStatusConfig[] = [
	{
		key: '서명전',
		color: 'yellow',
	},
	{
		key: '적용중',
		color: 'green',
	},
	{
		key: '누락확정',
		color: 'red',
	},
];
