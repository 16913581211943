import { IntegratedAssessmentFallQuestion } from 'types/view/recipient';

export const integratedAssessmentFallQuestions: IntegratedAssessmentFallQuestion[] = [
	{
		key: '연령',
		label: '연령',
		answer: [
			{
				label: '',
				description: '',
				point: 4,
			},
			{
				label: '',
				description: '80세 이상',
				point: 3,
			},
			{
				label: '',
				description: '79-70',
				point: 2,
			},
			{
				label: '',
				description: '69-60',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
	{
		key: '정신상태',
		label: '정신상태',
		answer: [
			{
				label: '',
				description: '혼란스러움\n방향감각장애',
				point: 4,
			},
			{
				label: '',
				description: '',
				point: 3,
			},
			{
				label: '',
				description: '때때로\n혼란스러움\n방향감각장애',
				point: 2,
			},
			{
				label: '',
				description: '',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
	{
		key: '배변',
		label: '배변',
		answer: [
			{
				label: '',
				description: '소변, 대변 실금',
				point: 4,
			},
			{
				label: '',
				description: '조절능력 있지만,\n도움필요',
				point: 3,
			},
			{
				label: '',
				description: '',
				point: 2,
			},
			{
				label: '',
				description: '유치도뇨관/\n인공항루',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
	{
		key: '낙상경험',
		label: '낙상경험',
		answer: [
			{
				label: '',
				description: '이미 세번 이상\n넘어짐',
				point: 4,
			},
			{
				label: '',
				description: '',
				point: 3,
			},
			{
				label: '',
				description: '이미 한번 또는 \n두번 넘어짐',
				point: 2,
			},
			{
				label: '',
				description: '',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
	{
		key: '활동상태',
		label: '활동',
		answer: [
			{
				label: '',
				description: '전적으로 도움을\n받음',
				point: 4,
			},
			{
				label: '',
				description: '자리에서 일어나\n앉기 도움',
				point: 3,
			},
			{
				label: '',
				description: '',
				point: 2,
			},
			{
				label: '',
				description: '자립/세면대,\n화장실 이용',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
	{
		key: '걸음걸이및균형',
		label: '걸음걸이 및 균형',
		answer: [
			{
				label: '',
				description: '불규칙/불안정,\n서 있을 때와 \n걸을 때 균형을\n거의 유지하지\n못함',
				point: 4,
			},
			{
				label: '',
				description: '일어서기/ \n걸을 때 기립성/\n빈혈/ 혈액 순환\n문제',
				point: 3,
			},
			{
				label: '',
				description: '보행장애 /보조\n도구나 도움으로\n걷기',
				point: 2,
			},
			{
				label: '',
				description: '',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
	{
		key: '약물',
		label: '지난 7일간 약복용\n이나 계획된 약물',
		answer: [
			{
				label: '',
				description: '3개 또는 \n그 이상의 \n약 복용',
				point: 4,
			},
			{
				label: '',
				description: '두가지 약복용',
				point: 3,
			},
			{
				label: '',
				description: '한가지 약복용',
				point: 2,
			},
			{
				label: '',
				description: '',
				point: 1,
			},
			{
				label: '',
				description: '해당 없음',
				point: 0,
			},
		],
	},
];
