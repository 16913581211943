import React from 'react';
import CRCalendarSmall from 'components/base/CRCalendarSmall';
import CRTaskBadge from 'components/base/CRTaskBadge';
import CRAdvertisingBanner, { DefaultBanners } from 'components/base/CRAdvertisingBanner';
import ScheduleClientMobileHeader from 'components/ui/Schedule/ScheduleClientMobileHeader';
import CRTaskList from 'components/base/CRTaskList';
import { Schedule } from 'types/api/schedule';
import { displayPadTime } from 'lib';
import { v4 } from 'uuid';
import { useParams } from 'react-router';
import { useClientScheduleRecipient } from 'lib/hook/react-query';
import { ClientScheduleAdapter } from 'lib/adapter/schedule';
import CRSpinner from 'components/base/CRSpinner';
import dayjs from 'dayjs';

function ScheduleClientRecipientPage() {
	const params = useParams();
	const { data: recipientScheduleGroupByDate } = useClientScheduleRecipient(
		{ sendUuid: params.id as string },
		ClientScheduleAdapter,
	);

	if (!recipientScheduleGroupByDate) {
		return <CRSpinner />;
	}

	// 가장 일정이 많은날의 일정갯수.
	// 달력 한 칸의 사이즈를 모두 동일하게 해주기 위해서 계산
	const maxScheduleCount = Math.max(
		...Object.values(recipientScheduleGroupByDate).map((schedules) => schedules.length),
	);

	// 달력에 렌더링 시킬 데이터,
	// 일자별로 그룹핑한 데이터를 사용.
	// 날짜와 렌더링할 react노드가 item으로 들어감.
	const calendarItems = Object.entries(recipientScheduleGroupByDate).map(([date, schedules]) => {
		// 위에서 구한 가장 일정이 많은 날만큼 사이즈를 고정하기위해
		// 부족한 일정의 수는 비어있는 뱃지로 렌더링
		const item = (
			<>
				{schedules.map(({ serviceKindNm }) => {
					const serviceType = serviceKindNm.substring(serviceKindNm.length - 2);
					return <CRTaskBadge key={v4()} type={serviceType} />;
				})}
				{Array.from({ length: maxScheduleCount - schedules.length })
					.fill(null)
					.map((_) => (
						<CRTaskBadge key={v4()} />
					))}
			</>
		);
		return {
			date,
			item,
		};
	});

	// 급여의 일정 목록
	// 일자별로 그룹핑한 데이터를 사용.
	const serviceTaskList = Object.entries(recipientScheduleGroupByDate).flatMap(
		([date, schedules]) => {
			// 내부적으로 동일한 일정에 두명의 요양보호사가 오는 경우를 그룹핑해줌 (ex 방문 목욕)
			const groupByDateServiceStartEndTime = schedules.reduce((acc, cur) => {
				const targetKey = `${cur.serviceStartDt}-${cur.serviceKindNm}-${cur.serviceStartTime}-${cur.serviceEndTime}`;
				acc[targetKey] = acc[targetKey] ? [...acc[targetKey], cur] : [cur];
				return acc;
			}, {} as Record<string, Schedule[]>);

			return Object.entries(groupByDateServiceStartEndTime).map(([key, schedules]) => {
				// 그룹핑할때 쓴 키를 해체
				const [date, type, start, end] = key.split('-');
				const employNames = schedules.map(
					(schedule) => `${schedule.employeeNm} ${schedule.dutyNm}`,
				);
				return {
					date,
					item: {
						startTime: displayPadTime(start?.substring(0, 4)),
						endTime: displayPadTime(end?.substring(0, 4)),
						type: type.substring(type.length - 2),
						description: employNames.join(', '),
					},
				};
			});
		},
	);

	const oneScheduleData = Object.values(recipientScheduleGroupByDate)?.[0]?.[0] || {};
	const { serviceStartDt, recipientNm } = oneScheduleData;

	const month = dayjs(calendarItems[0]?.date).toDate();

	// 10월 일정표 발송 전 까지 극동대 배너 임시 노출(9.24~ 10.25)
	const banners =
		dayjs().isAfter('2024-09-23', 'day') && dayjs().isBefore('2024-10-26', 'day')
			? [
					{
						id: 'open_kdu',
						imageUrl:
							'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_kdu.png',
						link: 'https://gdun.modoo.at/?link=9wp2iiwo',
						alt: '극동 대학교 입학안내 이미지',
					},
					...DefaultBanners,
			  ]
			: DefaultBanners;
	return (
		<>
			{recipientNm && serviceStartDt && (
				<ScheduleClientMobileHeader
					name={recipientNm}
					yearMonth={dayjs(serviceStartDt).format('YY년 MM월')}
				/>
			)}
			<CRCalendarSmall onChange={(e) => {}} date={month} items={calendarItems} />
			<CRAdvertisingBanner banners={banners} />
			<CRTaskList tasks={serviceTaskList} />
		</>
	);
}

export default ScheduleClientRecipientPage;
