import { useMyAccountInfo } from '../react-query';

enum PositionEnum {
	사회복지사 = 'CMN103.10',
	부센터장 = 'CMN103.20',
	센터장 = 'CMN103.30',
	케어링플러스본부장 = 'CMN103.40',
	케어본부장 = 'CMN103.50',
	컨택매니저 = 'CMN103.60',
	관리자 = 'CMN103.70',
	대표이사 = 'CMN103.80',
	외부인 = 'CMN103.99',
}

type PositionKor = keyof typeof PositionEnum;

export const useHasPermission = (positionsOrPosition: PositionKor[] | PositionKor): boolean => {
	const { data } = useMyAccountInfo();

	if (!data) return false;

	const positions = Array.isArray(positionsOrPosition)
		? positionsOrPosition
		: [positionsOrPosition];

	return positions.some((position) => PositionEnum[position]?.includes(data.positionCd));
};
