import React, { useCallback } from 'react';
import Assets from 'assets';
// import EFormViewer from 'components/base/CRFileViewer/viewer/EformViewer';
// import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import { useEmployeeInsurance } from 'lib/hook/react-query/query/insurance';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { EmployeeDTO } from 'types/api';
import CRDialog from 'components/base/CRDialog';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import { EmployeeInsuranceDTO } from 'types/api/insurance';
import dayjs from 'dayjs';
import Colors from 'common/colors';

import { StatusColor } from 'types/view/base';
import { InsuranceState } from 'pages/dashboard/InsuranceDetailPage';
import { PROTECTION_HISTORY_LIST_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	hideDialog: () => void;
}

interface Props {
	currentEmployee?: EmployeeDTO;
	onClickAddInsurance: () => void;
	onClickRow: (item: EmployeeInsuranceDTO) => () => void;
}

export default function InsuranceHistoryDialog({
	currentEmployee,
	onClickAddInsurance,
	hideDialog,
	onClickRow,
}: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: employeeInsuranceData } = useEmployeeInsurance({
		centerId: myAccountInfo?.centerId,
		employeeId: currentEmployee?.employeeId,
	});

	const renderInsuranceRequestState = useCallback(
		(value: { id: string; text: string; color: StatusColor }) => {
			const options = [{ key: value?.id, label: value?.text, color: value?.color }];
			return <CRStatus options={options}>{value?.id}</CRStatus>;
		},
		[],
	);

	const renderInsuranceRequestType = useCallback(
		(insuranceRequestType?: string) => (
			<S.ServiceTypeBadgeContainer>
				<S.TextBox>{insuranceRequestType || '-'}</S.TextBox>
			</S.ServiceTypeBadgeContainer>
		),
		[],
	);

	const renderPriceNumber = useCallback(
		(
			approvedKey?: keyof Pick<
				EmployeeInsuranceDTO,
				| 'accidentInsuranceApproveYn'
				| 'employInsuranceApproveYn'
				| 'healthInsuranceApproveYn'
				| 'pensionInsuranceApproveYn'
			>,
		) =>
			function renderPrice(value: number, item?: EmployeeInsuranceDTO) {
				let isDenied = false;
				if (
					approvedKey &&
					item?.insuranceRequestState?.id === InsuranceState.부분완료 &&
					item?.[approvedKey] === false
				) {
					isDenied = true;
				}
				return (
					<S.TextBox
						style={{
							textAlign: 'right',
							color: isDenied ? Colors.gray60 : Colors.gray10,
							textDecoration: isDenied ? 'line-through' : 'auto',
						}}>
						{value && typeof value === 'number' ? value?.toLocaleString() : '-'}
					</S.TextBox>
				);
			},
		[],
	);

	const renderDate = useCallback(
		(
			approvedKey?: keyof Pick<
				EmployeeInsuranceDTO,
				| 'accidentInsuranceApproveYn'
				| 'employInsuranceApproveYn'
				| 'healthInsuranceApproveYn'
				| 'pensionInsuranceApproveYn'
			>,
		) =>
			function renderPrice(date: string, item?: EmployeeInsuranceDTO) {
				let isDenied = false;
				if (
					approvedKey &&
					item?.insuranceRequestState?.id === InsuranceState.부분완료 &&
					item?.[approvedKey] === false
				) {
					isDenied = true;
				}
				return (
					<S.TextBox
						style={{
							textAlign: 'right',
							color: isDenied ? Colors.gray60 : Colors.gray10,
							textDecoration: isDenied ? 'line-through' : 'auto',
						}}>
						{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD') : '-'}
					</S.TextBox>
				);
			},
		[],
	);

	const renderFullDate = useCallback(
		(date: string) => (
			<S.TextBox>
				{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD HH:mm') : '-'}
			</S.TextBox>
		),
		[],
	);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='L'
			title='4대보험 이력'
			bottomContainerStyle={{ borderTop: `0.1rem solid ${Colors.gray90}` }}
			body={
				<CRTable.Root>
					<CRTable.Head offset={-77} heads={PROTECTION_HISTORY_LIST_TABLE_HEADER_CONFIG} />
					<CRTable.Body>
						{employeeInsuranceData?.map((item) => (
							<CRTable.Row
								key={item.insuranceRequestId}
								item={item}
								onClick={onClickRow(item)}
								customRender={{
									insuranceRequestState: renderInsuranceRequestState,
									insuranceRequestType: renderInsuranceRequestType,
									pensionInsuranceRequestAmt: renderPriceNumber('pensionInsuranceApproveYn'),
									pensionInsuranceRequestDate: renderDate('pensionInsuranceApproveYn'),
									healthInsuranceRequestAmt: renderPriceNumber('healthInsuranceApproveYn'),
									healthInsuranceRequestDate: renderDate('healthInsuranceApproveYn'),
									employInsuranceRequestAmt: renderPriceNumber('employInsuranceApproveYn'),
									employInsuranceRequestDate: renderDate('employInsuranceApproveYn'),
									accidentInsuranceRequestAmt: renderPriceNumber('accidentInsuranceApproveYn'),
									accidentInsuranceRequestDate: renderDate('accidentInsuranceApproveYn'),
									requestDate: renderFullDate,
								}}
								renderKeys={[
									'insuranceRequestState',
									'insuranceRequestType',
									'pensionInsuranceRequestAmt',
									'pensionInsuranceRequestDate',
									'healthInsuranceRequestAmt',
									'healthInsuranceRequestDate',
									'employInsuranceRequestAmt',
									'employInsuranceRequestDate',
									'accidentInsuranceRequestAmt',
									'accidentInsuranceRequestDate',
									'requestDate',
									'',
								]}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			}
			footer={
				<S.ButtonContainer>
					<S.LeftButtonContainer>
						<CRButton.IconButton
							palette='gray'
							type='outlined'
							iconLeft={Assets.icon.add}
							onClick={onClickAddInsurance}>
							4대보험 신고
						</CRButton.IconButton>
					</S.LeftButtonContainer>
				</S.ButtonContainer>
			}
		/>
	);
}
