import React from 'react';

import Assets from 'assets';

import { FileDetailDTO } from 'types/dto/common';
import { useDownload } from 'lib/hook/util/useDownload';
import * as S from './styles';

interface Props {
	items?: FileDetailDTO[];
}

export default function CRFileDownloader({ items = [] }: Props): React.ReactElement {
	const { downloadFiles } = useDownload();

	const renderFileItemInfo = (item: FileDetailDTO) => (
		<S.FileListItem key={item.originFileNm}>
			<S.FileInfo onClick={() => downloadFiles([item])}>
				<S.Icon src={Assets.icon.description} />
				<S.FileName>{item.originFileNm}</S.FileName>
			</S.FileInfo>
		</S.FileListItem>
	);

	return (
		<S.Container>
			{items?.length > 0 && (
				<S.FileList>{items.map((file) => renderFileItemInfo(file))}</S.FileList>
			)}
		</S.Container>
	);
}
