import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	overflow: overlay;
`;

export const ServiceTypeBadgeContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.8rem;
	padding: 0.4rem 0;
`;

export const ServiceTypeBadge = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
	background: ${(props) => props.theme.colors.gray90};
	border-radius: 100rem;
	height: 2.4rem;
	padding: 0 0.8rem;
	box-sizing: border-box;
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const DayCount = styled(TextBox)``;
