import React, { useCallback, useEffect, useMemo } from 'react';
import 'dayjs/locale/ko';

import CRInput from 'components/base/CRInput';
import useDialog from 'lib/hook/util/useDialog';
import * as Accordion from '@radix-ui/react-accordion';

import CRButton from 'components/base/CRButton';

import { useNavigate } from 'react-router-dom';
import {
	useCommonCodes,
	useMyAccountInfo,
	useRecipientServices,
	useResignRecipient,
} from 'lib/hook/react-query';
import CRTable from 'components/base/CRTable';
import dayjs from 'dayjs';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RecipientResign } from 'lib';

import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import { Toast } from 'components/base/CRToast';
import { ResignRecipientRequest, ResponseCode } from 'types/api';
import { commonCodeAdapter } from 'lib/adapter/common';
import { endpoint } from 'lib/service/Api/endpoint';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import RecipientDetailBasicInformationTable from 'components/ui/RecipientDetailBasicInformationTable';
import { RecipientResignForm } from 'types/view/recipient';
import { RecipientDTO } from 'types/api/common';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import * as S from './styles';
import { EMPLOYEE_CONTRACT_LEAVE_SERVICE_TABLE_HEADER_CONFIG } from './constans';

function RecipientResignPage(): React.ReactElement {
	const {
		setCurrentRecipient,
		currentRecipient,
		currentRecipientBase,
		currentRecipientStatus,
		currentRecipientInfoSummary,
	} = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { showDialog, hideDialog } = useDialog();
	const {
		control,
		handleSubmit,
		watch,
		formState: { isValid },
	} = useForm<RecipientResignForm>({
		resolver: yupResolver(RecipientResign),
	});

	const { data: recipientServicesData } = useRecipientServices({
		centerId: myAccountInfo?.centerId,
		recipientId: currentRecipient?.recipientId,
	});

	const navigate = useNavigate();

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN109'],
		},
		commonCodeAdapter,
	);

	const { mutate: resignRecipient } = useResignRecipient((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			if (returnData.data && currentRecipient) {
				client.invalidateQueries([
					endpoint.getRecipientBase.key,
					{
						recipientId: returnData.data.recipientId,
					},
				]);

				client.setQueryData(
					[
						endpoint.getRecipients.key,
						{
							centerIds: myAccountInfo?.centerId,
						},
					],
					(data: any) => {
						const recipientCache = (data.recipients as unknown as RecipientDTO[]) || [];
						const newRecipients = recipientCache?.map((recipient) => {
							if (Number(recipient.recipientId) === returnData?.data?.recipientId) {
								return {
									...recipient,
									stateNm: '퇴소',
								};
							}
							return recipient;
						});

						const newCache = {
							...data,
							recipients: newRecipients,
						};

						return newCache;
					},
				);

				setCurrentRecipient({
					...currentRecipient,
					state: '퇴소',
				});
			}
			hideDialog();
			Toast.success('퇴소 처리 되었습니다.');
			navigate(-1);
		} else {
			Toast.error('실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});

	const renderServiceStartDate = useCallback(
		(serviceStartDate: string) => (
			<S.TextContainer>
				{serviceStartDate ? dayjs(serviceStartDate).format('YYYY.MM.DD') : '-'}
			</S.TextContainer>
		),
		[],
	);

	const renderManagerName = useCallback(() => {
		const managerNameArr = currentRecipient?.managerName?.split(' ');
		const managerName =
			managerNameArr && managerNameArr?.length > 0
				? managerNameArr[managerNameArr.length - 1]
				: '-';
		return <S.TextContainer>{managerName}</S.TextContainer>;
	}, [currentRecipient]);

	const totalWorkDate = useMemo(() => {
		if (!recipientServicesData?.length) return '';

		const sortedServiceData = [...recipientServicesData];
		sortedServiceData.sort(
			(a, b) => new Date(a.serviceStartDate).getTime() - new Date(b.serviceStartDate).getTime(),
		);

		// 정렬된 데이터 중 첫 번째 요소가 가장 빠른 날짜를 가진 데이터입니다.
		const earliestStartDateData = sortedServiceData[0];
		const resignDate = watch('retirementDate');
		const { serviceStartDate } = earliestStartDateData;

		if (serviceStartDate && resignDate) {
			const diffTotalMonth = dayjs(resignDate).diff(dayjs(serviceStartDate), 'month');

			let baseText = `${dayjs(serviceStartDate).format('YYYY.MM.DD')} ~ ${dayjs(resignDate).format(
				'YYYY.MM.DD',
			)}`;
			if (diffTotalMonth > 11) {
				baseText += `(총 ${Math.floor(diffTotalMonth / 12)}년 ${Math.floor(
					diffTotalMonth % 12,
				)}개월 이용)`;
			} else {
				baseText = `${dayjs(serviceStartDate).format('YYYY.MM.DD')} ~ ${dayjs(resignDate).format(
					'YYYY.MM.DD',
				)}(총 ${Math.floor(diffTotalMonth % 12)}개월 이용)`;
			}
			return baseText;
		}

		return '';
	}, [recipientServicesData, watch('retirementDate')]);

	const onSubmit = (data: RecipientResignForm) => {
		if (!currentRecipient) return;
		const params: ResignRecipientRequest & { [key: string]: string | boolean | number } = {
			centerId: currentRecipient.centerId,
			retirementDate: dayjs(data.retirementDate).format('YYYYMMDD'),
			serviceStartDate: '20231025',
			serviceChangeDescCd: data.resignType.value,
			serviceChangeDesc: data.serviceChangeDesc || '',
			pcorpSalaryContractCancelYn: data.pcorpSalaryContractCancelYn,
		};

		if (data.resignType?.value === 'CMN109.50') {
			params.serviceChangeEtcDesc = data.resignTypeEtc;
		}

		resignRecipient({
			recipientId: currentRecipient.recipientId,
			params,
		});
	};

	const handleClickResign = () => {
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='퇴소 신청'
				content={`${currentRecipient?.name}(${currentRecipient?.recipientId}) 수급자 상태를 퇴소로 변경합니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '퇴소',
					successCallback: handleSubmit(onSubmit),
				}}
			/>
		));
	};

	const resignTypes = useMemo(
		() => (commonCodes?.CMN109 || [])?.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes?.CMN109],
	);

	useEffect(() => {
		if (currentRecipientStatus?.status === 'CMN058.30') {
			navigate(-1);
		}
	}, [currentRecipientStatus]);
	return (
		<S.Container>
			<S.Title>{`${currentRecipientBase?.name}(${currentRecipient?.recipientId}) 수급자 퇴소`}</S.Title>
			<S.ContentsContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
					}}
					defaultValue={['기본 정보', '계약 종료 급여', '퇴소 신청', '점검']}>
					<Accordion.Item value='기본 정보' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>기본 정보</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<RecipientDetailBasicInformationTable item={currentRecipientInfoSummary} />
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='계약 종료 급여' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>계약 종료 급여</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<CRTable.Root
										style={{
											width: '60rem',
											borderLeft: '0.1rem solid #E1E3E3',
											borderRight: '0.1rem solid #E1E3E3',
										}}>
										<CRTable.Head heads={EMPLOYEE_CONTRACT_LEAVE_SERVICE_TABLE_HEADER_CONFIG} />
										<CRTable.Body>
											{recipientServicesData?.map((item) => (
												<CRTable.Row
													style={{
														width: '60rem',
													}}
													key={item.serviceContractDetailId}
													item={item}
													customRender={{
														serviceStartDate: renderServiceStartDate,
														managerId: renderManagerName,
													}}
													renderKeys={[
														'centerNm',
														'managerId',
														'serviceTypeCdNm',
														'employeeNm',
														'serviceStartDate',
													]}
												/>
											))}
										</CRTable.Body>
									</CRTable.Root>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='퇴소 신청' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>퇴소 신청</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<div
										style={{
											width: '57.2rem',
											paddingBottom: '5rem',
										}}>
										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='퇴소일' type='left-sub' isRequired showOverflow>
													<CRInput.DatePicker
														onChangeValue={onChange}
														value={value}
														placeholder='퇴소일'
													/>
												</CRInputLabel>
											)}
											name='retirementDate'
											control={control}
										/>
										<CRInputLabel label='이용기간' type='left-sub'>
											<CRInput.Default disabled value={totalWorkDate} placeholder='이용기간' />
										</CRInputLabel>

										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='종료 유형' type='left-sub' isRequired showOverflow>
													<CRInput.Selector
														onChangeValue={onChange}
														currentValue={value}
														items={resignTypes}
														placeholder='종료 유형'
													/>
												</CRInputLabel>
											)}
											name='resignType'
											control={control}
										/>
										{watch('resignType')?.value === 'CMN109.50' && (
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRInputLabel label='' type='left-sub'>
														<CRInput.Default
															onChange={onChange}
															value={value}
															placeholder='종료 유형 입력'
														/>
													</CRInputLabel>
												)}
												name='resignTypeEtc'
												control={control}
											/>
										)}

										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInputLabel label='종료 사유' type='left-sub' isRequired>
													<CRInput.TextArea onChange={onChange} numberOfLines={10} value={value} />
												</CRInputLabel>
											)}
											name='serviceChangeDesc'
											control={control}
										/>
									</div>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='점검' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>점검</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<S.CheckBoxContainer>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														공단(수급자 계약 해지) <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='pcorpSalaryContractCancelYn'
												control={control}
											/>
										</S.CheckBoxRow>
									</S.CheckBoxContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
				</Accordion.Root>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer>
					<S.BottomRightMenuContainer>
						<CRButton.Default type='text' palette='gray' onClick={() => navigate(-1)}>
							취소
						</CRButton.Default>
						<CRButton.Default disabled={!isValid} type='outlined' onClick={handleClickResign}>
							퇴소
						</CRButton.Default>
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}

export default RecipientResignPage;
