import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: calc(100vh - 5.8rem);
	display: flex;
	position: relative;
	box-sizing: border-box;
`;

export const LeftContainer = styled.div`
	width: 28rem;
	min-width: 28rem;
	height: calc(100vh - 5.6rem);
	box-sizing: border-box;
	overflow-y: overlay;
	transition: all 300ms ease 0s;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	white-space: nowrap;
	background: ${(props) => props.theme.colors.gray99};
`;

export const MainContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: calc(100vh - 5.8rem);
	width: calc(100vw - 28rem);
	overflow: overlay;
`;

export const TaskContainer = styled.div`
	width: calc(100vw - 28rem);
	height: calc(100vh - 5.8rem);
	box-sizing: border-box;
`;
