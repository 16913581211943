import React from 'react';

import useFunnel from 'lib/hook/util/useFunnel';
import EDocClientPageProvider from 'lib/provider/eDoc/EDocClient';
import ESignInfoAuthStep from './ESignInfoAuthStep';
import ESignInfoConfirmStep from './ESignInfoConfirmStep';
import ESignInfoResultStep from './ESignInfoResultStep';
import ESignInfoAlreadyConfirmedStep from './ESignInfoAlreadyConfirmedStep';

type Steps = ['auth', 'confirm', 'result', 'alreadyConfirmed'];

function ESignInfoPage(): React.ReactElement {
	const [Funnel, setStep] = useFunnel<Steps>('auth');

	const onAuthSuccess = () => {
		setStep('confirm');
	};

	const onConfirm = () => {
		setStep('result');
	};
	const onBackToConfirm = () => {
		setStep('confirm');
	};

	const onAlreadyConfirmed = () => {
		setStep('alreadyConfirmed');
	};

	return (
		<EDocClientPageProvider>
			<Funnel>
				<Funnel.Step name='auth'>
					<ESignInfoAuthStep onSuccess={onAuthSuccess} onAlreadyConfirmed={onAlreadyConfirmed} />
				</Funnel.Step>
				<Funnel.Step name='confirm'>
					<ESignInfoConfirmStep onConfirm={onConfirm} />
				</Funnel.Step>
				<Funnel.Step name='result'>
					<ESignInfoResultStep onClickBack={onBackToConfirm} />
				</Funnel.Step>
				<Funnel.Step name='alreadyConfirmed'>
					<ESignInfoAlreadyConfirmedStep />
				</Funnel.Step>
			</Funnel>
		</EDocClientPageProvider>
	);
}

export default ESignInfoPage;
