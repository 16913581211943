import React, { useEffect, useMemo } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CRInput from 'components/base/CRInput';
import {
	EmployeeScheduleDetailCenterItem,
	EmployeeScheduleDetailForm,
	ProcessedEmployeeScheduleDetail,
} from 'types/view/schedule';
import { CheckOption } from 'components/base/Selections/type';
import { v4 } from 'uuid';
import CRButton from 'components/base/CRButton';
import Assets from 'assets';
import CRInputMessage from 'components/base/CRInputMessage';
import * as S from './styles';

interface Props {
	item?: ProcessedEmployeeScheduleDetail | null;
	options?: {
		services: CheckOption[];
		employees: CheckOption[];
		recipients: CheckOption[];
	};
	overlapIds: (string | number)[];
}

export function EmployeeScheduleEdit({ item, options, overlapIds }: Props) {
	const { watch, control, setValue } = useFormContext<EmployeeScheduleDetailForm>();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'items',
	});

	const memoizedOptions = useMemo(() => options, [options]);
	const memoizedMyCenter = useMemo(() => item?.myCenter, [item?.myCenter]);

	const handleAddMyCenterItem = () => {
		append({
			scheduleId: v4(),
			service: undefined,
			employee: undefined,
			recipient: undefined,
			deleteYn: false,
			time: {
				startTime: '',
				endTime: '',
			},
		});
	};

	const handleDeleteMyCenterItem = (scheduleId: string | number) => {
		if (typeof scheduleId === 'number') {
			const newItems = watch('items').map((item) =>
				item.scheduleId === scheduleId
					? {
							...item,
							deleteYn: true,
					  }
					: item,
			);
			setValue('items', newItems);
		} else {
			const newItems = watch('items').filter((item) => item.scheduleId !== scheduleId);
			setValue('items', newItems);
		}
	};

	useEffect(() => {
		if (!watch('items')?.length) {
			if (
				memoizedMyCenter?.length &&
				memoizedOptions?.employees?.length &&
				memoizedOptions.services.length
			) {
				const initFormData: EmployeeScheduleDetailCenterItem[] = [];
				memoizedMyCenter?.forEach((value) => {
					const service = memoizedOptions?.services.find(
						(service) => service.value === value.serviceKindCd,
					);
					const recipient = memoizedOptions?.recipients.find(
						(recipient) => recipient?.value?.id === String(value.recipientId),
					);

					const matchedEmployeeId = value.employees.find(
						(item) => item.employeeId !== value.employeeId,
					)?.employeeId;
					const employee =
						service?.value === 'CMN083.20'
							? memoizedOptions?.employees?.find((item) => item.value.id === matchedEmployeeId)
							: undefined;
					const data = {
						scheduleId: value.serviceSchedulePlanId,
						service,
						employee,
						recipient,
						deleteYn: false,
						time: {
							startTime: `${value.serviceStartTime.substring(
								0,
								2,
							)}:${value.serviceStartTime.substring(2, 4)}`,
							endTime: `${value.serviceEndTime.substring(0, 2)}:${value.serviceEndTime.substring(
								2,
								4,
							)}`,
						},
					} as EmployeeScheduleDetailCenterItem;

					if (fields.findIndex((item) => item.scheduleId === data.scheduleId) === -1) {
						initFormData.push(data);
					}
				});
				setValue('items', initFormData);
			}
		}
	}, [memoizedMyCenter, memoizedOptions, item]);

	return (
		<div>
			{watch('items')
				?.filter((field) => !field.deleteYn)
				?.map((formItem, index) => (
					<S.Container key={formItem.scheduleId}>
						<S.Title>
							내 센터
							<CRButton.Default
								type='outlined'
								size='xSmall'
								onClick={() => handleDeleteMyCenterItem(formItem.scheduleId)}>
								삭제
							</CRButton.Default>
						</S.Title>
						<S.Content key={formItem.scheduleId}>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='급여'>
										<CRInput.Selector
											disabled={typeof formItem.scheduleId === 'number'}
											items={memoizedOptions?.services}
											ref={ref}
											currentValue={value}
											onChangeValue={(item) => onChange(item)}
											placeholder='급여 선택'
										/>
									</CRInputLabel>
								)}
								name={`items.${index}.service`}
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='수급자'>
										<CRInput.SearchSelector
											currentValue={value}
											items={memoizedOptions?.recipients}
											onChange={onChange}
										/>
									</CRInputLabel>
								)}
								name={`items.${index}.recipient`}
								control={control}
							/>
							{watch('items')[index].service?.value === 'CMN083.20' && (
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInputLabel label='직원'>
											<CRInput.SearchSelector
												currentValue={value}
												items={memoizedOptions?.employees}
												onChange={onChange}
											/>
										</CRInputLabel>
									)}
									name={`items.${index}.employee`}
									control={control}
								/>
							)}
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='일정'>
										<CRInput.DayTimePicker
											showDays={false}
											currentValue={{
												id: formItem.scheduleId,
												days: [],
												startTime: value?.startTime || '',
												endTime: value?.endTime || '',
											}}
											onChange={(e) => {
												onChange(e);
											}}
										/>
										{errors?.items?.[index]?.time && (
											<CRInputMessage type='error'>
												{errors?.items?.[index]?.time?.message || ''}
											</CRInputMessage>
										)}
										{overlapIds?.includes(watch('items')?.[index]?.scheduleId) && (
											<CRInputMessage type='error'>중복된 일정이 있습니다.</CRInputMessage>
										)}
									</CRInputLabel>
								)}
								name={`items.${index}.time`}
								control={control}
							/>
						</S.Content>
					</S.Container>
				))}
			<CRButton.IconButton
				onClick={handleAddMyCenterItem}
				style={{
					width: '100%',
					marginTop: '1.6rem',
				}}
				palette='gray'
				type='tonal'
				size='default'
				iconLeft={Assets.icon.add}>
				일정 추가
			</CRButton.IconButton>
		</div>
	);
}

export default React.memo(EmployeeScheduleEdit);
