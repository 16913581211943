import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: calc(100vh - 5.6rem);
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

export const BodyContainer = styled.div`
	flex: 1;
	width: 100%;
	height: calc(100vh - 5.6rem - 8rem);
	padding: 2.4rem;
	overflow: overlay;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
`;

export const ContentContainer = styled.div`
	width: 108rem;
`;

export const ContentHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
	margin-bottom: 1.6rem;
`;

export const ContentBodyContainer = styled.div``;

export const FooterContainer = styled.div`
	width: 100%;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background-color: ${(props) => props.theme.colors.gray99};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.6rem 0rem 2.4rem 0rem;
`;

export const ButtonContainer = styled.div`
	width: 108rem;
	display: flex;
	justify-content: flex-end;
	gap: 0.8rem;
`;

export const Title = styled.h3`
	margin-bottom: 1.6rem;
`;
