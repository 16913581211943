import React, { CSSProperties, PropsWithChildren, ReactElement } from 'react';
import * as S from './styles';

interface IProps {
	style?: CSSProperties;
}

export default function CRDropdown({
	style = {},
	children,
}: PropsWithChildren<IProps>): ReactElement {
	return <S.Container style={style}>{children}</S.Container>;
}
