import { IntegratedAssessmentDesireCognitiveStateQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireCognitiveStateQuestions: IntegratedAssessmentDesireCognitiveStateQuestion[] =
	[
		{
			key: '지능및인지기능저하',
			label: '지남력 저하',
			value: '지능및인지기능저하',
			answer: [
				{
					label: '날짜 ・ 시간',
					value: '날짜 ・ 시간',
				},
				{
					label: '장소',
					value: '장소',
				},
				{
					label: '사람',
					value: '사람',
				},
			],
		},
		{
			key: '기억력저하',
			label: '기억력 저하',
			value: '기억력저하',
			answer: [
				{
					label: '단기',
					value: '단기',
				},
				{
					label: '장기',
					value: '장기',
				},
			],
		},
		{
			key: '주의집중력저하',
			label: '주의집중력 저하',
			value: '주의집중력저하',
			answer: [],
		},
		{
			key: '계산력저하',
			label: '계산력 저하',
			value: '계산력저하',
			answer: [],
		},
		{
			key: '판단력저하',
			label: '판단력 저하',
			value: '판단력저하',
			answer: [],
		},
		{
			key: '부적절한옷입기',
			label: '부적절한 옷입기(상하의 구분 못함, 겉옷과 속옷 구분 못함 등)',
			value: '부적절한옷입기',
			answer: [],
		},
		{
			key: '망상',
			label: '망상(부적절한 믿음, 편집증 등)',
			value: '망상',
			answer: [],
		},
		{
			key: '배회',
			label: '배회',
			value: '배회',
			answer: [
				{
					label: '밖으로 나가려함',
					value: '밖으로 나가려함',
				},
				{
					label: '의미없는 서성거림',
					value: '의미없는 서성거림',
				},
				{
					label: '길 잃음',
					value: '길 잃음',
				},
			],
		},
		{
			key: '환각',
			label: '환각',
			value: '환각',
			answer: [
				{
					label: '환시',
					value: '환시',
				},
				{
					label: '환청',
					value: '환청',
				},
				{
					label: '환미',
					value: '환미',
				},
				{
					label: '환촉',
					value: '환촉',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '반복적인행동',
			label: '반복적인 행동(물건 감추기, 짐싸기 등)',
			value: '반복적인행동',
			answer: [],
		},
		{
			key: '부적절한행동',
			label: '부적절한 행동',
			value: '부적절한행동',
			answer: [
				{
					label: '부적절한 성적행동',
					value: '부적절한 성적행동',
				},
				{
					label: '부적절한 일반행동',
					value: '부적절한 일반행동',
				},
			],
		},
		{
			key: '폭력적인행동',
			label: '폭력적 행동',
			value: '폭력적인행동',
			answer: [
				{
					label: '신체적인 공격',
					value: '신체적인 공격',
				},
				{
					label: '폭언',
					value: '폭언',
				},
				{
					label: '도움에 저항',
					value: '도움에 저항',
				},
			],
		},
		{
			key: '야간수면장애',
			label: '야간수면장애',
			value: '야간수면장애',
			answer: [],
		},
		{
			key: '불결한행동',
			label: '불결행동',
			value: '불결한행동',
			answer: [],
		},
		{
			key: '식습관변화',
			label: '식습관 변화',
			value: '식습관변화',
			answer: [
				{
					label: '식욕 저하',
					value: '식욕 저하',
				},
				{
					label: '식욕 증가',
					value: '식욕 증가',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '음식물아닌물건섭취',
			label: '먹는 것이 아닌 물건을 먹음',
			value: '음식물아닌물건섭취',
			answer: [],
		},
		{
			key: '불안',
			label: '불안',
			value: '불안',
			answer: [
				{
					label: '혼자 남겨짐에 대한 공포',
					value: '혼자 남겨짐에 대한 공포',
				},
				{
					label: '초조',
					value: '초조',
				},
				{
					label: '안절부절',
					value: '안절부절',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '우울',
			label: '우울',
			value: '우울',
			answer: [
				{
					label: '두려움',
					value: '두려움',
				},
				{
					label: '무기력함',
					value: '무기력함',
				},
				{
					label: '절망',
					value: '절망',
				},
			],
		},
	];
