import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRRsdNumber from 'components/base/CRRsdNumber';
import CRInput from 'components/base/CRInput';
import CRInputMessage from 'components/base/CRInputMessage';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';
import dayjs from 'dayjs';

import * as S from './styles';
import CRRequiredMark from '../../../base/CRRequiredMark';
import { displayShortDate } from '../../../../lib';

interface Props {
	index: number;
}

function ChangeEmployeeEmployeeBasicForm({ index }: Props): React.ReactElement {
	const { form, commonCodes, disabled } = useContractChangeEmployeeTask();

	const employee = form.watch(`employees.${index}`);

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		form?.setValue(`employees.${index}.base.employeeBasAddr`, postcodeData.address);
		form?.setValue(`employees.${index}.base.employeeZipCode`, postcodeData.zonecode);
		form?.setValue(`employees.${index}.base.roadNm`, postcodeData.roadname);
		form?.setValue(`employees.${index}.base.regionNm1`, postcodeData.sido);
		form?.setValue(`employees.${index}.base.regionNm2`, postcodeData.sigungu);
		form?.setValue(`employees.${index}.base.regionNm3`, postcodeData.bname);

		form?.setValue(`employees.${index}.base.employeeAddrLatitude`, postcodeData.latitude ?? 0);
		form?.setValue(`employees.${index}.base.employeeAddrLongitude`, postcodeData.longitude ?? 0);
	};

	const handleChangeDetailAddress = (detailAddress: string) => {
		form?.setValue(`employees.${index}.base.employeeDetailAddr`, detailAddress);
	};

	const relationCodes = useMemo(
		() => commonCodes?.CMN038.filter((item) => item?.data?.etcDesc1 === 'Y'),
		[commonCodes?.CMN038],
	);

	useEffect(() => {
		if (index >= 0) return;
		if (!employee.base.twoPhoneYn) {
			form.clearErrors(`employees.${index}.base.subMobilePhoneNo`);
		} else if (!employee.base.subMobilePhoneNo?.length) {
			form.setError(`employees.${index}.base.subMobilePhoneNo`, {
				message: '투폰 전화번호를 입력해주세요.',
			});
		}

		if (employee.base.tagCallRequestYn && employee.base.dutyCds?.length === 0) {
			form.setError(`employees.${index}.base.dutyCds`, {
				message: '태그콜 요일을 선택해주세요.',
			});
			return;
		}
	}, [employee, index]);

	useEffect(() => {
		const associates = form.getValues('service.associates.contractEmployees') ?? [];

		const targetContracts = associates
			.filter((item) => item?.employeeId === employee?.base?.employeeId)
			.map((item) => ({ dutyCd: item.employeeDutyCd }));

		const previousValue = employee?.base?.dutyCds ?? [];

		const uniqueDuty = _.uniqBy([...previousValue, ...targetContracts], (item) => item.dutyCd);
		if (uniqueDuty.length) {
			form.setValue(`employees.${index}.base.dutyCds`, uniqueDuty);
		}
	}, [employee, index]);

	if (!employee) return <div />;

	return (
		<S.Container>
			<S.Form>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn>
							이름 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										ref={ref}
										onChange={onChange}
										value={form.watch(`employees.${index}.base.employeeNm`) ?? ''}
										placeholder='직원명 입력'
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.employeeNm`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn
							rowSpan={2}
							style={{
								verticalAlign: 'top',
							}}>
							주소 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }} rowSpan={2}>
							<CRInput.Address
								onChangeBaseAddress={handleChangeBaseAddress}
								onChangeDetailAddress={handleChangeDetailAddress}
								baseAddress={employee.base.employeeBasAddr}
								detailAddress={employee.base.employeeDetailAddr}
								disabled={disabled}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							주민등록번호 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableLabelColumn>
							<CRRsdNumber
								rdsNumber={form.getValues(`employees.${index}.base.employeeRsdnNo`)}
								textColor='gray60'
								disabled={disabled}
							/>
						</S.TableLabelColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							생년월일 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										type='number'
										onBlur={onBlur}
										maxLength={8}
										onChange={onChange}
										value={form.watch(`employees.${index}.base.employeeBirthDt`) ?? ''}
										placeholder='예시) 19280301'
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.employeeBirthDt`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>
							관계 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								key={`${index}-employeeRelCd`}
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										items={relationCodes}
										ref={ref}
										type='small'
										onChangeValue={(item) => onChange(item.value)}
										currentValue={
											employee.base.employeeRelCd
												? {
														label: '',
														value: employee.base.employeeRelCd,
												  }
												: undefined
										}
										placeholder='관계 선택'
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.employeeRelCd`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							전화번호 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										ref={ref}
										type='number'
										onBlur={onBlur}
										onChange={onChange}
										value={form.watch(`employees.${index}.base.tagMobilePhoneNo`) ?? ''}
										maxLength={11}
										placeholder='전화번호 입력'
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.tagMobilePhoneNo`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>
							직무 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								key={`${index}-dutyCds`}
								render={({ field: { value, onBlur, ref, onChange }, formState: { errors } }) => (
									<S.WrappingContainer>
										<CRCheckBoxGroup
											ref={ref}
											checkType='multiple'
											onChange={(items) =>
												onChange(
													items.map((item) => ({
														dutyCd: item.value,
													})),
												)
											}
											type='checkbox'
											gap={0.8}
											value={
												employee.base.dutyCds?.map((item) => ({
													label: '',
													value: item.dutyCd,
												})) ?? []
											}
											options={(commonCodes?.CMN070 ?? []).filter(
												(item) => item?.data?.comCdDesc === '1',
											)}
											disabled
										/>
									</S.WrappingContainer>
								)}
								name={`employees.${index}.base.dutyCds`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							투폰/투번호 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, value, onChange }, formState: { errors } }) => (
									<CRCheckBoxGroup
										checkType='single'
										type='radio'
										gap={0.8}
										onChange={(item) => onChange(item[0].value)}
										value={[
											{
												label: '',
												value: form.watch(`employees.${index}.base.twoPhoneYn`),
											},
										]}
										options={[
											{
												label: '없음',
												value: false,
											},
											{
												label: '있음',
												value: true,
											},
										]}
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.twoPhoneYn`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>
							치매교육 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										onChange={(item) => onChange(item[0].value)}
										value={[
											{
												label: '',
												value: form.watch(`employees.${index}.base.dementiaEduCompleteYn`),
											},
										]}
										options={[
											{
												label: '미수료',
												value: false,
											},
											{
												label: '수료',
												value: true,
											},
										]}
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.dementiaEduCompleteYn`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							투폰 전화번호 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										disabled={!form.watch(`employees.${index}.base.twoPhoneYn`) || !disabled}
										ref={ref}
										type='number'
										onBlur={onBlur}
										maxLength={11}
										onChange={onChange}
										value={form.watch(`employees.${index}.base.subMobilePhoneNo`)}
										placeholder='전화번호 입력'
									/>
								)}
								name={`employees.${index}.base.subMobilePhoneNo`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>
							장애여부 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										items={commonCodes?.CMN149}
										ref={ref}
										type='small'
										onChangeValue={(item) => onChange(item.value)}
										currentValue={
											employee.base.disabilityGradeCd
												? {
														label: '',
														value: employee.base.disabilityGradeCd,
												  }
												: undefined
										}
										placeholder='장애코드 선택'
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.disabilityGradeCd`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							태그콜 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										value={[
											{ label: '', value: form.watch(`employees.${index}.base.tagCallRequestYn`) },
										]}
										onChange={(item) => onChange(item[0].value)}
										options={[
											{
												label: '미진행',
												value: false,
											},
											{
												label: '진행',
												value: true,
											},
										]}
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.tagCallRequestYn`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn
							rowSpan={2}
							style={{
								verticalAlign: 'top',
							}}>
							비고
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }} rowSpan={2}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TextArea
										typography='label'
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={form.watch(`employees.${index}.base.employeeSpecialDesc`)}
										placeholder='비고 입력'
										numberOfLines={3}
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.employeeSpecialDesc`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>태그콜 요일</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRCheckBoxGroup
											ref={ref}
											checkType='multiple'
											type='checkbox'
											gap={0.8}
											onChange={(items) => {
												if (items.length) {
													form.clearErrors(`employees.${index}.base.dutyCds`);
												}
												onChange(items.map((item) => ({ tagCallDayCd: item.value })));
											}}
											value={form
												.watch(`employees.${index}.base.tagCallDayCds`)
												?.map((item) => ({ label: '', value: item.tagCallDayCd }))}
											options={(commonCodes?.CMN020 ?? []).map((item) => ({
												...item,
												disabled: !form.watch(`employees.${index}.base.tagCallRequestYn`),
											}))}
										/>
										{errors?.employees?.[index]?.base?.dutyCds && (
											<CRInputMessage type='error'>
												{errors?.employees[index]?.base?.dutyCds?.message ?? ''}
											</CRInputMessage>
										)}
									</>
								)}
								name={`employees.${index}.base.tagCallDayCds`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>입사일</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onChange, value } }) => {
									const currentValue = form.watch(`employees.${index}.base.joinDate`)
										? dayjs(form.watch(`employees.${index}.base.joinDate`)).toDate()
										: undefined;
									return (
										<CRInput.DatePicker
											type='S'
											value={currentValue}
											onChangeValue={onChange}
											placeholder='입사일 선택'
											disabled={disabled}
										/>
									);
								}}
								name={`employees.${index}.base.joinDate`}
								control={form.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>서류 수신 방법</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										items={commonCodes?.CMN180}
										ref={ref}
										type='small'
										onChangeValue={(item) => onChange(item.value)}
										currentValue={
											employee.base.docReceiveMethodCd
												? {
														label: '',
														value: employee.base.docReceiveMethodCd,
												  }
												: undefined
										}
										placeholder='서류 수신 방법 선택'
										disabled={disabled}
									/>
								)}
								name={`employees.${index}.base.docReceiveMethodCd`}
								control={form.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.Form>
		</S.Container>
	);
}

export default ChangeEmployeeEmployeeBasicForm;
