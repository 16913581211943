import { styled } from 'styled-components';

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	padding: 1.6rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	padding: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;
