import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	padding: 0 0.8rem;

	& + & {
		margin-top: 0.8rem;
	}
`;
