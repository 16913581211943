import React from 'react';

import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import SalaryTable from 'components/domain/table/SalaryTable';
import SalaryDetailSideModal from 'components/domain/sideModal/SalaryDetailSideModal';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useSideModal from 'lib/hook/util/useSideModal';
import { SalaryDetail } from 'types/view/salary';

import * as S from './styles';

function SalaryTaskPage(): React.ReactElement {
	const { showSideModal } = useSideModal();

	const handleClickDetailItem = (item: SalaryDetail) => {
		showSideModal(<SalaryDetailSideModal item={item} />);
	};

	useFullScreen();

	return (
		<S.Container>
			<CRTable.BackBlind
				stickyHeader={
					<S.Header>
						<S.HeaderLeft>
							<S.Title>2023년 5월 급여 확인</S.Title>
							<S.Duration>시작일: 2023.04.01 ~ 마감일: 2023.04.05 (D-4)</S.Duration>
						</S.HeaderLeft>
						<S.HeaderRight>
							<CRButton.Default disabled={false}>처리 완료</CRButton.Default>
						</S.HeaderRight>
					</S.Header>
				}>
				{/* <SalaryTable
					items={SalaryMock}
					currentFilter={{}}
					onClickSalaryDetail={handleClickDetailItem}
				/> */}
			</CRTable.BackBlind>
		</S.Container>
	);
}

export default SalaryTaskPage;
