import React, { useMemo, useState } from 'react';

import Assets from 'assets';
import CRTableHeader from 'components/base/CRTableHeader';
import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import { useCommonCodes, useEmployeePageEduInfo } from 'lib/hook/react-query';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import EmployeeEducationTable from 'components/domain/table/EmployeeEducationTable';
import AddEducationDialog from 'components/domain/dialog/AddEducationDialog';
import CRSpinner from 'components/base/CRSpinner';
import dayjs from 'dayjs';
import * as S from './styles';

const YEAR_RANGE = Array.from(
	{ length: dayjs().year() - 2018 },
	(_, index) => dayjs().year() - index,
);

export default function EmployeeLicenseTab() {
	const [currentYear, setCurrentYear] = useState<number>(YEAR_RANGE[0]);
	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN074'],
	});

	// 교육 종류
	const eduTypes = useMemo(
		() =>
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN074')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [],
		[commonCodes],
	);
	const { showDialog } = useDialog();
	const { currentEmployee } = useEmployeePage();

	const {
		data: employeeEduInfo,
		refetch,
		isLoading,
	} = useEmployeePageEduInfo({
		employeeId: currentEmployee?.employeeId,
		year: currentYear,
	});

	const handleRefresh = async () => refetch();

	const handleClickAddEducation = () => {
		showDialog(({ hideDialog }) => (
			<AddEducationDialog
				currentEmployee={currentEmployee}
				eduTypes={eduTypes}
				hideDialog={hideDialog}
				onRefresh={handleRefresh}
			/>
		));
	};

	const onClickYearFilter = (year: number) => {
		setCurrentYear(year);
	};

	const renderYearFilter = () => (
		<S.ButtonContainer>
			{YEAR_RANGE.map((year) => (
				<CRButton.Default
					size='small'
					palette='gray'
					type={year === currentYear ? 'filled' : 'outlined'}
					onClick={() => onClickYearFilter(year)}>
					{`${year}년`}
				</CRButton.Default>
			))}
		</S.ButtonContainer>
	);
	return (
		<S.Container>
			<CRTableHeader
				currentFilter={{}}
				filters={[]}
				hideSearchButton
				renderCustomFilter={renderYearFilter()}
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddEducation}>
						교육 추가
					</CRButton.IconButton>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<EmployeeEducationTable
						items={employeeEduInfo}
						onRefresh={handleRefresh}
						eduTypes={eduTypes}
					/>
				</S.TableContainer>
			)}
		</S.Container>
	);
}
