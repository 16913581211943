import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: overlay;
	box-sizing: border-box;
`;

export const PaidAmountText = styled.span<{ $payMethod?: string }>`
	${(props) =>
		props.$payMethod === 'CMN135.10' &&
		css`
			color: ${props.theme.colors.primary60};
		`}

	${(props) =>
		props.$payMethod === 'CMN135.40' &&
		css`
			color: ${props.theme.colors.blue};
		`}
`;

export const TableContainer = styled.div`
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-top: 0rem;
	border-bottom: 0rem;
	margin-bottom: 2.4rem;
`;
