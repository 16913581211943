import React, {
	CSSProperties,
	ChangeEventHandler,
	FocusEvent,
	FocusEventHandler,
	ForwardedRef,
	forwardRef,
	useState,
} from 'react';

import Typography from 'common/typography';
import * as S from './styles';

interface IProps {
	typography?: keyof typeof Typography;
	value?: number | string;
	height?: string;
	id?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	readOnly?: boolean;
	style?: CSSProperties;
	onChange?: ChangeEventHandler<HTMLTextAreaElement>;
	onBlur?: FocusEventHandler;
	blurCallback?: (value: string | number | undefined) => void;
	numberOfLines?: number;
	showBorder?: boolean;
	fixedHeight?: boolean;
}

function CRTextArea(
	{
		typography = 'body',
		value,
		height,
		id,
		status = 'default',
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		placeholder = '',
		readOnly = false,
		style = {},
		onChange,
		onBlur,
		blurCallback,
		numberOfLines = 3,
		showBorder = true,
		fixedHeight = false,
	}: IProps,
	ref: ForwardedRef<HTMLTextAreaElement>,
): React.ReactElement {
	const [isFocused, setIsFocused] = useState(false);
	const onFocus = () => setIsFocused(true);
	const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
		blurCallback?.(value);
		setIsFocused(false);
		onBlur?.(e);
	};

	return (
		<S.Container style={style} $isDisabled={disabled}>
			<S.InputContainer
				$fixedHeight={fixedHeight}
				$showBorder={showBorder}
				$readOnly={readOnly}
				$height={height}
				$typography={typography}
				data-test={fixedHeight === true ? undefined : value}
				$isFocused={isFocused}
				$error={status === 'error'}
				disabled={disabled}
				$numberOfLines={numberOfLines}>
				<textarea
					ref={ref}
					id={id}
					value={value}
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={handleBlur}
					onFocus={onFocus}
					onChange={onChange}
					readOnly={readOnly}
				/>
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRTextArea);
