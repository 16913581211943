import { CRTableHeadConfig } from 'types/view/base';

export const SEND_HISTORY_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '18.2rem',
		label: '발송일시',
		sortKey: '발송일시',
	},
	{
		width: '26rem',
		label: '템플릿',
		sortKey: '템플릿',
	},
	{
		width: '60rem',
		label: '제목',
		sortKey: '제목',
	},
	{
		width: '10rem',
		label: '발송자',
		sortKey: '발송자',
	},
	{
		width: '12rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
];
