import CalendarChip from './CalendarChip';
import DayCalendarChip from './DayCalendarChip';
import DefaultChip from './DefaultChip';
import MonthCalendarChip from './MonthCalendarChip';
import SearchChips from './SearchChips';
import SelectionChip from './SelectionChip';

export default {
	Default: DefaultChip,
	Selection: SelectionChip,
	Calendar: CalendarChip,
	MonthCalendar: MonthCalendarChip,
	DayCalendar: DayCalendarChip,
	Search: SearchChips,
};
