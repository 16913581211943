import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const LONGTERM_SYNC_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '20rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '14.8rem',
		label: '요청 일시',
		sortKey: '요청 일시',
	},
	{
		width: '14.8rem',
		label: '완료 일시',
		sortKey: '완료 일시',
	},
	{
		width: '12rem',
		label: '연월',
		sortKey: '연월',
	},
	{
		width: '8rem',
		label: '요청자',
		sortKey: '요청자',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const DATA_SYNC_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '16rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '32rem',
		label: '상세',
		sortKey: '상세',
	},
	{
		width: '14.8rem',
		label: '요청 일시',
		sortKey: '요청 일시',
	},
	{
		width: '14.8rem',
		label: '완료 일시',
		sortKey: '완료 일시',
	},
	{
		width: '14.8rem',
		label: '연월',
		sortKey: '연월',
	},
	{
		width: '8rem',
		label: '요청자',
		sortKey: '요청자',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const SYNC_HISTORY_STATUS_CONFIG: CRStatusConfig[] = [
	{ key: 'CMN108.REQUEST', label: '요청완료', color: 'gray' },
	{ key: 'CMN108.PROGRESS', label: '진행중', color: 'yellow' },
	{ key: 'CMN108.ERROR', label: '실패', color: 'red' },
	{ key: 'CMN108.PARTIALLY_COMPLETE', label: '부분완료', color: 'blue' },
	{ key: 'CMN108.COMPLETE', label: '완료', color: 'green' },
	{ key: 'CMN108.CLEAR', label: '초기화', color: 'red' },
];
