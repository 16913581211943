import React from 'react';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';

import { EditableRecipientFormTypes } from 'lib/provider/recipient';
import RecipientOwnExpenseForm from './RecipientOwnExpenseForm';
import RecipientOwnExpenseView from './RecipientOwnExpenseView';
import * as S from './styles';

function RecipientOwnExpenseFormView(): React.ReactElement {
	const {
		currentRecipient,
		currentRecipientOwnExpense,
		isFormEditable,
		startChangeEditSection,
		cancelChangeEditSection,
		isEditSectionChanging,
	} = useRecipientPage();

	const onStartChangeSection = () => {
		startChangeEditSection(EditableRecipientFormTypes.본인부담금);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};
	return (
		<S.Container>
			{isFormEditable(EditableRecipientFormTypes.본인부담금) ? (
				<RecipientOwnExpenseForm
					currentRecipient={currentRecipient}
					items={currentRecipientOwnExpense}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<RecipientOwnExpenseView
					items={currentRecipientOwnExpense}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}

export default RecipientOwnExpenseFormView;
