import CRTableHeader from 'components/base/CRTableHeader';
import React, { useEffect, useMemo, useState } from 'react';
import CRTable from 'components/base/CRTable';
import { PageInfo } from 'types/view/base';
import { CheckOption } from 'components/base/Selections/type';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { Filter } from 'types/view/filter';
import UserAccountTable from 'components/domain/table/UserAccountTable';
import { useUserAccountRoles, useUserAccounts } from 'lib/hook/react-query/query/userAccount';
import CRSpinner from 'components/base/CRSpinner';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import usePageFilter from 'lib/hook/util/usePageFilter';
import * as S from './styles';

export function CenterUserTab() {
	const { data: accountRoles } = useUserAccountRoles();
	const isCenterChief = useHasPermission('센터장');
	const { filters, setFilters, pageInfo, setPageInfo } = usePageFilter({});
	const [keyword, setKeyword] = useState('');
	const { data: myAccountInfo } = useMyAccountInfo();
	const [searchValue, setSearchValue] = useState('');

	const {
		data: userAccounts,
		isLoading: userAccountsLoading,
		refetch,
	} = useUserAccounts({
		page: pageInfo.page,
		size: pageInfo.size,
		centerId: myAccountInfo?.centerId,
		roleIds: filters?.accountRolesFilter?.map((item) => item.value) || [],
		accountDisabledYn: isCenterChief ? filters?.accountDisabledYnFilter?.[0]?.value : false,
		keyword,
	});

	const handleRefetch = () => {
		refetch();
	};

	const accountRolesFilter = useMemo(() => {
		const options = accountRoles?.map((item) => ({
			label: item.roleDesc,
			value: item.roleId,
			data: item,
		}));
		return {
			key: 'accountRolesFilter',
			type: 'multi',
			placeholder: '직책',
			options: options || [],
		} as Filter<CheckOption>;
	}, [accountRoles]);

	const accountDisabledYnFilter = useMemo(
		() =>
			({
				key: 'accountDisabledYnFilter',
				type: 'toggle',
				options: [
					{
						label: '로그인 비허용',
						value: true,
					},
				],
			} as Filter<{ label: string; value: any }>),
		[],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);
	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>계정 정보가 없습니다.</S.EmptyTitle>
			</S.EmptyContainer>
		),
		[],
	);

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: userAccounts?.totalPages || 0,
		}));
	}, [userAccounts?.totalPages]);

	return (
		<CRTable.BackBlind
			contentStyle={{
				padding: 0,
			}}>
			<S.TableContainer>
				<S.TableHeaderContainer>
					<CRTableHeader
						onChangePageInfo={handleChangePageInfo}
						pageInfo={pageInfo}
						currentFilter={filters}
						stickyMode
						filters={
							isCenterChief ? [accountRolesFilter, accountDisabledYnFilter] : [accountRolesFilter]
						}
						searchValue={searchValue}
						placeholder='이름으로 검색'
						onChangeSearchValue={handleSearchValue}
						setCurrentFilter={setFilters}
						onSearch={handleSearch}
						showRefresh
						showViewCount
						onRefresh={handleRefetch}
					/>
				</S.TableHeaderContainer>
				{userAccountsLoading ? (
					<CRSpinner />
				) : userAccounts && userAccounts?.content?.length > 0 ? (
					<S.TableScrollContainer>
						<UserAccountTable
							isCenterChief={isCenterChief}
							refetch={handleRefetch}
							items={userAccounts?.content || []}
						/>
					</S.TableScrollContainer>
				) : (
					renderEmpty
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
