import React, {
	ChangeEventHandler,
	FocusEvent,
	FocusEventHandler,
	ForwardedRef,
	KeyboardEvent,
	KeyboardEventHandler,
	forwardRef,
	useState,
} from 'react';

import Assets from 'assets';
import * as S from './styles';

interface IProps {
	value?: number | string;
	id?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	autoComplete?: boolean;
	toggleSecret?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
}

function CRInputPassword(
	{
		value,
		id,
		status = 'default',
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		placeholder = '',
		autoComplete = false,
		toggleSecret = false,
		onChange,
		onBlur,
		onPressEnter,
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const [isFocused, setIsFocused] = useState(false);
	const [isVisibleMode, setIsVisibleMode] = useState(false);
	const onFocus = () => setIsFocused(true);
	const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
		setIsFocused(false);
		onBlur?.(e);
	};
	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};

	return (
		<S.Container>
			<S.InputContainer $isFocused={isFocused} $error={status === 'error'} disabled={disabled}>
				<input
					ref={ref}
					id={id}
					value={value ?? ''}
					type={isVisibleMode ? 'text' : 'password'}
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={handleBlur}
					onFocus={onFocus}
					onChange={onChange}
					onKeyUp={onKeyUp}
					autoComplete={autoComplete ? 'on' : 'new-password'}
				/>
				{toggleSecret && (
					<S.Icon
						src={isVisibleMode ? Assets.icon.disabledEye : Assets.icon.removeRedEye}
						disabled={disabled}
						onClick={() => setIsVisibleMode((prev) => !prev)}
					/>
				)}
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRInputPassword);
