import React, { useEffect, useMemo, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import VisitCareContractForm from 'components/domain/form/VisitCareContractForm';
import VisitCareServiceForm from 'components/domain/form/VisitCareServiceForm';
import VisitBathServiceForm from 'components/domain/form/VisitBathServiceForm';
import VisitNursingServiceForm from 'components/domain/form/VisitNursingServiceForm';
import VisitBathContractForm from 'components/domain/form/VisitBathContractForm';
import VisitNursingContractForm from 'components/domain/form/VisitNursingContractForm';
import ContractServiceAddDialog from 'components/domain/dialog/ContractServiceAddDialog';
import CRPortal from 'components/base/CRPortal';
import CRTab from 'components/base/CRTab';
import { CheckOption } from 'components/base/Selections/type';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import { ContractDetailServiceDTO } from 'types/dto';

function ServiceInspectionPage(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractTask();

	const { showDialog } = useGlobalLayout();

	const [tabs, setTabs] = useState<CheckOption[]>([]);
	const [currentService, setCurrentService] = useState<CheckOption | null>(tabs[0]);

	const handleAddTab = (value: CheckOption[]) => {
		const previous = form?.getValues('services') ?? [];
		const newServices = [
			...previous,
			...value.map((item) => ({ serviceTypeCd: item.value } as ContractDetailServiceDTO)),
		];
		form?.setValue('services', newServices);
		setTabs(
			newServices.map((item) => ({
				label: commonCodes?.CMN006?.find((code) => code.value === item.serviceTypeCd)?.label ?? '',
				value: item.serviceTypeCd,
			})),
		);
	};

	const handleClickRemoveTab = (value: CheckOption) => {
		const previous = form?.getValues('services') ?? [];
		const newServices = previous.filter((item) => item.serviceTypeCd !== value.value);
		const newTabs = newServices.map((item) => ({
			label: commonCodes?.CMN006?.find((code) => code.value === item.serviceTypeCd)?.label ?? '',
			value: item.serviceTypeCd,
		}));

		form?.setValue('services', newServices);
		setTabs(newTabs);
		setCurrentService({ ...newTabs[0] });
	};

	const handleClickAddService = () => {
		showDialog(({ hideDialog }) => (
			<ContractServiceAddDialog
				currentItems={tabs}
				onSubmit={handleAddTab}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleChangeTab = (current: CheckOption) => {
		setCurrentService(current);
	};

	const forms = useMemo(() => {
		if (currentService?.value === 'CMN006.10') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <VisitCareServiceForm />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <VisitCareContractForm />,
				},
			];
		}
		if (currentService?.value === 'CMN006.20') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <VisitBathServiceForm />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <VisitBathContractForm />,
				},
			];
		}
		if (currentService?.value === 'CMN006.30') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <VisitNursingServiceForm />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <VisitNursingContractForm />,
				},
			];
		}

		return [];
	}, [currentService]);

	useEffect(() => {
		const newTabs = (form?.getValues('services') ?? []).map((item) => ({
			label: commonCodes?.CMN006?.find((code) => code.value === item.serviceTypeCd)?.label ?? '',
			value: item.serviceTypeCd,
		}));
		setTabs(newTabs);
		setCurrentService(newTabs[0]);
	}, [form.getValues('services')?.length]);

	return (
		<>
			<CRPortal id='task_layout_portal'>
				<CRTab.Contract
					items={tabs}
					defaultActiveKey={currentService?.value}
					onChange={handleChangeTab}
					onAdd={handleClickAddService}
					onDelete={handleClickRemoveTab}
					canAdd={tabs.length < 3}
					addDisabled={disabled}
				/>
			</CRPortal>
			{forms.length && (
				<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
					{forms.map((form) => (
						<Accordion.Item value={form.id} asChild>
							<TaskAccordion.Item>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					))}
				</Accordion.Root>
			)}
		</>
	);
}

export default React.memo(ServiceInspectionPage);
