import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	overflow: hidden;
`;

export const Header = styled.div`
	padding: 2.4rem 2.4rem 1.6rem;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 7.6rem;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const Content = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 2.4rem 1.6rem;
	gap: 1.6rem;

	& > * {
		user-select: auto;
	}
`;

export const SubTitle = styled.p`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray00};
`;
export const BottomContainer = styled.div`
	height: 8rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const RightButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;

export const HelperTextContainer = styled.div`
	margin-top: -0.8rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const HelperTextTitle = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.8rem;
	gap: 0.8rem;
`;
