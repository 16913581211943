import styled, { css } from 'styled-components';

export const TableHeader = styled.thead`
	height: 3.3rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableHeaderColumn = styled.th<{ $isSortable?: boolean }>`
	position: sticky;
	height: 3.3rem;
	z-index: 5;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
	background: ${(props) => props.theme.colors.gray99};
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:first-of-type {
		border-left: none;
	}
`;

export const TableHeaderColumnWithPadding = styled(TableHeaderColumn)`
	padding: 0.4rem 0.8rem;
	text-align: left; ;
`;

export const TableHeaderColumnContentContainer = styled.div<{ $isCheckBox?: boolean }>`
	position: relative;
	width: 100%;
	height: 100%;
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	cursor: pointer;
	${(props) =>
		props.$isCheckBox &&
		css`
			display: flex;
			justify-content: center;
		`};
`;
