import React from 'react';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import CRButton from 'components/base/CRButton';
import { ResponseCode } from 'types/api';
import { useDeleteEmployeeSalarySchedule } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { Toast } from 'components/base/CRToast';
import dayjs from 'dayjs';
import CRSpinner from 'components/base/CRSpinner';
import { SalaryServiceSchedule } from 'types/api/salary';
import * as S from './styles';

interface Props {
	scheduleData: SalaryServiceSchedule;
}

export function DeleteSalaryScheduleDialog({ scheduleData }: Props) {
	const { hideDialog } = useDialog();

	const { mutate: deleteSalarySchedule, isLoading: deleteScheduleLoading } =
		useDeleteEmployeeSalarySchedule(async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('근로 상세 내역을 삭제하였습니다. 임금을 재계산해 주십시오.');
				hideDialog();
				client.invalidateQueries([
					endpoint.getSalarysEmployeeSalaryDetail.key,
					{ employeeSalaryId: scheduleData.employeeSalaryId },
				]);
			} else {
				hideDialog();
				Toast.error('근로 상세 내역 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const handleClickDelete = () => {
		deleteSalarySchedule({
			employeeSalaryId: scheduleData.employeeSalaryId,
			scheduleId: scheduleData.salaryServiceScheduleId,
		});
	};

	const content = `${dayjs(scheduleData.serviceStartDt).format('YYYY년 M월 D일')} ${
		scheduleData.recipientNm
	} 수급자 근로 상세 내역을 삭제하시겠습니까?`;
	return (
		<CRDialog
			title='근로 상세 내역 삭제'
			showCloseButton={false}
			body={<S.Container>{content}</S.Container>}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						style={{
							position: 'relative',
							minWidth: '7.6rem',
							height: '4rem',
							display: 'flex',
							gap: '0.8rem',
						}}
						palette='primary'
						type='outlined'
						size='default'
						disabled={deleteScheduleLoading}
						onClick={handleClickDelete}>
						<S.LoadingContainer>
							{deleteScheduleLoading ? <CRSpinner floatMode={false} /> : '삭제'}
						</S.LoadingContainer>
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
