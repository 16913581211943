import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRRequiredMark from 'components/base/CRRequiredMark';
import * as S from './styles';
import { integratedAssessmentDesireCommunicationStatusQuestions } from './constant';
import IntegratedAssessmentDesireCommunicationStatusFormHeader from './IntegratedAssessmentDesireCommunicationStatusFormHeader';
import CRCheckBox from '../../../base/Selections/CRCheckBox';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesireCommunicationStatusForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireCommunicationStatusFormHeader />
				<S.TableBody>
					{integratedAssessmentDesireCommunicationStatusQuestions.map((question) => (
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										{question.label}
										{showGenerateRequiredFields && question.required?.includes('generate') && (
											<CRRequiredMark />
										)}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentContainer>
											{(question.answer ?? []).map((answer) => (
												<CRCheckBox
													checkBoxType='radio'
													checked={form.watch(question.key)?.includes(answer.value)}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target.checked) {
															onChange(answer.value);
														} else {
															onChange(undefined);
														}
													}}>
													{answer.label}
												</CRCheckBox>
											))}
										</S.TableContentContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							)}
							name={question.key}
							control={form.control}
						/>
					))}
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='의사소통판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireCommunicationStatusForm;
