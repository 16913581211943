import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	background: ${(props) => props.theme.colors.gray99};
	overflow: auto;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
