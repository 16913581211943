import React from 'react';

import * as S from './styles';

interface IProps {
	LeftSideComponent?: React.ReactElement;
}

function TaskLayout({
	LeftSideComponent,
	children,
}: React.PropsWithChildren<IProps>): React.ReactElement {
	return (
		<S.Container>
			<S.Container>
				<S.LeftContainer>{LeftSideComponent}</S.LeftContainer>
				<S.MainContentContainer>
					<div id='task_layout_portal' />
					<S.TaskContainer>{children}</S.TaskContainer>
				</S.MainContentContainer>
			</S.Container>
		</S.Container>
	);
}

export default React.memo(TaskLayout);
