import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 10000;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	${(props) => props.theme.noneSelection}
`;

export const Background = styled.div`
	position: absolute;
	z-index: -10;
	width: 100%;
	height: 100%;
	background: ${(props) => props.theme.colors.gray00};
	opacity: 0.1;
`;

export const QuickSearchContainer = styled.div`
	background: ${(props) => props.theme.colors.gray100};
	border-radius: ${(props) => props.theme.radius.radius02};
	overflow: auto;
`;
