import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import RegisterAutomationTable from 'components/domain/table/RegisterAutomationTable';
import RegisterSideModal from 'components/domain/sideModal/RegisterSideModal';
import CRTable from 'components/base/CRTable';
import useSideModal from 'lib/hook/util/useSideModal';
import { CreateScheduleStatus, ScheduleItem } from 'types/view/task';

import * as S from './styles';

function AutomationPage(): React.ReactElement {
	const { showSideModal } = useSideModal();
	const navigate = useNavigate();

	const [currentFilter, setCurrentFilter] = useState('all');

	const dummy = [
		{
			id: v4(),
			name: '장원영',
			userNumber: 381108,
			serviceType: '방문요양',
			status: CreateScheduleStatus.DONE,
			items: [
				{
					days: [0, 1],
					startTime: '12:00',
					endTime: '14:00',
				},
				{
					days: [3, 5],
					startTime: '14:00',
					endTime: '16:00',
				},
			],
		},
		{
			id: v4(),
			name: '장투영',
			userNumber: 381110,
			serviceType: '방문요양',
			status: CreateScheduleStatus.ERROR,
			items: [
				{
					days: [0, 1],
					startTime: '12:00',
					endTime: '14:00',
				},
				{
					days: [3, 5],
					startTime: '14:00',
					endTime: '16:00',
				},
			],
		},
		{
			id: v4(),
			name: '장원여응',
			userNumber: 381111,
			serviceType: '방문요양',
			status: CreateScheduleStatus.DONE,
			items: [
				{
					days: [0, 1],
					startTime: '12:00',
					endTime: '14:00',
				},
				{
					days: [3, 5],
					startTime: '14:00',
					endTime: '16:00',
				},
			],
		},
	];
	const handleClickRow = (schedule: ScheduleItem) =>
		showSideModal(<RegisterSideModal item={schedule} />);

	const handleGoErrorEdit = () => {
		navigate(RouterPath.myTaskOperate().errorEdit.key);
	};

	const renderCustomFilter = () => (
		<S.CustomFilterContainer>
			<S.CustomFilterChip
				onClick={() => setCurrentFilter('all')}
				$isActive={currentFilter === 'all'}>
				전체
			</S.CustomFilterChip>
			<S.Divider />
			<S.CustomFilterRight>
				<S.CustomFilterChip
					onClick={() => setCurrentFilter('error')}
					$isActive={currentFilter === 'error'}>
					빈일정
				</S.CustomFilterChip>
				<S.CustomFilterChip
					onClick={() => setCurrentFilter('normal')}
					$isActive={currentFilter === 'normal'}>
					정상 일정
				</S.CustomFilterChip>
			</S.CustomFilterRight>
		</S.CustomFilterContainer>
	);

	const isNextStepReady = dummy.every(
		(data) => data.status !== CreateScheduleStatus.BEFORE_AUTOMATION,
	);

	return (
		<CRTable.BackBlind
			stickyHeader={
				<S.Header>
					<S.HeaderLeft>
						<S.Title>자동화 공단 등록 - 23년 5월 일정 생성 (120건)</S.Title>
						<S.Duration>시작일: 2023.04.10 ~ 마감일: 2023.04.19 (D-13)</S.Duration>
					</S.HeaderLeft>
					<S.HeaderRight>
						<CRButton.Default disabled={!isNextStepReady} onClick={handleGoErrorEdit}>
							다음
						</CRButton.Default>
					</S.HeaderRight>
				</S.Header>
			}>
			<RegisterAutomationTable
				renderCustomFilter={renderCustomFilter()}
				currentFilter={[] as any}
				onClickRow={handleClickRow}
				// onClickButton={onClickNewTask}
				items={dummy}
			/>
		</CRTable.BackBlind>
	);
}

export default AutomationPage;
