import { CRTableHeadConfig } from 'types/view/base';

export const STAFF_EDUCATION_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '40rem',
		label: '종류',
		sortKey: '종류',
	},
	{
		width: '16rem',
		label: '교육일정',
		sortKey: '교육일정',
	},
	{
		width: '16rem',
		label: '발급 기관',
		sortKey: '발급 기관',
	},
	{
		width: '21.6rem',
		label: '첨부파일',
		sortKey: '첨부파일',
	},
	{
		width: '20rem',
		label: '',
		sortKey: '',
	},
];
