import React from 'react';

import * as S from './styles';

function IntegratedAssessmentMajorIllnessStatusFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn style={{ width: '13.2rem' }}>질병 분류</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '60rem' }}>질병명</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentMajorIllnessStatusFormHeader);
