import React from 'react';
import { CRText } from 'components/base/CRText';
import { FieldPath, useFormContext } from 'react-hook-form';
import { displayComma } from 'lib';
import {
	SalaryDetailDialogFormFields,
	SalaryItemCdKorKeys,
} from 'components/domain/dialog/SalaryDetailDialog/types';
import CRFormTableInput from 'components/base/CRFormInput/CRFormTableInput';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function SalaryDetailPaymentDeduction({ disabled }: Props) {
	const fieldKey = 'salaryItem';

	const { getValues } = useFormContext<SalaryDetailDialogFormFields>();

	const burdenAmt = getValues(`salaryDetail.burdenAmtDeducts`);

	const getAmt = (field: SalaryItemCdKorKeys) => getValues(`${fieldKey}.${field}.salaryItemAmt`);

	const getRemark = (field: SalaryItemCdKorKeys) => getValues(`${fieldKey}.${field}.remark`);

	const getInputName = (field: SalaryItemCdKorKeys): FieldPath<SalaryDetailDialogFormFields> =>
		`${fieldKey}.${field}.salaryItemAmt`;

	return (
		<S.Container>
			<CRText typography='label' text='지급/공제 내역' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }} colSpan={2}>
						지급 내역
					</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '50%' }} colSpan={2}>
						공제 내역
					</S.TableLabelColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>기본급</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>{getAmt('지급_기본급')}</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>국민연금</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						{getAmt('공제_국민연금')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>주휴수당</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('지급_주휴수당')}</S.TableValueColumn>
					<S.TableLabelColumn>건강보험</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('공제_건강보험')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>식대</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_식대')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>장기요양보험</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('공제_장기요양보험')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>연차수당</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('지급_연차수당')}</S.TableValueColumn>
					<S.TableLabelColumn>고용보험 실업급여</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('공제_고용보험실업급여')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>고정야간수당</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('지급_고정야간수당')}</S.TableValueColumn>
					<S.TableLabelColumn>소득세</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('공제_소득세액')}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>고정휴일수당</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('지급_고정휴일수당')}</S.TableValueColumn>
					<S.TableLabelColumn>지방소득세</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('공제_지방소득세액')}</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>휴일수당</S.TableLabelColumn>
					<S.TableValueColumn>
						{getAmt('지급_휴일수당')}
						<CRText text={getRemark('지급_휴일수당')} display='block' color='gray60' />
					</S.TableValueColumn>
					<S.TableLabelColumn>본인부담금</S.TableLabelColumn>
					<S.TableValueColumn>
						{getAmt('공제_본인부담금')}
						{burdenAmt?.map((item, index) => (
							<FlexContainer direction='column' gap='0.4rem' key={item.burdenAmtDeductId}>
								{disabled ? (
									<>
										<CRText
											text={`(${item.recipientNm} - 요양 ${item.visitCareBurdenAmt}원)`}
											display='block'
											color='gray60'
										/>
										<CRText
											text={`(${item.recipientNm} - 목욕 ${item.visitBathBurdenAmt}원)`}
											display='block'
											color='gray60'
										/>
										<CRText
											text={`(${item.recipientNm} - 간호 ${item.visitNursingBurdenAmt}원)`}
											display='block'
											color='gray60'
										/>
									</>
								) : (
									<>
										<CRFormTableInput
											name={`salaryDetail.burdenAmtDeducts.[${index}].visitCareBurdenAmt`}
											type='comma'
											suffix='원'
											maxLength={11}
											disabled={disabled}
										/>
										<CRText text={`(${item.recipientNm} - 요양)`} display='block' color='gray60' />
										<CRFormTableInput
											name={`salaryDetail.burdenAmtDeducts.[${index}].visitBathBurdenAmt`}
											type='comma'
											suffix='원'
											maxLength={11}
											disabled={disabled}
										/>
										<CRText text={`(${item.recipientNm} - 목욕)`} display='block' color='gray60' />
										<CRFormTableInput
											name={`salaryDetail.burdenAmtDeducts.[${index}].visitNursingBurdenAmt`}
											type='comma'
											suffix='원'
											maxLength={11}
											disabled={disabled}
										/>
										<CRText text={`(${item.recipientNm} - 간호)`} display='block' color='gray60' />
									</>
								)}
							</FlexContainer>
						))}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>야간근로수당</S.TableLabelColumn>
					<S.TableValueColumn>
						{getAmt('지급_야간근로수당')}
						<CRText text={getRemark('지급_야간근로수당')} display='block' color='gray60' />
					</S.TableValueColumn>
					<S.TableLabelColumn>국민연금정산</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_국민연금정산')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>연장근로수당</S.TableLabelColumn>
					<S.TableValueColumn>
						{getAmt('지급_연장근로수당')}
						<CRText text={getRemark('지급_연장근로수당')} display='block' color='gray60' />
					</S.TableValueColumn>
					<S.TableLabelColumn>건강보험정산</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_건강보험정산')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>복지수당</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('지급_복지수당')}</S.TableValueColumn>
					<S.TableLabelColumn>장기요양보험정산</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_장기요양보험정산')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>종일방문요양임금 (비과세)</S.TableLabelColumn>
					<S.TableValueColumn>{getAmt('지급_종일방문요양임금_비과세')}</S.TableValueColumn>
					<S.TableLabelColumn>고용보험정산</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_고용보험정산')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>특별 추가 수당</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_특별추가수당')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>기타공제액</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_기타공제액')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>특별 추가 수당 (비과세)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_특별추가수당_비과세')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>연말정산 소득세</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_연말정산소득세')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>요양보호사 권익증진장려금</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_요양보호사권익증진장려금')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>연말정산 지방소득세</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('공제_연말정산지방소득세')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>간호 특별 수당</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_간호특별수당')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn />
					<S.TableValueColumn />
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>중증 수급자 가산금</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_중증수급자가산금')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
						<CRText
							typography='label'
							color='gray60'
							text={`실지급액(${getAmt('지급_중급수급자가산금_실수령')}원)`}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn />
					<S.TableValueColumn />
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>장기 근속 장려금</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_장기근속장려금')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
						<CRText
							typography='label'
							color='gray60'
							text={`실지급액(${getAmt('지급_장기근속장려금_실수령')}원)`}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn />
					<S.TableValueColumn />
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>방문간호 간호사 가산금</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_방문간호간호사가산금')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
						<CRText
							typography='label'
							color='gray60'
							text={`실지급액(${getAmt('지급_방문간호간호사가산금_실수령')}원)`}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn />
					<S.TableValueColumn />
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>원거리 교통비 가산금</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_원거리교통비가산금')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
						<CRText
							typography='label'
							color='gray60'
							text={`실지급액(${getAmt('지급_원거리교통비가산금_실수령')}원)`}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn />
					<S.TableValueColumn />
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>방문간호 인지 가산금</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={getInputName('지급_방문간호인지가산금')}
							type='comma'
							suffix='원'
							maxLength={11}
							disabled={disabled}
						/>
						<CRText
							typography='label'
							color='gray60'
							text={`실지급액(${getAmt('지급_방문간호인지가산금_실수령')}원)`}
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn />
					<S.TableValueColumn />
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>
						<CRText text='소득합계' typography='labelB' />
					</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRText text={displayComma(getValues('salaryDetail.paySumAmt'))} typography='labelB' />
					</S.TableValueColumn>
					<S.TableLabelColumn>
						<CRText text='공제합계' typography='labelB' />
					</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRText
							text={displayComma(getValues('salaryDetail.deductSumAmt'))}
							typography='labelB'
						/>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
			<CRText typography='label' text='회사부담인건비' margin='0 0 0.4rem 0' />
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>국민연금</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_국민연금')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>건강보험</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_건강보험')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>장기요양보험</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_노인장기요양보험')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>고용보험 실업급여</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_고용보험실업급여')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>고용보험 고용안정</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_고용보험고용안정')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>산재보험</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_산재보험')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '50%' }}>퇴직 적립금</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '50%' }}>
						{getAmt('회사부담_퇴직적립금')}
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default SalaryDetailPaymentDeduction;
