import React, { useEffect } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { EmployeeBaseInfoDTO, EmployeeDTO } from 'types/api/employee';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { HealthInsuranceLossCancelFormTypes } from 'types/view/eDoc';
import dayjs from 'dayjs';
import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<HealthInsuranceLossCancelFormTypes, any, undefined>;
	currentEmployeeBase?: EmployeeBaseInfoDTO;
}

// 건강보험상실취소확인서
export default function EDocHealthInsuranceLossCancelForm({
	templateCode,
	currentEmployee,
	currentEmployeeBase,
	formContext,
}: Props) {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	useEffect(() => {
		const defaultForm: HealthInsuranceLossCancelFormTypes = {
			requestDate: dayjs().set('h', 0).set('m', 0).set('s', 0).toDate(),
		};
		if (eDocBeforeIssueInfo) {
			defaultForm.data = eDocBeforeIssueInfo;
		}

		if (currentEmployeeBase?.healthInsuranceLossDate) {
			defaultForm.healthInsuranceLossDate = dayjs(
				currentEmployeeBase.healthInsuranceLossDate,
			).toDate();
		}

		formContext.reset(defaultForm);
	}, [eDocBeforeIssueInfo, currentEmployeeBase, currentEmployee]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='이름' isRequired>
					<CRInput.Default value={currentEmployeeBase?.korMemberNm || ''} disabled />
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='상실일' isRequired>
							<CRInput.DatePicker
								popupPosition={{
									x: 0,
									y: -250,
								}}
								value={value}
								onChangeValue={onChange}
								placeholder='상실일 입력'
							/>
						</CRInputLabel>
					)}
					name='healthInsuranceLossDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청일' isRequired>
							<CRInput.DatePicker
								popupPosition={{
									x: 0,
									y: -250,
								}}
								value={value}
								onChangeValue={onChange}
								placeholder='신청일 입력'
							/>
						</CRInputLabel>
					)}
					name='requestDate'
					control={formContext.control}
				/>
			</S.SubFormContainer>
		</S.Container>
	);
}
