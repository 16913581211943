import React from 'react';

import * as S from './styles';
import { IButtonProps } from '../CRButton/CRDefaultButton';
import CRButton from '../CRButton';

interface IProps {
	leftButtons?: IButtonProps[];
	rightButtons?: IButtonProps[];
}

function CRPageFooter({ leftButtons = [], rightButtons = [] }: IProps): React.ReactElement {
	return (
		<S.Container>
			<S.ButtonContainer>
				<S.LeftButtonContainer>
					{leftButtons.map((button) => (
						<CRButton.Default {...button} />
					))}
				</S.LeftButtonContainer>
				<S.RightButtonContainer>
					{rightButtons.map((button) => (
						<CRButton.Default {...button} />
					))}
				</S.RightButtonContainer>
			</S.ButtonContainer>
		</S.Container>
	);
}

export default React.memo(CRPageFooter);
