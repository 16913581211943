import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 160rem;
	height: 90vh;
	overflow: hidden;
`;

export const Header = styled.div`
	padding: 2.4rem 2.4rem 1.6rem;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 7.6rem;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Content = styled.div`
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	margin: 0.1rem;
	flex: 1;
	overflow: auto;

	& > * {
		user-select: auto;
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	box-sizing: border-box;
`;

export const BodyContainer = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	display: flex;
	flex-direction: column;
	background: ${(props) => props.theme.colors.gray99};
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: overlay;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const FooterRightSideContainer = styled.div`
	flex: 1;
`;

export const HeaderTitle = styled.h3`
	flex: 1;
`;

export const CloseIcon = styled.img`
	cursor: pointer;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;
