import React, { useState } from 'react';
import Assets from 'assets';
import useDialog from 'lib/hook/util/useDialog';
import RsdReadingDialog from 'components/domain/dialog/RsdReadingDialog';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { MenuId } from 'types/view/base';
import Colors from 'common/colors';
import * as S from './styles';

interface Props {
	rdsNumber?: string;
	disabled?: boolean;
	textColor?: keyof typeof Colors;
}

export default function CRRsdNumber({
	rdsNumber,
	disabled = false,
	textColor,
}: Props): React.ReactElement {
	const [rsdNumberSecretMode, setRsdNumberSecretMode] = useState(true);
	const { showDialog } = useDialog();
	const { currentMenuId } = useGlobalLayout();

	const calculateAgeFromResidentID = (rdsNumber: string) => {
		// 주민등록번호 앞자리에서 생년월일 추출
		const year = Number(rdsNumber.substring(0, 2)); // 년도 끝 두 자리
		const month = Number(rdsNumber.substring(2, 4)); // 월
		const day = Number(rdsNumber.substring(4, 6)); // 일
		const genderCode = Number(rdsNumber.substring(6, 7)); // 성별코드

		// 현재 날짜
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더합니다.
		const currentDay = currentDate.getDate();

		// 만 나이 계산
		let age: number;
		if ([3, 4, 7, 8].includes(genderCode)) {
			age = currentYear - (2000 + year); // 2000년 이후 출생자는 2000을 더해야 정확한 년도가 됩니다.
		} else {
			age = currentYear - (1900 + year); // 2000년 이전 출생자는 1900을 더해야 정확한 년도가 됩니다.
		}

		// 생일이 지났는지 확인
		if (currentMonth < month || (currentMonth === month && currentDay < day)) {
			age -= 1; // 지나지 않았다면 한살을 빼줍니다.
		}

		return age;
	};

	const handleToggleLock = () => {
		if (rsdNumberSecretMode && !disabled) {
			showDialog(() => (
				<RsdReadingDialog menuId={currentMenuId} onSuccess={() => setRsdNumberSecretMode(false)} />
			));
		} else {
			setRsdNumberSecretMode(true);
		}
	};

	const isRdsInvalid = !rdsNumber || rdsNumber?.length !== 13;
	const isDisabled = disabled || isRdsInvalid;

	const renderRdsNumber = () => {
		if (isRdsInvalid) return <span />;

		const result = (
			<S.Text $isDisabled={disabled} $color={textColor}>
				{rsdNumberSecretMode
					? `${rdsNumber.substring(0, 6)}-${rdsNumber.substring(6, 7)}******`
					: `${rdsNumber.substring(0, 6)}-${rdsNumber.substring(6)} `}
				{` (만${calculateAgeFromResidentID(rdsNumber)}세)`}
			</S.Text>
		);

		return result;
	};

	return (
		<S.Container $isDisabled={isDisabled}>
			{renderRdsNumber()}
			<img
				src={rsdNumberSecretMode ? Assets.icon.removeRedEye : Assets.icon.disabledEye}
				alt='보기'
				style={isRdsInvalid ? { cursor: 'no-drop' } : { cursor: 'pointer' }}
				onClick={isRdsInvalid ? () => {} : handleToggleLock}
				role='presentation'
			/>
		</S.Container>
	);
}
