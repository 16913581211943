import React from 'react';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import MyCenterBasicInformationView from './MyCenterBasicInformationView';
import MyCenterBasicInformationForm from './MyCenterBasicInformationForm';

function MyCenterBasicInformationFormView(): React.ReactElement {
	const { isEdit } = useCenterInfo();

	return isEdit ? <MyCenterBasicInformationForm /> : <MyCenterBasicInformationView />;
}

export default MyCenterBasicInformationFormView;
