import CRInputLabel from 'components/base/CRInputLabel';
import React from 'react';
import * as S from './styles';

export default function AcquirementCancelHelp() {
	return (
		<S.FormItemContainer>
			<CRInputLabel label='도움말' type='left-sub' showOverflow betweenGap='2.4rem'>
				<S.HelpText>
					<S.HelpTextTitle>취득 취소 신고</S.HelpTextTitle>
					<S.FormItemContainer>
						<S.HelpTextLabel>
							1. 고용보험 이중취득으로 인하여 신고했던 보험 취득을 취소 처리하는 기능
						</S.HelpTextLabel>
						<S.HelpTextLabel>
							2. 기존 급여 지급 시 고용보험료 공제 했을 경우, 요양보호사에게 취소일 기준 전액 환급
							필요
						</S.HelpTextLabel>
						<S.HelpTextLabel>
							3. 기존 급여 지급 시 고용보험료 공제 했을 경우, 요양보호사에게 취소일 기준 전액 환급
							필요
						</S.HelpTextLabel>
						<S.HelpTextLabel>
							4. 건강보험의 경우, 취득일 기준 14일이 경과한 경우, 근로자 자필서명이 들어간 근로자
							확인서 별도로 제출 필요
						</S.HelpTextLabel>
					</S.FormItemContainer>
				</S.HelpText>
			</CRInputLabel>
		</S.FormItemContainer>
	);
}
