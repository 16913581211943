import React from 'react';

import Assets from 'assets';
import CRTableHeader from 'components/base/CRTableHeader';
import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import EmployeeCareerTable from 'components/domain/table/EmployeeCareerTable';
import { useEmployeePageCareerInfo } from 'lib/hook/react-query';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import AddCareerDialog from 'components/domain/dialog/AddCareerDialog';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';

export default function EmployeeCareerTab() {
	const { showDialog } = useDialog();
	const { currentEmployee } = useEmployeePage();
	const { data: employeeCareerInfo, isLoading } = useEmployeePageCareerInfo({
		employeeId: currentEmployee?.employeeId,
	});

	const handleClickAddCareer = () => {
		showDialog(({ hideDialog }) => (
			<AddCareerDialog hideDialog={hideDialog} currentEmployee={currentEmployee} />
		));
	};

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={{}}
				filters={[]}
				hideSearchButton
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddCareer}>
						경력 추가
					</CRButton.IconButton>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<EmployeeCareerTable items={employeeCareerInfo} currentEmployee={currentEmployee} />
				</S.TableContainer>
			)}
		</S.Container>
	);
}
