import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const SCHEDULE_SYNC_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '14rem',
		label: '요청 일시',
		sortKey: '요청 일시',
	},
	{
		width: '14rem',
		label: '완료 일시',
		sortKey: '완료 일시',
	},
	{
		width: '19rem',
		label: '기간',
		sortKey: '기간',
	},
	{
		width: '12rem',
		label: '요청자',
		sortKey: '요청자',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const SCHEDULE_SYNC_HISTORY_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '요청완료',
		color: 'gray',
	},
	{
		key: '진행중',
		color: 'yellow',
	},
	{
		key: '실패',
		color: 'red',
	},
	{
		key: '완료',
		color: 'green',
	},
	{
		key: '부분완료',
		color: 'blue',
	},
	{
		key: '초기화',
		color: 'red',
	},
];
