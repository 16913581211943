import { CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_PAY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '9.6rem',
		label: '일자',
	},
	{
		width: '12rem',
		label: '납부액',
		sortKey: '납부액',
	},
	{
		width: '8rem',
		label: '납부방식',
		sortKey: '납부방식',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];
