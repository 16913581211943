import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'lib/hook/auth/useAuth';

function PublicRouter({ children }: { children: JSX.Element }): React.ReactElement {
	const { isLoggedIn } = useAuth();
	const location = useLocation();

	if (isLoggedIn) {
		return <Navigate to='/' state={{ from: location }} />;
	}

	return children;
}

export default React.memo(PublicRouter);
