import React from 'react';

import { CreateScheduleStatus, ScheduleItem } from 'types/view/task';
import * as S from './styles';

interface Props {
	item: ScheduleItem;
	onClickRow?: (subTask: ScheduleItem) => void;
}

function CollapsibleRow({ item, onClickRow }: Props): React.ReactElement {
	const renderStatusComponent = (status: CreateScheduleStatus) => {
		if (status === CreateScheduleStatus.BEFORE_AUTOMATION) {
			return (
				<S.ModifiedStatusLabelContainer>
					<S.ModifiedStatusLabel>자동화 등록전</S.ModifiedStatusLabel>
				</S.ModifiedStatusLabelContainer>
			);
		}
		if (status === CreateScheduleStatus.DONE) {
			return (
				<S.DoneStatusLabelContainer>
					<S.DoneStatusLabel>등록 완료</S.DoneStatusLabel>
				</S.DoneStatusLabelContainer>
			);
		}
		if (status === CreateScheduleStatus.ERROR) {
			return (
				<S.EmptyStatusLabelContainer>
					<S.EmptyStatusLabel>오류</S.EmptyStatusLabel>
				</S.EmptyStatusLabelContainer>
			);
		}
		return (
			<S.EmptyStatusLabelContainer>
				<S.EmptyStatusLabel>빈일정</S.EmptyStatusLabel>
			</S.EmptyStatusLabelContainer>
		);
	};

	return (
		<S.TableBodyRow onClick={() => onClickRow?.(item)}>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }}>
				{`${item.name} (${item.userNumber})`}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>{item.serviceType}</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }}>
				{renderStatusComponent(item.status)}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>
				{/* {item.items.map((e) => (
					<S.ServiceDetail key={e}>{e}</S.ServiceDetail>
				))} */}
			</S.TableBodyColumnWithPadding>
		</S.TableBodyRow>
	);
}

export default CollapsibleRow;
