import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import { newPassword } from 'lib/util/validator';

import * as S from './styles';
import CRInputMessage from '../../../components/base/CRInputMessage';
import useAuth from '../../../lib/hook/auth/useAuth';
import RouterPath from '../../../common/router';
import { Toast } from '../../../components/base/CRToast';

interface NewPasswordForm {
	password: string;
	passwordConfirm: string;
}

function NewPasswordPage(): React.ReactElement {
	const navigate = useNavigate();
	const location = useLocation();
	const { control, handleSubmit, watch } = useForm<NewPasswordForm>({
		resolver: yupResolver(newPassword),
		mode: 'onSubmit',
	});
	const auth = useAuth();

	const onSubmit = async (data: NewPasswordForm) => {
		await auth.setNewPassword(data);
		navigate(location.state?.from ?? RouterPath.home());
		Toast.success('비밀번호가 정상적으로 변경 되었습니다.');
	};

	return (
		<S.Container>
			<S.Contents>
				<S.Logo src={Assets.img.logo} alt='caring' />
				<S.Text>
					<h3>비밀번호 변경</h3>
					<span>
						{'새로운 비밀번호를 설정해주세요.\n임시 비밀번호와 동일한 번호는 사용할 수 없습니다.'}
					</span>
				</S.Text>
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.InputContainer>
						<Controller
							render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
								<CRInputLabel
									label='비밀번호'
									message={
										errors.password?.message ? (
											<CRInputMessage type='error'>{errors.password?.message ?? ''}</CRInputMessage>
										) : (
											<CRInputMessage>8~16자 영문 대・소문자,숫자,특수문자 조합</CRInputMessage>
										)
									}
									isRequired>
									<CRInput.Password
										status={errors.password ? 'error' : 'default'}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										placeholder='비밀번호 입력'
										toggleSecret
									/>
								</CRInputLabel>
							)}
							name='password'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
								<CRInputLabel label='비밀번호 확인' isRequired>
									<CRInput.Password
										addOnBottom={errors.passwordConfirm?.message}
										status={errors.passwordConfirm ? 'error' : 'default'}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										placeholder='위와 동일한 비밀번호를 입력해주세요'
										toggleSecret
									/>
								</CRInputLabel>
							)}
							name='passwordConfirm'
							control={control}
						/>
					</S.InputContainer>
					<S.ButtonContainer>
						<CRButton.Default
							buttonType='submit'
							size='large'
							disabled={!(watch('password') && watch('passwordConfirm'))}
							fullSize>
							변경하기
						</CRButton.Default>
					</S.ButtonContainer>
				</S.Form>
			</S.Contents>
		</S.Container>
	);
}

export default NewPasswordPage;
