import React from 'react';

import CRTable from 'components/base/CRTable';
import { CenterAccountRecordSummarys } from 'types/api/centerAccount';
import { v4 } from 'uuid';
import { displayComma } from 'lib';
import { CENTER_ACCOUNT_RECORD_SUMMARY_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: CenterAccountRecordSummarys[];
}

function CenterAccountRecordSummaryTable({ items = [] }: Props): React.ReactElement {
	const renderCenterAccountAliasNm = (index: number) => (value: string) => {
		if (index === items.length - 1) {
			return '';
		}
		return value;
	};

	const renderCenterAccount = (index: number) => (value: string) => {
		if (index === items.length - 1) {
			return '총합';
		}
		return value;
	};
	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={CENTER_ACCOUNT_RECORD_SUMMARY_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items?.map((item, index) => (
						<CRTable.Row
							key={v4()}
							item={item}
							renderKeys={[
								'centerAccountNo',
								'centerAccountAliasNm',
								'depositAmt',
								'withdrawAmt',
								'balanceAmt',
								'',
							]}
							customRender={{
								depositAmt: displayComma,
								withdrawAmt: displayComma,
								balanceAmt: displayComma,
								centerAccountNo: renderCenterAccount(index),
								centerAccountAliasNm: renderCenterAccountAliasNm(index),
							}}
							customStyle={{
								centerAccountNo: {
									paddingLeft: '2.4rem',
									...(index === items.length - 1 ? { fontWeight: 'bold' } : {}),
								},
								depositAmt: { textAlign: 'right' },
								withdrawAmt: { textAlign: 'right' },
								balanceAmt: { textAlign: 'right' },
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default CenterAccountRecordSummaryTable;
