import React, { useEffect } from 'react';
import CRButton from 'components/base/CRButton';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import useDialog from 'lib/hook/util/useDialog';
import InsuranceHistoryDialog from 'components/domain/dialog/InsuranceHistoryDialog';
import HealthCheckupHistoryDialog from 'components/domain/dialog/HealthCheckupHistoryDialog';
import CRRsdNumber from 'components/base/CRRsdNumber';
import { EmployeeDTO, SaveEmployeeBaseInfoRequestDTO } from 'types/api/employee';
import { EmployeeBasicInfo } from 'lib';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { EmployeeBasicInfoFormViewType } from 'types/view/employee';
import { yupResolver } from '@hookform/resolvers/yup';
import CRInput from 'components/base/CRInput';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import { CheckOption } from 'components/base/Selections/type';
import { useMyAccountInfo, useUpdateEmployeeBaseInfo } from 'lib/hook/react-query';
import { loadApplication } from 'lib/service/util/loader';

import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import dayjs from 'dayjs';
import { Toast } from 'components/base/CRToast';
import CRInputMessage from 'components/base/CRInputMessage';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import { EmployeeInsuranceDTO } from 'types/api/insurance';
import { endpoint } from 'lib/service/Api/endpoint';
import InsuranceInfoColumn from 'components/ui/InsuranceInfoColumn/Index';
import { CRText } from 'components/base/CRText';
import * as S from './styles';

enum DocReceiveMethod {
	Kakaotalk = 'CMN180.10',
	Sms = 'CMN180.20',
}

interface Props {
	item?: EmployeeBasicInfoFormViewType;
	options: {
		workState: CheckOption[];
		duty: CheckOption[];
		day: CheckOption[];
		disabilityLevel: CheckOption[];
		healthCheckKind: CheckOption[];
		docReceiveMethod: CheckOption[];
	};
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

export default function EmployeeBasicInfoEdit({
	item,
	options,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { queryClient } = loadApplication();
	const { showDialog, hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const navigate = useNavigate();
	const { currentEmployee } = useEmployeePage();

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		clearErrors,
		setError,
		formState: { errors },
	} = useForm<EmployeeBasicInfoFormViewType>({
		resolver: yupResolver(EmployeeBasicInfo),
		mode: 'onSubmit',
		defaultValues: item,
	});

	const { mutate } = useUpdateEmployeeBaseInfo(async (client, returnData, variables) => {
		if (returnData?.employeeId) {
			const queryKey = [
				'getEmployees',
				{
					centerIds: myAccountInfo?.centerId ? [myAccountInfo?.centerId] : [],
				},
			];

			queryClient.setQueryData(queryKey, (data) => {
				const employeesCache = data as EmployeeDTO[];
				const newCache = employeesCache?.map((employee) => {
					if (employee?.employeeId === variables?.employeeId) {
						const newEmployeeData = {
							...employee,
							korMemberNm: variables?.korMemberNm,
							birthDt: variables?.birthDt,
							dutyNms: variables?.dutyCds
								?.map(
									(dutyCd) => options.duty.find((option) => option.value === dutyCd.dutyCd)?.label,
								)
								?.join(', '),
							mobilePhoneNo: variables?.mobilePhoneNo,
						};
						return newEmployeeData;
					}
					return employee;
				});

				return newCache;
			});

			await queryClient.invalidateQueries([
				endpoint.getEmployeeBaseInfo.key,
				{
					centerId: Number(myAccountInfo?.centerId),
					memberId: Number(currentEmployee?.memberId),
					employeeId: Number(currentEmployee?.employeeId),
				},
			]);

			await queryClient.invalidateQueries([
				endpoint.getEmployeeDetailInfo.key,
				{
					centerId: Number(myAccountInfo?.centerId),
					memberId: Number(currentEmployee?.memberId),
					employeeId: Number(currentEmployee?.employeeId),
				},
			]);

			hideDialog();
			onStartChangeSection?.();
			Toast.success('기본정보를 수정했습니다.');
		} else {
			onCancelChangeSection?.();
		}
	});

	const onSubmitFail = (errors: FieldErrors<EmployeeBasicInfoFormViewType>) => {
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요');
	};

	const onSubmit = async (item: EmployeeBasicInfoFormViewType) => {
		if (!currentEmployee) return;

		const param: SaveEmployeeBaseInfoRequestDTO = {
			employeeId: currentEmployee?.employeeId,
			centerId: currentEmployee.centerId,
			korMemberNm: item.korMemberNm,
			zipCode: item.zipCode,
			basAddr: item.basAddr,
			detailAddr: item.detailAddr,
			regionNm1: item.regionNm1,
			regionNm2: item.regionNm2,
			regionNm3: item.regionNm3,
			roadNm: item.roadNm,
			rsdnNo: item.rsdnNo,
			birthDt: item.birthDt,
			dutyCds: item.dutyCds?.map((dutyCd) => ({
				dutyCd: dutyCd.value,
			})),
			mobilePhoneNo: item.mobilePhoneNo,
			dementiaEduCompleteYn: item.dementiaEduCompleteYn[0]?.value,
			twoPhoneYn: item.twoPhoneYn[0]?.value,
			subMobilePhoneNo: item.subMobilePhoneNo,
			disabilityGradeCd: item.disabilityGradeCd,
			employeeSpecialDesc: item.employeeSpecialDesc,
			tagCallRequestYn: item.tagCallRequestYn[0]?.value,
			tagCallDayCds: item.tagCallDayCds?.map((tagCallDayCd) => ({
				tagCallDayCd: tagCallDayCd.value,
			})),
			addrLatitude: item.addrLatitude ?? 0,
			addrLongitude: item.addrLongitude ?? 0,
			docReceiveMethodCd: item.docReceiveMethodCd || DocReceiveMethod.Kakaotalk,
			monthPerWorkHourCnt: item.monthPerWorkHourCnt ? Number(item.monthPerWorkHourCnt) : 0,
			visitCareOffHourCnt: item.visitCareOffHourCnt ? Number(item.visitCareOffHourCnt) : 0,
			visitBathOffHourCnt: item.visitBathOffHourCnt ? Number(item.visitBathOffHourCnt) : 0,
			visitNursingOffHourCnt: item.visitNursingOffHourCnt ? Number(item.visitNursingOffHourCnt) : 0,
		};
		if (item?.joinDate) {
			param.joinDate = dayjs(item.joinDate).format('YYYY-MM-DD HH:mm:ss');
		}
		mutate(param);
	};

	const handleClickRow = (item: EmployeeInsuranceDTO) => () => {
		const popup = window.open(
			`${RouterPath.insurance().tab.key}/${currentEmployee?.employeeId}/history/detail/${
				item.insuranceRequestId
			}`,
		);
		const timer = setInterval(() => {
			if (popup && popup.closed) {
				clearInterval(timer);
				queryClient.invalidateQueries([
					endpoint.getEmployeeInsurance.key,
					{
						centerId: myAccountInfo?.centerId,
						employeeId: currentEmployee?.employeeId,
					},
				]);
			}
		}, 500);
	};

	const handleClickProtectionHistory = () => {
		showDialog(({ hideDialog }) => (
			<InsuranceHistoryDialog
				onClickRow={handleClickRow}
				currentEmployee={currentEmployee}
				hideDialog={hideDialog}
				onClickAddInsurance={() => {
					hideDialog();
					navigate(
						`${RouterPath.insurance().tab.key}/${currentEmployee?.employeeId}/history/request`,
					);
				}}
			/>
		));
	};

	const handleClickCheckupHistory = () => {
		showDialog(({ hideDialog }) => (
			<HealthCheckupHistoryDialog
				hideDialog={hideDialog}
				options={options}
				currentEmployee={currentEmployee}
			/>
		));
	};

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		setValue('basAddr', postcodeData.address);
		setValue('zipCode', postcodeData.zonecode);
		setValue('addrLatitude', postcodeData.latitude);
		setValue('addrLongitude', postcodeData.longitude);
		setValue('regionNm1', postcodeData.sido);
		setValue('regionNm2', postcodeData.sigungu);
		setValue('regionNm3', postcodeData.bname);
		setValue('roadNm', postcodeData.roadname);
	};

	const handleChangeDetailAddress = (detailAddress: string) => {
		setValue('detailAddr', detailAddress);
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='기본 정보에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmit, onSubmitFail)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	useEffect(() => {
		if (watch('twoPhoneYn')?.[0]?.value === false) {
			setValue('subMobilePhoneNo', '');
			clearErrors('subMobilePhoneNo');
		} else if (!watch('subMobilePhoneNo')?.length) {
			setError('subMobilePhoneNo', {
				message: '투폰 전화번호를 입력해주세요.',
			});
		}
	}, [watch('twoPhoneYn'), watch('subMobilePhoneNo')]);

	useEffect(() => {
		if (watch('subMobilePhoneNo')?.length) {
			setValue('twoPhoneYn', [
				{
					label: '있음',
					value: true,
				},
			]);
		}
	}, [watch('subMobilePhoneNo')]);

	useEffect(() => {
		if (watch('tagCallRequestYn')?.[0]?.value === false) {
			setValue('tagCallDayCds', []);
			clearErrors('tagCallDayCds');
		} else if (watch('tagCallDayCds')?.length === 0) {
			setError('tagCallDayCds', {
				message: '태그콜 요일을 선택해주세요.',
			});
		}
	}, [watch('tagCallRequestYn')]);

	return (
		<S.Container>
			<S.Form onSubmit={handleSubmit(onSubmit)}>
				<S.Label>
					기본 정보
					<S.ButtonContainer>
						<CRButton.Default
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={onSubmitHandler}>
							취소
						</CRButton.Default>
						<CRButton.Default
							size='xSmall'
							type='filled'
							palette='gray'
							onClick={handleSubmit(onSubmit, onSubmitFail)}>
							저장
						</CRButton.Default>
					</S.ButtonContainer>
				</S.Label>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn>
							이름 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										ref={ref}
										onBlur={onBlur}
										status={errors?.korMemberNm?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='직원명 입력'
										addOnBottom={errors.korMemberNm?.message}
									/>
								)}
								name='korMemberNm'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn
							rowSpan={2}
							style={{
								verticalAlign: 'top',
							}}>
							주소
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }} rowSpan={2}>
							<CRInput.Address
								onChangeBaseAddress={handleChangeBaseAddress}
								onChangeDetailAddress={handleChangeDetailAddress}
								baseAddress={watch('basAddr')}
								detailAddress={watch('detailAddr')}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>주민등록번호</S.TableLabelColumn>
						<S.TableLabelColumn>
							<CRRsdNumber rdsNumber={item?.rsdnNo} textColor='gray60' key={item?.rsdnNo} />
						</S.TableLabelColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							생년월일 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRInput.TableInput
											ref={ref}
											type='number'
											onBlur={onBlur}
											maxLength={8}
											status={errors?.birthDt?.message ? 'error' : 'default'}
											onChange={onChange}
											value={value}
											placeholder='예시) 19280301'
										/>
										{errors.birthDt && (
											<CRInputMessage type='error'>{errors.birthDt?.message ?? ''}</CRInputMessage>
										)}
									</>
								)}
								name='birthDt'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>
							직무 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { value, ref, onChange }, formState: { errors } }) => (
									<>
										<CRCheckBoxGroup
											ref={ref}
											checkType='multiple'
											onChange={onChange}
											type='checkbox'
											gap={0.8}
											value={value}
											options={options.duty}
										/>
										{errors.dutyCds && (
											<CRInputMessage type='error'>{errors.dutyCds?.message ?? ''}</CRInputMessage>
										)}
									</>
								)}
								name='dutyCds'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>입사일</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value } }) => (
									<CRInput.TableInput
										ref={ref}
										status={errors?.joinDate?.message ? 'error' : 'default'}
										type='number'
										onBlur={onBlur}
										maxLength={8}
										onChange={onChange}
										value={value}
										placeholder='입사일 입력'
									/>
								)}
								name='joinDate'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>
							월소정
							<br />
							근로시간
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										ref={ref}
										type='comma'
										onBlur={onBlur}
										maxLength={3}
										onChange={onChange}
										value={value}
										placeholder='월소정 근로시간 입력'
										suffix='시간'
									/>
								)}
								name='monthPerWorkHourCnt'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							전화번호 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRInput.TableInput
											ref={ref}
											type='number'
											onBlur={onBlur}
											status={errors?.mobilePhoneNo?.message ? 'error' : 'default'}
											onChange={onChange}
											value={value}
											maxLength={11}
											placeholder='전화번호 입력'
										/>
										{errors.mobilePhoneNo && (
											<CRInputMessage type='error'>
												{errors.mobilePhoneNo?.message ?? ''}
											</CRInputMessage>
										)}
									</>
								)}
								name='mobilePhoneNo'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>치매교육</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										onChange={onChange}
										value={value}
										options={[
											{
												label: '미수료',
												value: false,
											},
											{
												label: '수료',
												value: true,
											},
										]}
									/>
								)}
								name='dementiaEduCompleteYn'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>투폰/투번호</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, value, onChange }, formState: { errors } }) => (
									<CRCheckBoxGroup
										checkType='single'
										type='radio'
										gap={0.8}
										onChange={onChange}
										value={value}
										options={[
											{
												label: '없음',
												value: false,
											},
											{
												label: '있음',
												value: true,
											},
										]}
									/>
								)}
								name='twoPhoneYn'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn>장애여부</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => {
									const matchedItem = options.disabilityLevel.find(
										(option) => option.value === watch('disabilityGradeCd'),
									);
									const currentValue = matchedItem
										? {
												label:
													options.disabilityLevel.find(
														(option) => option.value === watch('disabilityGradeCd'),
													)?.label ?? '',
												value: options.disabilityLevel.find(
													(option) => option.value === watch('disabilityGradeCd'),
												)?.value,
										  }
										: undefined;
									return (
										<CRInput.Selector
											items={options.disabilityLevel}
											ref={ref}
											type='small'
											status={errors?.disabilityGradeCd?.message ? 'error' : 'default'}
											onChangeValue={(item) => onChange(item.value)}
											currentValue={currentValue}
											placeholder='장애코드 선택'
										/>
									);
								}}
								name='disabilityGradeCd'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>투폰 전화번호</S.TableLabelColumn>
						<S.TableValueColumn>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRInput.TableInput
											disabled={watch('twoPhoneYn')[0].value === false}
											ref={ref}
											type='number'
											onBlur={onBlur}
											maxLength={11}
											status={errors?.subMobilePhoneNo?.message ? 'error' : 'default'}
											onChange={onChange}
											value={value}
											placeholder='전화번호 입력'
										/>
										{errors.subMobilePhoneNo && (
											<CRInputMessage type='error'>
												{errors.subMobilePhoneNo?.message ?? ''}
											</CRInputMessage>
										)}
									</>
								)}
								name='subMobilePhoneNo'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn
							rowSpan={2}
							style={{
								verticalAlign: 'top',
							}}>
							비고
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }} rowSpan={2}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TextArea
										// style={{
										// 	height: '13.1rem',
										// }}
										typography='label'
										ref={ref}
										onBlur={onBlur}
										status={errors?.employeeSpecialDesc?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='비고 입력'
										numberOfLines={3}
									/>
								)}
								name='employeeSpecialDesc'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>태그콜</S.TableLabelColumn>
						<S.TableValueColumn style={{ verticalAlign: 'middle' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										value={value}
										onChange={onChange}
										options={[
											{
												label: '미진행',
												value: false,
											},
											{
												label: '진행',
												value: true,
											},
										]}
									/>
								)}
								name='tagCallRequestYn'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>태그콜 요일</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								verticalAlign: 'middle',
							}}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<>
										<CRCheckBoxGroup
											ref={ref}
											checkType='multiple'
											type='checkbox'
											gap={0.8}
											onChange={onChange}
											value={value}
											options={options.day.map((item) => ({
												...item,
												disabled: !watch('tagCallRequestYn')?.[0]?.value,
											}))}
										/>
										{errors.tagCallDayCds && (
											<CRInputMessage type='error'>
												{errors.tagCallDayCds?.message ?? ''}
											</CRInputMessage>
										)}
									</>
								)}
								name='tagCallDayCds'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '9.6rem' }}>서류 수신 방법</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
							<Controller
								render={({ field: { value, onChange }, formState: { errors } }) => (
									<CRInput.Selector
										items={options.docReceiveMethod}
										type='small'
										currentValue={
											options.docReceiveMethod.find((item) => item.value === value) ||
											options.docReceiveMethod.find(
												(item) => item.value === DocReceiveMethod.Kakaotalk,
											)
										}
										onChangeValue={(option: CheckOption) => onChange(option.value)}
										placeholder='서류 수신 방법 입력'
									/>
								)}
								name='docReceiveMethodCd'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>
							근로자의 날<br />
							수당시간
						</S.TableLabelColumn>
						<S.TableValueColumn>
							<S.OffHourCntContainer>
								<S.OffHourCntItem>
									<S.OffHourCntContent>
										<S.OffHourCntLabel>
											<CRText text='방문요양' color='gray10' typography='label' />
										</S.OffHourCntLabel>
										<S.OffHourCntValue>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInput.TableInput
														ref={ref}
														type='number'
														onBlur={onBlur}
														status={errors?.visitCareOffHourCnt?.message ? 'error' : 'default'}
														onChange={onChange}
														value={value}
														maxLength={4}
														placeholder='수당 시간 (분)'
														suffix='분'
													/>
												)}
												name='visitCareOffHourCnt'
												control={control}
											/>
										</S.OffHourCntValue>
									</S.OffHourCntContent>
									<S.OffHourErrorContainer>
										{errors.visitCareOffHourCnt && (
											<CRInputMessage type='error'>
												{errors.visitCareOffHourCnt?.message ?? ''}
											</CRInputMessage>
										)}
									</S.OffHourErrorContainer>
								</S.OffHourCntItem>
								<S.OffHourCntItem>
									<S.OffHourCntContent>
										<S.OffHourCntLabel>
											<CRText text='방문목욕' color='gray10' typography='label' />
										</S.OffHourCntLabel>
										<S.OffHourCntValue>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInput.TableInput
														ref={ref}
														type='number'
														onBlur={onBlur}
														status={errors?.visitBathOffHourCnt?.message ? 'error' : 'default'}
														onChange={onChange}
														value={value}
														maxLength={4}
														placeholder='수당 시간 (분)'
														suffix='분'
													/>
												)}
												name='visitBathOffHourCnt'
												control={control}
											/>
										</S.OffHourCntValue>
									</S.OffHourCntContent>
									<S.OffHourErrorContainer>
										{errors.visitBathOffHourCnt && (
											<CRInputMessage type='error'>
												{errors.visitBathOffHourCnt?.message ?? ''}
											</CRInputMessage>
										)}
									</S.OffHourErrorContainer>
								</S.OffHourCntItem>
								<S.OffHourCntItem>
									<S.OffHourCntContent>
										<S.OffHourCntLabel>
											<CRText text='방문간호' color='gray10' typography='label' />
										</S.OffHourCntLabel>
										<S.OffHourCntValue>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInput.TableInput
														ref={ref}
														type='number'
														onBlur={onBlur}
														status={errors?.visitNursingOffHourCnt?.message ? 'error' : 'default'}
														onChange={onChange}
														value={value}
														maxLength={4}
														placeholder='수당 시간 (분)'
														suffix='분'
													/>
												)}
												name='visitNursingOffHourCnt'
												control={control}
											/>
										</S.OffHourCntValue>
									</S.OffHourCntContent>
									<S.OffHourErrorContainer>
										{errors.visitNursingOffHourCnt && (
											<CRInputMessage type='error'>
												{errors.visitNursingOffHourCnt?.message ?? ''}
											</CRInputMessage>
										)}
									</S.OffHourErrorContainer>
								</S.OffHourCntItem>
							</S.OffHourCntContainer>
						</S.TableValueColumn>
						<S.TableLabelColumn colSpan={2} />
					</S.TableRow>
				</S.Table>
				<S.Table>
					<S.TableLabelColumn>건강 검진일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.healthCheckDate ? dayjs(item.healthCheckDate).format('YYYY.MM.DD') : '-'}
						<S.RightButtonContainer>
							<CRButton.Default
								size='xSmall'
								palette='gray'
								type='outlined'
								onClick={handleClickCheckupHistory}>
								변경/이력
							</CRButton.Default>
						</S.RightButtonContainer>
					</S.TableValueColumn>
					<S.TableLabelColumn>4대보험</S.TableLabelColumn>
					<S.InsuranceInfoTd>
						<InsuranceInfoColumn height='4.8rem' {...item} />
					</S.InsuranceInfoTd>
					<S.ButtonTd>
						<CRButton.Default
							size='xSmall'
							palette='gray'
							type='outlined'
							onClick={handleClickProtectionHistory}>
							변경/이력
						</CRButton.Default>
					</S.ButtonTd>
				</S.Table>
			</S.Form>
		</S.Container>
	);
}
