import { MonitoringDetailStatus, MonitoringItem, MonitoringStatus } from 'types/view/monitoring';

export const monitoringArgs = {
	placeholder: 'Place Holder',
	items: [
		{
			id: '1',
			recipient: '김민수 (300101)',
			EmployeeName: '김요보(620213)',
			serviceType: '방문요양',
			taskCount: 9,
			status: MonitoringStatus.Done,
			servicePeriod: '23.06 1주차',
			startDate: '2023.04.10',
			endDate: '2023.04.19',
			items: [
				{
					id: '1',
					endDate: '2022.04.09',
					type: '근무 시간 오류',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.SUCCESS_INSPECTION,
				},
				{
					id: '2',
					endDate: '2022.04.10',
					type: '태그 일부 누락',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
				{
					id: '3',
					endDate: '2022.04.010',
					type: '미태그',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.SUCCESS_SAVE,
				},
			],
		},
		{
			id: '2',
			recipient: '김지영 (300101)',
			EmployeeName: '정지영 (581108), 윤선영 (601111)',
			serviceType: '방문목욕',
			taskCount: 2,
			status: MonitoringStatus.InProgress,
			servicePeriod: '23.06 2주차',
			startDate: '2023.04.10',
			endDate: '2023.04.19',
			items: [
				{
					id: '1',
					endDate: '2022.04.09',
					type: '근무 시간 오류',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
				{
					id: '2',
					endDate: '2022.04.10',
					type: '태그 일부 누락',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
				{
					id: '3',
					endDate: '2022.04.010',
					type: '미태그',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
			],
		},
		{
			id: '3',
			recipient: '이지은 (300101)',
			EmployeeName: '성시경 (581108), 전태윤 (601111)',
			serviceType: '방문간호',
			taskCount: 2,
			status: MonitoringStatus.Pending,
			servicePeriod: '23.06 3주차',
			startDate: '2023.04.10',
			endDate: '2023.04.19',
			items: [
				{
					id: '1',
					endDate: '2022.04.09',
					type: '근무 시간 오류',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
				{
					id: '2',
					endDate: '2022.04.10',
					type: '태그 일부 누락',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
				{
					id: '3',
					endDate: '2022.04.010',
					type: '미태그',
					estimatedTime: '16:00~18:00',
					measuredTime: '16:17~18:08',
					record: '작성완료',
					status: MonitoringDetailStatus.NEED_INSPECTION,
				},
			],
		},
	] as MonitoringItem[],
	filters: [
		{
			key: 'serviceType',
			type: 'single',
			options: [
				{
					label: '방문요양',
					value: '1',
					key: 'serviceType',
				},
				{
					label: '방문목욕',
					value: '2',
					key: 'serviceType',
				},
				{
					label: '방문간호',
					value: '3',
					key: 'serviceType',
				},
			],
			placeholder: '서비스명',
		},
		{
			key: 'signStatus',
			type: 'single',
			options: [
				{
					label: '처리중',
					value: '1',
					key: 'signStatus',
				},
				{
					label: '처리완료',
					value: '2',
					key: 'signStatus',
				},
				{
					label: '미처리',
					value: '3',
					key: 'signStatus',
				},
			],
			placeholder: '진행상황',
		},
	],
};
