import React from 'react';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import CRButton from 'components/base/CRButton';
import { ResponseCode } from 'types/api';
import { useDeleteEmployeeSalary } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { Toast } from 'components/base/CRToast';
import dayjs from 'dayjs';
import CRSpinner from 'components/base/CRSpinner';
import { SalarysEmployeeSalarysContent } from 'types/api/salary';
import * as S from './styles';

interface Props {
	salaryData: SalarysEmployeeSalarysContent;
}

export function DeleteSalaryDetailDialog({ salaryData }: Props) {
	const { hideDialog, clearDialog } = useDialog();

	const { mutate: deleteSalary, isLoading: deleteSalaryLoading } = useDeleteEmployeeSalary(
		async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('임금 정보를 삭제하였습니다.');
				clearDialog();
				// hideDialog();
				client.invalidateQueries([endpoint.getSalarysEmployeeSalaryList.key]);
			} else {
				hideDialog();
				Toast.error('임금 정보 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const handleClickDelete = () => {
		deleteSalary({
			employeeSalaryId: salaryData.employeeSalaryId,
		});
	};

	const content = `${salaryData.employeeNm} 님의 ${dayjs(salaryData.salaryYm).format(
		'YYYY년 M월',
	)} 임금 정보를 삭제하시겠습니까?`;

	return (
		<CRDialog
			title='임금 정보 삭제'
			showCloseButton={false}
			body={<S.Container>{content}</S.Container>}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						style={{
							position: 'relative',
							minWidth: '7.6rem',
							height: '4rem',
							display: 'flex',
							gap: '0.8rem',
						}}
						palette='primary'
						type='outlined'
						size='default'
						disabled={deleteSalaryLoading}
						onClick={handleClickDelete}>
						<S.LoadingContainer>
							{deleteSalaryLoading ? <CRSpinner floatMode={false} /> : '삭제'}
						</S.LoadingContainer>
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
