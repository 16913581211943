import React from 'react';

import { FieldPath, useFormContext } from 'react-hook-form';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';
import { SalaryFeeCdKorKeys } from 'components/domain/dialog/SalaryDetailDialog/types';
import CRFormTableInput from 'components/base/CRFormInput/CRFormTableInput';
import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function MyCenterFinanceEtcFeeForm({ disabled }: Props): React.ReactElement {
	const { watch } = useFormContext<CenterFinanceDetailFormFields>();
	const salaryFee = watch('salaryFee');
	const makeInputName = (field: SalaryFeeCdKorKeys): FieldPath<CenterFinanceDetailFormFields> =>
		`salaryFee.${field}.salaryFeeValue`;

	const getAmt = (field: SalaryFeeCdKorKeys): string => salaryFee?.[`${field}`]?.salaryFeeValue;

	const renderFieldValue = (field: SalaryFeeCdKorKeys) =>
		disabled ? (
			getAmt(field)
		) : (
			<CRFormTableInput
				name={makeInputName(field)}
				disabled={disabled}
				maxLength={11}
				type='comma'
				suffix='원'
			/>
		);

	return (
		<S.Container>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn width='25%'>종일방문요양 12시간</S.TableLabelColumn>
					<S.TableValueColumn width='25%'>
						{renderFieldValue('기타_종일방문요양12시간')}
					</S.TableValueColumn>
					<S.TableLabelColumn width='25%'>종일방문요양 24시간</S.TableLabelColumn>
					<S.TableValueColumn width='25%'>
						{renderFieldValue('기타_종일방문요양24시간')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>복지수당</S.TableLabelColumn>
					<S.TableValueColumn>{renderFieldValue('기타_복지수당')}</S.TableValueColumn>
					<S.TableLabelColumn>연차</S.TableLabelColumn>
					<S.TableValueColumn>
						{disabled ? (
							getAmt('기타_연차')
						) : (
							<CRFormTableInput
								name={makeInputName('기타_연차')}
								disabled={disabled}
								maxLength={11}
								type='comma'
								suffix='일'
							/>
						)}
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default MyCenterFinanceEtcFeeForm;
