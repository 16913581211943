import React, { CSSProperties, MouseEvent } from 'react';

import * as S from './styles';
import { ButtonPalette, ButtonSize, ButtonType } from '../type';

export interface IButtonProps {
	style?: CSSProperties;
	palette?: ButtonPalette;
	buttonType?: 'submit' | 'button';
	type?: ButtonType;
	size?: ButtonSize;
	fullSize?: boolean;
	disabled?: boolean;
	onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
	children?: React.ReactNode;
	id?: string;
	form?: string;
}

function CRDefaultButton({
	style = {},
	children,
	palette = 'primary',
	buttonType = 'button',
	type = 'filled',
	size = 'default',
	disabled = false,
	fullSize = false,
	onClick,
	...res
}: React.PropsWithChildren<IButtonProps>) {
	return (
		<S.Container
			style={style}
			type={buttonType}
			$fullSize={fullSize}
			onClick={onClick}
			$palette={palette}
			$buttonType={type}
			$size={size}
			disabled={disabled}
			{...res}>
			{children}
		</S.Container>
	);
}

export default CRDefaultButton;
