import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SignInRequest, SignInResponse } from 'types/api/memberAcount';
import { endpoint } from './endpoint';

export default class ProductApi {
	protected client: AxiosInstance;

	constructor(config?: AxiosRequestConfig<any>) {
		this.client = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 5000,
			...config,
			// headers: {}
		});
	}

	async getExample(): Promise<void> {
		return;
	}

	async deleteExampleItem(): Promise<void> {
		return;
	}

	async updateExampleItem(): Promise<void> {
		return;
	}

	async signIn(param: SignInRequest) {
		return this.client.post<SignInRequest, AxiosResponse<SignInResponse>>(
			endpoint.signIn.path,
			param,
		);
	}
}
