import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';
import CRStatus from 'components/base/CRStatus';
import { OwnExpensePayHistory } from 'types/view/ownExpense';

import dayjs from 'dayjs';
import * as S from './styles';
import {
	OWN_EXPENSE_PAY_HISTORY_HEADER_CONFIG,
	OWN_EXPENSE_PAY_HISTORY_STATUS_CONFIG,
} from './constant';

interface Props<T extends { label: string; value: any }> {
	items?: OwnExpensePayHistory[];
	onClickItem?: (ownExpense: OwnExpensePayHistory) => void;
}

function OwnExpensePayHistoryTable<T extends { label: string; value: any }>({
	items = [],
	onClickItem,
}: Props<T>): React.ReactElement {
	const OwnExpenseBadge = useCallback(
		(children: string) => (
			<CRStatus options={OWN_EXPENSE_PAY_HISTORY_STATUS_CONFIG}>{children}</CRStatus>
		),
		[],
	);

	const renderBirthDt = useCallback((value: string) => dayjs(value).format('YYYY.MM.DD'), []);

	const renderPaidAmount = useCallback(
		(paidAmount: string, item?: OwnExpensePayHistory) => (
			<S.PaidAmountText $payMethod={item?.status}>{paidAmount}</S.PaidAmountText>
		),
		[],
	);

	return (
		<S.Container>
			<S.TableContainer>
				<CRTable.Root>
					<CRTable.Head heads={OWN_EXPENSE_PAY_HISTORY_HEADER_CONFIG} offset={1} />
					<CRTable.Body>
						{items.map((item) => (
							<CRTable.Row
								key={item.id}
								item={item}
								renderKeys={[
									'recipient',
									'birth',
									'amount',
									'paidAmount',
									'paymentMethod',
									'status',
									'receipt',
									'manager',
								]}
								customRender={{
									status: OwnExpenseBadge,
									paidAmount: renderPaidAmount,
									birth: renderBirthDt,
								}}
								customStyle={{
									amount: {
										textAlign: 'right',
									},
									paidAmount: {
										textAlign: 'right',
									},
								}}
								onClick={onClickItem}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			</S.TableContainer>
		</S.Container>
	);
}

export default OwnExpensePayHistoryTable;
