import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import * as Pop from '@radix-ui/react-popover';
import Assets from 'assets';
import * as S from './styles';

interface Props {
	title?: ReactNode;
	content: ReactNode;
	isOpen?: boolean;
	setOpen?: (isOpen: boolean) => void;
	openCallback?: () => void;
	closeCallback?: () => void;
	sideOffset?: number;
	alignOffset?: number;
	showDivider?: boolean;
	showBorder?: boolean;
	align?: 'center' | 'start' | 'end';
	side?: 'top' | 'left' | 'right' | 'bottom';
	containerStyle?: CSSProperties;
	borderShape?: 'none' | 'small' | 'large';
}

export default function RdPopover({
	title,
	children,
	content,
	isOpen,
	setOpen,
	openCallback,
	closeCallback,
	sideOffset = -240,
	alignOffset,
	showDivider = true,
	showBorder = false,
	align = 'center',
	side = 'left',
	borderShape = 'none',
	containerStyle = {},
}: React.PropsWithChildren<Props>) {
	const closeRef = useRef<HTMLButtonElement>(null);

	const onOpenChange = (isOpen: boolean) => {
		setOpen?.(isOpen);
		if (isOpen) {
			openCallback?.();
		} else {
			closeCallback?.();
			closeRef.current?.click();
		}
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.code === 'Escape') {
			closeRef.current?.click();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	return (
		<S.Container style={containerStyle}>
			<S.PopoverRoot onOpenChange={onOpenChange} open={isOpen}>
				<S.PopoverTrigger>{children}</S.PopoverTrigger>
				<Pop.Portal>
					<S.PopoverContent
						$showBorder={showBorder}
						$borderShape={borderShape}
						side={side}
						align={align}
						alignOffset={alignOffset}
						sideOffset={sideOffset}
						hideWhenDetached>
						{title && (
							<S.Header $showDivider={showDivider}>
								<S.HeaderTitle>{title}</S.HeaderTitle>
								<S.PopoverClose ref={closeRef}>
									<S.CloseIcon src={Assets.icon.close} alt='닫기' />
								</S.PopoverClose>
							</S.Header>
						)}
						{content}
					</S.PopoverContent>
				</Pop.Portal>
			</S.PopoverRoot>
		</S.Container>
	);
}
