import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	overflow: overlay;
`;

export const RecipientCustomContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;
