import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	box-sizing: border-box;
	gap: 0.8rem;
`;
