import styled from 'styled-components';
import * as TypographyObj from 'common/typography';
import * as colorObj from 'common/colors';

export const Typography = styled.span<{
	$typography: keyof typeof TypographyObj.default;
	$color: keyof typeof colorObj.default;
	$margin?: string;
	$display?: string;
	$padding?: string;
}>`
	${(p) => p.theme.typography[p.$typography]};
	color: ${(p) => p.theme.colors[p.$color]};
	margin: ${(p) => p.$margin};
	padding: ${(p) => p.$padding};
	display: ${(p) => p.$display};
	flex-shrink: 0;
	white-space: pre-line;
`;
