import React, { ReactNode } from 'react';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import { CRText } from 'components/base/CRText';
import * as S from './styles';

interface Props {
	children: ReactNode;
}
function EDocClientPanelBase({ children }: Props) {
	return (
		<S.Container>
			<FlexContainer direction='column' align='center' gap='1rem'>
				<CRIcon src={Assets.img.logo} width='9.5rem' />
				<CRText typography='h4' text='전자서명시스템' />
			</FlexContainer>
			{children}
		</S.Container>
	);
}

export default EDocClientPanelBase;
