import React from 'react';
import { Controller } from 'react-hook-form';

import { ContractChangeEmployeeInspectionCheckDTO } from 'types/dto';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import CRButton from 'components/base/CRButton';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';

import * as S from './styles';

function ChangeEmployeeInspectionBeforeApprovalForm(): React.ReactElement {
	const { form, disabled } = useContractChangeEmployeeTask();

	const handleClickInsuranceDirectly = () => {
		window.open(`insurance`, '_blank');
	};

	return (
		<S.Container>
			<Controller
				name='inspection.check'
				control={form?.control}
				render={({ field: { onChange, value }, formState: { errors } }) => {
					const isAllChecked = (
						[
							'salaryOfferPlanWriteYn',
							'pcorpScheduleRegistYn',
							'wardReportCompleteYn',
							'hopeiumFormWriteYn',
							'autotransUploadYn',
							'liabilityInsuranceRegistNeedYn',
							'insuranceRequestYn',
						] as (keyof ContractChangeEmployeeInspectionCheckDTO)[]
					).every((item) => value?.[item]);

					return (
						<>
							<CRCheckBox
								checked={isAllChecked}
								onChange={() => {
									onChange({
										...value,
										salaryOfferPlanWriteYn: !isAllChecked,
										pcorpScheduleRegistYn: !isAllChecked,
										wardReportCompleteYn: !isAllChecked,
										hopeiumFormWriteYn: !isAllChecked,
										autotransUploadYn: !isAllChecked,
										liabilityInsuranceRegistNeedYn: !isAllChecked,
										insuranceRequestYn: !isAllChecked,
									});
								}}
								disabled={disabled}>
								전체 점검 완료
							</CRCheckBox>
							<S.Divider />
							<CRCheckBox
								checked={value?.salaryOfferPlanWriteYn}
								onChange={() =>
									onChange({ ...value, salaryOfferPlanWriteYn: !value?.salaryOfferPlanWriteYn })
								}>
								급여제공계획서 작성
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.pcorpScheduleRegistYn}
								onChange={() =>
									onChange({ ...value, pcorpScheduleRegistYn: !value?.pcorpScheduleRegistYn })
								}
								disabled={disabled}>
								공단 (일정)
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.wardReportCompleteYn}
								onChange={() =>
									onChange({ ...value, wardReportCompleteYn: !value?.wardReportCompleteYn })
								}
								disabled={disabled}>
								시군구보고
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.hopeiumFormWriteYn}
								onChange={() =>
									onChange({ ...value, hopeiumFormWriteYn: !value?.hopeiumFormWriteYn })
								}
								disabled={disabled}>
								희망이음 공문 작성
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.liabilityInsuranceRegistNeedYn}
								onChange={() =>
									onChange({
										...value,
										liabilityInsuranceRegistNeedYn: !value?.liabilityInsuranceRegistNeedYn,
									})
								}
								disabled={disabled}>
								일반요양보호사 배상책임보험 등록
								<CRRequiredMark />
							</CRCheckBox>
							{form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10' && (
								<CRCheckBox
									checked={value?.autotransUploadYn}
									onChange={() => {
										onChange({
											...value,
											autotransUploadYn: !value?.autotransUploadYn,
										});
									}}
									disabled={disabled}>
									자동이체동의서 업로드
									<CRRequiredMark />
								</CRCheckBox>
							)}
							<CRCheckBox
								checked={value?.insuranceRequestYn}
								onChange={() => {
									onChange({
										...value,
										insuranceRequestYn: !value?.insuranceRequestYn,
									});
								}}
								disabled={disabled}>
								4대 보험 신청
								<CRRequiredMark />
								<S.IntegratedAssessmentDirectContainer>
									<CRButton.Default
										palette='gray'
										type='outlined'
										size='xSmall'
										onClick={handleClickInsuranceDirectly}>
										바로가기
									</CRButton.Default>
								</S.IntegratedAssessmentDirectContainer>
							</CRCheckBox>
						</>
					);
				}}
			/>
		</S.Container>
	);
}

export default ChangeEmployeeInspectionBeforeApprovalForm;
