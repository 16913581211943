import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Label = styled.div`
	${(props) => props.theme.typography.labelB};
	display: flex;
	justify-content: space-between;
	color: ${(props) => props.theme.colors.gray00};
`;

export const Table = styled.table<{ $isDisabled?: boolean }>`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray99};
			color: ${(props) => props.theme.colors.gray60}; ;
		`};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	width: 9.6rem;
	height: 4.9rem;
	padding: 0.8rem;
	box-sizing: border-box;
	color: ${(props) => props.theme.colors.gray60};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	max-width: 1rem;
	box-sizing: border-box;
	height: 4.9rem;
	padding: 0.8rem;
	word-break: break-all;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const TableLabel = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	align-items: center;
	gap: 0.8rem;
	color: ${(props) => props.theme.colors.gray00};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const PlainText = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
`;
