import React, { ReactElement } from 'react';

import { FilterType } from 'types/view/filter';
import { ScheduleItem } from 'types/view/task';
import CRTableHeader from 'components/base/CRTableHeader';

import * as S from './styles';
import CollapsibleRow from './CollapsibleRow';

export enum TaskProgressStatus {
	InProgress,
	Done,
	Pending,
}

interface Props<T extends { label: string; value: any }> {
	items: ScheduleItem[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	renderCustomFilter?: ReactElement;
	onChangeSearchValue?: (searchValue: string) => void;
	// onChangeFilter?: (currentKey: string, value: any) => void;
	onClickRow?: (schedule: ScheduleItem) => void;
}

function RegisterAutomationTable<T extends { label: string; value: any }>({
	items = [],
	currentFilter,
	setCurrentFilter,
	searchValue,
	renderCustomFilter,
	onChangeSearchValue,
	onClickRow,
}: Props<T>): React.ReactElement {
	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				renderCustomFilter={renderCustomFilter}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				stickyMode
			/>
			<S.Table>
				<S.TableHeader>
					<tr>
						<S.TableHeaderColumnWithPadding style={{ width: '20rem', textAlign: 'left' }}>
							수급자명
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding style={{ width: '16rem', textAlign: 'left' }}>
							서비스 종류
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding style={{ width: '16rem', textAlign: 'left' }}>
							상태
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding style={{ width: '40.6rem', textAlign: 'left' }} />
					</tr>
				</S.TableHeader>
				<S.TableBody>
					{items.map((item) => (
						<CollapsibleRow
							key={`${item.serviceType},${item.name}`}
							item={item}
							onClickRow={onClickRow}
						/>
					))}
				</S.TableBody>
			</S.Table>
		</S.Container>
	);
}

export default RegisterAutomationTable;
