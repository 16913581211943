import {
	EmployeeSalaryFeeStandard,
	SalaryItem,
	SalarysEmployeeSalarysContent,
} from 'types/api/salary';

export const salaryItemCdKor = {
	지급_기본급: '2010',
	지급_주휴수당: '2020',
	지급_식대: '2015',
	지급_연차수당: '2030',
	지급_고정야간수당: '2051',
	지급_고정휴일수당: '2052',
	지급_휴일수당: '2060',
	지급_야간근로수당: '2070',
	지급_연장근로수당: '2080',
	지급_복지수당: '2090',
	지급_종일방문요양임금_비과세: '2050',
	지급_특별추가수당: '2110',
	지급_특별추가수당_비과세: '2100',
	지급_요양보호사권익증진장려금: '2180',
	지급_간호특별수당: '2120',
	지급_중증수급자가산금: '1170',
	지급_중급수급자가산금_실수령: '2170',
	지급_장기근속장려금: '1130',
	지급_장기근속장려금_실수령: '2130',
	지급_방문간호간호사가산금: '1140',
	지급_방문간호간호사가산금_실수령: '2140',
	지급_원거리교통비가산금: '1150',
	지급_원거리교통비가산금_실수령: '2150',
	지급_방문간호인지가산금: '1160',
	지급_방문간호인지가산금_실수령: '2160',
	/// ///////////////////////////////////
	공제_국민연금: '3050',
	공제_건강보험: '3060',
	공제_장기요양보험: '3070',
	공제_고용보험실업급여: '3080',
	공제_소득세액: '3030',
	공제_지방소득세액: '3040',
	공제_본인부담금: '3010',
	공제_기타공제액: '3090',
	공제_연말정산소득세: '3020',
	공제_연말정산지방소득세: '3025',
	공제_국민연금정산: '3051',
	공제_건강보험정산: '3061',
	공제_장기요양보험정산: '3071',
	공제_고용보험정산: '3081',
	/// //////////////////////////////////////////////////
	회사부담_국민연금: '5010',
	회사부담_건강보험: '5020',
	회사부담_노인장기요양보험: '5030',
	회사부담_고용보험실업급여: '5040',
	회사부담_고용보험고용안정: '5050',
	회사부담_산재보험: '5060',
	회사부담_퇴직적립금: '5070',
} as const;

export type SalaryItemCdKorKeys = keyof typeof salaryItemCdKor;

export const salaryFeeCdKor = {
	/// /////////////////////////////
	// 방문요양 (일반) 임금 정보
	/// ///////////////////////////////
	요양_일반_일반1등급: '511001',
	요양_일반_일반2등급: '511002',
	요양_일반_일반3등급: '511003',
	요양_일반_일반4등급: '511004',
	요양_일반_일반5등급: '511005',
	/// ////////////////////////////////
	// 방문요양 (가족) 임금 정보
	/// ////////////////////////////////
	요양_가족_60시간미만_가족30분: '512030',
	요양_가족_60시간미만_가족60분: '512060',
	요양_가족_60시간미만_가족90분: '512090',
	요양_가족_60시간이상_가족30분: '512130',
	요양_가족_60시간이상_가족60분: '512160',
	요양_가족_60시간이상_가족90분: '512190',
	/// //////////////////////////////////
	// 방문목욕 임금 정보
	/// //////////////////////////////////
	목욕_60시간미만_가족40분: '522040',
	목욕_60시간미만_가족60분: '522060',
	목욕_60시간미만_일반40분: '521040',
	목욕_60시간미만_일반60분: '521060',
	목욕_60시간이상_가족40분: '522140',
	목욕_60시간이상_가족60분: '522160',
	목욕_60시간이상_일반40분: '521140',
	목욕_60시간이상_일반60분: '521160',
	목욕_60시간미만_가정내목욕40분: '524040',
	목욕_60시간미만_가정내목욕60분: '524060',
	목욕_60시간이상_가정내목욕40분: '524140',
	목욕_60시간이상_가정내목욕60분: '524160',
	목욕_60시간미만_차량내목욕40분: '523040',
	목욕_60시간미만_차량내목욕60분: '523060',
	목욕_60시간이상_차량내목욕40분: '523140',
	목욕_60시간이상_차량내목욕60분: '523160',

	/// //////////////////////////////////////
	// 방문간호 임금 정보
	/// //////////////////////////////////////
	간호_60시간미만_가족30분미만: '532000',
	간호_60시간미만_가족30분이상60분미만: '532030',
	간호_60시간미만_가족60분이상: '532060',
	간호_60시간미만_일반30분미만_간호사: '531000',
	간호_60시간미만_일반30분이상60분미만_간호사: '531030',
	간호_60시간미만_일반60분이상_간호사: '531060',
	간호_60시간미만_일반30분미만_간호조무사: '530000',
	간호_60시간미만_일반30분이상60분미만_간호조무사: '530030',
	간호_60시간미만_일반60분이상_간호조무사: '530060',
	간호_60시간이상_가족30분미만: '532100',
	간호_60시간이상_가족30분이상60분미만: '532130',
	간호_60시간이상_가족60분이상: '532160',
	간호_60시간이상_일반30분미만_간호사: '531100',
	간호_60시간이상_일반30분이상60분미만_간호사: '531130',
	간호_60시간이상_일반60분이상_간호사: '531160',
	간호_60시간이상_일반30분미만_간호조무사: '530100',
	간호_60시간이상_일반30분이상60분미만_간호조무사: '530130',
	간호_60시간이상_일반60분이상_간호조무사: '530160',
	/// //////////////////////////////////////
	// 기타 임금 정보
	/// //////////////////////////////////////
	기타_종일방문요양12시간: '519012',
	기타_복지수당: '600010',
	기타_종일방문요양24시간: '519024',
	기타_연차: '600020',
} as const;

export type SalaryFeeCdKorKeys = keyof typeof salaryFeeCdKor;

interface FormFiledSalaryItems extends Omit<SalaryItem, 'salaryItemAmt'> {
	salaryItemAmt: string;
}

export type SalaryDetailDialogFormFields = {
	salaryDetail: SalarysEmployeeSalarysContent;
	salaryItem: Record<SalaryItemCdKorKeys, FormFiledSalaryItems>;
	salaryFee: Record<SalaryFeeCdKorKeys, EmployeeSalaryFeeStandard>;
};
