import { AxiosError } from 'axios';

class ServerError extends Error {
	extraInfo: { [k: string]: any };

	constructor(error: AxiosError) {
		const { response } = error;
		const message = `[${response?.config?.method?.toUpperCase()}] ${response?.config.url}
${response?.status} | ${response?.statusText || JSON.stringify(response?.data || '')}`;

		super(message);

		const configData =
			typeof response?.config?.data === 'string'
				? JSON.parse(error?.config?.data || '')
				: error?.config?.data;

		const apiType = error?.config?.url?.split('/')?.[1] || 'unknown';
		this.extraInfo = {
			requestParam: response?.config.params,
			requestData: configData,
			responseData: response?.data,
		};
		this.name = `ServerError / ${apiType}`;
	}
}

export default ServerError;
