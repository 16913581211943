import React, {
	PropsWithChildren,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import useDialog from 'lib/hook/util/useDialog';
import useNavigation from 'lib/hook/util/useNavigation';
import useQuickSearch from 'lib/hook/util/useQuickSearch';
import { parseURLToBreadCrumbs } from 'lib/util';

import { useMyMenuInfo } from 'lib/hook/react-query';
import { myTabMenuAdapter } from 'lib/adapter/common';
import { IDialogContext } from '../Dialog';

import { INavigationContext } from '../Navigation';
import { IQuickSearchContext } from '../QuickSearch';

export enum LGNBType {
	FullScreen = 1,
	FitScreen = 2,
}

interface IGlobalLayoutContext extends IDialogContext, INavigationContext, IQuickSearchContext {
	breadCrumbs: { link: string; label: string }[];
	helpers: React.ReactElement[];
	lGNBType: LGNBType;
	setLGNBType: React.Dispatch<React.SetStateAction<LGNBType>>;
	newConsultingMode: boolean;
	setNewConsultingMode: React.Dispatch<React.SetStateAction<boolean>>;
	rsdNumberSecretMode: boolean;
	setRsdNumberSecretMode: React.Dispatch<React.SetStateAction<boolean>>;
	currentMenuId?: number;
	customBackHandler?: () => void;
	setCustomBackHandler: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}
export const GlobalLayoutContext = createContext<IGlobalLayoutContext | undefined>(undefined);

function GlobalLayoutProvider({ children }: PropsWithChildren): React.ReactElement {
	const location = useLocation();
	const [lGNBType, setLGNBType] = useState<LGNBType>(LGNBType.FitScreen);
	const [breadCrumbs, setBreadCrumbs] = useState<{ link: string; label: string }[]>([]);
	const [helpers] = useState<React.ReactElement[]>([]);
	const [newConsultingMode, setNewConsultingMode] = useState(false);
	const [rsdNumberSecretMode, setRsdNumberSecretMode] = useState(true);
	const [customBackHandler, setCustomBackHandler] = useState<(() => void) | undefined>();
	const { data: myMenuInfo } = useMyMenuInfo(myTabMenuAdapter);
	const [currentMenuId, setCurrentMenuId] = useState<number | undefined>(undefined);

	const quickSearchContext = useQuickSearch();
	const dialogContext = useDialog();
	const navigationContext = useNavigation();

	const lockNavigation = () => {
		navigationContext.setIsHeld(false);
		navigationContext.setIsLeftSideFolded(true);
		navigationContext.setBlockLeftHoverEvent(true);
	};

	const unLockNavigation = () => {
		navigationContext.setBlockLeftHoverEvent(false);
	};

	const generateBreadCrumbs = useCallback((path: string) => {
		const breadCrumbs = parseURLToBreadCrumbs(path);

		if (breadCrumbs.length > 1) {
			navigationContext.blockLeftHoverEvent = true;
			lockNavigation();
			return breadCrumbs;
		}

		unLockNavigation();
		return [];
	}, []);

	useEffect(() => {
		const breadCrumbs = generateBreadCrumbs(location.pathname);
		setBreadCrumbs(breadCrumbs);
		const matchedMenu = myMenuInfo?.origin
			?.filter((item) => item.routerUrl)
			.find((item) => location.pathname.startsWith(item.routerUrl));
		setCurrentMenuId(matchedMenu?.menuId);
	}, [location.pathname, myMenuInfo]);

	useEffect(() => {
		setRsdNumberSecretMode(true);
	}, [location.search]);

	const value = useMemo(
		() => ({
			...dialogContext,
			...navigationContext,
			...quickSearchContext,
			breadCrumbs,
			helpers,
			lGNBType,
			setLGNBType,
			newConsultingMode,
			setNewConsultingMode,
			rsdNumberSecretMode,
			setRsdNumberSecretMode,
			currentMenuId,
			customBackHandler,
			setCustomBackHandler,
		}),
		[
			dialogContext,
			navigationContext,
			breadCrumbs,
			lGNBType,
			setLGNBType,
			newConsultingMode,
			setNewConsultingMode,
			rsdNumberSecretMode,
			setRsdNumberSecretMode,
			currentMenuId,
			customBackHandler,
			setCustomBackHandler,
		],
	);

	return <GlobalLayoutContext.Provider value={value}>{children}</GlobalLayoutContext.Provider>;
}

export default GlobalLayoutProvider;
