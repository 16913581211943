import React, { ReactElement } from 'react';

import { ScheduleItem } from 'types/view/task';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import CRTableHeader from 'components/base/CRTableHeader';

import * as S from './styles';
import CollapsibleRow from './CollapsibleRow';

export enum TaskProgressStatus {
	InProgress,
	Done,
	Pending,
}

interface Props<T extends { label: string; value: any }> {
	items: ScheduleItem[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	renderCustomFilter?: ReactElement;
	checkStatus: string[];
	onToggleAllCheck: (checked: boolean) => void;
	onClickCheck?: (scheduleId: string) => void;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickRow?: (schedule: ScheduleItem) => void;
}

function ScheduleErrorEditTable<T extends { label: string; value: any }>({
	items = [],
	currentFilter,
	searchValue,
	setCurrentFilter,
	renderCustomFilter,
	checkStatus,
	onToggleAllCheck,
	onClickCheck,
	onChangeSearchValue,
	onClickRow,
}: Props<T>): React.ReactElement {
	const checkConfirm = (scheduleId: string) => !!checkStatus.find((e) => e === scheduleId);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				renderCustomFilter={renderCustomFilter}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				stickyMode
			/>
			<S.Table>
				<S.TableHeader>
					<tr>
						<S.TableHeaderColumnWithPadding style={{ width: '20rem', textAlign: 'left' }}>
							수급자명
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding style={{ width: '16rem', textAlign: 'left' }}>
							서비스 종류
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding style={{ width: '16rem', textAlign: 'left' }}>
							<CRCheckBox
								checked={checkStatus.length === items.length}
								onChange={(e) => onToggleAllCheck(e.target.checked)}>
								전체완료
							</CRCheckBox>
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding style={{ width: '40.6rem', textAlign: 'left' }} />
					</tr>
				</S.TableHeader>
				<S.TableBody>
					{items.map((item) => (
						<CollapsibleRow
							key={`${item.serviceType},${item.name}`}
							item={item}
							isChecked={checkConfirm(item.id)}
							onClickRow={onClickRow}
							onClickCheck={onClickCheck}
						/>
					))}
				</S.TableBody>
			</S.Table>
		</S.Container>
	);
}

export default ScheduleErrorEditTable;
