import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import InformationTable from 'components/ui/InformationTable';

import { useMyAccountInfo, useSendMessage, useSendScheduleDetail } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { SendScheduleDetailRequest } from 'types/api/schedule';
import CRDialog from 'components/base/CRDialog';
import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import { SendMessageRequest } from 'types/api/send';
import { Toast } from 'components/base/CRToast';
import * as S from './styles';

interface Props {
	type: 'employees' | 'recipients';
	id?: number;
	params: SendScheduleDetailRequest;
}

function ScheduleSendDetailDialog({ type, id, params }: Props): React.ReactElement {
	const { data: sendScheduleDetail } = useSendScheduleDetail({
		type,
		id,
		params,
	});

	const { hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { mutate: sendMessage } = useSendMessage((client, returnData) => {
		if (returnData?.sendId) {
			Toast.success(`${sendScheduleDetail?.messageTitleNm}를 발송하였습니다.`);
		} else {
			Toast.error('발송 실패하였습니다.');
		}
		hideDialog();
	});

	const handleSendScheduleMessage = () => {
		if (!id || !params.targetYm) return;
		const receivers: { employees: number[]; recipients: number[] } = {
			employees: [],
			recipients: [],
		};

		if (type === 'employees') {
			receivers.employees.push(Number(id));
		} else {
			receivers.recipients.push(Number(id));
		}
		const param: SendMessageRequest & { [key: string]: any } = {
			isSchedule: false,
			sendTemplateId: sendScheduleDetail?.sendTemplateId,
			centerId: myAccountInfo?.centerId,
			targetYm: dayjs(params.targetYm).format('YYYYMM'),
			messageTitleNm: sendScheduleDetail?.messageTitleNm || '',
			messageDesc: sendScheduleDetail?.messageDesc || '',
			receivers,
		};

		sendMessage(param);
	};

	return (
		<CRDialog
			title='발송 상세'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<S.BodyContainer>
						<CRInputLabel label='발송 정보'>
							<InformationTable
								items={[
									[
										{
											label: '템플릿명',
											value: sendScheduleDetail?.sendTemplateNm ?? '',
										},
									],
									[
										{
											label: '제목',
											value: sendScheduleDetail?.messageTitleNm ?? '',
										},
									],
									[
										{
											label: '수신자',
											value: sendScheduleDetail?.receiverNm ?? '',
										},
									],
								]}
							/>
						</CRInputLabel>
						<CRInputLabel label='발송 내용'>
							<CRInput.TextArea
								disabled
								numberOfLines={10}
								value={sendScheduleDetail?.messageDesc?.replace(/<br\/>|\\n/g, '\n')}
							/>
						</CRInputLabel>
					</S.BodyContainer>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleSendScheduleMessage}>
						발송
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(ScheduleSendDetailDialog);
