import {
	VisitCareRecordFormFields,
	VisitCareRecordSheetParam,
} from 'components/domain/form/EDocRecodSheetForm/types';

export type FormattedVisitCareRecordSheet = VisitCareRecordFormFields[];

/**
 *	방문요양 기록지는 한장에 7일의 기록을 할 수 있음.
 *	해당 함수로 기록지의 내용을 일자별로 쪼개서 1차원 배열로 반환
 */
export const formatVisitCareRecordSheetsFromEDocParams = (params: VisitCareRecordSheetParam[]) => {
	const calendarMatrix = Array.from({ length: 10 }).fill(
		Array.from({ length: 7 }).fill(null),
	) as FormattedVisitCareRecordSheet[];

	const recordSheets = calendarMatrix.map((week, sheetIndex) =>
		week.map((_, dayIndex) => {
			const currentParam = params[sheetIndex];
			if (!currentParam) return null;

			const targetSchedule = dayIndex + 1;
			const keyPrefix = 'schedule';
			const targetKey = `${keyPrefix}${targetSchedule}`;

			if (currentParam[`${targetKey}Dt` as keyof VisitCareRecordSheetParam] === '') {
				return null;
			}

			const matchedObject: any = {};

			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(currentParam)) {
				if (key.startsWith(targetKey)) {
					const targetKeyRemoved = key.replace(targetKey, '');
					matchedObject[targetKeyRemoved] = value;
				}
			}
			return {
				_schedulePrefix: targetKey,
				recipientNm: currentParam.recipientNm,
				centerNm: currentParam.centerNm,
				centerMark: currentParam.centerMark,
				recipientBirthDt: currentParam.recipientBirthDt,
				longTermNo: currentParam.longTermNo,
				longTermGradeNm: currentParam.longTermGradeNm,
				year: currentParam.year,
				familyYn: currentParam?.familyYn,
				pageNo: String(sheetIndex + 1),
				colNo: String(dayIndex + 1),
				...matchedObject,
			} as VisitCareRecordFormFields;
		}),
	);
	return recordSheets.flatMap((sheet) =>
		sheet.filter((val) => val !== null),
	) as FormattedVisitCareRecordSheet;
};
