import styled from 'styled-components';

export const Container = styled.form`
	padding: 2.4rem;
	box-sizing: border-box;
	min-width: 48rem;
	min-height: 50rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const ContentsContainer = styled.div``;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	margin-bottom: 1.6rem;
`;

export const HeaderTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 3.2rem;
	gap: 1.6rem;
`;

export const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 0.8rem;
`;

export const HeaderTitle = styled.div`
	flex: 1;
	${(props) => props.theme.typography.h3}
`;

export const CloseIcon = styled.img`
	cursor: pointer;
	width: 2.4rem;
	height: 2.4rem;
`;

export const HeaderDescription = styled.div`
	${(props) => props.theme.typography.body}
`;

export const CheckboxContainer = styled.div<{ $direction?: 'row' | 'column' }>`
	display: flex;
	flex-direction: ${(props) => props.$direction ?? 'row'};
	gap: 0.8rem;
`;
