import { IntegratedAssessmentDesireHousingEnvironmentQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireHousingEnvironmentQuestions: IntegratedAssessmentDesireHousingEnvironmentQuestion[] =
	[
		{
			key: '난방',
			label: '난방',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '불안정',
					value: '불안정',
				},
			],
		},
		{
			key: '환기',
			label: '환기',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '불량',
					value: '불량',
				},
			],
		},
		{
			key: '문턱',
			label: '문턱',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '불량(높음)',
					value: '불량(높음)',
				},
			],
		},
		{
			key: '실내계단',
			label: '계단',
			labelDetail: '실내',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '불량',
					value: '불량',
				},
			],
		},
		{
			key: '실외계단',
			label: '계단',
			labelDetail: '실외',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '불량',
					value: '불량',
				},
			],
		},
		{
			key: '화장실',
			label: '화장실',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '불량',
					value: '불량',
				},
			],
		},
		{
			key: '좌변기',
			label: '좌변기',
			required: ['generate'],
			answer: [
				{
					label: '있음',
					value: '있음',
				},
				{
					label: '없음',
					value: '없음',
				},
			],
		},
		{
			key: '온수',
			label: '온수여부',
			required: ['generate'],
			answer: [
				{
					label: '있음',
					value: '있음',
				},
				{
					label: '없음',
					value: '없음',
				},
			],
		},
		{
			key: '욕조여부',
			label: '욕조여부',
			required: ['generate'],
			answer: [
				{
					label: '있음',
					value: '있음',
				},
				{
					label: '없음',
					value: '없음',
				},
			],
		},
		{
			key: '세면대여부',
			label: '세면대여부',
			required: ['generate'],
			answer: [
				{
					label: '있음',
					value: '있음',
				},
				{
					label: '없음',
					value: '없음',
				},
			],
		},
		{
			key: '주방',
			label: '주방',
			required: ['generate'],
			answer: [
				{
					label: '있음',
					value: '있음',
				},
				{
					label: '없음',
					value: '없음',
				},
			],
		},
	];
