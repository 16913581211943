import CRFormAddressSearch from './CRFormAddressSearch';
import CRFormInput from './CRFormInputDefault';
import CRInputPassword from './CRInputPassword';
import CRFormButtonInput from './CRFormButtonInput';
import CRInputSearch from './CRInputSearch';
import CRTextArea from './CRFormTextArea';
import CRTimePicker from './CRTimePicker';
import CRTableFileUploader from './CRTableFileUploader';
import CRSelector from './CRSelector';
import CRFileUploader from './CRFileUploader';
import CRDateTimePicker from './CRDateTimePicker';
import CRSerialCode from './CRSerialCode';
import CRFileDownloader from './CRFileDownloader';
import CRDateRangePicker from './CRDateRangePicker';
import CRFormTableInput from './CRFormTableInput';
import CRDayTimePicker from './CRDayTimePicker';
import CRDatePicker from './CRDatePicker';
import CRSearchSelector from './CRSearchSelector';
import CRYearMonthPicker from './CRYearMonthPicker';
import CRTime from './CRTime';
import CRInputRectangle from './CRInputRectangle';
import CRMultiSelector from './CRMultiSelector';

export default {
	Default: CRFormInput,
	Address: CRFormAddressSearch,
	TableInput: CRFormTableInput,
	TextArea: CRTextArea,
	// Time: CRTime,
	// ButtonInput: CRButtonInput,
	// RectangleInput: CRInputRectangle,
	// Search: CRInputSearch,
	// SearchSelector: CRSearchSelector,
	// Selector: CRSelector,
	// MultiSelector: CRMultiSelector,
	// Password: CRInputPassword,
	// TimePicker: CRTimePicker,
	// TableFileUploader: CRTableFileUploader,
	// FileUploader: CRFileUploader,
	// FileDownloader: CRFileDownloader,
	// DatePicker: CRDatePicker,
	// DateTimePicker: CRDateTimePicker,
	// DayTimePicker: CRDayTimePicker,
	// DateRangePicker: CRDateRangePicker,
	// SerialCode: CRSerialCode,
	// YearMonthPicker: CRYearMonthPicker,
};
