import React from 'react';

import { CheckOption } from 'components/base/Selections/type';
import * as S from './styles';
import Highlighter from '../../../ui/Highlighter';

interface Props {
	searchText?: string;
	searchItems?: CheckOption[];
	visibleKey?: string[];
	onClickSearchItem?: (searchItem: CheckOption) => void;
}

function SearchDropDown({
	searchText,
	searchItems = [],
	onClickSearchItem,
	visibleKey = ['duty', 'birth', 'id', 'guardian'],
}: Props): React.ReactElement {
	const renderSearchItem = (searchItem: CheckOption) =>
		visibleKey
			.map((key) => searchItem.value?.[key])
			.filter(Boolean)
			.filter((val) => val)
			.join('・');
	return (
		<S.SearchDropDownContainer>
			<S.SearchResultTitle>검색 결과</S.SearchResultTitle>
			<S.SearchResultContainer>
				{searchItems.length > 0 ? (
					searchItems?.map((searchItem) => (
						<S.SearchResultItemOuterContainer>
							<S.SearchResultItemContainer
								key={searchItem.value?.id}
								onClick={(e) => {
									e.stopPropagation();
									onClickSearchItem?.(searchItem);
								}}>
								<S.SearchResultItemMainContainer>
									<Highlighter
										text={`${searchItem.value.name}`}
										targetText={searchText ?? ''}
										highlightStyle={{ color: 'red' }}
									/>
								</S.SearchResultItemMainContainer>
								<S.SearchResultItemSubContainer>
									<Highlighter
										text={renderSearchItem(searchItem)}
										targetText={searchText ?? ''}
										highlightStyle={{ color: 'red' }}
									/>
								</S.SearchResultItemSubContainer>
							</S.SearchResultItemContainer>
							{searchItem.children?.map((chidren) => (
								<S.SearchResultItemChildContainer
									key={chidren.value?.id}
									onClick={(e) => {
										e.stopPropagation();
										onClickSearchItem?.(chidren);
									}}>
									<S.Line />
									<S.SearchResultItemMainContainer>
										<Highlighter
											text={`${chidren.value.name}`}
											targetText={searchText ?? ''}
											highlightStyle={{ color: 'red' }}
										/>
									</S.SearchResultItemMainContainer>
									<S.SearchResultItemSubContainer>
										<Highlighter
											text={renderSearchItem(chidren)}
											targetText={searchText ?? ''}
											highlightStyle={{ color: 'red' }}
										/>
									</S.SearchResultItemSubContainer>
								</S.SearchResultItemChildContainer>
							))}
						</S.SearchResultItemOuterContainer>
					))
				) : (
					<S.SearchResultEmptyListContainer>
						<S.SearchResultEmptyListDescription>
							{searchText ? '검색결과가 없습니다.' : '검색어를 입력해주세요.'}
						</S.SearchResultEmptyListDescription>
					</S.SearchResultEmptyListContainer>
				)}
			</S.SearchResultContainer>
		</S.SearchDropDownContainer>
	);
}

export default SearchDropDown;
