import React, { ChangeEvent, useEffect } from 'react';
import CRSwitch from 'components/base/Selections/CRSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import CRInput from 'components/base/CRInput';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { ServiceKindCds } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import * as S from '../styles';
import { EDocFormRow, EDocFormTableRoot, EDocFormTableInvisibleHead } from '../EDocFormTable';
import { VisitCareRecordFormFields } from '../types';

interface Props {
	disabled?: boolean;
	serviceKindCd: ServiceKindCds;
	familyYn?: boolean;
}

export function VisitCareRecordForm({ disabled, serviceKindCd, familyYn }: Props) {
	const { control, setValue } = useFormContext<VisitCareRecordFormFields>();
	const toggleOtherCheckBoxes = (e: ChangeEvent<HTMLInputElement>, count = 3) => {
		const { name, checked } = e.target;
		if (!checked) return;
		const fieldKey = e.target.name.substring(0, name.length - 1);

		for (let i = 1; i <= count; i += 1) {
			const fieldName = `${fieldKey}${i}` as keyof VisitCareRecordFormFields;
			const isClickedFiled = fieldName === name;
			if (isClickedFiled) {
				setValue(fieldName, checked ? '1' : '0');
			} else {
				setValue(fieldName, checked ? '0' : '1');
			}
		}
	};

	const isRecogCare = serviceKindCd === ServiceKindCds.인지요양;

	return (
		<S.FormContainer>
			<S.SectionWrapper>
				<CRText typography='h3' color='red'>
					1) 서비스 제공
				</CRText>
				<S.Container1>
					<S.Container2>
						<S.labelWrapper>
							<CRIcon src={Assets.icon.assistWalker} alt='신체활동' />
							<CRText typography='h4'>신체활동지원</CRText>
						</S.labelWrapper>
						<EDocFormTableRoot>
							<CRTable.RowTitle
								textAlign='left'
								items={[
									{ width: '65%', children: '개인위생' },
									{ width: '35%', children: '' },
								]}
							/>
							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='개인위생' />
											<CRText
												typography='body'
												text=' (옷갈아입기, 세면,	구강청결, 몸단장 도움 등)'
											/>
										</>
									),
									inputs: (
										<Controller
											name='Chk1'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='몸 씻기 도움' />,
									inputs: (
										<Controller
											name='Chk2'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
						<EDocFormTableRoot>
							<CRTable.RowTitle
								textAlign='left'
								items={[
									{ width: '65%', children: '영양관리' },
									{ width: '35%', children: '' },
								]}
							/>
							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='식사 도움' />
											<CRText typography='body' text=' (영양관리 등)' />
										</>
									),
									inputs: (
										<Controller
											name='Chk3'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
						<EDocFormTableRoot>
							<CRTable.RowTitle
								textAlign='left'
								items={[
									{ width: '65%', children: '이동도움' },
									{ width: '35%', children: '' },
								]}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='체위변경' />,
									inputs: (
										<Controller
											name='Chk4'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='이동 도움' />,
									inputs: (
										<Controller
											name='Chk5'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='화장실 이용하기' />,
									inputs: (
										<Controller
											name='Chk6'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='제공 시간' />,
									inputs: (
										<Controller
											name='Minute1'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled || isRecogCare}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													max={999}
													type='number'
													typography='body'
													color='gray60'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>
					<S.Container2>
						<S.labelWrapper>
							<CRIcon src={Assets.icon.psychologyAlt} alt='인지활동' />
							<CRText typography='h4'>인지활동 지원</CRText>
						</S.labelWrapper>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='인지자극활동' />
											<CRText typography='body' text=' (자기소개, 어린시절 놀이, 계절이야기 등)' />
										</>
									),
									inputs: (
										<Controller
											name='Minute2'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled || !isRecogCare}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													min={familyYn ? 60 : 60}
													max={!familyYn ? 60 : 999}
													type='number'
													typography='body'
													color='gray60'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='일상생활 함께하기' />
											<CRText typography='body' text=' (함께 옷개기 등)' />
										</>
									),
									inputs: (
										<Controller
											name='Minute3'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled || !isRecogCare}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													min={!familyYn ? 60 : undefined}
													max={999}
													type='number'
													typography='body'
													color='gray60'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>

					<S.Container2>
						<S.labelWrapper>
							<CRIcon src={Assets.icon.howToReg} alt='인지관리' />
							<CRText typography='h4'>인지관리 지원</CRText>
						</S.labelWrapper>

						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='인지행동변화 관리 등' />
											<CRText typography='body' text=' (위험한 물건 제거, 안전한 환경 조성 등)' />
										</>
									),
									inputs: (
										<Controller
											name='Minute4'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled || isRecogCare}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													max={999}
													type='number'
													typography='body'
													color='gray60'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>

					<S.Container2>
						<S.labelWrapper>
							<CRIcon src={Assets.icon.volunteerActivism} alt='정서 지원' />
							<CRText typography='h4'>정서 지원</CRText>
						</S.labelWrapper>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='의사소통 도움 등' />
											<CRText typography='body' text=' (말벗, 편지쓰기, 보청기 사용 등)' />
										</>
									),
									inputs: (
										<Controller
											name='Minute5'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled || isRecogCare}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													max={60}
													type='number'
													typography='body'
													color='gray60'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>

					<S.Container2>
						<S.labelWrapper>
							<CRIcon src={Assets.icon.house} alt='가사 및 일상생활 지원' />
							<CRText typography='h4'>가사 및 일상생활 지원</CRText>
						</S.labelWrapper>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

							<EDocFormRow
								item={{
									description: (
										<CRText typography='bodyB' text='식사준비, 청소 및 주변정리 정돈, 세탁 등' />
									),
									inputs: (
										<Controller
											name='Chk7'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: (
										<>
											<CRText typography='bodyB' text='개인활동지원' />
											<CRText typography='body' text=' (외출시 동행 등)' />
										</>
									),
									inputs: (
										<Controller
											name='Chk8'
											control={control}
											render={({ field }) => (
												<CRSwitch
													{...field}
													disabled={disabled || isRecogCare}
													onChange={(e) => {
														field.onChange(e ? '1' : '0');
													}}
													value={field.value === '1'}
													type='icon'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
						<EDocFormTableRoot>
							<EDocFormTableInvisibleHead cols={[{ width: '65%' }, { width: '35%' }]} />

							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='제공시간' />,
									inputs: (
										<Controller
											name='Minute6'
											control={control}
											render={({ field }) => (
												<CRInput.RectangleInput
													{...field}
													disabled={disabled || isRecogCare}
													placeholder='0'
													suffix={<CRText typography='body' text='분' />}
													maxLength={3}
													textAlign='center'
													max={90}
													type='number'
													typography='body'
													color='gray60'
												/>
											)}
										/>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>
				</S.Container1>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<CRText typography='h3' color='red'>
					2) 변화상태
				</CRText>
				<EDocFormTableRoot>
					<EDocFormTableInvisibleHead cols={[{ width: '25%' }, { width: '75%' }]} />

					<EDocFormRow
						item={{
							description: <CRText typography='bodyB' text='신체기능' />,
							inputs: (
								<S.JustifyContainer>
									<Controller
										name='Chk9_1'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}
												checkBoxType='radio'>
												<CRText text='호전' />
											</CRCheckBox>
										)}
									/>
									<Controller
										name='Chk9_2'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												checkBoxType='radio'
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}>
												<CRText text='유지' />
											</CRCheckBox>
										)}
									/>
									<Controller
										name='Chk9_3'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												checkBoxType='radio'
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}>
												<CRText text='악화' />
											</CRCheckBox>
										)}
									/>
								</S.JustifyContainer>
							),
						}}
					/>
					<EDocFormRow
						item={{
							description: <CRText typography='bodyB' text='식사기능' />,
							inputs: (
								<S.JustifyContainer>
									<Controller
										name='Chk10_1'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}
												checkBoxType='radio'>
												<CRText text='호전' />
											</CRCheckBox>
										)}
									/>
									<Controller
										name='Chk10_2'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												checkBoxType='radio'
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}>
												<CRText text='유지' />
											</CRCheckBox>
										)}
									/>
									<Controller
										name='Chk10_3'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												checkBoxType='radio'
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}>
												<CRText text='악화' />
											</CRCheckBox>
										)}
									/>
								</S.JustifyContainer>
							),
						}}
					/>
					<EDocFormRow
						item={{
							description: <CRText typography='bodyB' text='인지기능' />,
							inputs: (
								<S.JustifyContainer>
									<Controller
										name='Chk11_1'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}
												checkBoxType='radio'>
												<CRText text='호전' />
											</CRCheckBox>
										)}
									/>
									<Controller
										name='Chk11_2'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												checkBoxType='radio'
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}>
												<CRText text='유지' />
											</CRCheckBox>
										)}
									/>
									<Controller
										name='Chk11_3'
										control={control}
										render={({ field }) => (
											<CRCheckBox
												{...field}
												disabled={disabled}
												checkBoxType='radio'
												onChange={toggleOtherCheckBoxes}
												checked={field.value === '1'}>
												<CRText text='악화' />
											</CRCheckBox>
										)}
									/>
								</S.JustifyContainer>
							),
						}}
					/>
					<EDocFormRow
						item={{
							description: <CRText typography='bodyB' text='배변변화' />,
							inputs: (
								<S.CountMistakeContainer>
									<Controller
										name='Mistake1'
										control={control}
										render={({ field }) => (
											<div>
												<CRText text='대변 실수 횟수' />
												<CRInput.RectangleInput
													{...field}
													disabled={disabled}
													placeholder='0'
													suffix={<CRText typography='body' text='회' />}
													maxLength={2}
													textAlign='center'
													max={99}
													type='number'
													typography='body'
													color='gray60'
												/>
											</div>
										)}
									/>
									<Controller
										name='Mistake2'
										control={control}
										render={({ field }) => (
											<div>
												<CRText text='소변 실수 횟수' />
												<CRInput.RectangleInput
													{...field}
													disabled={disabled}
													placeholder='0'
													suffix={<CRText typography='body' text='회' />}
													maxLength={2}
													textAlign='center'
													max={99}
													type='number'
													typography='body'
													color='gray60'
												/>
											</div>
										)}
									/>
								</S.CountMistakeContainer>
							),
						}}
					/>
				</EDocFormTableRoot>
			</S.SectionWrapper>
			<S.Container2>
				<CRText typography='h3' color='red'>
					3) 특이사항
				</CRText>
				<Controller
					name='SpecialContent'
					control={control}
					render={({ field }) => {
						const maxLength = 300;
						return (
							<CRInput.TextArea
								{...field}
								disabled={disabled}
								placeholder='특이사항을 작성해주세요'
								status={field?.value?.length > maxLength ? 'error' : 'default'}
								addOnBottom={`${maxLength}자까지 입력이 가능합니다.`}
								maxLength={maxLength}
							/>
						);
					}}
				/>
			</S.Container2>
		</S.FormContainer>
	);
}
