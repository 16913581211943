import React, { useState } from 'react';

import Assets from 'assets';

import * as S from './styles';
import ScrollSpySubSection from './ScrollSpySubSection';

interface IProps {
	currentKey?: string;
	indexLabel: string;
	section: {
		label: string;
		onClick?: () => void;
		subSections: {
			label: string;
			items: {
				key: string;
				label: string;
				onClick?: () => void;
			}[];
		}[];
	};
	customHeader?: (props?: { index?: string; label?: string; open?: boolean }) => React.ReactElement;
	hideHeader?: boolean;
	hideProgress?: boolean;
	hideCollapse?: boolean;
}

function ScrollSpySection({
	currentKey,
	section,
	indexLabel,
	customHeader,
	hideHeader,
	hideProgress,
	hideCollapse,
}: IProps): React.ReactElement {
	const [open, setOpen] = useState(true);

	const handleClickSectionLabel = () => {
		setOpen((prev) => !prev);
	};

	return (
		<S.Section>
			<S.SectionContentContainer>
				{!hideHeader &&
					(customHeader ? (
						customHeader({ index: indexLabel, label: section.label, open })
					) : (
						<S.ItemContainer>
							{!hideCollapse && (
								<S.SectionIndexLabel onClick={section.onClick}>{indexLabel}</S.SectionIndexLabel>
							)}
							<S.SectionLabelItem onClick={section.onClick}>{section.label}</S.SectionLabelItem>
							{!hideCollapse && (
								<S.DropDownIcon
									onClick={handleClickSectionLabel}
									src={open ? Assets.icon.arrowDropTop : Assets.icon.arrowDropBottom}
								/>
							)}
						</S.ItemContainer>
					))}
				<S.SectionContainer>
					{open &&
						section.subSections.map((subSection) => (
							<ScrollSpySubSection
								key={subSection.label}
								subSection={subSection}
								currentKey={currentKey}
								hideProgress={hideProgress}
							/>
						))}
				</S.SectionContainer>
			</S.SectionContentContainer>
		</S.Section>
	);
}

export default ScrollSpySection;
