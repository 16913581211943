import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from '../base/useCRQuery';

export const useSalarysEmployeeSalaryList = useCRQuery(
	endpoint.getSalarysEmployeeSalaryList.key,
	'getSalarysEmployeeSalaryList',
	{ enabledKey: ['centerId'] },
);

export const useSalarysEmployeeSalaryDetail = useCRQuery(
	endpoint.getSalarysEmployeeSalaryDetail.key,
	'getSalarysEmployeeSalaryDetail',
);

export const useCenterSalaryFeeInfo = useCRQuery(
	endpoint.getCenterSalaryFeeInfo.key,
	'getCenterSalaryFeeInfoDTO',
	{ enabledKey: ['centerIds', 'page', 'size'] },
);
