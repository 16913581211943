import styled from 'styled-components';

export const Container = styled.form`
	padding: 0 2.4rem;
	box-sizing: border-box;
	width: 48rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const ContentsContainer = styled.div``;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	margin-bottom: 1.6rem;
`;

export const HeaderTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 3.2rem;
	gap: 1.6rem;
`;

export const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 0.8rem;
`;

export const HeaderTitle = styled.div`
	flex: 1;
	${(props) => props.theme.typography.h3}
`;

export const CloseIcon = styled.img`
	cursor: pointer;
	width: 2.4rem;
	height: 2.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
export const LoadingContainer = styled.div`
	position: relative;
	display: flex;
	gap: 0.8rem;
	align-items: center;
	justify-content: center;
`;
