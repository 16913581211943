import Assets from 'assets';
import styled from 'styled-components';

const Button = styled.button`
	${(props) => props.theme.typography.body};
	height: 4rem;
	padding: 0 2.4rem;
	border: none;
	border-radius: 0.8rem;
	outline: none;
	cursor: pointer;
	user-select: none;
`;

export const Container = styled.form`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const MenuContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	/* height: 8rem; */
	box-sizing: border-box;
	/* padding: 0 2.4rem; */
`;

export const Title = styled.div`
	${(props) => props.theme.typography.bodyB}
`;

export const MenuRightSection = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const DraftsTimeMessage = styled.span`
	color: ${(props) => props.theme.colors.gray60};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const DraftButton = styled(Button)`
	color: ${(props) => props.theme.colors.gray10};
	background: ${(props) => props.theme.colors.gray100};
`;
export const SendButton = styled(Button)`
	color: ${(props) => props.theme.colors.gray100};
	background: ${(props) => props.theme.colors.primary60};
`;

export const ContentsContainer = styled.div`
	flex: 1;
	display: flex;
	gap: 1.6rem;
	padding: 2.4rem;
	overflow-y: hidden;
`;

export const WriteFormSection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	height: 100%;
	background: ${(props) => props.theme.colors.gray100};
	border-radius: 1.6rem;
	gap: 0.8rem;
	box-sizing: border-box;
	width: 100%;
`;

export const FormItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 8.5rem;

	& + & {
		margin-top: 0.8rem;
	}
`;

export const FormLabel = styled.span`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.4rem;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const SearchIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const CRInput = styled.input`
	${(props) => props.theme.typography.body}
	width: 100%;
	height: 100%;
	border: none;
	outline: none;

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const TextAreaContainer = styled.div`
	flex: 1;
	padding: 1.6rem;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
`;

export const TextArea = styled.textarea`
	${(props) => props.theme.typography.body}
	padding: 0;
	width: 100%;
	height: 100%;
	resize: none;
	border: none;
	outline: none;
	color: ${(props) => props.theme.colors.gray10};

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const UserSelector = styled.div`
	padding: 1.6rem;
	height: calc(100% - 12.4rem);
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const UserSelectInfo = styled.div`
	display: flex;
	justify-content: space-between;
	& > span {
		${(props) => props.theme.typography.body}
		color: ${(props) => props.theme.colors.gray60}
	}
`;

export const UserList = styled.ul`
	overflow-y: auto;
	height: calc(100% - 3.8rem);
`;

export const DeleteIcon = styled.img`
	display: none;
	width: 2.4rem;
	height: 2.4rem;
	user-select: none;
`;

export const UserListItem = styled.li<{ $isSelected: boolean }>`
	${(props) => props.theme.typography.body};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.8rem 1.6rem;
	border-radius: 0.8rem;
	color: ${(props) => props.theme.colors.gray00};
	margin-top: 0.8rem;

	/* background: ${(props) => (props.$isSelected ? props.theme.colors.gray100 : 'none')}; */

	&:hover {
		background: ${(props) => props.theme.colors.gray100};
	}

	&:hover > ${DeleteIcon} {
		display: flex;
	}

	cursor: pointer;
`;

export const FormSection = styled.div`
	display: flex;
	gap: 1.6rem;
	flex: 1;
	/* width: 78.8rem; */
`;

export const FormLeftSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	flex: 1;
	overflow-y: auto;
	height: calc(100vh - 30rem);
	box-sizing: border-box;
	padding-bottom: 2.4rem;
`;

export const TargetEmpty = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	text-align: center;
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
`;

export const FormRightSection = styled.div`
	height: calc(100vh - 30rem);
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	flex: 1;
`;

export const MockUpSection = styled.div`
	display: flex;
	flex: 1;
	min-width: 78.8rem;
	/* max-width: 78.8rem; */
	height: 100%;
	padding: 2.4rem 0;
	justify-content: center;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray95};
	border-radius: 1.6rem;
	overflow: auto;
`;

export const MockUp = styled.div`
	width: 35rem;
	height: 70rem;
	padding: 7rem 2.1rem 0;
	box-sizing: border-box;
	background: url(${Assets.img.sendMobile}) no-repeat;
	background-size: contain;
	background-position: center;
`;

export const ScrollContainer = styled.div`
	width: 100%;
	height: 53.4rem;
	overflow-y: scroll;
	box-sizing: border-box;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const CaringIcon = styled.img`
	width: 4.8rem;
	height: 4.8rem;
`;

export const MessageContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const MessageRightSection = styled.div``;

export const UserName = styled.p`
	${(props) => props.theme.typography.label};
`;

export const Message = styled.div`
	display: flex;
	margin-top: 0.4rem;
	align-items: flex-end;
	gap: 0.8rem;
	margin-bottom: 3rem;
`;

export const MessageBody = styled.p`
	${(props) => props.theme.typography.label};
	max-width: 19.2rem;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};
	padding: 1.6rem;
	border-radius: 1.35rem;
	color: ${(props) => props.theme.colors.gray50};
	white-space: pre-line;
`;

export const SendTime = styled.span`
	${(props) => props.theme.typography.label};
	font-size: 1.2rem;
	white-space: nowrap;
	color: #1d3c6d;
`;

export const BottomMenuContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	height: 7.2rem;
	padding: 0 2.4rem;
	box-sizing: border-box;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const InputContainer = styled.div`
	padding: 1.6rem 1.6rem 0.8rem 1.6rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
`;

export const InputDescription = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const TargetContainer = styled.li`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const TargetName = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
`;

export const TargetDescription = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;
