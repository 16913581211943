import React from 'react';
import * as S from './styles';

function RdAccordionItem(
	{ children, style, ...rest }: React.PropsWithChildren<{ style?: React.CSSProperties }>,
	ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
	return (
		<S.Container ref={ref} style={style} {...rest}>
			{children}
		</S.Container>
	);
}

export default React.forwardRef(RdAccordionItem);
