import { IntegratedAssessmentDesireBasicStatusQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireBasicStatusQuestions: IntegratedAssessmentDesireBasicStatusQuestion[] =
	[
		{
			key: '영양상태',
			label: '영양상태',
			type: 'radio',
			required: ['generate'],
			answer: [
				{
					label: '양호: 건강 및 섭식, 영양 등에 문제가 없는 상태',
					value: '양호',
				},
				{
					label: '불량: 건강, 섭식, 영양 등에 문제가 있어 세심한 관찰이 요구',
					value: '불량',
				},
				{
					label: '심한 불량: 극도의 건강, 섭식, 영양 등에 문제가 있어 치료적 처치가 필요한 상태',
					value: '심한 불량',
				},
			],
		},
		{
			key: '식사형태',
			label: '식사형태',
			type: 'checkbox',
			required: ['generate'],
			answer: [
				{
					label: '일반식',
					value: '일반식',
				},
				{
					label: '다진식',
					value: '다진식',
				},
				{
					label: '죽',
					value: '죽',
				},
				{
					label: '미음',
					value: '미음',
				},
				{
					label: '경관식',
					value: '경관식',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '소화상태',
			label: '소화상태',
			type: 'checkbox',
			answer: [
				{
					label: '저작곤란',
					value: '저작곤란',
				},
				{
					label: '소화불량',
					value: '소화불량',
				},
				{
					label: '오심/구토',
					value: '오심/구토',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '연하상태',
			label: '연하상태',
			type: 'radio',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '가끔 사레 걸림',
					value: '가끔사레걸림',
				},
				{
					label: '자주 사레 걸림',
					value: '자주사레걸림',
				},
				{
					label: '연하곤란',
					value: '연하곤란',
				},
			],
		},
		{
			key: '구강상태',
			label: '구강상태',
			type: 'radio',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '청결불량',
					value: '청결불량',
				},
				{
					label: '치아약함',
					value: '치아약함',
				},
				{
					label: '틀니',
					value: '틀니',
				},
				{
					label: '잔존치아 없음',
					value: '잔존치아 없음',
				},
			],
		},
		{
			key: '소변상태',
			label: '소변상태',
			type: 'checkbox',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '요실금',
					value: '요실금',
				},
				{
					label: '배뇨곤란',
					value: '배뇨곤란',
				},
				{
					label: '기저귀',
					value: '기저귀',
				},
				{
					label: '유치도뇨/방관루',
					value: '유치도뇨/방관루',
				},
			],
		},
		{
			key: '대변상태',
			label: '대변상태',
			type: 'checkbox',
			required: ['generate'],
			answer: [
				{
					label: '양호',
					value: '양호',
				},
				{
					label: '지속적인 설사',
					value: '지속적인 설사',
				},
				{
					label: '변비',
					value: '변비',
				},
				{
					label: '기저귀',
					value: '기저귀',
				},
				{
					label: '장루',
					value: '장루',
				},
			],
		},
		{
			key: '보행',
			label: '보행',
			type: 'radio',
			required: ['generate'],
			answer: [
				{
					label: '단독보행',
					value: '단독보행',
				},
				{
					label: '보조기 사용',
					value: '단독보행보조기사용',
					options: [
						{
							label: '지팡이',
							value: '지팡이',
						},
						{
							label: '보행기',
							value: '보행기',
						},
						{
							label: '휠체어',
							value: '휠체어',
						},
					],
				},
				{
					label: '부축도움',
					value: '부축도움',
				},
				{
					label: '보행불가능',
					value: '보행불가능',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '치매',
			label: '치매',
			type: 'radio',
			answer: [
				{
					label: '기억력 저하',
					value: '기억력 저하',
				},
				{
					label: '기억력 저하와 행동변화 증상',
					value: '기억력 저하와 행동변화 증상',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '치매증상',
			label: '치매증상\n(행동변화)',
			type: 'checkbox',
			answer: [
				{
					label: '배회',
					value: '배회',
				},
				{
					label: '야간수면장애',
					value: '야간수면장애',
				},
				{
					label: '망각 ・ 환각',
					value: '망각 ・ 환각',
				},
				{
					label: '폭력성',
					value: '폭력성',
				},
				{
					label: '우울 ・ 불안',
					value: '우울 ・ 불안',
				},
				{
					label: '거부',
					value: '거부',
				},
				{
					label: '성적행동',
					value: '성적행동',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '시력상태',
			label: '시력상태',
			type: 'radio',
			required: ['generate'],
			answer: [
				{
					label: '정상(안경 사용 포함)',
					value: '정상(안경 사용 포함)',
				},
				{
					label: '1미터 정도 떨어진 글씨는 읽을 수 있다',
					value: '1미터 정도 떨어진 글씨는 읽을 수 있다',
				},
				{
					label: '눈 앞에 근접한 글씨만 읽을 수 있다',
					value: '눈 앞에 근접한 글씨만 읽을 수 있다',
				},
				{
					label: '거의 보이지 않는다',
					value: '거의 보이지 않는다',
				},
				{
					label: '보이는지 판단 불능',
					value: '보이는지 판단 불능',
				},
			],
		},
		{
			key: '청력상태',
			label: '청력상태',
			type: 'radio',
			required: ['generate'],
			answer: [
				{
					label: '정상(보청기 사용 포함)',
					value: '정상(보청기 사용 포함)',
				},
				{
					label: '가까운 곳에서 대화는 가능하나 먼곳의 말소리는 듣지 못한다',
					value: '가까운 곳에서 대화는 가능하나 먼곳의 말소리는 듣지 못한다',
				},
				{
					label: '큰소리만 들을 수 있다',
					value: '큰소리만 들을 수 있다',
				},
				{
					label: '소리에 거의 반응이 없다',
					value: '소리에 거의 반응이 없다',
				},
				{
					label: '들리는지 판단 불능',
					value: '들리는지 판단 불능',
				},
			],
		},
	];
