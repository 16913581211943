import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	gap: 2rem;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const SectionTitle = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10}
`;

export const HorizontalScrollContainer = styled.div`
	width: 100%;
	overflow-x: overlay;
	max-height: 20rem;
`;

export const HorizontalScrollContentContainer = styled.div`
	width: 92rem;
`;

export const HeaderButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	& > div {
		display: flex;
		gap: 0.8rem;
	}
	margin-top: 0.6rem;
	margin-bottom: 0.2rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const MonthDate = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;
