import { LongTermSalary } from 'types/view/billing';

export const LONG_TERM_SALARY_LIST_MOCK: LongTermSalary[] = [
	{
		date: '03.01',
		service: '방문 요양(가족 90)',
		scheduleTime: '09:00~10:30',
		rfidSendingDetail: '09:00~10:30',
		subtractionRate: '0%',
		employee: '김나리, 김하나',
		type: '자동',
		amount: '31,650원',
		reportStatus: '',
		reportNotify: '감기기운이 있으셔서 따듯한 죽을 해드리니, 잘 드셨습니다.',
		isReportEdited: 'Y',
	},
	{
		date: '03.01',
		service: '방문 요양(가족 90)',
		scheduleTime: '09:00~10:30',
		rfidSendingDetail: '09:00~10:30',
		subtractionRate: '0%',
		employee: '김나리, 김하나',
		type: '자동',
		amount: '31,650원',
		reportStatus: '서명전',
		reportNotify: '',
		isReportEdited: 'Y',
	},
	{
		date: '03.01',
		service: '방문 요양(가족 90)',
		scheduleTime: '09:00~10:30',
		rfidSendingDetail: '09:00~10:30',
		subtractionRate: '0%',
		employee: '김나리, 김하나',
		type: '자동',
		amount: '31,650원',
		reportStatus: '서명완료',
		reportNotify: '',
		isReportEdited: 'Y',
		isDisabled: true,
	},
	{
		date: '03.01',
		service: '방문 요양(가족 90)',
		scheduleTime: '09:00~10:30',
		rfidSendingDetail: '09:00~10:30',
		subtractionRate: '0%',
		employee: '김나리, 김하나',
		type: '자동',
		amount: '31,650원',
		reportStatus: '서명전',
		reportNotify: '',
		isReportEdited: 'Y',
	},
	{
		date: '03.01',
		service: '방문 요양(가족 90)',
		scheduleTime: '09:00~10:30',
		rfidSendingDetail: '09:00~10:30',
		subtractionRate: '0%',
		employee: '김나리, 김하나',
		type: '자동',
		amount: '31,650원',
		reportStatus: '서명전',
		reportNotify: '',
		isReportEdited: 'Y',
	},
	{
		date: '03.01',
		service: '방문 요양(가족 90)',
		scheduleTime: '09:00~10:30',
		rfidSendingDetail: '09:00~10:30',
		subtractionRate: '0%',
		employee: '김나리, 김하나',
		type: '자동',
		amount: '31,650원',
		reportStatus: '서명전',
		reportNotify: '',
		isReportEdited: 'Y',
	},
];
