import React, {
	ChangeEvent,
	ClipboardEvent,
	FocusEvent,
	FocusEventHandler,
	ForwardedRef,
	HTMLInputTypeAttribute,
	KeyboardEvent,
	KeyboardEventHandler,
	forwardRef,
	useState,
} from 'react';

import { displayComma } from 'lib';
import * as S from './styles';

interface IProps {
	type?: HTMLInputTypeAttribute | 'comma';
	value?: string;
	id?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	onChange?: (...event: any[]) => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
	formatter?: (e: ChangeEvent<HTMLInputElement>) => string;
	prefix?: string;
	suffix?: React.ReactNode;
}

function CRTableInput(
	{
		type = 'text',
		value,
		id,
		status = 'default',
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		placeholder = '',
		onChange,
		onBlur,
		onPressEnter,
		formatter,
		prefix,
		suffix,
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const [isFocused, setIsFocused] = useState(false);
	const onFocus = () => setIsFocused(true);
	const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
		setIsFocused(false);
		onBlur?.(e);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > maxLength) return;
		if (type === 'comma') {
			const isMinus = e.target.value.replace(/[^-]/g, '').length === 1;
			const number = e.target.value.replace(/[^\d]/g, '');
			const commaString = isMinus ? `-${displayComma(number)}` : displayComma(number);
			onChange?.(commaString);
			return;
		}

		onChange?.(formatter ? formatter(e) : e.target.value);
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (type === 'number' && ['e', 'E', '+', '-', 'ArrowUp', 'ArrowDown', '.'].includes(e.key)) {
			e.preventDefault();
		}
		if (type === 'comma') {
			if (
				![
					'0',
					'1',
					'2',
					'3',
					'4',
					'5',
					'6',
					'7',
					'8',
					'9',
					'Backspace',
					'ArrowLeft',
					'ArrowRight',
					'Tab',
					'-',
				].includes(e.key)
			) {
				e.preventDefault();
			}
		}
	};

	const formatValue = (value?: string) => {
		const output = value?.toString() ?? '';
		return output.slice(0, maxLength);
	};

	const inputType = type === 'comma' ? 'text' : type;
	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		if (type === 'number') {
			e.preventDefault();
			const clipboardString = e.clipboardData.getData('text');
			const numericValue = clipboardString.replace(/\D/g, '');
			handleChange({
				target: {
					value: numericValue,
				},
			} as any);
		}
	};

	const numberInputOnWheelPreventChange = (e) => {
		// Prevent the input value change
		e.target.blur();
		// Prevent the page/container scrolling
		e.stopPropagation();
	};

	return (
		<S.Container $isDisabled={disabled}>
			<S.InputContainer $isFocused={isFocused} $error={status === 'error'} disabled={disabled}>
				{prefix && <S.PrefixText>{prefix}</S.PrefixText>}
				<input
					onPaste={handlePaste}
					style={{ textAlign: prefix ? 'right' : 'left' }}
					type={inputType}
					ref={ref}
					id={id}
					value={formatValue(value)}
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={handleBlur}
					onFocus={onFocus}
					onChange={handleChange}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					onWheel={numberInputOnWheelPreventChange}
				/>
				{suffix && <S.SuffixText>{suffix}</S.SuffixText>}
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRTableInput);
