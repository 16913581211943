import * as Accordion from '@radix-ui/react-accordion';
import { CheckOption } from 'components/base/Selections/type';
import InformationSheet from 'components/ui/InformationSheet';
import SmallTitleAccordion from 'components/ui/radix/accordion/SmallTitleAccordion';
import { displayBirthDay, displayPadTime, displayPhoneNumber } from 'lib';
import React from 'react';
import {
	ScheduleDetailCenterItem,
	ScheduleDetailItem,
	ScheduleDetailOtherCorpWork,
} from 'types/api/schedule';
import { v4 } from 'uuid';
import * as S from './styles';

interface Props {
	item?: {
		recipients?: ScheduleDetailItem[];
		employee?: ScheduleDetailItem[];
		caringCenter?: ScheduleDetailCenterItem[];
		otherCorpWorks?: ScheduleDetailOtherCorpWork[];
		myCenter: ScheduleDetailCenterItem[];
	} | null;
	options: {
		gender: CheckOption[];
		employees: CheckOption[];
	};
}

export default function EmployeeScheduleInfoView({
	item = {
		recipients: [],
		employee: [],
		caringCenter: [],
		otherCorpWorks: [],
		myCenter: [],
	},
	options,
}: Props) {
	const formatTime = (time: string) => {
		if (time.length === 4) {
			return `${time.slice(0, 2)}:${time.slice(2)}`;
		}
		if (time.length === 6) {
			return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
		}
		return time;
	};

	const renderGender = (comCdId = '') =>
		options?.gender?.find((option) => option.value === comCdId)?.label ?? '';

	const recipientTotalCount = item?.recipients?.length ?? 0;

	const renderRecipient = (recipient: ScheduleDetailItem, index: number) => (
		<Accordion.Item key={recipient.rsdnNo} value={String(index)} asChild defaultValue={[]}>
			<SmallTitleAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<SmallTitleAccordion.Trigger>{`수급자${
							index > 0 ? ` ${index + 1}` : ''
						} 정보`}</SmallTitleAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<SmallTitleAccordion.Content>
						<InformationSheet
							items={[
								[
									{
										label: '이름',
										value: recipient.korMemberNm || '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										label: '성별',
										value: renderGender(recipient.genderCd) || '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										label: '생년월일',
										value: displayBirthDay(recipient.birthDt) || '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										// 보호자의 전화번호
										label: '전화번호',
										value: displayPhoneNumber(recipient?.mobilePhoneNo) || '-',
										labelWidth: '12rem',
									},
								],
							]}
							type='gray'
						/>
					</SmallTitleAccordion.Content>
				</Accordion.Content>
			</SmallTitleAccordion.Item>
		</Accordion.Item>
	);

	const renderEmployee = (employee: ScheduleDetailItem, index: number) => (
		<Accordion.Item key={employee.memberId} value={String(recipientTotalCount + index)} asChild>
			<SmallTitleAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<SmallTitleAccordion.Trigger>{`직원${
							index > 0 ? ` ${index + 1}` : ''
						} 정보`}</SmallTitleAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<SmallTitleAccordion.Content>
						<InformationSheet
							items={[
								[{ label: '이름', value: employee.korMemberNm, labelWidth: '12rem' }],
								[
									{
										label: '성별',
										value: renderGender(employee.genderCd) || '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										label: '생년월일',
										value: displayBirthDay(employee.birthDt) || '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										label: '전화번호',
										value: displayPhoneNumber(employee.mobilePhoneNo) || '-',
										labelWidth: '12rem',
									},
								],
							]}
							type='gray'
						/>
					</SmallTitleAccordion.Content>
				</Accordion.Content>
			</SmallTitleAccordion.Item>
		</Accordion.Item>
	);

	const renderCaringCenter = (schedule: ScheduleDetailCenterItem) => {
		const matchedEmployee = schedule.employees.find(
			(item) => item.employeeId === schedule.employeeId,
		);
		return (
			<S.CenterItem key={schedule.serviceSchedulePlanId}>
				<S.Title>케어링 센터</S.Title>
				<InformationSheet
					items={[
						[
							{
								label: '센터명',
								value: schedule.center?.centerNm || '-',
								labelWidth: '12rem',
							},
						],
						[
							{
								// 직원 담당자의 이름
								label: '담당자',
								value: matchedEmployee?.employeeManagerNm || '-',
								labelWidth: '12rem',
							},
						],
						[
							{
								// 직원 담당자의 전화번호
								label: '연락처',
								value: displayPhoneNumber(matchedEmployee?.employeeManagerMobilePhoneNumber) || '-',
								labelWidth: '12rem',
							},
						],
						[
							{
								label: '급여명',
								value: schedule.serviceKindNm || '-',
								labelWidth: '12rem',
							},
						],
						[
							{
								label: '이용일시',
								value:
									schedule.serviceStartTime && schedule.serviceEndTime
										? `${formatTime(schedule.serviceStartTime)} ~ ${formatTime(
												schedule.serviceEndTime,
										  )}`
										: '-',
								labelWidth: '12rem',
							},
						],
					]}
					type='gray'
				/>
			</S.CenterItem>
		);
	};

	const renderOtherCorpWorks = (schedule: ScheduleDetailOtherCorpWork) => (
		<S.CenterItem key={v4()}>
			<S.Title>타근무</S.Title>
			<InformationSheet
				items={[
					[
						{
							label: '상호명',
							value: schedule.otherCorpNm || '-',
							labelWidth: '12rem',
						},
					],
					[
						{
							label: '연락처',
							value: displayPhoneNumber(schedule.otherCorpPhoneNo) || '-',
							labelWidth: '12rem',
						},
					],
					[
						{
							label: '이용일시',
							value: `${displayPadTime(schedule.otherCorpWorkStartTime)} ~ ${displayPadTime(
								schedule.otherCorpWorkEndTime,
							)}`,
							labelWidth: '12rem',
						},
					],
				]}
				type='gray'
			/>
		</S.CenterItem>
	);

	return (
		<S.Container>
			<S.AccordionContainer>
				<Accordion.Root type='multiple'>
					{item?.recipients?.map(renderRecipient)}
					{item?.employee?.map(renderEmployee)}
				</Accordion.Root>
			</S.AccordionContainer>
			{item?.caringCenter?.map(renderCaringCenter)}
			{item?.otherCorpWorks?.map(renderOtherCorpWorks)}
		</S.Container>
	);
}
