import styled from 'styled-components';

export const Container = styled.div`
	cursor: pointer;
	justify-content: flex-start;
	width: 100%;
	box-sizing: border-box;
	padding: 0;
	background-color: ${(props) => props.theme.colors.gray99};
`;
