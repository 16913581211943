import React, { ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import dayjs from 'dayjs';
import * as S from './styles';
import { FlexContainer } from '../CRFlexLayout/styles';

interface DateItem {
	date: string;
	item: ReactNode;
}

export interface Props {
	date?: Date;
	items?: DateItem[];
	height?: string;
	onChange: (date: Date) => void;
	onClickDay?: (date: Date) => void;
}

export default function CRCalendarSmall({ date, height, items, onChange, onClickDay }: Props) {
	const renderCustomDay = (dayOfMonth: number, date: Date) => {
		const dayItem = items?.find(
			({ date: itemDate }) => dayjs(itemDate).format('YYYYMMDD') === dayjs(date).format('YYYYMMDD'),
		)?.item;

		return (
			<S.CustomDay $hasError={false} onClick={() => onClickDay?.(date)}>
				<S.DayNumberContainer>{dayOfMonth}</S.DayNumberContainer>
				<FlexContainer direction='column' gap='0.4rem' width='100%'>
					{dayItem}
				</FlexContainer>
			</S.CustomDay>
		);
	};
	return (
		<S.Container $height={height}>
			<DatePicker
				selected={date}
				locale={ko}
				minDate={dayjs(date).startOf('month').toDate()}
				maxDate={dayjs(date).endOf('month').toDate()}
				renderCustomHeader={() => <div />}
				renderDayContents={renderCustomDay}
				inline
				onChange={onChange}
			/>
		</S.Container>
	);
}
