import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useCurrentCenterSalaryFeeInfo = useCRMutation(
	endpoint.getCurrentApplySalaryFeeInfo.key,
	'getCurrentApplySalaryFeeInfo',
);

export const useCreateCenterSalaryFeeInfo = useCRMutation(
	endpoint.createCenterSalaryFeeInfo.key,
	'createCenterSalaryFeeInfo',
);

export const useCheckSalaryDuplicatedPeriod = useCRMutation(
	endpoint.checkSalaryDuplicatedPeriod.key,
	'checkSalaryDuplicatedPeriod',
);

export const useUpdateCenterSalaryFeeInfo = useCRMutation(
	endpoint.updateCenterSalaryFeeInfo.key,
	'updateCenterSalaryFeeInfo',
);

export const useCheckSalaryDuplicatedRange = useCRMutation(
	endpoint.checkSalaryDuplicatedRange.key,
	'checkSalaryDuplicatedRange',
);
