import { SalaryFeeCdKorKeys } from 'components/domain/dialog/SalaryDetailDialog/types';

export const errorMessages: [SalaryFeeCdKorKeys, string][] = [
	['요양_일반_일반1등급', '방문요양(일반) - 일반 1등급을 입력해주세요.'],
	['요양_일반_일반2등급', '방문요양(일반) - 일반 2등급을 입력해주세요.'],
	['요양_일반_일반3등급', '방문요양(일반) - 일반 3등급을 입력해주세요.'],
	['요양_일반_일반4등급', '방문요양(일반) - 일반 4등급을 입력해주세요.'],
	['요양_일반_일반5등급', '방문요양(일반) - 일반 5등급을 입력해주세요.'],
	['요양_가족_60시간미만_가족30분', '방문요양(가족) - 60시간 미만 - 가족 30분을 입력해주세요.'],
	['요양_가족_60시간미만_가족60분', '방문요양(가족) - 60시간 미만 - 가족 60분을 입력해주세요.'],
	['요양_가족_60시간미만_가족90분', '방문요양(가족) - 60시간 미만 - 가족 90분을 입력해주세요.'],
	['요양_가족_60시간이상_가족30분', '방문요양(가족) - 60시간 이상 - 가족 30분을 입력해주세요.'],
	['요양_가족_60시간이상_가족60분', '방문요양(가족) - 60시간 이상 - 가족 60분을 입력해주세요.'],
	['요양_가족_60시간이상_가족90분', '방문요양(가족) - 60시간 이상 - 가족 90분을 입력해주세요.'],
	['목욕_60시간미만_가족40분', '방문목욕 - 60시간 미만 - 가족 40분을 입력해주세요.'],
	['목욕_60시간미만_가족60분', '방문목욕 - 60시간 미만 - 가족 60분을 입력해주세요.'],
	['목욕_60시간미만_일반40분', '방문목욕 - 60시간 미만 - 일반 40분을 입력해주세요.'],
	['목욕_60시간미만_일반60분', '방문목욕 - 60시간 미만 - 일반 60분을 입력해주세요.'],
	['목욕_60시간이상_가족40분', '방문목욕 - 60시간 이상 - 가족 40분을 입력해주세요.'],
	['목욕_60시간이상_가족60분', '방문목욕 - 60시간 이상 - 가족 60분을 입력해주세요.'],
	['목욕_60시간이상_일반40분', '방문목욕 - 60시간 이상 - 일반 40분을 입력해주세요.'],
	['목욕_60시간이상_일반60분', '방문목욕 - 60시간 이상 - 일반 60분을 입력해주세요.'],
	['목욕_60시간미만_가정내목욕40분', '방문목욕 - 60시간 미만 - 가정내목욕 40분을 입력해주세요.'],
	['목욕_60시간미만_가정내목욕60분', '방문목욕 - 60시간 미만 - 가정내목욕 60분을 입력해주세요.'],
	['목욕_60시간이상_가정내목욕40분', '방문목욕 - 60시간 이상 - 가정내목욕 40분을 입력해주세요.'],
	['목욕_60시간이상_가정내목욕60분', '방문목욕 - 60시간 이상 - 가정내목욕 60분을 입력해주세요.'],
	['목욕_60시간미만_차량내목욕40분', '방문목욕 - 60시간 미만 - 차량내목욕 40분을 입력해주세요.'],
	['목욕_60시간미만_차량내목욕60분', '방문목욕 - 60시간 미만 - 차량내목욕 60분을 입력해주세요.'],
	['목욕_60시간이상_차량내목욕40분', '방문목욕 - 60시간 이상 - 차량내목욕 40분을 입력해주세요.'],
	['목욕_60시간이상_차량내목욕60분', '방문목욕 - 60시간 이상 - 차량내목욕 60분을 입력해주세요.'],
	['간호_60시간미만_가족30분미만', '방문간호 - 60시간 미만 - 가족 30분 미만을 입력해주세요.'],
	[
		'간호_60시간미만_가족30분이상60분미만',
		'방문간호 - 60시간 미만 - 가족 30분 이상 60분 미만을 입력해주세요.',
	],
	['간호_60시간미만_가족60분이상', '방문간호 - 60시간 미만 - 가족 60분 이상을 입력해주세요.'],
	[
		'간호_60시간미만_일반30분미만_간호사',
		'방문간호 - 60시간 미만 - 일반 30분 미만(간호사)을 입력해주세요.',
	],
	[
		'간호_60시간미만_일반30분이상60분미만_간호사',
		'방문간호 - 60시간 미만 - 일반 30분 이상 60분 미만(간호사)을 입력해주세요.',
	],
	[
		'간호_60시간미만_일반60분이상_간호사',
		'방문간호 - 60시간 미만 - 일반 60분 이상(간호사)을 입력해주세요.',
	],
	[
		'간호_60시간미만_일반30분미만_간호조무사',
		'방문간호 - 60시간 미만 - 일반 30분 미만(간호조무사)을 입력해주세요.',
	],
	[
		'간호_60시간미만_일반30분이상60분미만_간호조무사',
		'방문간호 - 60시간 미만 - 일반 30분 이상 60분 미만(간호조무사)을 입력해주세요.',
	],
	[
		'간호_60시간미만_일반60분이상_간호조무사',
		'방문간호 - 60시간 미만 - 일반 60분 이상(간호조무사)을 입력해주세요.',
	],

	['간호_60시간이상_가족30분미만', '방문간호 - 60시간 이상 - 가족 30분 미만을 입력해주세요.'],
	[
		'간호_60시간이상_가족30분이상60분미만',
		'방문간호 - 60시간 이상 - 가족 30분 이상 60분 미만을 입력해주세요.',
	],
	['간호_60시간이상_가족60분이상', '방문간호 - 60시간 이상 - 가족 60분 이상을 입력해주세요.'],
	[
		'간호_60시간이상_일반30분미만_간호사',
		'방문간호 - 60시간 이상 - 일반 30분 미만(간호사)을 입력해주세요.',
	],
	[
		'간호_60시간이상_일반30분이상60분미만_간호사',
		'방문간호 - 60시간 이상 - 일반 30분 이상 60분 미만(간호사)을 입력해주세요.',
	],
	[
		'간호_60시간이상_일반60분이상_간호사',
		'방문간호 - 60시간 이상 - 일반 60분 이상(간호사)을 입력해주세요.',
	],
	[
		'간호_60시간이상_일반30분미만_간호조무사',
		'방문간호 - 60시간 이상 - 일반 30분 미만(간호조무사)을 입력해주세요.',
	],
	[
		'간호_60시간이상_일반30분이상60분미만_간호조무사',
		'방문간호 - 60시간 이상 - 일반 30분 이상 60분 미만(간호조무사)을 입력해주세요.',
	],
	[
		'간호_60시간이상_일반60분이상_간호조무사',
		'방문간호 - 60시간 이상 - 일반 60분 이상(간호조무사)을 입력해주세요.',
	],
	['기타_종일방문요양12시간', '기타 - 종일방문요양 12시간을 입력해주세요'],
	['기타_복지수당', '기타 - 복지수당을 입력해주세요'],
	['기타_종일방문요양24시간', '기타 - 종일방문요양 24시간을 입력해주세요'],
	['기타_연차', '기타 - 연차를 입력해주세요'],
];
