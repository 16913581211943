import React, { useEffect, useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { Controller, UseFormReturn } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import { InsuranceEditDetailFormType } from 'types/view/insurance';
import CRInput from 'components/base/CRInput';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { InsuranceDetailDTO } from 'types/api/insurance';
import { EmployeeBaseInfoDTO } from 'types/api';
import dayjs from 'dayjs';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { CRText } from 'components/base/CRText';
import InformationTable from 'components/ui/InformationTable';
import { calculateKoreanAge, rsdnNumberToBirthDay } from 'lib';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import * as S from './styles';
import { InsuranceState } from '..';

interface Props {
	item: InsuranceDetailDTO;
	disabled?: boolean;
	employeeData?: EmployeeBaseInfoDTO;
	rsdnNumber?: string;
	context: UseFormReturn<InsuranceEditDetailFormType, any, undefined>;
}

export default function InsuranceAcqDateChangeView({
	item,
	disabled,
	employeeData,
	rsdnNumber,
	context,
}: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN161'],
		},
		commonCodeAdapter,
	);

	const isOver65 = useMemo(
		() =>
			calculateKoreanAge(
				rsdnNumberToBirthDay(rsdnNumber),
				item?.insuranceRequestDetails?.employInsuranceRequestDate,
			) > 64,
		[rsdnNumber, item.insuranceRequestDetails],
	);

	const displayAcqDate = (acqDate?: string) => (
		<CRText
			text={acqDate ? dayjs(acqDate).format('YYYY.MM.DD') : '-'}
			typography='label'
			color='gray10'
		/>
	);

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '이전 취득 일자',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '변경할 취득 일자',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
			],
		];
		if (item.employeeInsuranceDetails?.employInsuranceDate) {
			items.push([
				{
					type: 'value',
					value: '고용보험',
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(item.employeeInsuranceDetails?.employInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.employInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.employInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}
		if (item.employeeInsuranceDetails?.accidentInsuranceDate) {
			items.push([
				{
					type: 'value',
					value: '산재보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(item.employeeInsuranceDetails?.accidentInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.accidentInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.accidentInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}
		if (item.employeeInsuranceDetails?.pensionInsuranceDate) {
			items.push([
				{
					type: 'value',
					value: '국민연금',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(item.employeeInsuranceDetails?.pensionInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.pensionInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.pensionInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (item.employeeInsuranceDetails?.healthInsuranceDate) {
			items.push([
				{
					type: 'value',
					value: '건강보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(item.employeeInsuranceDetails?.healthInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.healthInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails.healthInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (items.length === 1) {
			items.push([
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
			]);
		}

		return items;
	}, [item, employeeData]);

	const renderUnemploymentBenefit = () =>
		isOver65 && item?.insuranceRequestDetails?.employInsuranceRequestDate ? (
			<CRInputLabel label='실업급여 납부' isRequired type='left-sub' betweenGap='2.4rem'>
				<CRCheckBoxGroup
					style={{
						width: 'auto',
					}}
					disabled
					checkType='single'
					type='radio'
					appearanceType='button'
					gap={0.8}
					value={[
						{
							label: '',
							value: !!item.unemploymentBenefitPreconditionFullYn,
						},
					]}
					options={[
						{
							label: '납부하지 않음',
							value: false,
						},
						{
							label: '납부함',
							value: true,
						},
					]}
				/>
			</CRInputLabel>
		) : null;

	useEffect(() => {
		if (item.insuranceRequestState) {
			context.setValue('insuranceRequestStateCd', {
				label: item.insuranceRequestState.text,
				value: item.insuranceRequestState.id,
			});
		}

		if (item.responseDesc) {
			context.setValue('responseDesc', item.responseDesc);
		}
	}, [item]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='신고 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<InformationTable roundBorder items={informationTableItems} />
								</CRInputLabel>
								{renderUnemploymentBenefit()}
								<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
									<CRInput.TextArea
										disabled
										numberOfLines={4}
										value={item.requestDesc || ''}
										placeholder=''
										fixedHeight
									/>
								</CRInputLabel>
								<div
									style={{
										width: '57.2rem',
									}}>
									{item.attachFile?.fileDetails &&
										item.attachFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
											<CRInputLabel
												label='파일 첨부'
												type='left-sub'
												showOverflow
												betweenGap='2.4rem'>
												<CRInput.FileUploader
													disabled
													files={item.attachFile?.fileDetails}
													placeholder='파일 업로드'
													type='multiple'
												/>
											</CRInputLabel>
										)}
								</div>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='업무 담당자 답변'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.TextArea
												disabled={disabled}
												numberOfLines={4}
												onChange={onChange}
												value={disabled ? item?.responseDesc : value}
												placeholder='업무 담당자 답변 입력'
												fixedHeight
											/>
										</CRInputLabel>
									)}
									name='responseDesc'
									control={context.control}
								/>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='4대보험 신고 결과' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>4대보험 신고 결과</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='결과 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.Selector
												topOffset={-285}
												disabled={disabled}
												items={commonCodes?.CMN161}
												currentValue={value}
												onChangeValue={onChange}
												placeholder='결과 선택'
											/>
										)}
										name='insuranceRequestStateCd'
										control={context.control}
									/>
								</CRInputLabel>
								{item.insuranceRequestState.id !== InsuranceState.완료 &&
									[InsuranceState.반려됨, InsuranceState.철회].includes(
										context.watch('insuranceRequestStateCd')?.value as InsuranceState,
									) && (
										<CRInputLabel
											label='반려 사유'
											type='left-sub'
											isRequired
											showOverflow
											betweenGap='2.4rem'>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRInput.Default
														disabled={disabled}
														value={item?.cancelDesc || value}
														onChange={onChange}
														placeholder='반려 사유 입력'
													/>
												)}
												name='cancelDesc'
												control={context.control}
											/>
										</CRInputLabel>
									)}
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
