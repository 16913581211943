import { CRTableHeadConfig } from 'types/view/base';

export const CENTER_ACCOUNT_RECORD_SUMMARY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '18rem',
		label: '계좌번호',
		sortKey: '계좌번호',
		paddingLeft: '2.4rem',
	},
	{
		width: '14.7rem',
		label: '계좌명',
		sortKey: '계좌명',
	},
	{
		width: '15rem',
		label: '입금계',
		sortKey: '입금계',
		textAlign: 'right',
	},
	{
		width: '15rem',
		label: '출금계',
		sortKey: '출금계',
		textAlign: 'right',
	},
	{
		width: '15rem',
		label: '잔금계',
		sortKey: '잔금계',
		textAlign: 'right',
	},
	{
		width: '100%',
		label: ' ',
		sortKey: ' ',
	},
];
