import styled, { css } from 'styled-components';
import { LGNBType } from 'lib/provider/ui/Global';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

export const ContentContainer = styled.div`
	width: 100vw;
	height: calc(100vh - 5.6rem);
	display: flex;
	position: relative;
	overflow: hidden;
`;

export const LeftContainer = styled.div<{
	$isFolded: boolean;
	$navMode: LGNBType;
}>`
	width: ${(props) => (props.$isFolded ? '0rem' : '28rem')};
	min-width: ${(props) => (props.$isFolded ? '0rem' : '28rem')};
	height: calc(100vh - 5.6rem);
	box-sizing: border-box;
	overflow-y: overlay;
	transform: ${(props) => (props.$isFolded ? 'translateX(-28rem)' : 'translateX(0)')};
	transition: all 0.2s ease 0s;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	white-space: nowrap;
	background: ${(props) => props.theme.colors.gray99};
	will-change: transform;
	${(props) =>
		props.$navMode === LGNBType.FullScreen &&
		css`
			position: absolute;
			z-index: 10;
		`}
`;

export const MainContentContainer = styled.div<{ $isLeftFolded: boolean; $isRightFolded: boolean }>`
	height: calc(100vh - 5.6rem);
	width: 100%;
	overflow-x: overlay;
	overflow-y: hidden;
	will-change: transform;
`;

export const MainContentView = styled.div`
	height: calc(100vh - 5.6rem);
	width: 100%;
	min-width: 1400px;
	overflow: hidden;
	overflow-x: auto;
`;

export const RightContainer = styled.div<{
	$isFolded: boolean;
	$newConsultingMode: boolean;
}>`
	width: ${(props) => (props.$isFolded ? '0' : props.$newConsultingMode ? '80rem' : '48rem ')};
	min-width: ${(props) => (props.$isFolded ? '0' : props.$newConsultingMode ? '80rem' : '48rem ')};
	height: calc(100vh - 5.6rem);
	box-sizing: border-box;
	transform: ${(props) => (props.$isFolded ? 'translateX(48rem)' : 'translateX(0)')};
	transition: all 0.2s ease 0s;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	white-space: nowrap;
	background: ${(props) => props.theme.colors.gray99};
	overflow-y: auto;
	will-change: transform;
`;
