import React, { ReactNode } from 'react';
import * as S from './styles';

interface Props {
	children?: ReactNode;
}

function EDocClientBottomFloating({ children }: Props) {
	return (
		<>
			<S.EmptyBox />
			<S.FixedContainer>{children}</S.FixedContainer>
		</>
	);
}

export default EDocClientBottomFloating;
