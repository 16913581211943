import { Employee } from 'components/domain/dialog/ApprovalLineSelectorDialog/mock';
import { DocumentItem } from 'types/view/approval';
import { Role } from 'types/view/user';

export const STAFF_MOCK: {
	TAEYOON: Employee;
	TOTORO: Employee;
	PORORO: Employee;
	CRONG: Employee;
	CHAEWON: Employee;
	MEERAY: Employee;
	JUNGRYEOL: Employee;
	JIEUN: Employee;
} = {
	TAEYOON: {
		id: '1',
		imgUrl: 'https://ca.slack-edge.com/T016HGM8GBY-U053DJXT8NR-f5883d5800f3-512',
		name: '전태윤',
		center: '가온센터',
		birth: '621203',
		statusInfo: {
			status: '반려',
			reason: '반려하고싶어요',
		},
		role: Role.Admin,
	},
	TOTORO: {
		id: '2',
		imgUrl: 'https://dimg.donga.com/wps/NEWS/IMAGE/2015/09/18/73705737.3.jpg',
		name: '토토로',
		center: '나온센터',
		birth: '621203',
		role: Role.Admin,
	},
	PORORO: {
		id: '3',
		imgUrl: 'https://image.kmib.co.kr/online_image/2015/0901/201509010635_61130009809053_1.jpg',
		name: '뽀로로',
		center: '다온센터',
		birth: '621203',
		statusInfo: {
			status: '반려',
			reason: '반려하고싶어요',
		},
		role: Role.Admin,
	},
	CRONG: {
		id: '4',
		imgUrl:
			'https://d2u3dcdbebyaiu.cloudfront.net/uploads/atch_img/245/51b79627831640164fc6b003faf240c3_res.jpeg',
		name: '크롱',
		center: '라온센터',
		birth: '621203',
		statusInfo: {
			status: '반려',
			reason: '반려하고싶어요',
		},
		role: Role.Admin,
	},
	CHAEWON: {
		id: '5',
		imgUrl: 'https://img.hankyung.com/photo/202206/BF.30292418.1.png',
		name: '강채원',
		center: '라온센터',
		birth: '621203',
		statusInfo: {
			status: '반려',
			reason: '반려하고싶어요',
		},
		role: Role.Admin,
	},
	MEERAY: {
		id: '6',
		name: '강미래',
		birth: '621212',
		center: '라온센터',
		imgUrl: 'https://ca.slack-edge.com/T016HGM8GBY-U03KASGCE9H-d5132162f800-512',
		role: Role.Admin,
	},
	JUNGRYEOL: {
		id: '7',
		imgUrl: 'https://www.allthatsunglass.co.kr/shopimages/eantrad789/0030580002802.jpg?1659062677',
		name: '10cm',
		center: '라온센터',
		birth: '621203',
		statusInfo: {
			status: '반려',
			reason: '반려하고싶어요',
		},
		role: Role.Admin,
	},
	JIEUN: {
		id: '8',
		name: '이지은',
		birth: '621211',
		center: '라온센터',
		imgUrl:
			'https://img.khan.co.kr/news/2023/05/12/news-p.v1.20230512.e5fffd99806f4dcabd8426d52788f51a_P1.png',
		role: Role.Admin,
	},
};

export const documentMock: DocumentItem[] = [
	{
		id: '1032',
		title: '[업무] 4월 청구',
		center: '마음센터',
		author: STAFF_MOCK.JIEUN,
		createdAt: '23.10.11 00:00',
		updatedAt: '2023.07.11 13:02',
		approvalLine: [
			STAFF_MOCK.MEERAY,
			STAFF_MOCK.TAEYOON,
			STAFF_MOCK.TOTORO,
			STAFF_MOCK.PORORO,
			STAFF_MOCK.CRONG,
		],
		relatedTask: {
			name: '[업무] 4월 청구',
			link: '',
		},
		status: '반려',
		referrer: [STAFF_MOCK.TAEYOON, STAFF_MOCK.TOTORO],
		type: '업무검토',
		content: '안녕하십니까? 안녕하십니까? 안녕하십니까?',
		files: [
			{
				name: '테스트.zip',
				size: 2160143,
				link: 'https://github.com/caring-Hyeonjun/download/blob/3227e32fcaaa315ce3dc12edb200bd9ed2ba18bc/test.jpeg',
			},
		],
	},
	{
		id: '1033',
		title: '[업무] 3월 급여',
		center: '마음센터',
		author: STAFF_MOCK.JIEUN,
		createdAt: '23.10.11 00:00',
		updatedAt: '2023.07.11 13:02',
		approvalLine: [
			{
				...STAFF_MOCK.TOTORO,
				statusInfo: {
					status: '승인완료',
					reason: '승인합니다.',
				},
			},
			{
				...STAFF_MOCK.PORORO,
				statusInfo: {
					status: '승인완료',
					reason: '승인합니다.',
				},
			},
			{
				...STAFF_MOCK.CRONG,
				statusInfo: {
					status: '승인완료',
					reason: '승인합니다.',
				},
			},
		],
		relatedTask: {
			name: '[업무] 3월 급여',
			link: '',
		},
		status: '승인완료',
		referrer: [STAFF_MOCK.TAEYOON, STAFF_MOCK.TOTORO],
		type: '업무검토',
		content: '안녕하십니까? 안녕하십니까? 안녕하십니까?',
	},
	{
		id: '1034',
		title: '[업무] 4월 청구',
		center: '마음센터',
		author: STAFF_MOCK.JIEUN,
		createdAt: '23.10.11 00:00',
		updatedAt: '2023.07.11 13:02',
		approvalLine: [STAFF_MOCK.TAEYOON, STAFF_MOCK.TOTORO, STAFF_MOCK.PORORO, STAFF_MOCK.CRONG],
		relatedTask: {
			name: '[업무] 4월 청구',
			link: '',
		},
		status: '진행중',
		referrer: [STAFF_MOCK.TAEYOON, STAFF_MOCK.TOTORO],
		type: '업무검토',
		content: '안녕하십니까? 안녕하십니까? 안녕하십니까?',
	},
];
