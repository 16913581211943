import React, { useEffect, useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { Controller, UseFormReturn } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import { InsuranceEditDetailFormType } from 'types/view/insurance';
import InformationTable from 'components/ui/InformationTable';
import CRInput from 'components/base/CRInput';
import { useCommonCodes } from 'lib/hook/react-query';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { InsuranceDetailDTO } from 'types/api/insurance';
import dayjs from 'dayjs';
import { commonCodeAdapter } from 'lib/adapter/common';

import CRSwitch from 'components/base/Selections/CRSwitch';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import * as S from './styles';
import { InsuranceState } from '..';

interface Props {
	item: InsuranceDetailDTO;
	disabled?: boolean;
	context: UseFormReturn<InsuranceEditDetailFormType, any, undefined>;
}

export default function InsuranceChangeSalaryView({ item, disabled, context }: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN161'],
		},
		commonCodeAdapter,
	);

	const {
		isEachSelect,
		employInsuranceApproveYn,
		accidentInsuranceApproveYn,
		pensionInsuranceApproveYn,
		healthInsuranceApproveYn,
	} = context.watch();

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '이전 신고 금액',
					labelStyle: {
						width: '16rem',
						textAlign: 'right',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '변경할 신고 금액',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						textAlign: 'right',
						verticalAlign: 'middle',
					},
				},
			],
		];
		if (item.insuranceRequestDetails?.employInsuranceRequestAmt) {
			items.push([
				{
					type: 'value',
					value: '고용보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: item?.employeeInsuranceDetails?.employInsuranceAmt
						? item.employeeInsuranceDetails?.employInsuranceAmt?.toLocaleString()
						: '-',
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							maxLength={13}
							disabled
							prefix=' '
							type='comma'
							value={String(
								item.insuranceRequestDetails?.employInsuranceRequestAmt?.toLocaleString(),
							)}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}
		if (item.insuranceRequestDetails?.accidentInsuranceRequestAmt) {
			items.push([
				{
					type: 'value',
					value: '산재보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: item?.employeeInsuranceDetails?.accidentInsuranceAmt
						? item.employeeInsuranceDetails?.accidentInsuranceAmt?.toLocaleString()
						: '-',
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							maxLength={13}
							disabled
							prefix=' '
							type='comma'
							value={String(
								item.insuranceRequestDetails?.accidentInsuranceRequestAmt?.toLocaleString(),
							)}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}
		if (item.insuranceRequestDetails?.pensionInsuranceRequestAmt) {
			items.push([
				{
					type: 'value',
					value: '국민연금',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: item?.employeeInsuranceDetails?.pensionInsuranceAmt
						? item.employeeInsuranceDetails?.pensionInsuranceAmt?.toLocaleString()
						: '-',
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							maxLength={13}
							disabled
							prefix=' '
							type='comma'
							value={String(
								item.insuranceRequestDetails?.pensionInsuranceRequestAmt?.toLocaleString(),
							)}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}
		if (item.insuranceRequestDetails?.healthInsuranceRequestAmt) {
			items.push([
				{
					type: 'value',
					value: '건강보험',
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: item?.employeeInsuranceDetails?.healthInsuranceAmt
						? item.employeeInsuranceDetails?.healthInsuranceAmt?.toLocaleString()
						: '-',
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							maxLength={13}
							disabled
							prefix=' '
							type='comma'
							value={String(
								item.insuranceRequestDetails?.healthInsuranceRequestAmt?.toLocaleString(),
							)}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (items.length === 1) {
			items.push([
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
			]);
		}
		return items;
	}, [item]);

	const renderResultSelect = () => {
		const isEmployInsuranceExist =
			item.insuranceRequestDetails?.employInsuranceRequestAmt ||
			item.employeeInsuranceDetails?.employInsuranceAmt;
		const isAccidentInsuranceExist =
			item.insuranceRequestDetails?.accidentInsuranceRequestAmt ||
			item.employeeInsuranceDetails?.accidentInsuranceAmt;
		const isPensionInsuranceExist =
			item.insuranceRequestDetails?.pensionInsuranceRequestAmt ||
			item.employeeInsuranceDetails?.pensionInsuranceAmt;
		const isHealthInsuranceExist =
			item.insuranceRequestDetails?.healthInsuranceRequestAmt ||
			item.employeeInsuranceDetails?.healthInsuranceAmt;

		const commonOptions = [
			{
				label: '완료',
				value: true,
			},
			{
				label: '반려',
				value: false,
			},
		];

		if (isEachSelect) {
			return (
				<S.ResultSelectContainer>
					{isEmployInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								고용보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										style={{
											width: 'auto',
										}}
										disabled={disabled}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='employInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isAccidentInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								산재보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='accidentInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isPensionInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								국민연금 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='pensionInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isHealthInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								건강보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='healthInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
				</S.ResultSelectContainer>
			);
		}
		return (
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.Selector
						topOffset={-285}
						disabled={disabled}
						items={commonCodes?.CMN161.filter((item) => item?.value !== InsuranceState.부분완료)}
						currentValue={value}
						onChangeValue={onChange}
						placeholder='결과 선택'
					/>
				)}
				name='insuranceRequestStateCd'
				control={context.control}
			/>
		);
	};

	const renderCancelDesc = () => {
		if (!item || item.insuranceRequestState.id === InsuranceState.완료) return null;
		const insuranceState = context.watch('insuranceRequestStateCd')?.value as InsuranceState;

		const isExistDenyInsurance = [
			employInsuranceApproveYn?.[0]?.value,
			accidentInsuranceApproveYn?.[0]?.value,
			pensionInsuranceApproveYn?.[0]?.value,
			healthInsuranceApproveYn?.[0]?.value,
		]
			.filter((item) => item !== undefined)
			.some((item) => !item);

		return (
			((isEachSelect && isExistDenyInsurance) ||
				[InsuranceState.반려됨, InsuranceState.철회].includes(insuranceState)) && (
				<CRInputLabel label='반려 사유' type='left-sub' isRequired showOverflow betweenGap='2.4rem'>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.Default
								disabled={disabled}
								value={item?.cancelDesc || value}
								onChange={onChange}
								placeholder='반려 사유 입력'
							/>
						)}
						name='cancelDesc'
						control={context.control}
					/>
				</CRInputLabel>
			)
		);
	};

	useEffect(() => {
		const defaultForm: InsuranceEditDetailFormType = {
			insuranceRequestStateCd: item.insuranceRequestState
				? {
						label: item.insuranceRequestState.text,
						value: item.insuranceRequestState.id,
				  }
				: undefined,
			responseDesc: item?.responseDesc || '',
			insuranceRequestDetails: item.insuranceRequestDetails || undefined,
		};

		if (item.insuranceRequestState?.id === InsuranceState.부분완료) {
			defaultForm.employInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.employInsuranceApproveYn,
				},
			];
			defaultForm.accidentInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.accidentInsuranceApproveYn,
				},
			];
			defaultForm.healthInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.healthInsuranceApproveYn,
				},
			];
			defaultForm.pensionInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.pensionInsuranceApproveYn,
				},
			];
			defaultForm.isEachSelect = true;
		}
		context.reset(defaultForm);
	}, [item]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='신고 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<InformationTable roundBorder items={informationTableItems} />
								</CRInputLabel>
								<CRInputLabel
									label='보수액 변경 월'
									type='left-sub'
									showOverflow
									isRequired
									betweenGap='2.4rem'>
									<CRInput.YearMonthPicker
										disabled
										currentValue={
											item.salaryChangeYm ? dayjs(item.salaryChangeYm).toDate() : undefined
										}
										placeholder='변경 월 선택'
									/>
								</CRInputLabel>
								<CRInputLabel
									label='변경 사유'
									type='left-sub'
									showOverflow
									isRequired
									betweenGap='2.4rem'>
									<CRInput.Default
										disabled
										value={item?.salaryChangeDesc}
										placeholder='변경 사유 선택'
									/>
								</CRInputLabel>
								<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
									<CRInput.TextArea
										disabled
										numberOfLines={4}
										value={item.requestDesc || ''}
										placeholder='요청 사항 입력'
										fixedHeight
									/>
								</CRInputLabel>
								<div
									style={{
										width: '57.2rem',
									}}>
									{item.attachFile?.fileDetails &&
										item.attachFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
											<CRInputLabel
												label='파일 첨부'
												type='left-sub'
												showOverflow
												betweenGap='2.4rem'>
												<CRInput.FileUploader
													disabled
													files={item.attachFile?.fileDetails}
													placeholder='파일 업로드'
													type='multiple'
												/>
											</CRInputLabel>
										)}
								</div>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='업무 담당자 답변'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.TextArea
												disabled={disabled}
												numberOfLines={4}
												onChange={onChange}
												value={disabled ? item?.responseDesc : value}
												placeholder='업무 담당자 답변 입력'
												fixedHeight
											/>
										</CRInputLabel>
									)}
									name='responseDesc'
									control={context.control}
								/>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='4대보험 신고 결과' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>4대보험 신고 결과</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='보험별 선택 여부'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<S.SwitchContainer>
												<CRSwitch
													disabled={disabled}
													onChange={onChange}
													value={value}
													type='icon'
												/>
											</S.SwitchContainer>
										)}
										name='isEachSelect'
										control={context.control}
									/>
								</CRInputLabel>
								<CRInputLabel
									label='결과 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									{renderResultSelect()}
								</CRInputLabel>
								{renderCancelDesc()}
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
