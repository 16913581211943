import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useUpdateMyCenterDocument = useCRMutation(
	endpoint.updateMyCenterDocs.key,
	'updateMyCenterDocument',
);

export const useDeleteMyCenterDocument = useCRMutation(
	endpoint.deleteMyCenterDocs.key,
	'deleteMyCenterDocument',
);
