import { styled } from 'styled-components';

export const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const FooterContentContainer = styled.div`
	width: 100%;
	max-width: 108rem;
	min-width: 80rem;
	padding: 0rem 2.4rem;
`;

export const MainComponentContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 5.8rem);
	overflow: hidden;
`;
