import dayjs from 'dayjs';
import { Task } from 'types/view/task';

export function displayNumber(value: string | number): string {
	if (typeof value === 'string') {
		return value;
	}
	if (typeof value === 'number') {
		if (value < 10) {
			return `0${value}`;
		}
		return `${value}`;
	}

	return '';
}

export function displayTask(task: Task): string {
	switch (task) {
		case Task.CONTRACT:
			return '자체 계약';
		case Task.CREATE_SCHEDULE:
			return '일정 생성';
		case Task.MONITORING:
			return '일정 모니터링';
		default:
			return '';
	}
}

export function displayPhoneNumber(number = '') {
	let value = number;
	// eslint-disable-next-line
	if (!value) {
		return '';
	}

	value = value.replace(/[^0-9]/g, '');

	const result = [];
	let restNumber = '';

	// 지역번호와 나머지 번호로 나누기
	if (value.startsWith('02')) {
		// 서울 02 지역번호
		result.push(value.substr(0, 2));
		restNumber = value.substring(2);
	} else if (value.startsWith('1')) {
		// 지역 번호가 없는 경우
		// 1xxx-yyyy
		restNumber = value;
	} else {
		// 나머지 3자리 지역번호
		// 0xx-yyyy-zzzz
		result.push(value.substr(0, 3));
		restNumber = value.substring(3);
	}

	if (restNumber.length === 7) {
		// 7자리만 남았을 때는 xxx-yyyy
		result.push(restNumber.substring(0, 3));
		result.push(restNumber.substring(3));
	} else {
		result.push(restNumber.substring(0, 4));
		result.push(restNumber.substring(4));
	}

	return result.filter((val) => val).join('-');
}

export function displayRdsNumber(rdsNo?: string) {
	if (!rdsNo) return '';

	const rdsNumber = String(rdsNo);

	return `${rdsNumber.substring(0, 6)}-${rdsNumber.substring(6)}`;
}

export function displayBirthDay(birthday?: string, renderAge = false) {
	if (!birthday) return '';

	const birthDay = String(birthday);
	const formattedDay = `${birthDay.substring(0, 4)}.${birthDay.substring(
		4,
		6,
	)}.${birthDay.substring(6)}`;
	const age = dayjs().diff(dayjs(formattedDay), 'year');

	return `${formattedDay}${renderAge ? `(${age}세)` : ''}`;
}

export function displayBirthDayId(birthday?: string) {
	if (!birthday) return '';
	if (birthday?.length === 6) return birthday;
	if (birthday?.length === 8) return birthday.slice(2, 8);
	return '';
}

export function displayShortDate(date?: string) {
	if (!date) return '';

	const formattedDay = `${date.substring(0, 4)}.${date.substring(4, 6)}.${date.substring(6)}`;

	return formattedDay;
}

export function displayPadTime(time?: string) {
	if (!time) return '';
	const hours = time.substring(-1, 2);
	const minutes = time.slice(-2);
	return `${hours}:${minutes}`;
}

export function displayMinuteToHourMinute(minutes?: number): string {
	if (Number.isNaN(minutes) || !minutes || minutes < 0) {
		return '-';
	}

	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;

	if (hours === 0) {
		return `${remainingMinutes}분`;
	}
	if (remainingMinutes === 0) {
		return `${hours}시간`;
	}
	return `${hours}시간 ${remainingMinutes}분`;
}

export function displayDayOfWeekInKorean(day: number) {
	return ['일', '월', '화', '수', '목', '금', '토'][day] || '';
}

export const displayDateToYYYYMM = (date: Date) => dayjs(date).format('YYYYMM');

export function displayComma(value: number | string) {
	const number = Number(value);

	// 숫자를 문자열로 변환합니다.
	let numberString = number.toString();

	// 소수점 위치를 찾습니다.
	const decimalIndex = numberString.indexOf('.');

	// 소수점 이전 부분에 콤마를 추가합니다.
	if (decimalIndex === -1) {
		numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		const integerPart = numberString.slice(0, decimalIndex);
		const decimalPart = numberString.slice(decimalIndex);
		numberString = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimalPart;
	}

	return numberString;
}

export function displayCommaToNumber(comma?: string) {
	if (!comma) return 0;
	return Number(comma.replace(/,/g, ''));
}

export function displayRowDividedText(inputText: string) {
	if (!inputText) {
		return '';
	}
	let result = ``;
	// 괄호 안의 쉼표를 기준으로 문자열을 나눔
	const textArr = inputText.split(',');

	textArr.forEach((text, index) => {
		result += `${text}${index !== textArr.length - 1 ? '\n' : ''}`;
	});
	return result;
}

export function displayBizNo(bizNo?: string) {
	if (!bizNo) return '';

	if (bizNo.length !== 10) return bizNo;

	return `${bizNo.slice(0, 3)}-${bizNo.slice(3, 5)}-${bizNo.slice(5)}`;
}
