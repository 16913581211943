import React from 'react';

import Assets from 'assets';
import * as S from './styles';

interface IProps {
	breadCrumbs: { link: string; label: string; onClick?: () => void }[];
}

function CRBreadCrumbs({ breadCrumbs }: IProps): React.ReactElement {
	return (
		<S.Container>
			{breadCrumbs.map((item, index) => (
				<React.Fragment key={item.link}>
					<S.BreadCrumbItem onClick={item?.onClick} $isActive={index === breadCrumbs.length - 1}>
						{item.label}
					</S.BreadCrumbItem>
					{index !== breadCrumbs.length - 1 && (
						<S.Divider src={Assets.icon.keyboardArrowRight} alt='keyboardArrowRight' />
					)}
				</React.Fragment>
			))}
		</S.Container>
	);
}

export default CRBreadCrumbs;
