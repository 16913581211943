import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import BillingTab from './BillingTab';

function BillingPage(): React.ReactElement {
	const billingTab = useMemo(() => RouterPath.billingTab(), []);

	return (
		<CRTab.Default
			defaultActiveKey={billingTab.billing.key}
			items={[
				{
					label: billingTab.billing.label,
					key: billingTab.billing.key,
					children: <BillingTab />,
				},
			]}
			breadCrumb='청구관리'
		/>
	);
}

export default BillingPage;
