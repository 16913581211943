import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	height: calc(100vh - 16.8rem);
	overflow: overlay;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const ContentContainer = styled.div<{ $isSelected?: boolean }>`
	cursor: pointer;
	padding: 2.4rem;
	box-sizing: border-box;
	&:hover {
		background: ${(props) => props.theme.colors.gray99};
	}

	${(props) =>
		props.$isSelected &&
		css`
			background: ${props.theme.colors.gray99};
		`}
`;

export const ContentDateText = styled.div`
	${(props) => props.theme.typography.body}
`;

export const ContentDescriptionText = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
