import styled from 'styled-components';

export const Container = styled.tr`
	border: 1px solid ${(props) => props.theme.colors.gray90};
`;

export const Wrapper = styled.th<{
	$textAlign?: 'left' | 'center' | 'right';
	width?: string;
	$showBorder?: boolean;
}>`
	background: ${(props) => props.theme.colors.gray40};
	border-bottom: 1px solid ${(props) => props.theme.colors.gray90};
	color: ${(props) => props.theme.colors.white};
	padding: 0px 10px;
	text-align: ${(p) => p.$textAlign};
	width: ${(p) => p.width};
	border-left: ${(p) => (p.$showBorder ? `1px solid ${p.theme.colors.gray90}` : undefined)};
	white-space: nowrap;
	&:first-child {
		border-left: 0;
	}
`;
