import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import * as S from './styles';

function MyCenterPcorpCertView(): React.ReactElement {
	const { form } = useCenterInfo();
	const isCenterChief = useHasPermission('센터장');
	return (
		<S.Container>
			<CRInputLabel type='left' label='공동인증서 (파일)'>
				<CRInput.FileUploader
					disabled
					files={form.getValues('pcorpCertiFile')}
					placeholder='공동인증서 (파일) 업로드'
					type='single'
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='인증서 비밀번호'>
				<CRInput.Password
					placeholder='인증서 비밀번호 입력'
					disabled
					value={form.getValues('pcorpLoginPassword')}
					toggleSecret={isCenterChief}
				/>
			</CRInputLabel>
		</S.Container>
	);
}

export default MyCenterPcorpCertView;
