import { CRTableHeadConfig } from 'types/view/base';

export const STAFF_EDUCATION_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '13rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '13rem',
		label: '작성 완료 여부',
		sortKey: '작성 완료 여부',
	},
	{
		width: '14.4rem',
		label: '방문(상담) 일',
		sortKey: '방문일',
	},
	{
		width: '20rem',
		label: '요양보호사 방문일정',
		sortKey: '요양보호사 방문일정',
	},
	{
		width: '20rem',
		label: '방문(상담) 시간',
		sortKey: '방문시간',
	},
	{
		width: '14.4rem',
		label: '방문자',
		sortKey: '방문자',
	},
	{
		width: '20rem',
		label: '',
		sortKey: '',
	},
];
