import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	padding: 1.6rem 2.4rem;
	box-sizing: border-box;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: visible;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1.6rem;
`;

export const HeaderTitle = styled.div`
	flex: 1;
	${(props) => props.theme.typography.bodyB}
	color: ${(props) => props.theme.colors.gray10}
`;

export const TimePickerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 0.8rem;
`;

export const RequiredMark = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.primary60};
`;
