import React, { useMemo } from 'react';
import CRTab from 'components/base/CRTab';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import Assets from 'assets';
import useDialog from 'lib/hook/util/useDialog';
import InsuranceExcelDownloadDialog from 'components/domain/dialog/InsuranceExcelDownloadDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import InsuranceTab from './InsuranceTab';

export default function InsurancePage() {
	const insuranceTab = useMemo(() => RouterPath.insurance().tab, []);
	const { showDialog } = useDialog();

	const handleClickDownloadExcel = () => {
		showDialog(() => <InsuranceExcelDownloadDialog />);
	};
	const hasPermission = useHasPermission(['센터장']);
	return (
		<CRTab.Default
			defaultActiveKey={insuranceTab.key}
			breadCrumb='4대보험'
			items={[
				{
					label: insuranceTab.label,
					key: insuranceTab.key,
					children: <InsuranceTab hasPermission={hasPermission} />,
				},
			]}
			renderRightButton={
				hasPermission ? (
					<CRButton.IconButton
						// disabled={disableControllers}
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.fileDownload}
						onClick={handleClickDownloadExcel}>
						엑셀 다운로드
					</CRButton.IconButton>
				) : undefined
			}
		/>
	);
}
