import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const PROTECTION_HISTORY_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '15rem',
		label: '득실 타입',
		sortKey: '득실 타입',
	},
	{
		width: '14rem',
		label: '국민연금 신고 금액',
		sortKey: '국민연금 신고 금액',
	},
	{
		width: '14rem',
		label: '국민연금 신고 일자',
		sortKey: '국민연금 신고 일자',
	},
	{
		width: '14rem',
		label: '건강보험 신고 금액',
		sortKey: '건강보험 신고 금액',
	},
	{
		width: '14rem',
		label: '건강보험 신고 일자',
		sortKey: '건강보험 신고 일자',
	},
	{
		width: '14rem',
		label: '고용보험 신고 금액',
		sortKey: '고용보험 신고 금액',
	},
	{
		width: '14rem',
		label: '고용보험 신고 일자',
		sortKey: '고용보험 신고 일자',
	},
	{
		width: '14rem',
		label: '산재보험 신고 금액',
		sortKey: '산재보험 신고 금액',
	},
	{
		width: '14rem',
		label: '산재보험 신고 일자',
		sortKey: '산재보험 신고 일자',
	},
	{
		width: '14rem',
		label: '생성일시',
		sortKey: '생성일시',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const STATE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: 'CMN161.50',
		label: '철회',
		color: 'red',
	},
	{
		key: 'CMN161.10',
		label: '대기',
		color: 'yellow',
	},
	{
		key: 'CMN161.30',
		label: '반려',
		color: 'red',
	},
	{
		key: 'CMN161.20',
		label: '진행중',
		color: 'yellow',
	},
	{
		key: 'CMN161.40',
		label: '완료',
		color: 'green',
	},
];
