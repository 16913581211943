import React, { useEffect } from 'react';
import { CheckOption } from 'components/base/Selections/type';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { EmploymentCertificationFormTypes } from 'types/view/eDoc';
import { EmployeeDTO } from 'types/api/employee';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import dayjs from 'dayjs';
import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	reasonCodes?: CheckOption[];
	formContext: UseFormReturn<EmploymentCertificationFormTypes, any, undefined>;
}

export default function EmploymentCertificationForm({
	templateCode,
	currentEmployee,
	reasonCodes,
	formContext,
}: Props) {
	const { data: employmentCertificationIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	useEffect(() => {
		if (employmentCertificationIssueInfo) {
			formContext.setValue('data', employmentCertificationIssueInfo);
			const joinDate = employmentCertificationIssueInfo.data?.employee?.[0]?.employeeJoinDate;
			if (joinDate) {
				formContext.setValue('workStartDate', dayjs(joinDate).toDate());
			}
		}
	}, [employmentCertificationIssueInfo]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='신청인' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: currentEmployee?.korMemberNm || '',
							value: currentEmployee?.employeeId,
						}}
						items={[
							{
								label: currentEmployee?.korMemberNm || '',
								value: currentEmployee?.employeeId,
							},
						]}
					/>
				</CRInputLabel>
				<CRInputLabel isRequired label='입사일'>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DatePicker
								value={value}
								onChangeValue={onChange}
								placeholder='입사일 선택'
							/>
						)}
						name='workStartDate'
						control={formContext.control}
					/>
				</CRInputLabel>
				<CRInputLabel isRequired label='신청사유'>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRCheckBoxGroup
								onChange={onChange}
								direction='col'
								appearanceType='button'
								checkType='single'
								type='radio'
								gap={0.4}
								value={value}
								options={
									reasonCodes?.map((option) => ({
										label: option.label,
										value: option.value,
									})) ?? []
								}
							/>
						)}
						name='issueReason'
						control={formContext.control}
					/>
					{formContext.watch('issueReason')?.[0]?.value === 'CMN142.30' && (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.Default placeholder='기타 사유 입력' onChange={onChange} value={value} />
							)}
							name='issueReasonEtcDescription'
							control={formContext.control}
						/>
					)}
				</CRInputLabel>
			</S.SubFormContainer>
		</S.Container>
	);
}
