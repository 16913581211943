import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useSaveCenterAccount = useCRMutation(
	endpoint.saveCenterAccount.key,
	'saveCenterAccount',
);

export const useUpdateCenterAccount = useCRMutation(
	endpoint.updateCenterAccount.key,
	'updateCenterAccount',
);

export const useDeleteCenterAccount = useCRMutation(
	endpoint.deleteCenterAccount.key,
	'deleteCenterAccount',
);
