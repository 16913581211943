import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	box-sizing: border-box;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	margin-top: 1.6rem;
	gap: 2rem;
`;

export const Title = styled.p`
	${(props) => props.theme.typography.bodyB};
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.8rem;
`;

export const Form = styled.form``;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;
