import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import * as S from './styles';
import { integratedAssessmentDesireCognitiveStateQuestions } from './constant';
import IntegratedAssessmentDesireCognitiveStateFormHeader from './IntegratedAssessmentDesireCognitiveStateFormHeader';
import CRCheckBox from '../../../base/Selections/CRCheckBox';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesireCognitiveStateForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireCognitiveStateFormHeader />
				<S.TableBody>
					{integratedAssessmentDesireCognitiveStateQuestions.map((question, index) => (
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => (
								<S.TableBodyRow>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>{index + 1}</S.TableBodyColumn>
									<S.TableBodyColumn style={{ textAlign: 'center' }}>
										<S.TableContentContainer>
											{question.label}
											{!!question.answer.length && (
												<>
													(
													{question.answer?.map((option) => (
														<CRCheckBox
															checkBoxType='checkbox'
															checked={form.watch(question.key)?.includes(option.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), option.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== option.value),
																	);
																}
															}}>
															{option.label}
														</CRCheckBox>
													))}
													)
												</>
											)}
										</S.TableContentContainer>
										{form.watch(question.key)?.includes('기타') && (
											<S.TableEtcContainer>
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder='기타 사항 입력'
															onChange={onChange}
															value={value as string}
														/>
													)}
													name={`${question.key}기타` as keyof IntegratedAssessmentDesireForms}
													control={form.control}
												/>
											</S.TableEtcContainer>
										)}
									</S.TableBodyColumn>
									<S.TableBodyColumn>
										<CRCheckBox
											checked={form.watch(question.key)?.includes(question.key)}
											onChange={() => {
												const targetValue = (value as string[]) ?? [];
												if (targetValue.includes(question.key)) {
													onChange(targetValue.filter((item) => item !== question.key));
												} else {
													onChange([...targetValue, question.key]);
												}
											}}
										/>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							)}
							name={question.key}
							control={form.control}
						/>
					))}
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='인지상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireCognitiveStateForm;
