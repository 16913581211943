import styled from 'styled-components';

export const TableBodyRow = styled.tr`
	cursor: pointer;
	height: 5.7rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray00};
	white-space: nowrap;
	box-sizing: border-box;
	vertical-align: middle;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableBodyColumnWithPadding = styled(TableBodyColumn)`
	padding: 1.6rem;
	text-align: left;

	&:first-of-type {
		padding-left: 2.4rem;
	}

	&:last-of-type {
		padding-right: 2.4rem;
	}
`;
