import React, { CSSProperties, useMemo } from 'react';

interface Props {
	text: string;
	targetText: string;
	style?: CSSProperties;
	highlightStyle?: CSSProperties;
}

function Highlighter({ text, targetText, style, highlightStyle }: Props) {
	const splitText = useMemo(() => text.split(targetText), [text, targetText]);

	return (
		<span style={style}>
			{splitText.map((item, index) => {
				const key = `matching-text-highlight-${index}`;
				const shouldRenderLastText = index === splitText.length - 1;
				if (shouldRenderLastText) {
					return <span key={key}>{item}</span>;
				}
				return (
					<React.Fragment key={key}>
						<span>{item}</span>
						<span style={highlightStyle}>{targetText}</span>
					</React.Fragment>
				);
			})}
		</span>
	);
}

export default React.memo(Highlighter);
