import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';
import { useForm } from 'react-hook-form';

import CRPageHeader from 'components/base/CRPageHeader';
import CRPageFooter from 'components/base/CRPageFooter';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import IntegratedBedsoreRecipientInfoForm from 'components/domain/form/IntegratedBedsoreRecipientInfoForm';
import IntegratedBedsoreForm from 'components/domain/form/IntegratedBedsoreForm';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import IntegratedAssessmentPreviousDataDialog from 'components/domain/dialog/IntegratedAssessmentPreviousDataDialog';
import {
	useDeleteRecipientIntegratedAssessment,
	useMyAccountInfo,
	useRecipientIntegratedAssessmentUpdateForm,
	useUpdateRecipientIntegratedAssessment,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { flattenIntegratedAssessment, groupIntegratedAssessment } from 'lib/util/form';
import { IntegratedAssessmentBedsoreForms, IntegratedAssessmentType } from 'types/view/recipient';

import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api';
import useDialog from 'lib/hook/util/useDialog';
import * as S from './styles';
import { IntegratedAssessmentFormDTO } from '../../../types/dto';

function IntegratedAssessmentBedsoreInfoPage(): React.ReactElement {
	const { showDialog, setCustomBackHandler } = useGlobalLayout();
	const form = useForm<IntegratedAssessmentBedsoreForms>({
		defaultValues: {
			감각인지여부: 0,
			습기여부: 0,
			활동상태: 0,
			움직임: 0,
			영양상태: 0,
			마찰력과응전력: 0,
		},
	});
	const navigate = useNavigate();
	const params = useParams<{
		id: string;
		recipientIaId: string;
	}>();
	const { currentRecipient, currentRecipientInfoSummary } = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { hideDialog } = useDialog();

	const { data: previousData } = useRecipientIntegratedAssessmentUpdateForm({
		recipientIaId: params.recipientIaId ?? '',
		recipientId: params.id ?? '',
	});

	const deleteRecipientIntegratedAssessment = useDeleteRecipientIntegratedAssessment(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 삭제을 완료하였습니다.');
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessment.key,
					{
						recipientId: params.id,
						centerId: myAccountInfo?.centerId.toString(),
						targetYear: previousData?.targetYear,
					},
				]);
				hideDialog();
			} else {
				Toast.error('삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const updateRecipientIntegratedAssessment = useUpdateRecipientIntegratedAssessment(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 저장을 완료하였습니다.');
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessment.key,
					{
						recipientId: params.id,
						centerId: myAccountInfo?.centerId.toString(),
						targetYear: previousData?.targetYear,
					},
				]);
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessmentUpdateForm.key,
					{
						recipientIaId: params.recipientIaId ?? '',
						recipientId: params.id ?? '',
					},
				]);
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const handleClickDelete = async () => {
		if (!params.recipientIaId) return;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='문서 삭제'
				hideDialog={hideDialog}
				content='욕창위험도 문서를 삭제하시겠습니까?'
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: async () => {
						await deleteRecipientIntegratedAssessment.mutateAsync({
							recipientIaId: Number(params.recipientIaId),
						});
						navigate(-1);
					},
				}}
			/>
		));
	};

	const getContent = (
		formItem: IntegratedAssessmentBedsoreForms,
		previousData?: IntegratedAssessmentFormDTO | null,
	) =>
		flattenIntegratedAssessment(formItem, groupIntegratedAssessment(previousData?.content ?? {}), [
			'type',
			'longTermEndDate',
			'longTermGradeCd',
			'longTermStartDate',
			'longTermNo',
			'recipientBirthDt',
			'recipientGenderCd',
			'recipientNm',
			'writeDate',
			'writerNm',
			'욕창비고',
		]);

	const handleClickCancel = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;

		const formItem = form.getValues() as IntegratedAssessmentBedsoreForms;

		Reflect.deleteProperty(formItem, 'type');

		const content = getContent(formItem, previousData);

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='욕창위험도 문서에 변경된 정보를 저장합니다.'
				hideDialog={hideDialog}
				successOption={{
					text: '저장',
					successCallback: async () => {
						hideDialog();
						if (form.getValues('writerNm')?.trim().length < 2) {
							Toast.error('작성자를 올바르게 입력해주세요.');
							return;
						}
						const totalPoint =
							Number(form.getValues('활동상태')) +
							Number(form.getValues('마찰력과응전력')) +
							Number(form.getValues('습기여부')) +
							Number(form.getValues('움직임')) +
							Number(form.getValues('영양상태')) +
							Number(form.getValues('감각인지여부'));

						await updateRecipientIntegratedAssessment.mutateAsync({
							recipientIaId: Number(params.recipientIaId),
							evalDescTypeCd: form.getValues('type')?.[0].value,
							edulevelCd: '',
							height: 0,
							weight: 0,
							dementiaYn: false,
							specialDesc: form.getValues('욕창비고') ?? '',
							totalPoint,
							content,
							writerNm: form.getValues('writerNm'),
							writeDate: form.getValues('writeDate'),
						});

						navigate(-1);
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						navigate(-1);
					},
				}}
			/>
		));
	};

	const handleClickConfirm = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;
		if (form.getValues('writerNm')?.trim().length < 2) {
			Toast.error('작성자를 올바르게 입력해주세요.');
			return;
		}
		const formItem = form.getValues() as IntegratedAssessmentBedsoreForms;

		Reflect.deleteProperty(formItem, 'type');

		const content = getContent(formItem, previousData);

		const totalPoint =
			Number(form.getValues('활동상태')) +
			Number(form.getValues('마찰력과응전력')) +
			Number(form.getValues('습기여부')) +
			Number(form.getValues('움직임')) +
			Number(form.getValues('영양상태')) +
			Number(form.getValues('감각인지여부'));

		await updateRecipientIntegratedAssessment.mutateAsync({
			recipientIaId: Number(params.recipientIaId),
			evalDescTypeCd: form.getValues('type')?.[0].value,
			edulevelCd: '',
			height: 0,
			weight: 0,
			dementiaYn: false,
			specialDesc: form.getValues('욕창비고') ?? '',
			totalPoint,
			writerNm: form.getValues('writerNm'),
			writeDate: form.getValues('writeDate'),
			content,
		});

		navigate(-1);
	};

	const loadFormFromData = (item: IntegratedAssessmentFormDTO) => {
		if (item?.content) {
			form.reset(groupIntegratedAssessment(item?.content));
		}
		if (item?.specialDesc) {
			form.setValue('욕창비고', item.specialDesc);
		}

		if (previousData?.evalDescTypeCd) {
			form.setValue('type', [{ value: previousData.evalDescTypeCd, label: '' }]);
		}
		if (previousData?.writerNm) {
			form.setValue('writerNm', previousData.writerNm);
		}
		if (previousData?.writeDate) {
			form.setValue('writeDate', previousData.writeDate);
		}
		if (previousData?.recipientNm) {
			form.setValue('recipientNm', previousData.recipientNm);
		}
		if (previousData?.recipientBirthDt) {
			form.setValue('recipientBirthDt', previousData.recipientBirthDt);
		}
		if (previousData?.recipientGenderCd) {
			form.setValue('recipientGenderCd', previousData.recipientGenderCd);
		}
		if (previousData?.longTermStartDate) {
			form.setValue('longTermStartDate', previousData.longTermStartDate);
		}
		if (previousData?.longTermEndDate) {
			form.setValue('longTermEndDate', previousData.longTermEndDate);
		}
		if (previousData?.longTermGradeCd) {
			form.setValue('longTermGradeCd', previousData.longTermGradeCd);
		}
		if (previousData?.longTermNo) {
			form.setValue('longTermNo', previousData.longTermNo);
		}
	};

	const handleLoadPreviousData = (item: IntegratedAssessmentFormDTO) => {
		loadFormFromData(item);
	};

	const handleClickLoadPreviousData = async () => {
		showDialog(({ hideDialog }) => (
			<IntegratedAssessmentPreviousDataDialog
				title='욕창위험도 이력'
				hideDialog={hideDialog}
				recipientId={params.id}
				paperTypeCd={IntegratedAssessmentType.BedsoreInfo}
				onClickLoad={handleLoadPreviousData}
			/>
		));
	};

	const questions = [
		{
			id: `#1`,
			label: '수급자 정보',
			component: <IntegratedBedsoreRecipientInfoForm form={form} />,
		},
		{
			id: `#2`,
			label: '욕창위험도',
			component: <IntegratedBedsoreForm form={form} />,
		},
	];

	useFullScreen();

	useEffect(() => {
		if (!previousData) return;

		loadFormFromData(previousData);
	}, [previousData]);

	useEffect(() => {
		setCustomBackHandler(() => handleClickCancel);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, []);

	return (
		<S.MainComponentContainer>
			<S.BodyContainer>
				<S.ContentContainer>
					<S.HeaderComponentContainer>
						<CRPageHeader
							headerTitle='욕창위험도'
							buttons={[
								{
									palette: 'primary',
									buttonType: 'button',
									type: 'outlined',
									onClick: handleClickDelete,
									children: '삭제',
								},
							]}
						/>
					</S.HeaderComponentContainer>
					<Accordion.Root type='multiple' defaultValue={questions.map((form) => form.id)}>
						{questions.map((form) => (
							<Accordion.Item value={form.id} asChild>
								<TaskAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
									</Accordion.Content>
								</TaskAccordion.Item>
							</Accordion.Item>
						))}
					</Accordion.Root>
				</S.ContentContainer>
			</S.BodyContainer>
			<S.FooterContainer>
				<S.FooterContentContainer>
					<CRPageFooter
						leftButtons={[
							{
								palette: 'gray',
								buttonType: 'button',
								type: 'outlined',
								onClick: handleClickLoadPreviousData,
								children: '불러오기',
							},
						]}
						rightButtons={[
							{
								palette: 'gray',
								buttonType: 'button',
								type: 'text',
								onClick: handleClickCancel,
								children: '취소',
							},
							{
								palette: 'primary',
								buttonType: 'button',
								type: 'filled',
								onClick: handleClickConfirm,
								children: '저장',
							},
						]}
					/>
				</S.FooterContentContainer>
			</S.FooterContainer>
		</S.MainComponentContainer>
	);
}

export default IntegratedAssessmentBedsoreInfoPage;
