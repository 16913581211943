import { styled } from 'styled-components';

export const Container = styled.div`
	width: 25.5rem;
	padding-top: 1.6rem;
	box-sizing: border-box;
`;

export const Count = styled.p`
	${(props) => props.theme.typography.labelB};
	display: flex;
	align-items: center;
	height: 2.5rem;
	color: ${(props) => props.theme.colors.gray10};
	padding: 0 2.4rem;
`;

export const TargetContainer = styled.ul`
	max-height: 36rem;
	overflow-y: scroll;
`;

export const TargetItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 4.8rem;
	padding: 0 2.4rem;
	box-sizing: border-box;

	& > span {
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray60};
	}
`;
