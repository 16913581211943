import styled, { css } from 'styled-components';

// const checkedContainerCSS = css`
// 	background: ${(props) => props.theme.colors.primary95};
// 	border: 0.1rem solid ${(props) => props.theme.colors.primary90};
// 	border-radius: ${(props) => props.theme.radius.radius01};
// `;

// const unCheckedContainerCSS = css`
// 	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
// 	border-radius: ${(props) => props.theme.radius.radius01};
// `;

export const Container = styled.ul<{ checked?: boolean }>`
	display: flex;
	flex-direction: column;
`;

export const Section = styled.li`
	display: flex;
	flex-direction: row;
`;

export const CheckIconContainer = styled.div`
	display: flex;
	position: absolute;
	width: 2.8rem;
	height: 2.8rem;
	align-items: center;
	justify-content: center;
`;

export const CheckIcon = styled.img<{ $isActive?: boolean; $isDone?: boolean }>`
	width: 1.6rem;
	height: 1.6rem;
	margin-left: 0.16rem;

	${(props) => {
		if (props.$isDone) {
			return css`
				filter: invert(81%) sepia(25%) saturate(601%) hue-rotate(90deg) brightness(83%)
					contrast(95%);
			`;
		}

		if (props.$isActive) {
			return css`
				filter: invert(100%) sepia(15%) saturate(7441%) hue-rotate(169deg) brightness(119%)
					contrast(116%);
			`;
		}

		return css`
			filter: invert(66%) sepia(0%) saturate(827%) hue-rotate(246deg) brightness(87%) contrast(90%);
		`;
	}}
`;

export const DropDownIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const Line = styled.div<{ $isFirst?: boolean; $isLast?: boolean; $isActive?: boolean }>`
	flex: 1;
	width: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};

	${(props) => {
		if (props.$isActive) {
			return css`
				width: 0rem;
				height: 0rem;
			`;
		}
		if (props.$isFirst && props.$isLast) {
			return css`
				width: 0rem;
				height: 0rem;
			`;
		}
		if (props.$isFirst) {
			return css`
				margin-top: 2rem;
			`;
		}
		if (props.$isLast) {
			return css`
				height: 2rem;
				max-height: 2rem;
				margin-bottom: 2rem;
			`;
		}

		return '';
	}}
`;

export const CheckLineContainer = styled.div`
	width: 2.4rem;
	min-width: 2.4rem;
	height: 4rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 1.2rem;
`;

export const SectionContentContainer = styled.span`
	flex: 1;
	width: 1.6rem;
`;

export const SectionContainer = styled.ul`
	box-sizing: border-box;
`;

export const SectionIndexLabel = styled.span`
	${(props) => props.theme.typography.labelB}
	color: ${(props) => props.theme.colors.gray10};
	width: 2.8rem;
	text-align: center;
	margin-right: 0.8rem;
`;

export const SectionLabelItem = styled.span`
	flex: 1;
	${(props) => props.theme.typography.labelB}
	color: ${(props) => props.theme.colors.gray10};
`;

export const SubSectionLabelItem = styled.span`
	flex: 1;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;

export const ItemContainer = styled.div<{ $isActive?: boolean }>`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0rem 1.6rem;
	box-sizing: border-box;
	height: 4rem;
	align-items: center;
	border-radius: ${(props) => props.theme.radius.radius01};
	${(props) =>
		props.$isActive &&
		css`
			background-color: ${props.theme.colors.greenLighten};
		`}
`;

export const SectionItem = styled.li<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.label}
	cursor: pointer;
	color: ${(props) => props.theme.colors.gray10};

	${(props) =>
		props.$isActive &&
		css`
			color: ${props.theme.colors.greenDarken};
		`};
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background-color: ${(props) => props.theme.colors.gray90};
	margin: 1.6rem 0rem;
`;
