import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import ConsultingForm from 'components/domain/form/ConsultingForm';
import CRButton from 'components/base/CRButton';
import { Toast } from 'components/base/CRToast';
import { useAdvice, useMyAccountInfo, useUpdateAdvice } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { AddAdviceFormType } from 'types/view/advice';

import * as S from './styles';

function ConsultingEditPage(): React.ReactElement {
	const navigate = useNavigate();
	const params = useParams<{ adviceId: string; id: string }>();
	const { data: myAccountInfo } = useMyAccountInfo();
	const updateAdviceMutation = useUpdateAdvice((client) => {
		client.invalidateQueries([
			endpoint.getRecipientAdvices.key,
			{
				recipientId: params.id,
			},
		]);
		client.invalidateQueries([
			endpoint.getRecipientAdvice.key,
			{
				adviceId: Number.parseInt(params.adviceId ?? '0', 10),
			},
		]);
		client.invalidateQueries([endpoint.getEmployeeAdvices.key]);
		client.invalidateQueries([
			endpoint.getAdvice.key,
			{
				adviceId: Number.parseInt(params.adviceId ?? '0', 10),
			},
		]);
	});

	const { data } = useAdvice({
		adviceId: Number.parseInt(params.adviceId ?? '0', 10),
	});

	const [form, setForm] = useState<AddAdviceFormType | null>();

	const disabled = useMemo(
		() =>
			!(
				form &&
				form.date?.date &&
				form.date.start &&
				form.client &&
				form.method &&
				form.tag &&
				form.advisorOpinion &&
				form.managerOpinion
			),
		[form],
	);

	const handleChangeForm = (form: AddAdviceFormType) => {
		setForm(form);
	};

	const handleSubmitAdvice = async () => {
		if (!myAccountInfo) return;
		if (!form) return;
		if (!data) return;

		const date = form.date?.date ?? '00000000';
		const start = form.date?.start ?? '00:00';

		await updateAdviceMutation.mutateAsync({
			consultId: Number.parseInt(params.adviceId ?? '0', 10),
			consultDate: dayjs(
				`${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)} ${start}`,
			).format('YYYY-MM-DDTHH:mm:ss'),
			consultMethodCd: form.method?.value,
			attachFile: {
				...data.attachFile,
				fileDetails: form.files ?? [],
			},
			consultDesc: form.advisorOpinion ?? '',
			consultActionDesc: form.managerOpinion ?? '',
			consultTagId: Number(form.tag?.value) ?? 0,
		});
		Toast.success('정상적으로 상담이 등록 되었습니다.');
		navigate(-1);
	};

	useEffect(() => {
		if (!data) return;

		setForm({
			date: {
				date: dayjs(data.consultDate).format('YYYYMMDD'),
				start: dayjs(data.consultDate).format('HH:mm'),
			},
			client: {
				label: '',
				value: Number.parseInt(params.id ?? '0', 10),
			},
			method: {
				label: '',
				value: data.consultMethodCd,
			},
			tag: {
				label: '',
				value: data.consultTagId,
			},
			advisorOpinion: data.consultDesc,
			managerOpinion: data.consultActionDesc,
			files: data.attachFile?.fileDetails,
		});
	}, [data]);

	if (!data) {
		navigate(-1);
		return <div />;
	}

	if (!form) return <div />;

	return (
		<S.Container>
			<S.BodyContainer>
				<S.ContentContainer>
					<S.ContentHeaderContainer>
						<S.Title>상담 편집</S.Title>
					</S.ContentHeaderContainer>
					<S.ContentBodyContainer>
						<ConsultingForm form={form} onChange={handleChangeForm} editMode />
					</S.ContentBodyContainer>
				</S.ContentContainer>
			</S.BodyContainer>
			<S.FooterContainer>
				<S.ButtonContainer>
					<CRButton.Default palette='gray' type='tonal' onClick={() => navigate(-1)}>
						취소
					</CRButton.Default>
					<CRButton.Default disabled={disabled} onClick={handleSubmitAdvice}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			</S.FooterContainer>
		</S.Container>
	);
}

export default ConsultingEditPage;
