import { IntegratedAssessmentDesirePhysicalConditionQuestion } from 'types/view/recipient';

export const integratedAssessmentDesirePhysicalConditionQuestions: IntegratedAssessmentDesirePhysicalConditionQuestion[][] =
	[
		[
			{ key: '체위변경하기', label: '체위 변경하기' },
			{ key: '식사하기', label: '식사하기' },
			{ key: '몸단장하기', label: '몸단장하기' },
		],
		[
			{ key: '일어나앉기', label: '일어나앉기' },
			{ key: '세수하기', label: '세수하기' },
			{ key: '식사준비', label: '식사 준비' },
		],
		[
			{ key: '일어서기', label: '일어서기' },
			{ key: '양치질', label: '양치질' },
			{ key: '청소하기', label: '청소하기' },
		],

		[
			{ key: '이동', label: '이동' },
			{ key: '옷벗고입기', label: '옷 벗고 입기' },
			{ key: '빨래하기', label: '빨래하기' },
		],
		[
			{ key: '실내보행', label: '실내 보행' },
			{ key: '화장실사용', label: '화장실 사용' },
			{ key: '약챙겨먹기', label: '약 챙겨먹기' },
		],
		[
			{ key: '휠체어이동', label: '휠체어 이동' },
			{ key: '기저귀갈기', label: '기저귀 갈기' },
			{ key: '전화사용', label: '전화 사용하기' },
		],
		[
			{ key: '근거리외출', label: '근거리 외출' },
			{ key: '목욕하기', label: '목욕하기' },
			{ key: '교통수단이용', label: '교통수단 이용' },
		],
	];
