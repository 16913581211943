import styled from 'styled-components';

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-top: 0.1rem solid ${(p) => p.theme.colors.gray90};
	gap: 1.6rem;
	width: 100%;
	height: 100%;
`;

export const ScrollContainer = styled.div`
	padding: 2.4rem;
	height: 58rem;
	overflow: auto;
`;

export const TotalPriceContainer = styled.div`
	border-top: 0.1rem solid ${(p) => p.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(p) => p.theme.colors.gray90};
	background-color: ${(p) => p.theme.colors.gray99};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6rem 2.4rem;
	flex: 1;
`;
