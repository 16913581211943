import React, { useCallback, useEffect, useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';

import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { InsuranceAcquirementFormType } from 'types/view/insurance';
import InformationTable from 'components/ui/InformationTable';
import dayjs from 'dayjs';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRButton from 'components/base/CRButton';
import Assets from 'assets';
import { useInsurancePriorInfo } from 'lib/hook/react-query/query/insurance';
import { useParams } from 'react-router-dom';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { calculateKoreanAge, rsdnNumberToBirthDay } from 'lib';
import { Toast } from 'components/base/CRToast';
import { InsurancePriorInfoDTO } from 'types/api/insurance';
import { CRText } from 'components/base/CRText';
import * as S from './styles';
import { InsuranceType } from '..';

interface Props {
	context: UseFormReturn<InsuranceAcquirementFormType, any, undefined>;
	rsdnNumber?: string;
	requestTypeCd?: string;
}

export default function InsuranceAcquirementForm({ context, requestTypeCd, rsdnNumber }: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const params = useParams<{ id: string }>();
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN012', 'CMN072'],
		},
		commonCodeAdapter,
	);

	const formData = context.watch();

	const { insuranceType, employInsuranceRequestDate } = formData;

	const { data: insurancePriorInfo } = useInsurancePriorInfo({
		employeeId: Number(params.id),
		centerId: myAccountInfo?.centerId,
		insuranceTypeCd: requestTypeCd,
	});

	const isOver65 = useMemo(
		() => calculateKoreanAge(rsdnNumberToBirthDay(rsdnNumber), employInsuranceRequestDate) > 64,
		[rsdnNumber, employInsuranceRequestDate],
	);

	const { fields, append, remove } = useFieldArray({
		control: context.control,
		name: 'dependents',
	});

	const handleRemoveItem = (index: number) => () => {
		remove(index);
	};

	const handleAddItem = () => {
		append({
			dependentNm: '',
			dependentRsdnNo: '',
			dependentRelCd: undefined,
			dependentRegistDt: '',
			isForeign: [],
			dependentCountryCd: undefined,
			dependentStayStartDt: '',
			dependentStayEndDt: '',
		});
	};

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '12rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '신고 금액',
					labelStyle: {
						width: '16rem',
						textAlign: 'right',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '취득 일자',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						textAlign: 'right',
						verticalAlign: 'middle',
					},
				},
			],
		];

		if (insurancePriorInfo?.employInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.EMPLOY,
											value: InsuranceType.EMPLOY,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='employInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)}
									placeholder='취득 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
								/>
							)}
							name='employInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.accidentInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.ACCIDENT,
											value: InsuranceType.ACCIDENT,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='accidentInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)}
									placeholder='취득 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
								/>
							)}
							name='accidentInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.pensionInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.PENSION,
											value: InsuranceType.PENSION,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.PENSION)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='pensionInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.PENSION)}
									placeholder='취득 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
								/>
							)}
							name='pensionInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.healthInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.HEALTH,
											value: InsuranceType.HEALTH,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.HEALTH)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='healthInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.HEALTH)}
									placeholder='취득 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
								/>
							)}
							name='healthInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		return items;
	}, [insurancePriorInfo, insuranceType, context]);

	// 실업급여 납부 노출여부
	const renderUnemploymentBenefit = () =>
		isOver65 && !!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY) ? (
			<CRInputLabel label='실업급여 납부' isRequired type='left-sub' betweenGap='2.4rem'>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRCheckBoxGroup
							style={{
								width: 'auto',
							}}
							checkType='single'
							type='radio'
							onChange={onChange}
							appearanceType='button'
							gap={0.8}
							value={value}
							options={[
								{
									label: '납부하지 않음',
									value: false,
								},
								{
									label: '납부함',
									value: true,
								},
							]}
						/>
					)}
					name='unemploymentBenefitPreconditionFullYn'
					control={context.control}
				/>
			</CRInputLabel>
		) : null;

	const isInsuranceInfoEmpty = insurancePriorInfo
		? (
				[
					'accidentInsurancePosbYn',
					'employInsurancePosbYn',
					'healthInsurancePosbYn',
					'pensionInsurancePosbYn',
				] as (keyof InsurancePriorInfoDTO)[]
		  ).every((key) => insurancePriorInfo[key] === false)
		: false;

	useEffect(() => {
		if (rsdnNumber) {
			context.setValue('birthDate', rsdnNumberToBirthDay(rsdnNumber));
		}
	}, [rsdnNumber]);

	useEffect(() => {
		if (rsdnNumber && employInsuranceRequestDate) {
			const isExistEmploy = !!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY);
			if (isExistEmploy) {
				context.setValue('unemploymentBenefitPreconditionFullYn', [
					isOver65
						? {
								label: '납부하지않음',
								value: false,
						  }
						: {
								label: '납부함',
								value: true,
						  },
				]);
			}
		}
	}, [rsdnNumber, employInsuranceRequestDate, isOver65]);

	useEffect(() => {
		// 에러객체 감지 시점의 문제로 토스트 띄우는 시점이 이상해져서 싼값에 처리
		const pensionInsuranceError = context?.formState?.errors?.pensionInsuranceRequestDate;
		if (pensionInsuranceError?.type === 'over60') {
			Toast.error(pensionInsuranceError.message);
			context.setError('pensionInsuranceRequestDate', {
				message: '만 60세 이상은 국민연금을 취득할 수 없습니다.',
				type: 'over60Checked',
			});
		}
	}, [context?.formState?.errors?.pensionInsuranceRequestDate?.type]);

	useEffect(() => {
		// 체크값 바뀔때 관련 일자 및 신고 금액 초기화
		const isCheckAccident = !!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT);
		const isCheckEmploy = !!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY);
		const isCheckHealth = !!insuranceType?.find((item) => item.value === InsuranceType.HEALTH);
		const isCheckPension = !!insuranceType?.find((item) => item.value === InsuranceType.PENSION);

		if (!isCheckAccident) {
			context.setValue('accidentInsuranceRequestDate', '');
			context.setValue('accidentInsuranceRequestAmt', '');
		}
		if (!isCheckEmploy) {
			context.setValue('employInsuranceRequestDate', '');
			context.setValue('employInsuranceRequestAmt', '');
		}
		if (!isCheckHealth) {
			context.setValue('healthInsuranceRequestDate', '');
			context.setValue('healthInsuranceRequestAmt', '');
		}
		if (!isCheckPension) {
			context.setValue('pensionInsuranceRequestDate', '');
			context.setValue('pensionInsuranceRequestAmt', '');
		}
	}, [insuranceType]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='신고 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									{isInsuranceInfoEmpty ? (
										<S.EmptyContainer>
											<CRText
												typography='labelB'
												color='gray60'
												text='신고 가능한 보험이 없습니다.'
											/>
										</S.EmptyContainer>
									) : (
										<InformationTable roundBorder items={informationTableItems} />
									)}
								</CRInputLabel>
								{renderUnemploymentBenefit()}
								{!!insuranceType?.find((item) =>
									[InsuranceType.EMPLOY, InsuranceType.ACCIDENT].includes(item.value),
								) && (
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInputLabel
												label='주 소정근로시간'
												type='left-sub'
												isRequired={
													!!insuranceType?.find((item) =>
														[InsuranceType.EMPLOY, InsuranceType.ACCIDENT].includes(item.value),
													)
												}
												showOverflow
												betweenGap='2.4rem'>
												<CRInput.Default
													suffix='시간'
													type='number'
													onChange={onChange}
													value={String(value)}
													placeholder='시간 입력'
												/>
											</CRInputLabel>
										)}
										name='weekPerWorkHourCnt'
										control={context.control}
									/>
								)}

								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='요청 사항'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.TextArea
												disabled={isInsuranceInfoEmpty}
												numberOfLines={4}
												onChange={onChange}
												value={value}
												placeholder='요청 사항 입력'
												fixedHeight
											/>
										</CRInputLabel>
									)}
									name='requestDesc'
									control={context.control}
								/>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='파일 첨부'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.FileUploader
												disabled={isInsuranceInfoEmpty}
												type='multiple'
												onChange={onChange}
												files={value}
												placeholder='파일을 끌어오거나 업로드'
											/>
										</CRInputLabel>
									)}
									name='attachFile'
									control={context.control}
								/>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='피부양자' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>피부양자</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.TableContainer>
								{fields?.map((formItem, index) => (
									<S.TableItem key={formItem.id}>
										<S.ItemHeader>
											{`피부양자 정보 ${index + 1 < 10 ? `0${index + 1}` : index + 1}`}
											<S.DeleteButtonContainer>
												<CRButton.Default
													type='outlined'
													size='xSmall'
													onClick={handleRemoveItem(index)}>
													삭제
												</CRButton.Default>
											</S.DeleteButtonContainer>
										</S.ItemHeader>
										<S.Table>
											<S.TableRow>
												<S.TableLabelColumn>
													이름 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<Controller
														render={({ field: { onBlur, ref, onChange, value } }) => (
															<CRInput.TableInput
																ref={ref}
																onBlur={onBlur}
																onChange={onChange}
																value={value}
																placeholder='이름 입력'
															/>
														)}
														name={`dependents.${index}.dependentNm`}
														control={context.control}
													/>
												</S.TableValueColumn>
												<S.TableLabelColumn>
													등록일 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<Controller
														render={({ field: { onChange, value } }) => (
															<CRInput.DatePicker
																popupPosition={{
																	x: 0,
																	y: -150,
																}}
																placeholder='등록일 선택'
																type='S'
																containerStyle={{ width: '100%' }}
																value={value ? dayjs(value).toDate() : undefined}
																onChangeValue={(date) =>
																	onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))
																}
															/>
														)}
														name={`dependents.${index}.dependentRegistDt`}
														control={context.control}
													/>
												</S.TableValueColumn>
											</S.TableRow>
											<S.TableRow>
												<S.TableLabelColumn>
													관계 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<Controller
														render={({ field: { onChange, value } }) => (
															<CRInput.Selector
																type='small'
																items={commonCodes?.CMN072}
																currentValue={value}
																onChangeValue={onChange}
																placeholder='관계 선택'
															/>
														)}
														name={`dependents.${index}.dependentRelCd`}
														control={context.control}
													/>
												</S.TableValueColumn>
												<S.TableLabelColumn>
													국적 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<Controller
														render={({ field: { value, ref, onChange } }) => (
															<CRCheckBoxGroup
																ref={ref}
																checkType='single'
																onChange={onChange}
																type='radio'
																gap={0.8}
																value={value}
																options={[
																	{
																		label: '국내',
																		value: false,
																	},
																	{
																		label: '해외',
																		value: true,
																	},
																]}
															/>
														)}
														name={`dependents.${index}.isForeign`}
														control={context.control}
													/>
												</S.TableValueColumn>
											</S.TableRow>
											<S.TableRow>
												<S.TableLabelColumn>
													주민등록번호 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<Controller
														render={({ field: { onBlur, ref, onChange, value } }) => (
															<CRInput.TableInput
																maxLength={13}
																type='number'
																ref={ref}
																onBlur={onBlur}
																onChange={onChange}
																value={value}
																placeholder='주민등록번호 입력'
															/>
														)}
														name={`dependents.${index}.dependentRsdnNo`}
														control={context.control}
													/>
												</S.TableValueColumn>
												{formData.dependents[index].isForeign?.[0]?.value === true ? (
													<>
														<S.TableLabelColumn>
															국가 <S.RequiredMark>*</S.RequiredMark>
														</S.TableLabelColumn>
														<S.TableValueColumn>
															<Controller
																render={({ field: { onChange, value } }) => (
																	<CRInput.Selector
																		type='small'
																		items={commonCodes?.CMN012}
																		currentValue={value}
																		onChangeValue={onChange}
																	/>
																)}
																name={`dependents.${index}.dependentCountryCd`}
																control={context.control}
															/>
														</S.TableValueColumn>{' '}
													</>
												) : (
													<S.TableLabelColumn colSpan={2} />
												)}
											</S.TableRow>
											{formData.dependents[index].isForeign?.[0]?.value === true && (
												<S.TableRow>
													<S.TableLabelColumn colSpan={2} />
													<S.TableLabelColumn>
														체류 기간 <S.RequiredMark>*</S.RequiredMark>
													</S.TableLabelColumn>
													<S.TableValueColumn
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '0.4rem',
														}}>
														<Controller
															render={({ field: { onChange, value } }) => (
																<CRInput.DatePicker
																	popupPosition={{
																		x: 0,
																		y: -200,
																	}}
																	placeholder='시작일 선택'
																	type='S'
																	containerStyle={{ flex: 1 }}
																	value={value ? dayjs(value).toDate() : undefined}
																	onChangeValue={(date) =>
																		onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))
																	}
																/>
															)}
															name={`dependents.${index}.dependentStayStartDt`}
															control={context.control}
														/>
														<div style={{}}>-</div>
														<Controller
															render={({ field: { onChange, value } }) => (
																<CRInput.DatePicker
																	popupPosition={{
																		x: -200,
																		y: -200,
																	}}
																	placeholder='종료일 선택'
																	type='S'
																	containerStyle={{ flex: 1 }}
																	value={value ? dayjs(value).toDate() : undefined}
																	onChangeValue={(date) =>
																		onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))
																	}
																/>
															)}
															name={`dependents.${index}.dependentStayEndDt`}
															control={context.control}
														/>
													</S.TableValueColumn>
												</S.TableRow>
											)}
										</S.Table>
									</S.TableItem>
								))}
								<CRButton.IconButton
									disabled={isInsuranceInfoEmpty}
									onClick={handleAddItem}
									iconLeft={Assets.icon.add}
									palette='gray'
									type='outlined'>
									피부양자 추가
								</CRButton.IconButton>
							</S.TableContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
