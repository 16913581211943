const Colors = {
	primary00: '#000000',
	primary10: '#40000C',
	primary20: '#680019',
	primary30: '#920027',
	primary40: '#BE0036',
	primary50: '#E3294C',
	primary60: '#FF5168',
	primary70: '#FF8890',
	primary80: '#FFB3B6',
	primary90: '#FFDADA',
	primary95: '#FFEDEC',
	primary98: '#FFF8F7',
	primary99: '#FFFBFB',
	primary100: '#FFFFFF',
	gray00: '#000000',
	gray10: '#191C1D',
	gray20: '#2E3132',
	gray30: '#444748',
	gray40: '#5C5F5F',
	gray50: '#747878',
	gray60: '#8E9192',
	gray70: '#A9ACAC',
	gray80: '#C4C7C7',
	gray90: '#E1E3E3',
	gray95: '#EFF1F1',
	gray99: '#FAFAFA',
	gray100: '#FFFFFF',

	grayLighten: '#E1E3E3',
	grayDarken: '#2E3132',
	blue: '#458EFF',
	blueDarken: '#1D3C6D',
	blueLighten: '#C9DEFF',
	green: '#6DC993',
	greenDarken: '#2E563F',
	greenLighten: '#D5EFE0',
	yellow: '#FFC834',
	yellowLighten: '#FFEFC5',
	yellowDarken: '#6D5516',
	red: '#FF5168',
	redLighten: '#FFDADA',
	redDarken: '#680019',
	white: '#FFFFFF',
	gray: '#8E9192',
};

export default Colors;
