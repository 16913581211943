import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import * as S from './styles';

function MyCenterUsageInformationView(): React.ReactElement {
	const { form } = useCenterInfo();
	return (
		<S.Container>
			<CRInputLabel type='left' label='W4C 시설 코드'>
				<CRInput.Default
					placeholder='W4C 시설 코드 입력'
					disabled
					value={form.getValues('w4cCodeValue')}
				/>
			</CRInputLabel>
			<CRInputLabel type='left' label='API Name'>
				<CRInput.Default
					placeholder='API Name 입력'
					disabled
					value={form.getValues('centerApiNm')}
				/>
			</CRInputLabel>
		</S.Container>
	);
}

export default MyCenterUsageInformationView;
