import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const Title = styled.p`
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const ContentContainer = styled.form`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2.4rem;
	box-sizing: border-box;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const ContentItem = styled.div``;

export const DocumentInfo = styled.div`
	position: relative;
`;

export const DocumentId = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
`;

export const DocumentTitle = styled.h4`
	${(props) => props.theme.typography.h4};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Author = styled.div`
	display: flex;
	gap: 0.7rem;
	align-items: center;
	margin-top: 1.8rem;
	user-select: all;

	& span {
		${(props) => props.theme.typography.body};
		color: ${(props) => props.theme.colors.gray00};
	}
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const DocumentContent = styled.div`
	min-height: 33rem;
	user-select: all;
`;

export const RelatedTask = styled.div<{ $hasTask?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${(props) => props.theme.colors.gray99};
	height: 5.6rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0 1.6rem;
	border-radius: 0.8rem;

	${(props) =>
		props.$hasTask &&
		css`
			cursor: pointer;
		`};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const GoToRelatedTaskPageIcon = styled(Icon)`
	filter: brightness(0) saturate(100%) invert(70%) sepia(7%) saturate(114%) hue-rotate(149deg)
		brightness(81%) contrast(87%);
`;

export const RelatedTaskLeft = styled.div`
	display: flex;
	gap: 0.8rem;

	& > span {
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray00};
	}
`;

export const DownloadPDF = styled.a`
	position: absolute;
	top: 0;
	right: 0;
`;

export const LastUpdate = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	margin-top: 0.4rem;
`;
