import { styled } from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	width: 57.2rem;
`;

export const SwitchContainer = styled.div`
	display: flex;
	height: 5.8rem;
	align-items: center;
`;

export const ResultSelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const ResultSelectItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const ResultLabel = styled.div`
	${(props) => props.theme.typography.body};
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;
