import React, { useEffect } from 'react';
import { useOpenEDocClient, useOpenEDocManager } from 'lib/hook/react-query';
import { useParams } from 'react-router-dom';
import EDocClientAuthForm from 'components/ui/EDoc/EDocClientAuthForm';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api/base';
import { ESignStateCd } from 'types/api';

interface Props {
	onSuccess: () => void;
	onAlreadyConfirmed: () => void;
}

export const RecordWriteFormList = ['CMN119.0052', 'CMN119.0053', 'CMN119.0054', 'CMN119.0066'];
function ESignInfoAuthStep({ onSuccess, onAlreadyConfirmed }: Props) {
	const param = useParams();
	const { setAuthInfo, setEDocInfo, isErp } = useEDocClientPage();
	const { mutateAsync: openEdoc, isLoading: isOpeningEDoc } = isErp
		? useOpenEDocManager()
		: useOpenEDocClient();

	const handleOpenEDdc = async ({ birthDt }: { birthDt: string }) => {
		if (isOpeningEDoc) return;
		if (!param.id) {
			Toast.error('본인확인에 실패했습니다.');
			return;
		}
		const authParams = { birthDt, sendUuid: param.id, isEsign: true };

		const res = await openEdoc(authParams);
		if (res?.code !== ResponseCode.SUCCESS) {
			Toast.error(res?.message);
			return;
		}

		// 기록지는 제출 완료 후 다시 열람하거나 할 수 없음.
		// 완료 후 재발송 되는 경우에는 ESignStateCd는 완료상태이기 때문에 제출 완료로 이동되는 점 유의.
		if (
			res.data.esignStateCd !== ESignStateCd.OPENED &&
			RecordWriteFormList.includes(res.data.edocPreviewResponse.paperTypeCd)
		) {
			onAlreadyConfirmed();
		} else {
			onSuccess();
		}
		setAuthInfo(authParams);
		setEDocInfo(res.data);
	};

	useEffect(() => {
		if (isErp) {
			handleOpenEDdc({ birthDt: '' });
		}
	}, [isErp]);

	if (isErp) {
		return null;
	}

	return <EDocClientAuthForm onSubmit={handleOpenEDdc} />;
}

export default ESignInfoAuthStep;
