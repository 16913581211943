import { RecipientBurdenDTO } from '../dto';
import { BaseResponse } from './base';

export interface RecipientCheckDuplicateRequest {
	name: string;
	rsdnNo: string;
	centerId: number;
}

export interface RecipientCheckDuplicateDTO {
	isDuplicated: boolean;
}

export type RecipientCheckDuplicateResponse = BaseResponse<RecipientCheckDuplicateDTO>;

export type GetRecipientBurdensRequest = {
	centerId?: number;
	recipientId?: number;
	serviceTypeCds?: string[];
};
export type GetRecipientBurdensData = RecipientBurdenDTO;
export type GetRecipientBurdensResponse = BaseResponse<GetRecipientBurdensData>;

export interface ResignRecipientRequest {
	centerId: number;
	retirementDate: string;
	serviceStartDate: string;
	serviceChangeDescCd: string;
	serviceChangeEtcDesc?: string;
	serviceChangeDesc: string;
	pcorpSalaryContractCancelYn: boolean;
}

export interface ResignRecipientDTO {
	recipientId: number;
}

export type ResignRecipientResponse = BaseResponse<ResignRecipientDTO>;

export interface RecipientServiceRequest {
	centerId?: number;
	recipientId?: number;
}

export interface RecipientServiceDTO {
	centerId: number;
	centerNm: string;
	recipientId: number;
	employeeId: number;
	employeeNm: string;
	managerId: number;
	serviceTypeCd: string;
	serviceTypeCdNm: string;
	serviceStateCd: string;
	serviceContractId: number;
	serviceContractDetailId: number;
	serviceContractProviderId: number;
	serviceStartDate: string;
}

export type RecipientServiceResponse = BaseResponse<RecipientServiceDTO[]>;

export type CheckLongTermDuplicationRequest = {
	longTermNo: string;
	longTermMajorChangeNo: string;
	longTermMinorChangeNo: string;
};
export type CheckLongTermDuplicationData = { isLongTermNoDuplicated: boolean };

export type CheckLongTermDuplicationResponse = BaseResponse<CheckLongTermDuplicationData>;

export interface UpdateScheduleAutomationRequestItem {
	centerId?: number;
	serviceKindCd?: string;
	recipientId: number;
	employeeId1?: number;
	employeeId2?: number;
	serviceStartTime?: string;
	serviceEndTime?: string;
	managerId?: number; // 프로그램 관리자
	monthUseCnt?: number; // 월 이용횟수
	monthAmt?: number; // 월금액
	holidayExceptYn: boolean;
	monthAmtOverYn: boolean;
	totalDayYn: boolean;
	automationFeeCd?: string;
	remark?: string;
	scheduleAutomationDays?: {
		scheduleAutomationDayCd: string;
	}[];
	scheduleAutomationId?: number;
}

export interface UpdateScheduleAutomationRequest {
	params: UpdateScheduleAutomationRequestItem[];
	centerId: number;
	recipientId: number;
}

export interface UpdateScheduleAutomationDTO {
	recipientId: number;
}

export type UpdateScheduleAutomationResponse = BaseResponse<UpdateScheduleAutomationDTO>;

export enum RecipientAffiliationType {
	RSDN_NO = 'RSDN_NO',
	BIRTH_DT = 'BIRTH_DT',
	NAME = 'NAME',
	LONGTERM_NO = 'LONGTERM_NO',
	DELETE = 'DELETE',
	GET = 'GET',
}

export interface UpdateRecipientAffiliationRequest {
	targetId: number;
	centerId: number;
	target: RecipientAffiliationType;
	updateValue?: string;
}

export interface UpdateRecipientAffiliationDTO {
	recipientId: number;
	centerId: number;
	longTermUsePlanDetail: {
		value: 'string';
		count: number;
	};
	longTermUsePlanWelfare: {
		value: 'string';
		count: number;
	};
	longTermUsePlan: {
		value: 'string';
		count: number;
	};
	salaryServiceSchedule: {
		value: 'string';
		count: number;
	};
	salaryOfferPlan: {
		value: 'string';
		count: number;
	};
	recordingSheet: {
		value: 'string';
		count: number;
	};
	recordingSheetSendMapping: {
		value: 'string';
		count: number;
	};
	burdenAmtDeduct: {
		value: 'string';
		count: number;
	};
	burdenAmtCharge: {
		value: 'string';
		count: number;
	};
	burdenRateHist: {
		value: 'string';
		count: number;
	};
	consult: {
		value: 'string';
		count: number;
	};
	serviceContract: {
		value: 'string';
		count: number;
	};
	serviceGuardian: {
		value: 'string';
		count: number;
	};
	serviceUseDay: {
		value: 'string';
		count: number;
	};
	serviceUseTime: {
		value: 'string';
		count: number;
	};
	serviceContractProviderMatching: {
		value: 'string';
		count: number;
	};
	serviceContractDetail: {
		value: 'string';
		count: number;
	};
	serviceContractProvider: {
		value: 'string';
		count: number;
	};
	recipientContractWorkDay: {
		value: 'string';
		count: number;
	};
	recipientContractWorkTime: {
		value: 'string';
		count: number;
	};
	recipientContract: {
		value: 'string';
		count: number;
	};
	employeeContractWorkDay: {
		value: 'string';
		count: number;
	};
	employeeContractWorkTime: {
		value: 'string';
		count: number;
	};
	employeeContract: {
		value: 'string';
		count: number;
	};
	servicePcorpCharge: {
		value: 'string';
		count: number;
	};
	serviceSchedulePlan: {
		value: 'string';
		count: number;
	};
	recipientManagerHist: {
		value: 'string';
		count: number;
	};
	recipientChangeHist: {
		value: 'string';
		count: number;
	};
	recipientPaper: {
		value: 'string';
		count: number;
	};
	recipientIa: {
		value: 'string';
		count: number;
	};
	longTermGradeHist: {
		value: 'string';
		count: number;
	};
	scheduleCreation: {
		value: 'string';
		count: number;
	};
	otherServiceUse: {
		value: 'string';
		count: number;
	};
	otherServiceUseTime: {
		value: 'string';
		count: number;
	};
	otherServiceUseDay: {
		value: 'string';
		count: number;
	};
	recipientAffiliation: {
		value: 'string';
		count: number;
	};
	recipientGuardian: {
		value: 'string';
		count: number;
	};
	recipient: {
		value: 'string';
		count: number;
	};
	memberChangeHist: {
		value: 'string';
		count: number;
	};
	member: {
		value: 'string';
		count: number;
	};
	centerAccountRecordDetail: {
		value: 'string';
		count: number;
	};
	cashReceiptId: {
		value: 'string';
		count: number;
	};
	serviceStateChangeId: {
		value: 'string';
		count: number;
	};
	sendDetail: {
		value: 'string';
		count: number;
	};
	sendResult: {
		value: 'string';
		count: number;
	};
	send: {
		value: 'string';
		count: number;
	};
	edocSend: {
		value: 'string';
		count: number;
	};
	edocProgressHist: {
		value: 'string';
		count: number;
	};
	edocParam: {
		value: 'string';
		count: number;
	};
	esign: {
		value: 'string';
		count: number;
	};
	esignStep: {
		value: 'string';
		count: number;
	};
	esignDetail: {
		value: 'string';
		count: number;
	};
}

export type UpdateRecipientAffiliationResponse = BaseResponse<UpdateRecipientAffiliationDTO>;

export interface GetRecipientsExcelRequest {
	centerId: string;
}
// 수급자 목록 엑셀 다운로드
export interface GetRecipientsExcelDTO {
	recipientId: number;
	korMemberNm: string;
	longTermNo: string;
	centerId: number;
	centerNm: string;
	serviceTypeNms: string;
	guardianPhoneNo: string;
	recipientBasAddr: string;
	recipientDetailAddr: string;
	recipientZipCode: string;
	longTermUsePlanFileUrls: string[];
	longTermCertiFileUrls: string[];
	welfareDeviceConfirmFileUrls: string[];
	guardianNm: string;
	recipientStateNm: string;
	genderNm: string;
	longTermGradeNm: string;
	burdenRateNm: string;
	longTermDate: string;
	serviceDays: string;
	serviceMinute: number;
	workDayCnt: number;
	iaWriteDate: string;
	recipientIaId: number;
	recipientRegionNm1: string;
	recipientRegionNm2: string;
	recipientRegionNm3: string;
	recipientRoadNm: string;
	recipientAddrLatitude: number;
	recipientAddrLongitude: number;
	serviceContractIds: number[];
	serviceContractGroupUuids: string[];
	managerNm: string;
	workMobilePhoneNo: string;
	birthDt: string;
}

export type GetRecipientsExcelResponse = BaseResponse<GetRecipientsExcelDTO[]>;

export interface GetRecipientConsultClientRequest {
	recipientId?: string;
	centerId?: number;
}

export interface ConsultClientGuardianInfo {
	clientDivCd: string;
	clientId: number;
	clientNm: string;
	telNum?: string;
	mainGuardianYn?: boolean;
	guardianRelCd?: string;
	selfGuardianYn?: boolean;
	clientBirth?: string;
}

export interface GetRecipientConsultClientDTO {
	clientDivCd: string;
	clientId: number;
	clientNm: string;
	telNum?: string;
	clientBirth?: string;
	guardians: ConsultClientGuardianInfo[];
}

export type GetRecipientConsultClientResponse = BaseResponse<GetRecipientConsultClientDTO[]>;

export interface GetGenerateIntegratedAssessmentOpinionParams {
	body: string;
}
