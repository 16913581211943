import { useCallback, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useScroll() {
	const location = useLocation(); // 현재 경로 가져오기
	const pageKey = `scroll-${location.pathname}`; // 페이지 경로를 고유한 키로 사용

	// 스크롤을 조작할 엘리먼트를 참조하기 위한 ref
	const elementRef = useRef<HTMLDivElement>(null);

	// 스크롤 위치를 저장하는 함수
	const saveScrollPosition = useCallback(() => {
		if (elementRef.current) {
			sessionStorage.setItem(pageKey, String(elementRef.current.scrollTop || 0));
		}
	}, []);

	useLayoutEffect(() => {
		if (elementRef.current) {
			const prevScrollY = sessionStorage.getItem(pageKey);
			if (prevScrollY && !Number.isNaN(Number(prevScrollY))) {
				setTimeout(() => {
					if (elementRef.current) {
						elementRef.current.scrollTop = Number(prevScrollY);
						sessionStorage.removeItem(pageKey);
					}
				}, 0);
			}
		}
	}, []);

	return {
		elementRef, // 스크롤을 조작할 엘리먼트를 참조하기 위한 ref
		saveScrollPosition, // 스크롤 위치를 저장하는 함수
	};
}
