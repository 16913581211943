import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import { CheckOption } from 'components/base/Selections/type';
import { Toast } from 'components/base/CRToast';
import { Recipient, RecipientInfoSummary } from 'types/view/recipient';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useCreateContracts, useMyAccountInfo } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';

import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	currentRecipientInfoSummary?: RecipientInfoSummary;
	onClose?: () => void;
	onCancel?: () => void;
	onSubmit?: (contractId: number) => void;
}

function RecipientContractAddDialog({
	currentRecipient,
	currentRecipientInfoSummary,
	onClose,
	onCancel,
	onSubmit,
}: Props): React.ReactElement {
	const { watch, control } = useForm<{ serviceTypeCds: CheckOption[] }>();
	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006'] }, commonCodeAdapter);
	const { data: myAccountInfo } = useMyAccountInfo();
	const options = useMemo(
		() => commonCodes.CMN006?.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes.CMN006],
	);

	const createContractMutation = useCreateContracts((client, returnData) => {
		if (returnData.code === '200') {
			Toast.success('계약이 추가되었습니다.');
			client.invalidateQueries([endpoint.getEmployeeContractServices.key]);
		}
	});

	const handleSubmit = async () => {
		if (createContractMutation.isLoading) return;
		if (!myAccountInfo) return;
		if (!currentRecipient) return;

		const result = await createContractMutation.mutateAsync({
			contractTypeCd: 'CMN136.10',
			centerId: myAccountInfo.centerId,
			recipientId: currentRecipient.recipientId,
			serviceTypeCds: (watch('serviceTypeCds') ?? []).map((item) => item.value),
		});

		if (!result.data?.serviceContract.serviceContractId) return;
		onSubmit?.(result.data?.serviceContract.serviceContractId);
		onClose?.();
	};

	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>계약 추가</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={onClose} />
			</S.HeaderContainer>
			<S.BodyContainer>
				<S.BodyContent>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='서비스 종류' isRequired>
								<CRCheckBoxGroup
									checkType='multiple'
									type='checkbox'
									direction='col'
									appearanceType='button'
									gap={0.4}
									options={options}
									value={value}
									onChange={onChange}
								/>
							</CRInputLabel>
						)}
						name='serviceTypeCds'
						control={control}
					/>
					<CRCardFormLayout label='수급자' isRequired>
						<CRInputLabel label='이름'>
							<CRInput.Default value={currentRecipient?.name} disabled />
						</CRInputLabel>
						<CRInputLabel label='주민등록번호'>
							<CRInput.Default
								value={`${currentRecipientInfoSummary?.rsdn.substring(
									0,
									6,
								)}-${currentRecipientInfoSummary?.rsdn.substring(6, 7)}******`}
								disabled
							/>
						</CRInputLabel>
						<CRInputLabel label='소속'>
							<CRInput.Selector
								autoComplete
								items={[
									{
										label: currentRecipient?.centerName ?? '',
										value: currentRecipient?.centerId,
									},
								]}
								currentValue={{
									label: currentRecipient?.centerName ?? '',
									value: currentRecipient?.centerId,
								}}
								disabled
							/>
						</CRInputLabel>
					</CRCardFormLayout>
				</S.BodyContent>
			</S.BodyContainer>
			<S.FooterContainer>
				<CRButton.Default palette='gray' type='text' onClick={onCancel}>
					취소
				</CRButton.Default>
				<CRButton.Default
					disabled={!watch('serviceTypeCds')?.length || createContractMutation.isLoading}
					onClick={handleSubmit}>
					등록
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default React.memo(RecipientContractAddDialog);
