import { endpoint } from 'lib/service/Api/endpoint';
import useCRQuery from '../base/useCRQuery';

export const useMonitoringSchedules = useCRQuery(
	endpoint.getMonitoringSchedules.key,
	'getMonitoringSchedules',
	{
		enabledKey: ['centerId', 'serviceYm', 'page', 'size'],
	},
);

export const useCompleteMonitoringSyncDate = useCRQuery(
	endpoint.getMonitoringCompleteSynyDate.key,
	'getCompleteMonitoringSyncDate',
	{
		enabledKey: ['centerId', 'serviceYm'],
	},
);

export const useRecentMonitoringSyncDate = useCRQuery(
	endpoint.getRecentMonitoringSynyDate.key,
	'getRecentMonitoringSyncDate',
	{
		enabledKey: ['centerId', 'serviceYm'],
	},
);

export const useMonitoringSyncHistory = useCRQuery(
	endpoint.getMonitoringSchedules.key,
	'getMonitoringSyncHistory',
	{
		enabledKey: ['centerId', 'serviceYm', 'syncTypeCds'],
	},
);

export const useMonitoringDetails = useCRQuery(
	endpoint.getMonitoringDetails.key,
	'getMonitoringDetails',
	{
		enabledKey: ['centerId', 'serviceYm', 'recipientId', 'employeeId1', 'serviceTypeCd'],
	},
);

export const useMonitoringDetailItem = useCRQuery(
	endpoint.getMonitoringDetailItem.key,
	'getMonitoringDetailItem',
	{
		enabledKey: [
			'centerId',
			'serviceYm',
			'recipientId',
			'employeeId',
			'serviceTypeCd',
			'startDate',
			'endDate',
			'startTime',
			'endTime',
		],
	},
);
