import React from 'react';
import MyCenterLayout from 'components/domain/layout/MyCenterLayout';
import { Outlet } from 'react-router-dom';

export default function MyCenterPage() {
	return (
		<MyCenterLayout>
			<Outlet />
		</MyCenterLayout>
	);
}
