import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRInput from 'components/base/CRInput';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';

import * as S from './styles';

interface Props {
	index: number;
}

function ChangeEmployeeOtherWorkingForm({ index }: Props): React.ReactElement {
	const { form, disabled } = useContractChangeEmployeeTask();

	const { fields, append, remove } = useFieldArray({
		control: form.control,
		name: `employees.${index}.otherCorpWorks`,
	});

	const handleDeleteTimePickerItem = (fieldIndex: number, timeIndex: number) => () => {
		const previousTimes =
			form.getValues(
				`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes`,
			) ?? [];
		const newTimes = previousTimes.filter((_, itemIndex) => itemIndex !== timeIndex);
		form.setValue(
			`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes`,
			newTimes,
		);
	};

	const handleAddTime = (fieldIndex: number) => () => {
		const previousTimes =
			form.getValues(
				`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes`,
			) ?? [];

		form.setValue(`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes`, [
			...previousTimes,
			{
				otherCorpWorkDays: [],
				otherCorpWorkStartTime: '',
				otherCorpWorkEndTime: '',
			},
		]);
	};

	const handleClickRemoveOtherCorpWork = (index: number) => () => {
		remove(index);
	};

	const handleAddOtherCorpWorkItem = () => {
		append({
			personalBusinessOwnerYn: false,
			otherCorpNm: '',
			otherCorpPhoneNo: '',
			remark: '',
			otherCorpWorkStartEndTimes: [],
		});
	};

	const handleChangeTime =
		(fieldIndex: number, timeIndex: number) =>
		(dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
			const days = dayOfTheWeeks?.map((day) => ({ otherCorpWorkDayCd: day }));
			form.setValue(
				`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes.${timeIndex}.otherCorpWorkDays`,
				days,
			);
			form.setValue(
				`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes.${timeIndex}.otherCorpWorkStartTime`,
				startTime?.replace(':', ''),
			);
			form.setValue(
				`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes.${timeIndex}.otherCorpWorkEndTime`,
				endTime?.replace(':', ''),
			);
		};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	if (!form.watch(`employees.${index}`)) return <div />;

	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');

	return (
		<S.Container>
			<S.Form>
				{fields?.map((_, fieldIndex) => (
					<>
						<S.ItemHeader>
							{`타근무 ${formatIndex(index + 1)}`}
							<S.DeleteButtonContainer>
								{!disabled && (
									<CRButton.Default
										type='outlined'
										size='xSmall'
										onClick={handleClickRemoveOtherCorpWork(fieldIndex)}>
										삭제
									</CRButton.Default>
								)}
							</S.DeleteButtonContainer>
						</S.ItemHeader>
						<S.Table>
							<S.TableRow>
								<S.TableLabelColumn>유형</S.TableLabelColumn>
								<S.TableValueColumn>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRCheckBoxGroup
												ref={ref}
												checkType='single'
												type='radio'
												gap={0.8}
												onChange={(values) => {
													onChange(values[0].value);
												}}
												value={[{ label: '', value }]}
												options={[
													{
														label: '근로자',
														value: false,
													},
													{
														label: '사업자',
														value: true,
													},
												]}
												disabled={disabled}
											/>
										)}
										name={`employees.${index}.otherCorpWorks.${fieldIndex}.personalBusinessOwnerYn`}
										control={form.control}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn
									rowSpan={3}
									style={{
										verticalAlign: 'top',
									}}>
									비고
								</S.TableLabelColumn>
								<S.TableValueColumn rowSpan={3}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TextArea
												typography='label'
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='비고 입력'
												numberOfLines={4}
												disabled={disabled}
											/>
										)}
										name={`employees.${index}.otherCorpWorks.${fieldIndex}.remark`}
										control={form.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn>상호명</S.TableLabelColumn>
								<S.TableValueColumn>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='상호명 입력'
												disabled={disabled}
											/>
										)}
										name={`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpNm`}
										control={form.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn>전화번호</S.TableLabelColumn>
								<S.TableValueColumn>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												type='number'
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												maxLength={11}
												placeholder='전화번호 입력'
												disabled={disabled}
											/>
										)}
										name={`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpPhoneNo`}
										control={form.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn>근무일시</S.TableLabelColumn>
								<S.TableValueColumn style={{ verticalAlign: 'middle' }} colSpan={3}>
									<Controller
										render={({ formState: { errors } }) => (
											<S.TimePickerContainer>
												{form
													.watch(
														`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes`,
													)
													.map((time, timeIndex) => (
														<CRInput.TimePicker
															key={time.otherCorpWorkTimeId}
															selectedDayOfTheWeeks={time.otherCorpWorkDays?.map(
																(day) => day.otherCorpWorkDayCd as DayOfTheWeek,
															)}
															start={transformTimeString(time.otherCorpWorkStartTime)}
															end={transformTimeString(time.otherCorpWorkEndTime)}
															onChange={handleChangeTime(fieldIndex, timeIndex)}
															onDelete={handleDeleteTimePickerItem(index, timeIndex)}
															disabled={disabled}
														/>
													))}
												{!disabled && (
													<CRButton.IconButton
														onClick={handleAddTime(fieldIndex)}
														iconLeft={Assets.icon.add}
														palette='gray'
														type='tonal'>
														근무일시 추가
													</CRButton.IconButton>
												)}
											</S.TimePickerContainer>
										)}
										name={`employees.${index}.otherCorpWorks.${fieldIndex}.otherCorpWorkStartEndTimes`}
										control={form.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
						</S.Table>
					</>
				))}
			</S.Form>
			<S.AddButtonContainer>
				{!disabled && (
					<CRButton.IconButton
						onClick={handleAddOtherCorpWorkItem}
						style={{
							width: 'fit-content',
						}}
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.add}>
						타근무 추가
					</CRButton.IconButton>
				)}
			</S.AddButtonContainer>
		</S.Container>
	);
}

export default React.memo(ChangeEmployeeOtherWorkingForm);
