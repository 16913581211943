import { css, styled } from 'styled-components';

export const Container = styled.div`
	width: 100%;
	min-width: 80rem;
	height: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
	overflow: auto;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	position: sticky;
	z-index: 20;
	top: 2.4rem;
	height: 6.4rem;
	background: ${(props) => props.theme.colors.gray99};
	padding-bottom: 2.4rem;
`;

export const HeaderLeft = styled.div``;

export const HeaderRight = styled.div``;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
`;

export const SubTitle = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
`;

export const LeftSideContainer = styled.div`
	padding: 2.4rem 2.8rem;
`;

export const CustomFilterContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const CustomFilterChip = styled.button<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray100};
	padding: 0.2rem 1.2rem;
	cursor: pointer;
	box-sizing: border-box;

	${(props) =>
		props.$isActive &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.gray10};
			background: ${(props) => props.theme.colors.gray10};
			color: ${(props) => props.theme.colors.gray100};
		`};
`;

export const CustomFilterRight = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Divider = styled.div`
	height: 1.6rem;
	width: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin: 0 0.8rem;
`;
