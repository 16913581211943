import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
	overflow: auto;
`;

export const TextContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
`;
export const TableScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;
