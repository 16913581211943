import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';
import { CenterAccountManagementTab } from './CenterAccountManagementTab';

function CenterAccountManagementPage(): React.ReactElement {
	const accountManagementTab = useMemo(() => RouterPath.myCenterOperate().accountManagement, []);

	return (
		<CRTab.Default
			defaultActiveKey={accountManagementTab.key}
			items={[
				{
					label: accountManagementTab.label,
					key: accountManagementTab.key,
					children: <CenterAccountManagementTab />,
				},
			]}
			breadCrumb='계좌 관리'
		/>
	);
}

export default CenterAccountManagementPage;
