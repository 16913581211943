import { CRText } from 'components/base/CRText';
import { displayPadTime } from 'lib';
import React from 'react';
import { GetWorkExecuteLogServiceSchedulePlanDTO } from 'types/api/workExecuteLog';
import * as S from './styles';

interface Props {
	item: GetWorkExecuteLogServiceSchedulePlanDTO;
}

export default function WorkPlanCard({ item }: Props) {
	const time = `${displayPadTime(item.serviceStartTime)} ~ ${displayPadTime(item.serviceEndTime)}(${
		item.serviceTypeNm
	})`;
	return (
		<S.Container>
			<CRText text={time} color='gray10' typography='body' />
			<CRText text={item?.employeeNm} color='gray60' typography='label' />
		</S.Container>
	);
}
