import { CRStatusConfig } from 'types/view/base';

export const WRITE_COMPLETE_YN_CONFIG: CRStatusConfig[] = [
	{
		key: '작성 예정',
		label: '작성 예정',
		color: 'yellow',
	},
	{
		key: '작성 완료',
		label: '작성 완료',
		color: 'green',
	},
];
