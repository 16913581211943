import { CRStatusConfig, CRTableHeadConfig } from '../../../../types/view/base';
import { Filter } from '../../../base/CRTableHeader';

export const BILLING_TASK_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '20rem',
		label: '수급자',
		sortKey: '수급자',
	},
	{
		width: '16rem',
		label: '서비스 유형',
		sortKey: '서비스 유형',
	},
	{
		width: '12rem',
		label: '본인부담율',
		sortKey: '본인부담율',
	},
	{
		width: '20rem',
		label: '금액 초과 여부',
		sortKey: '금액 초과 여부',
	},
	{
		width: '12rem',
		label: '근로일정',
		sortKey: '근로일정',
		textAlign: 'right',
	},
	{
		width: '12rem',
		label: '총 시간',
		sortKey: '총 시간',
		textAlign: 'right',
	},
	{
		width: '16rem',
		label: '총 청구액',
		sortKey: '총 청구액',
		textAlign: 'right',
	},
	{
		width: '18rem',
		label: '상태',
		sortKey: '상태',
	},
];

export const BILLING_TASK_PROGRESS_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '등록완료',
		color: 'green',
	},
	{
		key: '청구 생성 실패',
		color: 'red',
	},
	{
		key: '수정됨',
		color: 'yellow',
	},
	{
		key: '특정내용 등록 필요',
		color: 'blue',
	},
	{
		key: '검토필요',
		color: 'gray',
	},
];

export enum BILLING_TASK_FILTER_TYPE {
	ALL = 'ALL',
	VISIT_CARE = 'VISIT_CARE',
	VISIT_BATH = 'VISIT_BATH',
	VISIT_NURSE = 'VISIT_NURSE',
}

// TODO: 타입스크립트 수정하기
export const BILLING_TASK_TABLE_FILTER_CONFIG: Filter<any>[] = [
	{
		key: BILLING_TASK_FILTER_TYPE.ALL,
		type: 'toggle',
		options: [
			{
				label: '전체',
				value: BILLING_TASK_FILTER_TYPE.ALL,
			},
		],
	},
	{
		key: BILLING_TASK_FILTER_TYPE.VISIT_CARE,
		type: 'toggle',
		options: [
			{
				label: '방문요양',
				value: BILLING_TASK_FILTER_TYPE.VISIT_CARE,
			},
		],
	},
	{
		key: BILLING_TASK_FILTER_TYPE.VISIT_BATH,
		type: 'toggle',
		options: [
			{
				label: '방문목욕',
				value: BILLING_TASK_FILTER_TYPE.VISIT_BATH,
			},
		],
	},
	{
		key: BILLING_TASK_FILTER_TYPE.VISIT_NURSE,
		type: 'toggle',
		options: [
			{
				label: '방문간호',
				value: BILLING_TASK_FILTER_TYPE.VISIT_NURSE,
			},
		],
	},
];
