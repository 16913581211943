import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import ContractManagementTab from './ContractManagementTab';

function ContractManagementPage(): React.ReactElement {
	const contractTab = useMemo(() => RouterPath.contract(), []);

	return (
		<CRTab.Default
			defaultActiveKey={contractTab}
			items={[
				{
					label: '',
					key: contractTab,
					children: <ContractManagementTab />,
				},
			]}
			breadCrumb='계약 관리'
		/>
	);
}

export default ContractManagementPage;
