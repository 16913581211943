import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	padding: 1.6rem 2rem;
	background-color: ${(p) => p.theme.colors.red};
`;

export const DateWrapper = styled.div`
	padding: 0.4rem 0.8rem;
	background: ${(p) => p.theme.colors.primary50};
	border-radius: 0.8rem;
`;

export const ProfileBackground = styled.div`
	width: 3.6rem;
	height: 3.6rem;
	border-radius: 1.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(p) => p.theme.colors.primary50};
	> img {
		opacity: 0.5;
		filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2672%) hue-rotate(150deg)
			brightness(116%) contrast(101%);
	}
`;
