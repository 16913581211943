import React from 'react';

import * as S from './styles';

interface Props {
	stickyHeader?: React.ReactElement;
	containerStyle?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
}

function CRTableBlind({
	children,
	stickyHeader,
	containerStyle,
	contentStyle,
}: React.PropsWithChildren<Props>): React.ReactElement {
	return (
		<S.Container style={containerStyle}>
			<S.ContentContainer>
				<S.Content style={contentStyle}>
					<S.Blind />
					{stickyHeader}
					{children}
				</S.Content>
			</S.ContentContainer>
		</S.Container>
	);
}

export default CRTableBlind;
