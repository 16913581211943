import styled from 'styled-components';

export const TableHeader = styled.thead`
	height: 6rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableHeaderColumn = styled.th`
	${(props) => props.theme.typography.labelB}
	color: ${(props) => props.theme.colors.gray10};
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
`;

export const TableHeaderColumnWithPadding = styled(TableHeaderColumn)`
	padding: 1.6rem;
	text-align: left;

	&:first-of-type {
		padding-left: 2.4rem;
	}

	&:last-of-type {
		padding-right: 2.4rem;
	}
`;
