import React, { useEffect, useMemo, useState } from 'react';
import CRTab from 'components/base/CRTab';
import RouterPath from 'common/router';
import CRSyncMenu from 'components/base/CRSyncMenu';
import useDialog from 'lib/hook/util/useDialog';
import { SyncDialog } from 'components/domain/dialog/SyncDialog';

import TagErrorSyncHistoryDialog from 'components/domain/dialog/TagErrorSyncHistoryDialog';
import {
	useAutomationLastSync,
	useMyAccountInfo,
	useTagErrorAutomation,
} from 'lib/hook/react-query';
import dayjs from 'dayjs';
import { Toast } from 'components/base/CRToast';
import { displayDateToYYYYMM } from 'lib';
import { ResponseCode } from 'types/api';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import useDateFilter from 'lib/hook/util/useDateFilter';
import TagErrorTab from './TagErrorTab';

export default function TagErrorPage() {
	const tagErrorTab = useMemo(() => RouterPath.scheduleOperate().tagError, []);
	const dialog = useDialog();
	const [date, setDate] = useDateFilter(dayjs().subtract(1, 'month').toDate());
	const [isTempSync, setIsTempSync] = useState(true);
	const { data: myAccountInfo } = useMyAccountInfo();

	const centerId = myAccountInfo?.centerId;

	const { data: lastSyncData } = useAutomationLastSync({
		centerId,
		uploadTypeCds: ['CMN107.RFID'],
	});

	const { mutate: tagErrorAutomation, isLoading: tagErrorAutomationLoading } =
		useTagErrorAutomation((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				setIsTempSync(true);
				Toast.success(
					'태그 내역 동기화 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
				);
			} else {
				Toast.error('태그 내역 동기화 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const hasPermission = useHasPermission('센터장');

	const handleScheduleSync = async (data: { yearMonth: Date }) => {
		if (!centerId) {
			return;
		}

		const YYYYMM = displayDateToYYYYMM(data.yearMonth);
		tagErrorAutomation({ centerId: `${centerId}`, yyyymm: YYYYMM });
		dialog.hideDialog();
	};

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickSyncButton = () => {
		dialog.showDialog(() => <SyncDialog title='태그 오류' onSync={handleScheduleSync} />);
	};

	const handleClickSyncHistory = () => {
		dialog.showDialog(() => <TagErrorSyncHistoryDialog />);
	};
	const isProgress = useMemo(() => isTempSync || tagErrorAutomationLoading, [isTempSync]);

	useEffect(() => {
		if (
			lastSyncData?.uploadStateCd === 'CMN108.REQUEST' ||
			lastSyncData?.uploadStateCd === 'CMN108.PROGRESS'
		) {
			setIsTempSync(true);
		} else {
			setIsTempSync(false);
		}
	}, [lastSyncData]);

	return (
		<CRTab.Default
			defaultActiveKey={tagErrorTab.key}
			breadCrumb='태그 오류 관리'
			renderRightButton={
				<CRSyncMenu
					showSyncButton={hasPermission}
					title={isProgress ? '태그 동기화 중' : '태그 동기화'}
					isProgress={isProgress}
					onClickSync={handleClickSyncButton}
					onClickSyncHistory={handleClickSyncHistory}
				/>
			}
			items={[
				{
					label: tagErrorTab.label,
					key: tagErrorTab.key,
					children: <TagErrorTab date={date} onChangeDate={handleChangeDate} />,
				},
			]}
		/>
	);
}
