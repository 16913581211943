import React from 'react';

import * as S from './styles';

interface Props {
	title?: string;
	description?: string;
}

function EmptyDescription({ title = '', description = '' }: Props) {
	return (
		<S.Container>
			<S.Title>{title}</S.Title>
			<S.Description>{description}</S.Description>
		</S.Container>
	);
}

export default React.memo(EmptyDescription);
