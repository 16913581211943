import React, { useCallback } from 'react';
import Assets from 'assets';

import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import CRDialog from 'components/base/CRDialog';
import { Recipient, RecipientManagerHistoryType } from 'types/view/recipient';
import { useRecipientHistoryOfManager } from 'lib/hook/react-query';
import { recipientHistoryOfManagerAdapter } from 'lib/adapter/recipient';

import dayjs from 'dayjs';
import * as S from './styles';
import {
	RECIPIENT_MANAGER_HISTORY_TABLE_HEADER_CONFIG,
	RECIPIENT_MANAGER_HISTORY_STATUS_CONFIG,
} from './constants';

interface Props {
	currentRecipient?: Recipient;
	onClickClose?: () => void;
	onClickAdd?: () => void;
	onClickEdit?: (item: RecipientManagerHistoryType) => void;
}

function RecipientManagerHistoryDialog({
	currentRecipient,
	onClickClose,
	onClickAdd,
	onClickEdit,
}: Props): React.ReactElement {
	const { data: historyOfManager = [] } = useRecipientHistoryOfManager(
		{
			recipientId: (currentRecipient?.recipientId ?? 0).toString(),
		},
		recipientHistoryOfManagerAdapter,
	);

	const Status = useCallback(
		(status: string) => (
			<CRStatus options={RECIPIENT_MANAGER_HISTORY_STATUS_CONFIG}>{status}</CRStatus>
		),
		[],
	);

	const ButtonContainer = useCallback(
		(id: string, item?: RecipientManagerHistoryType) => (
			<CRButton.Default
				type='outlined'
				palette='gray'
				size='xSmall'
				onClick={() => item && onClickEdit?.(item)}>
				수정
			</CRButton.Default>
		),
		[onClickEdit],
	);

	return (
		<CRDialog
			type='L'
			title='사회복지사 이력'
			body={
				<CRTable.Root>
					<CRTable.Head heads={RECIPIENT_MANAGER_HISTORY_TABLE_HEADER_CONFIG} disableBackBlind />
					<CRTable.Body>
						{historyOfManager.map((item) => (
							<CRTable.Row
								key={item.id}
								item={item}
								customRender={{
									status: Status,
									id: ButtonContainer,
									date: (item) => dayjs(item).format('YYYY.MM.DD'),
									center: (item) => item.name,
									manager: (item) => item.name,
								}}
								renderKeys={[
									'status',
									'date',
									'center',
									'manager',
									'note',
									'consultantOpinion',
									'changeReason',
									'id',
								]}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			}
			footer={
				<S.FooterRightSideContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='outlined'
						palette='gray'
						onClick={onClickAdd}>
						사회복지사 변경
					</CRButton.IconButton>
				</S.FooterRightSideContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default React.memo(RecipientManagerHistoryDialog);
