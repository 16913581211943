import EDocSignResult from 'components/ui/EDoc/EDocSignResult';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import React from 'react';

interface Props {
	onClickBack: () => void;
}

function ESignInfoResultStep({ onClickBack }: Props) {
	const { eDocInfo, eDocConfirmResult, isErp } = useEDocClientPage();

	const documentName = eDocInfo?.edocPreviewResponse.edocNm || '전자문서';
	const managerName = eDocInfo?.edocPreviewResponse.data.edocSend.edocSenderNm || '담당';

	const renderResult = () => {
		const ResultComponent = isErp
			? EDocSignResult.EDocConfirmResult
			: EDocSignResult.EDocSubmitResult;

		if (eDocConfirmResult?.code === '200') {
			return (
				<ResultComponent
					onClickBack={onClickBack}
					documentName={documentName}
					managerName={managerName}
					result='SUCCESS'
				/>
			);
		}
		if (eDocConfirmResult?.code === '400') {
			return (
				<ResultComponent
					onClickBack={onClickBack}
					documentName={documentName}
					managerName={managerName}
					result='FAIL'
				/>
			);
		}
		if (eDocConfirmResult?.code === '408') {
			return (
				<ResultComponent
					onClickBack={onClickBack}
					documentName={documentName}
					managerName={managerName}
					result='EXPIRED'
				/>
			);
		}
		return (
			<ResultComponent
				onClickBack={onClickBack}
				documentName={documentName}
				managerName={managerName}
				result='FAIL'
			/>
		);
	};

	return renderResult();
}

export default ESignInfoResultStep;
