import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CRButton from 'components/base/CRButton';
import MonitoringTable from 'components/domain/table/DeprecatedMonitoringTable';
import CRTable from 'components/base/CRTable';
import useFullScreen from 'lib/hook/util/useFullScreen';

import { PageInfo } from 'types/view/base';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { MonitoringContentDTO } from 'types/api/monitoring';

import { Filter, FilterType } from 'types/view/filter';
import * as S from './styles';
import { monitoringItems } from './mock';

function MonitoringPage<T extends { label: string; value: any }>(): React.ReactElement {
	const location = useLocation();
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);

	const [currentFilter, setCurrentFilter] = useState<{
		[key in string]: T[];
	}>({});
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const navigate = useNavigate();

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const onChangeFilter = (item: T, key: string, filterType: FilterType) => {
		const prevFilter = Object.keys(currentFilter).length === 0 ? {} : { ...currentFilter };

		if (filterType === 'single') {
			const newItems = { ...prevFilter };
			const exist = prevFilter[key]?.find((selectedItem) => selectedItem.value === item.value);
			if (exist) {
				delete newItems[key];
			} else {
				newItems[key] = [item];
			}
			setCurrentFilter({
				...newItems,
			});
		} else if (['multi', 'toggle'].includes(filterType)) {
			if (!prevFilter[key]) {
				setCurrentFilter({
					...prevFilter,
					[key]: [item],
				});
			} else {
				const newItems = { ...prevFilter };
				const exist = prevFilter[key].find((selectedItem) => selectedItem.value === item.value);
				if (exist) {
					newItems[key] = prevFilter[key].filter((prevItem) => prevItem.value !== item.value);
				} else {
					newItems[key] = [...prevFilter[key], item];
				}
				setCurrentFilter(newItems);
			}
		} else if (['calendar', 'month_calendar'].includes(filterType)) {
			setCurrentFilter({
				...prevFilter,
				[key]: [item],
			});
		}
	};

	const filters = useMemo(
		() =>
			[
				{
					key: 'serviceType',
					type: 'single',
					options: [
						{
							label: '방문요양',
							value: '1',
						},
						{
							label: '방문목욕',
							value: '2',
						},
						{
							label: '방문간호',
							value: '3',
						},
					],
					placeholder: '서비스명',
				},
				{
					key: 'signStatus',
					type: 'single',
					options: [
						{
							label: '처리중',
							value: '1',
						},
						{
							label: '처리완료',
							value: '2',
						},
						{
							label: '미처리',
							value: '3',
						},
					],
					placeholder: '진행상황',
				},
			] as Filter<T>[],
		[],
	);

	const handleClickTask = (item: MonitoringContentDTO) => {
		navigate(`${location.pathname}/detail/${item.id}`);
		// pushBreadCrumbs([
		// 	{
		// 		label: '일정 모니터링 상세',
		// 		link: `${location.pathname}/detail/${item.id}`,
		// 	},
		// ]);
	};

	const isDisabled = monitoringItems?.some((item) => item.status !== '처리완료');

	useFullScreen();

	return (
		<S.Container>
			<CRTable.BackBlind
				stickyHeader={
					<S.Header>
						<S.HeaderLeft>
							<S.Title>일정 모니터링 발생 (6건)</S.Title>
							<S.Duration>시작일: 2023.04.01 ~ 마감일: 2023.04.05 (D-4)</S.Duration>
						</S.HeaderLeft>
						<S.HeaderRight>
							<CRButton.Default disabled={isDisabled}>업무 완료</CRButton.Default>
						</S.HeaderRight>
					</S.Header>
				}>
				<MonitoringTable
					filters={filters}
					setCurrentFilter={setCurrentFilter}
					currentValue={currentFilter}
					items={monitoringItems}
					pageInfo={pageInfo}
					onChangePageInfo={handleChangePageInfo}
					searchValue={searchValue}
					onChangeSearchValue={handleSearchKeyword}
					onSearch={handleSearch}
					onClickTask={handleClickTask}
				/>
			</CRTable.BackBlind>
		</S.Container>
	);
}

export default MonitoringPage;
