import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
`;

export const MenuContainer = styled.ul`
	display: flex;
	min-height: 4.8rem;
	align-items: flex-end;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};
	position: relative;
	overflow: hidden;
`;

export const TabItem = styled.li<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: flex-end;
	justify-content: center;
	box-sizing: border-box;
	height: 2.8rem;
	padding-bottom: 0.4rem;
	cursor: pointer;
	color: ${(props) => (props.$isActive ? props.theme.colors.gray10 : props.theme.colors.gray60)};
`;

export const RequiredBadge = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.primary60};
`;

export const TabUnderLine = styled.div`
	position: absolute;
	height: 0.2rem;
	background-color: ${(props) => props.theme.colors.primary60};
	bottom: 0rem;
	border-radius: 0.2rem 0.2rem 0 0;
	transition: all 0.4s ease-in-out;
`;

export const TabContent = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: overlay;
`;
