function matched<T>(x: T) {
	return {
		on: () => matched(x),
		otherwise: () => x,
	};
}

/**
 * 특정 조건에 맞춰서 타겟의 논리가 일치하면, 해당 조건의 값을 리턴하는 함수
 * @param x  조건의 타겟이 되는 값
 * @returns void
 */
export function match<T>(x: T) {
	return {
		on: (pred: (value: T) => boolean, fn: (value: T) => any) =>
			pred(x) ? matched(fn(x)) : match(x),
		otherwise: (fn: (value: T) => any) => fn(x),
	};
}
