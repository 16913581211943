import React from 'react';

import * as S from './styles';

function IntegratedAssessmentDesireCommunicationStatusFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<colgroup>
				<col style={{ width: '6rem' }} />
				<col style={{ width: '80%' }} />
			</colgroup>
			<tr>
				<S.TableHeaderColumn>구분</S.TableHeaderColumn>
				<S.TableHeaderColumn>확인</S.TableHeaderColumn>
			</tr>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedAssessmentDesireCommunicationStatusFormHeader);
