import React from 'react';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import MyCenterWorkLeavePermitIpForm from './MyCenterWorkLeavePermitIpForm';
import MyCenterWorkLeavePermitIpView from './MyCenterWorkLeavePermitIpView';

function MyCenterWorkLeavePermitIpFormView(): React.ReactElement {
	const { isEdit } = useCenterInfo();
	return isEdit ? <MyCenterWorkLeavePermitIpForm /> : <MyCenterWorkLeavePermitIpView />;
}

export default MyCenterWorkLeavePermitIpFormView;
