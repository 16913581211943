import React, { CSSProperties, PropsWithChildren, useState } from 'react';
import Assets from 'assets';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import * as S from './styles';

import { AvatarSize } from './type';

interface IProps {
	style?: CSSProperties;
	size?: AvatarSize;
	src?: string;
	alt?: string;
	showTooltip?: boolean;
	borderColor?: string;
	content?: React.ReactNode;
}

function CRAvatar({
	size = 'default',
	src = '',
	showTooltip = false,
	alt,
	children,
	borderColor,
	content,
	...res
}: PropsWithChildren<IProps>) {
	const [isValid, setIsValid] = useState(true);

	const onError = () => {
		setIsValid(false);
	};

	return (
		<S.Wrapper $size={size} {...res}>
			{/* {showTooltip ? (
				<CRToolTip title={alt}>
					<S.Container $size={size} $isValid={isValid} $borderColor={borderColor}>
						{children ?? (
							<S.Icon src={isValid ? src : Assets.icon.person} alt={alt} onError={onError} />
						)}
					</S.Container>
				</CRToolTip>
			) : (
				<S.Container $size={size} $isValid={isValid} $borderColor={borderColor}>
					{children ?? (
						<S.Icon src={isValid ? src : Assets.icon.person} alt={alt} onError={onError} />
					)}
				</S.Container>
			)} */}
			{showTooltip ? (
				<RDTooltip content={content}>
					<S.Container $size={size} $isValid={isValid} $borderColor={borderColor}>
						{children ?? (
							<S.Icon src={isValid ? src : Assets.icon.person} alt={alt} onError={onError} />
						)}
					</S.Container>
				</RDTooltip>
			) : (
				<S.Container $size={size} $isValid={isValid} $borderColor={borderColor}>
					{children ?? (
						<S.Icon src={isValid ? src : Assets.icon.person} alt={alt} onError={onError} />
					)}
				</S.Container>
			)}
		</S.Wrapper>
	);
}

export default CRAvatar;
