import { styled } from 'styled-components';

export const EmptyContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	flex: 1;
	height: 100%;
`;

export const EmptyTitle = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray60};
`;

export const EmptyDescription = styled.p`
	${(props) => props.theme.typography.body};
	text-align: center;
	color: ${(props) => props.theme.colors.gray60};
`;
