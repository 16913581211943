import React from 'react';
import { useOpenEDocClient } from 'lib/hook/react-query';
import { useParams } from 'react-router-dom';
import EDocClientAuthForm from 'components/ui/EDoc/EDocClientAuthForm';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api/base';

interface Props {
	onSuccess: () => void;
}
function EDocInfoAuthStep({ onSuccess }: Props) {
	const param = useParams();
	const { mutate: openEdoc, isLoading: isOpeningEDoc } = useOpenEDocClient();
	const { setEDocInfo } = useEDocClientPage();

	const handleOpenEDdc = async ({ birthDt }: { birthDt: string }) => {
		if (isOpeningEDoc) return;
		if (!param.id) {
			Toast.error('본인확인에 실패했습니다.');
			return;
		}

		const params = { birthDt, sendUuid: param.id, isEsign: false };
		openEdoc(params, {
			onSuccess: (e) => {
				if (e?.code !== ResponseCode.SUCCESS) {
					Toast.error(e?.message);
					return;
				}
				onSuccess();
				setEDocInfo(e.data);
			},
		});
	};

	return <EDocClientAuthForm onSubmit={handleOpenEDdc} />;
}

export default EDocInfoAuthStep;
