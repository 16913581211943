import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'pages';
import { Sentry } from 'lib/third-party/sentry';
import { EnvConfig } from 'lib/config/env';
import reportWebVitals from './reportWebVitals';

Sentry.init();

if (EnvConfig.BUILD_ENV === 'production') {
	console.log = () => {}; // log 제거
	console.error = () => {}; // error 제거
	console.debug = () => {}; // debug 제거
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
