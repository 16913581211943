import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 0.4rem;
	background: ${(props) => props.theme.colors.gray100};
	overflow: overlay;
`;

export const CellContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	height: 8.8rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
`;

export const IntegratedAssessmentListContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 8.8rem;
`;

export const IntegratedAssessmentInfo = styled.div`
	${(props) => props.theme.typography.label}
`;
