import styled, { css } from 'styled-components';

export const Icon = styled.img<{ $disabled?: boolean; $filter?: any }>`
	width: 3.1rem;
	height: 3.1rem;

	${(props) =>
		props.$filter &&
		css`
			${props.$filter};
		`};

	${(props) =>
		props.$disabled &&
		css`
			${props.theme.filters.gray60};
		`};
`;
