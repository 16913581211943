import React, { useCallback } from 'react';
import CRTable from 'components/base/CRTable';
import { CenterAccountRecordData, CenterAccountRecordDetail } from 'types/api/centerAccount';
import dayjs from 'dayjs';
import { displayComma } from 'lib';
import Colors from 'common/colors';
import * as S from './styles';
import { ACCOUNT_HEADER_CONFIG } from './contant';

export enum TaskProgressStatus {
	InProgress,
	Done,
	Pending,
}

interface Props {
	items: CenterAccountRecordData[];
	onClickRowDetail: (
		idx: number,
		CenterAccountRecordData: CenterAccountRecordData,
	) => (SalaryDetail: CenterAccountRecordDetail) => void;
	onClickRow: (account: CenterAccountRecordData) => void;
	searchText?: string;
}

function CenterAccountTable({
	items = [],
	onClickRowDetail,
	onClickRow,
	searchText,
}: Props): React.ReactElement {
	const customRecordDt = useCallback(
		(value: string) => dayjs(value).format('YYYY.MM.DD HH:mm'),
		[],
	);

	const customAmtRenderer = useCallback((val: number) => (!val ? ' ' : displayComma(val)), []);

	const customBalanceAmtRenderer = useCallback(
		(val: number) => (typeof val === 'number' ? displayComma(val) : ' '),
		[],
	);

	return (
		<S.Container>
			<S.TableScrollContainer>
				<CRTable.Root
					style={{
						borderLeft: `0.1rem solid ${Colors.gray90}`,
						borderRight: `0.1rem solid ${Colors.gray90}`,
					}}>
					<CRTable.Head offset={1} heads={ACCOUNT_HEADER_CONFIG} />
					<CRTable.Body>
						{items.map((item) => {
							const isSingleChild = item.centerAccountRecordDetails?.length === 1;
							const mergedItem = {
								...item,
								recordDesc: item?.centerAccountRecordDetails?.[0]?.recordDesc,
							};
							return (
								<>
									<CRTable.Row
										key={item.centerAccountRecordId}
										item={isSingleChild ? mergedItem : item}
										onClick={onClickRow}
										searchKey={['recordDesc', 'record']}
										searchValue={searchText}
										renderKeys={[
											'recordKindNm',
											'recordTypeNm',
											'recordDesc',
											'record',
											'depositAmt',
											'withdrawAmt',
											'balanceAmt',
											'centerAccountAliasNm',
											'recordDate',
										]}
										customRender={{
											depositAmt: customAmtRenderer,
											withdrawAmt: customAmtRenderer,
											balanceAmt: customBalanceAmtRenderer,
											recordDate: customRecordDt,
										}}
										customStyle={{
											depositAmt: {
												textAlign: 'right',
											},
											withdrawAmt: {
												textAlign: 'right',
											},
											balanceAmt: {
												textAlign: 'right',
											},
										}}
									/>
									{!isSingleChild &&
										item.centerAccountRecordDetails.map((detail, idx) => (
											<CRTable.Row
												key={detail.centerAccountRecordDetailId}
												item={detail}
												onClick={onClickRowDetail(idx, item)}
												style={{ backgroundColor: Colors.gray99 }}
												searchKey={['recordDesc', 'record']}
												searchValue={searchText}
												renderKeys={[
													'',
													'recordTypeNm',
													'recordDesc',
													'record',
													'depositAmt',
													'withdrawAmt',
													'balanceAmt',
													'centerAccountAliasNm',
													'recordDate',
												]}
												customRender={{
													depositAmt: customAmtRenderer,
													withdrawAmt: customAmtRenderer,
													balanceAmt: () => ' ',
													recordDate: customRecordDt,
												}}
												customStyle={{
													depositAmt: {
														textAlign: 'right',
													},
													withdrawAmt: {
														textAlign: 'right',
													},
													balanceAmt: {
														textAlign: 'right',
													},
												}}
											/>
										))}
								</>
							);
						})}
					</CRTable.Body>
				</CRTable.Root>
			</S.TableScrollContainer>
		</S.Container>
	);
}

export default CenterAccountTable;
