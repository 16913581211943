import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 60rem;
	box-sizing: border-box;
	gap: 1.6rem;
`;

export const Badge = styled.span`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 0.6rem;
`;

export const InputContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const EmptyVisitSchedule = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 8.4rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const VisitScheduleContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem 0;
	margin-left: 0.4rem;
`;
