import styled from 'styled-components';

export const ValidateButtonContainer = styled.div`
	display: flex;
	gap: 0.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const HelperTextContainer = styled.div`
	margin-top: -0.8rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const HelperTextTitle = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.8rem;
	gap: 0.8rem;
`;
