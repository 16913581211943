import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 60rem;
	box-sizing: border-box;
	gap: 1.6rem;
`;

export const Table = styled.table<{ $isDisabled?: boolean }>`
	width: 75.2rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray99};
			color: ${(props) => props.theme.colors.gray60}; ;
		`};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	width: 25%;
	color: ${(props) => props.theme.colors.gray60};
	height: 4.9rem;
	padding: 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	width: 25%;
	position: relative;
	box-sizing: border-box;
	height: 4.9rem;
	padding: 0 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
	text-align: right;
`;
