import { styled } from 'styled-components';

export const Container = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	align-items: center;
	padding: 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const Label = styled.span`
	${(props) => props.theme.typography.bodyB}
	min-width: 8rem;
`;

export const Value = styled.span`
	${(props) => props.theme.typography.body}
`;

export const Suffix = styled.span`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray60};
`;
