import React from 'react';

import { ApprovalItem, ApprovalStatus } from 'types/view/approval';
import CRAvatar from 'components/base/CRAvatar';
import CRStatus from 'components/base/CRStatus';
import * as S from './styles';
import { APPROVAL_STATUS_CONFIG } from './constant';

interface Props {
	item: ApprovalItem;
	onClickRow?: (subTask: ApprovalItem) => void;
}

function CollapsibleRow({ item, onClickRow }: Props): React.ReactElement {
	const renderAvatarGroup = (item: ApprovalItem) => {
		if (item.approvalLine.length > 3) {
			return (
				<S.ApprovalLineContainer>
					<S.AvatarContainer>
						<CRAvatar.Group size='small' maxCount={3}>
							{item?.approvalLine?.map((e) => (
								<CRAvatar.Default
									showTooltip
									content={e.name}
									key={e.id}
									src={e.imgUrl}
									alt={e.name}
								/>
							))}
						</CRAvatar.Group>
						{item?.approvalLine?.length > 0 && (
							<span>
								{item?.approvalLine?.[0].name} 외 {item.approvalLine.length - 1} 명
							</span>
						)}
					</S.AvatarContainer>
				</S.ApprovalLineContainer>
			);
		}

		return (
			<S.ApprovalLineContainer>
				{item.approvalLine.map((e) => (
					<S.AvatarContainer key={e.imgUrl}>
						<CRAvatar.Default size='small' src={e.imgUrl} alt={e.name} />
						<span>{e.name}</span>
					</S.AvatarContainer>
				))}
			</S.ApprovalLineContainer>
		);
	};

	return (
		<S.TableBodyRow onClick={() => onClickRow?.(item)}>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }} title={item.title}>
				{item.title}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding title={item.center}>{item.center}</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding style={{ textAlign: 'left' }} title={item.author}>
				{item.author}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding title={item.createdAt}>
				{item.createdAt}
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>{renderAvatarGroup(item)}</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding>
				<CRStatus options={APPROVAL_STATUS_CONFIG}>{item.status}</CRStatus>
			</S.TableBodyColumnWithPadding>
			<S.TableBodyColumnWithPadding />
		</S.TableBodyRow>
	);
}

export default CollapsibleRow;
