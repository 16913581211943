import React, { useEffect, useMemo } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { EmployeeBaseInfoDTO, EmployeeDTO } from 'types/api/employee';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { HealthInsuranceAcqCancelFormTypes } from 'types/view/eDoc';
import dayjs from 'dayjs';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { InsCancelCds } from 'types/view/insurance';
import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<HealthInsuranceAcqCancelFormTypes, any, undefined>;
	currentEmployeeBase?: EmployeeBaseInfoDTO;
}

// 건강보험취득취소확인서
export default function EDocHealthInsuranceAcqCancelForm({
	templateCode,
	currentEmployee,
	currentEmployeeBase,
	formContext,
}: Props) {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	const insCancelOptions = useMemo(
		() =>
			eDocBeforeIssueInfo?.data?.insCancelCds?.map((item) => ({
				label: item.korComCdNm,
				value: item.comCdId,
			})) || [],
		[eDocBeforeIssueInfo],
	);

	useEffect(() => {
		const defaultForm: HealthInsuranceAcqCancelFormTypes = {
			requestDate: dayjs().set('h', 0).set('m', 0).set('s', 0).toDate(),
			insCancelCd: [
				{
					label: '근무이력없음',
					value: InsCancelCds.근무이력없음,
				},
			],
		};
		if (eDocBeforeIssueInfo) {
			defaultForm.data = eDocBeforeIssueInfo;
		}

		if (currentEmployeeBase?.healthInsuranceAcqDate) {
			defaultForm.healthInsuranceAcqDate = dayjs(
				currentEmployeeBase.healthInsuranceAcqDate,
			).toDate();
		}

		formContext.reset(defaultForm);
	}, [eDocBeforeIssueInfo, currentEmployeeBase, currentEmployee]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='이름' isRequired>
					<CRInput.Default value={currentEmployeeBase?.korMemberNm || ''} disabled />
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='사유' isRequired>
							<CRCheckBoxGroup
								gap={1.6}
								appearanceType='button'
								type='radio'
								checkType='single'
								options={insCancelOptions}
								value={value}
								onChange={onChange}
							/>
						</CRInputLabel>
					)}
					name='insCancelCd'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='취득일' isRequired>
							<CRInput.DatePicker
								popupPosition={{
									x: 0,
									y: -250,
								}}
								value={value}
								onChangeValue={onChange}
								placeholder='취득일 입력'
							/>
						</CRInputLabel>
					)}
					name='healthInsuranceAcqDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청일' isRequired>
							<CRInput.DatePicker
								popupPosition={{
									x: 0,
									y: -250,
								}}
								value={value}
								onChangeValue={onChange}
								placeholder='신청일 입력'
							/>
						</CRInputLabel>
					)}
					name='requestDate'
					control={formContext.control}
				/>
			</S.SubFormContainer>
		</S.Container>
	);
}
