import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0.8rem 1.6rem;
	box-sizing: border-box;
	gap: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
`;

export const Title = styled.div`
	${(props) => props.theme.typography.bodyB}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 0.8rem;
`;

export const Information = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
`;

export const ItemLabel = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	min-width: 12rem;
	max-width: 12rem;
`;

export const ItemValue = styled.span`
	${(props) => props.theme.typography.label}
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const HighlightItemLabel = styled.span`
	${(props) => props.theme.typography.label}
	min-width: 2.4rem;
	max-width: 12rem;
	color: ${(props) => props.theme.colors.gray10};
`;

export const HighlightItemValue = styled.span`
	${(props) => props.theme.typography.label}
	display: flex;
	align-items: center;
	gap: 0.8rem;
	color: ${(props) => props.theme.colors.gray10};
`;

export const SuffixValue = styled.span`
	color: ${(props) => props.theme.colors.gray50};
`;

export const Divider = styled.hr`
	width: 100%;
	height: 0.1rem;
	border: none;
	background-color: ${(props) => props.theme.colors.gray90};
	margin: 0;
`;
