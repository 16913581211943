import { CRTableHeadConfig } from 'types/view/base';

export const STAFF_LICENSE_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '40rem',
		label: '종류',
		sortKey: '취득일',
	},
	{
		width: '16rem',
		label: '자격증번호',
		sortKey: '자격증번호',
	},
	{
		width: '9.6rem',
		label: '발급일',
		sortKey: '발급일',
	},
	{
		width: '9.6rem',
		label: '발급 기관',
		sortKey: '취득기관',
	},
	{
		width: '18.4rem',
		label: '첨부파일',
		sortKey: '첨부파일',
	},
	{
		width: '20rem',
		label: '',
		sortKey: '',
	},
];
