import styled, { css } from 'styled-components';

export const Container = styled.ul`
	position: absolute;
	z-index: 100;
	flex-direction: column;
	width: fit-content;
	min-width: 16rem;
	overflow: auto;
	overflow-y: overlay;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
	background: ${(props) => props.theme.colors.gray100};
	box-shadow: ${(props) => props.theme.elevation.elevation01};
	box-sizing: border-box;
	padding: 0.8rem 0;
`;

export const ItemContainer = styled.li<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.body}
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 5.6rem;
	width: 100%;
	padding: 0 1.8rem;
	box-sizing: border-box;
	white-space: nowrap;

	&:hover {
		background: ${(props) => props.theme.colors.gray99};
	}

	${(props) =>
		props.$isActive &&
		css`
			background: ${props.theme.colors.gray99};
		`}
`;
