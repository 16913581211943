import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import useFullScreen from 'lib/hook/util/useFullScreen';
import RouterPath from 'common/router';
import CRScrollSpy from 'components/base/CRScrollSpy';
import ContractTaskLayout from 'components/domain/layout/TaskLayout';

import * as S from './styles';

function ScheduleTaskPage(): React.ReactElement {
	const location = useLocation();
	const navigate = useNavigate();
	const navigation = useMemo(
		() => [
			{
				label: '일정생성',
				subSections: [
					{
						label: '',
						items: [
							{
								label: '일정등록',
								key: RouterPath.myTaskOperate().registration.key,
								onClick: () => navigate(RouterPath.myTaskOperate().registration.key),
							},
							{
								label: '자동화 공단 등록',
								key: RouterPath.myTaskOperate().automation.key,
								onClick: () => navigate(RouterPath.myTaskOperate().automation.key),
							},
							{
								label: '일정 오류 수정',
								key: RouterPath.myTaskOperate().errorEdit.key,
								onClick: () => {
									navigate(RouterPath.myTaskOperate().errorEdit.key);
								},
							},
						],
					},
				],
			},
		],
		[],
	);

	const LeftSideComponent = useMemo(
		() => (
			<S.LeftSideContainer>
				<CRScrollSpy currentKey={location.pathname} sections={navigation} hideDivider hideHeader />
			</S.LeftSideContainer>
		),
		[location.pathname],
	);

	useFullScreen();

	return (
		<ContractTaskLayout LeftSideComponent={LeftSideComponent}>
			<Outlet />
		</ContractTaskLayout>
	);
}

export default ScheduleTaskPage;
