import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
	useCenterList,
	useCenterManagers,
	useCommonCodes,
	useContractRenewalDetail,
	useMyAccountInfo,
	useRenewalRecipientContract,
	useSaveRenewalRecipientContract,
	useUpdateRecipientOtherServices,
} from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { endpoint } from 'lib/service/Api/endpoint';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { ContractRenewalDetailDTO } from 'types/dto';
import { ContractStatusType } from 'types/view/common';
import { Toast } from '../../../components/base/CRToast';

interface ContractRenewalTaskContextType {
	form: UseFormReturn<ContractRenewalDetailDTO>;
	commonCodes?: {
		[key: string]: CheckOption[];
	};
	renewalRecipientContract?: () => void;
	saveRenewalRecipientContractTemp?: (configs?: { disableToast?: boolean }) => void;
	validate?: (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => void;
	isLoaded?: boolean;
	disabled?: boolean;
}

export const ContractRenewalTaskContext =
	React.createContext<ContractRenewalTaskContextType | null>(null);

function ContractRenewalTaskProvider({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	const params = useParams<{ contractId?: string }>();
	const [isLoaded, setIsLoaded] = useState(false);
	const { showDialog } = useGlobalLayout();
	const { data: myAccountInfo } = useMyAccountInfo();
	const renewalRecipientContractMutation = useRenewalRecipientContract();
	const saveRenewalRecipientContractMutation = useSaveRenewalRecipientContract();
	const updateRecipientOtherService = useUpdateRecipientOtherServices((client, _, variables) => {
		client.invalidateQueries([endpoint.getRecipient.key, { recipientId: variables.recipientId }]);
		client.invalidateQueries([
			endpoint.getRecipientBase.key,
			{ recipientId: variables.recipientId },
		]);
	});

	const { data } = useContractRenewalDetail({
		serviceContractGroupUuid: params.contractId ?? '',
	});

	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes(
		{
			comCdGroupNms: [
				'CMN006',
				'CMN041',
				'CMN042',
				'CMN043',
				'CMN162',
				'CMN164',
				'CMN143',
				'CMN038',
				'CMN020',
				'CMN070',
				'CMN084',
				'CMN064',
				'CMN037',
				'CMN133',
				'CMN068',
				'CMN020',
				'CMN149',
				'CMN070',
				'CMN067',
				'CMN180',
			],
		},
		commonCodeAdapter,
	);

	const { data: centerManager } = useCenterManagers({ centerId: myAccountInfo?.centerId });
	const { data: centers } = useCenterList();

	const centerManagerOption = (centerManager ?? []).map(
		(item) => ({ label: item.userNm, value: item.memberAccountId } as CheckOption),
	);
	const centerOption = (centers ?? []).map(
		(item) => ({ label: item.centerNm, value: item.centerId } as CheckOption),
	);

	const form = useForm<ContractRenewalDetailDTO>();

	const validate = (config?: { ignoreCheck?: boolean }) => {
		if (!form.getValues('recipient.base.addressInfo.basAddress')) {
			Toast.error('수급자 주소를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.base.addressInfo.detailAddress')) {
			Toast.error('수급자 상세 주소를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.base.birthDt')) {
			Toast.error('수급자 생년월일을 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.base.signMethodCd')) {
			Toast.error('수급자 서명 방법을 입력해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.longTerm.longTermNo') ||
			!form.getValues('recipient.longTerm.longTermMajorChangeNo') ||
			!form.getValues('recipient.longTerm.longTermMinorChangeNo')
		) {
			Toast.error('수급자 장기요양인정번호를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermGradeCd')) {
			Toast.error('수급자 장기요양인정등급을 입력해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.longTerm.longTermStartDate') ||
			!form.getValues('recipient.longTerm.longTermEndDate')
		) {
			Toast.error('수급자 장기요양인정기간을 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.burdenRateCd')) {
			Toast.error('수급자 장기요양 본인부담률을 입력해주세요');
			return false;
		}
		if (
			form
				.getValues('recipient.longTerm.longTermServices')
				.some(
					(item) =>
						form
							.getValues('services')
							.find((service) => service.serviceTypeCd === item.serviceTypeCd) &&
						(!item.longTermPayFee ||
							!item.serviceCnt ||
							!item.serviceCntStandardCd ||
							!item.serviceOfferTimeCd),
				)
		) {
			Toast.error('수급자 장기요양 급여한도를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermUsePlanFile')) {
			Toast.error('수급자 개인별 장기 요양 인정 이용 계획서를 등록해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermCertiFile')) {
			Toast.error('수급자 개인별 장기 요양 인정서를 등록해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.guardians')?.every((item) => {
				if (item.mainGuardianYn) {
					return (
						item.guardianNm &&
						item.guardianPhoneNo &&
						item.addressInfo?.detailAddress &&
						item.addressInfo?.basAddress &&
						item.guardianBirthDt &&
						item.guardianRelCd
					);
				}
				return item.guardianNm && item.guardianPhoneNo && item.guardianRelCd;
			})
		) {
			Toast.error('수급자 보호자 정보를 등록해주세요');
			return false;
		}
		if (
			!(
				(form.getValues('recipient.recipientReceipt.cashReceiptIssueYn') !== undefined &&
					!form.getValues('recipient.recipientReceipt.cashReceiptIssueYn')) ||
				(form.getValues('recipient.recipientReceipt.cashReceiptIssueYn') &&
					form.getValues('recipient.recipientReceipt.cashReceiptIssuerNm') &&
					form.getValues('recipient.recipientReceipt.cashReceiptIssueMethodCd') &&
					form.getValues('recipient.recipientReceipt.cashReceiptIssuerMobilePhoneNo'))
			)
		) {
			Toast.error('수급자 현금영수증 정보를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('services')
				.some(
					(item) =>
						!item.use?.monthPerWorkHourCnt ||
						!item.use.serviceEndDate ||
						!item.use.serviceStartDate ||
						!item.use.serviceTimes.length,
				)
		) {
			Toast.error('급여 정보의 이용 정보를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('services')
				.some(
					(item) =>
						!item.associates?.recipientContractAssignDate ||
						item.associates.contractEmployees?.some(
							(employee) => !employee.employeeContractAssignDate || !employee.employeeId,
						),
				)
		) {
			Toast.error('급여 정보의 계약 정보를 등록해주세요');
			return false;
		}
		if (
			!(
				form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') &&
				(!(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10') ||
					(form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountNo') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransBankCd') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountValidYn'))) &&
				(!(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.30') ||
					form.getValues('inspection.burdenAmt.burdenAmtPayDeductEmployeeId'))
			)
		) {
			Toast.error('점검의 본인부담금 정보를 등록해주세요');
			return false;
		}
		if (
			!config?.ignoreCheck &&
			!(
				form.getValues('inspection.check.recipientIaWriteYn') &&
				form.getValues('inspection.check.salaryOfferPlanWriteYn') &&
				form.getValues('inspection.check.admsnUsereqUsedescLocalgovSendYn') &&
				form.getValues('inspection.check.longTermUsePlanPcorpBrowseRequestYn') &&
				(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') !== 'CMN041.10' ||
					form.getValues('inspection.check.autotransUploadYn'))
			)
		) {
			Toast.error('점검의 점검 체크를 등록해주세요');
			return false;
		}
		return true;
	};

	const renewalRecipientContract = async () => {
		if (renewalRecipientContractMutation.isLoading) return;
		if (!validate()) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='갱신 완료'
				content='계약 갱신을 완료하고 저장합니다.'
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
					callback: () => hideDialog(),
				}}
				successOption={{
					text: '확인',
					successCallback: async () => {
						try {
							await updateRecipientOtherService.mutateAsync({
								recipientId: form.getValues('recipient.base.recipientId'),
								body: (form.getValues('recipient.otherServiceUses') ?? []).map((item) => ({
									otherCenterNm: item.otherCenterNm,
									otherCenterPhoneNo: item.otherCenterPhoneNo,
									serviceTypeCd: item.serviceTypeCd,
									remark: item.remark,
									times: item.times,
								})),
							});
							await renewalRecipientContractMutation.mutateAsync({
								...form.getValues(),
								recipient: {
									...form.getValues('recipient'),
									longTerm: {
										...form.getValues('recipient.longTerm'),
										longTermNo: `L${form.getValues('recipient.longTerm.longTermNo')}`,
									},
								},
								inspection: {
									...form.getValues('inspection'),
									check: {
										...form.getValues('inspection.check'),
										pcorpScheduleRegistYn: false,
										wardReportCompleteYn: false,
										hopeiumFormWriteYn: false,
										tagBeaconRequestYn: false,
										liabilityInsuranceRegistNeedYn: false,
										pcorpSalaryContractCancelYn: false,
										autotransUploadYn: false,
										insuranceRequestYn: false,
										wardEndReportCompleteYn: false,
										lastConfirmDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
										lastCheckerId: form.getValues('recipient.socialWorkerId'),
									},
								},
							});
							hideDialog();
							navigate(`/contract`);
						} catch (error: any) {
							Toast.error(error.message ?? '갱신 계약 완료가 실패하였습니다.');
							hideDialog();
						}
					},
				}}
			/>
		));
	};

	const saveRenewalRecipientContractTemp = async (configs?: { disableToast?: boolean }) => {
		try {
			if (saveRenewalRecipientContractMutation.isLoading) return;
			await updateRecipientOtherService.mutateAsync({
				recipientId: form.getValues('recipient.base.recipientId'),
				body: (form.getValues('recipient.otherServiceUses') ?? []).map((item) => ({
					otherCenterNm: item.otherCenterNm,
					otherCenterPhoneNo: item.otherCenterPhoneNo,
					serviceTypeCd: item.serviceTypeCd,
					remark: item.remark,
					times: item.times,
				})),
			});
			await saveRenewalRecipientContractMutation.mutateAsync({
				...form.getValues(),
				recipient: {
					...form.getValues('recipient'),
					longTerm: {
						...form.getValues('recipient.longTerm'),
						longTermNo: `L${form.getValues('recipient.longTerm.longTermNo')}`,
					},
				},
			});
			if (configs?.disableToast) return;
			Toast.success('계약이 저장되었습니다.');
		} catch {
			if (configs?.disableToast) return;
			Toast.error('계약 저장에 실패하였습니다.');
		}
	};

	const disabled = useMemo(
		() =>
			[ContractStatusType.Complete, ContractStatusType.Cancel, ContractStatusType.Pending].includes(
				form.watch('contractBaseInfo.contractStateCd') as ContractStatusType,
			),
		[form.watch('contractBaseInfo.contractStateCd')],
	);

	const value = useMemo(
		() => ({
			form,
			isLoaded,
			commonCodes: { ...commonCodes, centerManagerOption, centerOption },
			renewalRecipientContract,
			saveRenewalRecipientContractTemp,
			validate,
			disabled,
		}),
		[
			form,
			isLoaded,
			commonCodes,
			centerManagerOption,
			renewalRecipientContract,
			saveRenewalRecipientContractTemp,
			validate,
			disabled,
		],
	);

	useEffect(() => {
		if (data) {
			form.reset({
				...data,
				recipient: data?.recipient
					? {
							...data?.recipient,
							longTerm: data.recipient?.longTerm
								? {
										...data.recipient?.longTerm,
										longTermNo: data.recipient?.longTerm.longTermNo?.replaceAll('L', ''),
								  }
								: undefined,
					  }
					: undefined,
				inspection: data?.inspection
					? {
							...data.inspection,
							check: data?.inspection?.check
								? {
										...data?.inspection?.check,
								  }
								: undefined,
					  }
					: undefined,
			});
			setIsLoaded(true);
		}
	}, [data]);

	return (
		<ContractRenewalTaskContext.Provider value={value}>
			{children}
		</ContractRenewalTaskContext.Provider>
	);
}

export default React.memo(ContractRenewalTaskProvider);
