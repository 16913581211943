import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const SCHEDULE_TASK_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '',
		disabledPadding: true,
	},
	{
		width: '12rem',
		label: '업무유형',
		sortKey: 'taskTypeNm',
	},
	{
		width: '36rem',
		label: '제목',
		sortKey: 'taskTitle',
	},
	{
		width: '16rem',
		label: '시작일',
		sortKey: 'startDate',
	},
	{
		width: '16rem',
		label: '마감일자',
		sortKey: 'endDate',
	},
	{
		width: '30rem',
		label: '진행상황',
		sortKey: 'taskProgressRate',
	},
];

export const MY_WORK_LIST_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: 'CMN121.20',
		label: '진행중',
		color: 'blue',
	},
	{
		key: 'CMN121.30',
		label: '완료',
		color: 'green',
	},
	{
		key: 'CMN121.10',
		label: '시작전',
		color: 'gray',
	},
];
