import React, {
	ChangeEvent,
	ForwardedRef,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import * as Pop from '@radix-ui/react-popover';

import Assets from 'assets';

import { CheckOption } from 'components/base/Selections/type';
import * as S from './styles';

interface IProps<T> {
	currentValue?: CheckOption<T>;
	items?: CheckOption<T>[];
	disabled?: boolean;
	renderItem?: (item: CheckOption<T>) => React.ReactElement;
	renderOptionItem?: (item: CheckOption<T>) => React.ReactElement;
	autoComplete?: boolean;
	label?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	placeholder?: string;
	type?: 'default' | 'small' | 'tableHeader';
	topOffset?: number;
	onChangeValue?: (value: CheckOption<T>) => void;
	onSearch?: (keyword: string) => void;
}

function CRSelector<T>(
	{
		currentValue,
		items = [],
		disabled = false,
		placeholder,
		type = 'default',
		label,
		addOnBottom = '',
		status = 'default',
		autoComplete = false,
		topOffset,
		onChangeValue,
		renderItem: customRenderItem,
		renderOptionItem,
		onSearch,
	}: IProps<T>,
	ref?: ForwardedRef<HTMLDivElement>,
): React.ReactElement {
	const containerRef = useRef<HTMLDivElement>(null);
	const itemListRef = useRef<HTMLUListElement>(null);
	const itemRef = useRef<HTMLButtonElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	// currentValue의 label이 들어가는 텍스트의 ref(overflow여부 체크)
	const innerItemRef = useRef<HTMLDivElement>(null);

	const autoCompleteTextInputRef = useRef<HTMLInputElement>(null);
	const [autoCompleteText, setAutoCompleteText] = useState<any>('');

	const [position, setPosition] = useState({
		top: 0,
		left: 0,
		height: 0,
		width: 0,
	});
	const [keyboardCursorIndex, setKeyboardCursorIndex] = useState(-1);
	const [focus, setFocus] = useState(false);

	const filteredItems = useMemo(
		() => (autoComplete ? items?.filter((item) => item.label?.includes(autoCompleteText)) : items),
		[items, autoCompleteText, autoComplete],
	);

	const renderItem = (item: CheckOption): React.ReactElement => {
		const label = items.find((target) => target.value === item.value)?.label;
		if (customRenderItem) return customRenderItem(item);
		return (
			<S.Item ref={innerItemRef} $disabled={disabled} $type={type}>
				{label}
			</S.Item>
		);
	};

	const handleChangeAutoCompleteText = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setAutoCompleteText(event.target.value);
		onSearch?.(event.target.value);
	}, []);

	const handleClickItem = (item: CheckOption) => {
		onChangeValue?.(item);
		itemListRef.current?.scrollTo({ top: 0 });
		setKeyboardCursorIndex(-1);
	};

	const handleChangeAutoKeyDown = useCallback(
		(event: KeyboardEvent) => {
			switch (event.key) {
				case 'ArrowDown':
					event.preventDefault();
					if (keyboardCursorIndex === filteredItems.length - 1) {
						itemListRef.current?.scrollTo({ top: 0 });
						setKeyboardCursorIndex(-1);
						return;
					}
					itemListRef.current?.scrollTo({
						top: (keyboardCursorIndex + 1) * position.height,
						behavior: 'smooth',
					});
					setKeyboardCursorIndex(keyboardCursorIndex + 1);
					return;
				case 'ArrowUp':
					event.preventDefault();
					if (keyboardCursorIndex === -1) {
						itemListRef.current?.scrollTo({ top: (filteredItems.length - 2) * position.height });
						setKeyboardCursorIndex(filteredItems.length - 1);
						return;
					}

					itemListRef.current?.scrollTo({ top: (keyboardCursorIndex - 1) * position.height });
					setKeyboardCursorIndex(keyboardCursorIndex - 1);
					return;
				case 'Enter':
					event.preventDefault();
					if (keyboardCursorIndex !== -1) handleClickItem(filteredItems[keyboardCursorIndex]);
					if (currentValue?.label) setAutoCompleteText(currentValue?.label);
					autoCompleteTextInputRef.current?.blur();
					itemRef.current?.click();
					break;
				default:
					break;
			}
		},
		[keyboardCursorIndex, filteredItems, position],
	);

	const CurrentValueComponent = useMemo(() => {
		if (autoComplete && focus) {
			return (
				<S.TextInput
					$disabled={disabled}
					placeholder={placeholder}
					ref={autoCompleteTextInputRef}
					value={autoCompleteText}
					onChange={handleChangeAutoCompleteText}
				/>
			);
		}
		return (
			<S.Content>
				{currentValue ? (
					renderItem(currentValue)
				) : (
					<S.PlaceHolder $type={type}>{placeholder}</S.PlaceHolder>
				)}
				{autoComplete ? (
					<S.UnfoldMoreIcon alt='unfoldMore' src={Assets.icon.unfoldMore} />
				) : (
					<S.Icon
						alt='keyboardArrowBottom'
						$disabled={disabled}
						src={Assets.icon.keyboardArrowBottom}
					/>
				)}
			</S.Content>
		);
	}, [
		autoComplete,
		currentValue,
		renderItem,
		handleChangeAutoCompleteText,
		placeholder,
		type,
		focus,
	]);

	const handleResizeEvent = useCallback(() => {
		const boundingClientRect = containerRef.current?.getBoundingClientRect();
		if (!boundingClientRect) return;

		const { height, width } = boundingClientRect;
		setPosition({ top: height - 1, left: 0, height, width });
	}, []);

	useEffect(() => {
		handleResizeEvent();
		document.addEventListener('resize', handleResizeEvent);

		return () => {
			document.removeEventListener('resize', handleResizeEvent);
		};
	}, [handleResizeEvent]);

	useEffect(() => {
		if (focus && autoComplete) {
			autoCompleteTextInputRef.current?.focus();
		}
		if (focus) {
			document.addEventListener('keydown', handleChangeAutoKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleChangeAutoKeyDown);
		};
	}, [focus, handleChangeAutoKeyDown, autoComplete]);

	useEffect(() => {
		if (autoComplete && currentValue?.label) {
			setAutoCompleteText(currentValue.label);
		}
	}, [currentValue, autoComplete]);

	useEffect(() => {
		if (wrapperRef.current && innerItemRef.current && currentValue) {
			const element = innerItemRef.current;
			// 텍스트가 overflow 되어 ellipsis가 적용된 상태인지 확인 , 5는 마진
			if (element.scrollWidth > 330 && element.scrollWidth > element.clientWidth + 5) {
				wrapperRef.current.title = currentValue?.label || ''; // title 속성에 전체 텍스트 추가
			} else {
				wrapperRef.current.title = ''; // ellipsis가 적용되지 않았다면 title 제거
			}
		}
	}, [currentValue]);

	return (
		<div ref={ref}>
			<RdPopover
				openCallback={() => !disabled && setFocus(true)}
				closeCallback={() => !disabled && setFocus(false)}
				sideOffset={0}
				showDivider={false}
				borderShape='none'
				content={
					!disabled && (
						<S.ItemListContainer
							$isEmpty={filteredItems.length === 0}
							ref={itemListRef}
							style={{
								width: wrapperRef.current?.clientWidth,
								top:
									topOffset ||
									(wrapperRef.current?.clientHeight
										? wrapperRef.current.clientHeight / 2
										: position.top),
								left: position.left,
							}}
							$type={type}>
							{filteredItems.map((item, index) => (
								<Pop.Close
									ref={itemRef}
									style={{
										background: 'transparent',
										border: 0,
										padding: 0,
										margin: 0,
										cursor: 'pointer',
										outline: 'none',
									}}>
									<S.ItemContainer
										key={`${item.value}-${item.label}`}
										$type={type}
										onClick={() => handleClickItem(item)}
										$isActive={index === keyboardCursorIndex}>
										{renderOptionItem ? renderOptionItem(item) : item.label}
									</S.ItemContainer>
								</Pop.Close>
							))}
						</S.ItemListContainer>
					)
				}>
				<S.Container ref={wrapperRef} $isDisabled={disabled}>
					<S.ContentContainer
						$type={type}
						$hasValue={!!currentValue}
						ref={containerRef}
						$hasLabel={!!label}
						$isDisabled={disabled}
						$hasError={status === 'error'}>
						{CurrentValueComponent}
					</S.ContentContainer>
				</S.Container>
			</RdPopover>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</div>
	);
}

export default forwardRef(CRSelector);
