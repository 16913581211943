import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Label = styled.label`
	${(props) => props.theme.typography.label};
`;

export const InputContainer = styled.div<{ $isSingleUploaded: boolean; $isDragOver: boolean }>`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	margin-top: 0.4rem;
	padding: 0 1.6rem;
	height: 5.6rem;
	background: ${(props) => props.theme.colors.gray100};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	overflow: hidden;
	box-sizing: border-box;

	${(props) =>
		(props.$isSingleUploaded || props.$isDragOver) &&
		css`
			background: ${(props) => props.theme.colors.gray99};
		`};
`;

export const FileInput = styled.input`
	position: absolute;
	width: 0;
	height: 0;
	padding: 0;
	overflow: hidden;
	border: 0;
`;

export const CustomInputArea = styled.div<{ $isSingleUploaded: boolean }>`
	${(props) => props.theme.typography.body};
	flex: 1;
	white-space: nowrap;
	color: ${(props) => props.theme.colors.gray60};

	${(props) =>
		props.$isSingleUploaded &&
		css`
			${props.theme.typography.body};
			color: ${props.theme.colors.gray10};
		`};
`;

export const Icon = styled.img<{ $isSingleUploaded?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;
	${(props) => props.theme.filters.gray60};
`;

export const FileList = styled.ul`
	padding: 1.6rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const FileListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
	& + & {
		margin-top: 0.8rem;
	}
`;

export const DownloadContainer = styled.a`
	display: flex;
	align-items: center;
`;

export const FileInfo = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	flex: 1;
	cursor: pointer;
`;

export const FileName = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	text-decoration: underline;
`;

export const FileSize = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const DownloadIcon = styled(Icon)`
	filter: brightness(0) saturate(100%) invert(61%) sepia(6%) saturate(120%) hue-rotate(149deg)
		brightness(94%) contrast(84%);
	cursor: pointer;
`;
