import { styled } from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	width: 100%;
`;

export const FormItemContainer = styled.div`
	width: 100%;
`;

export const HelpText = styled.div`
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	padding: 2.4rem;
`;

export const HelpTextTitle = styled.p`
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const HelpTextLabel = styled.span`
	${(props) => props.theme.typography.body};
	display: block;
	color: ${(props) => props.theme.colors.gray00};
	padding-left: 0.5rem;
`;

export const HelpTextDesc = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray00};
	display: block;
	padding-left: 2rem;
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin: 0.8rem 0;
`;

export const BannerContainer = styled.div`
	margin-top: 0.8rem;
`;
