import { Billing } from 'types/view/billing';

export const BILLING_LIST_MOCK: Billing[] = [
	{
		id: '1',
		date: '23.04',
		type: '원청구',
		recipient: '김민수(300101)',
		service: '가족요양 60분',
		ownExpenseAmount: '200,000원',
		totalAmount: '600,000원',
		center: '서초센터',
		manager: '전태윤',
		status: '청구확정',
		totalWorkHour: '45시간',
		schedule: '30일',
		ownExpenseRate: '15%',
		taskStatus: '수정됨',
	},
	{
		id: '2',
		date: '23.04',
		type: '원청구',
		recipient: '김민수(300101)',
		service: '가족요양 60분',
		ownExpenseAmount: '200,000원',
		totalAmount: '600,000원',
		center: '서초센터',
		manager: '전태윤',
		status: '환수확정',
		totalWorkHour: '45시간',
		schedule: '30일',
		ownExpenseRate: '15%',
		taskStatus: '특정내용 등록 필요',
	},
	{
		id: '3',
		date: '23.04',
		type: '원청구',
		recipient: '김민수(300101)',
		service: '가족요양 60분',
		ownExpenseAmount: '200,000원',
		totalAmount: '600,000원',
		center: '서초센터',
		manager: '전태윤',
		status: '환수진행',
		totalWorkHour: '45시간',
		schedule: '30일',
		ownExpenseRate: '15%',
		taskStatus: '청구 생성 실패',
	},
	{
		id: '4',
		date: '23.04',
		type: '원청구',
		recipient: '김민수(300101)',
		service: '가족요양 60분',
		ownExpenseAmount: '200,000원',
		totalAmount: '600,000원',
		center: '서초센터',
		manager: '전태윤',
		status: '청구확정',
		totalWorkHour: '45시간',
		schedule: '30일',
		ownExpenseRate: '15%',
		taskStatus: '검토필요',
	},
	{
		id: '5',
		date: '23.04',
		type: '원청구',
		recipient: '김민수(300101)',
		service: '가족요양 60분',
		ownExpenseAmount: '200,000원',
		totalAmount: '600,000원',
		center: '서초센터',
		manager: '전태윤',
		status: '청구확정',
		totalWorkHour: '45시간',
		schedule: '30일',
		ownExpenseRate: '15%',
		taskStatus: '등록완료',
	},
	{
		id: '6',
		date: '23.04',
		type: '원청구',
		recipient: '김민수(300101)',
		service: '가족요양 60분',
		ownExpenseAmount: '200,000원',
		totalAmount: '600,000원',
		center: '서초센터',
		manager: '전태윤',
		status: '청구확정',
		totalWorkHour: '45시간',
		schedule: '30일',
		ownExpenseRate: '15%',
		taskStatus: '수정됨',
	},
];
