import React from 'react';
import { InsuranceAcqStateCd } from 'types/api/insurance';
import CRToolTip from 'components/base/CRToolTip';
import dayjs from 'dayjs';
import * as S from './styles';

interface Props {
	accidentInsuranceAcqStateCd?: string;
	accidentInsuranceAcqStateNm?: string;
	accidentInsuranceAcqDate?: string;
	accidentInsuranceLossDate?: string;
	employInsuranceAcqStateCd?: string;
	employInsuranceAcqStateNm?: string;
	employInsuranceAcqDate?: string;
	employInsuranceLossDate?: string;
	healthInsuranceAcqStateCd?: string;
	healthInsuranceAcqStateNm?: string;
	healthInsuranceAcqDate?: string;
	healthInsuranceLossDate?: string;
	pensionInsuranceAcqStateCd?: string;
	pensionInsuranceAcqStateNm?: string;
	pensionInsuranceAcqDate?: string;
	pensionInsuranceLossDate?: string;
	height?: string;
}

function InsuranceInfoColumn({
	accidentInsuranceAcqStateCd,
	accidentInsuranceAcqStateNm,
	accidentInsuranceAcqDate,
	accidentInsuranceLossDate,
	employInsuranceAcqStateCd,
	employInsuranceAcqStateNm,
	employInsuranceAcqDate,
	employInsuranceLossDate,
	healthInsuranceAcqStateCd,
	healthInsuranceAcqStateNm,
	healthInsuranceAcqDate,
	healthInsuranceLossDate,
	pensionInsuranceAcqStateCd,
	pensionInsuranceAcqStateNm,
	pensionInsuranceAcqDate,
	pensionInsuranceLossDate,
	height,
}: Props) {
	const renderItem = [
		{
			label: '국민',
			InsuranceAcqStateCd: pensionInsuranceAcqStateCd,
			InsuranceAcqStateNm: pensionInsuranceAcqStateNm,
			InsuranceAcqDate: pensionInsuranceAcqDate,
			InsuranceLossDate: pensionInsuranceLossDate,
		},

		{
			label: '건강',
			InsuranceAcqStateCd: healthInsuranceAcqStateCd,
			InsuranceAcqStateNm: healthInsuranceAcqStateNm,
			InsuranceAcqDate: healthInsuranceAcqDate,
			InsuranceLossDate: healthInsuranceLossDate,
		},
		{
			label: '고용',
			InsuranceAcqStateCd: employInsuranceAcqStateCd,
			InsuranceAcqStateNm: employInsuranceAcqStateNm,
			InsuranceAcqDate: employInsuranceAcqDate,
			InsuranceLossDate: employInsuranceLossDate,
		},
		{
			label: '산재',
			InsuranceAcqStateCd: accidentInsuranceAcqStateCd,
			InsuranceAcqStateNm: accidentInsuranceAcqStateNm,
			InsuranceAcqDate: accidentInsuranceAcqDate,
			InsuranceLossDate: accidentInsuranceLossDate,
		},
	];

	return (
		<td>
			<S.TableLabelColumn $height={height}>
				{renderItem.map((item, index) => {
					const isAcq = item.InsuranceAcqStateCd === InsuranceAcqStateCd.가입;
					const isEmpty = !item.InsuranceAcqStateCd;
					const placement = renderItem.length - 1 === index ? 'right' : 'down';
					return (
						<S.Block>
							<S.Chip $isActive={isAcq}>
								<CRToolTip
									placement={placement}
									title={
										isEmpty ? (
											'이력없음'
										) : (
											// eslint-disable-next-line react/jsx-no-useless-fragment
											<>
												{isAcq ? (
													<div>{dayjs(item.InsuranceAcqDate).format(`취득일 : YYYY.MM.DD`)}</div>
												) : (
													<div>
														{dayjs(item.InsuranceAcqDate).format(`취득일 : YYYY.MM.DD`)}
														<br />
														{dayjs(item.InsuranceLossDate).format(`상실일 : YYYY.MM.DD`)}
													</div>
												)}
											</>
										)
									}>
									{item.label}
								</CRToolTip>
							</S.Chip>
						</S.Block>
					);
				})}
			</S.TableLabelColumn>
		</td>
	);
}

export default InsuranceInfoColumn;
