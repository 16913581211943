import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RouterPath from 'common/router';
import ScheduleTaskTable from 'components/domain/table/ScheduleTaskTable';
import CRTable from 'components/base/CRTable';
import { Task, TaskAction } from 'types/view/task';
import { MySubTaskContentDTO, MySubTaskRequestDTO, MyTaskContentDTO } from 'types/api/myTask';
import { useMyTaskProcess, useMyTaskTypes, useSubMyTask } from 'lib/hook/react-query';
import { PageInfo } from 'types/view/base';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { Filter } from 'components/base/CRTableHeader';
import { FilterType } from 'types/view/filter';

interface IProps {
	onClickNewTask: () => void;
}

function ProgressTab<T extends { label: string; value: any }>({
	onClickNewTask,
}: IProps): React.ReactElement {
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [searchValue, setSearchValue] = useState('');
	const { data: myTaskTypes } = useMyTaskTypes();

	const filters = useMemo(
		() =>
			[
				{
					key: 'taskTypeCd',
					type: 'single',
					options:
						myTaskTypes?.data?.map(
							(item) =>
								({
									label: item.taskTypeNm,
									value: item.taskTypeCd,
								} as T),
						) ?? [],
					placeholder: '업무유형',
				},
			] as Array<Filter<T>>,
		[myTaskTypes],
	);
	const [currentFilter, setCurrentFilter] = useState<{
		[key: string]: T[];
	}>({});
	const [keyword, setKeyword] = useState('');
	const { data: myTask } = useMyTaskProcess({
		size: pageInfo.size,
		page: pageInfo.page,
		keyword,
		taskTypeCd: currentFilter?.taskTypeCd?.[0].value,
	});

	const subMyTaskMutation = useSubMyTask();
	const navigate = useNavigate();

	const getSubLink = (category: TaskAction) => {
		const subPaths = RouterPath.myTaskOperate();
		if (category === TaskAction.RecipientBasic) {
			return subPaths.recipientBasic.key;
		}
		if (category === TaskAction.IntegratedAssessment) {
			return subPaths.integratedAssessment.key;
		}
		if (category === TaskAction.Manager) {
			return subPaths.manager.key;
		}
		if (category === TaskAction.ServiceInspection) {
			return subPaths.serviceInspection.key;
		}
		if (category === TaskAction.ContractInspection) {
			return subPaths.contractInspection.key;
		}
		if (category === TaskAction.SCHEDULE_REGISTRATION) {
			return subPaths.registration.key;
		}
		if (category === TaskAction.AUTOMATION) {
			return subPaths.automation.key;
		}
		if (category === TaskAction.SCHEDULE_ERROR_EDIT) {
			return subPaths.errorEdit.key;
		}
		if (category === TaskAction.TEST) {
			return subPaths.contract.key;
		}
		return subPaths.contract.key;
	};

	const getLink = (category: Task) => {
		const subPaths = RouterPath.myTaskOperate();
		if (category === Task.MONITORING) {
			return subPaths.monitoring.key;
		}

		return `${subPaths.billing.key}`;
	};

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const handleClickSubTask = (subTask: MySubTaskContentDTO) => {
		const link = getSubLink(subTask.category);
		navigate(link);
	};

	const handleClickTask = (task: MyTaskContentDTO) => {
		const link = getLink(task.category);
		navigate(link);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const onChangeFilter = (item: T, key: string, filterType: FilterType) => {
		const prevFilter = Object.keys(currentFilter).length === 0 ? {} : { ...currentFilter };

		if (filterType === 'single') {
			const newItems = { ...prevFilter };
			const exist = prevFilter[key]?.find((selectedItem) => selectedItem.value === item.value);
			if (exist) {
				delete newItems[key];
			} else {
				newItems[key] = [item];
			}
			setCurrentFilter({
				...newItems,
			});
		} else if (['multi', 'toggle'].includes(filterType)) {
			if (!prevFilter[key]) {
				setCurrentFilter({
					...prevFilter,
					[key]: [item],
				});
			} else {
				const newItems = { ...prevFilter };
				const exist = prevFilter[key].find((selectedItem) => selectedItem.value === item.value);
				if (exist) {
					newItems[key] = prevFilter[key].filter((prevItem) => prevItem.value !== item.value);
				} else {
					newItems[key] = [...prevFilter[key], item];
				}
				setCurrentFilter(newItems);
			}
		} else if (['calendar', 'month_calendar'].includes(filterType)) {
			setCurrentFilter({
				...prevFilter,
				[key]: [item],
			});
		}
	};

	const dataLoader = async (param: MySubTaskRequestDTO) => {
		const result = await subMyTaskMutation.mutateAsync(param);
		return result?.data ?? [];
	};

	useEffect(() => {
		setPageInfo({
			...pageInfo,
			totalPages: myTask?.data?.myTaskData.totalPages ?? 0,
		});
	}, [myTask]);

	return (
		<CRTable.BackBlind>
			<ScheduleTaskTable
				filters={filters}
				onChangeFilter={onChangeFilter}
				currentValue={currentFilter}
				items={myTask?.data?.myTaskData.content ?? []}
				onClickButton={onClickNewTask}
				onClickSubTask={handleClickSubTask}
				onClickTask={handleClickTask}
				dataLoader={dataLoader}
				pageInfo={pageInfo}
				onChangePageInfo={handleChangePageInfo}
				searchValue={searchValue}
				onChangeSearchValue={handleSearchKeyword}
				onSearch={handleSearch}
			/>
		</CRTable.BackBlind>
	);
}

export default ProgressTab;
