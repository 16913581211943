import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';
import CRDialog from 'components/base/CRDialog';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import { OwnExpenseUploadHistory } from 'types/view/ownExpense';

import {
	OWN_EXPENSE_UPLOAD_HISTORY_TABLE_HEADER_CONFIG,
	UPLOAD_HISTORY_STATUS_OPTIONS,
} from './constants';
import * as S from './styles';

interface Props {
	items: OwnExpenseUploadHistory[];
	onClickClose?: () => void;
	onClickItem?: (item: OwnExpenseUploadHistory) => void;
}

function OwnExpenseUploadHistoryDialog({
	items,
	onClickClose,
	onClickItem,
}: Props): React.ReactElement {
	const Status = useCallback(
		(status: string) => <CRStatus options={UPLOAD_HISTORY_STATUS_OPTIONS}>{status}</CRStatus>,
		[onClickItem],
	);

	const Button = useCallback(
		(id: number, item?: OwnExpenseUploadHistory) => {
			if (!item) return <div />;
			return (
				<S.ButtonContainer>
					<CRButton.Default
						type='outlined'
						size='xSmall'
						palette='gray'
						onClick={() => onClickItem?.(item)}>
						결과
					</CRButton.Default>
				</S.ButtonContainer>
			);
		},
		[onClickItem],
	);

	return (
		<CRDialog
			type='L'
			title='본인부담금 업로드 이력'
			body={
				<CRTable.Root>
					<CRTable.Head
						heads={OWN_EXPENSE_UPLOAD_HISTORY_TABLE_HEADER_CONFIG}
						disableBackBlind
						offset={1}
					/>
					<CRTable.Body>
						{items.map((item) => (
							<CRTable.Row
								key={item.id}
								item={item}
								customRender={{
									status: Status,
									id: Button,
								}}
								renderKeys={['status', 'type', 'requestDate', 'completeDate', 'uploader', 'id']}
								customStyle={{
									status: {
										paddingLeft: '2.4rem',
									},
								}}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default OwnExpenseUploadHistoryDialog;
