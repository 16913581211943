import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';
import RouterPath from 'common/router';

export const TAB_CONFIG: TaskTabProps[] = [
	{
		label: '기초 정보',
		key: RouterPath.employee().basic.uniqueKey,
	},
	{
		label: '근무 경력',
		key: RouterPath.employee().career.uniqueKey,
	},
	{
		label: '자격증',
		key: RouterPath.employee().license.uniqueKey,
	},
	{
		label: '교육',
		key: RouterPath.employee().education.uniqueKey,
	},
	{
		label: '급여 관리',
		key: RouterPath.employee().contract.uniqueKey,
	},
	{
		label: '일정 관리',
		key: RouterPath.employee().schedule.uniqueKey,
	},
	{
		label: '임금 내역',
		key: RouterPath.employee().wage.uniqueKey,
	},
	{
		label: '상담 기록',
		key: RouterPath.employee().consulting.uniqueKey,
	},
	{
		label: '발송 이력',
		key: RouterPath.employee().message.uniqueKey,
	},
	{ label: '서류 관리', key: RouterPath.employee().documents.uniqueKey },
];
