import { SideNavigationMenuItem, SideNavigationSectionItem } from 'types/view/navigation';
import RouterPath from 'common/router';

export const hiddenMenuPageList = [
	'/my-center/finance-information/new',
	'/my-center/finance-information/detail',
];

const menu: SideNavigationMenuItem[] = [
	{
		label: '계정',
		path: RouterPath.myCenterOperate().user.key,
	},
	{
		label: '센터 정보',
		path: RouterPath.myCenterOperate().information.key,
	},
	{
		label: '계좌 관리',
		path: RouterPath.myCenterOperate().accountManagement.key,
	},
	{
		label: '재무 정보',
		path: RouterPath.myCenterOperate().financeInformation.key,
	},
	{
		label: '문서',
		path: RouterPath.myCenterOperate().document.key,
	},
];

export default menu;
