import React, { useState } from 'react';

import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import BillingTaskSideModal from 'components/domain/sideModal/BillingTaskSideModal';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useSideModal from 'lib/hook/util/useSideModal';
import { Billing } from 'types/view/billing';

import * as S from './styles';
import { BILLING_LIST_MOCK } from '../BillingPage/mock';
import BillingTaskTable from '../../../components/domain/table/BillingTaskTable';
import { BILLING_TASK_FILTER_TYPE } from '../../../components/domain/table/BillingTaskTable/constant';

function BillingTaskPage(): React.ReactElement {
	const [filter, setFilter] = useState<{ [key in string]: any }>({
		[BILLING_TASK_FILTER_TYPE.ALL]: BILLING_TASK_FILTER_TYPE.ALL,
	});
	const { showSideModal } = useSideModal();

	const handleClickItem = (item: Billing) => {
		showSideModal(<BillingTaskSideModal item={item} />);
	};

	const handleChangeFilter = (item: { label: string; value: string }, key: string) => {
		if (filter[key]) {
			if ((key as BILLING_TASK_FILTER_TYPE) === BILLING_TASK_FILTER_TYPE.ALL) return;
			filter[key] = undefined;
			setFilter({ ...filter });
			return;
		}

		if ((key as BILLING_TASK_FILTER_TYPE) === BILLING_TASK_FILTER_TYPE.ALL) {
			[
				BILLING_TASK_FILTER_TYPE.VISIT_BATH,
				BILLING_TASK_FILTER_TYPE.VISIT_CARE,
				BILLING_TASK_FILTER_TYPE.VISIT_NURSE,
			].forEach((target) => {
				filter[target] = undefined;
			});
		}

		filter[BILLING_TASK_FILTER_TYPE.ALL] = undefined;
		filter[key] = item.value;

		const isAll = [
			BILLING_TASK_FILTER_TYPE.VISIT_BATH,
			BILLING_TASK_FILTER_TYPE.VISIT_CARE,
			BILLING_TASK_FILTER_TYPE.VISIT_NURSE,
		].every((target) => filter[target]);

		if (isAll) {
			[
				BILLING_TASK_FILTER_TYPE.VISIT_BATH,
				BILLING_TASK_FILTER_TYPE.VISIT_CARE,
				BILLING_TASK_FILTER_TYPE.VISIT_NURSE,
			].forEach((target) => {
				filter[target] = undefined;
			});

			filter[BILLING_TASK_FILTER_TYPE.ALL] = BILLING_TASK_FILTER_TYPE.ALL;

			setFilter({ ...filter });
			return;
		}

		setFilter({ ...filter });
	};

	useFullScreen();

	return (
		<S.Container>
			<CRTable.BackBlind
				stickyHeader={
					<S.Header>
						<S.HeaderLeft>
							<S.Title>23년 3월 청구 검토(120건)</S.Title>
							<S.Duration>시작일: 2023.04.01 ~ 마감일: 2023.04.05 (D-4)</S.Duration>
						</S.HeaderLeft>
						<S.HeaderRight>
							<CRButton.Default disabled={false}>결재 요청</CRButton.Default>
						</S.HeaderRight>
					</S.Header>
				}>
				<BillingTaskTable
					items={BILLING_LIST_MOCK}
					currentFilter={filter}
					onClickBilling={handleClickItem}
					setCurrentFilter={setFilter}
				/>
			</CRTable.BackBlind>
		</S.Container>
	);
}

export default BillingTaskPage;
