import { useContext } from 'react';

import { SideModalContext } from 'lib/provider/ui/SideModalProvider';

export default function useSideModal() {
	const context = useContext(SideModalContext);
	if (!context) {
		throw new Error('useSideModal must be used within a SideModalContext');
	}
	return context;
}
