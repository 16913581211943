import { NavigateFunction } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';

import { LocalNavigationSection } from 'types/view/task';
import RouterPath from 'common/router';
import { WorkExecuteLogFormType } from 'types/view/workExecuteLog';
import { WorkExecuteLogCode } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm';

export function generateWorkExecuteLogScrollSpy(
	navigate: NavigateFunction,
	form: UseFormReturn<WorkExecuteLogFormType>,
) {
	const subSectionItems = [
		{
			label: '기본 정보',
			key: `${RouterPath.myTaskOperate().workExecuteLogNew.key}#1`,
			onClick: () => navigate(`#1`),
			isDone: !!form.getValues('workExecuteLogDivCd')?.length,
		},
		{
			label: '방문 정보',
			key: `${RouterPath.myTaskOperate().workExecuteLogNew.key}#2`,
			onClick: () => navigate(`#2`),
			isDone: !!(
				form.getValues('workExecuteLogStateCd') &&
				form.getValues('visitDt') &&
				form.getValues('time.startTime')?.length === 5 &&
				form.getValues('time.endTime')?.length === 5 &&
				form.getValues('visitor') &&
				form.getValues('facilityManager') &&
				form.getValues('visitSchedule')?.length &&
				((form.getValues('salaryOfferVisitYn')?.[0].value === true &&
					!form.getValues('visitDenyDesc')) ||
					(form.getValues('salaryOfferVisitYn')?.[0].value === false &&
						form.getValues('visitDenyDesc')))
			),
		},
		{
			label: '상담',
			key: `${RouterPath.myTaskOperate().workExecuteLogNew.key}#4`,
			onClick: () => navigate(`#4`),
			isDone: !!(form.getValues('client')?.[0] && form.getValues('consultDesc')),
		},
		{
			label: '급여 및 인지활동프로그램 제공 확인',
			key: `${RouterPath.myTaskOperate().workExecuteLogNew.key}#5`,
			onClick: () => navigate(`#5`),
			isDone: !!(
				form.getValues('salaryrecogActpgOfferConfirmDesc') &&
				form.getValues('salaryrecogActpgOfferActionDesc')
			),
		},
		{
			label: '욕구사정 및 급여제공계획',
			key: `${RouterPath.myTaskOperate().workExecuteLogNew.key}#6`,
			onClick: () => navigate(`#6`),
			isDone: !!(
				form.getValues('physicalConditionDesc') &&
				form.getValues('diseaseDesc') &&
				form.getValues('recogStateDesc') &&
				form.getValues('communicationDesc') &&
				form.getValues('nutritionalStateDesc') &&
				form.getValues('familyEnvironmentDesc') &&
				form.getValues('totalOpinionDesc') &&
				form.getValues('salaryGoalDesc') &&
				form.getValues('needSalaryDesc') &&
				form.getValues('provisionMethodDesc')
			),
		},
	];

	if (form?.watch('workExecuteLogDivCd')?.[0]?.value === WorkExecuteLogCode.프로그램관리자) {
		subSectionItems.splice(2, 0, {
			label: '인지활동 프로그램 제공 계획',
			key: `${RouterPath.myTaskOperate().workExecuteLogNew.key}#3`,
			onClick: () => navigate(`#3`),
			isDone: !!(
				form.getValues('recogStimulationNeedDesc') &&
				form.getValues('recogStimulationProvisionMethodDesc') &&
				form.getValues('physicalAbilityNeedDesc') &&
				form.getValues('physicalAbilityProvisionMethodDesc')
			),
		});
	}

	const workExecuteLogScrollSpyMock: LocalNavigationSection[] = [
		{
			label: RouterPath.myTaskOperate().workExecuteLogNew.label,
			onClick: () => {
				navigate('');
			},
			subSections: [
				{
					label: '',
					items: subSectionItems,
				},
			],
		},
	];
	return workExecuteLogScrollSpyMock;
}
