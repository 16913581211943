import React, { ChangeEventHandler, PropsWithChildren, memo } from 'react';
import { ButtonPalette } from 'components/base/CRButton/type';
import { CheckBoxAppearance, CheckBoxType } from '../type';
import CheckBoxDefault from './CheckBoxDefault';
import CheckBoxButton from './CheckBoxButton';

interface IProps {
	id?: string;
	appearanceType?: CheckBoxAppearance;
	name?: string;
	checked?: boolean;
	disabled?: boolean;
	onlyCheck?: boolean;
	checkBoxType?: CheckBoxType;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	errorMessage?: React.ReactNode;
	palette?: ButtonPalette;
}

function CRCheckBox({
	appearanceType = 'default',
	palette = 'primary',
	...rest
}: PropsWithChildren<IProps>) {
	if (appearanceType === 'button') {
		return <CheckBoxButton palette={palette} {...rest} />;
	}

	return <CheckBoxDefault {...rest} palette={palette} />;
}

export default memo(CRCheckBox);
