import Colors from 'common/colors';
import { CRTableHeadConfig } from 'types/view/base';

export const BILLING_SIDE_MODAL_TABLE_HEAD_CONFIG: CRTableHeadConfig[] = [
	{ label: '제공일', width: '8rem', color: Colors.gray60 },
	{ label: '서비스 명칭', width: '16rem', color: Colors.gray60 },
	{
		label: '일정 게획 시간',
		width: '12rem',
		color: Colors.gray60,
	},
	{ label: '감산 비율', width: '8.8rem', color: Colors.gray60 },
	{ label: '직원', width: '9.6rem', color: Colors.gray60 },
	{ label: '수가', width: '12rem', color: Colors.gray60 },
	{ label: '구분', width: '6.4rem', color: Colors.gray60 },
	{ label: '기록지', width: '12rem', color: Colors.gray60 },
];
