import { css } from 'styled-components';

const Typography = {
	display: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 6rem;
		line-height: 7.2rem;
	`,
	h1: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 4.8rem;
		line-height: 6rem;
	`,
	h2: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 3.6rem;
		line-height: 4.5rem;
	`,
	h3: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 2.4rem;
		line-height: 3.6rem;
	`,
	h4: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 2.1rem;
		line-height: 3.2rem;
	`,
	// bodyB = B
	bodyB: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 1.6rem;
		line-height: 2.8rem;
	`,
	body: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 2.8rem;
	`,
	labelB: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 700;
		font-size: 1.4rem;
		line-height: 2.4rem;
	`,
	label: css`
		font-family: 'Pretendard';
		font-feature-settings: tnum;
		font-variant-numeric: tabular-nums;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 2.4rem;
	`,
};

export default Typography;
