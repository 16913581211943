import React, { useCallback } from 'react';

import CRTableHeader from 'components/base/CRTableHeader';
import CRTable from 'components/base/CRTable';
import { PageInfo } from 'types/view/base';
import { ESignHistoryContentDTO } from 'types/api/send';
import CRStatus from 'components/base/CRStatus';

import { CheckOption } from 'components/base/Selections/type';
import CRSpinner from 'components/base/CRSpinner';
import Colors from 'common/colors';
import { Filter } from 'types/view/filter';
import * as S from './styles';
import { ESIGN_HISTORY_HEADER_CONFIG, SIGN_STATUS_CONFIG } from './constant';

interface Props {
	isLoading?: boolean;
	items?: ESignHistoryContentDTO[];
	searchValue?: string;
	currentFilter: {
		[key in string]: CheckOption[];
	};
	setCurrentFilter: React.Dispatch<
		React.SetStateAction<{
			[key: string]: CheckOption[];
		}>
	>;
	pageInfo?: PageInfo;
	filters?: Array<Filter<CheckOption>>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickRow?: (task: ESignHistoryContentDTO) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onSearch?: () => void;
	onRefresh: () => void;
}

function ESignHistoryTable({
	isLoading = false,
	items = [],
	searchValue,
	pageInfo,
	filters,
	currentFilter,
	setCurrentFilter,
	onChangeSearchValue,
	onClickRow,
	onChangePageInfo,
	onSearch,
	onRefresh,
}: Props): React.ReactElement {
	const SignStatusBadge = useCallback(
		(value: string | number) => <CRStatus options={SIGN_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	return (
		<CRTable.BackBlind>
			<S.TableContainer>
				<CRTableHeader
					showRefresh
					onRefresh={onRefresh}
					showViewCount
					onChangePageInfo={onChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
					stickyMode
					filters={filters}
					searchValue={searchValue}
					placeholder='제목, 구성원 성명으로 검색'
					onChangeSearchValue={onChangeSearchValue}
					onSearch={onSearch}
				/>
				{isLoading ? (
					<CRSpinner />
				) : (
					<S.TableScrollContainer>
						<CRTable.Root
							style={{
								borderLeft: `0.1rem solid ${Colors.gray90}`,
								borderRight: `0.1rem solid ${Colors.gray90}`,
							}}>
							<CRTable.Head offset={-47} heads={ESIGN_HISTORY_HEADER_CONFIG} />
							<CRTable.Body>
								{items.map((item) => (
									<CRTable.Row
										key={item.edocSendId}
										item={item}
										renderKeys={[
											'lastEsignStateNm',
											'lastActionDate',
											'edocNm',
											'edocTitle',
											'signatoryNms',
											'edocSenderNm',
										]}
										customRender={{
											lastEsignStateNm: SignStatusBadge,
										}}
										onClick={onClickRow}
									/>
								))}
							</CRTable.Body>
						</CRTable.Root>
					</S.TableScrollContainer>
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}

export default ESignHistoryTable;
