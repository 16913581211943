import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
`;

export const HeaderButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.8rem;
	background-color: white;
	height: 4.2rem;
	width: 100%;
	margin-bottom: 2rem;
`;

export const MonthDate = styled.h4`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	${(props) => props.theme.typography.h4};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const TableContainer = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	background: ${(props) => props.theme.colors.gray100};
`;

export const InnerContainer = styled.div`
	width: 100%;
	height: calc(100% - 11.2rem);
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;
