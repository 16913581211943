// !마이그레이션 후 삭제 예정
/**
 * 일정 생성, 자동화 등록 공통 상태 값
 */
export enum CreateScheduleStatus {
	BEFORE_REGISTER, // 등록 예정
	BEFORE_AUTOMATION, // 자동화 등록전
	EMPTY, // 빈 일정
	MODIFIED, // 수정된 일정
	ERROR, // 오류
	DONE, // 등록 완료
}

/**
 * Task, TaskAction으로 업무 관련 기능 열거
 */
export enum Task {
	CONTRACT, // 자체계약
	CREATE_SCHEDULE, // 일정생성
	MONITORING, // 일정모니터링
	RECORDING_PAPER, // 기록지
	BILLING, // 청구
	OWN_EXPENSE, // 본인부담금
	SALARY, // 급여
	RENEW_DOCUMENT, // 서류갱신
	CHANGE_SALARY, // 요양요원 임금 변경
	CHANGE_STAFF, // 요양요원 변경
	EXIT_CENTER, // 퇴사/퇴소
}

export enum TaskAction {
	RecipientBasic, // 수급자 정보
	IntegratedAssessment, // 통합사정 정보
	Manager, // 담당 직원 정보
	ServiceInspection, // 서비스 이용 점검
	ContractInspection, // 계약 점검
	SCHEDULE_REGISTRATION, // 일정 생성
	AUTOMATION, // 자동화 공단 등록
	SCHEDULE_ERROR_EDIT, // 일정 오류 수정
	TEST,
}

export type TaskActionItem = {
	title?: string;
	startDate?: string;
	endDate?: string;
	category: TaskAction;
};

export type TaskItem = {
	id: string;
	title: string;
	category: Task;
	categoryNm?: string;
	startDate?: string;
	endDate?: string;
	items: TaskActionItem[];
};

export type ScheduleItem = {
	id: string;
	name: string;
	userNumber: number;
	serviceType: string;
	status: CreateScheduleStatus;
	items: {
		days: number[];
		startTime: string;
		endTime: string;
	}[];
};

export type LocalNavigationItem = {
	key: string;
	label: string;
	isDone?: boolean;
	onClick?: () => void;
};

export type LocalNavigationSubSection = {
	label: string;
	items: LocalNavigationItem[];
};

export type LocalNavigationSection = {
	label: string;
	onClick?: () => void;
	subSections: LocalNavigationSubSection[];
};

export enum CalendarCategory {
	HOLIDAY,
	MY_WORK, // 내 업무
	MEETING, // 회의
	REGULAR, // 정기 일정
	SALARY, // 직원 급여
	RENEW_DOCUMENT, // 서류 갱신
}
