import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const TAG_ERROR_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '12rem',
		label: '일자',
		sortKey: '일자',
	},
	{
		width: '8.8rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '8.8rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '8.8rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '9.6rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '12rem',
		label: '일정계획 시간',
		sortKey: '일정계획 시간',
	},
	{
		width: '12rem',
		label: 'RFID 시간',
		sortKey: 'RFID 시간',
	},
	{
		width: '12rem',
		label: '기록지 시간',
		sortKey: '기록지 시간',
	},
	{
		width: '12rem',
		label: '오류 여부',
		sortKey: '오류 여부',
	},
	{
		width: '13rem',
		label: '기록지 작성 여부',
		sortKey: '기록지 작성 여부',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const TAG_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '정상',
		label: '정상',
		color: 'green',
	},
	{
		key: '오류',
		label: '오류',
		color: 'red',
	},
];

export const RECORD_PAPER_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '발송전',
		label: '미완료',
		color: 'yellow',
	},
	{
		key: '서명 대기',
		label: '미완료',
		color: 'yellow',
	},
	{
		key: '검수 대기',
		label: '미완료',
		color: 'yellow',
	},
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '서명 취소',
		label: '서명 취소',
		color: 'red',
	},
	{
		key: '기간 만료',
		label: '기간 만료',
		color: 'blue',
	},
];
