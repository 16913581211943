import React from 'react';

import Colors from 'common/colors';
import InformationSheet from 'components/ui/InformationSheet';

function IntegratedBedsoreFormScoreInfo(): React.ReactElement {
	return (
		<InformationSheet
			title='점수해석'
			align='flex-start'
			items={[
				[
					{
						label: '19-23',
						value: '위험 없음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
				[
					{
						label: '15-18',
						value: '약간의 위험 있음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
				[
					{
						label: '13-14',
						value: '중간 정도의 위험 있음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
				[
					{
						label: '10-12',
						value: '위험이 높음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
				[
					{
						label: '9점 이하',
						value: '위험이 매우 높음',
						labelWidth: '12rem',
						labelStyle: {
							color: Colors.gray10,
						},
						valueStyle: {
							color: Colors.gray10,
						},
					},
				],
			]}
			type='gray'
		/>
	);
}

export default React.memo(IntegratedBedsoreFormScoreInfo);
