export enum Role {
	Admin = 'admin',
	Employee = 'employee',
}

export type MyMenu = {
	korMenuNm: string;
	menuGroupCd: string;
	menuUrl: string;
	menuClassName: string;
};

export type MyMenuGroup = {
	label: string;
	menus: MyMenu[];
};
