import { useContext } from 'react';
import { ContractChangeEmployeeTaskContext } from 'lib/provider/contractChangeEmployee';

export default function useContractChangeEmployeeTask() {
	const context = useContext(ContractChangeEmployeeTaskContext);
	if (!context) {
		throw new Error(
			'useContractChangeEmployeeTask must be used within a ContractChangeEmployeeTaskContext',
		);
	}
	return context;
}
