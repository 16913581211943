import React, { ReactElement } from 'react';

import { FilterType } from 'types/view/filter';
import { ApprovalItem } from 'types/view/approval';
import CRTableHeader from 'components/base/CRTableHeader';

import * as S from './styles';
import CollapsibleRow from './CollapsibleRow';

export enum TaskProgressStatus {
	InProgress,
	Done,
	Pending,
}

interface Props<T extends { label: string; value: any }> {
	items: ApprovalItem[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	renderCustomFilter?: ReactElement;
	renderRightButton?: ReactElement;
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickRow?: (schedule: ApprovalItem) => void;
}

function ApprovalProgressTable<T extends { label: string; value: any }>({
	items = [],
	searchValue,
	currentFilter,
	setCurrentFilter,
	renderCustomFilter,
	renderRightButton,
	onChangeSearchValue,
	onClickRow,
}: Props<T>): React.ReactElement {
	return (
		<S.Container>
			<CRTableHeader
				renderRightButton={renderRightButton}
				currentFilter={currentFilter}
				renderCustomFilter={renderCustomFilter}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				stickyMode
			/>
			<S.Table>
				<S.TableHeader>
					<tr>
						<S.TableHeaderColumnWithPadding
							style={{ minWidth: '48rem', width: '48rem', textAlign: 'left' }}>
							제목
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding
							style={{ minWidth: 'rem', width: '12rem', textAlign: 'left' }}>
							센터
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding
							style={{ minWidth: '12rem', width: '12rem', textAlign: 'left' }}>
							작성자
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding
							style={{ minWidth: '16rem', width: '16rem', textAlign: 'left' }}>
							상신일시
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding
							style={{ minWidth: '32rem', width: '32rem', textAlign: 'left' }}>
							결재선
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding
							style={{ minWidth: '12rem', width: '12rem', textAlign: 'left' }}>
							상태
						</S.TableHeaderColumnWithPadding>
						<S.TableHeaderColumnWithPadding />
					</tr>
				</S.TableHeader>
				<S.TableBody>
					{items.map((item) => (
						<CollapsibleRow key={item.id} item={item} onClickRow={onClickRow} />
					))}
				</S.TableBody>
			</S.Table>
		</S.Container>
	);
}

export default ApprovalProgressTable;
