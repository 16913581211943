import CRTableHeader from 'components/base/CRTableHeader';
import React, { useEffect, useMemo, useState } from 'react';
import CRTable from 'components/base/CRTable';
import { PageInfo } from 'types/view/base';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { useCenterSalaryFeeInfo, useMyAccountInfo } from 'lib/hook/react-query';
import CRSpinner from 'components/base/CRSpinner';
import CRButton from 'components/base/CRButton';
import { useHasPermission } from 'lib/hook/util/useHasPermission';

import Assets from 'assets';
import CenterFinanceTable from 'components/domain/table/CenterFinanceTable';
import { useLocation, useNavigate } from 'react-router-dom';
import usePageFilter from 'lib/hook/util/usePageFilter';
import * as S from './styles';

export function CenterFinanceInformationTab() {
	const isCenterChief = useHasPermission('센터장');
	const { filters, setFilters, pageInfo, setPageInfo } = usePageFilter({});
	const [keyword, setKeyword] = useState('');
	const { data: myAccountInfo } = useMyAccountInfo();
	const [searchValue, setSearchValue] = useState('');
	const location = useLocation();

	const navigate = useNavigate();

	const {
		data: centerDocuments,
		isLoading: centerDocumentsLoading,
		refetch,
	} = useCenterSalaryFeeInfo({
		page: pageInfo.page,
		size: pageInfo.size,
		centerIds: myAccountInfo?.centerId,
		keyword,
	});

	const handleRefetch = () => {
		refetch();
	};

	const handleSearchValue = (value: string) => setSearchValue(value);
	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleClickAddFinanceInfo = () => {
		navigate(`${location.pathname}/new`);
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>등록된 문서 정보가 없습니다.</S.EmptyTitle>
			</S.EmptyContainer>
		),
		[],
	);

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: centerDocuments?.totalPages || 0,
		}));
	}, [centerDocuments?.totalPages]);

	return (
		<CRTable.BackBlind
			contentStyle={{
				padding: 0,
			}}>
			<S.TableContainer>
				<S.TableHeaderContainer>
					<CRTableHeader
						onChangePageInfo={handleChangePageInfo}
						pageInfo={pageInfo}
						currentFilter={filters}
						stickyMode
						searchValue={searchValue}
						placeholder='문서 이름으로 검색'
						onChangeSearchValue={handleSearchValue}
						setCurrentFilter={setFilters}
						onSearch={handleSearch}
						showRefresh
						showViewCount
						onRefresh={handleRefetch}
						renderRightButton={
							isCenterChief ? (
								<CRButton.IconButton
									size='xSmall'
									type='filled'
									iconLeft={Assets.icon.add}
									onClick={handleClickAddFinanceInfo}>
									재무 정보 추가
								</CRButton.IconButton>
							) : undefined
						}
					/>
				</S.TableHeaderContainer>
				{centerDocumentsLoading ? (
					<CRSpinner />
				) : centerDocuments && centerDocuments?.content?.length > 0 ? (
					<S.TableScrollContainer>
						<CenterFinanceTable items={centerDocuments?.content || []} />
					</S.TableScrollContainer>
				) : (
					renderEmpty
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
