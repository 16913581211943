import React from 'react';

import Assets from 'assets';
import * as S from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	disabled?: boolean;
}

function RdAccordionTrigger(
	{ children, disabled, ...rest }: React.PropsWithChildren<Props>,
	ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement<HTMLDivElement> {
	const isOpen = (rest as { 'data-state': string })['data-state'] === 'open';
	return (
		<S.Container ref={ref} {...rest}>
			<S.Title>{children}</S.Title>
			{disabled ? (
				<S.Icon
					$disabled={disabled}
					src={Assets.icon.keyboardArrowBottom}
					alt='keyboardArrowBottom'
				/>
			) : (
				<S.Icon
					src={isOpen ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom}
					alt={isOpen ? 'keyboardArrowTop' : 'keyboardArrowBottom'}
				/>
			)}
		</S.Container>
	);
}

export default React.forwardRef(RdAccordionTrigger);
