import React, { useCallback } from 'react';

import * as S from './styles';

interface Props<T extends object, U extends keyof T> {
	item: T;
	renderKeys?: (U | '')[];
	customRender?: {
		[k in U]?: (value: T[k], item?: T) => React.ReactElement;
	};
	mergeConfig?: {
		[k in keyof T]?: {
			colSpan?: number;
			rowSpan?: number;
		};
	};
	onClick?: (item: T) => void;
	borderBottom?: string;
	style?: React.CSSProperties;
	customStyle?: {
		[k in U]?: React.CSSProperties;
	};
}

function CRInformationRow<T extends object, U extends keyof T>({
	item,
	renderKeys = [],
	customRender = {},
	mergeConfig = {},
	borderBottom,
	style,
	customStyle,
	onClick,
}: Props<T, U>): React.ReactElement {
	const renderContent = useCallback(
		(renderKey: U | '') => {
			const commonStyles = {
				borderBottom,
				...style,
				...customStyle?.[renderKey],
			} as React.CSSProperties;

			if (customRender[renderKey as U]) {
				return (
					<S.TableBodyColumnWithPadding
						key={renderKey.toString()}
						colSpan={mergeConfig[renderKey]?.colSpan}
						rowSpan={mergeConfig[renderKey]?.rowSpan}
						style={commonStyles}>
						{customRender[renderKey as U]?.(item[renderKey as U])}
					</S.TableBodyColumnWithPadding>
				);
			}

			return (
				<S.TableBodyColumnWithPadding
					key={renderKey.toString()}
					colSpan={mergeConfig[renderKey]?.colSpan}
					rowSpan={mergeConfig[renderKey]?.rowSpan}
					style={commonStyles}>
					{item[renderKey as U]}
				</S.TableBodyColumnWithPadding>
			);
		},
		[customStyle, customRender],
	);

	return (
		<S.TableBodyRow onClick={() => onClick?.(item)}>{renderKeys.map(renderContent)}</S.TableBodyRow>
	);
}

export default React.memo(CRInformationRow) as typeof CRInformationRow;
