import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from 'components/ui/ErrorBoundary';

import GlobalStyle from 'common/globalStyles';
import Theme from 'common/theme';
import { loadApplication } from 'lib/service/util/loader';
import DialogProvider from 'lib/provider/ui/Dialog';
import ApiProvider from 'lib/provider/service/Api';
import AuthProvider from 'lib/provider/auth';
import { StyledToastContainer } from 'components/base/CRToast';
import RootRouter from './router';
import 'react-toastify/dist/ReactToastify.css';

const { api, queryClient } = loadApplication();

const autoSignIn = localStorage.getItem('autoSignIn');
const user = autoSignIn
	? !!localStorage.getItem('access_token')
	: !!sessionStorage.getItem('access_token');

function App(): React.ReactElement {
	return (
		<ThemeProvider theme={Theme}>
			<StyledToastContainer />
			<ErrorBoundary>
				<ApiProvider api={api}>
					<QueryClientProvider client={queryClient}>
						<AuthProvider user={user}>
							<ReactQueryDevtools initialIsOpen={false} />
							<DialogProvider>
								<RootRouter />
								<GlobalStyle />
							</DialogProvider>
						</AuthProvider>
					</QueryClientProvider>
				</ApiProvider>
			</ErrorBoundary>
		</ThemeProvider>
	);
}

export default App;
