import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: overlay;
`;

export const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	margin: 4rem auto;
`;

export const Logo = styled.img`
	display: flex;
	justify-content: center;
	margin-bottom: 3.2rem;
	height: 4rem;
`;

export const Header = styled.h3`
	margin-bottom: 0.8rem;
`;

export const Description = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	white-space: pre-wrap;
	margin-bottom: 3.2rem;
`;

export const Form = styled.form``;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	margin-top: 4rem;
`;

export const CancelButtonContainer = styled.div`
	flex: 1;
`;

export const SignUpButtonContainer = styled.div`
	flex: 5;
`;
