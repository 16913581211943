import React from 'react';
import styled from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6rem;
	padding: 2rem 2rem 6rem 2rem;
	background-color: white;
`;

export const labelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

export const Container1 = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
`;

export const Container2 = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const JustifyContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
`;

export const SelectionFlexColContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	gap: 1rem;
	padding: 0.5rem 0.5rem;
`;

export const CountMistakeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	> div {
		display: flex;
		align-items: center;
		gap: 2.5rem;
	}
`;

export const TableAlign = styled.div<{
	$textAlign?: 'left' | 'center' | 'right';
	$padding?: string;
}>`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: ${(p) => p.$textAlign || 'center'};
	align-items: center;
	padding: ${(p) => p.$padding};
`;

export const InputBloodPressureContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;
