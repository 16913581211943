import { css, styled } from 'styled-components';

export const RelatedTask = styled.div<{ $hasTask?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${(props) => props.theme.colors.gray99};
	height: 5.6rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0 1.6rem;
	border-radius: 0.8rem;

	${(props) =>
		props.$hasTask &&
		css`
			cursor: pointer;
		`};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const GoToRelatedTaskPageIcon = styled(Icon)`
	filter: brightness(0) saturate(100%) invert(70%) sepia(7%) saturate(114%) hue-rotate(149deg)
		brightness(81%) contrast(87%);
`;

export const RelatedTaskLeft = styled.div`
	display: flex;
	gap: 0.8rem;

	& > span {
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray00};
	}
`;
