import React, { useEffect, useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import useCRForm from 'lib/hook/form/useCRForm';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';
import { displayComma } from 'lib';
import CRPageHeader from 'components/base/CRPageHeader';
import MyCenterFinanceDurationForm from 'components/domain/form/MyCenterFinanceDurationForm';
import MyCenterFinanceVisitNormalFeeForm from 'components/domain/form/MyCenterFinanceVisitNormalFeeForm';
import {
	salaryFeeCdKor,
	SalaryFeeCdKorKeys,
} from 'components/domain/dialog/SalaryDetailDialog/types';
import MyCenterFinanceVisitBathFeeForm from 'components/domain/form/MyCenterFinanceVisitBathFeeForm';
import MyCenterFinanceVisitNursingFeeForm from 'components/domain/form/MyCenterFinanceVisitNursingFeeForm';
import MyCenterFinanceEtcFeeForm from 'components/domain/form/MyCenterFinanceEtcFeeForm';
import dayjs from 'dayjs';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import CRPageFooter from 'components/base/CRPageFooter';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import MyCenterFinanceVisitFamilyFeeForm from 'components/domain/form/MyCenterFinanceVisitFamilyFeeForm';
import {
	useCreateCenterSalaryFeeInfo,
	useCurrentCenterSalaryFeeInfo,
	useCheckSalaryDuplicatedRange,
} from 'lib/hook/react-query/mutation/centerFinance';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api';
import {
	CenterSalaryFeeStandardDTO,
	CreateCenterSalaryFeeInfoRequest,
} from 'types/api/centerFinanceInfo';
import useDialog from 'lib/hook/util/useDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import * as S from './styles';
import { errorMessages } from './constants';

export default function AddCenterFinanceInfoPage() {
	const location = useLocation();
	const { data: myAccountInfo } = useMyAccountInfo();
	const isCenterChief = useHasPermission('센터장');
	const { showDialog, hideDialog } = useDialog();
	const navigate = useNavigate();

	const { watch, getValues, setValue, CRHandleSubmit, CRFormProvider } =
		useCRForm<CenterFinanceDetailFormFields>();

	const { mutate: checkSalaryDuplicatedRange, data: isRangeDuplicate } =
		useCheckSalaryDuplicatedRange();

	const { mutate: createCenterSalaryInfo, isLoading: createCenterSalaryInfoLoading } =
		useCreateCenterSalaryFeeInfo((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 재무 정보 추가를 완료하였습니다.');
				navigate(-1);
			} else if (returnData?.code === ResponseCode.ERROR) {
				Toast.error(returnData.message);
			} else {
				Toast.error('재무 정보 추가에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const validate = () => {
		if (!getValues('salaryFeeStandardStartDate')) {
			Toast.error('적용기간 - 적용 시작 연월을 입력해주세요.');
			return false;
		}

		if (isRangeDuplicate) {
			Toast.error('현재 적용중인 재무 정보의 시작 연월 이후로 설정해 주십시오.');
			return false;
		}

		const hasError = errorMessages.some(([grade, message]) => {
			if (
				!getValues('salaryFee')?.[grade]?.salaryFeeValue ||
				getValues('salaryFee')?.[grade]?.salaryFeeValue === '0'
			) {
				Toast.error(message);
				return true; // 조건이 충족되면 반복을 종료하고 true 반환
			}
			return false;
		});

		if (hasError) {
			return false;
		}

		return true;
	};

	const applyCenterSalaryFee = (financeSalaryFeeDetailInfo: CenterSalaryFeeStandardDTO[]) => {
		const SalaryFeeObj: any = {};
		financeSalaryFeeDetailInfo?.forEach((item) => {
			const itemKor = Object.entries(salaryFeeCdKor).find(
				([name, value]) => item.salaryFeeCd.salaryFeeCd === value,
			);
			if (itemKor) {
				const convertedItem = {
					...item,
					salaryFeeValue: displayComma(item.salaryFeeValue),
				};
				const korKey = itemKor[0];
				SalaryFeeObj[korKey] = convertedItem;
			}
		});
		setValue('salaryFee', SalaryFeeObj);
	};

	const { mutate: getCurrentCenterSalaryFeeInfo, isLoading: currentInfoLoading } =
		useCurrentCenterSalaryFeeInfo((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				if (returnData.data?.length) {
					applyCenterSalaryFee(returnData.data);
				}
			} else {
				Toast.error('직전 이력을 불러오지 못했습니다. 잠시후 다시 시도해주세요.');
			}
			hideDialog();
		});

	const forms = useMemo(
		() => [
			{
				id: '#1',
				label: '적용 기간',
				component: <MyCenterFinanceDurationForm periodDuplicate={isRangeDuplicate || false} />,
			},
			{
				id: '#2',
				label: '방문요양 (일반) 임금 정보',
				component: <MyCenterFinanceVisitNormalFeeForm />,
			},
			{
				id: '#3',
				label: '방문요양 (가족) 임금 정보',
				component: <MyCenterFinanceVisitFamilyFeeForm />,
			},
			{
				id: '#4',
				label: '방문목욕 임금 정보',
				component: <MyCenterFinanceVisitBathFeeForm />,
			},
			{
				id: '#5',
				label: '방문간호 임금 정보',
				component: <MyCenterFinanceVisitNursingFeeForm />,
			},
			{
				id: '#6',
				label: '기타 임금 정보',
				component: <MyCenterFinanceEtcFeeForm />,
			},
		],
		[isRangeDuplicate],
	);

	const onClickSave = async (formData: CenterFinanceDetailFormFields) => {
		if (!validate() || !myAccountInfo?.centerId || createCenterSalaryInfoLoading) return;
		const { salaryFeeStandardStartDate, salaryFee } = formData;

		const salaryFeeStandardValues = Object.entries(salaryFee).map(([name, value]) => {
			const salaryFeeCd = salaryFeeCdKor[name as SalaryFeeCdKorKeys];
			return {
				salaryFeeCd,
				salaryFeeValue: value.salaryFeeValue.replace(/,/g, ''),
			};
		});
		const params: CreateCenterSalaryFeeInfoRequest = {
			centerId: myAccountInfo.centerId,
			salaryFeeStandardStartDate: dayjs(salaryFeeStandardStartDate).format('YYYY-MM-DD HH:mm:ss'),
			salaryFeeStandardValues,
		};

		createCenterSalaryInfo(params);
	};

	const handleClickCurrentApplySalaryFee = async () => {
		if (!myAccountInfo?.centerId) return;
		showDialog(() => (
			<DefaultDialog
				title='직전 이력 불러오기'
				hideDialog={hideDialog}
				content='직전 이력에 입력한 값을 불러오시겠습니까?'
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '불러오기',
					successCallback: () => {
						if (!currentInfoLoading) {
							getCurrentCenterSalaryFeeInfo({
								centerId: myAccountInfo.centerId,
							});
						}
					},
				}}
			/>
		));
	};

	useEffect(() => {
		if (myAccountInfo?.centerId && watch('salaryFeeStandardStartDate')) {
			checkSalaryDuplicatedRange({
				centerId: myAccountInfo?.centerId,
				salaryFeeStandardStartDate: watch('salaryFeeStandardStartDate')
					? dayjs(watch('salaryFeeStandardStartDate')).format('YYYY-MM-DD HH:mm:ss')
					: undefined,
			});
		}
	}, [myAccountInfo, watch('salaryFeeStandardStartDate')]);

	if (myAccountInfo && !isCenterChief) {
		return <Navigate to='/' state={{ from: location }} />;
	}

	return (
		<S.MainComponentContainer>
			<S.FormContainer>
				<CRPageHeader headerTitle='재무 정보 추가' />
				<CRFormProvider>
					<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
						{forms.map((form) => (
							<Accordion.Item value={form.id} asChild>
								<TaskAccordion.Item>
									<Accordion.Header asChild>
										<Accordion.Trigger asChild>
											<TaskAccordion.Trigger>{form.label}</TaskAccordion.Trigger>
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content asChild>
										<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
									</Accordion.Content>
								</TaskAccordion.Item>
							</Accordion.Item>
						))}
					</Accordion.Root>
				</CRFormProvider>
			</S.FormContainer>
			{isCenterChief && (
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							leftButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'outlined',
									onClick: handleClickCurrentApplySalaryFee,
									children: '불러오기',
								},
							]}
							rightButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'text',
									onClick: () => navigate(-1),
									children: '취소',
								},
								{
									palette: 'primary',
									buttonType: 'button',
									type: 'filled',
									onClick: CRHandleSubmit(onClickSave),
									children: '추가',
								},
							]}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			)}
		</S.MainComponentContainer>
	);
}
