interface GeoLocation {
	latitude?: string;
	longitude?: string;
}

const useKakaoLocation = () => {
	const getGeolocation = async (address: string): Promise<GeoLocation> =>
		new Promise((resolve, reject) => {
			const geocoder = new window.kakao.maps.services.Geocoder();

			geocoder.addressSearch(address, (result, status) => {
				if (status === window.kakao.maps.services.Status.OK) {
					resolve({
						latitude: result[0].y,
						longitude: result[0].x,
					});
				} else {
					reject(new Error(`Failed to get coordinates for address: ${address}`));
				}
			});
		});

	return { getGeolocation };
};

export default useKakaoLocation;
