import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';

import useWorkExecuteLog from 'lib/hook/view/workExecuteLog/useWorkExecuteLog';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRInput from 'components/base/CRInput';
import CRStatus from 'components/base/CRStatus';
import { v4 } from 'uuid';
import { CRText } from 'components/base/CRText';
import { displayPadTime } from 'lib';
import { ErrorMessage } from 'components/base/CRInputMessage/styles';
import { CheckOption } from 'components/base/Selections/type';
import * as S from './styles';
import { VISIT_STATE_STATUS_CONFIG, VisitStatusCode, salaryOfferVisitOption } from './constant';
import WorkPlanCard from './WorkPlanCard';

function WorkExecuteLogVisitForm(): React.ReactElement {
	const { commonCodes, form, workExecuteLogServiceSchedulePlan } = useWorkExecuteLog();

	const { visitDt, workExecuteLogStateCd, salaryOfferVisitYn } = form.watch();

	const isNonVisit = salaryOfferVisitYn?.[0]?.value === false;

	const statusCode = commonCodes?.CMN184?.map((item) => ({
		label: <CRStatus options={VISIT_STATE_STATUS_CONFIG}>{item.value}</CRStatus>,
		value: item.value,
	}));

	const visitStatusOptions = statusCode?.filter((test) => {
		if (isNonVisit) {
			return test.value === VisitStatusCode.미방문;
		}
		return test.value !== VisitStatusCode.미방문;
	});

	const workPlan = useMemo(
		() =>
			(workExecuteLogServiceSchedulePlan || [])?.map((item) => ({
				label: <WorkPlanCard item={item} />,
				value:
					item.employeeId +
					item.serviceStartDt +
					item.serviceTypeCd +
					item.serviceStartTime +
					item.serviceEndTime,
				data: item,
			})),
		[workExecuteLogServiceSchedulePlan],
	);

	const handleDefaultVisitTime = (item: CheckOption<any>[]) => {
		if (item?.[0] && !isNonVisit) {
			const ids = item[0].data.employeeId.split(',');
			const matchedEmployee1 = commonCodes?.employeesOptions?.find(
				(item) => Number(ids?.[0]) === item.value.id,
			);
			const matchedEmployee2 = commonCodes?.employeesOptions?.find(
				(item) => Number(ids?.[1]) === item.value.id,
			);

			form.setValue('employee1', matchedEmployee1 || undefined);
			form.setValue('employee2', matchedEmployee2 || undefined);
		}
		form.setValue('serviceTypeCd', [
			{
				label: item?.[0]?.data?.serviceTypeNm || '',
				value: item?.[0]?.data?.serviceTypeCd || '',
			},
		]);
		let hour = Number(item[0]?.data?.serviceStartTime?.substring(0, 2));
		let minute = Number(item[0]?.data?.serviceStartTime?.substring(2));
		// 20분을 추가
		minute += 20;
		// 분이 60을 넘으면 시간에 반영
		if (minute >= 60) {
			hour += 1;
			minute -= 60;
		}
		// 시간과 분을 두 자리 숫자로 포맷
		const result = `0${hour}`.slice(-2) + `0${minute}`.slice(-2);
		form.setValue('time', {
			startTime: displayPadTime(item[0].data.serviceStartTime),
			endTime: displayPadTime(result),
		});
	};

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='상태' type='left'>
						<CRInput.Selector
							onChangeValue={onChange}
							currentValue={value}
							items={visitStatusOptions}
							placeholder='방문 상태 선택'
						/>
					</CRInputLabel>
				)}
				name='workExecuteLogStateCd'
				control={form?.control}
			/>
			<CRInputLabel isRequired label='방문(상담) 일시' type='left'>
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DatePicker placeholder='0000.00.00' onChangeValue={onChange} value={value} />
						)}
						name='visitDt'
						control={form?.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DayTimePicker
								startPlaceHolder='00:00'
								endPlaceHolder='00:00'
								iconFill
								showDays={false}
								currentValue={{
									id: v4(),
									days: [],
									startTime: value?.startTime || '',
									endTime: value?.endTime || '',
								}}
								onChange={(e) => {
									onChange(e);
								}}
							/>
						)}
						name='time'
						control={form?.control}
					/>
				</S.InputContainer>
				{form.formState.errors?.time?.message && (
					<ErrorMessage>{form.formState.errors.time?.message}</ErrorMessage>
				)}
			</CRInputLabel>
			{!isNonVisit && (
				<Controller
					render={({ field: { onChange, value } }) => (
						<CRInputLabel
							isRequired={!(workExecuteLogStateCd?.value === VisitStatusCode.미방문)}
							label='요양보호사 방문일정'
							type='left'>
							{!visitDt || workExecuteLogStateCd?.value === VisitStatusCode.미방문 ? (
								<S.EmptyVisitSchedule>
									<CRText
										color='gray60'
										typography='labelB'
										text='방문(상담)일을 선택해 주십시오.'
									/>
								</S.EmptyVisitSchedule>
							) : workExecuteLogServiceSchedulePlan?.length ? (
								<CRCheckBoxGroup
									direction='col'
									onChange={(item) => {
										if (item.length) {
											handleDefaultVisitTime(item);
											onChange(item);
										}
									}}
									appearanceType='button'
									checkType='single'
									type='radio'
									gap={0.8}
									value={value}
									options={workPlan}
								/>
							) : (
								<S.EmptyVisitSchedule>
									<CRText
										color='gray60'
										typography='labelB'
										text='해당일에 선택가능한 일정이 없습니다.'
									/>
								</S.EmptyVisitSchedule>
							)}
						</CRInputLabel>
					)}
					name='visitSchedule'
					control={form?.control}
				/>
			)}
			{isNonVisit && (
				<Controller
					render={({ field: { onChange, value } }) => (
						<CRInputLabel isRequired label='급여 종류' type='left'>
							<CRCheckBoxGroup
								direction='col'
								onChange={onChange}
								appearanceType='button'
								checkType='single'
								type='radio'
								gap={0.8}
								value={value}
								options={commonCodes?.serviceTypeOption || []}
							/>
						</CRInputLabel>
					)}
					name='serviceTypeCd'
					control={form?.control}
				/>
			)}

			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='방문자' type='left'>
						<CRInput.Selector
							onChangeValue={onChange}
							currentValue={value}
							items={commonCodes?.centerManagerOption}
							placeholder='방문자 선택'
						/>
					</CRInputLabel>
				)}
				name='visitor'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='시설장(관리책임자)' type='left'>
						<CRInput.Selector
							autoComplete
							items={commonCodes?.centerChiefOptions}
							currentValue={value}
							placeholder='센터장 선택'
							onChangeValue={onChange}
						/>
					</CRInputLabel>
				)}
				name='facilityManager'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='방문 장소' type='left'>
						<CRInput.Default
							onChange={onChange}
							value={value}
							placeholder='방문 장소 입력'
							maxLength={50}
						/>
					</CRInputLabel>
				)}
				name='visitPlaceNm'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='급여제공 중 방문 여부' type='left'>
						<CRCheckBoxGroup
							onChange={(value) => {
								if (value?.[0]?.value === false) {
									const unVisitedCode = statusCode?.find(
										(item) => item.value === VisitStatusCode.미방문,
									);
									form.setValue('workExecuteLogStateCd', unVisitedCode);
								} else {
									const workExecuteLogStateCd = form.getValues('workExecuteLogStateCd');
									if (workExecuteLogStateCd?.value === VisitStatusCode.미방문) {
										const scheduledVisit = statusCode?.find(
											(item) => item.value === VisitStatusCode.방문예정,
										);
										form.setValue('workExecuteLogStateCd', scheduledVisit);
									}
								}
								onChange(value);
							}}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={salaryOfferVisitOption}
						/>
					</CRInputLabel>
				)}
				name='salaryOfferVisitYn'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, onBlur, value, ref } }) => {
					const isRequired = form.watch('salaryOfferVisitYn')?.[0]?.value === false;
					return (
						<CRInputLabel label='방문불가 사유' isRequired={isRequired} type='left'>
							<CRInput.TextArea
								fixedHeight
								ref={ref}
								maxLength={200}
								placeholder='방문불가 사유 입력'
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								disabled={!isRequired}
							/>
						</CRInputLabel>
					);
				}}
				name='visitDenyDesc'
				control={form?.control}
			/>
		</S.Container>
	);
}

export default React.memo(WorkExecuteLogVisitForm);
