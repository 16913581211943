import React, { useEffect, useState } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRButton from 'components/base/CRButton';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMyAccountInfo, useUpdateEmployeeEducation } from 'lib/hook/react-query';
import CRInputMessage from 'components/base/CRInputMessage';
import { EmployeeEducationForm } from 'types/view/employee';
import { EmployeeEducation } from 'lib';
import {
	EmployeeDTO,
	EmployeeEduInfoDTO,
	SaveEmployeeEducationRequestDTO,
} from 'types/api/employee';
import { QueryObserverResult } from '@tanstack/react-query';
import CRDialog from 'components/base/CRDialog';
import { CheckOption } from 'components/base/Selections/type';
import dayjs from 'dayjs';
import { ResponseCode } from 'types/api/base';
import { Toast } from 'components/base/CRToast';
import * as S from './styles';

interface Props {
	item?: EmployeeEduInfoDTO;
	currentEmployee?: EmployeeDTO;
	eduTypes: CheckOption[];
	hideDialog: () => void;
	onRefresh: () => Promise<QueryObserverResult<EmployeeEduInfoDTO[] | null, unknown>>;
}

export default function AddEducationDialog({
	item,
	currentEmployee,
	eduTypes,
	hideDialog,
	onRefresh,
}: Props) {
	const {
		watch,
		setValue,
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<EmployeeEducationForm>({
		resolver: yupResolver(EmployeeEducation),
		mode: 'onChange',
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { mutateAsync: updateEmployeeEducation } = useUpdateEmployeeEducation();
	const [fileLoading, setFileLoading] = useState<boolean>(false);

	const onSubmit = async (data: EmployeeEducationForm) => {
		if (!myAccountInfo?.centerId || !currentEmployee?.employeeId) return;
		const param: SaveEmployeeEducationRequestDTO = {
			employeeId: currentEmployee.employeeId,
			eduTypeCd: data.eduTypeCd,
			eduInstitutionNm: data.eduInstitutionNm,
			eduCompleteDate: dayjs(data.eduCompleteDate).format('YYYY-MM-DDT00:00:00'),
		};
		if (data.eduCompleteCertiFile?.length) {
			param.eduCompleteCertiFile = {
				fileDetails: data.eduCompleteCertiFile,
			};

			if (item?.eduCompleteCertiFile?.fileId) {
				param.eduCompleteCertiFile = {
					...param.eduCompleteCertiFile,
					fileId: item?.eduCompleteCertiFile.fileId,
				};
			}
		}

		if (item?.employeeEduId) {
			param.employeeEduId = item?.employeeEduId;
		}

		const result = await updateEmployeeEducation(param);

		if (result.code === ResponseCode.SUCCESS) {
			await onRefresh();
			hideDialog();
			Toast.success(item ? '교육을 수정했습니다.' : '교육을 추가했습니다.');
		}
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	useEffect(() => {
		if (item) {
			setValue('eduTypeCd', item.eduTypeCd);
			setValue('eduInstitutionNm', item.eduInstitutionNm);
			setValue('eduCompleteDate', dayjs(item.eduCompleteDate).toDate());
			setValue('eduCompleteCertiFile', item.eduCompleteCertiFile?.fileDetails);
		}
	}, [item]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='교육'
			height={800}
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.Content>
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => {
								const matchedItem = eduTypes.find((option) => option.value === watch('eduTypeCd'));
								const currentValue = matchedItem
									? {
											label:
												eduTypes.find((option) => option.value === watch('eduTypeCd'))?.label ?? '',
											value: eduTypes.find((option) => option.value === watch('eduTypeCd'))?.value,
									  }
									: undefined;
								return (
									<CRInputLabel
										label='종류'
										isRequired
										message={
											errors.eduTypeCd?.message && (
												<CRInputMessage type='error'>
													{errors.eduTypeCd?.message ?? ''}
												</CRInputMessage>
											)
										}>
										<CRInput.Selector
											items={eduTypes}
											ref={ref}
											onChangeValue={(item) => onChange(item.value)}
											currentValue={currentValue}
											placeholder='종류 선택'
										/>
									</CRInputLabel>
								);
							}}
							name='eduTypeCd'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel
									isRequired
									label='교육 일정'
									message={
										errors.eduCompleteDate?.message && (
											<CRInputMessage type='error'>
												{errors.eduCompleteDate?.message ?? ''}
											</CRInputMessage>
										)
									}>
									<CRInput.DatePicker
										placeholder='교육 일정 선택'
										onChangeValue={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='eduCompleteDate'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel
									isRequired
									label='교육 기관'
									message={
										errors.eduInstitutionNm?.message && (
											<CRInputMessage type='error'>
												{errors.eduInstitutionNm?.message ?? ''}
											</CRInputMessage>
										)
									}>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='교육 기관 입력'
									/>
								</CRInputLabel>
							)}
							name='eduInstitutionNm'
							control={control}
						/>
						<Controller
							name='eduCompleteCertiFile'
							control={control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='수료증 파일'>
									<CRInput.FileUploader
										onDelete={onRefresh}
										setIsLoading={setFileLoading}
										title={`${currentEmployee?.korMemberNm} 님의 수료증`}
										onChange={onChange}
										files={value}
										type='multiple'
									/>
								</CRInputLabel>
							)}
						/>
					</S.Content>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid || fileLoading}
						palette='primary'
						size='default'
						onClick={submitForm}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
