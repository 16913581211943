import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 1.6rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	overflow: overlay;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const SectionLabel = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;

export const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.8rem;
`;

export const Tag = styled.div<{ $checked?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.4rem;
	${(props) => props.theme.typography.body}
	cursor: pointer;
	padding: 0.2rem 1.2rem;
	border: 0.1rem solid
		${(props) => (props.$checked ? props.theme.colors.primary90 : props.theme.colors.gray90)};
	border-radius: 0.8rem;
	color: ${(props) => (props.$checked ? props.theme.colors.primary10 : props.theme.colors.gray10)};
	background: ${(props) =>
		props.$checked ? props.theme.colors.primary95 : props.theme.colors.white};
`;

export const CheckIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;
