import React from 'react';
import dayjs from 'dayjs';

import Assets from 'assets';

import Colors from 'common/colors';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import CRStatus from 'components/base/CRStatus';
import { GetCenterSalaryFeeInfoContent } from 'types/api/centerFinanceInfo';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import * as S from './styles';
import { CENTER_FINANCE_STATUS_CONFIG } from './constants';

interface Props {
	items: GetCenterSalaryFeeInfoContent[];
}

export default function CenterFinanceTable({ items }: Props) {
	const navigate = useNavigate();
	const handleClickRow = (item: GetCenterSalaryFeeInfoContent) => {
		if (!item.salaryFeeStandardStartDate || !item.salaryFeeStandardEndDate) return;
		navigate(
			`${RouterPath.myCenterOperate().updateFinanceInformation.key}?start=${
				item.salaryFeeStandardStartDate
			}&end=${item.salaryFeeStandardEndDate}`,
		);
	};
	const renderStatus = (item: GetCenterSalaryFeeInfoContent) => {
		if (!item) return null;

		const startDate = new Date(item.salaryFeeStandardStartDate);
		const endDate = new Date(item.salaryFeeStandardEndDate);
		const today = new Date();
		let statusText = '';
		if (today >= startDate && today <= endDate) {
			statusText = '현재 적용중';
		} else if (today < startDate) {
			statusText = '적용 예정';
		} else {
			statusText = '이전 이력';
		}

		return (
			<CRStatus type='pill' options={CENTER_FINANCE_STATUS_CONFIG}>
				{statusText}
			</CRStatus>
		);
	};

	return (
		<S.Container>
			<S.TableRow
				$isHeader
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 1,
				}}>
				<S.TableHeaderColumn
					style={{
						width: '5.6rem',
					}}
				/>
				<S.TableHeaderColumn
					style={{
						width: '16rem',
					}}>
					상태
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '12rem',
					}}>
					적용 시작연월
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '4rem',
					}}
				/>
				<S.TableHeaderColumn
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '12rem',
						whiteSpace: 'nowrap',
					}}>
					적용 종료연월
					<RDTooltip
						side='top'
						content={
							<>
								새 재무 정보를 추가하면 자동으로 설정됩니다.
								<br />
								(다음 적용 시작일의 전월)
							</>
						}>
						<S.QuestionMark src={Assets.icon.help} alt='이용요일 도움말' />
					</RDTooltip>
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						flex: 1,
					}}
				/>
			</S.TableRow>
			{items?.map((item) => (
				<S.TableRow key={v4()} onClick={() => handleClickRow(item)}>
					<S.TableBodyColumn
						style={{
							width: '5.6rem',
						}}
					/>
					<S.TableBodyColumn
						style={{
							width: '16rem',
						}}>
						{renderStatus(item)}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '12rem',
						}}>
						{item.salaryFeeStandardStartDate
							? dayjs(item.salaryFeeStandardStartDate).format('YYYY년 MM월')
							: '-'}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '4rem',
							color: Colors.gray90,
						}}>
						~
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '12rem',
						}}>
						{item.salaryFeeStandardEndDate
							? dayjs(item.salaryFeeStandardEndDate).format('YYYY년 MM월')
							: '-'}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							flex: 1,
						}}
					/>
				</S.TableRow>
			))}
		</S.Container>
	);
}
