import styled from 'styled-components';

export const ListBackground = styled.div`
	background: ${(p) => p.theme.colors.gray95};
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	padding: 1.6rem 2rem;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid ${(p) => p.theme.colors.gray90};
	background: ${(p) => p.theme.colors.white};
	padding: 0.8rem 1.6rem 1rem 1.6rem;
`;
