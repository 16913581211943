import styled from 'styled-components';

export const Page = styled.div`
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
`;

export const Background = styled.div`
	background-color: #fafafa;
	width: 100%;
	height: 100%;
`;

export const Container = styled.div`
	background-color: white;
	max-width: 768px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	position: relative;
`;
