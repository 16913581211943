import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: 2rem;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	box-sizing: border-box;
`;

export const SectionTitle = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10}
`;

export const PaymentBillContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const PaymentBillText = styled.div`
	margin-right: 0.8rem;
`;
