import * as XLSX from 'sheetjs-style';
import {
	InsuranceAcqExcelDownloadDTO,
	InsuranceEtcExcelDownloadDTO,
	InsuranceLossExcelDownloadDTO,
} from 'types/api/insurance';

export const exportInsuranceEtcTransfer = (
	fileName: string,
	data: InsuranceEtcExcelDownloadDTO[],
) => {
	const excelData = data.map(
		({
			insuranceState,
			createdDate,
			name,
			insuranceType,
			pensionInsuranceRequestAmt,
			pensionInsuranceRequestDate,
			healthInsuranceRequestAmt,
			healthInsuranceRequestDate,
			employInsuranceRequestAmt,
			employInsuranceRequestDate,
			accidentInsuranceRequestAmt,
			accidentInsuranceRequestDate,
			paySumAmtCurrentYear,
			paySumAmtLastYear,
			paySumAmtLastYear1stHalf,
			paySumAmtLastYear2ndHalf,
		}) => ({
			상태: insuranceState || '',
			생성일시: createdDate || '',
			요양보호사: name || '',
			'득실 타입': insuranceType || '',
			'국민연금 신고금액': pensionInsuranceRequestAmt
				? pensionInsuranceRequestAmt.toLocaleString()
				: '',
			'국민연금 신고일자': pensionInsuranceRequestDate || '',
			'건강보험 신고금액': healthInsuranceRequestAmt
				? healthInsuranceRequestAmt.toLocaleString()
				: '',
			'건강보험 신고일자': healthInsuranceRequestDate || '',
			'고용보험 신고금액': employInsuranceRequestAmt
				? employInsuranceRequestAmt.toLocaleString()
				: '',
			'고용보험 신고일자': employInsuranceRequestDate || '',
			'산재보험 신고금액': accidentInsuranceRequestAmt
				? accidentInsuranceRequestAmt.toLocaleString()
				: '',
			'산재보험 신고일자': accidentInsuranceRequestDate || '',
			'당해년도 총급여액': paySumAmtCurrentYear ? paySumAmtCurrentYear.toLocaleString() : '',
			'전년도 총급여액': paySumAmtLastYear ? paySumAmtLastYear.toLocaleString() : '',
			'전년도 1~6월 급여액': paySumAmtLastYear1stHalf
				? paySumAmtLastYear1stHalf.toLocaleString()
				: '',
			'전년도 7~12월 급여액': paySumAmtLastYear2ndHalf
				? paySumAmtLastYear2ndHalf.toLocaleString()
				: '',
		}),
	);

	const workSheet = XLSX.utils.json_to_sheet(excelData);

	const headerStyle = {
		fill: {
			fgColor: { rgb: 'DDDDDD' },
		},
		alignment: {
			horizontal: 'center',
		},
	};

	workSheet.A1.s = headerStyle;
	workSheet.B1.s = headerStyle;
	workSheet.C1.s = headerStyle;
	workSheet.D1.s = headerStyle;
	workSheet.E1.s = headerStyle;
	workSheet.F1.s = headerStyle;
	workSheet.G1.s = headerStyle;
	workSheet.H1.s = headerStyle;
	workSheet.I1.s = headerStyle;
	workSheet.J1.s = headerStyle;
	workSheet.K1.s = headerStyle;
	workSheet.L1.s = headerStyle;
	workSheet.M1.s = headerStyle;
	workSheet.N1.s = headerStyle;
	workSheet.O1.s = headerStyle;
	workSheet.P1.s = headerStyle;

	const columnStyles = [
		{ width: 12 },
		{ width: 15 },
		{ width: 15 },
		{ width: 20 },
		{ width: 17 },
		{ width: 17 },
		{ width: 17 },
		{ width: 17 },
		{ width: 17 },
		{ width: 17 },
		{ width: 17 },
		{ width: 17 },
		{ width: 20 },
		{ width: 17 },
		{ width: 20 },
		{ width: 20 },
	];
	workSheet['!cols'] = columnStyles;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};

export const exportInsuranceAcqTransfer = (
	fileName: string,
	data: InsuranceAcqExcelDownloadDTO[],
) => {
	const headers = [
		[
			'가입자정보',
			'',
			'',
			'',
			'',
			'',
			'국민연금(소득월액상이사유는 국민연금 소속 직원이 접수하는 경우에만 입력합니다.)',
			'',
			'',
			'',
			'',
			'',
			'',
			'건강보험',
			'',
			'',
			'',
			'',
			'',
			'',
			'고용보험',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'산재보험',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'일자리신청',
			'비고',
			'',
		],
		[
			'*주민등록번호',
			'*성명',
			'*대표자여부',
			'영문성명(외국인)',
			'국적',
			'체류자격',
			// 국민연금
			'*소득월액',
			'*자격취득일',
			'*취득월납부여부',
			'*자격취득부호',
			'특수직종부호',
			'소득월액상이사유(1.국외근로수당 , 2.사후정산)',
			'직역연금구분(1.직역연금가입자, 2.직역연금수급권자, 0.없음)',
			'*피부양자신청',
			'*보수월액',
			'*자격취득일',
			'*자격취득부호',
			'보험료/감면부호',
			'공무원/교직원(회계명)',
			'공무원/교직원(직종명)',
			'*월평균보수',
			'*자격취득일',
			'*직종부호',
			'*주소정근로시간',
			'보험료부과구분(부호)',
			'보험료부과구분(사유)',
			'*계약직여부',
			'계약종료년월',
			'*월평균보수',
			'*자격취득일',
			'직종부호',
			'주소정근로시간',
			'보험료부과구분(부호)',
			'보험료부과구분(사유)',
			'계약직여부',
			'계약종료년월',
			'신청여부',
			'오류메시지',
			'경고메시지',
		],
	];

	// 38
	const excelRows = data.map((row) => [
		row.rsdnNo || '',
		row.korNm || '',
		row.representativeYnNm || '',
		row.enNm || '',
		row.countryNm || '',
		row.stayQualificationNm || '',
		row.pensionInsuranceAmt || '',
		row.pensionInsuranceAcqDate || '',
		row.acqMonthPayYn || '',
		row.pensionInsuranceAcqSign || '',
		'', // 특수직종부호
		'', // 소득월액상이사유(1.국외근로수당 , 2.사후정산)
		'', // 직역연금구분(1.직역연금가입자, 2.직역연금수급권자, 0.없음)
		row.dependentYn || '',
		row.healthInsuranceAmt || '',
		row.healthInsuranceAcqDate || '',
		row.healthInsuranceAcqSign || '',
		'', // 보험료/감면부호
		'', // 공무원/교직원(회계명)
		'', // 공무원/교직원(직종명)
		row.employInsuranceAmt || '',
		row.employInsuranceAcqDate || '',
		row.dutySign || '',
		row.weekPerWorkHourCnt || '',
		'', // 보험료부과구분(부호)
		'', // 보험료부과구분(사유)
		row.workFormYn || '',
		row.workEndYearMonth || '', // 계약종료년월
		row.accidentInsuranceAmt || '',
		row.accidentInsuranceAcqDate || '',
		'', // 직종부호
		'', // 주소정근로시간
		'', // 보험료부과구분(번호)
		'', // 보험료부과구분(사유)
		'', // 계약직여부
		'', // 계약종료년월
		'', // 신청여부
		'', // 오류메세지
		'', // 경고메시지
	]);

	const isNumberColumn = (idx: number) => [6, 14, 20, 28].includes(idx);

	const wb = XLSX.utils.book_new();
	const workSheet = XLSX.utils.aoa_to_sheet([...headers, ...excelRows]);

	const headerBoundary = {
		start: XLSX.utils.decode_cell('A1'),
		end: { c: headers[0].length - 1, r: 1 },
	};

	for (let { c } = headerBoundary.start; c <= headerBoundary.end.c; c += 1) {
		for (let { r } = headerBoundary.start; r <= headerBoundary.end.r; r += 1) {
			const isLastRow = r === headerBoundary.end.r;
			const cellAddress = XLSX.utils.encode_cell({ r, c });
			workSheet[cellAddress].s = {
				fill: {
					fgColor: {
						rgb:
							r === 0
								? 'DDDDDD'
								: [
										3, 4, 5, 10, 11, 12, 17, 18, 19, 24, 25, 27, 30, 31, 32, 33, 34, 35, 36,
								  ].includes(c)
								? 'FFFFFF'
								: 'DDDDDD',
					},
				},
				font: {
					bold: true,
				},
				alignment: { vertical: 'center', horizontal: 'center' },
				border: {
					top: { style: 'thin', color: { rgb: '000000' } },
					bottom: isLastRow
						? { style: 'medium', color: { rgb: '000000' } }
						: { style: 'thin', color: { rgb: '000000' } },
					left: {
						style: r === 1 && [6, 13, 20, 28].includes(c) ? 'thick' : 'thin',
						color: {
							rgb: '000000',
						},
					},
					right: { style: 'thin', color: { rgb: '000000' } },
				},
			};
		}
	}

	// 셀 너비 설정
	const columnStyles = headers[0].map((_, idx) => {
		const getMaxLength = headers[1][idx].length;
		const minWidth = 14;

		return {
			width: Math.max(minWidth, getMaxLength * 2.2),
		};
	});
	workSheet['!cols'] = columnStyles;

	if (excelRows?.length) {
		// 본몬 내용 스타일 설정
		const bodyBoundary = {
			start: XLSX.utils.decode_cell('A3'),
			end: { c: excelRows[0].length - 1, r: excelRows.length + 1 },
		};

		for (let { c } = bodyBoundary.start; c <= bodyBoundary.end.c; c += 1) {
			for (let { r } = bodyBoundary.start; r <= bodyBoundary.end.r; r += 1) {
				const cellAddress = XLSX.utils.encode_cell({ r, c });
				workSheet[cellAddress].s = {
					alignment: { vertical: 'center', horizontal: isNumberColumn(c) ? 'left' : 'center' },
					border: {
						top: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
						bottom: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
						left: {
							style: [6, 13, 20, 28].includes(c) ? 'thick' : 'thin',
							color: {
								rgb: '000000',
							},
						},
						right: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
					},
					...(isNumberColumn(c)
						? {
								numFmt: '#,##0',
						  }
						: {}),
				};
			}
		}
	}

	workSheet['!merges'] = [
		{ s: XLSX.utils.decode_cell('A1'), e: XLSX.utils.decode_cell('F1') },
		{ s: XLSX.utils.decode_cell('G1'), e: XLSX.utils.decode_cell('M1') },
		{ s: XLSX.utils.decode_cell('N1'), e: XLSX.utils.decode_cell('T1') },
		{ s: XLSX.utils.decode_cell('U1'), e: XLSX.utils.decode_cell('AB1') },
		{ s: XLSX.utils.decode_cell('AC1'), e: XLSX.utils.decode_cell('AJ1') },
		{ s: XLSX.utils.decode_cell('AL1'), e: XLSX.utils.decode_cell('AM1') },
	];

	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};

export const exportInsuranceLossTransfer = (
	fileName: string,
	data: InsuranceLossExcelDownloadDTO[],
) => {
	const headers = [
		[
			'성명',
			'주민(외국인)등록번호국내거소신고번호',
			'전화(지역번호)',
			'전화(국번)',
			'전화(뒷번호)',
			'국민연금상실일',
			'국민연금상실부호',
			'국민연금초일취득당월상실자납부여부',
			'건강보험상실일',
			'건강보험상실부호',
			'건강보험당해년도보수총액',
			'건강보험당해년도근무개월수',
			'건강보험전년도보수총액',
			'건강보험전년도근무개월수',
			'고용보험상실연월일',
			'고용보험상실사유구분코드',
			'고용보험구체적사유',
			'고용보험해당연도보수총액',
			'고용보험전년도보수총액',
			'산재보험상실연월일',
			'산재보험해당연도보수총액',
			'산재보험전년도보수총액',
		],
	];

	// 10,12 ,17,18,22,23
	const excelRows = data.map((row) => [
		row.name || '', // 성명
		row.rsdnNo || '', // 주민등록번호
		'', // 전화(지역번호)
		'', // 전화(국번)
		'', // 전화(뒷번호)
		row.pensionInsuranceLossDt || '', // 국민연금상실일
		row.pensionInsuranceLossSign || '', // 국민연금상실부호
		row.pensionInsurancePayYn || '', // 국민연금초일취득당월상실자납부여부
		row.healthInsuranceLossDt || '', // 건강보험상실일자
		row.healthInsuranceLossSign || '', // 건강보험상실부호
		row.healthInsurancePaySumAmtCurrentYear || '', // 건강보험당해년도보수총액
		row.healthInsuranceWorkMonthCurrentYear || '', // 건강보험당해년도근무개월수
		row.healthInsurancePaySumAmtLastYear || '', // 건강보험전년도보수총액
		row.healthInsuranceWorkMonthLastYear || '', // 건강보험전년도근무개월수
		row.employInsuranceLossDt || '', // 고용보험상실일자
		row.employInsuranceLossReasonDivCd || '', // 고용보험상실사유구분코드
		row.employInsuranceLossReasonDetailCd || '', // 고용보험상실구체적사유
		row.employInsurancePaySumAmtCurrentYear || '', // 고용보험당해년도보수총액
		row.employInsurancePaySumAmtLastYear || '', // 고용보험전년도보수총액
		row.accidentInsuranceLossDt || '', // 산재보험상실일자
		row.accidentInsurancePaySumAmtCurrentYear || '', // 산재보험당해년도보수총액
		row.accidentInsurancePaySumAmtLastYear || '', // 산재보험전년도보수총액
	]);

	const isNumberColumn = (idx: number) => [10, 12, 17, 18, 22, 23].includes(idx);

	const wb = XLSX.utils.book_new();
	const workSheet = XLSX.utils.aoa_to_sheet([...headers, ...excelRows]);

	const headerBoundary = {
		start: XLSX.utils.decode_cell('A1'),
		end: { c: headers[0].length - 1, r: 0 },
	};

	for (let { c } = headerBoundary.start; c <= headerBoundary.end.c; c += 1) {
		for (let { r } = headerBoundary.start; r <= headerBoundary.end.r; r += 1) {
			const cellAddress = XLSX.utils.encode_cell({ r, c });
			workSheet[cellAddress].s = {
				fill: {
					fgColor: {
						rgb: 'DDDDDD',
					},
				},
				height: 20,
				font: {
					bold: true,
				},
				alignment: { vertical: 'center', horizontal: 'center' },
				border: {
					top: { style: 'thin', color: { rgb: '000000' } },
					bottom: { style: 'thin', color: { rgb: '000000' } },
					left: {
						style: 'thin',
						color: {
							rgb: '000000',
						},
					},
					right: { style: 'thin', color: { rgb: '000000' } },
				},
			};
		}
	}

	// 셀 너비 설정
	const columnStyles = headers[0].map((_, idx) => {
		const getMaxLength = headers[0][idx].length;
		const minWidth = 14;

		return {
			width: Math.max(minWidth, getMaxLength * 2.2),
		};
	});
	workSheet['!cols'] = columnStyles;

	if (excelRows?.length) {
		// 본몬 내용 스타일 설정
		const bodyBoundary = {
			start: XLSX.utils.decode_cell('A2'),
			end: { c: excelRows[0].length - 1, r: excelRows.length },
		};

		for (let { c } = bodyBoundary.start; c <= bodyBoundary.end.c; c += 1) {
			for (let { r } = bodyBoundary.start; r <= bodyBoundary.end.r; r += 1) {
				const cellAddress = XLSX.utils.encode_cell({ r, c });
				workSheet[cellAddress].s = {
					alignment: { vertical: 'center', horizontal: isNumberColumn(c) ? 'left' : 'center' },
					border: {
						top: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
						bottom: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
						left: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
						right: {
							style: 'thin',
							color: {
								rgb: '000000',
							},
						},
					},
					...(isNumberColumn(c)
						? {
								numFmt: '#,##0',
						  }
						: {}),
				};
			}
		}
	}

	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};
