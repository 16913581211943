import { IntegratedAssessmentMajorIllnessStatusQuestion } from 'types/view/recipient';

export const integratedAssessmentMajorIllnessStatusQuestions: IntegratedAssessmentMajorIllnessStatusQuestion[] =
	[
		{
			key: '만성질환',
			label: '만성질환',
			etcKey: '만성질환암',
			etcPlaceholder: '암 종류 입력',
			etcValue: '암',
			answer: [
				{
					label: '당뇨',
					value: '당뇨',
				},
				{
					label: '고혈압',
					value: '고혈압',
				},
				{
					label: '만성호흡기질환',
					value: '만성호흡기질환',
				},
				{
					label: '암',
					value: '암',
				},
			],
		},
		{
			key: '순환기계',
			label: '순환기계',
			answer: [
				{
					label: '뇌경색',
					value: '뇌경색',
				},
				{
					label: '뇌출혈',
					value: '뇌출혈',
				},
				{
					label: '협심증',
					value: '협심증',
				},
				{
					label: '심근경색증',
					value: '심근경색증',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '신경계',
			label: '신경계',
			answer: [
				{
					label: '치매',
					value: '치매',
				},
				{
					label: '파킨슨병',
					value: '파킨슨병',
				},
				{
					label: '간질',
					value: '간질',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '근골격계',
			label: '근골격계',
			answer: [
				{
					label: '관절염',
					value: '관절염',
				},
				{
					label: '요골/좌골통',
					value: '요골/좌골통',
				},
				{
					label: '골절 등 후유증',
					value: '골절 등 후유증',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '정신행동장애',
			label: '정신/행동장애',
			answer: [
				{
					label: '우울증',
					value: '우울증',
				},
				{
					label: '수면장애',
					value: '수면장애',
				},
				{
					label: '정신질환',
					value: '정신질환',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '호흡기계',
			label: '호흡기계',
			answer: [
				{
					label: '호흡곤란',
					value: '호흡곤란',
				},
				{
					label: '결핵',
					value: '결핵',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '만성신장질환',
			label: '만성신장질환',
			answer: [
				{
					label: '만성신부전증',
					value: '만성신부전증',
					options: [
						{
							label: '복막투석',
							value: '복막투석',
						},
						{
							label: '혈액투석',
							value: '혈액투석',
						},
					],
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '기타질환',
			label: '기타질환',
			answer: [
				{
					label: '알레르기',
					value: '알레르기',
				},
				{
					label: '식품',
					value: '식품',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
	];
