import styled from 'styled-components';

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
	cursor: pointer;
	height: 5.7rem;
	max-height: 5.7rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableBodySubRow = styled(TableBodyRow)`
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableBodyIconContainer = styled.div`
	cursor: pointer;
	width: 2.4rem;
	height: 5.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TableBodyLineContainer = styled.div`
	cursor: pointer;
	width: 2.4rem;
	height: 5.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1.6rem;
`;

export const TableBodySubLine = styled.div`
	width: 0.1rem;
	height: 100%;
	background: ${(props) => props.theme.colors.gray90};
`;

export const TableBodyColumn = styled.td`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray00};
	white-space: nowrap;
	box-sizing: border-box;
	vertical-align: middle;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableBodyColumnWithPadding = styled(TableBodyColumn)`
	padding: 0rem 1.6rem;
	text-align: left;
	box-sizing: border-box;
	&:first-of-type {
		padding-left: 2.4rem;
	}

	&:last-of-type {
		padding-right: 2.4rem;
	}
`;

export const Content = styled.div`
	${(props) => props.theme.typography.label}
	width: 100%;
	display: flex;
	align-items: center;
`;

export const TableBodyToggleIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;
