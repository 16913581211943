import { IntegratedAssessmentDesireFamilySupportSystemQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireFamilySupportSystemQuestions: IntegratedAssessmentDesireFamilySupportSystemQuestion[] =
	[
		{
			key: '동거가족',
			label: '동거인',
			answer: [
				{
					label: '독거',
					value: '독거',
				},
				{
					label: '배우자',
					value: '배우자',
				},
				{
					label: '부모',
					value: '부모',
				},
				{
					label: '자녀',
					value: '자녀',
				},
				{
					label: '자부 ・ 사위',
					value: '자부 ・ 사위',
				},
				{
					label: '손자녀',
					value: '손자녀',
				},
				{
					label: '친척',
					value: '친척',
				},
				{
					label: '친구 ・ 이웃',
					value: '친구 ・ 이웃',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '주수발자관계',
			label: '주수발자 관계',
			answer: [
				{
					label: '배우자',
					value: '배우자',
				},
				{
					label: '자녀',
					value: '자녀',
				},
				{
					label: '자부 ・ 사위',
					value: '자부 ・ 사위',
				},
				{
					label: '손자녀',
					value: '손자녀',
				},
				{
					label: '친척',
					value: '친척',
				},
				{
					label: '친구 ・ 이웃',
					value: '친구 ・ 이웃',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '주수발자경제상태',
			label: '주수발자\n경제상태',
			answer: [
				{
					label: '안정',
					value: '안정',
				},
				{
					label: '불안정',
					value: '불안정',
				},
				{
					label: '연금생활',
					value: '연금생활',
				},
				{
					label: '기초생활수급자',
					value: '기초생활수급자',
				},
				{
					label: '의료급여',
					value: '의료급여',
				},
			],
		},
		{
			key: '주수발자부양부담',
			label: '주수발자\n부양부담',
			answer: [
				{
					label: '전혀 부담되지 않음',
					value: '전혀 부담되지 않음',
				},
				{
					label: '아주 가끔 부담됨',
					value: '아주 가끔 부담됨',
				},
				{
					label: '가끔 부담됨',
					value: '가끔 부담됨',
				},
				{
					label: '자주 부담됨',
					value: '자주 부담됨',
				},
				{
					label: '항상 부담됨',
					value: '항상 부담됨',
				},
			],
		},
		{
			key: '종교활동',
			label: '종교활동',
			answer: [
				{
					label: '천주교',
					value: '천주교',
				},
				{
					label: '기독교',
					value: '기독교',
				},
				{
					label: '불교',
					value: '불교',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '지역사회자원',
			label: '지역사회자원',
			answer: [
				{
					label: '노인맞춤돌봄서비스',
					value: '노인맞춤돌봄서비스',
					disabled: true,
					options: [
						{
							label: '노인돌봄기본 서비스',
							value: '노인돌봄기본 서비스',
						},
						{
							label: '노인돌봄 종합서비스',
							value: '노인돌봄 종합서비스',
						},
						{
							label: '단기기사서비스',
							value: '단기기사서비스',
						},
						{
							label: '독거노인사회관계활성화',
							value: '독거노인사회관계활성화',
						},
						{
							label: '초기독거노인자립지원',
							value: '초기독거노인자립지원',
						},
					],
				},
				{
					label: '가사간병',
					value: '가사간병',
				},
				{
					label: '재가복지',
					value: '재가복지',
				},
				{
					label: '급식 및 도시락배달',
					value: '급식 및 도시락배달',
				},
				{
					label: '보건소사업',
					value: '보건소사업',
				},
				{
					label: '개인간병인',
					value: '개인간병인',
				},
				{
					label: '산업재해 간병인',
					value: '산업재해 간병인',
				},
				{
					label: '치매안심센터',
					value: '치매안심센터',
				},
				{
					label: '복지관(마을회관)',
					value: '복지관(마을회관)',
				},
				{
					label: '노인정',
					value: '노인정',
				},
				{
					label: '이동서비스',
					value: '이동서비스',
				},
				{
					label: '종교단체',
					value: '종교단체',
				},
				{
					label: '이 ・ 미용',
					value: '이 ・ 미용',
				},
				{
					label: '주거개선사업',
					value: '주거개선사업',
				},
			],
		},
	];
