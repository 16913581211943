import React from 'react';

import { CheckOption } from 'components/base/Selections/type';
import * as S from './styles';
import Highlighter from '../../../ui/Highlighter';

interface Props {
	searchText?: string;
	searchItems?: CheckOption[];
	onClickSearchItem?: (searchItem: CheckOption) => void;
	tableInput?: boolean;
}

function SearchDropDown({
	tableInput = false,
	searchText,
	searchItems = [],
	onClickSearchItem,
}: Props): React.ReactElement {
	const renderSearchItem = (searchItem: CheckOption) => {
		const { id, birth, duty, guardian } = searchItem.value;
		if (['수급자', '요양보호사', '간호사', '간호조무사'].includes(duty)) {
			return [duty, birth].filter((val) => val).join('・');
		}
		return [duty, birth, id, guardian].filter((val) => val).join('・');
	};

	return (
		<S.SearchDropDownContainer $tableInput={tableInput}>
			<S.SearchResultTitle $tableInput={tableInput}>검색 결과</S.SearchResultTitle>
			<S.SearchResultContainer $tableInput={tableInput}>
				{searchItems.length > 0 ? (
					searchItems?.map((searchItem) => (
						<S.SearchResultItemOuterContainer>
							<S.SearchResultItemContainer
								$tableInput={tableInput}
								key={searchItem.value?.id}
								onClick={(e) => {
									e.stopPropagation();
									onClickSearchItem?.(searchItem);
								}}>
								<S.SearchResultItemMainContainer $tableInput={tableInput}>
									<Highlighter
										text={`${searchItem.value.name}`}
										targetText={searchText ?? ''}
										highlightStyle={{ color: 'red' }}
									/>
								</S.SearchResultItemMainContainer>
								<S.SearchResultItemSubContainer>
									<Highlighter
										text={renderSearchItem(searchItem)}
										targetText={searchText ?? ''}
										highlightStyle={{ color: 'red' }}
									/>
								</S.SearchResultItemSubContainer>
							</S.SearchResultItemContainer>
							{searchItem.children?.map((chidren) => (
								<S.SearchResultItemChildContainer
									key={chidren.value?.id}
									onClick={(e) => {
										e.stopPropagation();
										onClickSearchItem?.(chidren);
									}}>
									<S.Line />
									<S.SearchResultItemMainContainer $tableInput={tableInput}>
										<Highlighter
											text={`${chidren.value.name}`}
											targetText={searchText ?? ''}
											highlightStyle={{ color: 'red' }}
										/>
									</S.SearchResultItemMainContainer>
									<S.SearchResultItemSubContainer>
										<Highlighter
											text={renderSearchItem(chidren)}
											targetText={searchText ?? ''}
											highlightStyle={{ color: 'red' }}
										/>
									</S.SearchResultItemSubContainer>
								</S.SearchResultItemChildContainer>
							))}
						</S.SearchResultItemOuterContainer>
					))
				) : (
					<S.SearchResultEmptyListContainer>
						<S.SearchResultEmptyListDescription $tableInput={tableInput}>
							{searchText ? '검색결과가 없습니다.' : '검색어를 입력해주세요.'}
						</S.SearchResultEmptyListDescription>
					</S.SearchResultEmptyListContainer>
				)}
			</S.SearchResultContainer>
		</S.SearchDropDownContainer>
	);
}

export default SearchDropDown;
