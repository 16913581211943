import { IntegratedAssessmentBedsoreQuestion } from 'types/view/recipient';

export const integratedAssessmentBedsoreQuestions: IntegratedAssessmentBedsoreQuestion[] = [
	{
		key: '감각인지여부',
		label: '감각 인지 여부',
		answer: [
			{
				label: '1. 감각 완전 제한됨',
				description:
					'의식저하 및 진정자극으로 인해 통증자극에 대한 반응 없음. 신체 대부분 통증 지각 없음',
				point: 1,
			},
			{
				label: '2. 감각 매우 제한됨',
				description: '통증자극에만 반응 (신음, 불안정한 양상 보임), 신체 1/2만 통증 지각',
				point: 2,
			},
			{
				label: '3. 감각 약간 제한됨',
				description: '말로 지시하면 반응하지만 항상 말할 수 있지 않다',
				point: 3,
			},
			{
				label: '4. 감각 손상 없음',
				description: '언어적 명령에 반응, 통증을 말로 표현할 수 있다',
				point: 4,
			},
		],
	},
	{
		key: '습기여부',
		label: '습기 여부',
		answer: [
			{
				label: '1. 항상 젖어 있음',
				description: '피부가 땀, 소변 등으로 항상 젖어있다',
				point: 1,
			},
			{
				label: '2. 자주 젖어 있음',
				description: '자주 축축해져 있어 8시간에 한번은 린넨을 갈아주어야 한다',
				point: 2,
			},
			{
				label: '3. 가끔 젖어 있음',
				description: '가끔 축축하여 하루에 한번 정도 린넨 교환이 필요하다',
				point: 3,
			},
			{
				label: '4. 거의 젖지 않음',
				description: '피부는 보통 건조하여 린넨은 평상시대로 교환해주면 된다',
				point: 4,
			},
		],
	},
	{
		key: '활동상태',
		label: '활동 상태',
		answer: [
			{
				label: '1. 항상 침대 생활',
				description: '도움 없이는 조금도 움직이지 못한다',
				point: 1,
			},
			{
				label: '2. 의자에 앉을 수 있음',
				description:
					'걸을 수 없거나 걷는 능력이 상당히 제한되어 있어 의자나 휠체어 이동 시 상당한 도움이 필요로 한다',
				point: 2,
			},
			{
				label: '3. 가끔 걸을 수 있음',
				description: '대부분의 시간을 침상에서 보내며 잠깐동안 매우 짧은 거리를 걸을 수 있다',
				point: 3,
			},
			{
				label: '4. 자주 걸을 수 있음',
				description: '적어도 하루에 두 번 방 밖을 걷고, 방 안을 2시간마다 걷는다',
				point: 4,
			},
		],
	},
	{
		key: '움직임',
		label: '움직임',
		answer: [
			{
				label: '1. 완전히 못 움직임',
				description: '도움 없이는 신체나 사지를 전혀 움직이지 못한다.',
				point: 1,
			},
			{
				label: '2. 매우 제한됨',
				description: '신체나 사지의 체위를 가끔 변경시킬 수 있지만 자주는 못한다',
				point: 2,
			},
			{
				label: '3. 약간 제한됨',
				description: '혼자서 신체나 사지의 체위를 조금이기는하지만 자주 변경 시킨다',
				point: 3,
			},
			{
				label: '4. 제한없음',
				description: '도움 없이도 체위를 자주 변경시킨다',
				point: 4,
			},
		],
	},
	{
		key: '영양상태',
		label: '영양상태',
		answer: [
			{
				label: '1. 매우나쁨',
				description:
					'제공된 음식의 1/3이하를 섭취, 5일 이상동안 금식상태이거나 유동식으로 유지한다',
				point: 1,
			},
			{
				label: '2. 부족함',
				description: '제공된 음식의 1/2를 섭취, 유동식이나 위관영양을 적정량 미만으로 투여받는다',
				point: 2,
			},
			{
				label: '3. 적당함',
				description:
					'식사의 반 이상을 먹는다. 가끔 식사를 거부하지만 보통 영양 보충식이는 섭취한다',
				point: 3,
			},
			{
				label: '4. 우수함',
				description: '대부분의 식사를 섭취, 영양보충식이는 필요하지 않다',
				point: 4,
			},
		],
	},
	{
		key: '마찰력과응전력',
		label: '마찰력과\n응전력',
		answer: [
			{
				label: '1. 문제있음',
				description:
					'움직임에 많은 도움을 필요로하며 린넨으로 끌어당기지 않고\n완전히 들어올리는 것은 불가능함. 관절구축이나, 강직,\n움직임 등으로 항상 마찰이 생긴다',
				point: 1,
			},
			{
				label: '2. 잠정적으로 문제있음',
				description:
					'움직이는 동안 의자 억제대나 린넨 또는 다른 장비에 의해\n마찰이 생길 수 있으며, 약간은 미끄러져 내려올 수 있다',
				point: 2,
			},
			{
				label: '3. 문제없음',
				description:
					'침대나 의자에서 자유로이 움직이며 움직일 때 스스로 자신을\n들어올릴 수 있을 정도로 충분한 근력이 있다',
				point: 3,
			},
		],
	},
];
