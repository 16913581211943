import React, { useEffect } from 'react';
import CRButton from 'components/base/CRButton';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';

import { v4 } from 'uuid';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import CRInput from 'components/base/CRInput';
import { EmployeeOtherCorpWork, displayPadTime } from 'lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeOtherCorpWorkFormViewType } from 'types/view/employee';
import { SaveEmployeeOtherCorpWorkRequestDTO, OtherCorpWorkSave } from 'types/api/employee';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import useDialog from 'lib/hook/util/useDialog';
import Assets from 'assets';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useUpdateEmployeeOtherCorpWork } from 'lib/hook/react-query';

import { Toast } from 'components/base/CRToast';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import { endpoint } from 'lib/service/Api/endpoint';
import CRInputMessage from 'components/base/CRInputMessage';
import dayjs from 'dayjs';
import { ErrorMessage } from 'components/base/CRInputMessage/styles';
import * as S from './styles';

interface Props {
	items?: EmployeeOtherCorpWorkFormViewType[];
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

function OtherCorpWorkEdit({
	items,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { currentEmployee } = useEmployeePage();
	const { showDialog, hideDialog } = useDialog();
	const { mutate: updateEmployeeOtherCorpWork } = useUpdateEmployeeOtherCorpWork(
		(client, returnData) => {
			if (returnData?.employeeId) {
				client.invalidateQueries([
					endpoint.getEmployeeDetailInfo.key,
					{
						centerId: Number(currentEmployee?.centerId),
						memberId: Number(currentEmployee?.memberId),
						employeeId: Number(currentEmployee?.employeeId),
					},
				]);
				client.invalidateQueries([
					endpoint.getEmployeeBaseInfo.key,
					{
						centerId: Number(currentEmployee?.centerId),
						memberId: Number(currentEmployee?.memberId),
						employeeId: Number(currentEmployee?.employeeId),
					},
				]);
				hideDialog();
				onStartChangeSection?.();
				Toast.success('타근무를 수정했습니다.');
			} else {
				onCancelChangeSection?.();
			}
		},
	);

	const { control, handleSubmit, watch, setValue, getValues } = useForm<{
		items: EmployeeOtherCorpWorkFormViewType[];
	}>({
		resolver: yupResolver(EmployeeOtherCorpWork),
		mode: 'onChange',
		defaultValues: {
			items:
				items?.length === 0
					? [
							{
								otherCorpWorkId: v4(),
								personalBusinessOwnerYn: [],
								otherCorpNm: '',
								otherCorpPhoneNo: '',
								remark: '',
								times: [
									{
										id: v4(),
										days: [],
										otherCorpWorkStartTime: '',
										otherCorpWorkEndTime: '',
									},
								],
							},
					  ]
					: items,
		},
		reValidateMode: 'onChange',
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'items',
	});

	const checkValidOhterService = (form: { items: EmployeeOtherCorpWorkFormViewType[] }) =>
		form.items.every((item, idx1) =>
			item.times.every((time, idx2) => {
				const startTime = dayjs(`2000-01-01 ${displayPadTime(time.otherCorpWorkStartTime)}:00`);
				const endTime = dayjs(`2000-01-01 ${displayPadTime(time.otherCorpWorkEndTime)}:00`);
				const result = startTime.isBefore(endTime);

				if (!result) {
					Toast.error(`근무일시를 확인해주세요 [타근무${idx1 + 1}의 ${idx2 + 1}번째]`);
				}
				return result;
			}),
		);

	const checkDuplicateOtherService = (form: { items: EmployeeOtherCorpWorkFormViewType[] }) => {
		const { items } = form;
		const flatSchedule = items.flatMap((item, idx1) =>
			item.times.flatMap((time, idx2) => ({
				itemInfo: `타근무${idx1 + 1}`,
				timeInfo: `${idx2 + 1}번`,
				...time,
			})),
		);

		for (let i = 0; i < flatSchedule.length; i += 1) {
			for (let j = 0; j < flatSchedule.length; j += 1) {
				// eslint-disable-next-line no-continue
				if (i === j) continue;

				const timeOne = flatSchedule[i];
				const { otherCorpWorkStartTime: _oneStart, otherCorpWorkEndTime: _oneEnd } = timeOne;
				const timeTwo = flatSchedule[j];
				const { otherCorpWorkStartTime: _twoStart, otherCorpWorkEndTime: _twoEnd } = timeTwo;

				const oneStart = displayPadTime(_oneStart);
				const oneEnd = displayPadTime(_oneEnd);
				const twoStart = displayPadTime(_twoStart);
				const twoEnd = displayPadTime(_twoEnd);

				// 중복 시간이 있는지 체크
				const isDuplicated =
					// two의 시작시간이 one의 시간 번위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${twoStart}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// two의 종료시간이 one의 시간 번위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoEnd}:00`) &&
						dayjs(`2000-01-01 ${twoEnd}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// two의 시간 범위가 one의 시간 범위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${twoEnd}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// one의 시간 범위가 two의 시간 범위 안에 있을때
					(dayjs(`2000-01-01 ${twoStart}:00`).isBefore(`2000-01-01 ${oneStart}:00`) &&
						dayjs(`2000-01-01 ${oneEnd}:00`).isBefore(`2000-01-01 ${twoEnd}:00`)) ||
					// one의 시간범위와 two의 시간 범위가 동일할때
					(dayjs(`2000-01-01 ${oneStart}:00`).isSame(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${oneEnd}:00`).isSame(`2000-01-01 ${twoEnd}:00`));
				// 중복된 시간이 있다면 날짜 체크
				if (isDuplicated) {
					const oneDays = timeOne.days.map((day) => day.otherCorpWorkDayCd);
					const twoDays = timeTwo.days.map((day) => day.otherCorpWorkDayCd);

					const DuplicateInfo = [
						`${timeOne.itemInfo}의 ${timeOne.timeInfo}`,
						`${timeTwo.itemInfo}의 ${timeTwo.timeInfo}`,
					].sort();

					for (let k = 0; k < oneDays.length; k += 1) {
						for (let l = 0; l < twoDays.length; l += 1) {
							if (oneDays[k] === twoDays[l]) {
								Toast.error(
									`중복된 일정이 존재합니다. [${DuplicateInfo[0]}] [${DuplicateInfo[1]}]`,
									{ autoClose: 5000 },
								);
								return false;
							}
						}
					}
				}
			}
		}

		return true;
	};

	const onSubmitFail = (errors: { items: FieldErrors<EmployeeOtherCorpWorkFormViewType>[] }) => {
		const item = errors?.items?.filter?.(Boolean)?.[0];
		onCancelChangeSection?.();
		if (!item) return;
		if (Object.keys(item).length === 1 && item.times) {
			const detailItem = item?.times?.filter?.(Boolean)?.[0] as FieldErrors<{
				otherServiceUseStartTime: string;
				otherServiceUseEndTime: string;
				days: string;
			}>;
			if (!Array.isArray(item?.times)) {
				Toast.error(item.times.message || '입력폼을 확인해주세요');
				return;
			}
			if (detailItem) {
				Toast.error(Object.values(detailItem)[0]?.message || '입력폼을 확인해주세요');
			}
		} else {
			Toast.error(Object.values(item)[0]?.message || '입력폼을 확인해주세요');
		}
	};

	// 근무시간 삭제
	const handleDeleteTimePickerItem = (index: number, timeIndex: number) => () => {
		const previousTimes = getValues(`items.${index}.times`) ?? [];
		const newTimes = previousTimes.filter((_, itemIndex) => itemIndex !== timeIndex);
		setValue(`items.${index}.times`, newTimes);
	};

	// 근무시간 추가
	const handleAddTime = (index: number) => () => {
		const previousTimes = getValues(`items.${index}.times`) ?? [];

		setValue(`items.${index}.times`, [
			...previousTimes,
			{
				days: [],
				otherCorpWorkStartTime: '',
				otherCorpWorkEndTime: '',
			},
		]);
	};

	// 타근무 삭제
	const handleClickRemoveOtherCorpWork = (index: number) => () => {
		remove(index);
	};

	// 타근무 추가
	const handleAddOtherCorpWorkItem = () => {
		append({
			otherCorpWorkId: v4(),
			personalBusinessOwnerYn: [],
			otherCorpNm: '',
			otherCorpPhoneNo: '',
			remark: '',
			times: [
				{
					id: v4(),
					days: [],
					otherCorpWorkStartTime: '',
					otherCorpWorkEndTime: '',
				},
			],
		});
	};

	const handleChangeTime =
		(index: number, timeIndex: number) =>
		(dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
			const days = dayOfTheWeeks?.map((day) => ({ otherCorpWorkDayCd: day }));
			setValue(`items.${index}.times.${timeIndex}.days`, days);
			setValue(
				`items.${index}.times.${timeIndex}.otherCorpWorkStartTime`,
				startTime?.replace(':', ''),
			);
			setValue(`items.${index}.times.${timeIndex}.otherCorpWorkEndTime`, endTime?.replace(':', ''));
		};

	const onSubmit = async (form: { items: EmployeeOtherCorpWorkFormViewType[] }) => {
		if (!currentEmployee) return;

		// 토스트까지 함수내부에서 처리
		if (!checkValidOhterService(form)) {
			onCancelChangeSection?.();
			return;
		}
		// 토스트까지 함수내부에서 처리
		if (!checkDuplicateOtherService(form)) {
			onCancelChangeSection?.();
			return;
		}

		const data: SaveEmployeeOtherCorpWorkRequestDTO = {
			employeeId: currentEmployee.employeeId,
			otherCorpWorks: form.items?.map((item) => ({
				personalBusinessOwnerYn: !!item?.personalBusinessOwnerYn?.[0].value,
				otherCorpNm: item.otherCorpNm,
				otherCorpPhoneNo: item.otherCorpPhoneNo,
				remark: item.remark,
				times: item.times,
			})) as OtherCorpWorkSave[],
		};

		updateEmployeeOtherCorpWork(data);
	};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='타근무에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmit, onSubmitFail as any)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	return (
		<S.Container>
			<S.Form>
				<S.Label>
					타근무
					<S.ButtonContainer>
						<CRButton.Default
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={onSubmitHandler}>
							취소
						</CRButton.Default>
						<CRButton.Default
							size='xSmall'
							type='filled'
							palette='gray'
							onClick={handleSubmit(onSubmit, onSubmitFail as any)}>
							저장
						</CRButton.Default>
					</S.ButtonContainer>
				</S.Label>
				{fields?.map((_, index) => (
					<>
						<S.ItemHeader>
							{`타근무 ${String(index + 1).padStart(2, '0')}`}
							<S.DeleteButtonContainer>
								<CRButton.Default
									type='outlined'
									size='xSmall'
									onClick={handleClickRemoveOtherCorpWork(index)}>
									삭제
								</CRButton.Default>
							</S.DeleteButtonContainer>
						</S.ItemHeader>
						<S.Table>
							<S.TableRow>
								<S.TableLabelColumn>
									유형 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn>
									<Controller
										render={({ field: { ref, onChange }, formState: { errors } }) => (
											<>
												<CRCheckBoxGroup
													ref={ref}
													checkType='single'
													type='radio'
													gap={0.8}
													onChange={(e) => {
														onChange(e);
													}}
													value={watch(`items.${index}.personalBusinessOwnerYn`)}
													options={[
														{
															label: '근로자',
															value: false,
														},
														{
															label: '사업자',
															value: true,
														},
													]}
												/>
												{errors?.items?.[index]?.personalBusinessOwnerYn && (
													<CRInputMessage type='error'>
														{errors.items[index]?.personalBusinessOwnerYn?.message ?? ''}
													</CRInputMessage>
												)}
											</>
										)}
										name={`items.${index}.personalBusinessOwnerYn`}
										control={control}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn
									rowSpan={3}
									style={{
										verticalAlign: 'top',
									}}>
									비고
								</S.TableLabelColumn>
								<S.TableValueColumn rowSpan={3}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TextArea
												status={errors?.items?.[index]?.remark?.message ? 'error' : 'default'}
												addOnBottom={errors?.items?.[index]?.remark?.message}
												typography='label'
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='비고 입력'
												numberOfLines={4}
											/>
										)}
										name={`items.${index}.remark`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn>
									상호명 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												status={errors?.items?.[index]?.otherCorpNm ? 'error' : 'default'}
												addOnBottom={errors?.items?.[index]?.otherCorpNm?.message}
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='상호명 입력'
											/>
										)}
										name={`items.${index}.otherCorpNm`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn>전화번호</S.TableLabelColumn>
								<S.TableValueColumn>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												type='number'
												onBlur={onBlur}
												onChange={onChange}
												status={
													errors.items?.[index]?.otherCorpPhoneNo?.message ? 'error' : 'default'
												}
												value={value}
												maxLength={11}
												placeholder='전화번호 입력'
											/>
										)}
										name={`items.${index}.otherCorpPhoneNo`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn>
									근무일시 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ verticalAlign: 'middle' }} colSpan={3}>
									<Controller
										render={({ formState: { errors } }) => (
											<S.TimePickerContainer>
												{watch(`items.${index}.times`).map((time, timeIndex) => (
													<React.Fragment key={time.id}>
														<CRInput.TimePicker
															selectedDayOfTheWeeks={time.days?.map(
																(day) => day.otherCorpWorkDayCd as DayOfTheWeek,
															)}
															start={transformTimeString(time.otherCorpWorkStartTime)}
															end={transformTimeString(time.otherCorpWorkEndTime)}
															onChange={handleChangeTime(index, timeIndex)}
															onDelete={handleDeleteTimePickerItem(index, timeIndex)}
														/>
														{errors.items?.[index]?.times?.[timeIndex] && (
															<ErrorMessage>
																{
																	Object.values(
																		errors?.items?.[index]?.times?.[timeIndex] as {
																			[key: string]: { message: string };
																		},
																	)[0].message
																}
															</ErrorMessage>
														)}
													</React.Fragment>
												))}
												<CRButton.IconButton
													onClick={handleAddTime(index)}
													iconLeft={Assets.icon.add}
													palette='gray'
													type='tonal'>
													근무일시 추가
												</CRButton.IconButton>
												{errors.items?.[index]?.times && (
													<ErrorMessage>{errors.items?.[index]?.times?.message}</ErrorMessage>
												)}
											</S.TimePickerContainer>
										)}
										name={`items.${index}.times`}
										control={control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
						</S.Table>
					</>
				))}
			</S.Form>
			<S.AddButtonContainer>
				<CRButton.IconButton
					onClick={handleAddOtherCorpWorkItem}
					style={{
						width: 'fit-content',
					}}
					palette='gray'
					type='outlined'
					iconLeft={Assets.icon.add}>
					타근무 추가
				</CRButton.IconButton>
			</S.AddButtonContainer>
		</S.Container>
	);
}

export default React.memo(OtherCorpWorkEdit);
