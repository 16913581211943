import React, { useCallback, useEffect, useMemo } from 'react';

import Assets from 'assets';
import useSideModal from 'lib/hook/util/useSideModal';
import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import CRStatus from 'components/base/CRStatus';
import { COMPLETE_STATUS_CONFIG } from 'components/domain/table/MonitoringTable/constant';
import { displayPadTime, MonitoringDetail } from 'lib';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { MonitoringDetailsDTO, ResponseCode, SaveScheduleMonitoringDetailRequest } from 'types/api';
import { useMonitoringDetailItem } from 'lib/hook/react-query/query/monitoring';
import { ChargeTypeCds } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import { Controller, FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MonitoringDetailForm } from 'types/view/monitoring';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { Toast } from 'components/base/CRToast';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import {
	useSaveScheduleMonitoringDetail,
	useUpdateMonitoringDetailItemCompleteStatus,
} from 'lib/hook/react-query/mutation/monitoring';
import { endpoint } from 'lib/service/Api/endpoint';
import useDialog from 'lib/hook/util/useDialog';
import { MonitoringDetailItemsCompleteStatusDialog } from 'components/domain/dialog/MonitoringDetailItemsCompleteStatusDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { useQueryClient } from '@tanstack/react-query';
import * as S from './styles';
import SpecificDescForm from './SpecificDesc';
import { SpecialDescCheck } from './SpecialDescCheck';
import { TagTimeError } from './TagTimeError';

interface Props {
	item: MonitoringDetailsDTO;
	clearCurrentRow?: () => void;
}

function MonitoringSideModal({ item, clearCurrentRow }: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const hasPermission = useHasPermission(['센터장']);
	const queryClient = useQueryClient();

	const { data: monitoringDetailItem, refetch: detailRefetch } = useMonitoringDetailItem({
		centerId: item.centerId,
		serviceYm: item.serviceYm,
		recipientId: item.recipientId,
		employeeId: item.employeeId,
		serviceTypeCd: item.serviceTypeCd,
		startDate: dayjs(item.schedulePlanStartDt).format('YYYY-MM-DD'),
		endDate: dayjs(item.schedulePlanEndDt).format('YYYY-MM-DD'),
		startTime: `${displayPadTime(item.schedulePlanStartTime)}:00`,
		endTime: `${displayPadTime(item.schedulePlanEndTime)}:00`,
	});

	const { mutateAsync: cancelMonitoringSchedule } = useUpdateMonitoringDetailItemCompleteStatus(
		async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				await client.invalidateQueries([endpoint.getMonitoringDetailItem.key]);
				await client.invalidateQueries([endpoint.getMonitoringDetails.key]);
				Toast.success('일정 확정을 취소했습니다.');
			} else {
				Toast.error(`확정 취소에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.`);
			}
		},
	);

	const methods = useForm<MonitoringDetailForm>({
		resolver: yupResolver(MonitoringDetail),
		mode: 'onSubmit',
		defaultValues: {
			data: item,
			time: {
				workStartTime: '',
				workEndTime: '',
			},
		},
	});

	const { mutateAsync: saveDetail } = useSaveScheduleMonitoringDetail();

	const isConfirmed = !!monitoringDetailItem?.scheduleMonitoringCompleteYn;
	const workTime = methods.watch('time');

	const handleSyncWorkTime = () => {
		methods.setValue('time', {
			workStartTime:
				monitoringDetailItem?.schedulePlanStartTime?.slice(0, 5)?.replace(':', '') ?? '',
			workEndTime: monitoringDetailItem?.schedulePlanEndTime?.slice(0, 5)?.replace(':', '') ?? '',
		});
		methods.setValue('workConfirmYn', [
			{
				label: '확인 완료',
				value: true,
			},
		]);
	};

	const { hideSideModal } = useSideModal();

	const onClickClose = () => {
		clearCurrentRow?.();
		hideSideModal();
	};

	const renderUserInfo = () =>
		monitoringDetailItem?.serviceDt
			? `${dayjs(monitoringDetailItem?.serviceDt).format('YYYY년 M월 D일')}`
			: '';

	const renderCompleteStatus = useCallback(() => {
		if (monitoringDetailItem?.schedulePlanCompleteYn === undefined) return '-';
		return (
			<CRStatus options={COMPLETE_STATUS_CONFIG}>
				{monitoringDetailItem?.schedulePlanCompleteYn ? '완료' : '미완료'}
			</CRStatus>
		);
	}, [monitoringDetailItem]);

	const renderPlanTime = useCallback(() => {
		if (!monitoringDetailItem?.schedulePlanStartTime) return '-';
		return (
			<S.TextContainer>
				{monitoringDetailItem?.schedulePlanStartTime?.slice(0, 5)}~
				{monitoringDetailItem?.schedulePlanEndTime
					? monitoringDetailItem?.schedulePlanEndTime?.slice(0, 5)
					: ''}
			</S.TextContainer>
		);
	}, [monitoringDetailItem]);

	// RFID시간
	const renderRfidTime = useCallback(() => {
		if (!monitoringDetailItem?.rfidStartTime) return '-';
		return (
			<S.TextContainer>
				{monitoringDetailItem?.rfidStartTime?.slice(0, 5)}~
				{monitoringDetailItem?.rfidEndTime ? monitoringDetailItem?.rfidEndTime?.slice(0, 5) : ''}
			</S.TextContainer>
		);
	}, [monitoringDetailItem]);

	// 근무시간
	const renderRfidRecordingTime = useCallback(() => {
		if (!monitoringDetailItem?.workStartTime) return '-';
		return (
			<S.TextContainer>
				{monitoringDetailItem?.workStartTime?.slice(0, 5)}~
				{monitoringDetailItem?.workEndTime ? monitoringDetailItem?.workEndTime?.slice(0, 5) : ''}
			</S.TextContainer>
		);
	}, [monitoringDetailItem]);

	const renderRfidProcessState = useCallback(() => {
		if (!monitoringDetailItem?.rfidProcessState) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.rfidProcessState}
				typography='label'
				color={monitoringDetailItem?.rfidProcessState === '미생성' ? 'primary60' : 'gray00'}
			/>
		);
	}, [monitoringDetailItem]);

	const isNotTaggedRecordingSheet = useMemo(
		() => monitoringDetailItem?.chargeTypeCd === ChargeTypeCds.기록지_미태그,
		[monitoringDetailItem],
	);

	const isActiveConfirmInput = useMemo(
		() =>
			[ChargeTypeCds.기록지_미태그, ChargeTypeCds.기록지_태그].includes(
				monitoringDetailItem?.chargeTypeCd as ChargeTypeCds,
			),
		[monitoringDetailItem],
	);

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	const handleChangeTime = (dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
		methods.setValue('time.workStartTime', startTime?.replace(':', ''));
		methods.setValue('time.workEndTime', endTime?.replace(':', ''));

		if (!isConfirmed && startTime.length === 5 && endTime.length === 5) {
			methods.setValue('workConfirmYn', [
				{
					label: '확인 완료',
					value: true,
				},
			]);
		}
	};

	const onSubmitFail = (errors: FieldErrors<MonitoringDetailForm>) => {
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요');
		throw Error();
	};

	const onSave = (data: MonitoringDetailForm) => {
		if (!monitoringDetailItem) return null;

		if (isNotTaggedRecordingSheet) {
			if (data.time?.workStartTime?.length !== 4 || data.time?.workEndTime?.length !== 4) {
				Toast.error('근무 시간은 필수입니다.');
				return null;
			}
		}

		const params: SaveScheduleMonitoringDetailRequest = {
			centerId: monitoringDetailItem.centerId,
			serviceYm: monitoringDetailItem.serviceYm,
			recipientId: monitoringDetailItem.recipientId,
			employeeId: monitoringDetailItem.employeeId,
			serviceTypeCd: monitoringDetailItem.serviceTypeCd,
			schedulePlanStartDate: monitoringDetailItem.schedulePlanStartDate,
			schedulePlanEndDate: monitoringDetailItem.schedulePlanEndDate,
		};

		if (data.scheduleMonitoringMemo) params.scheduleMonitoringMemo = data.scheduleMonitoringMemo;

		if (data.time?.workStartTime && data.time?.workEndTime) {
			params.workStartTime = `${displayPadTime(data.time.workStartTime)}:00`;
			params.workEndTime = `${displayPadTime(data.time.workEndTime)}:00`;
		}

		if (data.workConfirmYn?.length) params.workConfirmYn = data.workConfirmYn[0].value;
		if (data.otherCenterWorkDuplConfirmYn?.length)
			params.otherCenterWorkDuplConfirmYn = data.otherCenterWorkDuplConfirmYn[0].value;
		if (data.otherCorpWorkDuplConfirmYn?.length)
			params.otherCorpWorkDuplConfirmYn = data.otherCorpWorkDuplConfirmYn[0].value;
		if (data.careIntervalWorkDuplConfirmYn?.length)
			params.careIntervalWorkDuplConfirmYn = data.careIntervalWorkDuplConfirmYn[0].value;

		if (isNotTaggedRecordingSheet) {
			params.stateChangeRecordingSheetExpectYn = !!data.stateChangeRecordingSheetExpectYn?.length;
			params.specialHoursRecordingSheetExpectYn = !!data.specialHoursRecordingSheetExpectYn?.length;
			params.cognitiveProgramRecordingSheetExpectYn =
				!!data.cognitiveProgramRecordingSheetExpectYn?.length;
			params.biweeklyBathRecordingSheetExpectYn = !!data.biweeklyBathRecordingSheetExpectYn?.length;
		}

		if (monitoringDetailItem.recogPlanTimeUnderYn) {
			params.recogPlanTimeUnderOfferDescCd = data.recogPlanTimeUnderOfferDescCd?.value;
			params.recogPlanTimeUnderOfferDesc =
				data.recogPlanTimeUnderOfferDescCd?.value === 'CMN203.0199'
					? data.recogPlanTimeUnderOfferDesc
					: data.recogPlanTimeUnderOfferDescCd?.label;
		}

		if (monitoringDetailItem.carePlanTimeOverYn) {
			params.carePlanTimeOverOfferDescCd = data.carePlanTimeOverOfferDescCd?.value;
			params.carePlanTimeOverOfferDesc =
				data.carePlanTimeOverOfferDescCd?.value === 'CMN203.0299'
					? data.carePlanTimeOverOfferDesc
					: data.carePlanTimeOverOfferDescCd?.label;
		}

		if (monitoringDetailItem.careMultipleUseYn) {
			params.careMultipleUseOfferDescCd = data.careMultipleUseOfferDescCd?.value;
			params.careMultipleUseOfferDesc =
				data.careMultipleUseOfferDescCd?.value === 'CMN203.0399'
					? data.careMultipleUseOfferDesc
					: data.careMultipleUseOfferDescCd?.label;
		}

		if (monitoringDetailItem.visitCareFiveGradeYn) {
			params.visitCareFiveGradeOfferDescCd = data.visitCareFiveGradeOfferDescCd?.value;
			params.visitCareFiveGradeOfferDesc =
				data.visitCareFiveGradeOfferDescCd?.value === 'CMN203.0499'
					? data.visitCareFiveGradeOfferDesc
					: data.visitCareFiveGradeOfferDescCd?.label;
		}

		if (monitoringDetailItem.bathOverOnceWeekYn) {
			params.bathOverOnceWeekOfferDescCd = data.bathOverOnceWeekOfferDescCd?.value;
			params.bathOverOnceWeekOfferDesc =
				data.bathOverOnceWeekOfferDescCd?.value === 'CMN203.0599'
					? data.bathOverOnceWeekOfferDesc
					: data.bathOverOnceWeekOfferDescCd?.label;
		}

		params.etcSpecificDesc = data.etcSpecificDesc || '';

		return saveDetail(params);
	};

	const onSubmit = async (data: MonitoringDetailForm) => {
		const result = await onSave(data);
		if (!result) {
			throw Error();
		} else if (result?.code === ResponseCode.SUCCESS) {
			await queryClient.invalidateQueries([endpoint.getMonitoringDetails.key]);
			await detailRefetch();
			Toast.success('정상적으로 저장하였습니다.');
		} else {
			Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};

	const handleClickComplete = () => {
		if (!monitoringDetailItem) return;
		if (monitoringDetailItem?.scheduleMonitoringCompleteYn) {
			// 확정 취소
			cancelMonitoringSchedule({
				centerId: monitoringDetailItem?.centerId,
				serviceYm: monitoringDetailItem?.serviceYm,
				recipientId: monitoringDetailItem?.recipientId,
				employeeId: monitoringDetailItem?.employeeId,
				serviceTypeCd: monitoringDetailItem?.serviceTypeCd,
				schedulePlanStartDate: monitoringDetailItem?.schedulePlanStartDate,
				schedulePlanEndDate: monitoringDetailItem?.schedulePlanEndDate,
				completeYn: false,
			});
		} else {
			// 확정
			showDialog(() => (
				<MonitoringDetailItemsCompleteStatusDialog
					item={monitoringDetailItem}
					onSubmit={methods.handleSubmit(onSubmit, onSubmitFail)}
				/>
			));
		}
	};

	useEffect(() => {
		methods.reset({
			data: monitoringDetailItem || undefined,
			workConfirmYn: isActiveConfirmInput
				? [
						{
							label: '',
							value: monitoringDetailItem?.workConfirmYn ?? false,
						},
				  ]
				: undefined,

			otherCenterWorkDuplConfirmYn: monitoringDetailItem?.otherCenterWorkDuplYn
				? [
						{
							label: '',
							value: monitoringDetailItem?.otherCenterWorkDuplConfirmYn ?? false,
						},
				  ]
				: undefined,

			otherCorpWorkDuplConfirmYn: monitoringDetailItem?.otherCorpWorkDuplYn
				? [
						{
							label: '',
							value: monitoringDetailItem?.otherCorpWorkDuplConfirmYn ?? false,
						},
				  ]
				: undefined,
			careIntervalWorkDuplConfirmYn: monitoringDetailItem?.careIntervalWorkErrorYn
				? [
						{
							label: '',
							value: monitoringDetailItem?.careIntervalWorkDuplConfirmYn ?? false,
						},
				  ]
				: undefined,
			time:
				monitoringDetailItem?.workStartTime && monitoringDetailItem.workEndTime
					? {
							workStartTime:
								monitoringDetailItem?.workStartTime?.slice(0, 5)?.replace(':', '') ?? '',
							workEndTime: monitoringDetailItem?.workEndTime?.slice(0, 5)?.replace(':', '') ?? '',
					  }
					: undefined,
			scheduleMonitoringMemo: monitoringDetailItem?.scheduleMonitoringMemo ?? '',
			stateChangeRecordingSheetExpectYn: monitoringDetailItem?.stateChangeRecordingSheetExpectYn
				? [
						{
							label: '',
							value: true,
						},
				  ]
				: undefined,
			specialHoursRecordingSheetExpectYn: monitoringDetailItem?.specialHoursRecordingSheetExpectYn
				? [
						{
							label: '',
							value: true,
						},
				  ]
				: undefined,
			cognitiveProgramRecordingSheetExpectYn:
				monitoringDetailItem?.cognitiveProgramRecordingSheetExpectYn
					? [
							{
								label: '',
								value: true,
							},
					  ]
					: undefined,
			biweeklyBathRecordingSheetExpectYn: monitoringDetailItem?.biweeklyBathRecordingSheetExpectYn
				? [
						{
							label: '',
							value: true,
						},
				  ]
				: undefined,
			recogPlanTimeUnderOfferDescCd:
				monitoringDetailItem?.recogPlanTimeUnderOfferDescCd &&
				monitoringDetailItem?.recogPlanTimeUnderOfferDesc
					? {
							label:
								monitoringDetailItem?.recogPlanTimeUnderOfferDescCd === 'CMN203.0199'
									? '기타'
									: monitoringDetailItem?.recogPlanTimeUnderOfferDesc,
							value: monitoringDetailItem?.recogPlanTimeUnderOfferDescCd,
					  }
					: undefined,
			recogPlanTimeUnderOfferDesc: monitoringDetailItem?.recogPlanTimeUnderOfferDesc || '',
			carePlanTimeOverOfferDescCd:
				monitoringDetailItem?.carePlanTimeOverOfferDescCd &&
				monitoringDetailItem?.carePlanTimeOverOfferDesc
					? {
							label:
								monitoringDetailItem?.carePlanTimeOverOfferDescCd === 'CMN203.0299'
									? '기타'
									: monitoringDetailItem?.carePlanTimeOverOfferDesc,
							value: monitoringDetailItem?.carePlanTimeOverOfferDescCd,
					  }
					: undefined,
			carePlanTimeOverOfferDesc: monitoringDetailItem?.carePlanTimeOverOfferDesc || '',
			careMultipleUseOfferDescCd:
				monitoringDetailItem?.careMultipleUseOfferDescCd &&
				monitoringDetailItem?.careMultipleUseOfferDesc
					? {
							label:
								monitoringDetailItem?.careMultipleUseOfferDescCd === 'CMN203.0399'
									? '기타'
									: monitoringDetailItem?.careMultipleUseOfferDesc,
							value: monitoringDetailItem?.careMultipleUseOfferDescCd,
					  }
					: undefined,
			careMultipleUseOfferDesc: monitoringDetailItem?.careMultipleUseOfferDesc || '',
			visitCareFiveGradeOfferDescCd:
				monitoringDetailItem?.visitCareFiveGradeOfferDescCd &&
				monitoringDetailItem?.visitCareFiveGradeOfferDesc
					? {
							label:
								monitoringDetailItem?.visitCareFiveGradeOfferDescCd === 'CMN203.0499'
									? '기타'
									: monitoringDetailItem?.visitCareFiveGradeOfferDesc,
							value: monitoringDetailItem?.visitCareFiveGradeOfferDescCd,
					  }
					: undefined,
			visitCareFiveGradeOfferDesc: monitoringDetailItem?.visitCareFiveGradeOfferDesc || '',
			bathOverOnceWeekOfferDescCd:
				monitoringDetailItem?.bathOverOnceWeekOfferDescCd &&
				monitoringDetailItem?.bathOverOnceWeekOfferDesc
					? {
							label:
								monitoringDetailItem?.bathOverOnceWeekOfferDescCd === 'CMN203.0599'
									? '기타'
									: monitoringDetailItem?.bathOverOnceWeekOfferDesc,
							value: monitoringDetailItem?.bathOverOnceWeekOfferDescCd,
					  }
					: undefined,
			bathOverOnceWeekOfferDesc: monitoringDetailItem?.bathOverOnceWeekOfferDesc || '',
			etcSpecificDesc: monitoringDetailItem?.etcSpecificDesc || '',
		});
	}, [monitoringDetailItem, isActiveConfirmInput]);

	return (
		<FormProvider {...methods}>
			<S.Container onSubmit={methods.handleSubmit(onSubmit, onSubmitFail)}>
				<S.Header>
					<CRText typography='h4' text={renderUserInfo()} color='gray00' />
					<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={onClickClose} />
				</S.Header>
				<S.Content>
					<S.ScheduleDetail>
						<S.AccordionContentItem>
							<S.DetailLabel>상태</S.DetailLabel>
							<S.DetailValue>{renderCompleteStatus()}</S.DetailValue>
						</S.AccordionContentItem>
						{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
							<S.AccordionContentItem>
								<S.DetailLabel>직원</S.DetailLabel>
								<S.DetailValue>{monitoringDetailItem?.employeeNm ?? '-'}</S.DetailValue>
							</S.AccordionContentItem>
						)}
						<S.AccordionContentItem>
							<S.DetailLabel>일정계획시간</S.DetailLabel>
							<S.DetailValue>{renderPlanTime()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>청구 유형</S.DetailLabel>
							<S.DetailValue>{monitoringDetailItem?.chargeTypeNm ?? '-'}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>RFID 시간</S.DetailLabel>
							<S.DetailValue>{renderRfidTime()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>
								<FlexContainer>
									<CRText text='근무 시간' color='gray60' typography='label' />
									<RDTooltip
										side='top'
										content={
											<CRText
												color='gray100'
												typography='label'
												text='일정계획시간과 근무시간은 동일해야합니다.'
											/>
										}>
										<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
									</RDTooltip>
								</FlexContainer>
							</S.DetailLabel>
							<S.DetailValue>{renderRfidRecordingTime()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>처리 상태</S.DetailLabel>
							<S.DetailValue>{renderRfidProcessState()}</S.DetailValue>
						</S.AccordionContentItem>
						<S.AccordionContentItem>
							<S.DetailLabel>특이사항</S.DetailLabel>
							<S.DetailValue>{monitoringDetailItem?.rfidSpecialDesc ?? '-'}</S.DetailValue>
						</S.AccordionContentItem>
					</S.ScheduleDetail>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='메모'>
								<CRInput.TextArea
									disabled={isConfirmed}
									onChange={onChange}
									value={value}
									height='10.2rem'
									numberOfLines={2.5}
									placeholder='메모 입력'
									fixedHeight
								/>
							</CRInputLabel>
						)}
						name='scheduleMonitoringMemo'
						control={methods.control}
					/>
					{isNotTaggedRecordingSheet && (
						<CRInputLabel
							label='근무 시간'
							isRequired
							betweenGap='0.8rem'
							renderRightAddon={
								!isConfirmed && (
									<CRButton.Default
										type='outlined'
										size='xSmall'
										palette='gray'
										onClick={handleSyncWorkTime}>
										일정계획시간 적용
									</CRButton.Default>
								)
							}>
							<Controller
								render={({ field: { value }, formState: { errors } }) => (
									<CRInput.TimePicker
										disabled={isConfirmed}
										type='time'
										start={transformTimeString(workTime?.workStartTime)}
										end={transformTimeString(workTime?.workEndTime)}
										onChange={handleChangeTime}
									/>
								)}
								name='time'
								control={methods.control}
							/>
						</CRInputLabel>
					)}
					<Controller
						render={({ field: { onChange, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='근무 확인' isRequired>
								<CRCheckBoxGroup
									disabled={isConfirmed || !isActiveConfirmInput}
									checkType='single'
									onChange={onChange}
									value={value}
									appearanceType='button'
									type='radio'
									gap={0.8}
									options={[
										{
											label: '미확인',
											value: false,
										},
										{
											label: '확인 완료',
											value: true,
										},
									]}
								/>
							</CRInputLabel>
						)}
						name='workConfirmYn'
						control={methods.control}
					/>
					<SpecificDescForm item={monitoringDetailItem} isConfirmed={isConfirmed} />
					<SpecialDescCheck
						isNotTaggedRecordingSheet={isNotTaggedRecordingSheet}
						monitoringDetailItem={monitoringDetailItem}
						control={methods.control}
						isConfirmed={isConfirmed}
					/>
					<TagTimeError monitoringDetailItem={monitoringDetailItem} />
					<S.CheckGroupContainer>
						<S.ContentTitle>
							<CRText text='일정 중복 확인' color='gray10' typography='bodyB' />
						</S.ContentTitle>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='타센터 중복'>
									<CRCheckBoxGroup
										disabled={isConfirmed || !monitoringDetailItem?.otherCenterWorkDuplYn}
										checkType='single'
										onChange={onChange}
										value={value}
										appearanceType='button'
										type='radio'
										gap={0.8}
										options={[
											{
												label: '미확인',
												value: false,
											},
											{
												label: '확인 완료',
												value: true,
											},
										]}
									/>
								</CRInputLabel>
							)}
							name='otherCenterWorkDuplConfirmYn'
							control={methods.control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='타직장 중복'>
									<CRCheckBoxGroup
										disabled={isConfirmed || !monitoringDetailItem?.otherCorpWorkDuplYn}
										checkType='single'
										onChange={onChange}
										value={value}
										appearanceType='button'
										type='radio'
										gap={0.8}
										options={[
											{
												label: '미확인',
												value: false,
											},
											{
												label: '확인 완료',
												value: true,
											},
										]}
									/>
								</CRInputLabel>
							)}
							name='otherCorpWorkDuplConfirmYn'
							control={methods.control}
						/>
					</S.CheckGroupContainer>
				</S.Content>
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={monitoringDetailItem?.scheduleMonitoringCompleteYn}
						buttonType='submit'
						palette={hasPermission ? 'gray' : 'primary'}
						type={hasPermission ? 'tonal' : 'filled'}>
						저장
					</CRButton.Default>
					{hasPermission && (
						<CRButton.Default onClick={handleClickComplete}>
							{monitoringDetailItem?.scheduleMonitoringCompleteYn ? '확정 취소' : '확정'}
						</CRButton.Default>
					)}
				</S.ButtonContainer>
			</S.Container>
		</FormProvider>
	);
}

export default React.memo(MonitoringSideModal);
