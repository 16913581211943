import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { boolean } from 'yup';
import * as S from './styles';

export interface ISideModalContext {
	showSideModal: (_component?: React.ReactElement) => void;
	hideSideModal: () => void;
	isShowing: boolean;
}

export const SideModalContext = React.createContext<ISideModalContext>({
	showSideModal: () => {},
	hideSideModal: () => {},
	isShowing: false,
});

export enum SideModalModeType {
	FullScreen = 1,
	FitScreen = 2,
}

function SideModalProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const location = useLocation();
	const contentRef = useRef<HTMLDivElement>(null);

	const [sideModalMode] = useState<SideModalModeType>(SideModalModeType.FitScreen);
	const [maxWidth, setMaxWidth] = useState(0);
	const [component, setComponent] = useState<React.ReactElement | null>(null);
	const [isShowing, setIsShowing] = useState<boolean>(false);

	const showSideModal = (_component?: React.ReactElement) => {
		if (_component) {
			setComponent(_component);
		}
		setIsShowing(true);
	};

	const hideSideModal = () => {
		if (!isShowing) return;

		setMaxWidth(0);
		setComponent(null);
		setIsShowing(false);
	};

	useLayoutEffect(() => {
		const result = contentRef.current?.getBoundingClientRect();
		if (result?.width) {
			setMaxWidth(result.width);
		}
	}, [component]);

	// ! URL 변화가 있으면 hideSideModal을 하도록 구현, 추후에 새로운 이벤트 리스너 강구 필요
	useEffect(() => {
		hideSideModal();
	}, [location]);

	const value = useMemo(
		() => ({ showSideModal, hideSideModal, isShowing }),
		[showSideModal, hideSideModal, isShowing],
	);

	return (
		<SideModalContext.Provider value={value}>
			<S.Container>
				<S.MainContentContainer $isShowing={isShowing} $maxWidth={maxWidth}>
					<S.MainContent>{children}</S.MainContent>
				</S.MainContentContainer>
				<S.RightContainer
					$isShowing={isShowing}
					$sideModalMode={sideModalMode}
					$maxWidth={maxWidth}>
					<S.Content ref={contentRef}>{component}</S.Content>
				</S.RightContainer>
			</S.Container>
		</SideModalContext.Provider>
	);
}

export default React.memo(SideModalProvider);
