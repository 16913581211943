import styled, { css } from 'styled-components';

export const Container = styled.div`
	height: 5rem;
	position: relative;
	display: flex;
	justify-content: center;
	background: ${(props) => props.theme.colors.gray99};
`;

export const MenuContainer = styled.ul`
	display: flex;
	width: 100%;
	max-width: 108rem;
	min-width: 80rem;
	padding: 0rem 2.4rem;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
`;

export const Divider = styled.div`
	position: absolute;
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	bottom: 0;
`;

export const TabItem = styled.li<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.body};
	display: flex;
	gap: 0.8rem;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 1rem 1.6rem;
	white-space: nowrap;
	border-top-right-radius: 0.8rem;
	border-top-left-radius: 0.8rem;
	border: 0.1rem solid transparent;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	color: ${(props) => props.theme.colors.gray60};
	z-index: 10;
	cursor: pointer;
	${(props) =>
		props.$isActive &&
		css`
			color: ${(props) => props.theme.colors.gray10};
			background: ${(props) => props.theme.colors.gray100};
			color: ${(props) => props.theme.colors.gray60};
			border: 0.1rem solid ${(props) => props.theme.colors.gray90};
			border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray100};
		`};
`;

export const Icon = styled.img`
	width: 1.6rem;
	height: 1.6rem;
	cursor: pointer;
`;
