import { css, styled } from 'styled-components';

export const CustomDay = styled.div<{ $hasError: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 0.8rem 0.8rem 0.8rem;
	${(props) =>
		props.$hasError &&
		css`
			/* cursor: pointer; */
			background: ${(props) => props.theme.colors.primary95};
		`};
`;

export const DayNumberContainer = styled.div`
	${(props) => props.theme.typography.bodyB};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.8rem;
	color: ${(props) => props.theme.colors.gray00};
`;

export const Container = styled.div<{ $height?: string }>`
	.react-datepicker {
		border: none;
		width: 100%;
		border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

		background: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__aria-live {
		display: none;
	}

	.react-datepicker__header {
		background: none;
		width: 100%;
		border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day-names {
		display: flex;
		margin: 0;
		padding-top: 0.8rem;
	}

	.react-datepicker__day-name {
		${(props) => props.theme.typography.label};
		display: flex;
		justify-content: center;
		align-items: flex-end;
		flex: 1;
		margin: 0;
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__month {
		margin: 0;
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
		overflow: auto;
		height: ${(props) => (props.$height ? `calc(${props.$height})` : 'auto')};
	}

	.react-datepicker__day--weekend {
		background: ${(props) => props.theme.colors.gray99};
	}

	.react-datepicker__week {
		display: flex;
	}

	.react-datepicker__week + .react-datepicker__week {
		border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day {
		width: 100%;
		min-height: 6.1rem;
		margin: 0;
		box-sizing: border-box;
		border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
		&:last-child {
			border-right: none;
		}
	}

	.react-datepicker__day--outside-month ${DayNumberContainer} {
		color: ${(props) => props.theme.colors.gray60};
		flex-shrink: 1;
	}

	.react-datepicker__day--keyboard-selected {
		background: none;
	}
`;
