import styled from 'styled-components';

export const Container = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 0.8rem;
`;

export const Title = styled.h4`
	flex: 1;
	${(props) => props.theme.typography.labelB};
	color: ${(props) => props.theme.colors.gray10};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const DetailLabel = styled.span``;
