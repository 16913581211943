import { useEffect, useState } from 'react';

interface IPResponse {
	asn: string;
	city: string;
	continent_code: string;
	country: string;
	country_area: number;
	country_calling_code: string;
	country_capital: string;
	country_code: string;
	country_code_iso3: string;
	country_name: string;
	country_population: number;
	country_tld: string;
	currency: string;
	currency_name: string;
	in_eu: boolean;
	ip: string;
	languages: string;
	latitude: number;
	longitude: number;
	network: string;
	org: string;
	postal: string;
	region: string;
	region_code: string;
	timezone: string;
	utc_offset: string;
	version: string;
}

const useIpAddress = () => {
	const [ip, setIp] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const getIpAddress = async () => {
		try {
			const response = await fetch('https://ipapi.co/json/');
			if (!response.ok) {
				throw new Error('Failed to fetch IP address');
			}
			const data: IPResponse = await response.json();
			setIp(data.ip);
			return data;
		} catch (err: any) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
		return undefined;
	};

	return { ip, getIpAddress, loading, error };
};

export default useIpAddress;
