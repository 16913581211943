import React, { useEffect, useRef } from 'react';

import CRTable from 'components/base/CRTable';

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { EmployeeDTO } from 'types/api/employee';
import { displayBirthDay, queryStringToObject } from 'lib';
import { useLocation, useParams } from 'react-router-dom';
import * as S from './styles';
import { STAFF_LIST_TABLE_HEADER_CONFIG } from './constant';

interface Props {
	items: EmployeeDTO[];
	searchValue: string;
	currentItem?: EmployeeDTO;
	onClickRow?: (recipient: EmployeeDTO) => void;
}

function EmployeeListTable({
	items = [],
	currentItem,
	searchValue = '',
	onClickRow,
}: Props): React.ReactElement {
	const birthDateCustomRenderer = (value: string | undefined) => displayBirthDay(value);
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const params = useParams<{ id: string }>();
	const { search } = useLocation();

	// 새로 등록된 수급자 위치로 스크롤
	useEffect(() => {
		const queryObj = queryStringToObject(search);
		if (queryObj.isNew === 'true') {
			const targetScrollIndex = items.findIndex((item) => String(item.employeeId) === params.id);
			if (targetScrollIndex !== -1) {
				scrollContainerRef.current?.scroll(0, (targetScrollIndex - 9) * 33);
			}
		}
	}, [items, scrollContainerRef, params, search]);

	const rowRenderer = (virtualRow: VirtualItem, index: number) => {
		const item = items?.[virtualRow.index];
		if (!item) return null;

		const isFocused = currentItem?.memberId === item?.memberId;

		return (
			<CRTable.Row
				isFocused={isFocused}
				item={{ index: virtualRow.index + 1, ...item }}
				searchValue={searchValue}
				searchKey={['korMemberNm', 'mobilePhoneNo', 'birthDt']}
				toolTipKey={['dutyNms']}
				key={item.employeeId}
				style={{
					width: '100%',
					display: 'flex',
					position: 'absolute',
					transform: `translateY(${virtualRow.start}px)`,
				}}
				customStyle={{
					index: {
						width: STAFF_LIST_TABLE_HEADER_CONFIG[0].width,
						flexShrink: 0,
						textAlign: 'right',
						lineHeight: '3.3rem',
					},
					korMemberNm: {
						width: STAFF_LIST_TABLE_HEADER_CONFIG[1].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					birthDt: {
						width: STAFF_LIST_TABLE_HEADER_CONFIG[2].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					workStateNm: {
						width: STAFF_LIST_TABLE_HEADER_CONFIG[3].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					dutyNms: {
						width: STAFF_LIST_TABLE_HEADER_CONFIG[4].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					managerNm: {
						width: STAFF_LIST_TABLE_HEADER_CONFIG[5].width,
						lineHeight: '3.3rem',
					},
				}}
				customRender={{
					birthDt: birthDateCustomRenderer,
				}}
				renderKeys={['index', 'korMemberNm', 'birthDt', 'workStateNm', 'dutyNms', 'managerNm']}
				onClick={onClickRow}
			/>
		);
	};

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => 33,
		overscan: 30,
	});

	return (
		<S.Container ref={scrollContainerRef}>
			<CRTable.Root>
				<CRTable.Head offset={1} heads={STAFF_LIST_TABLE_HEADER_CONFIG} />
				<CRTable.Body
					style={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						position: 'relative',
					}}>
					{rowVirtualizer?.getVirtualItems()?.map(rowRenderer)}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default React.memo(EmployeeListTable);
