import { CRStatusConfig, CRTableHeadConfig } from '../../../../types/view/base';
import { Filter } from '../../../base/CRTableHeader';

export const BILLING_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '연월',
		sortKey: '연월',
	},
	{
		width: '12rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '16rem',
		label: '수급자',
		sortKey: '수급자',
	},
	{
		width: '12rem',
		label: '서비스명',
		sortKey: '서비스명',
	},
	{
		width: '12rem',
		label: '본인부담금',
		sortKey: '본인부담금',
	},
	{
		width: '12rem',
		label: '총 청구액',
		sortKey: '총 청구액',
	},
	{
		width: '12rem',
		label: '센터',
		sortKey: '센터',
	},
	{
		width: '12rem',
		label: '담당자',
		sortKey: '담당자',
	},
	{
		width: '12rem',
		label: '진행상황',
		sortKey: '진행상황',
	},
];

export const BILLING_PROGRESS_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '청구확정',
		color: 'green',
	},
	{
		key: '환수확정',
		color: 'red',
	},
	{
		key: '환수진행',
		color: 'yellow',
	},
];

// TODO: 타입스크립트 수정하기
export const BILLING_TABLE_FILTER_CONFIG: Filter<any>[] = [
	{
		key: 'all',
		type: 'toggle',
		options: [
			{
				label: '전체',
				value: 'all',
			},
		],
	},
	{
		key: 'visitCare',
		type: 'toggle',
		options: [
			{
				label: '방문요양',
				value: 'visitCare',
			},
		],
	},
	{
		key: 'visitBath',
		type: 'toggle',
		options: [
			{
				label: '방문목욕',
				value: 'visitBath',
			},
		],
	},
	{
		key: 'visitNurse',
		type: 'toggle',
		options: [
			{
				label: '방문간호',
				value: 'visitNurse',
			},
		],
	},
];
