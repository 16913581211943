import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { CRTableHeadConfig } from 'types/view/base';

import CRCheckBox from 'components/base/Selections/CRCheckBox';
import * as S from './styles';

interface Props {
	heads: CRTableHeadConfig[];
	offset?: number;
	disableBackBlind?: boolean;
	checkIds?: string[];
	onToggleCheck?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function CRTableHead({
	heads,
	offset = 0,
	disableBackBlind = false,
	checkIds,
	onToggleCheck,
}: Props): React.ReactElement {
	const [top, setTop] = useState(0);
	const [checkAll, setCheckAll] = useState<boolean>(false);

	const ref = useRef<HTMLTableSectionElement>(null);

	useLayoutEffect(() => {
		const parentTop = !disableBackBlind
			? ref.current?.parentElement?.parentElement?.parentElement?.getBoundingClientRect().top
			: ref.current?.parentElement?.parentElement?.getBoundingClientRect().top;
		const newTop = (ref.current?.getBoundingClientRect().top ?? 0) - (parentTop ?? 0);
		setTop(newTop + offset - 1);
	}, [offset, disableBackBlind]);

	useEffect(() => {
		if (checkIds && !checkIds.length) {
			setCheckAll(false);
		}
	}, [checkIds]);

	return (
		<S.TableHeader ref={ref}>
			<tr>
				{heads.map(({ label, disabledPadding, sortKey, checkbox, ...rest }) =>
					disabledPadding ? (
						<S.TableHeaderColumn
							key={label}
							$isSortable={false}
							style={{
								...rest,
								maxWidth: rest.width,
								minWidth: rest.width,
								top,
							}}>
							{' '}
						</S.TableHeaderColumn>
					) : (
						<S.TableHeaderColumnWithPadding
							key={label}
							$isSortable={!!sortKey}
							style={{
								...rest,
								maxWidth: rest.width,
								minWidth: rest.width,
								top,
							}}>
							<S.TableHeaderColumnContentContainer $isCheckBox={!!checkbox}>
								{checkbox ? (
									<CRCheckBox
										palette='gray'
										checkBoxType='toggle'
										checked={checkAll}
										onChange={(e) => {
											setCheckAll(!checkAll);
											onToggleCheck?.(e);
										}}
									/>
								) : (
									label
								)}
							</S.TableHeaderColumnContentContainer>
						</S.TableHeaderColumnWithPadding>
					),
				)}
			</tr>
		</S.TableHeader>
	);
}

export default React.memo(CRTableHead);
