import { CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_CLAIM_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '8rem',
		label: '연월',
	},
	{
		width: '9.6rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '12rem',
		label: '청구액',
		textAlign: 'right',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];
