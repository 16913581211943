import React from 'react';
import { useLocation } from 'react-router-dom';

import RecipientConsultingDetailPage from './RecipientConsultingDetailPage';
import EmployeeConsultingDetailPage from './EmployeeConsultingDetailPage';

function ConsultingDetailPage(): React.ReactElement {
	const location = useLocation();

	if (location.pathname.includes('recipient')) {
		return <RecipientConsultingDetailPage />;
	}
	if (location.pathname.includes('employee')) {
		return <EmployeeConsultingDetailPage />;
	}
	return <div />;
}

export default ConsultingDetailPage;
