import React from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { CRText } from 'components/base/CRText';
import { displayDayOfWeekInKorean } from 'lib';
import { ProvideServiceType } from 'types/view/schedule';
import * as S from './styles';
import CRTaskBadge from '../CRTaskBadge';

dayjs.extend(weekOfYear);

export interface TaskItem {
	date: string;
	item: {
		startTime: string;
		endTime: string;
		type: string;
		description: string;
	};
}

interface Props {
	tasks: TaskItem[];
}

function CRTaskList({ tasks }: Props) {
	const acc: { [k: string]: TaskItem[] } = {};

	const groupedTasks = tasks.reduce((acc, cur) => {
		const firstWeekOfMonth = dayjs(dayjs(cur.date).format('YYYY-MM-01 00:00:00')).week();
		let weekGroup = dayjs(cur.date).week() - firstWeekOfMonth + 1;
		// 해가 넘어가는 경우
		// 12월은 31일까지이니 -3일을 하면 마지막 전 주로 갈 수 있음
		if (weekGroup < 0) {
			weekGroup = dayjs(cur.date).subtract(3, 'day').week() - firstWeekOfMonth + 2;
		}
		const groupKey = `${dayjs(cur.date).format('MM월')} ${weekGroup}주차`;
		acc[groupKey] = acc[groupKey]?.length ? [...acc[groupKey], cur] : [cur];

		return acc;
	}, acc);

	return (
		<S.ListBackground>
			{Object.entries(groupedTasks).map(([key, taskItems]) => (
				<FlexContainer direction='column' gap='0.8rem'>
					<CRText typography='label' color='gray60'>
						{key}
					</CRText>

					{taskItems.map((task) => (
						<S.Container>
							<FlexContainer>
								<CRText color='gray00' typography='bodyB'>
									{`${dayjs(task.date).format('MM월 D일 ')} ${displayDayOfWeekInKorean(
										dayjs(task.date).day(),
									)}요일 ${task.item.startTime} ~ ${task.item.endTime}`}
								</CRText>
							</FlexContainer>
							<FlexContainer gap='0.8rem'>
								<CRTaskBadge type={task.item.type} padding='0 0.8rem' />
								<CRText text={task.item.description} />
							</FlexContainer>
						</S.Container>
					))}
				</FlexContainer>
			))}
		</S.ListBackground>
	);
}

export default CRTaskList;
