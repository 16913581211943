import { CRTableHeadConfig } from 'types/view/base';

export const EMPLOYEE_CONTRACT_LEAVE_SERVICE_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '소속',
		sortKey: '소속',
	},
	{
		width: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '12rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '12rem',
		label: '담당 직원',
		sortKey: '담당 직원',
	},
	{
		width: '12rem',
		label: '급여 시작일',
		sortKey: '급여 시작일',
	},
];
