import styled from 'styled-components';

export const HistoryListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;
export const HistoryContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	gap: 1.6rem;
	padding: 1.6rem;
	width: 100%;
	border: 0.1rem solid ${(p) => p.theme.colors.gray90};
`;
