import * as yup from 'yup';
import { CheckOption } from 'components/base/Selections/type';
import dayjs from 'dayjs';
import { regex } from './regex';
import { calculateKoreanAge } from './util';

const isValidBirthDate = (isRequired = true) =>
	yup
		.string()
		.length(8, '생년월일 8자리를 입력해주세요.')
		.test(
			'valid',
			'올바른 생년월일을 입력해 주십시오.(8자리)',
			(value) => !!(value && dayjs(value, 'YYYYMMDD').format('YYYYMMDD') === value),
		)
		.when([], (_, schema) => (isRequired ? schema.required('필수 정보입니다.') : schema));

export const signIn = yup.object({
	userId: yup.string().required('사번을 입력해주세요.'),
	password: yup.string().required('비밀번호를 입력해주세요.'),
	// .matches(regex.password, '8~16자 영문 대·소문자, 숫자, 특수문자를 조합한 비밀번호를 입력해주세요.'),
});

export const signUp = yup.object({
	userId: yup
		.string()
		.required('아이디를 입력해주세요.')
		.matches(regex.id, '2~20자 영문, 숫자를 조합한 아이디를 입력해주세요.'),
	password: yup
		.string()
		.required('비밀번호를 입력해주세요.')
		.matches(
			regex.password,
			'8~16자 영문 대·소문자, 숫자, 특수문자를 조합한 비밀번호를 입력해주세요.',
		),
	passwordConfirm: yup
		.string()
		.required('비밀번호를 입력해주세요.')
		.oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.'),
	username: yup.string().required('이름을 입력해주세요.'),
	phoneNumber: yup
		.string()
		.required('휴대폰 번호를 입력해주세요.')
		.matches(regex.phoneNumber, '전화번호 형식에 맞지않습니다.'),
	workMobilePhoneNo: yup
		.string()
		.required('업무처리 휴대폰 번호를 입력해주세요.')
		.matches(regex.phoneNumber, '전화번호 형식에 맞지않습니다.'),
	center: yup.object().required('소속 센터를 선택해주세요.'),
	birthDt: isValidBirthDate(),
});

export const passwordReset = yup.object({
	password: yup
		.string()
		.required('비밀번호를 입력해주세요.')
		.matches(
			regex.password,
			'8~16자 영문 대·소문자, 숫자, 특수문자를 조합한 비밀번호를 입력해주세요.',
		),
	passwordConfirm: yup.string().oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.'),
});

export const newPassword = yup.object({
	password: yup
		.string()
		.required('비밀번호를 입력해주세요.')
		.matches(
			regex.password,
			'8~16자 영문 대·소문자, 숫자, 특수문자를 조합한 비밀번호를 입력해주세요.',
		),
	passwordConfirm: yup
		.string()
		.required('비밀번호 확인을 입력해주세요.')
		.matches(
			regex.password,
			'8~16자 영문 대·소문자, 숫자, 특수문자를 조합한 비밀번호를 입력해주세요.',
		)
		.oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.'),
});

export const approval = yup.object({
	type: yup.object().required('종류를 선택해주세요.'),
	title: yup.string().required('제목 사번을 입력해주세요.'),
	content: yup.string().required('내용을 입력해주세요.'),
	attachment: yup.array().nullable(),
});

export const EmployeeEnroll = yup.object({
	name: yup
		.string()
		.required('필수 정보입니다.')
		.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.')
		.min(2, '2글자 이상 입력해야 합니다.')
		.max(20, '20자까지 입력할 수 있습니다.'),
	rsdnNo: yup
		.string()
		.required('필수 정보입니다.')
		.matches(regex.number, '숫자만 입력할 수 있습니다.')
		.min(13, '주민등록번호를 정확히 입력해야합니다.')
		.max(13, '13자까지 입력할 수 있습니다.'),
});

export const RecipientEnroll = yup.object({
	username: yup
		.string()
		.required('필수 정보입니다.')
		.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.')
		.min(2, '2글자 이상 입력해야 합니다.')
		.max(20, '20자까지 입력할 수 있습니다.'),
	residenceNumber: yup
		.string()
		.required('필수 정보입니다.')
		.matches(regex.number, '숫자만 입력할 수 있습니다.')
		.min(13, '주민등록번호를 정확히 입력해야합니다.')
		.max(13, '13자까지 입력할 수 있습니다.'),
});

export const EmployeeBasicInfo = yup.object({
	korMemberNm: yup
		.string()
		.required('이름을 입력해주세요.')
		.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.')
		.min(2, '2글자 이상 입력해야 합니다.')
		.max(20, '20자까지 입력할 수 있습니다.'),
	birthDt: isValidBirthDate(),
	mobilePhoneNo: yup.string().required('전화번호를 입력해주세요.'),

	twoPhoneYn: yup.array().of(
		yup.object().shape({
			label: yup.string().required(),
			value: yup.boolean().required(),
		}),
	),
	subMobilePhoneNo: yup
		.string()
		.when(['twoPhoneYn'], (twoPhoneYn, schema) =>
			twoPhoneYn?.[0]?.[0]?.value ? schema.required('투폰 전화번호를 입력해주세요.') : schema,
		),
	tagCallRequestYn: yup.array().of(
		yup.object().shape({
			label: yup.string().required(),
			value: yup.boolean().required(),
		}),
	),
	tagCallDayCds: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.when(['tagCallRequestYn'], (tagCallRequestYn, schema) =>
			tagCallRequestYn?.[0]?.[0]?.value
				? schema.test(
						'min-files',
						'태그콜 요일을 선택해주세요.',
						(value) => value && value.length >= 1,
				  )
				: schema,
		),
	dutyCds: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.test('min-select', '직무를 선택해주세요.', (value) => value && value.length >= 1),
	docReceiveMethodCd: yup.string(),
	joinDate: yup
		.string()
		.test('length-8', '입사일 8자리를 입력해주세요.', (value) => !value || value.length === 8)
		.test(
			'valid',
			'올바른 날짜를 입력해주세요.',
			(value) =>
				!value ||
				(dayjs(value, 'YYYYMMDD', true).isValid() &&
					dayjs(value, 'YYYYMMDD').format('YYYYMMDD') === value),
		),
	monthPerWorkHourCnt: yup.string(),
	visitCareOffHourCnt: yup
		.string()
		.test('valid', '분 단위로 입력해 주십시오.(30분 이상 입력 가능)', (value) => {
			const target = Number(value);
			if (value && !Number.isNaN(target)) {
				return !!(target === 0 || target >= 30);
			}
			return true;
		}),
	visitBathOffHourCnt: yup
		.string()
		.test('valid', '분 단위로 입력해 주십시오.(30분 이상 입력 가능)', (value) => {
			const target = Number(value);
			if (value && !Number.isNaN(target)) {
				return !!(target === 0 || target >= 30);
			}
			return true;
		}),
	visitNursingOffHourCnt: yup
		.string()
		.test('valid', '분 단위로 입력해 주십시오.(30분 이상 입력 가능)', (value) => {
			const target = Number(value);
			if (value && !Number.isNaN(target)) {
				return !!(target === 0 || target >= 30);
			}
			return true;
		}),
});

export const RecipientBasicInfo = yup.object({
	name: yup
		.string()
		.required('수급자 이름은 필수 정보입니다.')
		.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.')
		.min(2, '2글자 이상 입력해야 합니다.')
		.max(20, '20자까지 입력할 수 있습니다.'),
	birthday: isValidBirthDate(),
});

export const RecipientGuardianInfo = yup.object({
	items: yup.array().of(
		yup.object().shape({
			name: yup
				.string()
				.required('보호자 이름을 입력해주세요.')
				.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.'),
			phoneNumber: yup
				.string()
				.required('전화번호를 입력해주세요.')
				.matches(regex.phoneNumber, '전화번호 형식에 맞지않습니다.'),
			relationship: yup.string().required('관계를 선택해주세요.'),
			birthday: yup.string().when('isMain', ([isMain], schema) => {
				if (isMain === true) {
					return schema
						.required('주 보호자는 생년월일 입력이 필수입니다.')
						.length(8, '생년월일 8자리를 입력해주세요.')
						.test(
							'valid',
							'올바른 생년월일을 입력해주세요.',
							(value) => !!(value && dayjs(value, 'YYYYMMDD').format('YYYYMMDD') === value),
						);
				}
				return schema
					.nullable()
					.notRequired()
					.test('length', '생년월일 8자리를 입력해주세요.', (value) => !value || value.length === 8)
					.test(
						'valid',
						'올바른 생년월일을 입력해주세요.',
						(value) => !value || dayjs(value, 'YYYYMMDD').format('YYYYMMDD') === value,
					);
			}),
			address: yup.object().when('isMain', ([isMain], schema) => {
				if (isMain === true) {
					return schema.shape({
						basAddr: yup.string().required('주 보호자는 주소 입력이 필수입니다.'),
					});
				}
				return schema;
			}),
			scheduleReceiveYn: yup.boolean(),
			longTermPayFeeCertiReceiveYn: yup.boolean(),
			docReceiveMethodCd: yup.string(),
		}),
	),
});

export const RecipientScheduleAutomation = yup.object({
	items: yup.array().of(
		yup.object().shape({
			totalDayYn: yup.array().of(yup.object()),
			serviceKindCd: yup.string().required('급여종류를 선택해주세요.'),
			automationFeeCd: yup
				.string()
				.when(['serviceKindCd'], ([serviceKindCd], schema) =>
					serviceKindCd === 'CMN083.20' ? schema.required('수가를 선택해주세요.') : schema,
				),
			employeeId1: yup.object().required('직원을 선택해주세요.'),
			employeeId2: yup
				.object()
				.when(['serviceKindCd'], ([serviceKindCd], schema) =>
					serviceKindCd === 'CMN083.20' ? schema.required('직원2를 선택해주세요.') : schema,
				),
			days: yup
				.array()
				.of(yup.string())
				.test('min-select', '이용 요일을 선택해주세요.', (value) => value && value.length >= 1)
				.required('이용 요일을 선택해주세요.'),
			time: yup
				.object()
				.shape({
					serviceStartTime: yup
						.string()
						.length(4, '시작시간을 확인해주세요.')
						.required('시작시간을 입력해주세요.'),
					serviceEndTime: yup
						.string()
						.length(4, '종료시간을 확인해주세요.')
						.required('종료시간을 입력해주세요.'),
				})
				.required('zz'),
			monthMethod: yup
				.string()
				.when(['totalDayYn'], ([totalDayYn], schema) =>
					!totalDayYn?.[0]?.value ? schema.required('등록 기준을 선택해주세요.') : schema,
				),
			monthUseCnt: yup
				.string()
				.when(['monthMethod', 'totalDayYn'], ([monthMethod, totalDayYn], schema) =>
					monthMethod === 'count' && !totalDayYn?.[0]?.value
						? schema.required('월 최대 이용횟수를 입력해주세요.')
						: schema,
				),
			monthAmt: yup
				.string()
				.when(['monthMethod', 'totalDayYn'], ([monthMethod, totalDayYn], schema) =>
					monthMethod === 'amount' && !totalDayYn?.[0]?.value
						? schema.required('최대 월금액을 입력해주세요.')
						: schema,
				),
			managerId: yup
				.number()
				.when(['serviceKindCd'], ([serviceKindCd], schema) =>
					serviceKindCd === 'CMN083.12' ? schema.required('관리자를 선택해주세요.') : schema,
				),
		}),
	),
});

export const RecipientOtherServiceFormResolver = yup.object({
	items: yup.array().of(
		yup.object().shape({
			centerName: yup.string().required('센터명을 입력해주세요.'),
			phoneNumber: yup.string().required('전화번호를 입력해주세요.'),
			otherService: yup.string().required('타 급여를 선택해주세요.'),
			times: yup
				.array()
				.of(
					yup.object().shape({
						days: yup.array().of(yup.object()).min(1, '이용일을 입력해주세요'),
						otherServiceUseStartTime: yup
							.string()
							.length(4, '올바른 시작시간을 입력해주세요.')
							.required('시작시간을 입력해주세요'),
						otherServiceUseEndTime: yup
							.string()
							.length(4, '올바른 종료시간을 입력해주세요.')
							.required('종료시간을 입력해주세요'),
					}),
				)
				.min(1, '이용시간을 입력해주세요.'),
			memo: yup.string().max(100, '100자 이하로 입력해주세요.'),
		}),
	),
});

export const RecipientOwnExpenseFormResolver = yup.object({
	payMethod: yup.object().shape({
		label: yup.string().optional(),
		value: yup.string().required('납부방식을 선택해주세요.'),
	}),
	bank: yup.string().when('payMethod', ([payMethod], schema) => {
		if (payMethod?.value === 'CMN041.10') {
			return schema.required('은행을 선택해주세요');
		}
		return schema;
	}),
	account: yup.string().when('payMethod', ([payMethod], schema) => {
		if (payMethod?.value === 'CMN041.10') {
			return schema.required('계좌번호를 입력해주세요');
		}
		return schema;
	}),
	accountOwner: yup.string().when('payMethod', ([payMethod], schema) => {
		if (payMethod?.value === 'CMN041.10') {
			return schema.required('계좌주를 입력해주세요');
		}
		return schema;
	}),
	payDeductEmployeeId: yup.number().when('payMethod', ([payMethod], schema) => {
		if (payMethod?.value === 'CMN041.30') {
			return schema.required('공제 대상자를 선택해주세요');
		}
		return schema;
	}),
	name: yup.string().when('receipt', ([receipt], schema) => {
		if (receipt?.value === true) {
			return schema
				.required('현금영수증 대상 이름을 입력해주세요')
				.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.');
		}
		return schema;
	}),
	receiptNumber: yup.string().when('receipt', ([receipt], schema) => {
		if (receipt?.value === true) {
			return schema.required('휴대폰 혹은 사업자등록번호를 입력해주세요.');
		}
		return schema;
	}),
});

export const EmployeeOtherCorpWork = yup.object().shape({
	items: yup.array().of(
		yup.object().shape({
			personalBusinessOwnerYn: yup
				.array()
				.of(
					yup.object().shape({
						label: yup.string().required(),
						value: yup.boolean().required(),
					}),
				)
				.test('min-files', '유형을 선택해주세요.', (value) => value && value.length >= 1),
			remark: yup.string().max(100, '100자 이하로 입력해주세요.'),
			otherCorpNm: yup.string().required('상호명을 입력해주세요.'),
			otherCorpPhoneNo: yup.string(),
			times: yup
				.array()
				.of(
					yup.object().shape({
						days: yup.array().of(yup.object()).min(1, '근무일을 입력해주세요'),
						otherCorpWorkStartTime: yup
							.string()
							.length(4, '올바른 시작시간을 입력해주세요.')
							.required('시작시간을 입력해주세요.'),
						otherCorpWorkEndTime: yup
							.string()
							.length(4, '올바른 종료시간을 입력해주세요.')
							.required('종료시간을 입력해주세요'),
					}),
				)
				.min(1, '근무일시를 입력해주세요.')
				.required('근무일시를 입력해주세요.'),
		}),
	),
});

export const EmployeePayInfo = yup.object({
	payType: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.test('min-select', '수령방식을 선택해주세요.', (value) => value && value.length >= 1),
	// 	.required(),
	accountNo: yup.string().when('payType', (payType, schema) =>
		payType && payType?.[0]?.[0]?.value === 'CMN133.10' // 기타 일때 기타 항목란 필수
			? schema.required('계좌번호를 입력해주세요.')
			: schema,
	),
	bank: yup.object().when('payType', (payType, schema) =>
		payType && payType?.[0]?.[0]?.value === 'CMN133.10' // 기타 일때 기타 항목란 필수
			? schema.required('은행을 선택해주세요.')
			: schema,
	),
	ownerName: yup.string().when('payType', (payType, schema) =>
		payType && payType?.[0]?.[0]?.value === 'CMN133.10' // 기타 일때 기타 항목란 필수
			? schema.required('계좌주를 입력하세요.')
			: schema,
	),
	accountValid: yup.boolean().when('payType', (payType, schema) =>
		payType && payType[0]?.[0]?.value === 'CMN133.10' // 기타 일때 기타 항목란 필수
			? schema.test('min-files', '계좌정보를 조회해주세요.', (value) => value === true)
			: schema,
	),
});

export const EmployeeHealthCheck = yup.object({
	healthCheckDate: yup.date().required('검진일을 입력해주세요.'),
	healthCheckKind: yup.array().required('검진종류를 선택해주세요.'),
	healthCheckInstitutionNm: yup.string().required('검진기관을 입력해주세요.'),
});

export const EmployeeCareer = yup.object({
	workDate: yup.object().shape({
		start: yup.string().required('시작일을 입력해주세요'),
	}),
	companyNm: yup.string().required('소속을 입력해주세요.'),
});

export const EmployeeLicense = yup.object({
	licenseTypeCd: yup.string().required('종류를 선택해주세요'),
	licenseNo: yup.string().required('자격증 번호를 입력해주세요.'),
	licenseNm: yup.string().when('licenseTypeCd', (licenseTypeCd, schema) =>
		licenseTypeCd && licenseTypeCd[0] === 'CMN067.99' // 기타 일때 입력 항목란 필수
			? schema.required('자격증 종류를 입력해주세요.')
			: schema,
	),
	licenseIssueDate: yup.date().required('발급일을 선택해주세요'),
	licenseIssueInstitutionNm: yup.string().required('발급기관을 입력해주세요.'),
});

export const EmployeeEducation = yup.object({
	eduTypeCd: yup.string().required('종류를 입력해주세요.'),
	eduInstitutionNm: yup.string().required('교육 기관을 입력해주세요.'),
	eduCompleteDate: yup.date().required('교율 일정을 선택해주세요'),
});

export const employeeManagerHistory = yup.object({
	changeDate: yup.date().required('변경일을 선택해주세요.'),
	center: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required('소속을 선택해주세요.'),
	manager: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required('사회복지사를 선택해주세요.'),

	managerChangeDesc: yup.string().required('변경사유를 입력해주세요.'),
});

export const EmployeeScheduleDetail = yup.object().shape({
	items: yup.array().of(
		yup.object().shape({
			service: yup.object().required(),
			time: yup
				.object()
				.shape({
					startTime: yup.string(),
					endTime: yup.string(),
				})
				.required('일정 시간을 확인해주세요.'),
		}),
	),
});

// 직원 서류 관리 등록
export const CreateEDoc = yup.object({
	paperType: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	paperTypeEtc: yup.string().when('paperType', (paperType, schema) =>
		paperType && paperType?.[0]?.value === 'CMN119.9999' // 기타 일때 기타 항목란 필수
			? schema.required('paperTypeEtc is required when paperType?.value is CMN119.9999')
			: schema,
	),
	paperFile: yup
		.array()
		.of(
			yup.object().shape({
				fileDeleteYn: yup.boolean().required(),
				filePathNm: yup.string().required(),
				fileSize: yup.number().required(),
				originFileNm: yup.string().required(),
				transFileNm: yup.string().required(),
			}),
		)
		.test('min-files', 'At least one paper file is required', (value) => value && value.length >= 1)
		.required(),
	vniMedicalInstitutionNm: yup.string().when('paperType', (paperType, schema) =>
		paperType && paperType?.[0]?.value === 'CMN119.0081' // 방문간호지시서 일때 필수
			? schema.required('paperTypeEtc is required when paperType?.value is CMN119.0081')
			: schema,
	),
	vniIssueDt: yup.string().when('paperType', (paperType, schema) =>
		paperType && paperType?.[0]?.value === 'CMN119.0081' // 방문간호지시서 일때 필수
			? schema.required('paperTypeEtc is required when paperType?.value is CMN119.0081')
			: schema,
	),
	vniValidPeriodDayCnt: yup.string().when('paperType', (paperType, schema) =>
		paperType && paperType?.[0]?.value === 'CMN119.0081' // 방문간호지시서 일때 필수
			? schema.required('paperTypeEtc is required when paperType?.value is CMN119.0081')
			: schema,
	),
	vniDoctorLicenseNo: yup.string().when('paperType', (paperType, schema) =>
		paperType && paperType?.[0]?.value === 'CMN119.0081' // 방문간호지시서 일때 필수
			? schema.required('paperTypeEtc is required when paperType?.value is CMN119.0081')
			: schema,
	),
	vniVisitCntStandardCd: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.when('paperType', (paperType, schema) =>
			paperType && paperType?.[0]?.value === 'CMN119.0081' // 방문간호지시서 일때 필수
				? schema.required('paperTypeEtc is required when paperType?.value is CMN119.0081')
				: schema,
		),
	vniVisitCnt: yup.string().when('paperType', (paperType, schema) =>
		paperType && paperType?.[0]?.value === 'CMN119.0081' // 방문간호지시서 일때 필수
			? schema.required('paperTypeEtc is required when paperType?.value is CMN119.0081')
			: schema,
	),
});

export const admissionUseRequest = yup.object({
	type: yup
		.object()
		.shape({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	requestDate: yup.date().required(),
	birthDt: isValidBirthDate(),
	startEndDate: yup.object().required(),
	service: yup.array().of(
		yup.object().shape({
			serviceType: yup
				.object()
				.shape({
					label: yup.string().required(),
					value: yup.string().required(),
				})
				.required(),
			serviceDesc: yup.string().required(),
			serviceFee: yup.string().required(),
			serviceNumberOfMonth: yup.string().required(),
		}),
	),
});

export const contractCreateValidator = yup.object({
	serviceType: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.test('min-select', '필수 정보입니다.', (value) => value && value?.length >= 1),
	name: yup
		.string()
		.required('필수 정보입니다.')
		.matches(regex.name, '한글이나 영문만 입력할 수 있습니다.')
		.min(2, '2글자 이상 입력해야 합니다.')
		.max(20, '20자까지 입력할 수 있습니다.'),
	rsdnNo: yup
		.string()
		.required('필수 정보입니다.')
		.matches(regex.number, '숫자만 입력할 수 있습니다.')
		.min(13, '주민등록번호를 정확히 입력해야합니다.')
		.max(13, '13자까지 입력할 수 있습니다.'),
});

// 태그 상세
export const TagDetail = yup.object({
	errorYN: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.length(1)
		.required(),
	scheduleTime: yup
		.object()
		.shape({
			startTime: yup.string().length(5).required(),
			endTime: yup.string().length(5).required(),
		})
		.required(),
	rfidTime: yup.lazy((value) => {
		if (value?.startTime || value?.endTime) {
			return yup.object().shape({
				startTime: yup.string().length(5).required(),
				endTime: yup.string().length(5).required(),
			});
		}
		return yup.object();
	}),
	// .required(),
});

export const EmployeeResign = yup.object({
	insuranceLossRequestYn: yup
		.boolean()
		.test('isTrue', '필수 정보입니다.', (value) => value === true)
		.required(),
	wardResignDischargeRequestYn: yup
		.boolean()
		.test('isTrue', '필수 정보입니다.', (value) => value === true)
		.required(),
	incompletePaperConfirmYn: yup
		.boolean()
		.test('isTrue', '필수 정보입니다.', (value) => value === true)
		.required(),
	calculateConfirmYn: yup
		.boolean()
		.test('isTrue', '필수 정보입니다.', (value) => value === true)
		.required(),
});

export const RecipientResign = yup.object({
	retirementDate: yup.date().required(),
	resignType: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	resignTypeEtc: yup.string().when('resignType', (resignType, schema) =>
		resignType && resignType?.[0]?.value === 'CMN109.50' // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	serviceChangeDesc: yup.string().required(),
	pcorpSalaryContractCancelYn: yup
		.boolean()
		.test('isTrue', '필수 정보입니다.', (value) => value === true)
		.required(),
});

export const IssueEmploymentCertificationDoc = yup.object({
	issueReason: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.length(1)
		.required(),
	issueReasonEtcDescription: yup.string().when('issueReason', (issueReason, schema) =>
		issueReason && issueReason?.[0]?.[0]?.value === 'CMN142.30' // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	workStartDate: yup.date().required(),
});

export const IssueCareerDoc = yup.object({
	issueReason: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.length(1)
		.required(),
	issueReasonEtcDescription: yup.string().when('issueReason', (issueReason, schema) =>
		issueReason && issueReason?.[0]?.[0]?.value === 'CMN142.30' // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
});

export const IssueResignDoc = yup.object({
	resignDate: yup.date().required(),
	workStartDate: yup.date().required(),
	resignType: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	resignDetailType: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	reason: yup.string().required(),
});

export const IssueForeignConfirmDoc = yup.object({
	joinType: yup.array().of(yup.object()).length(1).required(),
	engNm: yup.string().required(),
	korNm: yup.string().required(),
	gender: yup.array().of(yup.object()).length(1).required(),
	rsdnNo: yup.string().length(13).required(),
	birthDt: isValidBirthDate(),
	countryNm: yup.string().required(),
	stayQualificationNm: yup.string().required(),
	date: yup.date().required(),
});

export const IssueHealthInsuranceAcqCancelDoc = yup.object({
	healthInsuranceAcqDate: yup.date().required(),
	insCancelCd: yup.array().of(yup.object()).length(1).required(),
	requestDate: yup.date().required(),
});

export const IssueHealthInsuranceLossCancelDoc = yup.object({
	healthInsuranceLossDate: yup.date().required(),
	requestDate: yup.date().required(),
});

export const IssueForeignRequestConfirmDoc = yup.object({
	joinType: yup.array().of(yup.object()).length(1).required(),
	engNm: yup.string().required(),
	korNm: yup.string().required(),
	gender: yup.array().of(yup.object()).length(1).required(),
	rsdnNo: yup.string().length(13).required(),
	birthDt: isValidBirthDate(),
	countryNm: yup.string().required(),
	stayQualificationNm: yup.string().required(),
	joinDate: yup.date().required(),
	dutyNm: yup.object().required(),
	weekPerWorkTime: yup.string().required(),
	monthlyAvgSalaryAmt: yup.string().required(),
	paySumAmtCurrentYear: yup.string().when(['joinType'], ([joinType], schema) =>
		joinType && joinType?.[0]?.value === false // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	paySumAmtLastYear: yup.string().when(['joinType'], ([joinType], schema) =>
		joinType && joinType?.[0]?.value === false // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	date: yup.date().required(),
});

export const IssueOtherBankBook = yup.object({
	bank: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	accountNumber: yup.string().required(),
	name: yup.string().required(),
	birthDt: isValidBirthDate(),
	phoneNumber: yup.string().required(),
	relation: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	relationEtc: yup.string().when('relation', (relation, schema) =>
		relation && relation?.[0]?.value === 'CMN038.99' // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	reason: yup.string().required(),
});

export const IssuePledge = yup.object({
	recipient: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	pledgeContent: yup.string().required(),
});

export const InsuranceExcelDownload = yup.object({
	state: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	insuranceType: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
		)
		.length(1)
		.required(),
});

// 4대보험 신고
export const InsuranceRequest = yup.object({
	insuranceRequestType: yup.object().shape({
		label: yup.string().required(),
		value: yup.string().required(),
	}),
	requestDesc: yup.string().required(),
	attachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	),
});

// 4대보험 신고 취득
export const InsuranceAcquirement = yup.object({
	birthDate: yup.string(),
	insuranceType: yup.array().min(1).required(),
	pensionInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '국민연금') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	pensionInsuranceRequestDate: yup
		.string()
		.test('over60', '만 60세 이상은 국민연금을 취득할 수 없습니다.', function (value) {
			const { birthDate, insuranceType } = this.parent;
			const isPensionInsurance = !!insuranceType?.find(
				(item: CheckOption) => item?.value === '국민연금',
			);
			if (isPensionInsurance) {
				const koreanAge = calculateKoreanAge(birthDate, value);
				return !(koreanAge >= 60);
			}
			return true;
		})
		.when('insuranceType', (insuranceType, schema) =>
			insuranceType?.[0]?.find((item: CheckOption) => item?.value === '국민연금') // 기타 일때 기타 항목란 필수
				? schema.required()
				: schema,
		),
	healthInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '건강보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	healthInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '건강보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	employInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '고용보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	employInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '고용보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	accidentInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '산재보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	accidentInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '산재보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	weekPerWorkHourCnt: yup.number().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => ['고용보험', '산재보험'].includes(item?.value)) // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	dependents: yup.array().of(
		yup.object().shape({
			dependentNm: yup.string().required(),
			dependentRsdnNo: yup.string().required(),
			dependentRelCd: yup.object().shape({
				label: yup.string().required(),
				value: yup.string().required(),
			}),
			dependentRegistDt: yup.string().required(),
			isForeign: yup
				.array()
				.of(
					yup.object().shape({
						label: yup.string().required(),
						value: yup.boolean().required(),
					}),
				)
				.length(1)
				.required(),
			dependentCountryCd: yup.object().when('isForeign', (isForeign, schema) =>
				isForeign?.[0]?.[0]?.value // 기타 일때 기타 항목란 필수
					? schema.required()
					: schema,
			),
			dependentStayStartDt: yup.string().when('isForeign', (isForeign, schema) =>
				isForeign?.[0]?.[0]?.value // 기타 일때 기타 항목란 필수
					? schema.required()
					: schema,
			),
			dependentStayEndDt: yup.string().when('isForeign', (isForeign, schema) =>
				isForeign?.[0]?.[0]?.value // 기타 일때 기타 항목란 필수
					? schema.required()
					: schema,
			),
		}),
	),
});

export const InsuranceLoss = yup.object({
	insuranceType: yup.array().min(1).required(),
	pensionInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '국민연금') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	accidentInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '산재보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	healthInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '건강보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	employInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '고용보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	lossDescCd: yup
		.object({
			label: yup.string(),
			value: yup.string(),
			data: yup.mixed().nullable(),
		})
		.required(),
	lossDetailDescCd: yup
		.object()
		.when('lossDescCd', (lossDescCd, schema) =>
			lossDescCd?.[0]?.value !== 'CMN114.30' ? schema.required() : schema,
		),
	attachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	),
	thisSalaryInfo: yup.object().shape({
		totalWorkHourCnt: yup.number().required(),
		paySumAmt: yup.number().required(),
	}),
	currentMonth: yup
		.array()
		.of(
			yup.object().shape({
				hour: yup
					.string()
					.when(['salaryFee.data.generalCareYn'], ([generalCareYn], schema) =>
						generalCareYn ? schema.required('Hour is required when generalCareYn is true') : schema,
					),
				count: yup.string().required(),
				salaryFee: yup.object().required(),
				serviceType: yup.object().required(),
			}),
		)
		.when(
			[
				'thisSalaryInfo',
				'pensionInsuranceRequestDate',
				'accidentInsuranceRequestDate',
				'healthInsuranceRequestDate',
				'employInsuranceRequestDate',
			],
			(
				[
					thisSalaryInfo,
					employInsuranceRequestDate,
					healthInsuranceRequestDate,
					pensionInsuranceRequestDate,
					accidentInsuranceRequestDate,
				],
				schema,
			) => {
				const dates = [
					employInsuranceRequestDate,
					healthInsuranceRequestDate,
					pensionInsuranceRequestDate,
					accidentInsuranceRequestDate,
				]
					.filter(Boolean)
					.map((item) => dayjs(item).format('YYYYMMDD'));

				const firstItem = dates?.[0];
				const isAllRequestMonthEqual = dates.every((item) => item === firstItem);
				const isAllValidTargetDt = dates.every((date) => dayjs(date).isAfter('20240101'));
				// 2024년 1월 2일 전은 상실월 급여정보 입력하지 않아도 되게 처리
				return thisSalaryInfo?.paySumAmt === 0 &&
					thisSalaryInfo?.totalWorkHourCnt === 0 &&
					(!isAllRequestMonthEqual || isAllValidTargetDt)
					? schema
							.test(
								'min-length',
								'At least one paper file is required',
								(value) => value && value?.length >= 1,
							)
							.required()
					: schema;
			},
		),
	requestDesc: yup.string(),
	issueCareerChangeCert: yup.array(),
	attachFileRequestDesc: yup
		.string()
		.when('issueCareerChangeCert', (issueCareerChangeCert, schema) =>
			issueCareerChangeCert?.[0]?.find((item: CheckOption) => item?.value === true) // 이직확인서 요청 여부 true일때 필수
				? schema.required()
				: schema,
		), // 이직 확인서 요청사항
	careerChangeAttachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	), // 이직 확인서 첨부 파일
});

export const InsuranceCancel = yup.object({
	insuranceType: yup.array().min(1).required(),
	requestDesc: yup.string(),
	attachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	),
});

export const InsuranceChangeSalary = yup.object({
	insuranceType: yup.array().min(1).required(),
	pensionInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '국민연금') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	accidentInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '산재보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	healthInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '건강보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	employInsuranceRequestAmt: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '고용보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	salaryChangeYm: yup.date().required(),
	salaryChangeDescCd: yup.object().required(),
	requestDesc: yup.string(),
	attachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	),
});

export const InsuranceDateChange = yup.object({
	insuranceType: yup.array().min(1).required(),
	pensionInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '국민연금') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	accidentInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '산재보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	healthInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '건강보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	employInsuranceRequestDate: yup.string().when('insuranceType', (insuranceType, schema) =>
		insuranceType?.[0]?.find((item: CheckOption) => item?.value === '고용보험') // 기타 일때 기타 항목란 필수
			? schema.required()
			: schema,
	),
	requestDesc: yup.string(),
	attachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	),
});

export const InsuranceBase = yup.object({
	requestDesc: yup.string().required(),
	attachFile: yup.array().of(
		yup.object().shape({
			fileDeleteYn: yup.boolean().required(),
			filePathNm: yup.string().required(),
			fileSize: yup.number().required(),
			originFileNm: yup.string().required(),
			transFileNm: yup.string().required(),
		}),
	),
});

// 4대보험 상세 수정
export const InsuranceDetailBase = yup.object({
	insuranceRequestDetails: yup.object(),
	responseDesc: yup.string(),
	insuranceRequestStateCd: yup.object().required(),
	isEachSelect: yup.boolean().required(),
	employInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.employInsuranceRequestAmt ||
					insuranceRequestDetails?.employInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	accidentInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.accidentInsuranceRequestAmt ||
					insuranceRequestDetails?.accidentInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	healthInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.healthInsuranceRequestAmt ||
					insuranceRequestDetails?.healthInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	pensionInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.pensionInsuranceRequestAmt ||
					insuranceRequestDetails?.pensionInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	cancelDesc: yup
		.string()
		.when(
			[
				'insuranceRequestStateCd',
				'employInsuranceApproveYn',
				'accidentInsuranceApproveYn',
				'pensionInsuranceApproveYn',
				'healthInsuranceApproveYn',
			],
			(
				[
					insuranceRequestStateCd,
					employInsuranceApproveYn,
					accidentInsuranceApproveYn,
					pensionInsuranceApproveYn,
					healthInsuranceApproveYn,
				],
				schema,
			) => {
				const isDenyRequest =
					insuranceRequestStateCd?.value === 'CMN161.30' ||
					insuranceRequestStateCd?.value === 'CMN161.50'; // 반려나 철회이면 필수

				const isExistDenyInsurance = [
					employInsuranceApproveYn?.[0]?.value,
					accidentInsuranceApproveYn?.[0]?.value,
					pensionInsuranceApproveYn?.[0]?.value,
					healthInsuranceApproveYn?.[0]?.value,
				]
					.filter((item) => item !== undefined)
					.some((item) => !item);
				return isDenyRequest || isExistDenyInsurance ? schema.required() : schema;
			},
		),
});

export const InsuranceDetailAttachResponse = yup.object({
	insuranceRequestDetails: yup.object(),
	attachFileResponseDesc: yup.string(),
	insuranceRequestStateCd: yup.object().required(),
	isEachSelect: yup.boolean().required(),
	employInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.employInsuranceRequestAmt ||
					insuranceRequestDetails?.employInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	accidentInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.accidentInsuranceRequestAmt ||
					insuranceRequestDetails?.accidentInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	healthInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.healthInsuranceRequestAmt ||
					insuranceRequestDetails?.healthInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	pensionInsuranceApproveYn: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required(),
				value: yup.boolean().required(),
			}),
		)
		.when(
			['insuranceRequestDetails', 'isEachSelect'],
			([insuranceRequestDetails, isEachSelect], schema) =>
				isEachSelect &&
				(insuranceRequestDetails?.pensionInsuranceRequestAmt ||
					insuranceRequestDetails?.pensionInsuranceRequestDate)
					? schema.required()
					: schema,
		),
	cancelDesc: yup
		.string()
		.when(
			[
				'insuranceRequestStateCd',
				'employInsuranceApproveYn',
				'accidentInsuranceApproveYn',
				'pensionInsuranceApproveYn',
				'healthInsuranceApproveYn',
			],
			(
				[
					insuranceRequestStateCd,
					employInsuranceApproveYn,
					accidentInsuranceApproveYn,
					pensionInsuranceApproveYn,
					healthInsuranceApproveYn,
				],
				schema,
			) => {
				const isDenyRequest =
					insuranceRequestStateCd?.value === 'CMN161.30' ||
					insuranceRequestStateCd?.value === 'CMN161.50'; // 반려나 철회이면 필수

				const isExistDenyInsurance = [
					employInsuranceApproveYn?.[0]?.value,
					accidentInsuranceApproveYn?.[0]?.value,
					pensionInsuranceApproveYn?.[0]?.value,
					healthInsuranceApproveYn?.[0]?.value,
				]
					.filter((item) => item !== undefined)
					.some((item) => !item);
				return isDenyRequest || isExistDenyInsurance ? schema.required() : schema;
			},
		),
});

export const InsuranceCancel2 = yup.object({
	cancelDesc: yup.string().required(),
});

export const AddOwnExpenseFormValidator = yup.object({
	changeDate: yup.date().required('변경일자를 선택해주세요'),
	ownExpense: yup.string().required('본인부담율을 선택해주세요'),
	burdenRateChangeDesc: yup.string().required('본인부담율 변경 사유를 입력해주세요'),
	center: yup.object().required('소속 센터를 선택해주세요'),
});

export const UpdateOwnExpenseFormValidator = yup.object({
	changeDate: yup.date().required('변경일자를 선택해주세요'),
	ownExpense: yup.string().required('본인부담율을 선택해주세요'),
	burdenRateChangeDesc: yup.string().required('본인부담율 변경 사유를 입력해주세요'),
	center: yup.object().required('소속 센터를 선택해주세요'),
});

export const Termination = yup.object({
	serviceEndDate: yup.date().required(),
	contractEndDescCd: yup.object().required(),
	serviceEndDesc: yup.string().required(),
});

export const SalaryDetailValidator = yup.object({
	salaryDetail: yup.object({
		salaryMemo: yup.string().optional().max(100, '임금 메모는 100자까지 입력 가능합니다.'),
	}),
});

export const MyCenterDocument = yup.object({
	centerFileNm: yup.string().required('종류를 선택해주세요'),
	centerFile: yup
		.array()
		.of(
			yup.object().shape({
				fileDeleteYn: yup.boolean().required(),
				filePathNm: yup.string().required(),
				fileSize: yup.number().required(),
				originFileNm: yup.string().required(),
				transFileNm: yup.string().required(),
			}),
		)
		.test('min-files', 'At least one paper file is required', (value) => value && value.length >= 1)
		.required()
		.test('min-files', 'At least one paper file is required', (value) => value && value.length >= 1)
		.test(
			'at-least-one-delete-true',
			'At least one file must be marked for deletion',
			(value) => value && value.some((file) => !file.fileDeleteYn === true),
		),
});

export const MyCenterAddAccount = yup.object({
	centerAccountBankCd: yup.object().required(),
	centerAccountAliasNm: yup.string().required(),
	centerAccountNo: yup.string().required(),
	centerAccountPassword: yup.string().required(),
	centerAccountBizNo: yup.string().required(),
	centerAccountLoginId: yup
		.string()
		.when('centerAccountBankCd', (centerAccountBankCd, schema) =>
			centerAccountBankCd?.[0]?.value === 'CMN037.088' ? schema.required() : schema,
		),
	centerAccountLoginPassword: yup
		.string()
		.when('centerAccountBankCd', (centerAccountBankCd, schema) =>
			centerAccountBankCd?.[0]?.value === 'CMN037.088' ? schema.required() : schema,
		),
});

export const MyCenterAddFinance = yup.object({
	salaryFeeStandardStartDate: yup.date().required(),
});

export const LoadLongtermData = yup.object({
	dataTypes: yup.array().min(1).required(),
	yearMonth: yup.date().required(),
});

export const MonitoringDataSync = yup.object({
	yearMonth: yup.date().required(),
});

export const LinkAccount = yup.object({
	account: yup.array().min(1).required(),
});

export const MonitoringDetail = yup.object({
	data: yup.mixed(),
	workConfirmYn: yup
		.array()
		.when('data', (data, schema) =>
			data?.[0]?.chargeTypeCd === 'CMN201.20' || data?.[0]?.chargeTypeCd === 'CMN201.30'
				? schema.required('근무 확인 여부는 필수입니다.')
				: schema,
		),
	otherCenterWorkDuplConfirmYn: yup.array(),
	otherCorpWorkDuplConfirmYn: yup.array(),
	careIntervalWorkDuplConfirmYn: yup.array(),
	recogPlanTimeUnderOfferDescCd: yup
		.object()
		.when('data', (data, schema) =>
			data?.[0]?.recogPlanTimeUnderYn
				? schema.required('120분 미만 제공 사유는 필수입니다.')
				: schema,
		),
	recogPlanTimeUnderOfferDesc: yup
		.string()
		.when('recogPlanTimeUnderOfferDescCd', (recogPlanTimeUnderOfferDescCd, schema) =>
			recogPlanTimeUnderOfferDescCd?.[0]?.value === 'CMN203.0199'
				? schema.required('120분 미만 제공 사유는 필수입니다.')
				: schema,
		),
	carePlanTimeOverOfferDescCd: yup
		.object()
		.when('data', (data, schema) =>
			data?.[0]?.carePlanTimeOverYn && data?.[0]?.carePlanTimeOverNm !== '해당없음'
				? schema.required(`${data?.[0]?.carePlanTimeOverNm}분이상 제공 사유는 필수입니다.`)
				: schema,
		),
	carePlanTimeOverOfferDesc: yup
		.string()
		.when(['carePlanTimeOverOfferDescCd', 'data'], ([carePlanTimeOverOfferDescCd, data], schema) =>
			carePlanTimeOverOfferDescCd?.value === 'CMN203.0299'
				? schema.required(
						`${
							data?.carePlanTimeOverNm ? `${data?.carePlanTimeOverNm}분이상 ` : ''
						}제공 사유는 필수입니다.`,
				  )
				: schema,
		),
	careMultipleUseOfferDescCd: yup
		.object()
		.when('data', (data, schema) =>
			data?.[0]?.careMultipleUseYn
				? schema.required('1일 다횟수 방문요양 제공 사유는 필수입니다.')
				: schema,
		),
	careMultipleUseOfferDesc: yup
		.string()
		.when('careMultipleUseOfferDescCd', (careMultipleUseOfferDescCd, schema) =>
			careMultipleUseOfferDescCd?.[0]?.value === 'CMN203.0399'
				? schema.required('1일 다횟수 방문요양 제공 사유는 필수입니다.')
				: schema,
		),
	visitCareFiveGradeOfferDescCd: yup
		.object()
		.when('data', (data, schema) =>
			data?.[0]?.visitCareFiveGradeYn
				? schema.required('5등급 일반요양 제공 사유는 필수입니다.')
				: schema,
		),
	visitCareFiveGradeOfferDesc: yup
		.string()
		.when('visitCareFiveGradeOfferDescCd', (visitCareFiveGradeOfferDescCd, schema) =>
			visitCareFiveGradeOfferDescCd?.[0]?.value === 'CMN203.0499'
				? schema.required('5등급 일반요양 제공 사유는 필수입니다.')
				: schema,
		),
	bathOverOnceWeekOfferDescCd: yup
		.object()
		.when('data', (data, schema) =>
			data?.[0]?.bathOverOnceWeekYn ? schema.required('주1회 초과사유는 필수입니다.') : schema,
		),
	bathOverOnceWeekOfferDesc: yup
		.string()
		.when('bathOverOnceWeekOfferDescCd', (bathOverOnceWeekOfferDescCd, schema) =>
			bathOverOnceWeekOfferDescCd?.[0]?.value === 'CMN203.0599'
				? schema.required('주1회 초과사유는 필수입니다.')
				: schema,
		),
});
