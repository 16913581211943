import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 60rem;
	box-sizing: border-box;
	gap: 1.6rem;
`;

export const TimePickerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.8rem;
`;

export const EmptyContainer = styled.div`
	width: 100%;
	height: 18rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.8rem;
	background-color: ${(p) => p.theme.colors.gray99};
`;
