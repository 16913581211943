import Colors from 'common/colors';

export const EMPLOYEE_LABELS = [
	{
		label: '일정',
		taskColor: Colors.green,
	},
	{
		label: '일정 오류',
		taskColor: Colors.primary60,
	},
	{
		label: '케어링 센터',
		taskColor: Colors.blue,
	},
	{
		label: '타근무',
		taskColor: Colors.yellow,
	},
];
export const RECIPIENT_LABELS = [
	{
		label: '일정',
		taskColor: Colors.green,
	},
	{
		label: '일정 오류',
		taskColor: Colors.primary60,
	},
	{
		label: '케어링 센터',
		taskColor: Colors.blue,
	},
	{
		label: '타급여',
		taskColor: Colors.yellow,
	},
];

export enum CalendarFilterType {
	ALL,
	MY_CENTER,
	CARING_CENTER,
	OTHER,
}

export const CALENDAR_FILTER = [
	{
		label: '전체',
		value: CalendarFilterType.ALL,
	},
	{
		label: '내 센터',
		value: CalendarFilterType.MY_CENTER,
	},
	{
		label: '케어링 센터',
		value: CalendarFilterType.CARING_CENTER,
	},
	{
		label: '타근무',
		value: CalendarFilterType.OTHER,
	},
];
