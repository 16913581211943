import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import CRInput from 'components/base/CRInput';

import Assets from 'assets';
import useDialog from 'lib/hook/util/useDialog';

import CRButton from 'components/base/CRButton';
import { v4 } from 'uuid';
import { korReg } from 'lib';
import { Employee, initEmployeeData } from './mock';
import * as S from './styles';
import { StrictModeDroppable } from './StrictModeDroppable';

interface Props {
	title?: string;
	items: Employee[];
	onApply: (selectedItems: Employee[]) => void;
}

export default function ApprovalLineSelectorDialog({ title = '', items, onApply }: Props) {
	const { hideDialog } = useDialog();
	const [searchResult, setSearchResult] = useState<Employee[]>(initEmployeeData);
	const [sortedItems, setSortedItems] = useState<Employee[]>(items ?? []);
	const [searchText, setSearchText] = useState('');

	const renderSearchUserInfo = (employee: Employee) => `${employee?.name} (${employee?.center})`;
	const onSelectItem = (item: Employee) => {
		let newSelectedItems = [];
		if (sortedItems.length === 0) {
			newSelectedItems = [item];
		} else {
			const exist = sortedItems.find((user) => user.id === item.id);
			if (exist) {
				newSelectedItems = sortedItems?.filter((user) => user.id !== item.id);
			} else {
				newSelectedItems = [...sortedItems, item];
			}
		}

		setSortedItems(newSelectedItems);
	};

	const onDeleteSelectedItem = (id: string) => {
		const newItems = sortedItems?.filter((item) => item.id !== id);
		setSortedItems(newItems);
	};

	const onDeleteAll = () => setSortedItems([]);

	const onDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) return;
			const items = [...sortedItems];
			const [reorderedItem] = items.splice(result.source.index, 1);
			items.splice(result.destination.index, 0, reorderedItem);
			setSortedItems(items);
		},
		[sortedItems],
	);

	const onChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);

	const matchedSearchResult = useMemo(
		() => searchResult?.filter((item) => korReg(searchText).test(item.name)),
		[searchResult, searchText],
	);

	const renderSearchResult = () => {
		let result: Employee[] = [];
		if (matchedSearchResult?.length) {
			result = matchedSearchResult;
		} else if (searchResult?.length) {
			result = searchResult;
		}

		return result.length > 0
			? result.map((item) => {
					const isSelected = !!sortedItems.find((employee) => employee.id === item.id);
					return (
						<S.GroupItem onClick={() => onSelectItem(item)} key={item.id}>
							{item.name}
							{isSelected && <S.CheckIcon src={Assets.icon.check} alt='check' />}
						</S.GroupItem>
					);
			  })
			: null;
	};

	return (
		<S.Container>
			<S.Title>
				{title}
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={() => hideDialog()} />
			</S.Title>
			<S.SearchContainer>
				<S.InputSection>
					<S.SearchBarContainer>
						<CRInput.Search
							value={searchText}
							onChange={onChangeSearchText}
							onClickDeleteButton={() => setSearchText('')}
						/>
					</S.SearchBarContainer>
					<S.CategorySection>
						<S.Group>
							<S.GroupTitle>구성원</S.GroupTitle>
							{renderSearchResult()}
						</S.Group>
					</S.CategorySection>
				</S.InputSection>
				<S.SelectSection>
					<S.TitleContainer>
						<S.GroupTitle>선택({sortedItems.length})</S.GroupTitle>
						<CRButton.Default type='text' palette='gray' size='xSmall' onClick={onDeleteAll}>
							초기화
						</CRButton.Default>
					</S.TitleContainer>
					<S.SelectItemContainer>
						<DragDropContext onDragEnd={onDragEnd}>
							<StrictModeDroppable droppableId={v4()}>
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{sortedItems.map((item, index) => (
											<Draggable key={item.id} draggableId={item.id} index={index}>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}>
														<S.UserListItem key={item.id}>
															<S.UserItemLeft>
																<S.Info>
																	<S.DraggableIcon
																		src={Assets.icon.dragIndicator}
																		alt='dragIndicator'
																	/>
																	<S.ItemIndex>
																		{index < 9 ? `0${index + 1}` : index + 1}
																	</S.ItemIndex>
																</S.Info>
																{renderSearchUserInfo(item)}
															</S.UserItemLeft>
															<S.DeleteIcon
																src={Assets.icon.close}
																onClick={() => onDeleteSelectedItem(item.id)}
															/>
														</S.UserListItem>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</StrictModeDroppable>
						</DragDropContext>
					</S.SelectItemContainer>
				</S.SelectSection>
			</S.SearchContainer>
			<S.ButtonSection>
				<CRButton.Default type='text' palette='gray' onClick={() => hideDialog()}>
					취소
				</CRButton.Default>
				<CRButton.Default
					type='filled'
					palette='primary'
					onClick={() => {
						onApply(sortedItems);
						hideDialog();
					}}>
					저장
				</CRButton.Default>
			</S.ButtonSection>
		</S.Container>
	);
}
