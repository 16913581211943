import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const MenuContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 0 2.4rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const MenuRightContainer = styled.div``;

export const MenuLeftContainer = styled.ul`
	display: flex;
	position: relative;
	width: 100%;
	min-height: 6.4rem;
	max-height: 6.4rem;

	box-sizing: border-box;
	overflow-x: auto;
	overflow-y: hidden;
`;

export const TabItem = styled.li<{ $isActive?: boolean }>`
	${(props) => props.theme.typography.h4};
	display: flex;
	align-items: center;
	position: relative;
	min-height: 2.8rem;
	padding: 1.2rem 0rem;
	box-sizing: border-box;
	cursor: pointer;
	white-space: nowrap;
	color: ${(props) => (props.$isActive ? props.theme.colors.gray00 : props.theme.colors.gray60)};
`;

export const TabUnderLine = styled.div`
	position: absolute;
	height: 0.2rem;
	background-color: ${(props) => props.theme.colors.primary60};
	bottom: -0.1rem;
	border-radius: 0.2rem 0.2rem 0 0;
	box-sizing: border-box;
	transition: all 0.4s ease-in-out;
`;

export const TabContent = styled.div`
	width: 100%;
	height: 100%;
	overflow: overlay;
`;

export const BreadCrumb = styled.h3`
	display: flex;
	gap: 1.6rem;
	align-items: center;
	white-space: nowrap;
	& span {
		${(props) => props.theme.typography.h3};
		color: ${(props) => props.theme.colors.gray00};
	}

	& img {
		width: 2.4rem;
		height: 2.4rem;
	}
`;
