import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useActivateAccount = useCRMutation(endpoint.activateAccount.key, 'activateAccount');
export const useDeActivateAccount = useCRMutation(
	endpoint.deactivateAccount.key,
	'deactivateAccount',
);

export const useGetLinkAccounts = useCRMutation(endpoint.getLinkAccounts.key, 'getLinkAccounts');

export const useLinkAccounts = useCRMutation(endpoint.linkAccounts.key, 'linkAccounts');
