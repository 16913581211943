import styled, { DefaultTheme, Interpolation, ThemeProps, css } from 'styled-components';
import { AvatarSize } from '../CRAvatar/type';

export type RemainTextStyleObj = {
	[key in AvatarSize]: Interpolation<ThemeProps<DefaultTheme>>;
};

const REMAIN_TEXT: RemainTextStyleObj = {
	default: css`
		font-size: 1.2rem;
	`,
	small: css`
		font-size: 1rem;
	`,
	large: css`
		font-size: 1.8rem;
	`,
};

export const Container = styled.div`
	display: flex;
`;

export const RemainText = styled.span<{ size: AvatarSize }>`
	${(props) => REMAIN_TEXT[props.size]}
	color: ${(props) => props.theme.colors.gray60};
`;

export const AvatarContainer = styled.div`
	display: flex;
`;
