import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const EMPLOYEE_SALARY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '8rem',
		label: '연월',
		sortKey: '연월',
	},
	{
		width: '12rem',
		label: '수급자',
		sortKey: '수급자',
	},
	{
		width: '20rem',
		label: '급여',
		sortKey: '급여',
	},
	{
		width: '8rem',
		label: '근로 일정',
		sortKey: '근로 일정',
		textAlign: 'right',
	},
	{
		width: '8.8rem',
		label: '근로 시간',
		sortKey: '근로 시간',
		textAlign: 'right',
	},

	{
		width: '12rem',
		label: '임금',
		sortKey: '임금',
		textAlign: 'right',
	},
	{
		width: '12rem',
		label: '공제액',
		sortKey: '공제액',
		textAlign: 'right',
	},
	{
		width: '12rem',
		label: '실수령액',
		sortKey: '실수령액',
		textAlign: 'right',
	},
	{
		width: '8.8rem',
		label: '검수 상태',
		sortKey: '검수 상태',
	},
	{
		width: '8.8rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
	{
		width: '9.6rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];

export const EMPLOYEE_SALARY_SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '성공',
		label: '성공',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
	{
		key: '발송중',
		label: '발송중',
		color: 'yellow',
	},
];

export const EMPLOYEE_SALARY_COMPLETE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
];
