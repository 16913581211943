import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 80rem;
	box-sizing: border-box;
	gap: 2.4rem;
`;

export const CheckContainer = styled.div`
	padding: 1.6rem;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
`;

export const AllCheckContainer = styled.div``;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
`;

export const CheckContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const CheckRowContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const CheckColumnContainer = styled.div`
	width: 33.3%;
`;
