import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { Toast } from 'components/base/CRToast';
import {
	useCenterList,
	useCenterManagers,
	useCommonCodes,
	useContractDetail,
	useMyAccountInfo,
	useSaveCompleteContract,
	useSaveTempContract,
	useUpdateEmployeeOtherCorpWork,
	useUpdateEmployeeTagCall,
	useUpdateRecipientOtherServices,
} from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import { endpoint } from 'lib/service/Api/endpoint';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { ContractDetailDTO } from 'types/dto';
import { CommonCodeDTO } from 'types/api/common';
import { ContractStatusType } from 'types/view/common';

interface ContractTaskContextType {
	form: UseFormReturn<ContractDetailDTO>;
	refetch: () => void;
	commonCodes?: {
		[key: string]: CheckOption<CommonCodeDTO>[];
	};
	saveCompleteContract?: () => void;
	saveTempContract?: (param?: { disableToast?: boolean }) => void;
	validate?: (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => void;
	isLoaded?: boolean;
	disabled?: boolean;
}

export const ContractTaskContext = React.createContext<ContractTaskContextType | null>(null);

function ContractTaskProvider({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	const { showDialog } = useGlobalLayout();
	const [isLoaded, setIsLoaded] = useState(false);
	const params = useParams<{ contractId?: string }>();
	const { data: myAccountInfo } = useMyAccountInfo();
	const saveCompleteContractMutation = useSaveCompleteContract();
	const saveTempContractMutation = useSaveTempContract();
	const updateRecipientOtherService = useUpdateRecipientOtherServices((client, _, variables) => {
		client.invalidateQueries([endpoint.getRecipient.key, { recipientId: variables.recipientId }]);
		client.invalidateQueries([
			endpoint.getRecipientBase.key,
			{ recipientId: variables.recipientId },
		]);
	});
	const updateEmployeeTagCall = useUpdateEmployeeTagCall();

	const updateEmployeeOtherCorpWork = useUpdateEmployeeOtherCorpWork(
		(client, returnData, variables) => {
			if (returnData?.employeeId) {
				client.invalidateQueries([
					endpoint.getEmployeeDetailInfo.key,
					{
						centerId: Number(myAccountInfo?.centerId),
						employeeId: Number(variables?.employeeId),
					},
				]);
				client.invalidateQueries([
					endpoint.getEmployeeBaseInfo.key,
					{
						centerId: Number(myAccountInfo?.centerId),
						employeeId: Number(variables?.employeeId),
					},
				]);
			}
		},
	);

	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes(
		{
			comCdGroupNms: [
				'CMN006',
				'CMN041',
				'CMN042',
				'CMN043',
				'CMN162',
				'CMN164',
				'CMN143',
				'CMN038',
				'CMN020',
				'CMN070',
				'CMN084',
				'CMN064',
				'CMN037',
				'CMN133',
				'CMN068',
				'CMN020',
				'CMN149',
				'CMN070',
				'CMN067',
				'CMN180',
			],
		},
		commonCodeAdapter,
	);

	const { data: centerManager } = useCenterManagers({ centerId: myAccountInfo?.centerId });
	const { data: centers } = useCenterList();
	const { data, refetch } = useContractDetail({
		serviceContractId: Number(params.contractId),
	});

	const centerManagerOption = (centerManager ?? []).map(
		(item) => ({ label: item.userNm, value: item.memberAccountId } as CheckOption),
	);
	const centerOption = (centers ?? []).map(
		(item) => ({ label: item.centerNm, value: item.centerId } as CheckOption),
	);

	const form = useForm<ContractDetailDTO>();

	const validate = (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => {
		if (!form.getValues('recipient.base.addressInfo.basAddress')) {
			Toast.error('수급자 주소를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.base.addressInfo.detailAddress')) {
			Toast.error('수급자 상세 주소를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.base.birthDt')) {
			Toast.error('수급자 생년월일을 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.base.signMethodCd')) {
			Toast.error('수급자 서명 방법을 입력해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.longTerm.longTermNo') ||
			!form.getValues('recipient.longTerm.longTermMajorChangeNo') ||
			!form.getValues('recipient.longTerm.longTermMinorChangeNo')
		) {
			Toast.error('수급자 장기요양인정번호를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermGradeCd')) {
			Toast.error('수급자 장기요양인정등급을 입력해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.longTerm.longTermStartDate') ||
			!form.getValues('recipient.longTerm.longTermEndDate')
		) {
			Toast.error('수급자 장기요양인정기간을 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.burdenRateCd')) {
			Toast.error('수급자 장기요양 본인부담률을 입력해주세요');
			return false;
		}
		if (
			form
				.getValues('recipient.longTerm.longTermServices')
				.some(
					(item) =>
						!item.longTermPayFee ||
						!item.serviceCnt ||
						!item.serviceCntStandardCd ||
						!item.serviceOfferTimeCd,
				)
		) {
			Toast.error('수급자 장기요양 급여한도를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermUsePlanFile')) {
			Toast.error('수급자 개인별 장기 요양 인정 이용 계획서를 등록해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermCertiFile')) {
			Toast.error('수급자 개인별 장기 요양 인정서를 등록해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.guardians')?.every((item) => {
				if (item.mainGuardianYn) {
					return (
						item.guardianNm &&
						item.guardianPhoneNo &&
						item.addressInfo?.detailAddress &&
						item.addressInfo?.basAddress &&
						item.guardianBirthDt &&
						item.guardianRelCd
					);
				}
				return item.guardianNm && item.guardianPhoneNo && item.guardianRelCd;
			})
		) {
			Toast.error('수급자 보호자 정보를 등록해주세요');
			return false;
		}
		if (
			!(
				(form.getValues('recipient.recipientReceipt.cashReceiptIssueYn') !== undefined &&
					!form.getValues('recipient.recipientReceipt.cashReceiptIssueYn')) ||
				(form.getValues('recipient.recipientReceipt.cashReceiptIssueYn') &&
					form.getValues('recipient.recipientReceipt.cashReceiptIssuerNm') &&
					form.getValues('recipient.recipientReceipt.cashReceiptIssueMethodCd') &&
					form.getValues('recipient.recipientReceipt.cashReceiptIssuerMobilePhoneNo'))
			)
		) {
			Toast.error('수급자 현금영수증 정보를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('services')
				.some(
					(item) =>
						!item.use?.monthPerWorkHourCnt ||
						!item.use.serviceEndDate ||
						!item.use.serviceStartDate ||
						!item.use.serviceTimes.length,
				)
		) {
			Toast.error('급여 정보의 이용 정보를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('services')
				.some(
					(item) =>
						!item.associates?.recipientContractAssignDate ||
						item.associates.contractEmployees?.some(
							(employee) => !employee.employeeContractAssignDate || !employee.employeeId,
						),
				)
		) {
			Toast.error('급여 정보의 계약 정보를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeNm)) {
			Toast.error('직원의 이름을 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeBasAddr)) {
			Toast.error('직원의 주소를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeDetailAddr)) {
			Toast.error('직원의 상세 주소를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeBirthDt)) {
			Toast.error('직원의 생년월일을 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeRelCd)) {
			Toast.error('직원의 관계를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.tagMobilePhoneNo)) {
			Toast.error('직원의 전화번호를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => item.base.twoPhoneYn === undefined)) {
			Toast.error('직원의 투폰 여부를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('employees')
				.some((item) => item.base.twoPhoneYn && !item.base.subMobilePhoneNo)
		) {
			Toast.error('직원의 투폰 전화번호를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => item.base.dementiaEduCompleteYn === undefined)) {
			Toast.error('직원의 치매교육 여부를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.disabilityGradeCd)) {
			Toast.error('직원의 장애 여부를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => item.base.tagCallRequestYn === undefined)) {
			Toast.error('직원의 태그콜 진행 여부를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('employees')
				.some((item) =>
					item.otherCorpWorks?.some(
						(otherCorpWork) =>
							!otherCorpWork.otherCorpNm ||
							!otherCorpWork.otherCorpPhoneNo ||
							!otherCorpWork.otherCorpWorkStartEndTimes.length,
					),
				)
		) {
			Toast.error('직원의 타근무를 등록해주세요');
			return false;
		}
		if (
			!config?.isCreateDocument &&
			form
				.getValues('employees')
				.some(
					(item) =>
						!(
							(item.salary?.salaryPayTypeCd && item.salary?.salaryPayTypeCd !== 'CMN133.10') ||
							(item.salary?.salaryAccountDepositorNm &&
								item.salary.salaryAccountNo &&
								item.salary.salaryAccountBankCd &&
								item.salary.salaryAccountValidYn)
						),
				)
		) {
			Toast.error('직원의 임금 정보를 등록해주세요');
			return false;
		}
		if (
			!(
				form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') &&
				(!(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10') ||
					(form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountNo') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransBankCd') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountValidYn'))) &&
				(!(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.30') ||
					form.getValues('inspection.burdenAmt.burdenAmtPayDeductEmployeeId'))
			)
		) {
			Toast.error('점검의 본인부담금 정보를 등록해주세요');
			return false;
		}
		if (
			!config?.ignoreCheck &&
			!(
				form.getValues('inspection.check.recipientIaWriteYn') &&
				form.getValues('inspection.check.salaryOfferPlanWriteYn') &&
				form.getValues('inspection.check.pcorpScheduleRegistYn') &&
				form.getValues('inspection.check.wardReportCompleteYn') &&
				form.getValues('inspection.check.hopeiumFormWriteYn') &&
				form.getValues('inspection.check.tagBeaconRequestYn') &&
				form.getValues('inspection.check.admsnUsereqUsedescLocalgovSendYn') &&
				form.getValues('inspection.check.liabilityInsuranceRegistNeedYn') &&
				form.getValues('inspection.check.longTermUsePlanPcorpBrowseRequestYn') &&
				form.getValues('inspection.check.pcorpSalaryContractCancelYn') &&
				(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') !== 'CMN041.10' ||
					form.getValues('inspection.check.autotransUploadYn')) &&
				form.getValues('inspection.check.insuranceRequestYn') &&
				form.getValues('inspection.check.wardEndReportCompleteYn')
			)
		) {
			Toast.error('점검의 점검 체크를 등록해주세요');
			return false;
		}
		return true;
	};

	const disabled = useMemo(
		() =>
			[ContractStatusType.Complete, ContractStatusType.Cancel, ContractStatusType.Pending].includes(
				form.watch('contractBaseInfo.contractStateCd') as ContractStatusType,
			),
		[form.watch('contractBaseInfo.contractStateCd')],
	);

	const saveCompleteContract = async () => {
		if (saveCompleteContractMutation.isLoading) return;
		if (!validate()) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='계약완료'
				content='계약을 완료하고 저장합니다.'
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
					callback: () => hideDialog(),
				}}
				successOption={{
					text: '확인',
					successCallback: async () => {
						try {
							await updateRecipientOtherService.mutateAsync({
								recipientId: Number(data?.recipient?.base.recipientId),
								body: (form.getValues('recipient.otherServiceUses') ?? []).map((item) => ({
									otherCenterNm: item.otherCenterNm,
									otherCenterPhoneNo: item.otherCenterPhoneNo,
									serviceTypeCd: item.serviceTypeCd,
									remark: item.remark,
									times: item.times,
								})),
							});
							await Promise.all(
								(form.getValues('employees') ?? []).map(async (item) => {
									await updateEmployeeOtherCorpWork.mutateAsync({
										employeeId: item.base.employeeId,
										otherCorpWorks: (item.otherCorpWorks ?? []).map((otherCorpWork) => ({
											personalBusinessOwnerYn: otherCorpWork.personalBusinessOwnerYn,
											otherCorpNm: otherCorpWork.otherCorpNm,
											otherCorpPhoneNo: otherCorpWork.otherCorpPhoneNo,
											remark: otherCorpWork.remark,
											times: (otherCorpWork.otherCorpWorkStartEndTimes ?? []).map((time) => ({
												otherCorpWorkTimeId: time.otherCorpWorkTimeId,
												otherCorpWorkStartTime: time.otherCorpWorkStartTime,
												otherCorpWorkEndTime: time.otherCorpWorkEndTime,
												days: time.otherCorpWorkDays,
											})),
										})),
									});
									await updateEmployeeTagCall.mutateAsync([
										{
											employeeId: item.base.employeeId,
											tagCallDayCds:
												item.base.tagCallDayCds?.map((item) => item.tagCallDayCd) ?? [],
										},
									]);
								}),
							);
							await saveCompleteContractMutation.mutateAsync({
								serviceContractId: Number(params.contractId),
								...form.getValues(),
								recipient: {
									...form.getValues('recipient'),
									longTerm: {
										...form.getValues('recipient.longTerm'),
										longTermNo: `L${form.getValues('recipient.longTerm.longTermNo')}`,
									},
									otherServiceUses: null,
								},
								employees: (form.getValues('employees') ?? [])?.map((item) => ({
									...item,
									base: {
										...item.base,
										tagCallDayCds: null,
										joinDate: item.base.joinDate
											? dayjs(item.base.joinDate).format('YYYY-MM-DD HH:mm:ss')
											: null,
									},
									otherCorpWorks: null,
								})),
								inspection: {
									...form.getValues('inspection'),
									check: {
										...form.getValues('inspection.check'),
										lastConfirmDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
										lastCheckerId: form.getValues('recipient.socialWorkerId'),
									},
								},
							});
							hideDialog();
							navigate(`/contract`);
						} catch (error: any) {
							Toast.error(error.message ?? '계약 완료가 실패하였습니다.');
							hideDialog();
						}
					},
				}}
			/>
		));
	};

	const saveTempContract = async (configs?: { disableToast?: boolean }) => {
		try {
			if (saveTempContractMutation.isLoading) return;
			await updateRecipientOtherService.mutateAsync({
				recipientId: Number(form.getValues('recipient.base.recipientId')),
				body: (form.getValues('recipient.otherServiceUses') ?? []).map((item) => ({
					otherCenterNm: item.otherCenterNm,
					otherCenterPhoneNo: item.otherCenterPhoneNo,
					serviceTypeCd: item.serviceTypeCd,
					remark: item.remark,
					times: item.times,
				})),
			});
			await Promise.all(
				(form.getValues('employees') ?? []).map(async (item) => {
					await updateEmployeeOtherCorpWork.mutateAsync({
						employeeId: item.base.employeeId,
						otherCorpWorks: (item.otherCorpWorks ?? []).map((otherCorpWork) => ({
							personalBusinessOwnerYn: otherCorpWork.personalBusinessOwnerYn,
							otherCorpNm: otherCorpWork.otherCorpNm,
							otherCorpPhoneNo: otherCorpWork.otherCorpPhoneNo,
							remark: otherCorpWork.remark,
							times: (otherCorpWork.otherCorpWorkStartEndTimes ?? []).map((time) => ({
								otherCorpWorkTimeId: time.otherCorpWorkTimeId,
								otherCorpWorkStartTime: time.otherCorpWorkStartTime,
								otherCorpWorkEndTime: time.otherCorpWorkEndTime,
								days: time.otherCorpWorkDays,
							})),
						})),
					});
					await updateEmployeeTagCall.mutateAsync([
						{
							employeeId: item.base.employeeId,
							tagCallDayCds: item.base.tagCallDayCds?.map((item) => item.tagCallDayCd) ?? [],
						},
					]);
				}),
			);

			await saveTempContractMutation.mutateAsync({
				serviceContractId: Number(params.contractId),
				...form.getValues(),
				recipient: {
					...form.getValues('recipient'),
					longTerm: {
						...form.getValues('recipient.longTerm'),
						longTermNo: `L${form.getValues('recipient.longTerm.longTermNo')}`,
					},
					otherServiceUses: null,
				},
				employees: (form.getValues('employees') ?? [])?.map((item) => ({
					...item,
					base: {
						...item.base,
						tagCallDayCds: null,
						joinDate: item.base.joinDate
							? dayjs(item.base.joinDate).format('YYYY-MM-DD HH:mm:ss')
							: null,
					},
					otherCorpWorks: null,
				})),
				inspection: {
					...form.getValues('inspection'),
					check: {
						...form.getValues('inspection.check'),
						lastConfirmDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
						lastCheckerId: form.getValues('recipient.socialWorkerId'),
					},
				},
			});

			if (configs?.disableToast) return;
			Toast.success('계약이 저장되었습니다.');
		} catch (error) {
			if (configs?.disableToast) return;
			Toast.error('계약 저장에 실패하였습니다.');
		}
	};

	const value = useMemo(
		() => ({
			form,
			commonCodes: { ...commonCodes, centerManagerOption, centerOption },
			saveCompleteContract,
			saveTempContract,
			validate,
			refetch,
			isLoaded,
			disabled,
		}),
		[
			form,
			commonCodes,
			centerManagerOption,
			saveCompleteContract,
			saveTempContract,
			validate,
			refetch,
			isLoaded,
			disabled,
		],
	);

	useEffect(() => {
		if (data) {
			form.reset({
				...data,
				recipient: data?.recipient
					? {
							...data?.recipient,
							longTerm: data.recipient?.longTerm
								? {
										...data.recipient?.longTerm,
										longTermNo: data.recipient?.longTerm.longTermNo?.replaceAll('L', ''),
								  }
								: undefined,
					  }
					: undefined,
			});
			setIsLoaded(true);
		}
	}, [data]);

	return <ContractTaskContext.Provider value={value}>{children}</ContractTaskContext.Provider>;
}

export default React.memo(ContractTaskProvider);
