import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: overlay;
`;

export const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	margin: 12rem auto;
`;

export const Logo = styled.img`
	display: flex;
	justify-content: center;
	margin-bottom: 3.2rem;
	height: 4rem;
`;

export const Text = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	& > h3 {
		${(props) => props.theme.typography.h3};
		color: ${(props) => props.theme.colors.gray10};
	}

	& > span {
		${(props) => props.theme.typography.body};
		color: ${(props) => props.theme.colors.gray50};
		white-space: pre-wrap;
	}
`;

export const Form = styled.form`
	margin-top: 3.2rem;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	margin-top: 3.2rem;
`;
