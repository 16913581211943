import React, { useEffect, useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { Controller, UseFormReturn } from 'react-hook-form';
import CRInputLabel from 'components/base/CRInputLabel';
import { InsuranceChangeSalaryFormType } from 'types/view/insurance';
import InformationTable from 'components/ui/InformationTable';
import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { useParams } from 'react-router-dom';
import { useInsurancePriorInfo } from 'lib/hook/react-query/query/insurance';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { InsurancePriorInfoDTO } from 'types/api/insurance';
import { CRText } from 'components/base/CRText';
import { commonCodeAdapter } from 'lib/adapter/common';
import * as S from './styles';
import { InsuranceType } from '..';

interface Props {
	context: UseFormReturn<InsuranceChangeSalaryFormType, any, undefined>;
	requestTypeCd?: string;
}

export default function InsuranceChangeSalaryForm({ context, requestTypeCd }: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN199'],
		},
		commonCodeAdapter,
	);
	const { data: myAccountInfo } = useMyAccountInfo();
	const params = useParams<{ id: string }>();
	const formData = context.watch();

	const { data: insurancePriorInfo } = useInsurancePriorInfo({
		employeeId: Number(params.id),
		centerId: myAccountInfo?.centerId,
		insuranceTypeCd: requestTypeCd,
	});

	const { insuranceType } = formData;

	const salaryChangeDescOptions = commonCodes?.CMN199 || [];

	const displayRequestAmount = (amount?: number) => (
		<CRText text={amount ? amount.toLocaleString() : '-'} typography='label' color='gray10' />
	);

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '이전 신고 금액',
					labelStyle: {
						width: '16rem',
						textAlign: 'right',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '변경할 신고 금액',
					labelStyle: {
						width: '16rem',
						height: '4.1rem',
						textAlign: 'right',
						verticalAlign: 'middle',
					},
				},
			],
		];
		if (insurancePriorInfo?.employInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.EMPLOY,
											value: InsuranceType.EMPLOY,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayRequestAmount(insurancePriorInfo?.employInsuranceAmt),
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='employInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.accidentInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.ACCIDENT,
											value: InsuranceType.ACCIDENT,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayRequestAmount(insurancePriorInfo?.accidentInsuranceAmt),
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='accidentInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}
		if (insurancePriorInfo?.pensionInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.PENSION,
											value: InsuranceType.PENSION,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayRequestAmount(insurancePriorInfo?.pensionInsuranceAmt),
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.PENSION)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='pensionInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.healthInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.HEALTH,
											value: InsuranceType.HEALTH,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayRequestAmount(insurancePriorInfo?.healthInsuranceAmt),
					valueStyle: {
						textAlign: 'right',
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.TableInput
									maxLength={13}
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.HEALTH)}
									prefix=' '
									type='comma'
									value={value}
									onChange={onChange}
									placeholder='신고 금액 입력'
								/>
							)}
							name='healthInsuranceRequestAmt'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		return items;
	}, [insurancePriorInfo, insuranceType, context]);

	const isInsuranceInfoEmpty = insurancePriorInfo
		? (
				[
					'accidentInsurancePosbYn',
					'employInsurancePosbYn',
					'healthInsurancePosbYn',
					'pensionInsurancePosbYn',
				] as (keyof InsurancePriorInfoDTO)[]
		  ).every((key) => insurancePriorInfo[key] === false)
		: false;

	useEffect(() => {
		// 체크값 바뀔때 관련 일자 및 신고 금액 초기화
		const isCheckAccident = !!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT);
		const isCheckEmploy = !!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY);
		const isCheckHealth = !!insuranceType?.find((item) => item.value === InsuranceType.HEALTH);
		const isCheckPension = !!insuranceType?.find((item) => item.value === InsuranceType.PENSION);

		if (!isCheckAccident) {
			context.setValue('accidentInsuranceRequestAmt', '');
		}
		if (!isCheckEmploy) {
			context.setValue('employInsuranceRequestAmt', '');
		}
		if (!isCheckHealth) {
			context.setValue('healthInsuranceRequestAmt', '');
		}
		if (!isCheckPension) {
			context.setValue('pensionInsuranceRequestAmt', '');
		}
	}, [insuranceType]);

	useEffect(() => {
		if (insurancePriorInfo) {
			context.setValue('data', insurancePriorInfo);
		}
	}, [insurancePriorInfo]);

	return (
		<Accordion.Item value='신고 내용' asChild>
			<TaskAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<TaskAccordion.Content>
						<S.FormContainer>
							<CRInputLabel
								label='신고 선택'
								type='left-sub'
								isRequired
								showOverflow
								betweenGap='2.4rem'>
								{isInsuranceInfoEmpty ? (
									<S.EmptyContainer>
										<CRText
											typography='labelB'
											color='gray60'
											text='신고 가능한 보험이 없습니다.'
										/>
									</S.EmptyContainer>
								) : (
									<InformationTable roundBorder items={informationTableItems} />
								)}
							</CRInputLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInputLabel
										label='보수액 변경 월'
										type='left-sub'
										showOverflow
										isRequired
										betweenGap='2.4rem'>
										<CRInput.YearMonthPicker
											disabled={isInsuranceInfoEmpty}
											onChangeValue={onChange}
											currentValue={value}
											placeholder='변경 월 선택'
										/>
									</CRInputLabel>
								)}
								name='salaryChangeYm'
								control={context.control}
							/>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInputLabel
										label='변경 사유'
										type='left-sub'
										showOverflow
										isRequired
										betweenGap='2.4rem'>
										<CRInput.Selector
											disabled={isInsuranceInfoEmpty}
											onChangeValue={onChange}
											currentValue={value}
											items={salaryChangeDescOptions}
											placeholder='변경 사유 선택'
										/>
									</CRInputLabel>
								)}
								name='salaryChangeDescCd'
								control={context.control}
							/>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
										<CRInput.TextArea
											disabled={isInsuranceInfoEmpty}
											numberOfLines={4}
											onChange={onChange}
											value={value}
											placeholder='요청 사항 입력'
											fixedHeight
										/>
									</CRInputLabel>
								)}
								name='requestDesc'
								control={context.control}
							/>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInputLabel label='파일 첨부' type='left-sub' showOverflow betweenGap='2.4rem'>
										<CRInput.FileUploader
											disabled={isInsuranceInfoEmpty}
											onChange={onChange}
											files={value}
											type='multiple'
											placeholder='파일을 끌어오거나 업로드'
										/>
									</CRInputLabel>
								)}
								name='attachFile'
								control={context.control}
							/>
						</S.FormContainer>
					</TaskAccordion.Content>
				</Accordion.Content>
			</TaskAccordion.Item>
		</Accordion.Item>
	);
}
