import React, { useEffect, useMemo } from 'react';
import { CheckOption } from 'components/base/Selections/type';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { EmployeeBaseInfoDTO, EmployeeDTO } from 'types/api/employee';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { ForeignRequestConfirmFormTypes } from 'types/view/eDoc';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import dayjs from 'dayjs';
import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<ForeignRequestConfirmFormTypes, any, undefined>;
	currentEmployeeBase?: EmployeeBaseInfoDTO;
}

// 서약서
export default function EDocForeignRequestConfirmForm({
	templateCode,
	currentEmployee,
	currentEmployeeBase,
	formContext,
}: Props) {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});
	const isWithDrawType = formContext.watch('joinType')?.[0]?.value === false;

	const dutySignCds = useMemo(
		() =>
			(eDocBeforeIssueInfo?.data.dutySignCds || [])?.map((item) => ({
				label: item.korComCdNm,
				value: item.etcDesc5,
			})),
		[eDocBeforeIssueInfo],
	);

	useEffect(() => {
		const defaultForm: ForeignRequestConfirmFormTypes = {
			date: dayjs().set('h', 0).set('m', 0).set('s', 0).toDate(),
		};
		if (eDocBeforeIssueInfo) {
			defaultForm.data = eDocBeforeIssueInfo;
		}
		if (currentEmployeeBase?.rsdnNo) {
			defaultForm.rsdnNo = currentEmployeeBase.rsdnNo;
			const gender = ['1', '3', '5', '7'].includes(currentEmployeeBase.rsdnNo?.[6]) ? '남' : '여';
			defaultForm.gender = [{ label: gender, value: gender }];
		}
		if (currentEmployee?.korMemberNm) {
			defaultForm.engNm = currentEmployee.korMemberNm;
		}
		if (currentEmployee?.birthDt) {
			defaultForm.birthDt = currentEmployee?.birthDt;
		}
		if (currentEmployeeBase?.joinDate) {
			defaultForm.joinDate = dayjs(currentEmployeeBase.joinDate).toDate();
		}
		if (currentEmployeeBase?.dutyNms) {
			const matchedItem = dutySignCds?.find((item) => item.label === currentEmployeeBase?.dutyNms);
			if (matchedItem) {
				defaultForm.dutyNm = matchedItem;
			}
		}

		formContext.reset(defaultForm);
	}, [eDocBeforeIssueInfo, currentEmployeeBase, currentEmployee, dutySignCds]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='가입 유형' isRequired>
							<CRCheckBoxGroup
								style={{
									width: 'auto',
								}}
								checkType='single'
								type='radio'
								onChange={onChange}
								appearanceType='button'
								gap={0.8}
								value={value}
								options={[
									{
										label: '가입',
										value: true,
									},
									{
										label: '가입탈퇴',
										value: false,
									},
								]}
							/>
						</CRInputLabel>
					)}
					name='joinType'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청인 영문 성명' isRequired>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='신청인 영문 성명 입력'
							/>
						</CRInputLabel>
					)}
					name='engNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청인 한글 성명' isRequired>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='신청인 한글 성명 입력'
							/>
						</CRInputLabel>
					)}
					name='korNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='성별' isRequired>
							<CRCheckBoxGroup
								style={{
									width: 'auto',
								}}
								checkType='single'
								type='radio'
								onChange={onChange}
								appearanceType='button'
								gap={0.8}
								value={value}
								options={[
									{
										label: '남',
										value: '남',
									},
									{
										label: '여',
										value: '여',
									},
								]}
							/>
						</CRInputLabel>
					)}
					name='gender'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='외국인등록번호' isRequired>
							<CRInput.Default
								maxLength={13}
								value={value}
								onChange={onChange}
								placeholder='외국인등록번호 입력'
							/>
						</CRInputLabel>
					)}
					name='rsdnNo'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='생년월일' isRequired>
							<CRInput.Default
								type='number'
								maxLength={8}
								value={value}
								onChange={onChange}
								placeholder='생년월일 입력'
								addOnBottom='8자리 입력 (예:19850101)'
							/>
						</CRInputLabel>
					)}
					name='birthDt'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='국적' isRequired>
							<CRInput.Default value={value} onChange={onChange} placeholder='국적 입력' />
						</CRInputLabel>
					)}
					name='countryNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='체류자격' isRequired>
							<CRInput.Default value={value} onChange={onChange} placeholder='체류자격 입력' />
						</CRInputLabel>
					)}
					name='stayQualificationNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='채용일' isRequired>
							<CRInput.DatePicker
								value={value}
								onChangeValue={onChange}
								placeholder='채용일 입력'
							/>
						</CRInputLabel>
					)}
					name='joinDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='직종' isRequired>
							<CRInput.Selector
								items={dutySignCds}
								currentValue={value}
								onChangeValue={onChange}
								placeholder='직종 입력'
							/>
						</CRInputLabel>
					)}
					name='dutyNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='주 소정근로시간' isRequired>
							<CRInput.Default
								type='number'
								value={value}
								onChange={onChange}
								placeholder='주 소정근로시간 입력'
								suffix='시간'
							/>
						</CRInputLabel>
					)}
					name='weekPerWorkTime'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='월 평균 보수' isRequired>
							<CRInput.Default
								type='number'
								value={value}
								onChange={onChange}
								placeholder='월 평균 보수 입력'
								suffix='원'
							/>
						</CRInputLabel>
					)}
					name='monthlyAvgSalaryAmt'
					control={formContext.control}
				/>
				{isWithDrawType && (
					<>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='해당년도 보수 총액' isRequired>
									<CRInput.Default
										type='number'
										value={value}
										onChange={onChange}
										placeholder='해당년도 보수 총액 입력'
										suffix='원'
									/>
								</CRInputLabel>
							)}
							name='paySumAmtCurrentYear'
							control={formContext.control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='전년도 보수 총액' isRequired>
									<CRInput.Default
										type='number'
										value={value}
										onChange={onChange}
										placeholder='전년도 보수 총액 입력'
										suffix='원'
									/>
								</CRInputLabel>
							)}
							name='paySumAmtLastYear'
							control={formContext.control}
						/>
					</>
				)}
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청일' isRequired>
							<CRInput.DatePicker
								popupPosition={{
									x: 0,
									y: -250,
								}}
								value={value}
								onChangeValue={onChange}
								placeholder='신청일 입력'
							/>
						</CRInputLabel>
					)}
					name='date'
					control={formContext.control}
				/>
			</S.SubFormContainer>
		</S.Container>
	);
}
