import React from 'react';

import { TaskTabProps } from './type';
import * as S from './styles';

interface Props {
	items: TaskTabProps[];
	defaultActiveKey?: string;
	onChange?: (item: TaskTabProps) => void;
}

function PageTab({ defaultActiveKey, onChange, items }: Props): React.ReactElement {
	const onClickTab = (selectedItem: TaskTabProps) => {
		onChange?.(selectedItem);
	};

	return (
		<S.Container>
			<S.MenuContainer>
				{items?.map((item) => (
					<S.TabItem
						key={item.key}
						$isActive={item.key === defaultActiveKey}
						onClick={() => onClickTab(item)}>
						{item.label}
					</S.TabItem>
				))}
			</S.MenuContainer>
			<S.Divider />
		</S.Container>
	);
}

export default React.memo(PageTab);
