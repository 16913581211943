import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CashReceiptForm } from 'types/view/ownExpense';
import { BurdenChargeDTO } from 'types/dto';

import * as S from './styles';
import { useCommonCodes } from '../../../../lib/hook/react-query';
import { commonCodeAdapter } from '../../../../lib/adapter/common';

interface Props {
	title?: string;
	item: BurdenChargeDTO;
	hideDialog?: () => void | Promise<void>;
	isCancel?: boolean;
	onSubmit?: (form: CashReceiptForm) => void;
}

export default function CashReceiptDialog({
	title,
	item,
	hideDialog,
	isCancel = false,
	onSubmit,
}: Props): React.ReactElement {
	const [isProcessing, setIsProcessing] = useState(false);

	const { data: commonCodes = {} } = useCommonCodes(
		{
			comCdGroupNms: ['CMN162'],
		},
		commonCodeAdapter,
	);

	const { control, getValues } = useForm<CashReceiptForm>({
		defaultValues: {
			name: isCancel ? item.cashReceipt?.cashReceiptIssuerNm ?? '' : item.cashReceiptIssuerNm ?? '',
			date: item.burdenAmtChargeDt,
			amount: item.burdenAmt.toLocaleString(),
			issuerNo: {
				number: isCancel ? item.cashReceipt?.cashReceiptIssuerNo : item.cashReceiptIssuerNo,
				type: {
					label: '',
					value: isCancel
						? item.cashReceipt?.cashReceiptIssueMethodCd ?? 'CMN162.10'
						: item.cashReceiptIssueMethodCd ?? 'CMN162.10',
				},
			},
		},
	});

	const handleClickSubmit = async () => {
		if (isProcessing) return;
		setIsProcessing(true);
		await onSubmit?.(getValues());
		setIsProcessing(false);

		hideDialog?.();
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title={title}
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='이름' isRequired>
								<CRInput.Default
									value={value}
									onChange={onChange}
									placeholder='이름 입력'
									disabled={isCancel}
								/>
							</CRInputLabel>
						)}
						name='name'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='등록 번호' isRequired>
								<CRCheckBoxGroup
									type='radio'
									checkType='single'
									options={commonCodes?.CMN162 ?? []}
									value={[value.type]}
									onChange={isCancel ? undefined : (item) => onChange({ ...value, type: item[0] })}
								/>
								<CRInput.Default
									value={value?.number}
									onChange={(item) => onChange({ ...value, number: item })}
									type='number'
									placeholder={
										value.type.value === 'CMN162.10' ? '휴대폰 번호 입력' : '사업자 번호 입력'
									}
									disabled={isCancel}
								/>
							</CRInputLabel>
						)}
						name='issuerNo'
						control={control}
					/>
					<Controller
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='청구 일자'>
								<CRInput.Default onChange={onChange} value={value} disabled />
							</CRInputLabel>
						)}
						name='date'
					/>
					<Controller
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='금액'>
								<CRInput.Default onChange={onChange} value={value} disabled suffix='원' />
							</CRInputLabel>
						)}
						name='amount'
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						{isCancel ? '닫기' : '취소'}
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleClickSubmit}>
						{isCancel ? '발행 취소' : '발행 신청'}
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
