import React from 'react';
import { Controller } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';

import useWorkExecuteLog from 'lib/hook/view/workExecuteLog/useWorkExecuteLog';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import InformationSheet from 'components/ui/InformationSheet';
import Colors from 'common/colors';
import { WorkExecuteLogNewRecipient } from 'types/api/workExecuteLog';
import CRInput from 'components/base/CRInput';
import CRStatus from 'components/base/CRStatus';
import * as S from './styles';
import { WRITE_COMPLETE_YN_CONFIG } from './constants';

export enum WorkExecuteLogCode {
	사회복지사 = 'CMN183.10',
	프로그램관리자 = 'CMN183.20',
}

export const insuranceTypes = [
	{
		label: '사회복지사 업무수행일지',
		value: WorkExecuteLogCode.사회복지사,
	},
	{
		label: '프로그램관리자 업무수행일지',
		value: WorkExecuteLogCode.프로그램관리자,
	},
] as CheckOption<any>[];

export const writeCompleteYnOptions = [
	{
		label: '작성 예정',
		value: false,
	},
	{
		label: '작성 완료',
		value: true,
	},
] as CheckOption<any>[];

interface Props {
	recipient?: WorkExecuteLogNewRecipient;
}

function WorkExecuteLogBasicInfoForm({ recipient }: Props): React.ReactElement {
	const { form } = useWorkExecuteLog();

	const writeCompleteYn = writeCompleteYnOptions?.map((item) => ({
		label: <CRStatus options={WRITE_COMPLETE_YN_CONFIG}>{item.label}</CRStatus>,
		value: item.value,
	}));

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='구분' type='left'>
						<CRCheckBoxGroup
							direction='col'
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={insuranceTypes}
						/>
					</CRInputLabel>
				)}
				name='workExecuteLogDivCd'
				control={form?.control}
			/>
			<CRInputLabel label='수급자 정보' type='left'>
				<InformationSheet
					align='flex-start'
					items={[
						[
							{
								label: '이름',
								value: recipient?.recipientNm || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '성별',
								value: recipient?.genderNm || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '생년월일',
								value: recipient?.birthDt || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정번호',
								value: recipient?.longTermNo || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정등급',
								value: recipient?.longTermGradeNm || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
					]}
					type='gray'
				/>
			</CRInputLabel>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='작성 완료 여부' type='left'>
						<CRInput.Selector
							onChangeValue={onChange}
							currentValue={value}
							items={writeCompleteYn}
							placeholder='작성 완료 여부 선택'
						/>
					</CRInputLabel>
				)}
				name='writeCompleteYn'
				control={form?.control}
			/>
		</S.Container>
	);
}

export default React.memo(WorkExecuteLogBasicInfoForm);
