import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import RenewalContractOwnExpenseForm from 'components/domain/form/RenewalContractOwnExpenseForm';
import RenewalContractDocumentForm from 'components/domain/form/RenewalContractDocumentForm';
import RenewalInspectionBeforeApprovalForm from 'components/domain/form/RenewalInspectionBeforeApprovalForm';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';
import { useCenterManagers, useMyAccountInfo } from 'lib/hook/react-query';

import * as S from './styles';

function RenewalContractInspectionPage(): React.ReactElement {
	const { form } = useContractRenewalTask();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({ centerId: myAccountInfo?.centerId });

	const forms = [
		{
			id: '#1',
			label: '본인부담금 정보',
			component: <RenewalContractOwnExpenseForm />,
		},
		{
			id: '#2',
			label: '발송',
			component: <RenewalContractDocumentForm />,
		},
		{
			id: '#3',
			label: '점검',
			component: <RenewalInspectionBeforeApprovalForm />,
		},
	];

	return (
		<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
			{forms.map((form) => (
				<Accordion.Item value={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
			{!!form.watch('recipient.socialWorkerId') && (
				<S.LastCheckContainer>
					{`${
						(centerManagers ?? []).find(
							(item) => item.memberAccountId === form.watch('recipient.socialWorkerId'),
						)?.centerNm
					} ${
						(centerManagers ?? []).find(
							(item) => item.memberAccountId === form.watch('recipient.socialWorkerId'),
						)?.userNm
					} 사회복지사가 최종 확인하였습니다.\n${dayjs(
						form.watch('inspection.check.lastConfirmDate'),
					).format('YYYY.MM.DD')}`}
				</S.LastCheckContainer>
			)}
		</Accordion.Root>
	);
}

export default RenewalContractInspectionPage;
