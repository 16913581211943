import React from 'react';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';

import { EditableRecipientFormTypes } from 'lib/provider/recipient';
import * as S from './styles';
import RecipientBasicInfoView from './RecipientBasicInfoView';
import RecipientBasicInfoForm from './RecipientBasicInfoForm';

interface Props {
	onlyEdit?: boolean;
}

function RecipientBasicInfoFormView({ onlyEdit = false }: Props): React.ReactElement {
	const {
		currentRecipientBase,
		currentRecipient,
		isFormEditable,
		startChangeEditSection,
		isEditSectionChanging,
		cancelChangeEditSection,
	} = useRecipientPage();

	const onStartChangeSection = () => {
		startChangeEditSection(EditableRecipientFormTypes.기본정보);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableRecipientFormTypes.기본정보) || onlyEdit ? (
				<RecipientBasicInfoForm
					currentRecipient={currentRecipient}
					item={currentRecipientBase}
					onlyEdit={onlyEdit}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<RecipientBasicInfoView item={currentRecipientBase} onChangeMode={onStartChangeSection} />
			)}
		</S.Container>
	);
}

export default RecipientBasicInfoFormView;
