import CRAddressSearch from './CRAddressSearch';
import CRInput from './CRInputDefault';
import CRInputPassword from './CRInputPassword';
import CRButtonInput from './CRButtonInput';
import CRInputSearch from './CRInputSearch';
import CRTextArea from './CRTextArea';
import CRTimePicker from './CRTimePicker';
import CRTableFileUploader from './CRTableFileUploader';
import CRSelector from './CRSelector';
import CRFileUploader from './CRFileUploader';
import CRDateTimePicker from './CRDateTimePicker';
import CRSerialCode from './CRSerialCode';
import CRFileDownloader from './CRFileDownloader';
import CRDateRangePicker from './CRDateRangePicker';
import CRTableInput from './CRTableInput';
import CRDayTimePicker from './CRDayTimePicker';
import CRDatePicker from './CRDatePicker';
import CRSearchSelector from './CRSearchSelector';
import CRYearMonthPicker from './CRYearMonthPicker';
import CRTime from './CRTime';
import CRInputRectangle from './CRInputRectangle';
import CRMultiSelector from './CRMultiSelector';
import CRDayPicker from './CRDayPicker';
import CRTableSearchSelector from './CRTableSearchSelector';
import CRIpPermitter from './CRIpPermitter';

export default {
	Default: CRInput,
	Time: CRTime,
	TableInput: CRTableInput,
	ButtonInput: CRButtonInput,
	RectangleInput: CRInputRectangle,
	Search: CRInputSearch,
	SearchSelector: CRSearchSelector,
	TableSearchSelector: CRTableSearchSelector,
	Selector: CRSelector,
	MultiSelector: CRMultiSelector,
	TextArea: CRTextArea,
	Password: CRInputPassword,
	Address: CRAddressSearch,
	TimePicker: CRTimePicker,
	TableFileUploader: CRTableFileUploader,
	FileUploader: CRFileUploader,
	FileDownloader: CRFileDownloader,
	DatePicker: CRDatePicker,
	DateTimePicker: CRDateTimePicker,
	DayTimePicker: CRDayTimePicker,
	DateRangePicker: CRDateRangePicker,
	SerialCode: CRSerialCode,
	YearMonthPicker: CRYearMonthPicker,
	DayPicker: CRDayPicker,
	IPPermitter: CRIpPermitter,
};
