import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MenuIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 1.2rem;
`;

export const Title = styled.div`
	${(props) => props.theme.typography.label}
	flex:1;
	display: flex;
	height: 4.8rem;
	align-items: center;
`;

export const Container = styled.a`
	cursor: pointer;
	color: ${(props) => props.theme.colors.gray10};
	display: flex;
	height: 4.8rem;
	align-items: center;
	padding: 0 1.6rem;
	border-radius: ${(props) => props.theme.radius.radius01};
	text-decoration: none;

	&:hover {
		background-color: ${(props) => props.theme.colors.gray100};
	}

	&.active > ${MenuIcon} {
		filter: invert(46%) sepia(20%) saturate(5945%) hue-rotate(323deg) brightness(103%)
			contrast(102%);
	}

	&.active > ${Title} {
		color: ${(props) => props.theme.colors.primary60};
	}
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;
