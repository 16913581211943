import React, { ReactNode } from 'react';
import * as S from './styles';

interface Props {
	textAlign?: 'left' | 'center' | 'right';
	items: { width?: string; colSpan?: number; children: ReactNode }[];
	showBorder?: boolean;
}

function CRRowTitle({ items, textAlign, showBorder }: Props) {
	return (
		<S.Container>
			{items.map(({ colSpan = 1, children, width = '100%' }, index) => (
				<S.Wrapper
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					$textAlign={textAlign}
					width={width}
					colSpan={colSpan}
					$showBorder={showBorder}>
					{children}
				</S.Wrapper>
			))}
		</S.Container>
	);
}

export default React.memo(CRRowTitle);
