import { CRStatusConfig, CRTableHeadConfig } from '../../../../types/view/base';

export const RECIPIENT_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.2rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '7rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '9.6rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '6.2rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '100%',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];
