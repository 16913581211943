import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import React, { useMemo, useState } from 'react';
import { Filter } from 'types/view/filter';
import {
	useCommonCodes,
	useMessageTabReservationHistory,
	useTemplateList,
} from 'lib/hook/react-query';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import dayjs from 'dayjs';
import { MessageTabReservationHistoryDTO } from 'types/api/send';
import EmployeeReservationTable from 'components/domain/table/EmployeeReservationTable';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import * as S from './styles';

export default function ReservationMessageTab() {
	const [searchValue, setSearchValue] = useState<string>('');
	const { currentEmployee } = useEmployeePage();
	const navigate = useNavigate();

	const [currentFilter, setCurrentFilter] = useState<{
		rangeFilter: CheckOption[];
		templateTypeFilter: CheckOption[];
		sendStatusFilter: CheckOption[];
	}>({
		rangeFilter: [],
		templateTypeFilter: [],
		sendStatusFilter: [],
	});

	const { data: reservationHistory, isLoading } = useMessageTabReservationHistory({
		type: 'employees',
		id: currentEmployee?.employeeId,
		params: {
			sendStartDate:
				currentFilter.rangeFilter?.[0]?.value?.start &&
				dayjs(currentFilter.rangeFilter[0].value.start).format('YYYY-MM-DDT00:00:00'),
			sendEndDate:
				currentFilter.rangeFilter?.[0]?.value?.end &&
				dayjs(currentFilter.rangeFilter[0].value.end).format('YYYY-MM-DDT23:59:59'),
			sendStateCds: currentFilter.sendStatusFilter?.map((item) => item.value).join(', '),
			sendTemplateIds: currentFilter.templateTypeFilter?.map((item) => item.value).join(', '),
		},
	});

	const reservationHistoryData = useMemo(
		() =>
			(reservationHistory || [])?.filter((item) =>
				item.sendTitle.toLowerCase().includes(searchValue.toLowerCase()),
			),
		[reservationHistory, searchValue],
	);

	const { data: templateList } = useTemplateList();
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN028'] });

	const rangeFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'calendar',
				placeholder: '발송 예정 일시',
			} as Filter<CheckOption>),
		[],
	);

	const templateTypeFilter = useMemo(
		() =>
			({
				key: 'templateTypeFilter',
				type: 'multi',
				options: (templateList ?? []).map((item) => ({
					label: item.sendTemplateTitle,
					value: item.sendTemplateId,
					data: item,
				})),
				placeholder: '템플릿',
			} as Filter<CheckOption>),
		[templateList],
	);

	const sendStatusFilter = useMemo(
		() =>
			({
				key: 'sendStatusFilter',
				type: 'multi',
				options: (commonCodes?.filter((item) => item.comCdGroupNm === 'CMN028') || [])?.map(
					(item) => ({
						label: item.korComCdAliasNm,
						value: item.comCdId,
					}),
				),
				placeholder: '발송 상태',
			} as Filter<CheckOption>),
		[commonCodes],
	);

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleClickRow = (item: MessageTabReservationHistoryDTO) => {
		navigate(`${RouterPath.send().history.key}/${item.sendId}/detail`);
	};

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={[rangeFilter, templateTypeFilter, sendStatusFilter]}
				placeholder='제목으로 검색'
				searchValue={searchValue}
				onChangeSearchValue={handleSearchKeyword}
			/>
			<S.TableContainer>
				<EmployeeReservationTable
					isLoading={isLoading}
					items={reservationHistoryData}
					onClickRow={handleClickRow}
				/>
			</S.TableContainer>
		</S.Container>
	);
}
