import { styled } from 'styled-components';

export const Container = styled.div``;

export const AccordionContainer = styled.div`
	padding: 1.6rem 0;
	border-radius: 0.8rem;
	background-color: ${(props) => props.theme.colors.gray99};
`;

export const CenterItem = styled.div`
	padding: 1.6rem;
	box-sizing: border-box;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};

	margin-top: 1.6rem;
`;

export const Title = styled.p`
	${(props) => props.theme.typography.bodyB}
	margin-bottom: 0.8rem;
`;
