import styled from 'styled-components';
import color from 'common/colors';

export const ProgressContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

export const ProgressBarContainer = styled.div<{
	$background?: keyof typeof color;
	$height?: string;
}>`
	position: relative;
	width: 100%;
	height: ${(p) => p.$height || '0.8rem'};
	background: ${(props) => props.theme.colors[props.$background || 'gray90']};
	border-radius: 0.4rem;
`;

export const ProgressBarPercentage = styled.div<{
	percentage?: number;
	$background?: keyof typeof color;
}>`
	position: absolute;
	width: ${(props) => `${props.percentage}%`};
	height: 100%;
	background: ${(props) => props.theme.colors[props.$background || 'green']};
	border-radius: 0.4rem;
`;

export const ProgressBarLabel = styled.span`
	width: 6rem;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	text-align: right;
`;
