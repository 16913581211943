import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import Assets from 'assets';

import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import * as S from './styles';

export enum DayOfTheWeek {
	Sun = 'CMN020.0',
	Mon = 'CMN020.1',
	Tue = 'CMN020.2',
	Wed = 'CMN020.3',
	Thu = 'CMN020.4',
	Fri = 'CMN020.5',
	Sat = 'CMN020.6',
}

interface IProps {
	selectedDayOfTheWeeks?: DayOfTheWeek[];
	start?: string;
	end?: string;
	disabled?: boolean;
	disableDelete?: boolean;
	placeHolder1?: string;
	placeHolder2?: string;
	type?: 'default' | 'day' | 'time';
	onChange?: (dayOfTheWeeks: DayOfTheWeek[], startTime?: string, endTime?: string) => void;
	onDelete?: () => void;
}

function CRTimePicker({
	selectedDayOfTheWeeks = [],
	start = '',
	end = '',
	disabled = false,
	disableDelete = false,
	placeHolder1 = '00:00',
	placeHolder2 = '00:00',
	type = 'default',
	onChange,
	onDelete,
}: IProps): React.ReactElement {
	const startTimeInput = useRef<HTMLInputElement>(null);
	const endTimeInput = useRef<HTMLInputElement>(null);

	const [startTime, setStartTime] = useState(start);
	const [endTime, setEndTime] = useState(end);

	const handleChangeDayOfWeeks = (dayOfWeek: DayOfTheWeek) => {
		if (selectedDayOfTheWeeks?.includes(dayOfWeek)) {
			onChange?.(
				selectedDayOfTheWeeks?.filter((selectedDayOfTheWeek) => selectedDayOfTheWeek !== dayOfWeek),
				startTime,
				endTime,
			);
		} else {
			onChange?.([...selectedDayOfTheWeeks, dayOfWeek], startTime, endTime);
		}
	};

	const handleClickWeekDay = () => {
		if (
			[
				DayOfTheWeek.Mon,
				DayOfTheWeek.Tue,
				DayOfTheWeek.Wed,
				DayOfTheWeek.Thu,
				DayOfTheWeek.Fri,
			].every((item) => selectedDayOfTheWeeks.includes(item))
		) {
			onChange?.(
				selectedDayOfTheWeeks?.filter(
					(selectedDayOfTheWeek) =>
						![
							DayOfTheWeek.Mon,
							DayOfTheWeek.Tue,
							DayOfTheWeek.Wed,
							DayOfTheWeek.Thu,
							DayOfTheWeek.Fri,
						].includes(selectedDayOfTheWeek),
				),
				startTime,
				endTime,
			);
		} else {
			onChange?.(
				_.uniq([
					DayOfTheWeek.Mon,
					DayOfTheWeek.Tue,
					DayOfTheWeek.Wed,
					DayOfTheWeek.Thu,
					DayOfTheWeek.Fri,
					...selectedDayOfTheWeeks,
				]),
				startTime,
				endTime,
			);
		}
	};

	const handleClickWeekend = () => {
		if (
			[DayOfTheWeek.Sat, DayOfTheWeek.Sun].every((item) => selectedDayOfTheWeeks.includes(item))
		) {
			onChange?.(
				selectedDayOfTheWeeks?.filter(
					(selectedDayOfTheWeek) =>
						![DayOfTheWeek.Sat, DayOfTheWeek.Sun].includes(selectedDayOfTheWeek),
				),
				startTime,
				endTime,
			);
		} else {
			onChange?.(
				_.uniq([DayOfTheWeek.Sat, DayOfTheWeek.Sun, ...selectedDayOfTheWeeks]),
				startTime,
				endTime,
			);
		}
	};

	const handleChangeStartTime = (event: ChangeEvent<HTMLInputElement>) => {
		const numericOnly = event.target.value.replace(/\D/g, '');
		let formattedTime = numericOnly;

		if (numericOnly.length > 2) {
			formattedTime = `${numericOnly.substring(0, 2)}:${numericOnly.substring(2)}`;
		}
		// 시간 제한: 24시 이하, 분 제한: 60분 이하
		if (parseInt(numericOnly.substring(0, 2), 10) > 23) {
			formattedTime = '00:';
		}
		if (parseInt(numericOnly.substring(2), 10) > 59) {
			formattedTime = `${numericOnly.substring(0, 2)}:00`;
		}

		const done = formattedTime.length === 5;
		if (done) {
			endTimeInput.current?.focus();
		}
		setStartTime(formattedTime);
		onChange?.(selectedDayOfTheWeeks, formattedTime, endTime);
	};

	const handleChangeEndTime = (event: ChangeEvent<HTMLInputElement>) => {
		const numericOnly = event.target.value.replace(/\D/g, '');
		let formattedTime = numericOnly;

		if (numericOnly.length > 2) {
			formattedTime = `${numericOnly.substring(0, 2)}:${numericOnly.substring(2)}`;
		}
		// 시간 제한: 24시 이하, 분 제한: 60분 이하
		if (parseInt(numericOnly.substring(0, 2), 10) > 23) {
			formattedTime = '00:';
		}
		if (parseInt(numericOnly.substring(2), 10) > 59) {
			formattedTime = `${numericOnly.substring(0, 2)}:00`;
		}
		setEndTime(formattedTime);
		onChange?.(selectedDayOfTheWeeks, startTime, formattedTime);
	};

	const displayDayOfWeek = (dayOfWeek: DayOfTheWeek) => {
		switch (dayOfWeek) {
			case DayOfTheWeek.Mon:
				return '월';
			case DayOfTheWeek.Tue:
				return '화';
			case DayOfTheWeek.Wed:
				return '수';
			case DayOfTheWeek.Thu:
				return '목';
			case DayOfTheWeek.Fri:
				return '금';
			case DayOfTheWeek.Sat:
				return '토';
			case DayOfTheWeek.Sun:
				return '일';
			default:
				return '';
		}
	};

	useEffect(() => {
		setStartTime(start);
	}, [start]);

	useEffect(() => {
		setEndTime(end);
	}, [end]);

	return (
		<S.Container $type={type}>
			{['default', 'day'].includes(type) && (
				<S.ActionContainer>
					<S.DayOfWeeksContainer>
						{[
							DayOfTheWeek.Mon,
							DayOfTheWeek.Tue,
							DayOfTheWeek.Wed,
							DayOfTheWeek.Thu,
							DayOfTheWeek.Fri,
							DayOfTheWeek.Sat,
							DayOfTheWeek.Sun,
						].map((item) => (
							<S.DayOfWeekContainer
								$isDisabled={disabled}
								key={item}
								onClick={() => !disabled && handleChangeDayOfWeeks(item)}
								$isSelected={selectedDayOfTheWeeks?.includes(item)}>
								{displayDayOfWeek(item)}
							</S.DayOfWeekContainer>
						))}
						<S.WeekContainer
							$isSelected={[
								DayOfTheWeek.Mon,
								DayOfTheWeek.Tue,
								DayOfTheWeek.Wed,
								DayOfTheWeek.Thu,
								DayOfTheWeek.Fri,
							].every((item) => selectedDayOfTheWeeks.includes(item))}
							onClick={() => !disabled && handleClickWeekDay()}>
							평일
						</S.WeekContainer>
						<S.WeekContainer
							$isSelected={[DayOfTheWeek.Sat, DayOfTheWeek.Sun].every((item) =>
								selectedDayOfTheWeeks.includes(item),
							)}
							onClick={() => !disabled && handleClickWeekend()}>
							주말
						</S.WeekContainer>
					</S.DayOfWeeksContainer>
					{!disabled && (
						<CRButton.Default
							type='outlined'
							palette='gray'
							size='xSmall'
							disabled={disableDelete}
							onClick={() => {
								onDelete?.();
							}}>
							삭제
						</CRButton.Default>
					)}
				</S.ActionContainer>
			)}
			{['default', 'time'].includes(type) && (
				<S.TimeContainer $isDisabled={disabled}>
					<FlexContainer justify='flex-start' align='center' width='100%'>
						<S.TimeTextInput
							maxLength={5}
							disabled={disabled}
							ref={startTimeInput}
							placeholder={placeHolder1}
							value={startTime}
							onChange={handleChangeStartTime}
						/>
						<S.TimeTextDivider>~</S.TimeTextDivider>
						<S.TimeTextInput
							maxLength={5}
							disabled={disabled}
							ref={endTimeInput}
							placeholder={placeHolder2}
							value={endTime}
							onChange={handleChangeEndTime}
						/>
					</FlexContainer>
					<S.TimeIcon
						src={type === 'time' ? Assets.icon.accessTimeBlack : Assets.icon.accessTime}
					/>
				</S.TimeContainer>
			)}
		</S.Container>
	);
}

export default React.memo(CRTimePicker);
