import _ from 'lodash';

export function checkEmptyValues(data: any): any {
	if (Array.isArray(data)) {
		if (data.length === 0) {
			return null;
		}

		return data.map((item) => checkEmptyValues(item));
	}

	if (typeof data === 'object' && data !== null) {
		const keys = Object.keys(data);
		if (keys.length === 0) {
			return null;
		}

		if (keys.every((k) => data[k] === null || data[k] === undefined)) {
			return null;
		}

		const result: { [key: string]: any } = {};
		keys.forEach((key) => {
			result[key] = checkEmptyValues(data[key]);
		});

		return result;
	}

	return data;
}

/**
 * 숫자 형태의 문자열은 문자열로 판별하기 위함.
 * ex) 전화번호처럼 0으로 시작하는 경우
 * @param value
 * @returns
 */
const isInteger = (value?: any) => {
	const numberValue = Number(value);
	if (!Number.isInteger(numberValue)) {
		return false;
	}
	return `${numberValue}` === `${value}`;
};

export function groupIntegratedAssessment(data: { [key: string]: string | boolean }): {
	[key: string]: any;
} {
	const keys = Object.keys(data);
	const newObject: { [key: string]: any } = {};
	keys.forEach((key) => {
		const splittedKeys = key.split('.');
		if (splittedKeys.length > 1) {
			const [key, value] = splittedKeys;
			if (newObject[key]) {
				newObject[key] = [...newObject[key], value];
				return;
			}
			newObject[key] = [value];
			return;
		}

		if (isInteger(data[key])) {
			newObject[key] = Number(data[key]);
			return;
		}

		newObject[key] = ['true', 'false'].includes(data[key] as string)
			? Boolean(data[key] === 'true')
			: data[key];
	});

	return newObject;
}

export function flattenIntegratedAssessment(
	data: { [key: string]: any },
	previousData: { [key: string]: any },
	exceptList: string[] = [],
): {
	[key: string]: string | boolean | null;
} {
	const keys = _.uniq(
		[...Object.keys(previousData), ...Object.keys(data)].filter(
			(item) => !exceptList?.includes(item),
		),
	);
	const newObject: { [key: string]: string | boolean | null } = {};
	keys.forEach((key) => {
		if (Array.isArray(previousData[key]) || Array.isArray(data[key])) {
			_.uniq([...(previousData[key] ?? []), ...(data[key] ?? [])] as string[]).forEach((item) => {
				if (data[key]?.includes(item)) {
					newObject[`${key}.${item}`] = true;
				} else {
					newObject[`${key}.${item}`] = null;
				}
			});
			return;
		}

		if (previousData[key] && !data[key]) {
			newObject[key] = null;
		} else {
			newObject[key] = data[key] as string;
		}
	});

	return newObject;
}

export function flattenKmmseIntegratedAssessment(
	data: { [key: string]: any },
	exceptList: string[] = [],
): {
	[key: string]: string | boolean;
} {
	const keys = Object.keys(data).filter((item) => !exceptList.includes(item));
	const newObject: { [key: string]: string | boolean } = {};
	keys.forEach((key) => {
		if (!data[key]) return;
		Object.keys(data[key]).forEach((item) => {
			if (data[key][item]?.value) {
				newObject[`${key}.${item}.값`] = data[key][item]?.value;
			}
			if (data[key][item]?.checked !== null) {
				newObject[`${key}.${item}.인정여부`] = data[key][item]?.checked;
			}
		});
	});

	return newObject;
}

export function groupKmmseIntegratedAssessment(data: { [key: string]: string | boolean }): {
	[key: string]: any;
} {
	const keys = Object.keys(data);
	let newObject: { [key: string]: any } = {};
	keys.forEach((key) => {
		const splittedKeys = key.split('.');
		if (splittedKeys.length > 1) {
			const [newKey, item, property] = splittedKeys;
			const propertyKey = property === '값' ? 'value' : 'checked';
			const value = ['true', 'false'].includes(data[key] as string)
				? data[key] === 'true'
				: data[key];
			newObject = {
				...newObject,
				[newKey]: {
					...newObject[newKey],
					[item]: { ...newObject[newKey]?.[item], [propertyKey]: value },
				},
			};
			return;
		}
	});

	return newObject;
}

export function structuredIntegratedAssessment(data: { [k: string]: string | boolean | null }) {
	return {
		일반상태: {
			영양상태: data['영양상태'] || '',
			식사형태: {
				일반식: data['식사형태.일반식'] || false,
				다진식: data['식사형태.다진식'] || false,
				죽: data['식사형태.죽'] || false,
				미음: data['식사형태.미음'] || false,
				경관식: data['식사형태.경관식'] || false,
				기타: data['식사형태.기타'] || false,
				기타_설명: data['식사형태기타'] || '',
			},
			소화상태: {
				저작곤란: data['소화상태.저작곤란'] || false,
				소화불량: data['소화상태.소화불량'] || false,
				오심_구토: data['소화상태.오심/구토'] || false,
				기타: data['소화상태.기타'] || false,
				기타_설명: data['소화상태기타'] || '',
			},
			연하상태: data['연하상태'] || '',
			구강상태: data['구강상태'] || '',
			소변상태: {
				소변상태_양호: data['소변상태.양호'] || false,
				소변상태_요실금: data['소변상태.요실금'] || false,
				소변상태_배뇨곤란: data['소변상태.배뇨곤란'] || false,
				소변상태_기저귀: data['소변상태.기저귀'] || false,
				소변상태_유치도뇨_방관루: data['소변상태.유치도뇨/방관루'] || false,
			},
			대변상태: {
				기저귀: data['대변상태.기저귀'] || false,
				변비: data['대변상태.변비'] || false,
				양호: data['대변상태.양호'] || false,
				장루: data['대변상태.장루'] || false,
				지속적인_설사: data['대변상태.지속적인 설사'] || false,
			},
			보행: {
				보행: data['보행'] || '',
				지팡이: data['보행상태.지팡이'] || false,
				보행기: data['보행상태.보행기'] || false,
				휠체어: data['보행상태.휠체어'] || false,
				기타_설명: data['보행기타'] || '',
			},
			치매: {
				치매: data['치매'] || '',
				치매기타: data['치매기타'] || '',
				증상: {
					배회: data['치매증상.배회'] || false,
					야간수면장애: data['치매증상.야간수면장애'] || false,
					망각_환각: data['치매증상.망각 ・ 환각'] || false,
					폭력성: data['치매증상.폭력성'] || false,
					우울_불안: data['치매증상.우울 ・ 불안'] || false,
					거부: data['치매증상.거부'] || false,
					성적행동: data['치매증상.성적행동'] || false,
					기타: data['치매증상.기타'] || false,
					기타_설명: data['치매증상기타'] || '',
				},
			},
			시력상태: data['시력상태'] || '',
			청력상태: data['청력상태'] || '',
			판단근거: data['일반상태판단근거'] || '',
		},
		주요질병상태: {
			만성질환: {
				당뇨: data['만성질환.당뇨'] || false,
				고혈압: data['만성질환.고혈압'] || false,
				만성호흡기질환: data['만성질환.만성호흡기질환'] || false,
				암: data['만성질환.암'] || false,
				암_설명: data['만성질환암'] || '',
			},
			순환기계: {
				뇌경색: data['순환기계.뇌경색'] || false,
				뇌출혈: data['순환기계.뇌출혈'] || false,
				협심증: data['순환기계.협심증'] || false,
				심근경색증: data['순환기계.심근경색증'] || false,
				기타: data['순환기계.기타'] || false,
				기타_설명: data['순환기계기타'] || '',
			},
			신경계: {
				치매: data['신경계.치매'] || false,
				파킨슨병: data['신경계.파킨슨병'] || false,
				간질: data['신경계.간질'] || false,
				기타: data['신경계.기타'] || false,
				기타_설명: data['신경계기타'] || '',
			},
			근골격계: {
				관절염: data['근골격계.관절염'] || false,
				요골_좌골통: data['근골격계.요골/좌골통'] || false,
				골절_등_후유증: data['근골격계.골절 등 후유증'] || false,
				기타: data['근골격계.기타'] || false,
				기타_설명: data['근골격계기타'] || '',
			},
			정신행동장애: {
				우울증: data['정신행동장애.우울증'] || false,
				수면장애: data['정신행동장애.수면장애'] || false,
				정신질환: data['정신행동장애.정신질환'] || false,
				기타: data['정신행동장애.기타'] || false,
				기타_설명: data['정신행동장애기타'] || '',
			},
			호흡기계: {
				호흡곤란: data['호흡기계.호흡곤란'] || false,
				결핵: data['호흡기계.결핵'] || false,
				기타: data['호흡기계.기타'] || false,
				기타_설명: data['호흡기계기타'] || '',
			},
			만성신장질환: {
				만성신부전증: data['만성신장질환.만성신부전증'] || false,
				복막투석: data['만성신장질환.복막투석'] || false,
				혈액투석: data['만성신장질환.혈액투석'] || false,
				기타: data['만성신장질환.기타'] || false,
				기타_설명: data['만성신장질환기타'] || '',
			},
			기타질환: {
				알레르기: data['기타질환.알레르기'] || false,
				식품: data['기타질환.식품'] || false,
				기타: data['기타질환.기타'] || false,
				기타_설명: data['기타질환기타'] || '',
			},
			판단근거: data['주요질병상태판단근거'] || '',
		},
		신체상태: {
			체위변경하기: data['체위변경하기'] || '',
			일어나앉기: data['일어나앉기'] || '',
			일어서기: data['일어서기'] || '',
			이동: data['이동'] || '',
			실내보행: data['실내보행'] || '',
			휠체어이동: data['휠체어이동'] || '',
			근거리외출: data['근거리외출'] || '',
			식사하기: data['식사하기'] || '',
			세수하기: data['세수하기'] || '',
			양치질: data['양치질'] || '',
			옷벗고입기: data['옷벗고입기'] || '',
			화장실사용: data['화장실사용'] || '',
			기저귀갈기: data['기저귀갈기'] || '',
			목욕하기: data['목욕하기'] || '',
			몸단장하기: data['몸단장하기'] || '',
			식사준비: data['식사준비'] || '',
			청소하기: data['청소하기'] || '',
			빨래하기: data['빨래하기'] || '',
			약챙겨먹기: data['약챙겨먹기'] || '',
			전화사용: data['전화사용'] || '',
			교통수단이용: data['교통수단이용'] || '',
			판단근거: data['신체상태판단근거'] || '',
		},
		재활상태: {
			운동장애: {
				우측상지: data['운동장애.우측상지'] || false,
				좌측상지: data['운동장애.좌측상지'] || false,
				우측하지: data['운동장애.우측하지'] || false,
				좌측하지: data['운동장애.좌측하지'] || false,
			},
			관절구축: {
				어깨관절_좌_우: data['관절구축.어깨관절(좌/우)'] || false,
				팔꿈치관절_좌_우: data['관절구축.팔꿈치관절(좌/우)'] || false,
				손목_및_수지관절_좌_우: data['관절구축.손목 및 수지관절(좌/우)'] || false,
				고관절_좌_우: data['관절구축.고관절(좌/우)'] || false,
				무릎관절_좌_우: data['관절구축.무릎관절(좌/우)'] || false,
				발목관절_좌_우: data['관절구축.발목관절(좌/우)'] || false,
			},
			보행장애: {
				낙상경험: {
					매일: data['보행장애낙상경험.매일'] || false,
					주_1회이상: data['보행장애낙상경험.주 1회이상'] || false,
					월_1회이상: data['보행장애낙상경험.월 1회이상'] || false,
					가끔: data['보행장애낙상경험.가끔'] || false,
				},
				걸음걸이: {
					서거나_걸을_때_균형을_유지하지_못함:
						data['보행장애걸음걸이.서거나 걸을 때 균형을 유지하지 못함'] || false,
					일어서거나_걸을_때_어지러움:
						data['보행장애걸음걸이.일어서거나 걸을 때 어지러움'] || false,
					보조도구나_부축해서_걷기: data['보행장애걸음걸이.보조도구나 부축해서 걷기'] || false,
				},
			},
			판단근거: data['재활상태판단근거'] || '',
		},
		간호처치상태: {
			호흡: {
				기관지_절개관_간호: data['호흡.기관지 절개관 간호'] || false,
				흡인: data['호흡.흡인'] || false,
				산소요법: data['호흡.산소요법'] || false,
				기타: data['호흡.기타'] || false,
				기타_설명: data['호흡기타'] || '',
			},
			영양: {
				비위관: data['영양.비위관'] || false,
				위관: data['영양.위관'] || false,
				치료식이: data['영양.치료식이'] || false,
				기타: data['영양.기타'] || false,
				기타_설명: data['영양기타'] || '',
			},
			배설: {
				투석간호: data['배설.투석간호'] || false,
				유치도뇨관: data['배설.유치도뇨관'] || false,
				단순도뇨: data['배설.단순도뇨'] || false,
				방광루: data['배설.방광루'] || false,
				장루간호: data['배설.장루간호'] || false,
			},
			상처: {
				상처간호: data['상처.상처간호'] || false,
				당뇨발간호: data['상처.당뇨발간호'] || false,
				기타: data['상처.기타'] || false,
				기타_설명: data['상처기타'] || '',
			},
			욕창: {
				단계: {
					'1단계': data['욕창단계.1단계'] || false,
					'2단계': data['욕창단계.2단계'] || false,
					'3단계': data['욕창단계.3단계'] || false,
					'4단계': data['욕창단계.4단계'] || false,
				},
				부위: {
					머리: data['욕창부위.머리'] || false,
					등: data['욕창부위.등'] || false,
					어깨: data['욕창부위.어깨'] || false,
					팔꿈치: data['욕창부위.팔꿈치'] || false,
					엉덩이: data['욕창부위.엉덩이'] || false,
					뒤꿈치: data['욕창부위.뒤꿈치'] || false,
					기타: data['욕창부위.기타'] || false,
					기타_설명: data['욕창부위기타'] || '',
				},
			},
			통증: {
				암: {
					폐: data['암발생부위.폐'] || false,
					위: data['암발생부위.위'] || false,
					대장: data['암발생부위.대장'] || false,
					간: data['암발생부위.간'] || false,
					전립선: data['암발생부위.전립선'] || false,
					유방: data['암발생부위.유방'] || false,
					담낭_및_기타_담도: data['암발생부위.담낭 및 기타 담도'] || false,
					기타: data['암발생부위.기타'] || false,
					기타_설명: data['암발생부위기타'] || '',
				},
				일반: {
					머리: data['일반통증부위.머리'] || false,
					상지: data['일반통증부위.상지'] || false,
					하지: data['일반통증부위.하지'] || false,
					허리: data['일반통증부위.허리'] || false,
					등: data['일반통증부위.등'] || false,
					기타: data['일반통증부위.기타'] || false,
					기타_설명: data['일반통증부위기타'] || '',
				},
			},
			판단근거: data['간호처치상태판단근거'] || '',
		},
		인지상태: {
			지능및인지기능저하: {
				지능및인지기능저하: data['지능및인지기능저하.지능및인지기능저하'] || false,
				날짜_시간: data['지능및인지기능저하.날짜 ・ 시간'] || false,
				장소: data['지능및인지기능저하.장소'] || false,
				사람: data['지능및인지기능저하.사람'] || false,
			},
			기억력저하: {
				기억력저하: data['기억력저하.기억력저하'] || false,
				단기: data['기억력저하.단기'] || false,
				장기: data['기억력저하.장기'] || false,
			},
			주의집중력저하: data['주의집중력저하.주의집중력저하'] || false,
			계산력저하: data['계산력저하.계산력저하'] || false,
			판단력저하: data['판단력저하.판단력저하'] || false,
			부적절한옷입기: data['부적절한옷입기.부적절한옷입기'] || false,
			망상: data['망상.망상'] || false,
			배회: {
				배회: data['배회.배회'] || false,
				밖으로_나가려함: data['배회.밖으로 나가려함'] || false,
				의미없는_서성거림: data['배회.의미없는 서성거림'] || false,
				길_잃음: data['배회.길 잃음'] || false,
			},
			환각: {
				환각: data['환각.환각'] || false,
				환시: data['환각.환시'] || false,
				환청: data['환각.환청'] || false,
				환미: data['환각.환미'] || false,
				환촉: data['환각.환촉'] || false,
				기타: data['환각.기타'] || false,
				기타_설명: data['환각기타'] || '',
			},
			반복적인행동: data['반복적인행동.반복적인행동'] || false,
			부적절한행동: {
				부적절한행동: data['부적절한행동.부적절한행동'] || false,
				부적절한_성적행동: data['부적절한행동.부적절한 성적행동'] || false,
				부적절한_일반행동: data['부적절한행동.부적절한 일반행동'] || false,
			},
			폭력적인행동: {
				폭력적인행동: data['폭력적인행동.폭력적인행동'] || false,
				신체적인_공격: data['폭력적인행동.신체적인 공격'] || false,
				폭언: data['폭력적인행동.폭언'] || false,
				도움에_저항: data['폭력적인행동.도움에 저항'] || false,
			},
			야간수면장애: data['야간수면장애.야간수면장애'] || false,
			불결한행동: data['불결한행동.불결한행동'] || false,
			식습관변화: {
				식습관변화: data['식습관변화.식습관변화'] || false,
				식욕_저하: data['식습관변화.식욕 저하'] || false,
				식욕_증가: data['식습관변화.식욕 증가'] || false,
				기타: data['식습관변화.기타'] || false,
				기타_설명: data['식습관변화기타'] || '',
			},
			음식물아닌물건섭취: data['음식물아닌물건섭취.음식물아닌물건섭취'] || false,
			불안: {
				불안: data['불안.불안'] || false,
				혼자_남겨짐에_대한_공포: data['불안.혼자 남겨짐에 대한 공포'] || false,
				초조: data['불안.초조'] || false,
				안절부절: data['불안.안절부절'] || false,
				기타: data['불안.기타'] || false,
				기타_설명: data['불안기타'] || '',
			},
			우울: {
				우울: data['우울.우울'] || false,
				두려움: data['우울.두려움'] || false,
				무기력함: data['우울.무기력함'] || false,
				절망: data['우울.절망'] || false,
			},
			판단근거: data['인지상태판단근거'] || '',
		},
		의사소통: {
			의사소통능력: data['의사소통능력'] || '',
			발음능력: data['발음능력'] || '',
			판단근거: data['의사소통판단근거'] || '',
		},
		가족및지지체계: {
			동거가족: {
				독거: data['동거가족.독거'] || false,
				배우자: data['동거가족.배우자'] || false,
				부모: data['동거가족.부모'] || false,
				자녀: data['동거가족.자녀'] || false,
				자부_사위: data['동거가족.자부 ・ 사위'] || false,
				손자녀: data['동거가족.손자녀'] || false,
				친척: data['동거가족.친척'] || false,
				친구_이웃: data['동거가족.친구 ・ 이웃'] || false,
				기타: data['동거가족.기타'] || false,
				기타_설명: data['동거가족기타'] || '',
			},
			자녀: {
				아들: data['자녀수아들'] ? Number(data['자녀수아들']) : 0,
				딸: data['자녀수딸'] ? Number(data['자녀수딸']) : 0,
			},
			주수발자관계: {
				배우자: data['주수발자관계.배우자'] || false,
				자녀: data['주수발자관계.자녀'] || false,
				자부_사위: data['주수발자관계.자부 ・ 사위'] || false,
				손자녀: data['주수발자관계.손자녀'] || false,
				친척: data['주수발자관계.친척'] || false,
				친구_이웃: data['주수발자관계.친구 ・ 이웃'] || false,
				기타: data['주수발자관계.기타'] || false,
				기타_설명: data['주수발자관계기타'] || '',
			},
			주수발자경제상태: {
				안정: data['주수발자경제상태.안정'] || false,
				불안정: data['주수발자경제상태.불안정'] || false,
				연금생활: data['주수발자경제상태.연금생활'] || false,
				기초생활수급자: data['주수발자경제상태.기초생활수급자'] || false,
				의료급여: data['주수발자경제상태.의료급여'] || false,
			},
			주수발자부양부담: {
				전혀_부담되지_않음: data['주수발자부양부담.전혀 부담되지 않음'] || false,
				아주_가끔_부담됨: data['주수발자부양부담.아주 가끔 부담됨'] || false,
				가끔_부담됨: data['주수발자부양부담.가끔 부담됨'] || false,
				자주_부담됨: data['주수발자부양부담.자주 부담됨'] || false,
				항상_부담됨: data['주수발자부양부담.항상 부담됨'] || false,
			},
			진료병원: {
				진료병원: data['진료병원'] || '',
				정기진료: data['정기진료'] || false,
			},
			약복용: {
				일간: data['약복용일간'] ? Number(data['약복용일간']) : 0,
				주간: data['약복용주간'] ? Number(data['약복용주간']) : 0,
				약복용갯수: data['약복용갯수'] ? Number(data['약복용갯수']) : 0,
			},
			종교활동: {
				천주교: data['종교활동.천주교'] || false,
				기독교: data['종교활동.기독교'] || false,
				불교: data['종교활동.불교'] || false,
				기타: data['종교활동.기타'] || false,
				기타_설명: data['종교활동기타'] || '',
			},
			지역사회자원: {
				노인돌봄기본_서비스: data['지역사회자원.노인돌봄기본 서비스'] || false,
				노인돌봄_종합서비스: data['지역사회자원.노인돌봄 종합서비스'] || false,
				단기기사서비스: data['지역사회자원.단기기사서비스'] || false,
				독거노인사회관계활성화: data['지역사회자원.독거노인사회관계활성화'] || false,
				초기독거노인자립지원: data['지역사회자원.초기독거노인자립지원'] || false,
				가사간병: data['지역사회자원.가사간병'] || false,
				재가복지: data['지역사회자원.재가복지'] || false,
				급식_및_도시락배달: data['지역사회자원.급식 및 도시락배달'] || false,
				보건소사업: data['지역사회자원.보건소사업'] || false,
				개인간병인: data['지역사회자원.개인간병인'] || false,
				산업재해_간병인: data['지역사회자원.산업재해 간병인'] || false,
				치매안심센터: data['지역사회자원.치매안심센터'] || false,
				복지관_마을회관: data['지역사회자원.복지관(마을회관)'] || false,
				노인정: data['지역사회자원.노인정'] || false,
				이동서비스: data['지역사회자원.이동서비스'] || false,
				종교단체: data['지역사회자원.종교단체'] || false,
				이_미용: data['지역사회자원.이 ・ 미용'] || false,
				주거개선사업: data['지역사회자원.주거개선사업'] || false,
			},
			판단근거: data['가족및지지체계판단근거'] || '',
		},
		주거환경상태: {
			난방: data['난방'] || '',
			문턱: data['문턱'] || '',
			화장실: data['화장실'] || '',
			온수: data['온수'] || '',
			세면대여부: data['세면대여부'] || '',
			환기: data['환기'] || '',
			실내계단: data['실내계단'] || '',
			실외계단: data['실외계단'] || '',
			좌변기: data['좌변기'] || '',
			욕조여부: data['욕조여부'] || '',
			주방: data['주방'] || '',
			판단근거: data['주거환경상태판단근거'] || '',
		},
		복지용구: {
			이동변기: data['이동변기'] || false,
			성인용보행기: data['성인용보행기'] || false,
			안전손잡이: data['안전손잡이'] || false,
			미끄럼방지용품: data['미끄럼방지용품'] || false,
			수동휠체어: data['수동휠체어'] || false,
			전동침대: data['전동침대'] || false,
			수동침대: data['수동침대'] || false,
			배회감지기: data['배회감지기'] || false,
			실내경사로: data['실내경사로'] || false,
			실외경사로: data['실외경사로'] || false,
			판단근거: data['복지용구판단근거'] || '',
		},
		수급자및보호자개별욕구: {
			일상생활: {
				개인위생_세수_구강청결_몸씻기_등:
					data['일상생활.개인위생 (세수, 구강청결, 몸씻기 등)'] || false,
				식사하기_식사준비_식사도움: data['일상생활.식사하기 (식사준비, 식사도움)'] || false,
				화장실_이용하기_이동변기_기저귀_교환_등:
					data['일상생활.화장실 이용하기 (이동변기, 기저귀 교환 등)'] || false,
				이동도움_부축_보행기_휠체어_등:
					data['일상생활.이동도움 (부축, 보행기, 휠체어 등)'] || false,
				산책동행: data['일상생활.산책동행'] || false,
				병원진료_동행: data['일상생활.병원진료 동행'] || false,
				관공서_동행: data['일상생활.관공서 동행'] || false,
				여가활동_동행: data['일상생활.여가활동 동행'] || false,
				청소_주변정돈: data['일상생활.청소 ・ 주변정돈'] || false,
				세탁: data['일상생활.세탁'] || false,
				장보기: data['일상생활.장보기'] || false,
				기타: data['일상생활.기타'] || false,
				기타_설명: data['일상생활기타'] || '',
			},
			기능회복훈련: {
				신체기능훈련_연하훈련_관절운동_근력운동_팔운동_손가락운동_등:
					data['기능회복훈련.신체기능훈련 (연하훈련, 관절운동, 근력운동, 팔운동, 손가락운동 등)'] ||
					false,
				기본동작훈련_체위변경_일어나앉기_일어서기_서있기_옮겨앉기_보행_등:
					data[
						'기능회복훈련.기본동작훈련 (체위변경, 일어나앉기, 일어서기, 서있기, 옮겨앉기, 보행 등)'
					] || false,
				일상생활동작훈련_식사동작_양치동작_옷갈아입기_동작_화장실사용_등:
					data[
						'기능회복훈련.일상생활동작훈련 (식사동작, 양치동작, 옷갈아입기 동작, 화장실사용 등)'
					] || false,
				인지기능향상프로그램_회상훈련_감각활동_작업치료_등:
					data['기능회복훈련.인지기능향상프로그램 (회상훈련, 감각활동, 작업치료 등)'] || false,
				사회적응훈련_대중교통이용_문화체험_종교활동_등:
					data['기능회복훈련.사회적응훈련(대중교통이용, 문화체험, 종교활동 등)'] || false,
				기타: data['기능회복훈련.기타'] || false,
				기타_설명: data['기능회복훈련기타'] || '',
			},
			정서지원: {
				말벗_서비스: data['정서지원.말벗 서비스'] || false,
				여가_정서프로그램_개인활동_취미활동_요리활동_산책_등:
					data['정서지원.여가 ・ 정서프로그램 (개인활동, 취미활동, 요리활동, 산책 등)'] || false,
				정서지원_기타: data['정서지원.기타'] || false,
				기타_설명: data['정서지원기타'] || '',
			},
			가족수발경감: {
				신체적_부양부담_완화: data['가족수발경감.신체적 부양부담 완화'] || false,
				정신적_부양부담_완화: data['가족수발경감.정신적 부양부담 완화'] || false,
				경제적_부양부담_완화: data['가족수발경감.경제적 부양부담 완화'] || false,
			},
			판단근거: data['수급자및보호자개별욕구판단근거'] || '',
		},
	};
}
