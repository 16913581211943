import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '14.4rem',
		label: '일시',
		sortKey: '일시',
	},
	{
		width: '17.4rem',
		label: '수신자',
		sortKey: '수신자',
	},
	{
		width: '17.4rem',
		label: '전화번호',
		sortKey: '전화번호',
	},
	{
		width: '18.5rem',
		label: '문서 번호',
		sortKey: '문서 번호',
	},
	{
		width: '11.2rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const SEND_HISTORY_CONFIG: CRStatusConfig[] = [
	{
		key: 'CMN028.10',
		label: '발송전',
		color: 'blue',
	},
	{
		key: 'CMN028.20',
		label: '발송중',
		color: 'yellow',
	},
	{
		key: 'CMN028.30',
		label: '완료',
		color: 'green',
	},
	{
		key: 'CMN028.40',
		label: '재발송',
		color: 'blue',
	},
	{
		key: 'CMN028.99',
		label: '발송실패',
		color: 'red',
	},
];
