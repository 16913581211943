import React from 'react';
import * as color from 'common/colors';
import * as S from './styles';

interface IProps {
	percentage?: number;
	showPercentText?: boolean;
	barColor?: keyof typeof color.default;
	backgroundColor?: keyof typeof color.default;
	height?: string;
}

function CRProgressBar({
	percentage = 1,
	showPercentText = false,
	barColor,
	backgroundColor,
	height,
}: IProps) {
	const calcPercentage = (percentage: number) => {
		if (percentage > 1) {
			return 1;
		}
		if (percentage < 0) {
			return 0;
		}
		return percentage;
	};

	return (
		<S.ProgressContainer>
			<S.ProgressBarContainer $height={height} $background={backgroundColor}>
				<S.ProgressBarPercentage
					$background={barColor}
					percentage={calcPercentage(percentage) * 100}
				/>
			</S.ProgressBarContainer>
			{showPercentText && (
				<S.ProgressBarLabel>{`${(calcPercentage(percentage) * 100).toFixed(
					0,
				)}%`}</S.ProgressBarLabel>
			)}
		</S.ProgressContainer>
	);
}

export default CRProgressBar;
