import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const OWN_EXPENSE_MONTHLY_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '6%',
		maxWidth: '12rem',
		label: '연월',
		sortKey: '연월',
	},
	{
		width: '8%',
		maxWidth: '13.4rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '6%',
		maxWidth: '12rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '8%',
		maxWidth: '13.4rem',
		label: '주 보호자',
		sortKey: '주 보호자',
	},
	{
		width: '12%',
		maxWidth: '24rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '10%',
		maxWidth: '14.5rem',
		label: '수가 총액',
		sortKey: '수가 총액',
		textAlign: 'right',
	},
	{
		width: '10%',
		maxWidth: '14.5rem',
		label: '본인부담금',
		sortKey: '본인부담금',
		textAlign: 'right',
	},
	{
		width: '10%',
		maxWidth: '14.5rem',
		label: '공단청구금',
		sortKey: '공단청구금',
		textAlign: 'right',
	},
	{
		width: '10%',
		maxWidth: '14.5rem',
		label: '가산후 총액',
		sortKey: '가산후 총액',
	},
	{
		width: '6%',
		maxWidth: '12rem',
		label: '발송 상태',
		sortKey: '발송 상태',
	},
	{
		width: '6%',
		maxWidth: '12rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];

export const EMPLOYEE_SALARY_SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '성공',
		label: '성공',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
	{
		key: '발송중',
		label: '발송중',
		color: 'yellow',
	},
];
