import React from 'react';

import { SideNavigationSectionItem } from 'types/view/navigation';
import LeftSideNavigationSectionItem from './LeftSideNavigationSectionItem';
import * as S from './styles';

interface IProps {
	items?: SideNavigationSectionItem[];
	onClick?: (path: string) => void;
}

function CRLeftSideNavigation({ items = [], onClick }: IProps): React.ReactElement {
	return (
		<S.Container>
			{items?.map((item, index) => (
				<LeftSideNavigationSectionItem key={item.label ?? index} item={item} onClick={onClick} />
			))}
		</S.Container>
	);
}

export default React.memo(CRLeftSideNavigation);
