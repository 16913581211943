import React, { useEffect, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import {
	useCenterList,
	useCenterManagers,
	useChangeEmployeeContract,
	useChangeTempEmployeeContract,
	useCommonCodes,
	useContractChangeEmployeeDetail,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { commonCodeAdapter } from 'lib/adapter/common';
import { ContractChangeEmployeeDetailDTO } from 'types/dto';
import { ContractStatusType } from 'types/view/common';
import { Toast } from '../../../components/base/CRToast';

interface ContractChangeEmployeeContextType {
	form: UseFormReturn<ContractChangeEmployeeDetailDTO>;
	commonCodes?: {
		[key: string]: CheckOption[];
	};
	changeEmployee?: () => void;
	saveTempChangeEmployee?: (configs?: { disableToast?: boolean }) => void;
	validate?: (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => void;
	disabled?: boolean;
}

export const ContractChangeEmployeeTaskContext =
	React.createContext<ContractChangeEmployeeContextType | null>(null);

function ContractChangeEmployeeTaskProvider({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	const params = useParams<{ contractId?: string }>();
	const { showDialog } = useGlobalLayout();
	const { data: myAccountInfo } = useMyAccountInfo();
	const changeEmployeeContractMutation = useChangeEmployeeContract();
	const changeTempEmployeeContractMutation = useChangeTempEmployeeContract();

	const { data } = useContractChangeEmployeeDetail({
		serviceContractId: Number(params.contractId),
	});

	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes(
		{
			comCdGroupNms: [
				'CMN006',
				'CMN041',
				'CMN042',
				'CMN043',
				'CMN162',
				'CMN164',
				'CMN143',
				'CMN038',
				'CMN020',
				'CMN070',
				'CMN084',
				'CMN064',
				'CMN037',
				'CMN133',
				'CMN068',
				'CMN020',
				'CMN149',
				'CMN070',
				'CMN067',
				'CMN180',
			],
		},
		commonCodeAdapter,
	);

	const { data: centerManager } = useCenterManagers({ centerId: myAccountInfo?.centerId });
	const { data: centers } = useCenterList();

	const centerManagerOption = (centerManager ?? []).map(
		(item) => ({ label: item.userNm, value: item.memberAccountId } as CheckOption),
	);
	const centerOption = (centers ?? []).map(
		(item) => ({ label: item.centerNm, value: item.centerId } as CheckOption),
	);

	const form = useForm<ContractChangeEmployeeDetailDTO>();

	const validate = (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => {
		if (
			!form.getValues('recipient.longTerm.longTermNo') ||
			!form.getValues('recipient.longTerm.longTermMajorChangeNo') ||
			!form.getValues('recipient.longTerm.longTermMinorChangeNo')
		) {
			Toast.error('수급자 장기요양인정번호를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermGradeCd')) {
			Toast.error('수급자 장기요양인정등급을 입력해주세요');
			return false;
		}
		if (
			!form.getValues('recipient.longTerm.longTermStartDate') ||
			!form.getValues('recipient.longTerm.longTermEndDate')
		) {
			Toast.error('수급자 장기요양인정기간을 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.burdenRateCd')) {
			Toast.error('수급자 장기요양 본인부담률을 입력해주세요');
			return false;
		}
		if (
			form
				.getValues('recipient.longTerm.longTermServices')
				.some(
					(item) =>
						!item.longTermPayFee ||
						!item.serviceCnt ||
						!item.serviceCntStandardCd ||
						!item.serviceOfferTimeCd,
				)
		) {
			Toast.error('수급자 장기요양 급여한도를 입력해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermUsePlanFile')) {
			Toast.error('수급자 개인별 장기 요양 인정 이용 계획서를 등록해주세요');
			return false;
		}
		if (!form.getValues('recipient.longTerm.longTermCertiFile')) {
			Toast.error('수급자 개인별 장기 요양 인정서를 등록해주세요');
			return false;
		}

		if (
			!form.getValues('service').use?.monthPerWorkHourCnt ||
			!form.getValues('service').use?.serviceEndDate ||
			!form.getValues('service').use?.serviceStartDate ||
			!form.getValues('service').use?.serviceTimes.length
		) {
			Toast.error('급여 정보의 이용 정보를 등록해주세요');
			return false;
		}
		if (
			!form.getValues('service').associates?.recipientContractAssignDate ||
			form
				.getValues('service')
				.associates?.contractEmployees?.some(
					(employee) => !employee.employeeContractAssignDate || !employee.employeeId,
				)
		) {
			Toast.error('급여 정보의 계약 정보를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeNm)) {
			Toast.error('직원의 이름을 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeBasAddr)) {
			Toast.error('직원의 주소를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeDetailAddr)) {
			Toast.error('직원의 상세 주소를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeBirthDt)) {
			Toast.error('직원의 생년월일을 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.employeeRelCd)) {
			Toast.error('직원의 관계를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.tagMobilePhoneNo)) {
			Toast.error('직원의 전화번호를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => item.base.twoPhoneYn === undefined)) {
			Toast.error('직원의 투폰 여부를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('employees')
				.some((item) => item.base.twoPhoneYn && !item.base.subMobilePhoneNo)
		) {
			Toast.error('직원의 투폰 전화번호를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => item.base.dementiaEduCompleteYn === undefined)) {
			Toast.error('직원의 치매교육 여부를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => !item.base.disabilityGradeCd)) {
			Toast.error('직원의 장애 여부를 등록해주세요');
			return false;
		}
		if (form.getValues('employees').some((item) => item.base.tagCallRequestYn === undefined)) {
			Toast.error('직원의 태그콜 진행 여부를 등록해주세요');
			return false;
		}
		if (
			form
				.getValues('employees')
				.some((item) =>
					item.otherCorpWorks?.some(
						(otherCorpWork) =>
							!otherCorpWork.otherCorpNm ||
							!otherCorpWork.otherCorpPhoneNo ||
							!otherCorpWork.otherCorpWorkStartEndTimes.length,
					),
				)
		) {
			Toast.error('직원의 타근무를 등록해주세요');
			return false;
		}
		if (
			!config?.isCreateDocument &&
			form
				.getValues('employees')
				.some(
					(item) =>
						!(
							(item.salary?.salaryPayTypeCd && item.salary?.salaryPayTypeCd !== 'CMN133.10') ||
							(item.salary?.salaryAccountDepositorNm &&
								item.salary.salaryAccountNo &&
								item.salary.salaryAccountBankCd &&
								item.salary.salaryAccountValidYn)
						),
				)
		) {
			Toast.error('직원의 임금 정보를 등록해주세요');
			return false;
		}
		if (
			!(
				form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') &&
				(!(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10') ||
					(form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountNo') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransBankCd') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm') &&
						form.getValues('inspection.burdenAmt.burdenAmtAutotransAccountValidYn'))) &&
				(!(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.30') ||
					form.getValues('inspection.burdenAmt.burdenAmtPayDeductEmployeeId'))
			)
		) {
			Toast.error('점검의 본인부담금 정보를 등록해주세요');
			return false;
		}
		if (
			!config?.ignoreCheck &&
			!(
				form.getValues('inspection.check.salaryOfferPlanWriteYn') &&
				form.getValues('inspection.check.pcorpScheduleRegistYn') &&
				form.getValues('inspection.check.wardReportCompleteYn') &&
				form.getValues('inspection.check.hopeiumFormWriteYn') &&
				form.getValues('inspection.check.liabilityInsuranceRegistNeedYn') &&
				(form.getValues('inspection.burdenAmt.burdenAmtPayMethodCd') !== 'CMN041.10' ||
					form.getValues('inspection.check.autotransUploadYn')) &&
				form.getValues('inspection.check.insuranceRequestYn')
			)
		) {
			Toast.error('점검의 점검 체크를 등록해주세요');
			return false;
		}
		return true;
	};

	const changeEmployee = async () => {
		if (changeEmployeeContractMutation.isLoading) return;
		if (!validate()) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='직원 변경 완료'
				content='직원 변경을 완료하고 저장합니다.'
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
					callback: () => hideDialog(),
				}}
				successOption={{
					text: '확인',
					successCallback: async () => {
						try {
							await changeEmployeeContractMutation.mutateAsync({
								...form.getValues(),
								recipient: {
									...form.getValues('recipient'),
									longTerm: {
										...form.getValues('recipient.longTerm'),
										longTermNo: `L${form.getValues('recipient.longTerm.longTermNo')}`,
									},
								},
								employees: (form.getValues('employees') ?? [])?.map((item) => ({
									...item,
									base: {
										...item.base,
										joinDate: item.base.joinDate
											? dayjs(item.base.joinDate).format('YYYY-MM-DD HH:mm:ss')
											: null,
									},
								})),
								inspection: {
									...form.getValues('inspection'),
									check: {
										...form.getValues('inspection.check'),
										recipientIaWriteYn: false,
										longTermUsePlanPcorpBrowseRequestYn: false,
										admsnUsereqUsedescLocalgovSendYn: false,
										tagBeaconRequestYn: false,
										pcorpSalaryContractCancelYn: false,
										autotransUploadYn: false,
										wardEndReportCompleteYn: false,
										lastConfirmDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
										lastCheckerId: form.getValues('contractBaseInfo.socialWorkerId'),
									},
								},
							});
							hideDialog();
							Toast.success('직원 변경 계약 완료가 성공 하였습니다.');
							navigate(`/contract`);
						} catch (error: any) {
							Toast.error(error.message ?? '직원 변경 계약 완료가 실패하였습니다.');
							hideDialog();
						}
					},
				}}
			/>
		));
	};

	const saveTempChangeEmployee = async (configs?: { disableToast?: boolean }) => {
		try {
			if (changeTempEmployeeContractMutation.isLoading) return;
			await changeTempEmployeeContractMutation.mutateAsync({
				...form.getValues(),
				recipient: {
					...form.getValues('recipient'),
					longTerm: {
						...form.getValues('recipient.longTerm'),
						longTermNo: `L${form.getValues('recipient.longTerm.longTermNo')}`,
					},
				},
				employees: (form.getValues('employees') ?? [])?.map((item) => ({
					...item,
					base: {
						...item.base,
						joinDate: item.base.joinDate
							? dayjs(item.base.joinDate).format('YYYY-MM-DD HH:mm:ss')
							: null,
					},
				})),
			});

			if (configs?.disableToast) return;
			Toast.success('계약이 저장되었습니다.');
		} catch {
			if (configs?.disableToast) return;
			Toast.error('계약 저장에 실패하였습니다.');
		}
	};

	const disabled = useMemo(
		() =>
			[ContractStatusType.Complete, ContractStatusType.Cancel, ContractStatusType.Pending].includes(
				form.watch('contractBaseInfo.contractStateCd') as ContractStatusType,
			),
		[form.watch('contractBaseInfo.contractStateCd')],
	);

	const value = useMemo(
		() => ({
			form,
			commonCodes: { ...commonCodes, centerManagerOption, centerOption },
			changeEmployee,
			validate,
			saveTempChangeEmployee,
			disabled,
		}),
		[
			form,
			commonCodes,
			centerManagerOption,
			changeEmployee,
			saveTempChangeEmployee,
			disabled,
			validate,
		],
	);

	useEffect(() => {
		if (data) {
			form.reset({
				...data,
				recipient: data?.recipient
					? {
							...data?.recipient,
							longTerm: data.recipient?.longTerm
								? {
										...data.recipient?.longTerm,
										longTermNo: data.recipient?.longTerm.longTermNo?.replaceAll('L', ''),
								  }
								: undefined,
					  }
					: undefined,
				inspection: data?.inspection
					? {
							...data.inspection,
							check: data?.inspection?.check
								? {
										...data?.inspection?.check,
								  }
								: undefined,
					  }
					: undefined,
			});
		}
	}, [data]);

	return (
		<ContractChangeEmployeeTaskContext.Provider value={value}>
			{children}
		</ContractChangeEmployeeTaskContext.Provider>
	);
}

export default React.memo(ContractChangeEmployeeTaskProvider);
