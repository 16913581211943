import React, { useState } from 'react';

import Assets from 'assets';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRInput from 'components/base/CRInput';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { useEmployeeWage } from 'lib/hook/react-query';

import * as S from './styles';
import CRInputLabel from '../../../base/CRInputLabel';

interface Props {
	employeeName: string;
	config: {
		serviceContractId: number;
		employeeId: number;
		serviceTypeCd: string;
		monthPerWorkHourCnt: number;
	};
	onClose: () => void;
	onConfirm?: (wage: number | null) => void | Promise<void>;
}

function CheckHourlyWageDialog({
	employeeName = '',
	config,
	onClose,
	onConfirm,
}: Props): React.ReactElement {
	const [mode, setMode] = useState<'view' | 'edit'>('view');
	const [wage, setWage] = useState<number | null>(null);
	const isCenterChief = useHasPermission('센터장');
	const { data, isLoading } = useEmployeeWage(config);

	if (isLoading) return <CRSpinner />;

	const handleClickEdit = () => {
		setMode('edit');
		setWage(data?.serviceEmployeeWage ?? 0);
	};

	const handleClickConfirm = () => {
		onConfirm?.(wage);
	};

	const handleChangeWage = (value: string) => {
		setWage(Number(value));
	};

	return (
		<CRDialog
			onClickClose={onClose}
			title='계약 임금 확인'
			showCloseButton
			type='S'
			body={
				<S.Container>
					{mode === 'view' ? (
						<>
							<S.Description>
								{`계약 정보를 바탕으로 계산된 ${employeeName}님의 임금입니다.\n아래 임금으로 계약서를 발급 하시겠습니까?`}
							</S.Description>
							<S.WageContainer>
								<S.WageLabel>{data?.serviceUseTimeMinYn90 ? '90분 임금' : '계약 시급'}</S.WageLabel>
								<S.PriceLabel>{`${(
									data?.serviceEmployeeWage ?? 0
								).toLocaleString()}원`}</S.PriceLabel>
							</S.WageContainer>
							<S.InfoContainer>
								<S.InfoIcon src={Assets.icon.help} />
								<p>시급 수정이 필요하다면 센터장에게 요청해 주십시오.</p>
							</S.InfoContainer>
						</>
					) : (
						<CRInputLabel label='계약 시급' isRequired>
							<CRInput.Default
								type='number'
								value={wage?.toString()}
								onChange={handleChangeWage}
								suffix='원'
							/>
						</CRInputLabel>
					)}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<S.LeftButtonContainer>
						{isCenterChief && (
							<CRButton.Default type='outlined' palette='primary' onClick={handleClickEdit}>
								수정
							</CRButton.Default>
						)}
					</S.LeftButtonContainer>
					<FlexContainer gap='0.8rem'>
						<CRButton.Default type='text' palette='gray' onClick={onClose}>
							취소
						</CRButton.Default>
						<CRButton.Default onClick={handleClickConfirm}>
							{mode === 'view' ? '확인' : '확정'}
						</CRButton.Default>
					</FlexContainer>
				</S.ButtonContainer>
			}
		/>
	);
}

export default CheckHourlyWageDialog;
