import React, { useMemo } from 'react';

import CRDialog from 'components/base/CRDialog';
import CRStatus from 'components/base/CRStatus';
import { OwnExpenseUploadHistory } from 'types/view/ownExpense';

import * as S from './styles';

interface Props {
	item: OwnExpenseUploadHistory;
	onClickClose?: () => void;
}

function OwnExpenseUploadHistoryDetailDialog({ item, onClickClose }: Props): React.ReactElement {
	const completeCnt = useMemo(() => `완료 ${item.completeCnt}건`, [item.completeCnt]);
	const errorCnt = useMemo(() => `실패 ${item.errorCnt}건`, [item.errorCnt]);

	const logs = useMemo(() => item.uploadResultDesc.split('\n'), [item.uploadResultDesc]);
	return (
		<CRDialog
			type='S'
			title='업로드 결과'
			body={
				<S.Container>
					<S.ResultContainer>
						<CRStatus
							options={[
								{
									color: 'green',
									key: completeCnt,
								},
							]}>
							{completeCnt}
						</CRStatus>
						<CRStatus
							options={[
								{
									color: 'red',
									key: errorCnt,
								},
							]}>
							{errorCnt}
						</CRStatus>
					</S.ResultContainer>
					<S.ResultMessageListContainer>
						{logs.map((item) => (
							<S.ResultMessageContainer>{item}</S.ResultMessageContainer>
						))}
					</S.ResultMessageListContainer>
				</S.Container>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default OwnExpenseUploadHistoryDetailDialog;
