import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	height: calc(100vh - 16.8rem);
	overflow: hidden;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
	flex: 1;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	box-sizing: border-box;
`;

export const BodyContainer = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	display: flex;
	flex-direction: column;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: overlay;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 1.6rem 2.4rem;
	gap: 1.6rem;

	& > * {
		user-select: auto;
	}
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;
export const SubTitle = styled.p`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray00};
`;
export const BottomContainer = styled.div`
	height: 8rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;
