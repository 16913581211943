import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	padding-bottom: 5rem;
	position: relative;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;
