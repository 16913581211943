import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import TaskLayout from 'components/domain/layout/TaskLayout';
import CRScrollSpy from 'components/base/CRScrollSpy';
import CRPageHeader from 'components/base/CRPageHeader';
import CRScrollSpyContainer from 'components/base/CRScrollSpyContainer';
import CRPageFooter from 'components/base/CRPageFooter';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import RouterPath from 'common/router';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';

import * as S from './styles';
import { generateContractScrollSpy } from './constant';
import CRSpinner from '../../../components/base/CRSpinner';

function ContractTaskPage(): React.ReactElement {
	const { showDialog, setCustomBackHandler } = useGlobalLayout();
	const { saveCompleteContract, saveTempContract, form, isLoaded, refetch, disabled } =
		useContractTask();
	const params = useParams<{ id?: string }>();
	const navigate = useNavigate();
	const location = useLocation();

	const isCompleted = disabled;

	const LeftSideComponent = useMemo(() => {
		const scrollSpySections = generateContractScrollSpy(navigate, form, async () => {
			if (isCompleted) return;

			await saveTempContract?.({ disableToast: true });
			refetch();
		});
		const splitPathName = location.pathname.split('/');
		const lastPath = splitPathName[splitPathName.length - 1];
		return (
			<S.LeftSideComponentContainer>
				<CRScrollSpy currentKey={lastPath + location.hash} sections={scrollSpySections} />
			</S.LeftSideComponentContainer>
		);
	}, [location, navigate, form.watch(), isCompleted]);

	const title = useMemo(() => {
		if (location.pathname.includes('recipient-basic')) return '수급자 정보';
		if (location.pathname.includes('contract-service')) return '급여 정보';
		if (location.pathname.includes('manager') && form.watch('employees')?.length)
			return '담당 직원 정보';
		if (location.pathname.includes('contract-inspection')) return '계약 점검';
		return '';
	}, [location.pathname, form.watch('employees')?.length]);

	const hasTab = useMemo(() => {
		if (location.pathname.includes('contract-service')) return true;
		if (location.pathname.includes('manager') && form.watch('employees')?.length) return true;
		return false;
	}, [location.pathname, form.watch('employees')?.length]);

	const handleClickPrevious = async () => {
		const splitPathname = location.pathname.split('/');
		const newPathname = splitPathname.slice(0, splitPathname.length - 1).join('/');

		if (!isCompleted) {
			await saveTempContract?.();
			refetch();
		}
		if (location.pathname.includes('recipient-basic')) return;
		if (location.pathname.includes('contract-service')) {
			navigate(`${newPathname}/recipient-basic`);
			return;
		}
		if (location.pathname.includes('manager')) {
			navigate(`${newPathname}/contract-service`);
			return;
		}
		if (location.pathname.includes('contract-inspection')) {
			navigate(`${newPathname}/manager`);
			return;
		}
	};

	const handleClickNext = async () => {
		const splitPathname = location.pathname.split('/');
		const newPathname = splitPathname.slice(0, splitPathname.length - 1).join('/');

		if (location.pathname.includes('recipient-basic')) {
			navigate(`${newPathname}/contract-service`);
			return;
		}
		if (location.pathname.includes('contract-service')) {
			navigate(`${newPathname}/manager`);
			return;
		}
		if (location.pathname.includes('manager')) {
			if (!isCompleted) {
				await saveTempContract?.();
				refetch();
			}
			navigate(`${newPathname}/contract-inspection`);
			navigate(`${newPathname}/contract-inspection`);
			return;
		}
		if (location.pathname.includes('contract-inspection')) {
			return;
		}
	};

	const goBackPage = () => {
		if (location.pathname.includes('recipient/')) {
			navigate(
				`/${RouterPath.recipient().tab.uniqueKey}/${params.id}/${
					RouterPath.recipient().service.uniqueKey
				}`,
			);
		} else if (location.pathname.includes('employee/')) {
			navigate(
				`/${RouterPath.employee().tab.uniqueKey}/${params.id}/${
					RouterPath.employee().contract.uniqueKey
				}`,
			);
		} else {
			navigate(`${RouterPath.contract()}`);
		}
	};

	const handleClickCancel = useCallback(() => {
		if (isCompleted) {
			goBackPage();
			return;
		}
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='계약서에 변경된 정보를 저장하고 나갑니다.'
				hideDialog={hideDialog}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						goBackPage();
					},
				}}
				successOption={{
					text: '저장',
					successCallback: async () => {
						await saveTempContract?.();
						hideDialog();
						goBackPage();
					},
				}}
			/>
		));
	}, [isCompleted]);

	useFullScreen();

	useEffect(() => {
		setCustomBackHandler(() => handleClickCancel);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, [handleClickCancel]);

	if (!isLoaded) return <CRSpinner />;

	return (
		<TaskLayout LeftSideComponent={LeftSideComponent}>
			<S.MainComponentContainer $hasTab={hasTab}>
				<CRScrollSpyContainer offset={hasTab ? -50 : 0}>
					<S.ContentContainer>
						<S.HeaderComponentContainer>
							<CRPageHeader headerTitle={title} />
						</S.HeaderComponentContainer>
						<Outlet />
					</S.ContentContainer>
				</CRScrollSpyContainer>
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							leftButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'outlined',
									onClick: handleClickPrevious,
									disabled: location.pathname.includes('recipient-basic'),
									children: '이전',
								},
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'outlined',
									disabled: location.pathname.includes('contract-inspection'),
									onClick: handleClickNext,
									children: '다음',
								},
							]}
							rightButtons={
								isCompleted
									? [
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'text',
												onClick: goBackPage,
												children: '확인',
											},
									  ]
									: form.watch('contractBaseInfo.contractStateCd') === 'CMN082.00'
									? [
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'text',
												onClick: handleClickCancel,
												children: '취소',
											},
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'outlined',
												onClick: () => saveTempContract?.(),
												children: '저장',
											},
									  ]
									: [
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'text',
												onClick: handleClickCancel,
												children: '취소',
											},
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'outlined',
												onClick: () => saveTempContract?.(),
												children: '저장',
											},
											{
												palette: 'primary',
												buttonType: 'button',
												type: 'filled',
												onClick: saveCompleteContract,
												children: '계약완료',
											},
									  ]
							}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			</S.MainComponentContainer>
		</TaskLayout>
	);
}

export default React.memo(ContractTaskPage);
