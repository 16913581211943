import React from 'react';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import MyCenterUsageInformationView from './MyCenterUsageInformationView';
import MyCenterUsageInformationForm from './MyCenterUsageInformationForm';

function MyCenterUsageInformationFormView(): React.ReactElement {
	const { isEdit } = useCenterInfo();
	return isEdit ? <MyCenterUsageInformationForm /> : <MyCenterUsageInformationView />;
}

export default MyCenterUsageInformationFormView;
