import { IntegratedAssessmentKmmseQuestion } from 'types/view/recipient';

export const integratedAssessmentKmmseQuestions: IntegratedAssessmentKmmseQuestion[] = [
	{
		key: '기억등록',
		label: '기억 등록',
		questions: [
			{
				key: '기차',
				question1: '기차',
			},
			{
				key: '사과',
				question1: '사과',
			},
			{
				key: '은행나무',
				question1: '은행나무',
			},
		],
	},
	{
		key: '시간지남력',
		label: '시간지남력',
		questions: [
			{
				key: '년',
				question1: '년',
			},
			{
				key: '월',
				question1: '월',
			},
			{
				key: '일',
				question1: '일',
			},
			{
				key: '요일',
				question1: '요일',
			},
			{
				key: '계절',
				question1: '계절',
			},
		],
	},
	{
		key: '장소기억력',
		label: '장소지남력',
		questions: [
			{
				key: '국가',
				question1: '나라',
			},
			{
				key: '시',
				question1: '시/도',
				question2: '특별시(광역시)/도',
			},
			{
				key: '장소',
				question1: '무엇하는 곳',
				question2: '구/시・군',
			},
			{
				key: 'currentPlace',
				question1: '현재 장소명',
			},
			{
				key: 'floor',
				question1: '몇 층',
				question2: '동(도로명)/읍・면',
			},
		],
	},
	{
		key: '기억회상',
		label: '기억 회상',
		questions: [
			{
				key: '기차',
				question1: '기차',
			},
			{
				key: '사과',
				question1: '사과',
			},
			{
				key: '은행나무',
				question1: '은행나무',
			},
		],
	},
];
