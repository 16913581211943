import { CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_INTEGRATED_ASSESSMENT_HEAD_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '회차',
		sortKey: '회차',
	},
	{
		width: '16rem',
		label: '낙상위험도',
		sortKey: '낙상위험도',
	},
	{
		width: '16rem',
		label: '욕창위험도',
		sortKey: '욕창위험도',
	},
	{
		width: '16rem',
		label: '인지기능',
		sortKey: '인지기능',
	},
	{
		width: '16rem',
		label: '욕구사정',
		sortKey: '욕구사정',
	},
	{
		width: '19.2rem',
		label: '급여제공 계획서',
		sortKey: '급여제공 계획서',
	},
	{
		width: '19.2rem',
		label: '급여제공 변경 계획서',
		sortKey: '급여제공 변경 계획서',
	},
];
