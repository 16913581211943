import { useContext } from 'react';

import { RecipientPageContext } from 'lib/provider/recipient';

export default function useRecipientPage() {
	const context = useContext(RecipientPageContext);
	if (!context) {
		throw new Error('useRecipientPage must be used within a RecipientPageContext');
	}
	return context;
}
