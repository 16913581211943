import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useCreateContracts = useCRMutation(endpoint.createContract.key, 'createContract');

export const useSaveCompleteContract = useCRMutation(
	endpoint.saveCompleteContract.key,
	'saveCompleteContract',
);

export const useSaveTempContract = useCRMutation(endpoint.saveTempContract.key, 'saveTempContract');

export const useSendRecipientContract = useCRMutation(
	endpoint.sendRecipientContract.key,
	'sendRecipientContract',
);

export const useSendRecipientRenewalContract = useCRMutation(
	endpoint.sendRecipientRenewalContract.key,
	'sendRecipientRenewalContract',
);

export const useSendEmployeeContract = useCRMutation(
	endpoint.sendEmployeeContract.key,
	'sendEmployeeContract',
);

export const useCreateRenewalRecipientContract = useCRMutation(
	endpoint.createRenewalRecipientContract.key,
	'createRenewalRecipientContract',
);

export const useRenewalRecipientContract = useCRMutation(
	endpoint.renewalRecipientContract.key,
	'renewalRecipientContract',
);

export const useSaveRenewalRecipientContract = useCRMutation(
	endpoint.saveRenewalRecipientContract.key,
	'saveRenewalRecipientContract',
);

export const useChangeEmployeeContract = useCRMutation(
	endpoint.changeEmployeeContract.key,
	'changeEmployeeContract',
);

export const useChangeTempEmployeeContract = useCRMutation(
	endpoint.changeTempEmployeeContract.key,
	'changeTempEmployeeContract',
);

export const useCreateChangeEmployeeContract = useCRMutation(
	endpoint.createChangeEmployeeContract.key,
	'createChangeEmployeeContract',
);

export const useGetServiceEmployees = useCRMutation(
	endpoint.getServiceEmployees.key,
	'getServiceEmployees',
);

export const useChangeContractStatus = useCRMutation(
	endpoint.changeContractStatus.key,
	'changeContractStatus',
);

export const useUploadVisitNursing = useCRMutation(
	endpoint.uploadVisitNursing.key,
	'uploadVisitNursing',
);

export const useServiceTermination = useCRMutation(
	endpoint.serviceTermination.key,
	'serviceTermination',
);

export const useCreateRecipientDocument = useCRMutation(
	endpoint.createRecipientDocument.key,
	'createRecipientDocument',
);

export const useCreateRecipientRenewalDocument = useCRMutation(
	endpoint.createRecipientRenewalDocument.key,
	'createRecipientRenewalDocument',
);

export const useCreateEmployeeDocument = useCRMutation(
	endpoint.createEmployeeDocument.key,
	'createEmployeeDocument',
);

export const useUploadRecipientDocument = useCRMutation(
	endpoint.uploadRecipientDocument.key,
	'uploadRecipientDocument',
);

export const useUploadRecipientRenewalDocument = useCRMutation(
	endpoint.uploadRecipientRenewalDocument.key,
	'uploadRecipientRenewalDocument',
);

export const useUploadEmployeeDocument = useCRMutation(
	endpoint.uploadEmployeeDocument.key,
	'uploadEmployeeDocument',
);

export const useGetRecipientDocument = useCRMutation(
	endpoint.getRecipientDocument.key,
	'getRecipientDocument',
);

export const useGetRecipientRenewalDocument = useCRMutation(
	endpoint.getRecipientRenewalDocument.key,
	'getRecipientRenewalDocument',
);

export const useGetEmployeeDocument = useCRMutation(
	endpoint.getEmployeeDocument.key,
	'getEmployeeDocument',
);

export const useDeleteRenewalContract = useCRMutation(
	endpoint.deleteRenewalContract.key,
	'deleteRenewalContract',
);

export const useDeleteServiceContract = useCRMutation(
	endpoint.deleteServiceContract.key,
	'deleteServiceContract',
);
