import React, { useLayoutEffect, useMemo, useState } from 'react';

import CRTableHeader from 'components/base/CRTableHeader';
import { useCenterManagers, useEmployeeSalarys, useMyAccountInfo } from 'lib/hook/react-query';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { CheckOption } from 'components/base/Selections/type';
import { unique } from 'lib';
import { Filter } from 'types/view/filter';
import EmployeeSalaryTable from 'components/domain/table/EmployeeSalaryTable';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';

export default function EmployeeSalaryTab() {
	const [currentFilter, setCurrentFilter] = useState<{
		managerFilter: CheckOption[];
	}>({
		managerFilter: [],
	});
	const [searchValue, setSearchValue] = useState<string>('');

	const { data: myAccountInfo } = useMyAccountInfo();
	const { currentEmployee } = useEmployeePage();
	const {
		data: employeeSalarys,
		isLoading,
		refetch: refetchEmployeeSalarys,
	} = useEmployeeSalarys({
		centerId: myAccountInfo?.centerId,
		employeeId: currentEmployee?.employeeId,
		managerIds: currentFilter?.managerFilter?.map((item) => item.value) || [],
	});

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options: unique(centerManagers ?? [], (item) => item.memberAccountId)?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})),
				placeholder: '사회복지사',
			} as Filter<CheckOption>),
		[centerManagers],
	);

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const employeeSalarysData = useMemo(
		() =>
			(employeeSalarys || [])?.filter((item) =>
				item?.recipientNm?.toLowerCase().includes(searchValue.toLowerCase()),
			),
		[employeeSalarys, searchValue],
	);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<S.Container>
			<CRTableHeader
				searchValue={searchValue}
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={[managerFilter]}
				onChangeSearchValue={handleSearchKeyword}
				placeholder='수급자명으로 검색'
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<EmployeeSalaryTable items={employeeSalarysData} refetch={refetchEmployeeSalarys} />
				</S.TableContainer>
			)}
		</S.Container>
	);
}
