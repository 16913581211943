import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 67.5rem;
	max-width: 80rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const TableContainer = styled.div`
	width: 27.6rem;
`;

export const GenerateDescriptionContainer = styled.p`
	background-color: ${(p) => p.theme.colors.primary98};
	border-radius: 0.8rem;
	padding: 0.75rem 1.6rem;
	position: relative;

	> img {
		${(p) => p.theme.filters.primary98};
		transform: rotate(30deg);
		top: 1.5rem;
		left: -0.8rem;
		position: absolute;
	}
`;
