import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const SearchIcon = styled(Icon)``;

export const CloseIcon = styled(Icon)<{ $isWriting: boolean }>`
	visibility: ${(props) => (props.$isWriting ? 'visible' : 'hidden')};
	margin-left: 0.8rem;
	filter: invert(66%) sepia(0%) saturate(7248%) hue-rotate(282deg) brightness(85%) contrast(95%);
`;

export const SearchBar = styled.div<{ $isFolded: boolean }>`
	display: flex;
	align-items: center;
	position: relative;

	border: none;
	width: ${(props) => (props.$isFolded ? '0' : '100%')};
	max-width: ${(props) => (props.$isFolded ? '0' : '24.4rem')};
	margin-left: ${(props) => (props.$isFolded ? '0' : '0.8rem')};
	overflow: hidden;
	transition: all 300ms ease 0s;
	overflow: hidden;

	& > img {
		filter: invert(66%) sepia(0%) saturate(7248%) hue-rotate(282deg) brightness(85%) contrast(95%);
	}
`;

export const SearchInput = styled.input`
	${(props) => props.theme.typography.body};
	width: 100%;
	border: none;
	min-height: 2.8rem;
	outline: none;
	padding: 0.2rem 0 0.2rem;

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}
`;
