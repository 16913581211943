import React, { PropsWithChildren, createContext, useMemo, useState } from 'react';

export interface INavigationContext {
	isRightSideFolded: boolean;
	setIsRightSideFolded: React.Dispatch<React.SetStateAction<boolean>>;
	isLeftSideFolded: boolean;
	setIsLeftSideFolded: React.Dispatch<React.SetStateAction<boolean>>;
	isHeld: boolean;
	setIsHeld: React.Dispatch<React.SetStateAction<boolean>>;
	blockLeftHoverEvent: boolean;
	setBlockLeftHoverEvent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavigationContext = createContext<INavigationContext | undefined>(undefined);

function NavigationProvider({ children }: PropsWithChildren): React.ReactElement {
	const [isRightSideFolded, setIsRightSideFolded] = useState(true);
	const [isLeftSideFolded, setIsLeftSideFolded] = useState(false);
	const [isHeld, setIsHeld] = useState(true);
	const [blockLeftHoverEvent, setBlockLeftHoverEvent] = useState(false);

	const value = useMemo(
		() => ({
			isRightSideFolded,
			setIsRightSideFolded,
			isLeftSideFolded,
			setIsLeftSideFolded,
			isHeld,
			setIsHeld,
			blockLeftHoverEvent,
			setBlockLeftHoverEvent,
		}),
		[
			isRightSideFolded,
			setIsRightSideFolded,
			isLeftSideFolded,
			setIsLeftSideFolded,
			isHeld,
			setIsHeld,
			blockLeftHoverEvent,
			setBlockLeftHoverEvent,
		],
	);

	return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}

export default NavigationProvider;
