import React, { CSSProperties, PropsWithChildren } from 'react';
import Assets from 'assets';
import * as S from './styles';
import { TagPalette } from './type';
// import { TagPalette } from './styles';

interface IProps {
	palette?: TagPalette;
	onClose?: () => void;
	style?: CSSProperties;
}

function CRTag({ palette = 'primary', onClose, style, children }: PropsWithChildren<IProps>) {
	return (
		<S.Container $palette={palette} style={style}>
			{children}
			{onClose && <S.CloseIcon src={Assets.icon.close} />}
		</S.Container>
	);
}

export default CRTag;
