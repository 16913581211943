import { IntegratedAssessmentDesireRecipientCaregiverNeedsQuestion } from 'types/view/recipient';

export const integratedAssessmentDesireRecipientCaregiverNeedsQuestions: IntegratedAssessmentDesireRecipientCaregiverNeedsQuestion[] =
	[
		{
			key: '일상생활',
			label: '일상생활',
			answer: [
				{
					label: '개인위생 (세수, 구강청결, 몸씻기 등)',
					value: '개인위생 (세수, 구강청결, 몸씻기 등)',
				},
				{
					label: '식사하기 (식사준비, 식사도움)',
					value: '식사하기 (식사준비, 식사도움)',
				},
				{
					label: '화장실 이용하기 (이동변기, 기저귀 교환 등)',
					value: '화장실 이용하기 (이동변기, 기저귀 교환 등)',
				},
				{
					label: '이동도움 (부축, 보행기, 휠체어 등)',
					value: '이동도움 (부축, 보행기, 휠체어 등)',
				},
				{
					label: '산책동행',
					value: '산책동행',
				},
				{
					label: '병원진료 동행',
					value: '병원진료 동행',
				},
				{
					label: '관공서 동행',
					value: '관공서 동행',
				},
				{
					label: '여가활동 동행',
					value: '여가활동 동행',
				},
				{
					label: '청소 ・ 주변정돈',
					value: '청소 ・ 주변정돈',
				},
				{
					label: '세탁',
					value: '세탁',
				},
				{
					label: '장보기',
					value: '장보기',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '기능회복훈련',
			label: '기능회복훈련',
			answer: [
				{
					label: '신체기능훈련 (연하훈련, 관절운동, 근력운동, 팔운동, 손가락운동 등)',
					value: '신체기능훈련 (연하훈련, 관절운동, 근력운동, 팔운동, 손가락운동 등)',
				},
				{
					label: '기본동작훈련 (체위변경, 일어나앉기, 일어서기, 서있기, 옮겨앉기, 보행 등)',
					value: '기본동작훈련 (체위변경, 일어나앉기, 일어서기, 서있기, 옮겨앉기, 보행 등)',
				},
				{
					label: '일상생활동작훈련 (식사동작, 양치동작, 옷갈아입기 동작, 화장실사용 등)',
					value: '일상생활동작훈련 (식사동작, 양치동작, 옷갈아입기 동작, 화장실사용 등)',
				},
				{
					label: '인지기능향상프로그램 (회상훈련, 감각활동, 작업치료 등)',
					value: '인지기능향상프로그램 (회상훈련, 감각활동, 작업치료 등)',
				},
				{
					label: '사회적응훈련(대중교통이용, 문화체험, 종교활동 등)',
					value: '사회적응훈련(대중교통이용, 문화체험, 종교활동 등)',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '정서지원',
			label: '정서지원',
			answer: [
				{
					label: '말벗 서비스',
					value: '말벗 서비스',
				},
				{
					label: '여가 ・ 정서프로그램 (개인활동, 취미활동, 요리활동, 산책 등)',
					value: '여가 ・ 정서프로그램 (개인활동, 취미활동, 요리활동, 산책 등)',
				},
				{
					label: '기타',
					value: '기타',
				},
			],
		},
		{
			key: '가족수발경감',
			label: '가족 수발 경감',
			answer: [
				{
					label: '신체적 부양부담 완화',
					value: '신체적 부양부담 완화',
				},
				{
					label: '정신적 부양부담 완화',
					value: '정신적 부양부담 완화',
				},
				{
					label: '경제적 부양부담 완화',
					value: '경제적 부양부담 완화',
				},
			],
		},
	];
