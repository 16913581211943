import React from 'react';

import Colors from 'common/colors';

import * as S from './styles';

function IntegratedFallFormHeader(): React.ReactElement {
	return (
		<S.TableHeader>
			<S.TableHeaderColumn style={{ width: '17%', borderRight: `0.4rem solid ${Colors.gray90}` }}>
				구분
			</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '15%' }}>4점</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '15%' }}>3점</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '15%' }}>2점</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '15%' }}>1점</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '15%' }}>0점</S.TableHeaderColumn>
			<S.TableHeaderColumn style={{ width: '8%' }}>점수</S.TableHeaderColumn>
		</S.TableHeader>
	);
}

export default React.memo(IntegratedFallFormHeader);
