import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import { RecipientGuardianFormViewType } from 'types/view/recipient';
import { useCommonCodes } from 'lib/hook/react-query';

import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { displayBirthDay, displayPhoneNumber } from 'lib';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { CRText } from 'components/base/CRText';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { commonCodeAdapter } from 'lib/adapter/common';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import Assets from 'assets';
import * as S from './styles';

interface Props {
	items?: RecipientGuardianFormViewType[];
	onChangeMode: () => void;
}

function RecipientGuardianView({ items, onChangeMode }: Props): React.ReactElement {
	const { data: commonCodes } = useCommonCodes(
		{ comCdGroupNms: ['CMN038', 'CMN180'] },
		commonCodeAdapter,
	);
	const { currentRecipientStatus } = useRecipientPage();

	const relationshipOptions = useMemo(() => commonCodes?.CMN038 || [], [commonCodes]);

	const docReceiveMethodOptions = useMemo(() => commonCodes?.CMN180 || [], [commonCodes]);

	const renderAddress = (item: RecipientGuardianFormViewType) => {
		const address = [
			item.address.basAddr,
			item.address.detailAddr,
			item.address.zipCode ? `(${item.address.zipCode})` : '',
		]
			.filter(Boolean)
			.join(' ');
		return address || '-';
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';
	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');
	return (
		<S.Container>
			<S.Label>
				보호자
				{!isResign && (
					<S.ButtonContainer>
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>

			{items?.map((item, index) => (
				<>
					<S.ItemHeader>
						<CRText typography='label'>{`보호자 ${formatIndex(index)}`}</CRText>

						<CRCheckBox checkBoxType='radio' checked={item.isMain} disabled>
							주 보호자
						</CRCheckBox>
					</S.ItemHeader>
					<S.Table $isDisabled={isResign}>
						<S.TableRow>
							<S.TableLabelColumn style={{ width: '10.4rem' }}>이름</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								{item?.name || '-'}
							</S.TableValueColumn>
							<S.TableLabelColumn style={{ width: '10.4rem' }}>연락처</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								{item.phoneNumber ? displayPhoneNumber(item.phoneNumber) : '-'}
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
								주소
							</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }} rowSpan={2}>
								{renderAddress(item) ?? '-'}
							</S.TableValueColumn>
							<S.TableLabelColumn style={{ width: '10.4rem' }}>생년월일</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								{item?.birthday ? displayBirthDay(item?.birthday) : '-'}
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn style={{ width: '10.4rem' }}>관계</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								{relationshipOptions?.find((code) => code.value === item?.relationship)?.label ||
									'-'}
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn>
								<S.LabelContainer>
									서류 수신 여부
									<RDTooltip
										content={
											<>생년월일이 없는 보호자의 서류 비밀번호는 수급자의 생년월일 입니다</>
										}>
										<S.QuestionMark src={Assets.icon.help} alt='공휴일 근무일정' />
									</RDTooltip>
								</S.LabelContainer>
							</S.TableLabelColumn>
							<S.TableValueColumn
								style={{
									verticalAlign: 'middle',
								}}>
								<FlexContainer gap='0.8rem' align='center'>
									<FlexContainer>
										<CRCheckBox
											disabled
											name='일정표'
											checkBoxType='checkbox'
											checked={item.scheduleReceiveYn}>
											일정표
										</CRCheckBox>
									</FlexContainer>
									<FlexContainer>
										<CRCheckBox
											disabled
											name='장기요양급여비용명세서'
											checkBoxType='checkbox'
											checked={item.longTermPayFeeCertiReceiveYn}>
											장기요양급여비용명세서
										</CRCheckBox>
									</FlexContainer>
								</FlexContainer>
							</S.TableValueColumn>
							<S.TableLabelColumn>서류 수신 방법</S.TableLabelColumn>
							<S.TableValueColumn>
								{docReceiveMethodOptions?.find(
									(option) => option?.value === item.docReceiveMethodCd,
								)?.label || '-'}
							</S.TableValueColumn>
						</S.TableRow>
					</S.Table>
				</>
			))}
		</S.Container>
	);
}

export default RecipientGuardianView;
