import { IntegratedAssessmentDesireRehabilitationStatusQuestion } from 'types/view/recipient';

export const integratedAssessmentMajorIllnessStatusQuestions: IntegratedAssessmentDesireRehabilitationStatusQuestion[] =
	[
		{
			key: '운동장애',
			label: '운동장애',
			answer: [
				{
					label: '우측상지',
					value: '우측상지',
				},
				{
					label: '좌측상지',
					value: '좌측상지',
				},
				{
					label: '우측하지',
					value: '우측하지',
				},
				{
					label: '좌측하지',
					value: '좌측하지',
				},
			],
		},
		{
			key: '관절구축',
			label: '관절구축',
			answer: [
				{
					label: '어깨관절(좌/우)',
					value: '어깨관절(좌/우)',
				},
				{
					label: '팔꿈치관절(좌/우)',
					value: '팔꿈치관절(좌/우)',
				},
				{
					label: '손목 및 수지관절(좌/우)',
					value: '손목 및 수지관절(좌/우)',
				},
				{
					label: '고관절(좌/우)',
					value: '고관절(좌/우)',
				},
				{
					label: '무릎관절(좌/우)',
					value: '무릎관절(좌/우)',
				},
				{
					label: '발목관절(좌/우)',
					value: '발목관절(좌/우)',
				},
			],
		},
		{
			key: '보행장애낙상경험',
			label: '보행장애',
			answer: [
				{
					label: '매일',
					value: '매일',
				},
				{
					label: '주 1회이상',
					value: '주 1회이상',
				},
				{
					label: '월 1회이상',
					value: '월 1회이상',
				},
				{
					label: '가끔',
					value: '가끔',
				},
			],
		},
		{
			key: '보행장애걸음걸이',
			label: '보행장애',
			answer: [
				{
					label: '서거나 걸을 때 균형을 유지하지 못함',
					value: '서거나 걸을 때 균형을 유지하지 못함',
				},
				{
					label: '일어서거나 걸을 때 어지러움',
					value: '일어서거나 걸을 때 어지러움',
				},
				{
					label: '보조도구나 부축해서 걷기',
					value: '보조도구나 부축해서 걷기',
				},
			],
		},
	];
