import React, { ReactElement } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { ButtonType } from 'components/base/CRButton/type';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';

interface Props {
	title?: ReactElement | string;
	content?: ReactElement | string;
	successOption?: {
		text?: string;
		successCallback?: () => void;
		disabled?: boolean;
		type?: ButtonType;
	};
	cancelOption?: {
		text?: string;
		callback?: () => void;
		type?: ButtonType;
	};
	isLoading?: boolean;
	hideCloseButton?: boolean;
	hideDialog?: () => void;
}

export default function DefaultDialog({
	title,
	content,
	isLoading,
	successOption = {
		disabled: false,
	},
	cancelOption,
	hideCloseButton,
	hideDialog,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.ContentsContainer>
				<S.HeaderContainer>
					<S.HeaderTitleContainer>
						<S.HeaderTitle>{title}</S.HeaderTitle>
						{!hideCloseButton && (
							<S.CloseIcon src={Assets.icon.close} alt='close' onClick={() => hideDialog?.()} />
						)}
					</S.HeaderTitleContainer>
				</S.HeaderContainer>
				<S.MainContainer>{content}</S.MainContainer>
			</S.ContentsContainer>
			<S.FooterContainer>
				{cancelOption && (
					<CRButton.Default
						type={cancelOption?.type ?? 'text'}
						palette='gray'
						onClick={() => {
							cancelOption?.callback?.();
							hideDialog?.();
						}}>
						{cancelOption?.text}
					</CRButton.Default>
				)}
				<CRButton.Default
					style={{
						position: 'relative',
						width: '7.6rem',
						height: '4rem',
					}}
					type={successOption?.type}
					disabled={isLoading ? true : successOption?.disabled ?? false}
					onClick={() => {
						successOption?.successCallback?.();
					}}>
					{isLoading ? <CRSpinner /> : successOption?.text}
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}
